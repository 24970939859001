import React from 'react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
  style?: React.CSSProperties;
}

const Money = ({ width = 24, height = 18, color = 'none', style }: Properties) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 32 32'
      fill={color}
      style={style}
    >
      <mask
        id='mask0_17_619'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='32'
        height='32'
      >
        <rect width='31.7115' height='31.7115' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_17_619)'>
        <path
          d='M18.4983 17.1771C17.3972 17.1771 16.4613 16.7917 15.6906 16.0209C14.9198 15.2502 14.5344 14.3142 14.5344 13.2131C14.5344 12.1121 14.9198 11.1761 15.6906 10.4054C16.4613 9.6346 17.3972 9.24921 18.4983 9.24921C19.5994 9.24921 20.5354 9.6346 21.3061 10.4054C22.0769 11.1761 22.4623 12.1121 22.4623 13.2131C22.4623 14.3142 22.0769 15.2502 21.3061 16.0209C20.5354 16.7917 19.5994 17.1771 18.4983 17.1771ZM9.24916 21.141C8.52244 21.141 7.90032 20.8823 7.38281 20.3647C6.86529 19.8472 6.60654 19.2251 6.60654 18.4984V7.9279C6.60654 7.20118 6.86529 6.57906 7.38281 6.06155C7.90032 5.54404 8.52244 5.28528 9.24916 5.28528H27.7475C28.4742 5.28528 29.0964 5.54404 29.6139 6.06155C30.1314 6.57906 30.3901 7.20118 30.3901 7.9279V18.4984C30.3901 19.2251 30.1314 19.8472 29.6139 20.3647C29.0964 20.8823 28.4742 21.141 27.7475 21.141H9.24916ZM11.8918 18.4984H25.1049C25.1049 17.7717 25.3637 17.1496 25.8812 16.632C26.3987 16.1145 27.0208 15.8558 27.7475 15.8558V10.5705C27.0208 10.5705 26.3987 10.3118 25.8812 9.79425C25.3637 9.27674 25.1049 8.65462 25.1049 7.9279H11.8918C11.8918 8.65462 11.633 9.27674 11.1155 9.79425C10.598 10.3118 9.97588 10.5705 9.24916 10.5705V15.8558C9.97588 15.8558 10.598 16.1145 11.1155 16.632C11.633 17.1496 11.8918 17.7717 11.8918 18.4984ZM26.4262 26.4263H3.96391C3.23719 26.4263 2.61507 26.1675 2.09756 25.65C1.58005 25.1325 1.32129 24.5104 1.32129 23.7836V9.24921H3.96391V23.7836H26.4262V26.4263Z'
          fill='#4BB6B9'
        />
      </g>
    </svg>
  );
};

export default Money;
