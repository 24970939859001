import { Spinner as ChakraSpinner, StyleProps } from '@chakra-ui/react';

interface Properties {
  thickness?: string | number;
  size?: string;
  styleProperties?: StyleProps;
}

const Spinner = ({ styleProperties, thickness = '4px', size = 'xl' }: Properties) => {
  return (
    <ChakraSpinner
      {...styleProperties}
      thickness={typeof thickness === 'string' ? thickness : `${thickness}px`}
      speed='0.65s'
      emptyColor='grey.800'
      color='green.900'
      size={size}
    />
  );
};

export default Spinner;
