import { Flex, VStack, Text } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CloseIcon } from '@chakra-ui/icons';

import {
  clearStepperFormData,
  getFormValues,
  setActionCreateForm,
  setClearSelectedAreasTable,
} from 'redux/DataImportSlice';
import { fetchModelTime } from 'redux/IndicatorPageSlice';

import Rows from './TimePeriod/Rows';
import Columns from './TimePeriod/Columns';

interface Properties {
  setHelpModalSeleceted?: any;
}

const DataStepsTwo = ({ setHelpModalSeleceted }: Properties) => {
  const dispatch: any = useDispatch();
  const { modelId } = useParams();

  const getFormValue = useSelector(getFormValues);
  useEffect(() => {
    dispatch(fetchModelTime(Number(modelId)));
  }, []);
  return (
    <VStack w={'100%'} pt={3}>
      <Flex justifyContent={'space-between'} w={'full'} align={'center'}>
        <Text
          color={'#6562CF'}
          fontSize='lg'
          fontFamily={'regular_roboto'}
          fontWeight={'400'}
          textAlign={'start'}
        >
          Select time period
        </Text>
        <CloseIcon
          color={'black'}
          width={3}
          height={3}
          cursor={'pointer'}
          onClick={async () => {
            await dispatch(setActionCreateForm(false));
            await dispatch(clearStepperFormData());
            await dispatch(setClearSelectedAreasTable());
          }}
        />
      </Flex>
      {getFormValue?.selectedTimePeriod === 'row' ? (
        <Rows setHelpModalSeleceted={setHelpModalSeleceted} getFormValue={getFormValue} />
      ) : (
        <Columns setHelpModalSeleceted={setHelpModalSeleceted} getFormValue={getFormValue} />
      )}
    </VStack>
  );
};

export default DataStepsTwo;
