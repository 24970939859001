import { Box } from '@chakra-ui/react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { useState, useMemo } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { gePieDimFilter } from 'redux/DashboardSlice';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const UNIT_MULTIPLIERS: any = {
  k: 1000,
  mn: 1_000_000,
  bn: 1_000_000_000,
  auto: 1,
};

interface Properties {
  chartFormatting?: any;
  graphData?: any;
}
const PieChart = ({ chartFormatting, graphData }: Properties) => {
  const [pieChartData, setPieChartData] = useState<any>();
  const pieDimeFilter = useSelector(gePieDimFilter);

  const formatDataLabel = (value: any) => {
    if (chartFormatting?.units === 'auto') {
      return `£${value
        .toFixed(chartFormatting?.decimalPlaces)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
    }
    return `£${(value / UNIT_MULTIPLIERS[chartFormatting?.units])
      .toFixed(chartFormatting?.decimalPlaces)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${chartFormatting?.units}`;
  };

  useMemo(() => {
    let filterData = [];
    filterData =
      pieDimeFilter?.length > 0
        ? graphData?.dim_df?.filter((product: any) =>
            pieDimeFilter?.some((item: any) => item.status && item.name === product.Product),
          )
        : graphData?.dim_df || [];

    const dimName = graphData?.metric?.dimension_name;
    const indicatorName = graphData?.metric?.indicator_name;
    const { dimensionItems } = graphData?.metric?.formatting || {};

    const colorMap = new Map(
      dimensionItems?.map((item: any) => [item.dimension_item_name, item.color]),
    );

    if (graphData?.dim_df !== null) {
      const chartLabels = filterData?.map((item: any) => item[dimName]);
      const chartData = filterData?.map((item: any) => item.values);
      const chartColors = chartLabels?.map((label: any) => colorMap.get(label));

      const dataOfChart = {
        labels: chartLabels,
        datasets: [
          {
            label: dimName,
            data: chartData,
            backgroundColor: chartColors,
            borderWidth: 2,
          },
        ],
      };
      setPieChartData(dataOfChart);
    } else {
      const dataOfChart = {
        labels: [indicatorName],
        datasets: [
          {
            label: dimName,
            data: [graphData?.total],
            backgroundColor: ['#B062E3'],
            borderWidth: 2,
          },
        ],
      };
      setPieChartData(dataOfChart);
    }
  }, [graphData]);

  const donutHoleSize = Number(chartFormatting?.donutHoleSize);

  return (
    <Box>
      <Doughnut
        data={pieChartData}
        options={{
          cutout: `${donutHoleSize}%`,
          plugins: {
            tooltip: {
              enabled: true,
            },
            legend: {
              position: 'bottom',
              labels: {
                boxWidth: 8,
                boxHeight: 8,
                color: 'black',
                padding: 12,
                usePointStyle: false,
                font: {
                  size: 13,
                  family: 'Roboto',
                  weight: '500',
                },
              },
            },
            datalabels: {
              display: !!chartFormatting?.displayValue,
              color: 'black',
              font: {
                size: 16,
              },
              formatter: formatDataLabel,
            },
          },
        }}
      />
    </Box>
  );
};

export default PieChart;
