import { Button, Menu, Flex, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';

import AddIconComponent from 'components/Icons/AddIcon';
import ArrowDropdownIconComponent from 'components/Icons/ArrowDropdownIconComponent';

const Header = ({ onOpen }: any) => {
  return (
    <Flex justifyContent='space-between' align={'flex-start'} w={'100%'}>
      <Text fontSize={'md'}>Edit your source and actions here</Text>
      <Menu closeOnSelect={true} variant='primary'>
        <MenuButton
          backgroundColor={'#5A58AB'}
          padding={'.5rem 1rem'}
          as={Button}
          border={'none'}
          rightIcon={
            <ArrowDropdownIconComponent
              style={{ margin: '0 .5rem' }}
              height={12}
              width={12}
              color='white'
            />
          }
          leftIcon={
            <AddIconComponent
              style={{ margin: '0 .5rem' }}
              color='#62B5BA'
              height={18}
              width={18}
            />
          }
          fontSize={'md'}
          fontWeight={'400'}
          width={'fit-content'}
          height={'45px'}
        >
          Create
        </MenuButton>

        <MenuList>
          <MenuItem onClick={onOpen}>Source</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default Header;
