export default {
  gradient: {
    body: 'linear-gradient(180deg, #3B347B 0%, #6F65CA 100%)',
    body_dark: 'linear-gradient(180deg, #352f6b 0%, #5850a1 60%)',
    stat: 'linear-gradient(180deg, #02BE70 0%, #2ecad0 100%)',
    transparent: 'linear-gradient(180deg, rgb(113, 99, 208), rgba(113, 99, 208, 0))',
  },

  black: { 400: '#F5F5F5', 500: '#ffffff', 900: '#212121', 600: '#787878' },
  white: {
    200: '#F5F5F5',
    400: '#ffffff66',
    900: '#ffffff',
  },
  grey: {
    100: '#A9A8A8',
    200: '#F9F9F9',
    400: '#cdcdcd66',
    500: '#EFEEFD',
    600: '#bababa',
    700: '#D9D9D9',
    800: '#dadada',
    900: '#999999',
  },
  violet: {
    50: 'rgba(33, 33, 33, 0.08)',
    75: 'rgba(101, 98, 207, 0.16)',
    100: '#6153c5',
    200: '#DDDCFA',
    300: '#a785fc',
    400: '#8B89B1',
    450: '#8B8AAE',
    500: '#7163d0',
    600: '#6564C8',
    700: '#6561a9',
    750: '#6562cf',
    800: '#5850a1',
    850: '#4B4994',
    900: '#3b317d',
  },
  green: {
    300: '#69B4B8',
    400: '#4bb6b966',
    500: '#7CC8CE',
    600: '#4bb6b999',
    700: '#3BB8BC',
    800: '#2ecad0',
    900: '#4bb6b9',
  },
  accent: { 900: '#c06de8' },
  red: {
    500: '#fe6e6e',
    900: '#ff0000',
    dark: {
      500: '#D56565',
    },
  },
  cyan: {
    100: '#EEF2F6',
    500: '#62B5BA',
  },
  pink: {
    900: '#FF0240',
  },
};
