import { CheckIcon } from '@chakra-ui/icons';
import { Flex, Icon, useBoolean, VStack, Text, useMediaQuery } from '@chakra-ui/react';
import {
  Stepper,
  Step,
  StepIndicator,
  StepStatus,
  StepIcon,
  StepSeparator,
} from '@chakra-ui/stepper';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  // clearStepperFormData,
  getFormValues,
  getMetaDataFile,
  stepsLength,
  // setActionCreateForm,
  // setClearSelectedAreasTable,
} from 'redux/DataImportSlice';

import HelpImportModal from '../HelpImportModal';

import ActionStepOne from './ActionStepOne';
import ActionStepThree from './ActionStepThree';
import ActionStepTwo from './ActionStepTwo';
import DataStepsForm from './DataSteps/DataStepsForm';

const ActionCreateForm = ({ setactionCreate }: any) => {
  const dispatch: any = useDispatch();
  const [isMobile] = useMediaQuery('(max-width: 1610px)');
  const { modelId } = useParams();
  const [helpModalSeleceted, setHelpModalSeleceted] = useBoolean(false);
  const getFormValue = useSelector(getFormValues);
  const [steps, setSteps] = useState([1, 2, 3]);
  const activeActionStep = useSelector((state: any) => state.dataImportSlice.actionSteps);

  useEffect(() => {
    dispatch(getMetaDataFile(modelId));
  }, [modelId]);

  useEffect(() => {
    if (getFormValue.dataType === '1') {
      setSteps([1, 2, 3]);
    }
  }, [getFormValue.dataType === '1', activeActionStep]);

  useEffect(() => {
    if (getFormValue.dataType === '2') {
      setSteps([1, 2, 3, 4, 5]);
    }
  }, [getFormValue.dataType === '2', activeActionStep]);
  useEffect(() => {
    if (getFormValue.dataType === '2' && getFormValue?.dataPeriod === '1') {
      setSteps([1, 2, 3, 4, 5]);
    }
  }, [getFormValue.dataType === '2' && getFormValue?.dataPeriod === '1', activeActionStep]);

  useEffect(() => {
    if (getFormValue.dataType === '2' && getFormValue?.dataPeriod === '2') {
      setSteps([1, 2, 3, 4, 5, 6]);
    }
  }, [getFormValue.dataType === '2' && getFormValue?.dataPeriod === '2', activeActionStep]);

  useEffect(() => {
    if (
      getFormValue.dataType === '2' &&
      getFormValue?.dataPeriod === '2' &&
      (getFormValue?.selectedTimePeriod === 'row' || getFormValue?.selectedTimePeriod === 'column')
    ) {
      setSteps([1, 2, 3, 4, 5, 6]);
    }
  }, [
    getFormValue.dataType === '2' && getFormValue?.dataPeriod === '2',
    activeActionStep,
    getFormValue.selectedTimePeriod,
  ]);

  useEffect(() => {
    if (
      getFormValue.dataType === '2' &&
      getFormValue?.dataPeriod === '1' &&
      getFormValue?.multipleIndicatrosType === 'row' &&
      getFormValue?.importindicatroType === '2'
    ) {
      setSteps([1, 2, 3, 4, 5, 6, 7]);
    }
  }, [
    getFormValue.dataType === '2' &&
      getFormValue?.dataPeriod === '1' &&
      getFormValue?.multipleIndicatrosType === 'row' &&
      getFormValue?.importindicatroType === '2',
    activeActionStep,
  ]);

  useEffect(() => {
    if (
      getFormValue.dataType === '2' &&
      getFormValue?.dataPeriod === '1' &&
      getFormValue?.multipleIndicatrosType === 'column' &&
      getFormValue?.importindicatroType === '2'
    ) {
      setSteps([1, 2, 3, 4, 5, 6]);
    }
  }, [
    getFormValue.dataType === '2' &&
      getFormValue?.dataPeriod === '1' &&
      getFormValue?.multipleIndicatrosType === 'column' &&
      getFormValue?.importindicatroType === '2',
    activeActionStep,
  ]);

  useEffect(() => {
    if (
      getFormValue.dataType === '2' &&
      getFormValue?.dataPeriod === '2' &&
      getFormValue?.selectedTimePeriod === 'row' &&
      getFormValue?.multipleIndicatrosType === 'row' &&
      getFormValue?.importindicatroType === '2'
    ) {
      setSteps([1, 2, 3, 4, 5, 6, 7, 8]);
    }
  }, [
    getFormValue.dataType === '2' &&
      getFormValue?.dataPeriod === '2' &&
      getFormValue?.selectedTimePeriod === 'row' &&
      getFormValue?.multipleIndicatrosType === 'row' &&
      getFormValue?.importindicatroType === '2',
    activeActionStep,
  ]);
  useEffect(() => {
    if (
      getFormValue.dataType === '2' &&
      getFormValue?.dataPeriod === '2' &&
      getFormValue?.selectedTimePeriod === 'column' &&
      getFormValue?.multipleIndicatrosType === 'row' &&
      getFormValue?.importindicatroType === '2'
    ) {
      setSteps([1, 2, 3, 4, 5, 6, 7]);
    }
  }, [
    getFormValue.dataType === '2' &&
      getFormValue?.dataPeriod === '2' &&
      getFormValue?.selectedTimePeriod === 'column' &&
      getFormValue?.multipleIndicatrosType === 'row' &&
      getFormValue?.importindicatroType === '2',
    activeActionStep,
  ]);

  useEffect(() => {
    if (
      getFormValue.dataType === '2' &&
      getFormValue?.dataPeriod === '2' &&
      getFormValue?.multipleIndicatrosType === 'column' &&
      getFormValue?.importindicatroType === '2'
    ) {
      setSteps([1, 2, 3, 4, 5, 6, 7]);
    }
  }, [
    getFormValue.dataType === '2' &&
      getFormValue?.dataPeriod === '2' &&
      getFormValue?.multipleIndicatrosType === 'column' &&
      getFormValue?.importindicatroType === '2',
    activeActionStep,
  ]);

  useEffect(() => {
    dispatch(stepsLength(steps.length));
  }, [steps]);

  return (
    <Flex flexDirection={'column'}>
      <Flex
        width={'fit-content'}
        align={steps.length > 3 ? 'start' : 'center'}
        pt={3}
        pb={5}
        flexDirection={steps.length > 3 && isMobile ? 'column' : 'row'}
      >
        <Text me={3} mb={1}>
          Step {activeActionStep + 1}
        </Text>
        <Stepper display={'flex'} index={activeActionStep} color={'white'}>
          {steps.map((step, index) => (
            <Step key={index}>
              <Flex display='flex' alignItems='center' mt={'2px'}>
                <StepIndicator
                  bgColor={'#4BB6B9'}
                  borderRadius={'50%'}
                  height={'21px'}
                  width={'22px'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <StepStatus
                    complete={<StepIcon width={'1rem'} height={'1rem'} />}
                    incomplete={
                      <Icon
                        viewBox='0 0 200 200'
                        color='white'
                        border='1px solid #9C9BBD'
                        borderRadius='50%'
                        height={'21px'}
                        width={'22px'}
                      >
                        <path fill='#fff' d='M0,0 L0,200 L200,200 L200,0 Z' />
                      </Icon>
                    }
                    active={<CheckIcon width={'0.8rem'} height={'0.8rem'} />}
                  />
                </StepIndicator>
                {index !== steps.length - 1 && (
                  <StepSeparator>
                    <Flex
                      // style={{
                      border='1px solid #9C9BBD'
                      width={activeActionStep > 4 ? '20px' : '30px'}
                      height='1px'
                      color='black'
                      // {/* }} */ }
                    />
                  </StepSeparator>
                )}
              </Flex>
            </Step>
          ))}
        </Stepper>
      </Flex>
      <Flex flexDirection={'column'}>
        <VStack w={'full'} pt={5} px={7} bg={'white'} mt={2} borderRadius={'11px'}>
          {/* <Flex justifyContent={'space-between'} w={'full'} align={'center'}>
            <Text
              color={'#6562CF'}
              fontSize='lg'
              fontFamily={'regular_roboto'}
              fontWeight={'400'}
              textAlign={'start'}
            >
              {activeActionStep === 0
                ? 'Name your action'
                : activeActionStep === 1
                ? 'Select data type'
                : 'Select properties'}
            </Text>
            <CloseIcon
              color={'black'}
              width={3}
              height={3}
              cursor={'pointer'}
              onClick={async () => {
                setactionCreate(false);
                await dispatch(setActionCreateForm(false));
                await dispatch(clearStepperFormData());
                await dispatch(setClearSelectedAreasTable());
              }}
            />
          </Flex> */}

          {activeActionStep === 0 ? (
            <ActionStepOne
              setHelpModalSeleceted={setHelpModalSeleceted}
              setactionCreate={setactionCreate}
            />
          ) : activeActionStep === 1 ? (
            <ActionStepTwo setHelpModalSeleceted={setHelpModalSeleceted} />
          ) : getFormValue?.dataType === '1' && activeActionStep === 2 ? (
            <ActionStepThree setHelpModalSeleceted={setHelpModalSeleceted} />
          ) : (
            <DataStepsForm setHelpModalSeleceted={setHelpModalSeleceted} />
          )}
        </VStack>
        <HelpImportModal
          setHelpModalSeleceted={setHelpModalSeleceted}
          helpModalSeleceted={helpModalSeleceted}
        />
      </Flex>
    </Flex>
  );
};

export default ActionCreateForm;
