import { Icon, StyleProps } from '@chakra-ui/react';

interface Properties {
  width?: number | string;
  height?: number | string;
  color?: string;
  onClick?: () => void;
  styles?: StyleProps;
}

const Logo = ({ width = 15, height = 5, color = '#6561A9', onClick, styles }: Properties) => {
  return (
    <Icon
      viewBox='0 0 93 30'
      width={width}
      height={height}
      color={color}
      onClick={onClick}
      {...styles}
    >
      <path
        d='M32.1526 29.3217C30.3099 29.3217 28.6439 28.8996 27.1547 28.0553C25.6921 27.237 24.4751 26.0474 23.6284 24.6086C22.7657 23.1555 22.3108 21.5142 22.2638 19.6847V2.69713C22.2638 2.36938 22.3641 2.11139 22.5646 1.92315C22.7716 1.73376 23.0454 1.63287 23.3268 1.64233C23.6576 1.64233 23.9176 1.73594 24.1068 1.92315C24.296 2.11036 24.3903 2.36836 24.3898 2.69713V13.6706C25.146 12.4519 26.2033 11.4789 27.5618 10.7516C28.9202 10.0243 30.4502 9.66089 32.1518 9.66141C34.0421 9.66141 35.7376 10.0892 37.2381 10.9447C38.7141 11.7768 39.9384 12.9861 40.7838 14.4468C41.646 15.924 42.0771 17.6005 42.0771 19.4762C42.0771 21.3519 41.646 23.0342 40.7838 24.5232C39.9427 25.9907 38.718 27.2057 37.2389 28.0399C35.7389 28.895 34.0434 29.3222 32.1526 29.3217ZM32.1526 27.3521C33.6413 27.3521 34.9705 27.0062 36.1402 26.3143C37.2994 25.6335 38.2587 24.6635 38.923 23.5007C39.6073 22.3174 39.9498 20.9751 39.9503 19.4739C39.9508 17.9726 39.6084 16.6359 38.923 15.4639C38.2544 14.3091 37.2957 13.3458 36.1402 12.6681C34.97 11.9762 33.6408 11.6302 32.1526 11.6302C30.6872 11.6302 29.3639 11.9762 28.1828 12.6681C27.021 13.3385 26.0598 14.3034 25.3978 15.4639C24.7258 16.6375 24.3898 17.9741 24.3898 19.4739C24.3898 20.9736 24.7258 22.3159 25.3978 23.5007C26.0545 24.6688 27.0153 25.6404 28.1797 26.3143C29.3634 27.0062 30.6877 27.3521 32.1526 27.3521Z'
        fill='currentColor'
      />
      <path
        d='M51.2944 29.1101C50.4379 29.1231 49.5996 28.8644 48.9017 28.3716C48.2044 27.8792 47.6617 27.1993 47.2735 26.332C46.8853 25.4647 46.6904 24.4565 46.6888 23.3076V2.69715C46.6888 2.3694 46.7894 2.11141 46.9905 1.92317C47.1971 1.73368 47.4707 1.63276 47.7518 1.64235C48.0827 1.64235 48.3427 1.73596 48.5319 1.92317C48.721 2.11038 48.8149 2.36837 48.8133 2.69715V23.3076C48.8133 24.3847 49.0436 25.2697 49.5041 25.9627C49.9647 26.6556 50.5615 27.0016 51.2944 27.0006H52.1806C52.3014 26.9973 52.4213 27.0209 52.5317 27.0694C52.6421 27.118 52.7401 27.1904 52.8188 27.2814C52.9831 27.4699 53.0669 27.7276 53.0669 28.0554C53.0761 28.3347 52.9742 28.6063 52.7831 28.8116C52.5939 29.0112 52.3339 29.1107 52.0031 29.1101H51.2944Z'
        fill='currentColor'
      />
      <path
        d='M64.7615 29.3217C62.8702 29.3217 61.1747 28.8937 59.6752 28.0377C58.196 27.2036 56.9712 25.9886 56.1303 24.5209C55.2681 23.0335 54.837 21.3629 54.837 19.5093C54.837 17.6336 55.2681 15.9512 56.1303 14.4623C56.9713 12.9945 58.1961 11.7793 59.6752 10.9447C61.1742 10.0892 62.8697 9.66144 64.7615 9.66144C66.6534 9.66144 68.3429 10.0892 69.83 10.9447C71.3013 11.7833 72.519 12.998 73.3563 14.4623C74.219 15.9497 74.6503 17.632 74.6503 19.5093C74.6503 21.3609 74.219 23.0314 73.3563 24.5209C72.5191 25.9851 71.3014 27.1996 69.83 28.0377C68.3413 28.8942 66.6518 29.3223 64.7615 29.3217ZM64.7615 27.3522C66.2502 27.3522 67.5794 27.0062 68.7491 26.3143C69.9056 25.6362 70.8596 24.6652 71.5133 23.5007C72.1852 22.3175 72.5212 20.9752 72.5212 19.4739C72.5212 17.9967 72.1852 16.666 71.5133 15.4817C70.8592 14.3175 69.9053 13.3466 68.7491 12.6681C67.5789 11.9762 66.2497 11.6302 64.7615 11.6302C63.2961 11.6302 61.9728 11.9762 60.7917 12.6681C59.625 13.3451 58.659 14.3157 57.9912 15.4817C57.3058 16.6655 56.9633 18.008 56.9638 19.5093C56.9638 20.9859 57.3063 22.3164 57.9912 23.5007C58.6586 24.6671 59.6247 25.6377 60.7917 26.3143C61.9728 27.0062 63.2961 27.3522 64.7615 27.3522Z'
        fill='currentColor'
      />
      <path
        d='M77.309 29.1101C77.1722 29.1162 77.0355 29.0944 76.9075 29.0461C76.7795 28.9978 76.6628 28.924 76.5646 28.8293C76.3773 28.6469 76.2636 28.4028 76.2452 28.143C76.2341 28.0071 76.2504 27.8703 76.2929 27.7406C76.3355 27.6109 76.4036 27.4909 76.4933 27.3875L83.3699 18.9091L84.6104 20.4571L78.0533 28.7924C77.9117 29.0042 77.6636 29.1101 77.309 29.1101ZM91.6638 29.1101C91.38 29.1101 91.1554 29.0588 90.99 28.9562C90.7869 28.8152 90.6076 28.643 90.4589 28.4462L84.3623 20.4271L83.4761 19.7554L77.2733 11.631C77.1836 11.5276 77.1155 11.4076 77.0729 11.2779C77.0304 11.1482 77.0141 11.0114 77.0252 10.8754C77.0462 10.6113 77.1588 10.3625 77.3439 10.1715C77.4396 10.0723 77.5554 9.99437 77.6837 9.94291C77.812 9.89145 77.9499 9.86761 78.0882 9.87296C78.2331 9.87005 78.377 9.8966 78.5112 9.95098C78.6453 10.0054 78.7668 10.0864 78.8682 10.1892L84.7896 17.9967L85.7471 18.7353L92.3043 27.3875C92.6103 27.7624 92.7224 28.0789 92.6407 28.3369C92.5709 28.5781 92.4131 28.7849 92.198 28.917C91.9825 29.0463 91.8057 29.1101 91.6638 29.1101ZM85.2835 19.8955L84.008 18.3483L90.4589 10.5054C90.6169 10.3206 90.802 10.1603 91.0079 10.0299C91.1729 9.92803 91.3632 9.87344 91.5576 9.87219C91.8879 9.87219 92.136 9.9717 92.3019 10.1707C92.4665 10.3686 92.5544 10.6182 92.55 10.8747C92.5473 11.1517 92.4469 11.419 92.2663 11.6302L85.2835 19.8955Z'
        fill='currentColor'
      />
      <path
        d='M0.222168 0.444458H5.95511C7.03367 0.444458 8.06805 0.869605 8.83071 1.62637C9.59336 2.38314 10.0218 3.40954 10.0218 4.47977V10.1815H0.222168V0.444458Z'
        fill='#6FBB78'
      />
      <path d='M10.0218 10.1653H0.222168V19.8893H10.0218V10.1653Z' fill='#3BB8BC' />
      <path
        d='M10.0024 10.1653H14.8507C15.5009 10.1653 16.1448 10.2924 16.7455 10.5393C17.3463 10.7862 17.8921 11.1481 18.3519 11.6044C18.8116 12.0606 19.1764 12.6022 19.4252 13.1983C19.674 13.7944 19.8021 14.4333 19.8021 15.0785V19.8893H10.0024V10.1653Z'
        fill='#5F93CE'
      />
      <path
        d='M0.222168 19.8855H10.0218V29.6095H5.21465C4.55914 29.6098 3.90999 29.4819 3.30428 29.2332C2.69858 28.9845 2.1482 28.6198 1.68457 28.16C1.22095 27.7002 0.853167 27.1542 0.602243 26.5533C0.351319 25.9524 0.222168 25.3083 0.222168 24.6579L0.222168 19.8878V19.8855Z'
        fill='#5F93CE'
      />
      <path
        d='M10.0024 19.8855H19.8021V24.7848C19.8021 26.0644 19.2898 27.2916 18.378 28.1964C17.4661 29.1012 16.2294 29.6095 14.9399 29.6095H10.0024V19.8855Z'
        fill='#6561A9'
      />
      <path
        d='M14.8406 10.1661H9.99927V19.844L18.9158 12.8243V12.2857C18.4604 11.6313 17.8515 11.0964 17.1416 10.7272C16.4317 10.3579 15.6421 10.1654 14.8406 10.1661Z'
        fill='url(#paint0_linear_533_2210)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_533_2210'
          x1='9.99927'
          y1='15.0047'
          x2='18.915'
          y2='15.0047'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#537AB7' />
          <stop offset='0.24' stopColor='#5987C3' />
          <stop offset='0.56' stopColor='#5E90CB' />
          <stop offset='1' stopColor='#5F93CE' />
        </linearGradient>
      </defs>
    </Icon>
  );
};

export default Logo;
