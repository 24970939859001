export default {
  base: {
    borderRadius: 'full',
    _checked: {
      _before: {
        content: `""`,
        display: 'inline-block',
        pos: 'relative',
        w: '50%',
        h: '50%',
        borderRadius: 'full',
        bg: 'white.900',
      },
    },
  },
  sizes: {
    main: {
      control: {
        w: 4,
        h: 4,
      },
      label: { fontSize: '2xs' },
    },
  },
  defaultProps: {
    size: 'main',
    colorScheme: 'white.900',
  },
};
