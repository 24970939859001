import { Divider, Flex, Text } from '@chakra-ui/react';

interface Properties {
  text: string;
}

const DividerWithText = ({ text }: Properties) => {
  return (
    <Flex align='center'>
      <Divider my='5' borderColor='grey.900' />
      <Text variant='secondary' mx='2' fontSize='calc(0.5rem + 0.4vw)'>
        {text}
      </Text>
      <Divider my='5' borderColor='grey.900' />
    </Flex>
  );
};

export default DividerWithText;
