import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const EditPencilGreen = ({ width = 5, height = 5, color = 'green.900' }: Properties) => {
  return (
    <Icon viewBox='0 0 21 23' width={width} height={height} color={color}>
      <path
        d='M0.234863 22.2642V18.2642H20.2349V22.2642H0.234863ZM2.23486 16.2642V12.5142L11.2849 3.46418L15.0349 7.21418L5.98486 16.2642H2.23486ZM4.23486 14.2642H5.13486L12.2349 7.21418L11.2849 6.26418L4.23486 13.3642V14.2642ZM16.1599 6.11418L12.4099 2.36418L14.2099 0.564184C14.3932 0.364184 14.6265 0.268351 14.9099 0.276684C15.1932 0.285017 15.4265 0.380851 15.6099 0.564184L17.9599 2.91418C18.1432 3.09752 18.2349 3.32668 18.2349 3.60168C18.2349 3.87668 18.1432 4.11418 17.9599 4.31418L16.1599 6.11418Z'
        fill='#4BB6B9'
      />
    </Icon>
  );
};

export default EditPencilGreen;
