interface Properties {
  width?: number | string;
  height?: number | string;
  color?: string;
  onClick?: () => void;
}

const CloseIconComponent = ({
  width = '35px',
  height = '35px',
  color = '#6561A9',
  onClick,
}: Properties) => {
  return (
    <svg
      onClick={onClick}
      cursor={'pointer'}
      width={width}
      height={height}
      viewBox='0 0 12 13'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.66356 12.3897L0.520508 11.2454L5.09273 6.66836L0.520508 2.09129L1.66356 0.947021L6.23579 5.52409L10.808 0.947021L11.9511 2.09129L7.37884 6.66836L11.9511 11.2454L10.808 12.3897L6.23579 7.81263L1.66356 12.3897Z'
        fill={color}
      />
    </svg>
  );
};

export default CloseIconComponent;
