import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

interface Properties {
  isOpen: boolean;
  onClose: () => void;
  itemName: string;
  onDelete: () => void;
}

const DeleteModal = ({ isOpen, onClose, itemName, onDelete }: Properties) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='xs' blockScrollOnMount={false}>
      <ModalOverlay />
      <ModalContent pb={3} width={62.5}>
        <ModalHeader position='relative'>
          <Text variant='modal' size='subTitle' align='center'>
            Confirm your action
          </Text>
        </ModalHeader>

        <ModalBody>
          <Text variant='modal' size='modalContent'>
            Are you sure you want to delete{' '}
            <Text as='span' size='subTitle'>
              {itemName}
            </Text>
            ?
          </Text>
        </ModalBody>

        <ModalFooter pb='0'>
          <HStack spacing='5'>
            <Button variant='clear' size='auto' fontSize='xs' color='red.500' onClick={onClose}>
              Cancel
            </Button>
            <Button variant='clear' size='auto' fontSize='xs' color='violet.900' onClick={onDelete}>
              Confirm
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteModal;
