/* eslint-disable @typescript-eslint/lines-between-class-members */
/* eslint-disable class-methods-use-this */
import * as React from 'react';
import {
  CellTemplate,
  Cell,
  Compatible,
  Uncertain,
  getCellProperty,
  keyCodes,
} from '@silevis/reactgrid';
import { Box, IconButton } from '@chakra-ui/react';

import AddIcon from 'components/Icons/Add';

export interface FlagCell extends Cell {
  type: 'flag';
  text: string;
  rowId: string;
  showText?: boolean;
  placeholder?: string;
  planPermission?: boolean;
  validator?: (text: string) => boolean;
}
export class AddRowCellTemplate implements CellTemplate<FlagCell> {
  onDeleteRow: (rowId: any) => void;
  planPermission?: boolean;
  constructor(onDeleteRow: (rowId: string) => void, planPermission: boolean) {
    this.onDeleteRow = onDeleteRow;
    this.planPermission = planPermission;
  }
  getCompatibleCell(uncertainCell: Uncertain<FlagCell>): Compatible<FlagCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const value = Number.parseFloat(text);
    return { ...uncertainCell, text, value } as Compatible<FlagCell>;
  }

  handleKeyDown(
    cell: Compatible<FlagCell>,
    keyCode: number,
  ): { cell: Compatible<FlagCell>; enableEditMode: boolean } {
    if (keyCode === keyCodes.ENTER) {
      return { cell, enableEditMode: true };
    }
    return { cell, enableEditMode: false };
  }

  getClassName(cell: Compatible<FlagCell>): string {
    const isValid = cell.validator ? cell.validator(cell.text) : true;
    const className = cell.className || '';
    return `${className} ${isValid ? 'valid' : 'invalid'} ${
      cell.placeholder && cell.text === '' ? 'placeholder' : ''
    }`;
  }

  render(cell: Compatible<FlagCell>) {
    return (
      <Box
        cursor={this.planPermission ? 'not-allowed' : 'pointer'}
        display={'flex'}
        width={'100%'}
        paddingLeft={'11px'}
        onClick={this.planPermission ? undefined : () => this.onDeleteRow(cell)}
      >
        <IconButton
          aria-label='delete model'
          size='ghost'
          variant='ghost'
          cursor={this.planPermission ? 'not-allowed' : 'pointer'}
          icon={
            <AddIcon height={4} width={4} styles={{ filter: 'invert(1)', marginRight: '.4rem' }} />
          }
        />
        {cell.showText && 'Add indicator'}
      </Box>
    );
  }
}
