import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalHeader,
  Text,
  Box,
  Flex,
  IconButton,
  useMediaQuery,
} from '@chakra-ui/react';
import { connect } from 'react-redux';

import { SetTabs, UserItems, SetUserModalVisible } from 'redux/UserSlice';
import Cross from 'components/Icons/Cross';
import BillingIcon from 'components/Icons/BillingIcon';
import OrganisationIcon from 'components/Icons/OrganisationIcon';
import TeamIcon from 'components/Icons/TeamIcon';
import UserIcon from 'components/Icons/UserIcon';
import UserPermissions from 'utils/UserPermissions';

import UserTeamsComponent from './UserTeams';
import AddTeamMemberComponent from './AddTeamMember';
import OrganisationForm from './OrganisationForm';
import ProfileForm from './ProfileForm';
import UserBillingComponent from './UserBilling';
import UserPlansComponent from './UserPlans';

interface Properties {
  setUserModalVisible: (status: boolean) => void;
  userTabs: number;
  isUserModalVisible: boolean;
  setUserTabs: (parameters: number) => void;
  userManagementItems: UserItems[];
}
const UserMobileLayoutModal = ({
  userTabs,
  isUserModalVisible,
  setUserTabs,
  userManagementItems,
  setUserModalVisible,
}: Properties) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <Modal
      isOpen={isUserModalVisible}
      onClose={() => {
        setUserModalVisible(false);
      }}
    >
      <ModalOverlay bg='none' backdropFilter='auto' backdropInvert='3%' backdropBlur='4px' />
      <ModalContent
        padding={'2rem 1.5rem 1rem 1.5rem'}
        color='black.900'
        width={'90%'}
        height='80%'
        overflow={'hidden'}
      >
        {userTabs === 0 ? (
          <>
            <ModalHeader padding={'0'}>
              <Flex justifyContent='space-between' alignItems='center'>
                <Text
                  fontFamily='Comfortaa bold'
                  color='violet.700'
                  fontSize={isMobile ? '22px' : '30px'}
                  top='0'
                >
                  Settings
                </Text>
                <Cross
                  width={8}
                  height={8}
                  bgColor='black.900'
                  onClick={() => {
                    setUserModalVisible(false);
                  }}
                />
              </Flex>
            </ModalHeader>
            <ModalBody padding='0' overflow={'scroll'}>
              <Box>
                {userManagementItems.map((data: UserItems, index: number) => {
                  return (
                    <UserPermissions scopes={data?.tabRoles} permissionType='user' key={data?.id}>
                      <Flex
                        padding='1rem 0'
                        key={index}
                        cursor='pointer'
                        width='100%'
                        onClick={() => {
                          setUserTabs(index + 1);
                        }}
                      >
                        <IconButton
                          size='xs'
                          icon={
                            index === 0 ? (
                              <UserIcon height={25} width={25} color='#6564C8' />
                            ) : index === 1 ? (
                              <OrganisationIcon height={25} width={25} color='#6564C8' />
                            ) : index === 2 ? (
                              <TeamIcon height={25} width={25} color='#6564C8' />
                            ) : (
                              <BillingIcon height={25} width={25} color='#6564C8' />
                            )
                          }
                          aria-label='users'
                          border={'none'}
                        />
                        <Flex flexDir='column'>
                          <Text ml='1.3rem' color={userTabs === index + 1 ? '#6564C8' : 'black'}>
                            {data.name}
                          </Text>
                          <Text ml='1.3rem' color='grey.100'>
                            {data.description}
                          </Text>
                        </Flex>
                      </Flex>
                    </UserPermissions>
                  );
                })}
              </Box>
            </ModalBody>
          </>
        ) : (
          <Box width={'100%'} height={'100%'}>
            {!isMobile && (
              <Box position={'absolute'} right='10px'>
                <Cross
                  width={8}
                  height={8}
                  bgColor='black.900'
                  onClick={() => {
                    setUserModalVisible(false);
                  }}
                />
              </Box>
            )}
            {userTabs === 1 ? (
              <ProfileForm />
            ) : userTabs === 2 ? (
              <OrganisationForm />
            ) : userTabs === 3 ? (
              <UserTeamsComponent type={1} />
            ) : userTabs === 4 ? (
              <UserBillingComponent />
            ) : userTabs === 5 ? (
              <UserPlansComponent />
            ) : userTabs === 8 ? (
              <AddTeamMemberComponent />
            ) : userTabs === 9 ? (
              <UserTeamsComponent type={2} />
            ) : (
              ''
            )}
          </Box>
        )}
      </ModalContent>
    </Modal>
  );
};

const stateToProperty = (state: any) => {
  return {
    userTabs: state.userSlice.userTabs,
    isUserModalVisible: state.userSlice.isUserModalVisible,
    userManagementItems: state.userSlice.userManagementItems,
  };
};

const mapDispatchToProperties = (dispatch: any) => {
  return {
    setUserTabs: (data: any) => dispatch(SetTabs(data)),
    setUserModalVisible: (data: any) => dispatch(SetUserModalVisible(data)),
  };
};

export default connect(stateToProperty, mapDispatchToProperties)(UserMobileLayoutModal);
