import { Flex, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Container from 'components/Container';
import Logo from 'components/Icons/Logo';
import Spinner from 'components/Spinner';

const WelcomePage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      navigate('/home');
    }, 2000);
  }, []);

  return (
    <Container>
      <Flex h='full' direction='column' justify='center' align='center'>
        <Logo color='white.900' width='34vw' height='auto' />

        <Text fontFamily='regular' fontSize='calc(.5rem + 1vw)' letterSpacing='-0.015em' pt={5}>
          Thanks for signing up!
        </Text>
        <Text fontFamily='regular' fontSize='calc(.5rem + 1vw)' letterSpacing='-0.015em' pt={2}>
          We're getting you set up...
        </Text>
        <Spinner styleProperties={{ my: '4vh' }} />
      </Flex>
    </Container>
  );
};
export default WelcomePage;
