export default {
  variants: {
    solid: {
      container: {
        justifyContent: 'center',
        borderWidth: 1,
        borderRadius: 'lg',
        mr: 1,
        fontSize: '2xs',
        color: 'white.900',
      },
      closeButton: { fontSize: '2xs' },
    },
  },
  sizes: {
    main: {
      container: {
        minW: 17.5,
        h: 8.5,
        mb: 1,
      },
    },
  },
  defaultProps: {
    size: 'main',
    variant: 'solid',
  },
};
