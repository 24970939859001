interface Properties {
  width?: number;
  height?: number;
  color?: string;
  onClick?: () => void;
}

const DeleteIcon = ({ width = 4.75, height = 4.75, color = 'white.900', onClick }: Properties) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 15 20'
      fill={color}
      cursor={'pointer'}
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1.32966 17.3285C1.32966 18.4796 2.27146 19.4214 3.42256 19.4214H11.7942C12.9453 19.4214 13.8871 18.4796 13.8871 17.3285V4.77109H1.32966V17.3285ZM14.9335 1.63173H11.271L10.2245 0.585281H4.99224L3.94579 1.63173H0.283203V3.72464H14.9335V1.63173Z'
        fill={color}
      />
    </svg>
  );
};

export default DeleteIcon;
