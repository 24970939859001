/* eslint-disable no-restricted-syntax */
import { CheckCircleIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  Flex,
  ListItem,
  Text,
  UnorderedList,
  useOutsideClick,
} from '@chakra-ui/react';
import DOMPurify from 'dompurify';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import FunctionIconComponent from 'components/Icons/FunctionIcon';
import {
  FetchBlockOutputs,
  getBaseScenario,
  getFormulaList,
  getPlannerBlock,
  getSelectedIndicators,
  UpdateIndicatorFormula,
} from 'redux/PlannerModeSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

const AutoComplete2 = () => {
  const { blockId } = useParams();
  const fieldReference = useRef<HTMLInputElement>(null);
  const errorReference: any = useRef(null);
  const formulaListReference: any = useRef(null);
  const selectedReference: any = useRef(null);
  const errorModalReference: any = useRef(null);
  const dispatch: AppDispatch = useDispatch();

  const formulaList = useSelector(getFormulaList);
  const baseScenario = useSelector(getBaseScenario);
  const plannerBlock = useSelector(getPlannerBlock);
  const selectedIndicators = useSelector(getSelectedIndicators);

  const [filterValue, setfilterValue]: any = useState(null);
  const [message, setMessage]: any = useState({ type: '', text: '', errorStatus: false });
  const [hover, setHover] = useState(false);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [errorHeight, setErrorHeight]: any = useState(
    `${errorReference?.current ? errorReference.current.getBoundingClientRect().top - 45 : 0}px`,
  );

  const [formula] = useState('');

  const [selected, setSelected] = useState<number | null>(null);

  const moveCaretToEnd = () => {
    const element: any = document.querySelector('.formula-editor');
    const selection = window.getSelection();
    if (element && selection) {
      const range = document.createRange();
      range.selectNodeContents(element);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
      element.focus();
    }
  };

  const moveCaretToStart = () => {
    const element: any = document.querySelector('.formula-editor');
    const selection = window.getSelection();
    if (element && selection) {
      const range = document.createRange();
      range.selectNodeContents(element);
      range.collapse(true);
      selection.removeAllRanges();
      selection.addRange(range);
      element.focus();
    }
  };

  useOutsideClick({
    ref: formulaListReference,
    handler: () => {
      if (formulaListReference?.current && isOptionsVisible) setIsOptionsVisible(false);
    },
  });

  // It builds initial html from selected indicator's value.
  useEffect(() => {
    if (fieldReference.current) {
      fieldReference.current.innerHTML = selectedIndicators.calculated_formula?.html_formula
        ? `<div class="formula-container">${selectedIndicators.calculated_formula.html_formula}</div>`
        : `<div class="formula-container">&nbsp;</div>`;
      setTimeout(() => {
        moveCaretToEnd();
      }, 100);
    }
  }, [selectedIndicators]);

  useEffect(() => {
    const shouldVisible = Boolean(filterValue);
    setIsOptionsVisible(shouldVisible);
  }, [filterValue, fieldReference?.current?.textContent]);

  const handleFormulaBlur = () => {
    if (message.type === 'success') {
      setMessage({ type: '', text: '', errorStatus: false });
    } else if (message.type === 'error') {
      setMessage({ type: 'error', text: message.text, errorStatus: false });
    }
  };

  const selectOption = (optionName: string, optionHTML: string) => {
    const formulaArray = fieldReference?.current?.innerHTML
      ? fieldReference?.current?.innerHTML.split(' ')
      : [];
    const slicedData = formulaArray.splice(-1, 1, optionHTML);
    if (slicedData.some((element) => element.includes('formula-container'))) {
      const formulaString = `<div class="formula-container">${optionHTML}</div>`;
      if (fieldReference?.current?.innerHTML) {
        fieldReference.current.innerHTML = formulaString;
        setTimeout(() => {
          moveCaretToEnd();
        }, 0);
      }
    } else {
      const formulaString = formulaArray
        .map((element: any) => {
          if (element === optionName) {
            return optionHTML;
          }
          return element;
        })
        .join(' ');
      if (fieldReference?.current?.innerHTML) {
        fieldReference.current.innerHTML = formulaString;
        setTimeout(() => {
          moveCaretToEnd();
        }, 0);
      }
    }
    setfilterValue('');
  };
  const findNextEditableTextNode = (node: any) => {
    while (node) {
      if (node.nodeType === Node.TEXT_NODE && node!.textContent!.trim().length > 0) {
        return node;
      }
      if (node.nodeType === Node.ELEMENT_NODE && (node as HTMLElement).isContentEditable) {
        return node;
      }
      node = node!.nextSibling;
    }
    return null;
  };

  const setCaret = (node: any, range: any) => {
    // eslint-disable-next-line unicorn/prefer-switch
    if (node.nodeType === 3) {
      if (node.nodeType === Node.TEXT_NODE) {
        range.setStart(node, 0);
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const { firstChild } = node;
        if (firstChild && firstChild.nodeType === Node.TEXT_NODE) {
          range.setStart(firstChild, 0);
        } else {
          range.setStart(node, 0);
        }
      }
    } else if (node.nodeType === Node.TEXT_NODE) {
      range.setStart(node, (node as Text).length);
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      const textNode = document.createTextNode('');
      node.append(textNode);
      range.setStart(textNode, 0);
    }
  };

  const handleClick = (event: any) => {
    if (!event.target.isContentEditable) {
      const parent = event.target.closest('.formula-editor');
      if (parent) {
        const { lastChild } = parent;
        const range = document.createRange();
        const selection = window.getSelection();

        let nextNode = findNextEditableTextNode(event.target.nextSibling);

        if (!nextNode) {
          let current = event.target.parentNode;
          while (current && !nextNode) {
            nextNode = findNextEditableTextNode(current.nextSibling);
            current = current.parentNode;
          }
        }

        if (nextNode) {
          setCaret(nextNode, range);
        } else {
          setCaret(lastChild, range);
        }

        range.collapse(true);
        selection!.removeAllRanges();
        selection!.addRange(range);
      }
    }
  };

  const navigate = (event: any, index: number, selectdOption: any) => {
    if (event.key === 'ArrowDown' && event.currentTarget?.nextSibling) {
      event.preventDefault();
      if (event?.currentTarget?.nextSibling?.className?.includes('formula-list-ignore')) {
        (event.currentTarget?.nextSibling?.nextSibling?.nextSibling as HTMLElement).focus();
      } else {
        (event.currentTarget?.nextSibling as HTMLElement).focus();
      }
    }
    if (event.key === 'ArrowUp' && event.currentTarget?.previousSibling) {
      event.preventDefault();
      if (event.currentTarget?.previousSibling === formulaListReference?.current?.childNodes[0]) {
        fieldReference?.current?.focus();
        setSelected(null);
      } else if (
        event?.currentTarget?.previousSibling?.className?.includes('formula-list-ignore')
      ) {
        (
          event.currentTarget?.previousSibling?.previousSibling?.previousSibling as HTMLElement
        ).focus();
      } else {
        (event.currentTarget?.previousSibling as HTMLElement).focus();
      }
    }
    if (event.key === 'Enter' || event.key === 'Tab') {
      if (selectdOption?.name) {
        event.preventDefault();
        selectOption(selectdOption?.name, selectdOption?.html);
        setSelected(null);
      }
      if (index === selected) {
        setSelected(null);
      } else {
        setSelected(index);
      }
    }
  };

  const getIndicatorsInCurrentBlock: any = useMemo(() => {
    const currentBlocksIndicator = formulaList?.blocks?.filter(
      (item: any) => item?.id === Number(blockId),
    );
    const filteredIndicators = currentBlocksIndicator[0]?.indicators?.reduce(
      (accumulator: any, option: any) => {
        if (option?.name?.toLowerCase()?.includes(filterValue?.toLowerCase())) {
          const html = `<span contentEditable="false"><span class="formula-hidden">'</span><span class="formula formula-indicator">${option?.name?.trim()}</span><span class="formula-hidden">'</span></span>`;
          accumulator.push(
            <ListItem
              px={3}
              py={1}
              ref={selected === option?.id ? selectedReference : null}
              onClick={() => selectOption(option?.name, html)}
              onKeyDown={(event) =>
                navigate(event, option?.id, {
                  name: option?.name,
                  html,
                })
              }
              // onMouseEnter={(event) => (event.currentTarget as HTMLElement).focus()}
              // onMouseLeave={(event) => (event?.currentTarget as HTMLElement).blur()}
              tabIndex={selected === null ? 0 : -1}
              cursor={'pointer'}
              key={option?.id}
              listStyleType={'none'}
            >
              <Flex width={'100%'} maxW={'100%'}>
                <span className='formula formula-indicator' />
                <Text width={'100%'} maxW={'100%'} whiteSpace={'nowrap'} textOverflow={'ellipsis'}>
                  {option?.name}
                </Text>
              </Flex>
            </ListItem>,
          );
        }
        return accumulator;
      },
      [],
    );

    return (
      <>
        {filteredIndicators?.length > 0 && (
          <>
            <Text
              className='formula-list-ignore'
              fontSize={'14px'}
              fontWeight={'600'}
              mb={4}
              px={3}
            >
              Indicators in this block
            </Text>
            {filteredIndicators}
          </>
        )}
      </>
    );
  }, [filterValue]);

  const getIndicatorsInOthersBlock: any = useMemo(() => {
    const othersBlocksIndicator = formulaList?.blocks?.filter(
      (item: any) => item?.id !== Number(blockId),
    );
    const otherBlockIndicators: any[] = [];
    othersBlocksIndicator?.forEach((data: any) => {
      if (data?.indicators?.length) {
        const listItems = data?.indicators?.reduce((accumulator: any, option: any) => {
          if (
            option?.name?.toLowerCase()?.includes(filterValue?.toLowerCase()) ||
            data?.name?.toLowerCase()?.includes(filterValue?.toLowerCase())
          ) {
            const html = `<span contentEditable="false"><span class="formula-hidden">'</span><span class="formula formula-block">${data?.name}</span><span class="formula-hidden">'</span><span class="formula-hidden">.</span><span class="formula-hidden">'</span><span class="formula formula-indicator">${option?.name}</span><span class="formula-hidden">'</span></span>`;
            accumulator.push(
              <ListItem
                px={3}
                py={1}
                ref={selected === option?.id ? selectedReference : null}
                onClick={() => selectOption(`${data?.name}${option?.name}`, html)}
                onKeyDown={(event) =>
                  navigate(event, option?.id, {
                    name: `${data?.name}${option?.name}`,
                    html,
                  })
                }
                // onMouseEnter={(event) => (event.currentTarget as HTMLElement).focus()}
                // onMouseLeave={(event) => (event?.currentTarget as HTMLElement).blur()}
                tabIndex={selected === null ? 0 : -1}
                key={option?.id}
                listStyleType={'none'}
                cursor={'pointer'}
              >
                <Flex width={'100%'} maxW={'100%'}>
                  <span className='formula formula-block' />
                  <Text className='text-overflow' mr={2} maxW={'50%'}>
                    {data?.name}
                  </Text>
                  <span className='formula formula-indicator' />
                  <Text className='text-overflow' maxW={'50%'}>
                    {option?.name}
                  </Text>
                </Flex>
              </ListItem>,
            );
          }
          return accumulator;
        }, []);

        otherBlockIndicators.push(...listItems);
      }
    });
    return (
      <>
        {otherBlockIndicators?.length > 0 && (
          <>
            {getIndicatorsInCurrentBlock?.props?.children?.props?.children[1]?.length > 0 && (
              <Divider borderColor={'#EFEEFD'} className='formula-list-ignore' my={4} />
            )}
            <Text
              className='formula-list-ignore'
              fontSize={'14px'}
              fontWeight={'600'}
              mb={4}
              px={3}
            >
              Indicators in other blocks
            </Text>
            {otherBlockIndicators}
          </>
        )}
      </>
    );
  }, [filterValue]);

  const getDimensions: any = useMemo(() => {
    const dimensionalArray: any[] = [];
    formulaList?.dimensions?.forEach((data: any) => {
      if (data?.properties?.length) {
        const listItems = data?.properties?.reduce((accumulator: any, option: any) => {
          if (
            option?.name?.toLowerCase()?.includes(filterValue?.toLowerCase()) ||
            data?.name?.toLowerCase()?.includes(filterValue?.toLowerCase())
          ) {
            const html = `<span contentEditable="false"><span class="formula-hidden">'</span><span class="formula formula-dimension">${data?.name}</span><span class="formula-hidden">'</span><span class="formula-hidden">.</span><span class="formula-hidden">'</span><span class="formula formula-property">${option?.name}</span><span class="formula-hidden">'</span></span>`;
            accumulator.push(
              <ListItem
                px={3}
                py={1}
                ref={selected === option?.id ? selectedReference : null}
                onClick={() => selectOption(`${(data?.name, option?.name)}`, html)}
                onKeyDown={(event) =>
                  navigate(event, option?.id, {
                    name: `${(data?.name, option?.name)}`,
                    html,
                  })
                }
                // onMouseEnter={(event) => (event.currentTarget as HTMLElement).focus()}
                // onMouseLeave={(event) => (event?.currentTarget as HTMLElement).blur()}
                tabIndex={selected === null ? 0 : -1}
                key={option?.id}
                listStyleType={'none'}
                cursor={'pointer'}
              >
                <Flex width={'100%'} maxW={'100%'}>
                  <span className='formula formula-dimension' />
                  <Text className='text-overflow' mr={2} maxW={'50%'}>
                    {data?.name}
                  </Text>
                  <span className='formula formula-property' />
                  <Text className='text-overflow' mr={2}>
                    {option?.name}
                  </Text>
                </Flex>
              </ListItem>,
            );
          }
          return accumulator;
        }, []);

        dimensionalArray.push(...listItems);
      }
    });
    return (
      <>
        {dimensionalArray?.length > 0 && (
          <>
            {getIndicatorsInOthersBlock?.props?.children?.props?.children[2]?.length > 0 && (
              <Divider borderColor={'#EFEEFD'} className='formula-list-ignore' my={4} />
            )}
            <Text
              className='formula-list-ignore'
              fontSize={'14px'}
              fontWeight={'600'}
              mb={4}
              px={3}
            >
              Dimensions
            </Text>
            {dimensionalArray}
          </>
        )}
      </>
    );
  }, [filterValue]);

  const getFunctions: any = useMemo(() => {
    const functionItems = formulaList?.functions?.reduce(
      (accumulator: any, option: any, index: number) => {
        if (option?.toLowerCase()?.includes(filterValue?.toLowerCase())) {
          const html = `<span class="formula formula-function"><span class="formula-functionName" contentEditable="false">${option}</span>(&nbsp;</span>`;
          accumulator.push(
            <ListItem
              px={3}
              ref={selected === option ? selectedReference : null}
              onClick={() => selectOption(option, html)}
              cursor={'pointer'}
              onKeyDown={(event) =>
                navigate(event, option?.id, {
                  name: option,
                  html,
                })
              }
              // onMouseEnter={(event) => (event.currentTarget as HTMLElement).focus()}
              // onMouseLeave={(event) => (event?.currentTarget as HTMLElement).blur()}
              tabIndex={selected === null ? 0 : -1}
              key={index}
              display={'flex'}
              justifyContent={'space-between'}
              listStyleType={'none'}
            >
              <Text className='formula formula-fuctionName-dropdown' px={3}>
                {option}
              </Text>
            </ListItem>,
          );
        }
        return accumulator;
      },
      [],
    );

    return (
      <>
        {functionItems?.length > 0 && (
          <>
            {getDimensions?.props?.children?.props?.children[2]?.length > 0 && (
              <Divider borderColor={'#EFEEFD'} className='formula-list-ignore' my={4} />
            )}
            <Text
              className='formula-list-ignore'
              fontSize={'14px'}
              fontWeight={'600'}
              mb={4}
              px={3}
            >
              Functions
            </Text>
            {functionItems}
          </>
        )}
      </>
    );
  }, [filterValue]);

  // Handling changes in formula-bar, calls on every change in input field
  const handleInputChange = () => {
    if (message.type === 'success') {
      setMessage({ type: '', text: '', errorStatus: false });
    } else if (message.type === 'error') {
      setMessage({ type: 'error', text: message.text, errorStatus: false });
    }

    const value = fieldReference?.current?.innerText;

    // If current types letter is the last in the formula, then only formula list will appear.
    const lastTypedIndicator = value?.match(/\b(\w+)$/g);
    setfilterValue(lastTypedIndicator ? lastTypedIndicator[0] : '');
  };

  // It fetches Indicator data after submitting formula.
  const fetchIndicatorsData = () => {
    const idArray = plannerBlock?.dimensions
      .filter((item: any) => item.name !== 'Time')
      .map((item: any) => item.id);
    const dimensionIds = idArray.join(', ');
    const payload = {
      blockId,
      params: {
        dim_id: dimensionIds,
        indicator_filter: 'all',
        scenario_id: baseScenario?.id,
      },
    };
    dispatch(FetchBlockOutputs(payload));
  };

  const removeSpaceAfterFormula = (payload: string) => {
    for (const formulas of formulaList.functions) {
      const formulaIndex = payload.indexOf(formulas);
      if (formulaIndex !== -1) {
        const openingParenIndex = payload.indexOf('(', formulaIndex);
        if (openingParenIndex + 1 < payload.length && payload[openingParenIndex + 1] === ' ') {
          return payload.slice(0, openingParenIndex + 1) + payload.slice(openingParenIndex + 2);
        }
      }
    }
    return payload; // No change needed
  };

  // It Trims and replaces special characters.
  const replaceStrings = (payload: string | null = '') => {
    if (payload) {
      payload = removeSpaceAfterFormula(payload);
      payload = payload.trim();
      payload = payload.replace(/&nbsp;/g, ' ');
      payload = payload.replace('\\xa0', ' ');
      payload = payload.replace('\u00A0', ' ');
      return payload;
    }
    return '';
  };

  const trimStringOutsideQuotes = (string_: string) => {
    return string_.replace(/'[^']*'|\s+/g, (match) => {
      if (match.startsWith("'")) {
        return match;
      }
      return '';
    });
  };

  const handleFieldChanges = async () => {
    const updatedFormula = replaceStrings(fieldReference?.current?.textContent);

    const indicatorFormula: any = await dispatch(
      UpdateIndicatorFormula({
        indicatorId: selectedIndicators?.id,
        data: {
          formula: trimStringOutsideQuotes(updatedFormula),
        },
      }),
    );
    if (indicatorFormula?.error) {
      fetchIndicatorsData();
      setMessage({ type: 'error', text: indicatorFormula?.payload, errorStatus: true });
    } else {
      fetchIndicatorsData();
      setMessage({ type: 'success', text: 'Formula parsed successfully', errorStatus: false });
    }
    setIsOptionsVisible(false);
  };

  // OnkeyPress operations
  const handleKeyDown = (event: any) => {
    if (event?.key === 'ArrowDown' && formulaListReference?.current?.childNodes[1]) {
      event.preventDefault();
      formulaListReference?.current?.childNodes[1]?.focus();
    }
    // If Enter is clicked from keyboard, It will submit the formula
    if (event?.keyCode === 13) {
      event.preventDefault();
      handleFieldChanges();
    }
    // If Backspace or delete is pressed, Prevent last div in formula-bar
    if (event?.keyCode === 8 || event?.keyCode === 46) {
      const formulaEditor: any = document.querySelector('.formula-editor');
      if (
        formulaEditor.children.length === 1 &&
        (formulaEditor.textContent.trim() === '' || formulaEditor.textContent.trim() === ' ')
      ) {
        event.preventDefault();
      }
    }
    // Handles Pressed Home and End, so that it can move to start and end of field with or without selection
    if (event.key === 'Home') {
      const selection: any = window.getSelection();
      if (!event.shiftKey) {
        event.preventDefault();
        moveCaretToStart();
      } else {
        event.preventDefault();
        selection.modify('extend', 'backward', 'line');
      }
    } else if (event.key === 'End') {
      const selection: any = window.getSelection();
      if (!event.shiftKey) {
        event.preventDefault();
        moveCaretToEnd();
      } else {
        event.preventDefault();
        selection.modify('extend', 'forward', 'line');
      }
    }
  };

  useEffect(() => {
    if ((message?.type === 'error' && message?.errorStatus) || hover) {
      setErrorHeight(
        `${
          errorReference?.current && errorModalReference?.current
            ? errorReference.current.getBoundingClientRect().top -
              errorModalReference.current.getBoundingClientRect().height
            : 0
        }px`,
      );
    }
  }, [message, hover]);

  return (
    <Flex alignItems={'center'} width={'calc(100% - 195px)'} mr={4}>
      <FunctionIconComponent
        color='white'
        style={{ margin: '0 10px 0 15px', height: 30, minWidth: 30 }}
      />
      <Box
        alignItems={'center'}
        position={'relative'}
        width={'calc(100% - 40px)'}
        onClick={(event) => handleClick(event)}
        onBlur={handleFormulaBlur}
      >
        <Box
          className='formula-editor'
          ref={fieldReference}
          width={'100%'}
          overflow={'hidden'}
          maxH={'30px'}
          whiteSpace={'nowrap'}
          scrollBehavior={'smooth'}
          backgroundColor={'white'}
          color={'black'}
          borderRadius={'8px'}
          fontSize={'xs'}
          height={9}
          display={'flex'}
          alignItems={'center'}
          padding={'0 5px'}
          outline={
            message?.type === 'error'
              ? '2px red solid'
              : message?.type === 'success'
              ? '2px green solid'
              : 'none'
          }
          onInput={handleInputChange}
          onKeyDown={(event) => handleKeyDown(event)}
          contentEditable='true'
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(formula) }}
        ></Box>

        {isOptionsVisible && filterValue !== null && (
          <Flex
            width={fieldReference?.current?.getBoundingClientRect().width}
            flexDirection={'column'}
            py={4}
            position={'fixed'}
            top={`${
              fieldReference?.current ? fieldReference.current.getBoundingClientRect().top + 35 : 0
            }px`}
            zIndex={4}
            backgroundColor={'white'}
            borderRadius={'8px'}
            color={'#212121'}
            maxH={'400px'}
            overflowY={'auto'}
            className='formula-dropdown'
          >
            <UnorderedList className='formula-ul' ref={formulaListReference} margin={0}>
              {getIndicatorsInCurrentBlock}
              {getIndicatorsInOthersBlock}
              {getDimensions}
              {getFunctions}
            </UnorderedList>
          </Flex>
        )}
      </Box>

      <Box ref={errorReference} position={'relative'} height={'30px'} width={'30px'}>
        {(message?.type === 'error' || message?.type === 'success') && (
          <Box
            ml={4}
            maxW={'max-content'}
            color={message.type === 'success' ? '#4bb6b9' : 'red'}
            fontSize={'2xs'}
            position={'relative'}
          >
            {message?.type === 'error' && (
              <InfoOutlineIcon
                onMouseOver={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                height={6}
                width={6}
              />
            )}
            {message?.type === 'success' && (
              <CheckCircleIcon height={6} width={6} color={'#4bb6b9'} />
            )}

            {((message?.type === 'error' && message?.errorStatus) || hover) && (
              <Flex
                border={'1px black solid'}
                ref={errorModalReference}
                width={'200px'}
                maxWidth={'200px'}
                backgroundColor={'#ecd3d3'}
                color={'red'}
                position={'fixed'}
                top={errorHeight}
                padding={'.2rem .5rem'}
                borderRadius={'2px'}
                transform={'translateX(-50%)'}
                wordBreak={'break-word'}
                minHeight={'45px'}
              >
                {message.text}
              </Flex>
            )}
          </Box>
        )}
      </Box>
    </Flex>
  );
};

export default AutoComplete2;
