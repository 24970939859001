import {
  Flex,
  Text,
  VStack,
  Image,
  Menu,
  MenuList,
  MenuItem,
  Button,
  MenuButton,
} from '@chakra-ui/react';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Model } from 'models/response/model.interface';
import Container from 'components/Container';
import visitLibrary from 'assets/visit_library_icon.png';
import {
  AddModel,
  getDuplicateModels,
  getModels,
  GetUserModels,
  UpdateModel,
} from 'redux/ModelsSlice';
import ArrorDropdownIconComponent from 'components/Icons/ArrowDropdownIcon';
import AddIconComponent from 'components/Icons/AddIcon';
import CreateModelModal from 'components/Modals/CreateModelModal';

import LibraryButton from './MobileModel/LibraryButton';
import PageTitles from './PageTitle';
import ModelsSearch from './ModelsSearch';
import MobileModels from './MobileModel';

const MobileModelsLayout = () => {
  const dispatch: any = useDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [addModel, setAddModel] = useState({ type: '', status: false, modelData: {} });

  const models = useSelector(getModels);
  const duplicateModels = useSelector(getDuplicateModels);

  useEffect(() => {
    dispatch(GetUserModels({ loader: true }));
  }, []);

  const onSearchValue = (event: ChangeEvent<HTMLInputElement>) =>
    setSearchValue(event.target.value);

  const duplicateModel = useMemo(
    () =>
      models
        ?.filter((model: Model) => model?.name?.toLowerCase().includes(searchValue.toLowerCase()))
        .concat(duplicateModels),
    [models, searchValue, duplicateModels],
  );

  const handleAddModel = (form: any, shouldUpdate: boolean, modalData: any) => {
    setAddModel({ modelData: {}, type: '', status: false });
    if (!shouldUpdate) {
      dispatch(AddModel({ data: form, toaster: { successMessage: 'Added successfully' } }));
    } else {
      dispatch(UpdateModel({ modelId: modalData?.id, data: form, toaster: true }));
    }
  };

  return (
    <>
      <Container
        wrapperProperties={{
          borderTopWidth: '1',
          borderColor: 'grey.900',
          bg: 'white.900',
          overflowX: 'hidden',
        }}
      >
        <Flex direction='column' py='4' h='100%'>
          <PageTitles />

          <Text mt='5' variant='grey' fontFamily='regular' fontSize='2xs'>
            View more blocks from the Library
          </Text>

          <LibraryButton />

          <Flex mt={4} justifyContent={'space-between'} flexWrap={'wrap'}>
            {models.length > 0 && (
              <Flex alignItems={'center'}>
                <Text variant='grey' fontFamily='regular' fontSize='2xs' mr={2}>
                  View and edit your Blocks
                </Text>
                <ModelsSearch searchValue={searchValue} onChange={onSearchValue} />
              </Flex>
            )}
            <Menu closeOnSelect={true} variant='primary'>
              <MenuButton
                backgroundColor={'#5A58AB'}
                padding={'.5rem 1rem'}
                as={Button}
                border={'none'}
                rightIcon={
                  <ArrorDropdownIconComponent
                    style={{ margin: '0 .5rem' }}
                    height={12}
                    width={12}
                    color='white'
                  />
                }
                leftIcon={
                  <AddIconComponent
                    style={{ margin: '0 .5rem' }}
                    color='#62B5BA'
                    height={18}
                    width={18}
                  />
                }
                fontSize={'md'}
                fontWeight={'400'}
                width={'fit-content'}
                height={'40px'}
              >
                Create
              </MenuButton>
              <MenuList zIndex={4}>
                <MenuItem onClick={() => setAddModel({ status: true, type: 'add', modelData: {} })}>
                  New blank model
                </MenuItem>
                <MenuItem onClick={() => window.open('https://www.blox.so/models', '_blank')}>
                  New model from library
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>

          <VStack
            className='scroll'
            spacing='3.5'
            mt='7'
            pr='2'
            align='flex-start'
            h='100%'
            mb='10px'
            overflowY='auto'
          >
            {duplicateModel.length > 0 ? (
              duplicateModel.map((model: Model) => (
                <MobileModels
                  key={model.id}
                  model={model}
                  handleProperties={(data: any) => {
                    setAddModel({ type: 'edit', status: true, modelData: data });
                  }}
                />
              ))
            ) : models.length === 0 ? (
              <Image width={'30vh'} src={visitLibrary} ml={4} />
            ) : (
              <Text size='home_page_subtitle' variant='grey'>
                No blocks found with that name
              </Text>
            )}
          </VStack>
        </Flex>
      </Container>

      <CreateModelModal
        modalData={addModel.modelData}
        modalType={addModel.type}
        isOpen={addModel.status}
        onClose={() => setAddModel({ status: false, type: '', modelData: {} })}
        onSave={handleAddModel}
      />
    </>
  );
};

export default MobileModelsLayout;
