import { Image, StyleProps } from '@chakra-ui/react';

import assistant from 'assets/assistant_icon.png';

interface Properties {
  w?: number;
  h?: number;
  styles?: StyleProps;
}

const HelpIconComponent = ({ w = 34, h = 27, styles }: Properties) => {
  return <Image src={assistant} w={w} h={h} {...styles} />;
};

export default HelpIconComponent;
