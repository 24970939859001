import { Flex, Text, Box } from '@chakra-ui/react';
import { connect } from 'react-redux';

import Error from 'components/Icons/Error';
import Success from 'components/Icons/Success';

const CustomToastComponent = ({ toast }: any) => {
  if (!toast?.length) {
    return <></>;
  }
  const getBackground = (alertStatus: string) => {
    if (alertStatus === 'error') return 'red.500';

    return 'green.900';
  };

  const getAlertIcon = (alertStatus: string) => {
    if (alertStatus === 'error') return <Error />;

    return <Success />;
  };

  return (
    <Box
      position={'absolute'}
      bottom={'10px'}
      left={'50%'}
      zIndex={'9999'}
      style={{ translate: '-50%' }}
    >
      {toast.map((data: any, index: number) => {
        return (
          <Flex
            key={index}
            w='75'
            align='center'
            py='3'
            pl='4'
            pr='8'
            borderRadius='md'
            color='white.900'
            bgColor={getBackground(data?.status)}
            boxShadow='lg'
            overflowWrap='break-word'
            overflow='hidden'
          >
            {getAlertIcon(data?.status)}

            <Text ml='2' fontFamily='bold_roboto' fontSize='sm'>
              {data?.message}
            </Text>
          </Flex>
        );
      })}
    </Box>
  );
};
const stateToProperty = (state: any) => {
  return { toast: state?.sharedSlice?.toast };
};
export default connect(stateToProperty)(CustomToastComponent);
