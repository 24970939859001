import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  VStack,
  Image,
  Flex,
  Text,
  Box,
  UnorderedList,
  ListItem,
  RadioGroup,
  Stack,
  Radio,
  useOutsideClick,
} from '@chakra-ui/react';
import { FaListUl } from 'react-icons/fa';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { addModalFormData, getFormValues, getMetadatas } from 'redux/DataImportSlice';
import HelpIconComponent from 'components/Icons/HelpIcon';

import database from '../../../assets/database_icon.svg';

const textStyles = {
  fontSize: 'lg',
  fontFamily: 'regular_roboto',
  fontWeight: '700',
  color: '#6562CF',
};
const paragraphStyles = {
  fontSize: 'sm',
  fontFamily: 'regular_roboto',
  fontWeight: '400',
  color: '#000',
};
const subHeadingTextStyle = {
  fontSize: 'sm',
  fontFamily: 'regular_roboto',
  fontWeight: '700',
  color: '#000',
  ps: 2,
};
const checkedRadioStyles = {
  _before: {
    content: '""',
    display: 'inline-block',
    width: '6px',
    height: '5px',
    borderRadius: '50%',
    background: 'black',
  },
  _checked: {
    borderColor: 'black',
    color: 'black',
    boxShadow: 'none',
  },
};

const HelpImportModal = ({ setHelpModalSeleceted, helpModalSeleceted, flags }: any) => {
  const reference = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  const dispatch: any = useDispatch();
  const getFormValue = useSelector(getFormValues);
  const metaData = useSelector(getMetadatas);
  const indicatorLength = metaData?.indicators?.length;
  useOutsideClick({
    ref: reference,
    handler: () => {
      setHelpModalSeleceted.off();
    },
  });

  return (
    <Modal onClose={setHelpModalSeleceted.off} isOpen={helpModalSeleceted} isCentered>
      <ModalOverlay />
      <ModalContent color={'#000'} maxW={'60rem'} ref={reference}>
        <ModalCloseButton />
        <ModalBody>
          <VStack display={'flex'} justify={'start'} gap={5} align={'flex-start'} p={5}>
            <Flex justify={'space-between'} align={'center'}>
              <HelpIconComponent h={35} w={35} />
              <Flex display={'flex'} flexDirection={'column'} ps={5}>
                <Text {...textStyles} mb={2}>
                  Data types for {metaData?.model_name}
                </Text>
                <Text {...paragraphStyles} mb={1}>
                  Import the information in your file as either
                  <span style={{ fontWeight: 600 }}> Dimensions</span> or{' '}
                  <span style={{ fontWeight: 600 }}>Data</span>
                </Text>
                <Text {...paragraphStyles} mb={0}>
                  <span style={{ fontWeight: 600 }}>Dimensions</span>: Import each row in the file
                  as an item in a particular dimension, along with its corresponding properties
                </Text>
                <Text {...paragraphStyles} mb={1}>
                  <span style={{ fontWeight: 600 }}>Data</span>: Import the values in each row as
                  historical ‘Actual’ values for Indicators within a specific block
                </Text>
              </Flex>
            </Flex>
            <Flex justify={'space-between'} w={'100%'}>
              <Box
                w={'38%'}
                display={'flex'}
                alignItems={'baseline'}
                py={2}
                px={3}
                borderRadius={'14px'}
                bg={'#F5F4F8'}
                flexDirection={'column'}
              >
                <Flex justify={'start'}>
                  <FaListUl style={{ color: '#4BB6B9', height: '20px', width: '20px' }} />
                  <Text {...subHeadingTextStyle}>Dimensions in your Model</Text>
                </Flex>
                <Flex justify={'start'} w={'100%'} px='2' pb='5' pt='3'>
                  <UnorderedList>
                    {metaData &&
                      metaData?.dimensions.map((item: any) => (
                        <ListItem key={item?.id}>{item?.name}</ListItem>
                      ))}
                  </UnorderedList>
                </Flex>
              </Box>
              <Box
                w={'58%'}
                display={'flex'}
                alignItems={'baseline'}
                py={2}
                px={3}
                borderRadius={'14px'}
                bg={'#F5F4F8'}
                flexDirection={'column'}
              >
                <Flex justify={'start'} alignItems={'center'}>
                  <Image h={7} w={7} src={database} />
                  <Text {...subHeadingTextStyle}>Data in this model</Text>
                </Flex>
                <Flex
                  justify={'space-between'}
                  w={'100%'}
                  ps={'0.6rem'}
                  pt={'0.5rem'}
                  maxH={'25vh'}
                  className={'scroll'}
                  overflowY={'auto'}
                >
                  {metaData && metaData.indicators?.length > 12 ? (
                    <>
                      <Flex w={'48%'}>
                        <UnorderedList>
                          {metaData &&
                            metaData?.indicators
                              .slice(0, Math.ceil(indicatorLength / 2))
                              .map((item: any) => <ListItem key={item?.id}>{item?.name}</ListItem>)}
                        </UnorderedList>
                      </Flex>

                      <Flex w={'48%'}>
                        <UnorderedList>
                          {metaData &&
                            metaData?.indicators
                              .slice(Math.ceil(metaData.indicators.length / 2))
                              .map((item: any) => <ListItem key={item.id}>{item.name}</ListItem>)}
                        </UnorderedList>
                      </Flex>
                    </>
                  ) : (
                    <>
                      <Flex w={'48%'}>
                        <UnorderedList>
                          {metaData &&
                            metaData?.indicators
                              .slice(0, 6)
                              .map((item: any) => <ListItem key={item.id}>{item?.name}</ListItem>)}
                        </UnorderedList>
                      </Flex>
                      <Flex w={'48%'}>
                        <UnorderedList>
                          {metaData &&
                            metaData?.indicators
                              .slice(6)
                              .map((item: any) => <ListItem key={item.id}>{item.name}</ListItem>)}
                        </UnorderedList>
                      </Flex>
                    </>
                  )}
                </Flex>
              </Box>
            </Flex>
          </VStack>
        </ModalBody>
        <ModalFooter justifyContent={'flex-start'} ms={3} width={'80%'} px={5}>
          <RadioGroup
            width={'100%'}
            onChange={(value) => dispatch(addModalFormData(value))}
            value={getFormValue.dataType}
          >
            <Stack
              spacing={5}
              direction='row'
              pb={3}
              w={'100%'}
              justifyContent={'space-between'}
              ps={3}
            >
              <Flex w={'50%'}>
                <Radio _checked={checkedRadioStyles} value='1'>
                  <Text {...paragraphStyles}>Select dimension for your data type</Text>
                </Radio>
              </Flex>
              {flags?.importAsDataType && (
                <Flex w={'50%'}>
                  <Radio _checked={checkedRadioStyles} value='2'>
                    <Text {...paragraphStyles}>Select data for your data type</Text>
                  </Radio>
                </Flex>
              )}
            </Stack>
          </RadioGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default withLDConsumer()(HelpImportModal);
