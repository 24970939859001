export default {
  start: '0em',
  '1xl': '26rem', // 416px
  '1.75xl': '29rem', // 464px
  '1.5xl': '30rem', // 464px
  xs: '28em', // 448px
  sm: '36em', // 576px
  other: '42rem', // 672px
  '2xl': '42rem', // 672px
  '3xl': '48rem', // 768px
  laptop: '51rem', // 816px
  '4xl': '56rem', // 896px
  md: '64em', // 1024px
  '5xl': '64rem', // 1024px
  '6xl': '72rem', // 1152px
  '7xl': '80rem', // 1280px
  '8xl': '90rem', // 1440px
  '9xl': '100rem', // 1440px
  '10xl': '120rem', // 1440px
};
