// import { useMediaQuery } from '@chakra-ui/react';

const DataImportIcon = ({ color }: any) => {
  return (
    <svg width='22' height='40' viewBox='0 0 45 44' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Layer_1' clipPath='url(#clip0_2513_10332)'>
        <path
          id='Vector'
          d='M0.280412 23.1579H6.27884C4.39698 24.0379 2.86797 25.3579 1.71533 27.0948C0.562691 28.8316 -0.0253906 30.8001 -0.0253906 33.0001C-0.0253906 36.0569 1.05668 38.6506 3.24434 40.7811C5.43201 42.9116 8.06661 44.0001 11.1482 44.0001V39.3685C9.38391 39.3685 7.8549 38.7432 6.58465 37.5158C5.31439 36.2885 4.67926 34.7832 4.67926 33.0001C4.67926 31.4948 5.14973 30.1748 6.09066 29.0169C7.03159 27.859 8.23128 27.1179 9.68972 26.8169V32.4211H14.3944V18.5264H0.280412V23.1579Z'
          fill={color}
        />
        <path
          id='Vector_2'
          d='M43.6084 1.36632C42.691 0.463158 41.5854 0 40.2916 0H7.33551C6.04173 0 4.93614 0.463158 4.01873 1.36632C3.50122 1.87579 3.14837 2.43158 2.91314 3.05684H2.72495V3.6821C2.65438 3.98316 2.63086 4.30737 2.63086 4.63158V13.8947H2.72495H19.0971V41.661H40.2681C41.5619 41.661 42.6675 41.1979 43.5849 40.2947C44.5023 39.3916 44.9727 38.3032 44.9727 37.0295V4.63158C44.9727 3.35789 44.5023 2.26947 43.5849 1.36632H43.6084ZM26.9774 32.4905H22.6962V28.3916H26.9774V32.4905ZM26.9774 25.1726H22.6962V21.0737H26.9774V25.1726ZM26.9774 17.8316H22.6962V13.7326H26.9774V17.8316ZM41.7265 32.4905H29.9178V28.3916H41.7265V32.4905ZM41.7265 25.1726H29.9178V21.0737H41.7265V25.1726ZM41.7265 17.8316H29.9178V13.7326H41.7265V17.8316Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_2513_10332'>
          <rect width='45' height='44' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default DataImportIcon;
