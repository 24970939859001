import { Box, Button, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  CancelUserSubscriptions,
  FetchUserPaymentDetails,
  FetchUserPlanDetails,
  getUserPaymentData,
  getUserPlans,
  SubscribeUser,
} from 'redux/UserSlice';
import UserPermissions from 'utils/UserPermissions';

import Switch from './Switch';
import UserHeading from './UserHeading';

const UserPlansComponent = () => {
  const dispatch: any = useDispatch();

  const userPlans = useSelector(getUserPlans);
  const userPaymentData = useSelector(getUserPaymentData);

  const [planType, setPlanType] = useState(0);
  const [isLaptop] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const getPlanDetail = async () => {
      dispatch(FetchUserPaymentDetails());
      await dispatch(FetchUserPlanDetails());
    };
    getPlanDetail();
  }, []);

  const handlePriceButton = (data: any, data_: any, price: number = 0) => {
    let buttonName = '';
    if (data_?.name === 'Ultimate') {
      buttonName = 'Contact us';
    } else if (userPaymentData?.cancel_at) {
      buttonName = 'Canceled';
    } else if (userPaymentData.price > price) {
      buttonName = 'Edit plan';
    } else if (userPaymentData.price < price) {
      buttonName = 'Upgrade';
    } else {
      buttonName = 'Active plan';
    }
    return buttonName;
  };

  const handleSubscription = (name: string, data: any) => {
    if (data) {
      dispatch(
        SubscribeUser({
          request_body: {
            price_id: data.stripe_price_id,
          },
        }),
      ).then((data_: any) => {
        window.location.href = data_?.payload?.url;
      });
    } else {
      dispatch(CancelUserSubscriptions({})).then((response: any) => {
        window.location.href = response?.payload?.url;
      });
    }
  };

  return (
    <UserPermissions scopes={['edit']} permissionType='user'>
      <Box height='100%' overflow={'hidden'}>
        <UserHeading heading='Plan' />
        <Box height={`calc(100% - 63px)`}>
          <Flex justifyContent={'center'}>
            <Switch handleChange={() => setPlanType(planType === 0 ? 1 : 0)} />
          </Flex>
          <Flex
            gap={'4'}
            width='100%'
            mt='8'
            flexWrap={'wrap'}
            justifyContent='space-evenly'
            height={`calc(100% - 86px)`}
            overflow={'auto'}
            py='2'
          >
            {userPlans?.subscriptions?.map((data: any, index: number) => (
              <Box
                key={index}
                borderRadius='20px'
                padding={'1rem'}
                boxShadow={'1px 1px 10px #D9D9D9'}
                maxW={isLaptop ? '90%' : '23%'}
                minW='200px'
              >
                <Text fontWeight={'900'} fontSize={'3xl'} color='black'>
                  {data?.name}
                </Text>
                {data?.name === 'Ultimate' ? (
                  <Flex mt='4' alignItems={'center'}>
                    <Text fontWeight={'900'} fontSize={'3xl'} color='black'>
                      £Custom
                    </Text>
                  </Flex>
                ) : (
                  <Flex mt='4' alignItems={'center'}>
                    <Text fontWeight={'900'} fontSize={'3xl'} color='black'>
                      £{data?.plan_prices[planType]?.price || 0}
                    </Text>
                    <Text fontWeight={'900'} fontSize={'sm'} color='black' ml='2'>
                      per user/per {planType === 0 ? 'month' : 'year'}
                    </Text>
                  </Flex>
                )}
                <Box mt='4'>
                  <Button
                    bgColor={
                      userPaymentData?.price === data?.plan_prices[planType]?.price ||
                      (data?.plan_prices?.length === 0 && userPaymentData?.price === 0)
                        ? 'gray.100'
                        : 'green.900'
                    }
                    color={
                      userPaymentData?.price === data?.plan_prices[planType]?.price ||
                      (data?.plan_prices.length === 0 && userPaymentData?.price === 0)
                        ? 'black'
                        : 'white'
                    }
                    borderRadius='10px'
                    width={'100%'}
                    fontSize='xs'
                    cursor={
                      userPaymentData?.price === data?.plan_prices[planType]?.price ||
                      (data?.plan_prices.length === 0 && userPaymentData?.price === 0)
                        ? 'initial'
                        : 'pointer'
                    }
                    _hover={{}}
                    height={'fit-content'}
                    disabled={
                      userPaymentData?.cancel_at ||
                      (userPaymentData?.is_custom_plan && data?.name !== 'Ultimate')
                    }
                    padding='.5rem'
                    onClick={() => {
                      if (data?.name === 'Ultimate') {
                        window.open('https://www.blox.so/ultimate-contact-form', '_blank');
                      } else if (
                        userPaymentData?.price !== data?.plan_prices[planType]?.price &&
                        !userPaymentData?.is_custom_plan
                      )
                        handleSubscription(data?.name, data?.plan_prices[planType]);
                    }}
                  >
                    {handlePriceButton(data?.plan_prices, data, data?.plan_prices[planType]?.price)}
                  </Button>
                </Box>
                <Text fontWeight={'300'} fontSize={'sm'} color='grey.100' ml='2' mt='4'>
                  {data?.name === 'Free'
                    ? 'Great for individuals building ambitious plans'
                    : data?.name === 'Growth'
                    ? 'Great for small team who wants to collaborate and plan together.'
                    : data?.name === 'Starter'
                    ? 'Great for growing teams taking their next steps big.'
                    : data?.name === 'Ultimate'
                    ? 'Great for large and complex problem where you need extra power and dedicated support.'
                    : ''}
                </Text>
                <Text fontWeight={'600'} fontSize={'lg'} color='black' mt='4'>
                  {data?.name} includes:
                </Text>
                <Box mt='4'>
                  <Text fontSize={'sm'} color='black' fontWeight={'500'}>
                    {data?.name === 'Free'
                      ? ''
                      : data?.name === 'Starter'
                      ? 'Everything in Free'
                      : data?.name === 'Growth'
                      ? 'Everything in Starter'
                      : data?.name === 'Ultimate'
                      ? 'Everything in Growth'
                      : ''}
                  </Text>
                  <Text fontSize={'sm'} color='black' fontWeight={'500'}>
                    {data?.name === 'Free'
                      ? '1 User'
                      : data?.name === 'Starter'
                      ? 'Up to 3 users in a team'
                      : data?.name === 'Growth'
                      ? 'Unlimited users'
                      : data?.name === 'Ultimate'
                      ? 'Unlimited models'
                      : ''}
                  </Text>
                  <Text fontSize={'sm'} color='black' fontWeight={'500'}>
                    {data?.name === 'Free'
                      ? 'Up to 2 models'
                      : data?.name === 'Starter'
                      ? 'Up to 5 shared team models'
                      : data?.name === 'Growth'
                      ? 'Up to 10 shared team models'
                      : data?.name === 'Ultimate'
                      ? 'Support for larger custom models'
                      : ''}
                  </Text>
                  <Text fontSize={'sm'} color='black' fontWeight={'500'}>
                    {data?.name === 'Free'
                      ? 'Unlimited read-only sharing'
                      : data?.name === 'Starter'
                      ? `5 'What-if' scenarios per model`
                      : data?.name === 'Growth'
                      ? `10 'What-if' scenarios per model`
                      : data?.name === 'Ultimate'
                      ? 'Dedicated CS manager'
                      : ''}
                  </Text>
                  <Text fontSize={'sm'} color='black' fontWeight={'500'}>
                    {data?.name === 'Free'
                      ? `2 'What-if' scenarios per model`
                      : data?.name === 'Starter'
                      ? ``
                      : data?.name === 'Growth'
                      ? ``
                      : data?.name === 'Ultimate'
                      ? ''
                      : ''}
                  </Text>
                </Box>
              </Box>
            ))}
          </Flex>
        </Box>
      </Box>
    </UserPermissions>
  );
};

export default UserPlansComponent;
