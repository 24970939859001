import { Button, Flex, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { CSSProperties, useLayoutEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Container from 'components/Container';
import Assistant from 'components/Icons/Assistant';
import { getBlockDriversData, getSelectedDriver, setDriver } from 'redux/PlanPageSlice';
import { resetDimension } from 'redux/DimensionPageSlice';
import { resetIndicator } from 'redux/IndicatorPageSlice';
import { resetItem } from 'redux/ItemPageSlice';

export interface Properties {
  planError?: boolean;
  style?: CSSProperties;
}

const ErrorPage = ({ planError, style }: Properties) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const blockDrivers = useSelector(getBlockDriversData);
  const selectedDriver = useSelector(getSelectedDriver);

  useLayoutEffect(() => {
    document.title = 'Blox';
  }, []);

  const isSelectedDriver = (driver: any) => {
    let flag: boolean = false;
    if (!driver) return flag;
    if (
      driver?.type === 'indicator' &&
      selectedDriver?.type === 'indicator' &&
      driver?.id === selectedDriver?.id
    ) {
      flag = true;
    } else if (
      driver?.type === 'property' &&
      selectedDriver?.type === 'property' &&
      driver?.property_id === selectedDriver?.property_id
    ) {
      flag = true;
    } else if (
      driver?.type === 'dimension' &&
      selectedDriver?.type === 'dimension' &&
      driver?.dim_id === selectedDriver?.dim_id
    ) {
      flag = true;
    }
    return flag;
  };

  const selectDriver = (driver: any) => {
    if (isSelectedDriver(driver)) {
      dispatch(driver(null));
    } else {
      if (selectedDriver?.type === 'indicator') {
        dispatch(resetIndicator());
      }
      if (selectedDriver?.type === 'property') {
        dispatch(resetDimension());
      }
      if (selectedDriver?.type === 'dimension') {
        dispatch(resetItem());
      }
      dispatch(setDriver(driver));
    }
  };

  const onBack = () => {
    if (!planError) {
      navigate(-2);
    } else if (blockDrivers && blockDrivers.length > 0) {
      selectDriver(blockDrivers[0]);
    }
  };

  return (
    <Container style={style} bgColor='transparent'>
      <Flex h='100%' direction='column' align='center' justify='center'>
        <Flex position='relative'>
          <Assistant
            styles={{
              transform: 'scaleX(-1)',
              position: { base: 'absolute', sm: 'static' },
              top: '-125%',
              left: '25%',
            }}
          />

          <Flex direction='column' justify='space-between'>
            <Text
              mb='2.5'
              fontFamily='bold_roboto'
              fontSize={{ base: 'sm', sm: 'md' }}
              lineHeight='9'
            >
              Oops, something went wrong
            </Text>
            <Text
              width='200px'
              fontFamily='regular_roboto'
              fontSize={{ base: '2xs', sm: 'xs' }}
              lineHeight='5'
            >
              The page you are looking for either does not exist or you do not have access
            </Text>
          </Flex>
        </Flex>
        {!planError && (
          <Button
            variant='outlined'
            size='lg'
            w={{ base: '72', sm: '100' }}
            mt={{
              base: 10,
              sm: 14,
              md: 17,
            }}
            onClick={onBack}
          >
            go back
          </Button>
        )}
      </Flex>
    </Container>
  );
};

export default ErrorPage;
