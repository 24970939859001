import { Icon, StyleProps } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
  wrapperProperties?: StyleProps;
}

const Visibility = ({
  width = 4,
  height = 4.5,
  color = 'black.900',
  wrapperProperties,
}: Properties) => {
  return (
    <Icon {...wrapperProperties} viewBox='0 0 17 19' width={width} height={height} color={color}>
      <svg
        width='23'
        height='16'
        viewBox='0 0 23 16'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M11.4746 12.7383C12.7246 12.7383 13.7873 12.3009 14.6626 11.4263C15.5373 10.5509 15.9746 9.48828 15.9746 8.23828C15.9746 6.98828 15.5373 5.92561 14.6626 5.05028C13.7873 4.17561 12.7246 3.73828 11.4746 3.73828C10.2246 3.73828 9.16194 4.17561 8.28661 5.05028C7.41194 5.92561 6.97461 6.98828 6.97461 8.23828C6.97461 9.48828 7.41194 10.5509 8.28661 11.4263C9.16194 12.3009 10.2246 12.7383 11.4746 12.7383ZM11.4746 10.9383C10.7246 10.9383 10.0873 10.6756 9.56261 10.1503C9.03728 9.62561 8.77461 8.98828 8.77461 8.23828C8.77461 7.48828 9.03728 6.85061 9.56261 6.32528C10.0873 5.80061 10.7246 5.53828 11.4746 5.53828C12.2246 5.53828 12.8623 5.80061 13.3876 6.32528C13.9123 6.85061 14.1746 7.48828 14.1746 8.23828C14.1746 8.98828 13.9123 9.62561 13.3876 10.1503C12.8623 10.6756 12.2246 10.9383 11.4746 10.9383ZM11.4746 15.7383C9.04128 15.7383 6.82461 15.0589 4.82461 13.7003C2.82461 12.3423 1.37461 10.5216 0.474609 8.23828C1.37461 5.95495 2.82461 4.13395 4.82461 2.77528C6.82461 1.41728 9.04128 0.738281 11.4746 0.738281C13.9079 0.738281 16.1246 1.41728 18.1246 2.77528C20.1246 4.13395 21.5746 5.95495 22.4746 8.23828C21.5746 10.5216 20.1246 12.3423 18.1246 13.7003C16.1246 15.0589 13.9079 15.7383 11.4746 15.7383Z'
          fill='white'
        />
      </svg>
    </Icon>
  );
};

export default Visibility;
