import { Chart as ChartJS, registerables } from 'chart.js';
import { useEffect, useState } from 'react';
import { Chart as ReactChart } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { FormatProperties } from 'models/response/modelOutput.interface';
import { CHART_COLORS } from 'projectConstants';
import { planPageShow, filterStartDate, filterEndDate } from 'redux/PlanPageSlice';
import getChartOptions from 'utils/getChartOptions';

ChartJS.register(...registerables);

interface Properties {
  chartLabelData: { [x: string]: number };
  chartData: Record<string, Record<string, number>> | [];
  chartType: string;
  formatProperties: FormatProperties;
  showLabel?: boolean;
  dimId?: number | string;
}

const Chart = ({
  chartLabelData,
  chartData,
  chartType,
  formatProperties,
  showLabel,
}: Properties) => {
  const showPlanPage = useSelector(planPageShow);

  const startDateFilter = useSelector(filterStartDate);
  const endDateFilter = useSelector(filterEndDate);
  const [data, setData] = useState<any>();
  const [updateChartData, setUpdateChartData] = useState<any>(data);

  const legendMargin = {
    id: 'legendMargin',
    beforeInit(chart: any) {
      const originalFit = chart.legend.fit;

      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 25;
      };
    },
  };

  const chartComponentReadyData = () => {
    if (Object.values(chartData)[0] !== undefined) {
      const datasets = Object.entries(chartData).map((entry: any, index) => {
        const data1 = Object.values(entry[1]);

        // Check if the array contains only zeros.
        const allZeros = data1.every((value) => value === 0);

        // Copy data1 and remove trailing zeros only if not all values are zero.
        const trimmedData = allZeros ? [...data1] : [...data1];
        if (!allZeros) {
          while (trimmedData[trimmedData.length - 1] === 0 && trimmedData.length > 1) {
            trimmedData.pop();
          }
        }

        return {
          key: index,
          label: entry[0],
          data: trimmedData,
          backgroundColor: CHART_COLORS[index],
          borderColor: CHART_COLORS[index],
        };
      });

      const newdata = {
        labels: Object.keys(chartLabelData),
        datasets,
      };

      if (newdata !== undefined) setUpdateChartData(newdata);
    }
  };

  const formatChartOnTimeFilter = () => {
    const startIndex = Object.keys(chartLabelData).indexOf(startDateFilter);
    const endIndex = Object.keys(chartLabelData).indexOf(endDateFilter);
    const getSlicedData = (myData: any) => {
      const slicedData = Object.fromEntries(Object.entries(myData).slice(startIndex, endIndex + 1));
      return slicedData;
    };
    if (Object.values(chartData)[0] !== undefined) {
      const filterDatasets = Object?.entries(chartData).map((entry: any, index) => ({
        key: index,
        label: entry[0],
        data: Object.values(entry[1]),
        backgroundColor: CHART_COLORS[index],
        borderColor: CHART_COLORS[index],
      }));
      const newLabelData = getSlicedData(chartLabelData);
      const filterData = {
        labels: Object.keys(newLabelData),
        filterDatasets,
      };
      if (startIndex === -1 && endIndex === -1) setUpdateChartData(data);
      else {
        setUpdateChartData(filterData);
      }
    }
  };
  useEffect(() => {
    formatChartOnTimeFilter();
    chartComponentReadyData();
  }, [chartData, startDateFilter, endDateFilter]);

  useEffect(() => {
    if (updateChartData !== undefined) setData(updateChartData);
  }, [updateChartData]);
  return (
    <>
      {data && (
        <ReactChart
          type={chartType === 'line' ? 'line' : 'bar'}
          data={data}
          options={getChartOptions({
            chartType,
            isLegend: !showLabel,
            formatProperties,
            pageName: 'drivers',
            aspectRatio: showPlanPage ? 1.5 : 5,
          })}
          plugins={[legendMargin]}
        />
      )}
    </>
  );
};

export default Chart;
