import { Text, Flex, useMediaQuery } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';

import { getBlockDetails, groupByFilterChange, setEmptyData } from 'redux/PlanPageSlice';
import {
  getAllScenarios,
  getBaseScenario,
  getCompareScenario,
  getToggleScenario,
  selectBaseScenario,
  selectCompareScenario,
  toggleCompareScenario,
} from 'redux/ScenarioSlice';
import { Scenario } from 'models/response/scenario.interface';

import OverAllFilter from '../Plan/Filters/OverallFilters';
import ScenarioFilter from '../Plan/Filters/ScenarioFilter';
import CustomToggle from '../Plan/CustomToggle';

export interface Properties {
  refetchRequest: () => void;
}

const PlanPageHeader = ({ refetchRequest }: Properties) => {
  const dispatch: any = useDispatch();
  const blockDetails = useSelector(getBlockDetails);
  const scenarios = useSelector(getAllScenarios);
  const baseScenario = useSelector(getBaseScenario);
  const scenarioToggle = useSelector(getToggleScenario);
  const compareScenario = useSelector(getCompareScenario);

  const [isLaptop] = useMediaQuery('(max-width: 1024px)');

  return (
    <Flex px={0} my={7} h={7} alignItems={'center'} justifyContent={'space-between'}>
      <Flex alignItems={'baseline'}>
        <Text size={'Comfortaa-28'}>{blockDetails?.name}</Text>
        {scenarios.length > 0 && baseScenario && (
          <Flex alignItems={'center'} justifyContent={'start'} gap={4} ml={6}>
            <ScenarioFilter
              refetchRequest={refetchRequest}
              isSelectCompare={false}
              currentScenario={baseScenario}
              selectScenario={(scenario: Scenario) => dispatch(selectBaseScenario(scenario))}
            />

            <CustomToggle
              onChange={(value: any) => {
                dispatch(toggleCompareScenario(value));
              }}
              defaultValue={scenarioToggle}
            />

            {scenarioToggle && (
              <ScenarioFilter
                refetchRequest={refetchRequest}
                isSelectCompare={true}
                currentScenario={compareScenario || ''}
                selectScenario={(scenario: Scenario) => {
                  dispatch(groupByFilterChange('Total'));
                  dispatch(setEmptyData(true));
                  dispatch(selectCompareScenario(scenario));
                }}
              />
            )}
          </Flex>
        )}
      </Flex>
      {!isLaptop && <OverAllFilter refetchRequest={refetchRequest} />}
    </Flex>
  );
};

export default PlanPageHeader;
