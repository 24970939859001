import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { InputAdjutment } from 'models/requestBody/getModelOutputs.interface';
import apiService from 'services';

interface DriversAdjustmentContextProperties {
  inputAdjustments: InputAdjutment;
}

interface DriversAdjustmentState extends DriversAdjustmentContextProperties {}

export const editDriverDataInput = createAsyncThunk(
  'editDriver/post',
  async (data: any, thunkAPI) => {
    try {
      const response = await apiService.editDriverInputsData(data?.blockId, data?.requestBody);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

const initialState: DriversAdjustmentState = {
  inputAdjustments: {},
};

const driversAdjustmentSlice = createSlice({
  name: 'driverAdjustmentSlice',
  initialState,
  reducers: {
    resetDriverAdjustmentSlider(state) {
      state.inputAdjustments = {};
    },
    adjustInput: (state: any, action: PayloadAction<InputAdjutment | {}>) => {
      const parameter = action.payload;

      state.inputAdjustments =
        Object.keys(parameter).length === 0 ? {} : { ...state.inputAdjustments, ...parameter };
    },
  },
});

export const { resetDriverAdjustmentSlider, adjustInput } = driversAdjustmentSlice.actions;
export const GetInputAdjustments = (state: any) => state.driverAdjustmentSlice?.inputAdjustments;

export default driversAdjustmentSlice.reducer;
