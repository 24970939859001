import { Editable, EditableInput, EditablePreview } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import PlanPermission from 'container/PlanPermission';

interface Properties {
  defaultValue: string;
  onSubmit: (value: string) => void;
  isEditNameMode?: boolean;
  planPermission: boolean;
  closeEditMode?: () => void;
}

const EditableField = ({
  defaultValue,
  onSubmit,
  planPermission,
  isEditNameMode,
  closeEditMode,
}: Properties) => {
  const [value, setValue] = useState(defaultValue || '');
  useEffect(() => {
    setValue(defaultValue || '');
  }, [defaultValue]);

  const submitValue = (itemValue: string) => {
    onSubmit(itemValue);
  };
  const onChange = (itemValue: string) => {
    setValue(itemValue);
  };

  return (
    <Editable
      overflow={'hidden'}
      value={value}
      onChange={onChange}
      onSubmit={submitValue}
      startWithEditView={isEditNameMode}
      onBlur={closeEditMode}
      h='7.5'
      w={isEditNameMode ? 'full' : '20'}
      borderWidth='1'
      borderRadius='xs'
      borderColor='white.900'
      p='0'
      fontSize='2xs'
      display='flex'
      alignItems='center'
      justifyContent='center'
      textAlign={isEditNameMode ? 'start' : 'center'}
      cursor={planPermission ? 'not-allowed' : 'pointer'}
      isDisabled={!!planPermission}
    >
      <EditablePreview w='full' cursor={planPermission ? 'not-allowed' : 'pointer'} />
      <EditableInput
        cursor={planPermission ? 'not-allowed' : 'pointer'}
        _focusVisible={{
          h: 'full',
          px: '1',
          borderWidth: '3',
          borderRadius: 'xs',
          borderColor: 'blue.500',
        }}
      />
    </Editable>
  );
};

export default PlanPermission(EditableField);
