import { Flex, HStack, Image, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import Papa from 'papaparse';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CheckIcon } from '@chakra-ui/icons';

import Spinner from 'components/Spinner';
import {
  getNoheadersStatus,
  getSelectedAreaOfTable,
  getSelectedDataFileDetails,
  getSelectedVersionDetails,
  getSingleDataImportFiles,
  resetDataImport,
  setDistinctCSV,
  setTableHeaderName,
} from 'redux/DataImportSlice';
import { readDataFile } from 'services/s3Service';
import { AppDispatch } from 'utils/GlobalHelpers';

import blankTable from '../../../assets/blank_table_icon.png';
import './DataImportTable.scss';

const textStyles = {
  fontSize: 'sm',
  color: 'rgba(255, 255, 255, 1)',
  fontFamily: 'regular_roboto',
  fontWeight: '500',
};

export interface Properties {
  SelectedDataFileDetails?: any;
}

const DataImportDetails = () => {
  const dispatch: AppDispatch = useDispatch();
  const { modelId } = useParams();
  const [csvData, setCsvData] = useState<any>([]);
  const [headerNames, setHeaderNames] = useState<string[]>([]);
  const itemsPerPage = 200;
  const [currentPage, setCurrentPage] = useState(1);
  const [loadedData, setLoadedData] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const SelectedDataFileDetails = useSelector(getSelectedDataFileDetails);
  const selectedVersionDetails = useSelector(getSelectedVersionDetails);
  const [fileUrl, setFileURL] = useState('');
  const dataSourceId = SelectedDataFileDetails?.id;
  const tableSelecetedAreas = useSelector(getSelectedAreaOfTable) || {};
  const noHeaders = useSelector(getNoheadersStatus);
  const getSourceDetails = async () => {
    if (dataSourceId && modelId) {
      await dispatch(getSingleDataImportFiles({ modelId, fileId: dataSourceId }));
    }
  };

  // Clean Up function when components destroyed
  useEffect(() => {
    const cleanup = () => {
      // Clear csvData when the component unmounts
      setFileURL('');
      dispatch(resetDataImport());
      setLoadedData([]);
      setCsvData([]);
    };

    return cleanup;
  }, []);
  const [customHeaders, setCustomHeaders] = useState<any[]>(headerNames ?? []);
  const [customCSV, setCustomCSV] = useState<any[]>(csvData ?? []);

  // Function for creating addding custom header and creating unique values for every column

  useEffect(() => {
    if (noHeaders) {
      const array: string[] = new Array(26)
        .fill(1)
        .map((_: string, index: number) => String.fromCodePoint(65 + index));
      const newHeaders = array
        .slice(0, headerNames.length)
        .map((item: string, headerIndex: number) => {
          return { current: `${headerNames[headerIndex]}`, modified: `Column ${item}` };
        });
      const object: any = {};
      headerNames.map((item: string) => {
        object[item] = item;
        return item;
      });
      const firstLine = object;
      setCustomHeaders(newHeaders);
      let distinctCSV: Array<any> = [];
      newHeaders.map((header: any) => {
        header.distinctValues = [];
        distinctCSV.push(header);
        return header;
      });
      csvData.map((row: any) => {
        distinctCSV = distinctCSV.map((header: any) => {
          const distinctData: Array<any> = Array.from(
            row[header.current]
              ? new Set([...header.distinctValues, row[header.current]])
              : header.distinctValues,
          );
          header.distinctValues = distinctData;
          return header;
        });
        return row;
      });

      setLoadedData([]);
      setCustomCSV([firstLine, ...csvData]);
      dispatch(setDistinctCSV(distinctCSV));
      setCurrentPage(1);
    } else {
      const newHeaders = headerNames.map((item: string) => {
        return { current: `${item}`, modified: `${item}` };
      });
      setCustomHeaders(newHeaders);
      setLoadedData([]);
      setCurrentPage(1);
      setCustomCSV(csvData);
      let distinctCSV: Array<any> = [];
      newHeaders.map((header: any) => {
        header.distinctValues = [];
        distinctCSV.push(header);
        return header;
      });
      csvData.map((row: any) => {
        distinctCSV = distinctCSV.map((header: any) => {
          const distinctData: Array<any> = Array.from(
            row[header.current]
              ? new Set([...header.distinctValues, row[header.current]])
              : header.distinctValues,
          );
          header.distinctValues = distinctData;
          return header;
        });
        return row;
      });
      dispatch(setDistinctCSV(distinctCSV));
    }
  }, [noHeaders, headerNames, fileUrl]);

  useEffect(() => {
    if (SelectedDataFileDetails?.deatsilsPage === false) {
      setHeaderNames([]);
      setLoadedData([]);
    }
  }, [SelectedDataFileDetails]);

  useEffect(() => {
    if (selectedVersionDetails?.s3_file_url) {
      readDataFile(selectedVersionDetails?.s3_file_url.split('/').pop(), 'blox-data-import').then(
        (url: any) => {
          setFileURL(url);
        },
      );
    }
  }, [selectedVersionDetails?.s3_file_url]);
  useEffect(() => {
    getSourceDetails();
  }, [dataSourceId]);

  // parsing data of CSV file
  const parseFileData = (data: any) => {
    setLoader(true);
    const config = {
      worker: true,
      header: true,
      stream: true,
      chunkSize: 1024,
      complete: (result: any) => {
        setHeaderNames(result.meta.fields || []);
        dispatch(setTableHeaderName(result?.meta?.fields));
        setCsvData(result.data || []);
        setLoader(false);
      },
    };

    fetch(data)
      .then((response) => response.text())
      .then((data1: any) => {
        Papa.parse(data1, config);
      });
  };
  useEffect(() => {
    if (fileUrl) {
      setCurrentPage(1);
      setCustomCSV([]);
      dispatch(setDistinctCSV([]));
      setHeaderNames([]);
      setCsvData([]);
      setLoadedData([]);
      setCustomHeaders([]);
      parseFileData(fileUrl);
    }
  }, [fileUrl]);
  const handleScroll = (event: any) => {
    const { scrollTop, scrollHeight, clientHeight } = event.target;

    // Calculate the halfway point
    const halfwayPoint = (scrollHeight - clientHeight) / 1.2;

    if (scrollTop >= halfwayPoint) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    if (customCSV) {
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const newData = customCSV && customCSV.slice(startIndex, endIndex);

      // Append new data to the previously loaded data
      setLoadedData((previousData: any) => [...previousData, ...newData]);
    }
  }, [currentPage, customCSV]);

  useEffect(() => {
    return () => {
      setFileURL('');
      setCsvData([]);
      setLoadedData([]);
    };
  }, []);

  if (!fileUrl) {
    return (
      <Flex py={3}>
        <Image src={blankTable} />
      </Flex>
    );
  }
  return (
    <>
      <Flex width='100%' align={'baseline'} my={'1rem'}>
        <HStack w={'full'} overflowY='auto' overflowX={'auto'}>
          {loader ? (
            <Flex
              height={'400px'}
              direction='column'
              alignItems='center'
              justifyContent='center'
              justify='center'
              align='center'
              w={'full'}
            >
              <Spinner styleProperties={{ my: '4vh' }} />
            </Flex>
          ) : (
            <Flex
              w={'100%'}
              display={'block'}
              // whiteSpace={'nowrap'}
              maxHeight={'400px'}
              overflowY='auto'
              overflowX={'auto'}
              mb={{ base: 4.75, md: 0 }}
              mt={{ base: 0, md: 0 }}
              className='sm-scroll indicator-table'
              onScroll={handleScroll}
              borderRadius='1rem'
              borderBottom={'1px solid rgba(255, 255, 255, 0.30)'}
            >
              <Flex>
                <Table variant='simple'>
                  <Thead position='sticky' top={'-1px'} borderRadius={'11px'}>
                    <Tr bgColor={'#69B4B8'}>
                      {customHeaders.map((header: any, index: any) => (
                        <Th
                          {...textStyles}
                          py={3}
                          bgColor='#4BB6B9'
                          overflow='hidden'
                          whiteSpace='nowrap'
                          textOverflow='ellipsis'
                          color={'#ffff'}
                          key={`${header.modified} ${index} `}
                          textAlign={'center'}
                          textTransform='capitalize'
                          border={'1px solid rgba(255, 255, 255, 0.30)'}
                        >
                          <Flex justify={'space-between'} align={'center'} gap={3}>
                            {header.modified}
                            {tableSelecetedAreas &&
                              Object.values(tableSelecetedAreas).includes(header.current) && (
                                <CheckIcon />
                              )}
                          </Flex>
                        </Th>
                      ))}
                    </Tr>
                  </Thead>
                  <Tbody bg={'transparent'}>
                    {loadedData.map((rowData: any, rowIndex: any) => (
                      <Tr key={rowIndex}>
                        {headerNames.map((header, index) => (
                          <Td
                            {...textStyles}
                            border={'1px solid rgba(255, 255, 255, 0.30)'}
                            py={2}
                            key={index}
                            textAlign={'start'}
                            bgColor={
                              tableSelecetedAreas !== 'undefined' &&
                              Object.values(tableSelecetedAreas).length > 0 &&
                              Object.values(tableSelecetedAreas).includes(header)
                                ? 'rgba(147, 140, 209, 0.20)'
                                : 'none'
                            }
                          >
                            {rowData[header]}
                          </Td>
                        ))}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </Flex>
            </Flex>
          )}
        </HStack>
      </Flex>
    </>
  );
};

export default DataImportDetails;
