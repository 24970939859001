import * as yup from 'yup';

import * as MESSAGES from '../messages';

const editUserSchema = yup.object().shape({
  firstName: yup.string(),
  lastName: yup.string(),
  role: yup.string(),
  company: yup.string(),
  email: yup.string().email(MESSAGES.EMAIL_MESSAGE),
  password: yup.string().min(6).max(20),
  confirmPassword: yup.string().oneOf([yup.ref('password'), undefined], 'Passwords must match'),
});

export default editUserSchema;
