import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Droppable } from 'react-beautiful-dnd';
import { Box, Flex, Text, Tooltip, useToast } from '@chakra-ui/react';
import { css } from '@emotion/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import {
  fetchDimensionItemsGroup,
  getDataIndicatorsKeys,
  getGroupItem,
  getItemId,
  setGroupItem,
} from 'redux/ItemPageSlice';
import { AppDispatch } from 'utils/GlobalHelpers';
import { getBaseScenario } from 'redux/ScenarioSlice';
import { getSelectedDriver } from 'redux/PlanPageSlice';
import { UpdatePropertyValues } from 'models/requestBody/updatePropertyValues.interface';
import CustomToast from 'components/CustomToast';
import Plus from 'components/Icons/Plus';

import apiService from '../../../../services';

import DesktopElements from './DesktopElements';
import AddNewItem from './AddNewItem';
import DesktopItemHeader from './DesktopItemHeader';

interface Properties {
  planPermission: boolean;
  longClickCheck: any;
  isLongClicked: boolean;
  refetchRequest: () => void;
}

const styles = {
  container: (open: boolean) => css`
    &:hover {
      cursor: pointer;
      transition: all 0.15s ease-out;

      ${!open && 'background: #9588e7'};
    }

    &::before {
      content: '';
      position: absolute;
      right: 50px;
      width: 30px;
      top: 20px;
    }

    .infoButton {
      ${!open && 'display: none'};
    }

    .infoButtonParent:hover .infoButton {
      display: block;
    }
  `,
};

const DesktopItems = ({
  longClickCheck,
  isLongClicked,
  refetchRequest,
  planPermission,
}: Properties) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const dispatch: AppDispatch = useDispatch();

  const itemId = useSelector(getItemId);
  const groupItem = useSelector(getGroupItem);
  const baseScenario = useSelector(getBaseScenario);
  const currentDriver = useSelector(getSelectedDriver);
  // const selectedElement = useSelector(getSelectedElement);
  const dataIndicatorsKeys = useSelector(getDataIndicatorsKeys);
  // const [displayGroupInfo, setDisplayGroupInfo]: any = useState(null);

  const { mutate: updateValue } = useMutation(
    ({ item_id, request_body }: UpdatePropertyValues) =>
      apiService.update_property_values_group({ item_id, request_body }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['propertyValues', currentDriver?.block_id]);
        dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));
        refetchRequest();
      },
      onError: (error: any) => {
        toast({
          duration: 2500,
          render: () => (
            <CustomToast title={error?.response?.data?.message || 'Error'} status='error' />
          ),
        });
      },
    },
  );

  const handleItem = (name: string): void => {
    if (groupItem === name) {
      dispatch(setGroupItem(null));
      return;
    }
    dispatch(setGroupItem(name));
  };

  const onSubmitValue = async (value: string, id: number, propertyValue: string) => {
    const propertyId = itemId?.properties?.find(
      (element: any) => element.name === propertyValue,
    )?.id;

    if (propertyId) {
      updateValue({
        item_id: id,
        request_body: {
          property_id: propertyId,
          value,
          scenario_id: baseScenario?.id,
        },
      });
    }
  };

  return (
    <>
      {dataIndicatorsKeys &&
        dataIndicatorsKeys?.map((dataKey: any, index: number) => {
          const droppableId: string = JSON.stringify({
            id: dataKey?.dim_id,
            name: dataKey?.name,
          });

          return (
            <React.Fragment key={index}>
              <Droppable droppableId={droppableId} key={dataKey?.dim_id}>
                {(provided) => {
                  return (
                    <Flex
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className='dimension'
                      key={index}
                      // zIndex={
                      //   displayGroupInfo === dataKey?.dim_id || selectedElement === index ? 2 : 1
                      // }
                      mr='15px'
                      flexDirection='column'
                      minW='310px'
                      alignSelf='flex-start'
                      alignItems='flex-start'
                      backgroundColor='rgba(67, 64, 111, 0.5)'
                      borderRadius='18px'
                      mb='15px'
                      padding='5px 10px 0 10px'
                      maxWidth={{ base: '400px', md: '400px' }}
                    >
                      <DesktopItemHeader
                        isLongClicked={isLongClicked}
                        dataKey={dataKey}
                        planPermission={planPermission}
                        longClickCheck={longClickCheck}
                        refetchRequest={refetchRequest}
                      />
                      <DesktopElements
                        {...{
                          _index: index,
                          dataKey,
                          styles,
                          onSubmitValue,
                          longClickCheck,
                          refetchRequest,
                          isLongClicked,
                          planPermission,
                        }}
                      />

                      <AddNewItem
                        {...{
                          dataKey,
                          styles,
                          planPermission,
                          refetchRequest,
                        }}
                      />

                      <Flex
                        justifyContent='center'
                        alignItems='center'
                        pb='15px'
                        cursor={planPermission ? 'not-allowed' : 'pointer'}
                        onClick={planPermission ? undefined : () => handleItem(dataKey?.name)}
                      >
                        <Box mr='15px'>
                          <Plus height={5} width={5} />
                        </Box>
                        <Tooltip label={currentDriver?.dim_name}>
                          <Text fontSize='1xl' pt='2px'>
                            {currentDriver?.dim_name}
                          </Text>
                        </Tooltip>
                      </Flex>
                    </Flex>
                  );
                }}
              </Droppable>
            </React.Fragment>
          );
        })}
    </>
  );
};

export default DesktopItems;
