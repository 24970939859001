/* eslint-disable unicorn/no-for-loop */
import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Menu,
  Image,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  VStack,
  Button,
  Flex,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  Tooltip,
  useMediaQuery,
  TabIndicator,
  Checkbox,
  Box,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { BsArrowRight } from 'react-icons/bs';

import warningIcon from 'assets/warning_icon.png';
import apiService from 'services';
import { textStyles } from 'pages/ModelOverviewPage/Import/Helper';
import { useItemMapping } from 'hooks/ItemMapError';
import {
  clearStepperFormData,
  createAction,
  fetchDimensionItems,
  getDistinctCSV,
  getEditActionForm,
  getNoheadersStatus,
  getSelectedAreaOfTable,
  getSelectedDataFileDetails,
  handleActionStepPrevious,
  putActionData,
  setActionCreateForm,
  setClearSelectedAreasTable,
  setSelectedAreasProperties,
} from 'redux/DataImportSlice';
import { getBlockDetails } from 'redux/PlanPageSlice';
import CustomCheckboxIcon from 'components/Icons/CustomCheckboxIcon';

interface Properties {
  getFormValue: any;
  setIsFinalStep: any;
  setSelectedActionId: any;
  setSelectedMapType: any;
  setScenarioId: any;
}

const DimensionSelectionSteps = ({
  getFormValue,
  setIsFinalStep,
  setSelectedActionId,
  setSelectedMapType,
  setScenarioId,
}: Properties) => {
  const dispatch: any = useDispatch();
  const distinctCSVValues = useSelector(getDistinctCSV);
  const blockDetails = useSelector(getBlockDetails);
  const [isLaptop] = useMediaQuery('(max-width: 1200px)');
  const dimensions = blockDetails?.dimensions;
  const [tabIndex, setTabIndex] = useState(-1);
  const [dimensionMap, setDimensionMap] = useState<Array<any>>([]);
  const [finalBlockDimMap, setFinalBlockDimMap] = useState<any>(null);
  const isHeader = useSelector(getNoheadersStatus);
  const sourceFileId = useSelector(getSelectedDataFileDetails);
  const dimensionMapLength = dimensionMap.length - 1;
  const [mappedColumnData, setMappedColumnData] = useState();
  const stepperFormEditable = useSelector(getEditActionForm);
  const area = useSelector(getSelectedAreaOfTable);
  const isDimension = dimensions && dimensions?.length === 1;

  useEffect(() => {
    dispatch(fetchDimensionItems({}));
  }, []);

  useEffect(() => {
    if (dimensions && dimensions?.length) {
      const dimArray: any[] = dimensions
        .filter((dim: any) => dim.name !== 'Time')
        .map((dim: any) => {
          return {
            dim_id: dim.id,
            dim_name: dim.name,
            skip: true,
            column: null,
            item_map: [],
            dim_items: null,
          };
        });
      setDimensionMap(dimArray);
      setTabIndex(0);
    }
  }, [dimensions]);

  useEffect(() => {
    if (getFormValue?.columnIndicatorMappedData) {
      const mappedColumn: any = getFormValue?.columnIndicatorMappedData.reduce(
        (accumulator: any, item: any) => {
          accumulator[item.key] = item.value || null;
          return accumulator;
        },
        {},
      );
      setMappedColumnData(mappedColumn);
    }
  }, [getFormValue?.columnIndicatorMappedData]);

  useEffect(() => {
    if (dimensionMap) {
      const blockDimensionMap =
        dimensionMap &&
        dimensionMap?.map((items: any) => {
          // const newItemMap = items?.item_map
          //   ?.filter((item: any) => item[Object.keys(item)[0]] !== null)
          //   ?.map((itemId: any) => {
          //     const key = Object.keys(itemId)[0];
          //     const { id } = itemId[key];
          //     return { [key]: id };
          //   });
          let newItemMap = [];
          if (items?.item_map?.length > 0) {
            newItemMap = items.item_map
              .filter((item: any) => {
                const key = Object.keys(item)[0];
                return key && item[key] !== null && item[key] !== undefined;
              })
              .map((item: any) => {
                const key = Object.keys(item)[0];
                if (key && item[key] && item[key].id !== undefined) {
                  const { id } = item[key];
                  return { [key]: id };
                }
                return null;
              })
              .filter((mappedItem: any) => mappedItem !== null);
          }

          return {
            dim_id: items?.dim_id,
            skip: items?.skip,
            column: items?.column?.current || null,
            item_map: newItemMap,
          };
        });
      setFinalBlockDimMap(blockDimensionMap);
    }
  }, [dimensionMap]);

  const [isMapItemsManually, setMapItemsManually] = useState(!!getFormValue?.mapItemsManually);

  const onMapItemManually = (index: number, mapItemsManually: Boolean) => {
    const dimArray = JSON.parse(JSON.stringify(dimensionMap));
    dimArray[index].mapItemsManually = mapItemsManually;
    setDimensionMap(dimArray);
    setMapItemsManually(!isMapItemsManually);
  };

  useEffect(() => {
    if (getFormValue?.dimensionItemsData && distinctCSVValues && dimensions && dimensions?.length) {
      const dimArray: any[] = [];
      const promiseArray: any[] = [];
      const filteredDimensions: Array<any> = dimensions.filter((dim: any) => dim.name !== 'Time');
      filteredDimensions.map((dim: any) => {
        promiseArray.push(
          apiService.get_dimension_items(Number(dim.id), {
            params: { scenario_id: getFormValue?.selectedScenario },
          }),
        );
        return dim;
      });
      Promise.all(promiseArray).then((itemsResponse: any) => {
        for (let dimIndex = 0; dimIndex < filteredDimensions.length; dimIndex += 1) {
          const dim: any = filteredDimensions[dimIndex];
          const dimItem: any = getFormValue?.dimensionItemsData.find((object: any) => {
            return object.dim_id === dim.id;
          });
          const item: any = dimItem ? JSON.parse(JSON.stringify(dimItem)) : null;
          if (item && !item.skip) {
            item.dim_items = itemsResponse[dimIndex].data.items;
            const column = distinctCSVValues.find((cool: any) => {
              return cool.current === item.column;
            });
            if (column) {
              item.column = column;

              const dimMapping: any = column.distinctValues.map((eachValue: string) => {
                const foundMappedObject = item.item_map.find((eachObject: any) => {
                  return eachObject[eachValue];
                });
                const itemId =
                  foundMappedObject && eachValue in foundMappedObject
                    ? foundMappedObject[eachValue]
                    : null;
                const foundItem: any = itemId
                  ? item.dim_items.find((eachObject: any) => {
                      return eachObject.id === itemId;
                    })
                  : !isMapItemsManually
                  ? item.dim_items.find((eachObject: any) => {
                      return eachObject.name === eachValue;
                    })
                  : null;

                // Edit Mapping with item Id and Map or Automatic Mapping in Edit mode
                return { [eachValue]: foundItem ?? null };
              });
              item.item_map = dimMapping;
            }

            if (item.column) item.skip = false;
            dimArray.push({
              dim_id: dim.id,
              dim_name: dim.name,
              skip: item.skip,
              column: item.column,
              item_map: item.item_map,
              dim_items: item.dim_items,
              mapItemsManually: false,
            });
          } else {
            dimArray.push({
              dim_id: dim.id,
              dim_name: dim.name,
              skip: false,
              column: null,
              item_map: [],
              dim_items: null,
              mapItemsManually: false,
            });
          }
        }

        setDimensionMap(dimArray);
      });
    }
  }, [getFormValue?.dimensionItemsData, dimensions?.length, distinctCSVValues?.length]);

  const mappingSkipped = (index: number) => {
    const dimArray = JSON.parse(JSON.stringify(dimensionMap));
    dimArray[index].skip = true;
    dimArray[index].column = null;
    dimArray[index].item_map = [];
    setDimensionMap(dimArray);
  };

  const selectColumn = async (index: number, column: any) => {
    const dimArray = JSON.parse(JSON.stringify(dimensionMap));
    if (!dimArray[index]?.dim_items) {
      dispatch(
        fetchDimensionItems({
          dimensionId: Number(dimArray[index]?.dim_id),
          parameters: { scenario_id: getFormValue?.selectedScenario },
        }),
      ).then((response: any) => {
        const itemsData = response?.payload;
        dimArray[index].dim_items = itemsData;
        const dimMapping: any = column?.distinctValues?.map((eachValue: string) => {
          const findMatchData = itemsData?.find((item: any) => {
            return item?.name.toLowerCase() === eachValue.toLowerCase();
          });
          if (findMatchData) {
            return { [eachValue]: findMatchData };
          }
          return { [eachValue]: null };
        });
        dimArray[index].item_map = dimMapping;
        dimArray[index].column = column;
        if (dimArray[index]?.column) dimArray[index].skip = false;
        setDimensionMap(dimArray);
      });
    } else {
      dimArray[index].column = column;
      if (dimArray[index]?.column) dimArray[index].skip = false;
      setDimensionMap(dimArray);
    }
  };
  const mapItem = (index: number, item: string, columnIndex: number, dimItem: any) => {
    const dimArray = JSON.parse(JSON.stringify(dimensionMap));
    dimArray[index].item_map[columnIndex][item] = dimItem;
    setDimensionMap(dimArray);
  };

  const onSubmit = async () => {
    const object: any = {};
    if (
      getFormValue?.rowsIndicatorMappedData &&
      Object.keys(getFormValue?.rowsIndicatorMappedData)
    ) {
      Object.keys(getFormValue?.rowsIndicatorMappedData).map((key: string) => {
        if (getFormValue?.rowsIndicatorMappedData[key]) {
          object[getFormValue?.rowsIndicatorMappedData[key]] = key;
        }
        return key;
      });
    }
    if (mappedColumnData && Object.keys(mappedColumnData)) {
      Object.keys(mappedColumnData).map((key: string) => {
        if (mappedColumnData[key]) {
          object[mappedColumnData[key]] = key;
        }
        return key;
      });
    }
    const requestBody: any = {
      name: getFormValue?.name,
      description: getFormValue?.description,
      mappings: [
        {
          block_id: getFormValue?.blockId,
          time_column_map: getFormValue?.multiTimePeriodValue,
          block_dimension_map: finalBlockDimMap,
          indicator_map: {
            ...object,
          },
          indicator: getFormValue?.singleIndicatorValue,
          indicator_column: getFormValue?.rowsIndicatorTableColumn ?? null,
          value_column: getFormValue?.columnNumericValueoofRowsIndicatorType,
          shaddedArea: area,
        },
      ],
      scenario_id: getFormValue?.selectedScenario,
      mapping_type: getFormValue?.selectedTimePeriod,
      action_type: 'data',

      time_column: getFormValue?.singleTimePeriodValue,
      period_type: getFormValue?.dataPeriod === '1' ? 'single' : 'multiple',
      missing_header: isHeader,
      period_start_date: getFormValue?.startDate,
      is_multiple_indicator: getFormValue.importindicatroType === '2',
      indicator_mapping_type: getFormValue?.multipleIndicatrosType,
      mapItemsManually: isMapItemsManually,
    };
    if (stepperFormEditable?.isEditForm) {
      await dispatch(
        putActionData({
          dataSourceId: sourceFileId?.id,
          actionId: stepperFormEditable?.actionId,
          request_body: requestBody,
        }),
      );
      await Promise.all([
        setSelectedActionId(stepperFormEditable?.actionId),
        setSelectedMapType(stepperFormEditable?.actionType),
        setScenarioId(stepperFormEditable?.scenarioId),
      ]);
    } else {
      const response: any = await dispatch(
        createAction({ dataSourceId: sourceFileId?.id, request_body: requestBody }),
      );
      await Promise.all([
        setSelectedActionId(response?.payload?.id),
        setSelectedMapType(response?.payload?.action_type),
        setScenarioId(response?.payload?.scenario_id),
      ]);
    }
    await dispatch(setClearSelectedAreasTable());
    setIsFinalStep(true);
  };

  const handleMapItem = useCallback(
    (item: any, columnIndex: number, dimItem: any) => mapItem(tabIndex, item, columnIndex, dimItem),
    [tabIndex, mapItem],
  );

  const filteredItems = useMemo(() => {
    return dimensionMap[tabIndex]?.column?.distinctValues?.filter(
      (item: any) => item !== undefined && item !== '\n',
    );
  }, [dimensionMap, tabIndex]);

  const currentDimension = dimensionMap[tabIndex];

  const allItemsMapped = useItemMapping({ data: currentDimension?.item_map });

  return (
    <VStack display='flex' justify={'center'} w={'100%'} pt={3}>
      <Flex justifyContent={'space-between'} w={'full'} align={'center'}>
        <Text
          color={'#6562CF'}
          fontSize='lg'
          fontFamily={'regular_roboto'}
          fontWeight={'400'}
          textAlign={'start'}
        >
          Select dimension columns{' '}
        </Text>
        <CloseIcon
          color={'black'}
          width={3}
          height={3}
          cursor={'pointer'}
          onClick={async () => {
            await dispatch(setActionCreateForm(false));
            await dispatch(clearStepperFormData());
            await dispatch(setClearSelectedAreasTable());
          }}
        />
      </Flex>
      {isDimension ? (
        <Text color={'black'} {...textStyles} textAlign={'start'} width={'100%'} pb={5}>
          No dimension found
        </Text>
      ) : (
        <>
          <Text color={'black'} {...textStyles} textAlign={'start'} width={'100%'} pb={5}>
            This block contains{' '}
            <Text as={'span'} fontWeight={600}>
              {' '}
              {dimensionMap[tabIndex]?.dim_name}{' '}
            </Text>{' '}
            information. Select the column which contains the product list, or skip to import the
            totals
          </Text>
          <Tabs
            position='relative'
            variant='unstyled'
            index={tabIndex}
            onChange={setTabIndex}
            border={'none'}
          >
            <TabList border={'none'}>
              {dimensionMap &&
                dimensionMap?.map((dim: any) => (
                  <Tab
                    borderLeft='none'
                    borderRight='none'
                    _selected={{
                      backgroundColor: 'white',
                      borderTopRadius: '10px',
                    }}
                    color='black'
                    key={dim.dim_id}
                    fontSize={'sm'}
                    fontFamily='regular_roboto'
                  >
                    <Text>{dim.dim_name}</Text>
                  </Tab>
                ))}
            </TabList>
            <TabIndicator
              style={{
                marginTop: '-1.5px',
                height: '2px',
                backgroundColor: '#6562CF',
                borderRadius: '10px',
              }}
            />
            <TabPanels>
              {dimensionMap &&
                dimensionMap?.map((dim: any, index: number) => (
                  <TabPanel key={dim.dim_id}>
                    <VStack align={'start'}>
                      <Text
                        color={'#6562CF'}
                        fontSize='lg'
                        fontFamily={'regular_roboto'}
                        fontWeight={'400'}
                        textAlign={'start'}
                        py={1}
                      >
                        Select column containing&nbsp;
                        <Text
                          as={'span'}
                          fontSize='lg'
                          fontFamily={'regular_roboto'}
                          fontWeight={'400'}
                        >
                          {dimensionMap[tabIndex]?.dim_name?.toLowerCase()}
                        </Text>
                      </Text>
                      <Flex justify={'start'} align={'center'} gap={5}>
                        <Menu closeOnSelect={true} isLazy>
                          <MenuButton
                            as={Button}
                            backgroundColor='white'
                            maxHeight={'50px'}
                            borderRadius='5px'
                            minWidth='140px'
                            textAlign='left'
                            padding='0 .8rem'
                            boxShadow='rgb(139, 137, 177) 0px 0px 0px 0.6px'
                            rightIcon={<ChevronDownIcon fontSize='md' />}
                            fontWeight='300'
                            fontSize='xs'
                            color='black.900'
                            w='40%'
                          >
                            {dimensionMap[index].column?.modified || (
                              <Text opacity={0.5}>select</Text>
                            )}
                          </MenuButton>
                          <MenuList
                            height='fit-content'
                            maxHeight='20vh'
                            overflowY='auto'
                            className='scroll'
                          >
                            <MenuOptionGroup
                              defaultValue={dimensionMap[index]?.column?.modified ?? ''}
                            >
                              <MenuItemOption
                                value={''}
                                onClick={() => {
                                  selectColumn(index, null);
                                  dispatch(
                                    setSelectedAreasProperties({
                                      [`dimSteps.${index}`]: null,
                                    }),
                                  );
                                }}
                              >
                                Select column
                              </MenuItemOption>
                              ;
                              {distinctCSVValues &&
                                distinctCSVValues
                                  ?.filter((csv: any) => {
                                    const foundIndex = dimensionMap.findIndex((dim_data: any) => {
                                      return (
                                        dim_data?.column &&
                                        dim_data?.column.current === csv?.current
                                      );
                                    });
                                    return !(foundIndex > -1);
                                  })
                                  ?.map((item: any) => (
                                    <MenuItemOption
                                      onClick={() => {
                                        selectColumn(index, item);
                                        dispatch(
                                          setSelectedAreasProperties({
                                            [`dimSteps.${index}`]: item?.current,
                                          }),
                                        );
                                      }}
                                      key={item?.current}
                                      value={item?.current}
                                    >
                                      {item?.modified}
                                    </MenuItemOption>
                                  ))}
                            </MenuOptionGroup>
                          </MenuList>
                        </Menu>
                        {dimensionMapLength > 0 && (
                          <Text
                            cursor={'pointer'}
                            {...textStyles}
                            color={'black'}
                            as={'u'}
                            onClick={() => {
                              if (dimensionMapLength > tabIndex) {
                                setTabIndex(tabIndex + 1);
                              } else {
                                setTabIndex(0);
                              }
                              mappingSkipped(tabIndex);
                              dispatch(
                                setSelectedAreasProperties({
                                  [`dimSteps.${tabIndex}`]: null,
                                }),
                              );
                            }}
                          >
                            Skip
                          </Text>
                        )}
                      </Flex>
                    </VStack>
                  </TabPanel>
                ))}
              {dimensionMap[tabIndex]?.column && (
                <>
                  <Flex gap={2} w={'100%'} py={2}>
                    <Checkbox
                      className='checkboxStyle'
                      background={'transparent'}
                      borderColor={'black'}
                      border={'2px solid black'}
                      icon={<CustomCheckboxIcon isChecked={isMapItemsManually} />}
                      isChecked={isMapItemsManually}
                      // isChecked={mapItemsManually || dimensionMap[tabIndex]?.mapItemsManually}
                      onChange={(event) => {
                        onMapItemManually(tabIndex, event.target.checked);
                      }}
                    ></Checkbox>
                    <Text fontSize='0.9rem' color='black' fontFamily={'Roboto Regular'}>
                      Map items manually ?
                    </Text>
                  </Flex>
                  {!allItemsMapped && (
                    <Box
                      justifyContent='space-between !important'
                      width='100%'
                      my='2'
                      border='1px solid #EB7500'
                      p={1}
                    >
                      <Flex ml='2'>
                        <Image src={warningIcon} height={4} width={4} />
                        <Text fontSize='xs' fontFamily='bold_roboto' size='xs' color='black'>
                          Warnings
                        </Text>
                      </Flex>
                      <Box>
                        <Text ml='2' fontSize='xs' fontFamily='regular_roboto' color='black'>
                          Oops! It seems a few items are unmapped.
                        </Text>
                      </Box>
                    </Box>
                  )}
                  <Text
                    color={'#6562CF'}
                    fontSize='lg'
                    fontFamily={'regular_roboto'}
                    fontWeight={'400'}
                    textAlign={'start'}
                    py={1}
                  >
                    Map the values into Blox
                  </Text>

                  <Flex justifyContent={'space-between'} align={'center'} w='full'>
                    <Text
                      fontSize='1rem'
                      color='black'
                      fontFamily={'regular_roboto'}
                      fontWeight={'600'}
                    >
                      Name in File
                    </Text>
                    <Flex w={'41%'}>
                      <Text
                        fontSize='1rem'
                        color='black'
                        fontFamily={'regular_roboto'}
                        fontWeight={'600'}
                        paddingStart={'2rem'}
                      >
                        Item in Blox
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex
                    maxH={'20vh'}
                    height={'fit-content'}
                    overflowY={'auto'}
                    className='scroll'
                    flexDirection={'column'}
                  >
                    {filteredItems?.length > 0 ? (
                      filteredItems.map((item: any, columnIndex: number) => (
                        <Flex
                          key={item}
                          justifyContent='space-between'
                          align='center'
                          pt={3}
                          pe={1}
                        >
                          <Tooltip label={item}>
                            <Text
                              fontSize='1rem'
                              color='black'
                              fontFamily='Roboto Regular'
                              m={0}
                              maxW='fit-content'
                              noOfLines={1}
                              h='fit-content'
                              fontWeight={500}
                            >
                              {item}
                            </Text>
                          </Tooltip>
                          <Flex align='center' gap={2} justify='end' w='50%'>
                            <BsArrowRight color='black' style={{ width: '20px', height: '20px' }} />
                            <Menu closeOnSelect={true} isLazy>
                              <MenuButton
                                isDisabled={!isMapItemsManually}
                                // dimensionMap[tabIndex]?.mapItemsManually

                                as={Button}
                                backgroundColor='white'
                                maxHeight='40px'
                                borderRadius='5px'
                                minWidth='150px'
                                textAlign='left'
                                padding='0 .8rem'
                                boxShadow='rgb(139, 137, 177) 0px 0px 0px 0.6px'
                                rightIcon={<ChevronDownIcon fontSize='md' />}
                                fontWeight='300'
                                fontSize='xs'
                                color='black.900'
                                w='70%'
                                border='0.6px solid #8B89B1'
                              >
                                <Text me={3} noOfLines={1} height='fit-content'>
                                  {currentDimension?.item_map?.[columnIndex]?.[item]?.name || ''}
                                </Text>
                              </MenuButton>
                              <MenuList
                                height='fit-content'
                                maxHeight='20vh'
                                overflowY='auto'
                                className='scroll'
                              >
                                <MenuOptionGroup
                                  defaultValue={
                                    currentDimension?.item_map?.[columnIndex]?.[item]?.id || ''
                                  }
                                >
                                  <MenuItemOption
                                    onClick={() => handleMapItem(item, columnIndex, null)}
                                  >
                                    Select Item
                                  </MenuItemOption>
                                  {currentDimension?.dim_items
                                    ?.filter((dimItem: any) => {
                                      const foundIndex = currentDimension?.item_map?.findIndex(
                                        (itemMap: any) =>
                                          itemMap[Object.keys(itemMap)[0]]?.id === dimItem?.id,
                                      );
                                      return !(foundIndex > -1);
                                    })
                                    .map((dimItem: any) => (
                                      <MenuItemOption
                                        key={dimItem?.id}
                                        value={dimItem?.id}
                                        onClick={() => handleMapItem(item, columnIndex, dimItem)}
                                      >
                                        {dimItem?.name}
                                      </MenuItemOption>
                                    ))}
                                </MenuOptionGroup>
                              </MenuList>
                            </Menu>
                          </Flex>
                        </Flex>
                      ))
                    ) : (
                      <Text color='black'>No properties found</Text>
                    )}
                  </Flex>
                </>
              )}
            </TabPanels>
          </Tabs>
        </>
      )}
      <Flex alignItems={'center'} justifyContent={'flex-end'} pb={8} pt='5' w={'100%'}>
        <Flex my={2} color={'black.900'} fontSize='xs' display={'flex'} alignContent={'center'}>
          <Button
            width={'80px'}
            height={'30px'}
            borderRadius='xs'
            fontSize='xs'
            bgColor='#EFEEFE'
            color='#6562CF'
            fontWeight='400'
            me={'1rem'}
            fontFamily={'Roboto Regular'}
            px={isLaptop ? '8' : '10'}
            py={isLaptop ? '5' : '6'}
            onClick={() => dispatch(handleActionStepPrevious())}
            textTransform={'capitalize'}
          >
            Previous
          </Button>

          <Button
            _hover={{ _disabled: {} }}
            type='submit'
            borderRadius='xs'
            fontWeight='400'
            fontFamily={'Roboto Regular'}
            width={'fit-content'}
            px={isLaptop ? '6' : '10'}
            py={isLaptop ? '5' : '6'}
            height={'30px'}
            fontSize='xs'
            bgColor='#6562CF'
            textTransform={'capitalize'}
            onClick={onSubmit}
            color={'white'}
          >
            Continue
            {/* {isSubmitting && <Spinner ml={'4'} />} */}
          </Button>
        </Flex>
      </Flex>
    </VStack>
  );
};

export default DimensionSelectionSteps;
