const convertDate = (inputDate: string): string => {
  const [month, year] = inputDate.split(' ');

  const shortYear = year.slice(-2);

  return `${month}-${shortYear}`;
};

const formateDate = (date: any) => {
  const formatStartDate = `${new Date(date).toLocaleDateString('en-US', {
    month: 'short',
  })} ${new Date(date).getFullYear()}`;
  return formatStartDate;
};

const totalMonthsGap = (start: any, end: any) => {
  const totalMonths =
    start && end
      ? (new Date(end).getFullYear() - new Date(start).getFullYear()) * 12 +
        (new Date(end).getMonth() - new Date(start).getMonth())
      : null;
  return totalMonths;
};

function getPreviousMonth(date: any) {
  const previousMonthDate = new Date(date);
  previousMonthDate.setMonth(previousMonthDate.getMonth() - 1);
  return previousMonthDate;
}

const priorYear = (date: any) => {
  const oneYearPrior = new Date(date);
  oneYearPrior.setMonth(oneYearPrior.getMonth() - 12);
  return oneYearPrior;
};

function getDate(date: any) {
  if (!date) return null;
  const monthMap: any = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12',
  };
  const [monthString, yearString] = date.split('-');
  const month = monthMap[monthString];
  const year = `20${yearString}`;
  const newDate = new Date(`${year}-${month}-01`);
  return newDate;
}

export { convertDate, priorYear, formateDate, getDate, getPreviousMonth, totalMonthsGap };
