import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
  selected?: boolean;
}

const TableView = ({ width = 10, height = 10, color = 'green.900', selected }: Properties) => {
  return (
    <Icon
      viewBox='0 0 30 35'
      width={width}
      height={height}
      color={color}
      style={{
        padding: '.3rem',
        backgroundColor: selected ? '#4bb6b9' : '',
        borderRadius: selected ? '8px' : '',
      }}
    >
      <svg
        viewBox='0 0 46 46'
        width='30'
        height='35'
        fill='#000'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_1077_47)'>
          <rect width='46' height='46' fill={selected ? '#4bb6b9' : '#796CD6'} />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M3 3H11.928V16.3247H3V3ZM0 0H3H11.928H14.928V3V8.04578H31.072V3V0H34.072H43H46V3V16.3247V19.3247H43H34.072H31.072V16.3247V11.2789H24.3042V34.7211H31.072V29.6753V26.6753H34.072H43H46V29.6753V43V46H43H34.072H31.072V43V37.9542L24.3042 37.9542L21.2412 37.9542V34.7211L21.2412 11.2789H14.928V16.3247V19.3247H11.928H3H0V16.3247V3V0ZM34.072 3H43V16.3247H34.072V3ZM43 29.6753H34.072V43H43V29.6753Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_1077_47'>
            <rect width='46' height='46' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};

export default TableView;
