import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
  marginLeft?: string;
}

const ChevronRight = ({
  width = 1.5,
  height = 1.5,
  color = '#212121',
  marginLeft = '0px',
}: Properties) => {
  return (
    <Icon viewBox='0 0 5 9' width={width} height={height} marginLeft={marginLeft}>
      <path d='M4.5 4.5L0 8.39711L0 0.602886L4.5 4.5Z' fill={color} />
    </Icon>
  );
};

export default ChevronRight;
