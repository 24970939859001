import { FormatProperties } from 'models/response/modelOutput.interface';

const formatValue = (totalValues: any, formatProperties: FormatProperties) => {
  const {
    decimal_points: decimalPoints,
    measure,
    negative_parentheses: negativeParentheses,
    scale,
    thousand_separators: thousandSeparators,
  } = formatProperties;
  const total =
    typeof totalValues === 'number'
      ? totalValues
      : totalValues
      ? totalValues.reduce((accumulator: any, item: any) => {
          accumulator += Number(item);
          return accumulator;
        }, 0)
      : totalValues;

  if (total < 0 && negativeParentheses) {
    if (scale === 'thousands') {
      if (thousandSeparators) {
        if (measure) {
          if (['$', '£', '€'].includes(measure)) {
            return `(${measure}${((total * -1) / 1000).toLocaleString('en-GB', {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })}k)`;
          }

          return `(${(total / 1000).toLocaleString('en-GB', {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}k${measure})`;
        }

        // measure === NULL
        return `(${(total / 1000).toLocaleString('en-GB', {
          minimumFractionDigits: decimalPoints,
          maximumFractionDigits: decimalPoints,
        })}k)`;
      }

      // thousandSeparators === false
      return !measure
        ? `(${(total / 1000).toFixed(decimalPoints)}k)`
        : ['$', '£', '€'].includes(measure)
        ? `(${measure}${(total / 1000).toFixed(decimalPoints)}k)`
        : `(${(total / 1000).toFixed(decimalPoints)}k${measure})`;
    }

    if (scale === 'millions') {
      if (thousandSeparators) {
        if (measure) {
          if (['$', '£', '€'].includes(measure)) {
            return `(${measure}${((-1 * total) / 1_000_000).toLocaleString('en-GB', {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })}mn)`;
          }

          return `(${(total / 1_000_000).toLocaleString('en-GB', {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}mn${measure})`;
        }

        // measure === NULL
        return `(${(total / 1_000_000).toLocaleString('en-GB', {
          minimumFractionDigits: decimalPoints,
          maximumFractionDigits: decimalPoints,
        })}mn)`;
      }

      // thousandSeparators === false
      return !measure
        ? `(${(total / 1_000_000).toFixed(decimalPoints)}mn)`
        : ['$', '£', '€'].includes(measure)
        ? `(${measure}${((-1 * total) / 1_000_000).toFixed(decimalPoints)}mn)`
        : `(${(total / 1_000_000).toFixed(decimalPoints)}mn${measure})`;
    }

    if (scale === 'billions') {
      if (thousandSeparators) {
        if (measure) {
          if (['$', '£', '€'].includes(measure)) {
            return `(${measure}${((-1 * total) / 1_000_000_000).toLocaleString('en-GB', {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })}bn)`;
          }

          return `(${(total / 1_000_000_000).toLocaleString('en-GB', {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}bn${measure})`;
        }

        // measure === NULL
        return `(${(total / 1_000_000_000).toLocaleString('en-GB', {
          minimumFractionDigits: decimalPoints,
          maximumFractionDigits: decimalPoints,
        })}bn)`;
      }

      // thousandSeparators === false
      return !measure
        ? `(${(total / 1_000_000_000).toFixed(decimalPoints)}bn)`
        : ['$', '£', '€'].includes(measure)
        ? `(${measure}${((-1 * total) / 1_000_000_000).toFixed(decimalPoints)}bn)`
        : `(${(total / 1_000_000_000).toFixed(decimalPoints)}bn${measure})`;
    }

    if (!scale) {
      if (thousandSeparators) {
        if (measure) {
          if (['$', '£', '€'].includes(measure)) {
            return `(${measure}${(-1 * total).toLocaleString('en-GB', {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })})`;
          }

          return `(${total.toLocaleString('en-GB', {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}${measure})`;
        }

        // measure === NULL
        return `(${total.toLocaleString('en-GB', {
          minimumFractionDigits: decimalPoints,
          maximumFractionDigits: decimalPoints,
        })})`;
      }

      // thousandSeparators === false
      return !measure
        ? `(${total.toFixed(decimalPoints)})`
        : ['$', '£', '€'].includes(measure)
        ? `(${measure}${(-1 * total).toFixed(decimalPoints)})`
        : `(${total.toFixed(decimalPoints)}${measure})`;
    }
  }

  if (total < 0 && !negativeParentheses) {
    if (scale === 'thousands') {
      if (thousandSeparators) {
        if (measure) {
          if (['$', '£', '€'].includes(measure)) {
            return `${measure}${(total / 1000).toLocaleString('en-GB', {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })}k`;
          }

          return `${(total / 1000).toLocaleString('en-GB', {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}k${measure}`;
        }

        // measure === NULL
        return `${(total / 1000).toLocaleString('en-GB', {
          minimumFractionDigits: decimalPoints,
          maximumFractionDigits: decimalPoints,
        })}k`;
      }

      // thousandSeparators === false
      return !measure
        ? `${(total / 1000).toFixed(decimalPoints)}k`
        : ['$', '£', '€'].includes(measure)
        ? `${measure}${(total / 1000).toFixed(decimalPoints)}k`
        : `${(total / 1000).toFixed(decimalPoints)}k${measure}`;
    }

    if (scale === 'millions') {
      if (thousandSeparators) {
        if (measure) {
          if (['$', '£', '€'].includes(measure)) {
            return `${measure}${(total / 1_000_000).toLocaleString('en-GB', {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })}mn`;
          }

          return `${(total / 1_000_000).toLocaleString('en-GB', {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}mn${measure}`;
        }

        // measure === NULL
        return `${(total / 1_000_000).toLocaleString('en-GB', {
          minimumFractionDigits: decimalPoints,
          maximumFractionDigits: decimalPoints,
        })}mn`;
      }

      // thousandSeparators === false
      return !measure
        ? `${(total / 1_000_000).toFixed(decimalPoints)}mn`
        : ['$', '£', '€'].includes(measure)
        ? `${measure}${(total / 1_000_000).toFixed(decimalPoints)}mn`
        : `${(total / 1_000_000).toFixed(decimalPoints)}mn${measure}`;
    }

    if (scale === 'billions') {
      if (thousandSeparators) {
        if (measure) {
          if (['$', '£', '€'].includes(measure)) {
            return `${measure}${(total / 1_000_000_000).toLocaleString('en-GB', {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })}bn`;
          }

          return `${(total / 1_000_000_000).toLocaleString('en-GB', {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}bn${measure}`;
        }

        // measure === NULL
        return `${(total / 1_000_000_000).toLocaleString('en-GB', {
          minimumFractionDigits: decimalPoints,
          maximumFractionDigits: decimalPoints,
        })}bn`;
      }

      // thousandSeparators === false
      return !measure
        ? `${(total / 1_000_000_000).toFixed(decimalPoints)}bn`
        : ['$', '£', '€'].includes(measure)
        ? `${measure}${(total / 1_000_000_000).toFixed(decimalPoints)}bn`
        : `${(total / 1_000_000_000).toFixed(decimalPoints)}bn${measure}`;
    }

    if (!scale) {
      if (thousandSeparators) {
        if (measure) {
          if (['$', '£', '€'].includes(measure)) {
            return `${measure}${total.toLocaleString('en-GB', {
              minimumFractionDigits: decimalPoints,
              maximumFractionDigits: decimalPoints,
            })}`;
          }

          return `${total.toLocaleString('en-GB', {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}${measure}`;
        }

        // measure === NULL
        return `${total.toLocaleString('en-GB', {
          minimumFractionDigits: decimalPoints,
          maximumFractionDigits: decimalPoints,
        })}`;
      }

      // thousandSeparators === false
      return !measure
        ? `${total.toFixed(decimalPoints)}`
        : ['$', '£', '€'].includes(measure)
        ? `${measure}${total.toFixed(decimalPoints)}`
        : `${total.toFixed(decimalPoints)}${measure}`;
    }
  }

  if (scale === 'thousands') {
    if (thousandSeparators) {
      if (measure) {
        if (['$', '£', '€'].includes(measure)) {
          return `${measure}${(total / 1000).toLocaleString('en-GB', {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}k`;
        }

        return `${(total / 1000).toLocaleString('en-GB', {
          minimumFractionDigits: decimalPoints,
          maximumFractionDigits: decimalPoints,
        })}k${measure}`;
      }

      // measure === NULL
      return `${(total / 1000).toLocaleString('en-GB', {
        minimumFractionDigits: decimalPoints,
        maximumFractionDigits: decimalPoints,
      })}k`;
    }

    // thousandSeparators === false
    return !measure
      ? `${(total / 1000).toFixed(decimalPoints)}k`
      : ['$', '£', '€'].includes(measure)
      ? `${measure}${(total / 1000).toFixed(decimalPoints)}k`
      : `${(total / 1000).toFixed(decimalPoints)}k${measure}`;
  }

  if (scale === 'millions') {
    if (thousandSeparators) {
      if (measure) {
        if (['$', '£', '€'].includes(measure)) {
          return `${measure}${(total / 1_000_000).toLocaleString('en-GB', {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}mn`;
        }

        return `${(total / 1_000_000).toLocaleString('en-GB', {
          minimumFractionDigits: decimalPoints,
          maximumFractionDigits: decimalPoints,
        })}mn${measure}`;
      }

      // measure === NULL
      return `${(total / 1_000_000).toLocaleString('en-GB', {
        minimumFractionDigits: decimalPoints,
        maximumFractionDigits: decimalPoints,
      })}mn`;
    }

    // thousandSeparators === false
    return !measure
      ? `${(total / 1_000_000).toFixed(decimalPoints)}mn`
      : ['$', '£', '€'].includes(measure)
      ? `${measure}${(total / 1_000_000).toFixed(decimalPoints)}mn`
      : `${(total / 1_000_000).toFixed(decimalPoints)}mn${measure}`;
  }

  if (scale === 'billions') {
    if (thousandSeparators) {
      if (measure) {
        if (['$', '£', '€'].includes(measure)) {
          return `${measure}${(total / 1_000_000_000).toLocaleString('en-GB', {
            minimumFractionDigits: decimalPoints,
            maximumFractionDigits: decimalPoints,
          })}bn`;
        }

        return `${(total / 1_000_000_000).toLocaleString('en-GB', {
          minimumFractionDigits: decimalPoints,
          maximumFractionDigits: decimalPoints,
        })}bn${measure}`;
      }

      // measure === NULL
      return `${(total / 1_000_000_000).toLocaleString('en-GB', {
        minimumFractionDigits: decimalPoints,
        maximumFractionDigits: decimalPoints,
      })}bn`;
    }

    // thousandSeparators === false
    return !measure
      ? `${(total / 1_000_000_000).toFixed(decimalPoints)}bn`
      : ['$', '£', '€'].includes(measure)
      ? `${measure}${(total / 1_000_000_000).toFixed(decimalPoints)}bn`
      : `${(total / 1_000_000_000).toFixed(decimalPoints)}bn${measure}`;
  }

  if (thousandSeparators) {
    if (measure) {
      if (['$', '£', '€'].includes(measure)) {
        return `${measure}${total?.toLocaleString('en-GB', {
          minimumFractionDigits: decimalPoints,
          maximumFractionDigits: decimalPoints,
        })}`;
      }

      return `${total?.toLocaleString('en-GB', {
        minimumFractionDigits: decimalPoints,
        maximumFractionDigits: decimalPoints,
      })}${measure}`;
    }

    // measure === NULL
    return total
      ? total?.toLocaleString('en-GB', {
          minimumFractionDigits: decimalPoints,
          maximumFractionDigits: decimalPoints,
        })
      : '0';
  }

  // thousandSeparators === false
  return !measure
    ? total?.toFixed(decimalPoints)
    : ['$', '£', '€'].includes(measure)
    ? `${measure}${total?.toFixed(decimalPoints)}`
    : `${total?.toFixed(decimalPoints)}${measure}`;
};

export default formatValue;
