import 'react-datepicker/dist/react-datepicker.css';

import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { MouseEventHandler, ReactNode, forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { GoChevronDown } from 'react-icons/go';

import datePickerStyle from './style';

interface Properties {
  value?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  planPermission?: boolean;
  styles?: React.CSSProperties;
}

const DatePickerInput = forwardRef<HTMLButtonElement, Properties>(
  ({ value, onClick, planPermission, styles }, reference) => {
    const handleClick = (event: any) => {
      event.preventDefault();
      if (onClick) onClick(event);
    };

    return (
      <>
        <Button
          ref={reference}
          cursor={planPermission ? 'not-allowed' : 'pointer'}
          onClick={(event: any) => (planPermission ? undefined : handleClick(event))}
          rightIcon={<GoChevronDown size={18} />}
          width={'100px'}
          height={'30px'}
          variant='picker'
          color={'black'}
          borderRadius={'5px'}
          border={'1px solid #8B89B1'}
          style={styles}
        >
          {value}
        </Button>
      </>
    );
  },
);

const DatePickerInputButton = ({
  selected,
  onChange,
  placeholder,
  label,
  errorMessage,
  timescale,
  onSelect,
  clearDateInput,
  dateId,
  startIn,
  endIn,
  deleteStart,
  clearSection = true,
  isDisabled,
  minDate,
  maxDate,
  styles,
}: any) => {
  return (
    <FormControl
      sx={datePickerStyle}
      data-testid='datePicker-test'
      style={{ width: 'fit-content' }}
    >
      {label && <FormLabel>{label}</FormLabel>}

      <DatePicker
        data-testid='datePicker-test'
        selected={selected}
        onChange={onChange}
        disabled={isDisabled}
        placeholderText={placeholder}
        dateFormat={timescale === 'Y' ? 'yyyy' : 'MMM yyyy'}
        showMonthYearPicker={timescale === 'M'}
        showYearPicker={timescale === 'Y'}
        customInput={<DatePickerInput planPermission={isDisabled} styles={styles} />}
        onSelect={onSelect}
        minDate={minDate}
        maxDate={maxDate}
      >
        {clearSection && (
          <Flex
            marginBottom='15px'
            borderTop='1px solid rgba(112, 138, 138, 0.3)'
            width='90%'
            display='flex'
            justifyContent='center'
            alignItems='center'
            paddingTop='10px'
            color='purple'
            onClick={() => {
              clearDateInput(dateId, deleteStart, startIn, endIn);
            }}
          >
            <Box>Clear Selection</Box>
          </Flex>
        )}
      </DatePicker>

      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};

export default DatePickerInputButton;
