import { Switch, ThemeProvider, Text, useMediaQuery, VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { groupByFilterChange } from 'redux/PlanPageSlice';
import { getAllScenarios } from 'redux/ScenarioSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

const theme = {
  components: {
    Switch: {
      baseStyle: {
        track: {
          borderRadius: '10px !important',
          bg: '#ACABC5',
          width: '2.5rem',
          height: '9px',
          _checked: {
            bg: '#ACABC5',
          },
          _hover: {
            bg: '#D6D5E2',
          },
          transition: 'background-color 0.3s ease',
        },
        thumb: {
          borderRadius: '10px !important',
          w: '1.3rem',
          h: '1.3rem',
          bg: 'white',
          boxShadow: 'none',
          transform: 'translateY(-30%)',
          _checked: {
            bg: '#6562CF',
            transform: 'translate(1.5rem, -30%)',
          },
          _hover: {
            bg: '#B3B0D7',
          },
          transition: 'background-color 0.3s ease, transform 0.3s ease',
        },
      },
    },
  },
};

export interface Properties {
  onChange: (value: any) => void;
  defaultValue: boolean;
}

const CustomToggle = ({ onChange, defaultValue }: Properties) => {
  const [toggleValue, setToggleValue] = useState(defaultValue);

  const isSmallLaptop = useMediaQuery('(max-width: 1415px)');
  const allscenarios = useSelector(getAllScenarios);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    setToggleValue(defaultValue);
  }, [defaultValue]);

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setToggleValue(newValue);
    if (newValue) {
      dispatch(groupByFilterChange('Total'));
    }

    onChange(newValue);
  };

  return (
    <ThemeProvider theme={theme}>
      <VStack>
        <Text alignSelf={'center'} fontSize={isSmallLaptop ? '11px' : '8px'} mt={'-0.4rem'}>
          Compare
        </Text>
        <Switch
          onChange={handleToggleChange}
          isChecked={toggleValue}
          isDisabled={!(allscenarios?.length > 1)}
          css={{
            cursor: allscenarios?.length > 1 ? 'pointer' : 'not-allowed',
            pointerEvents: allscenarios?.length > 1 ? 'auto' : 'none',
          }}
        />
      </VStack>
    </ThemeProvider>
  );
};

export default CustomToggle;
