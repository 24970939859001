const styles = {
  mapContainer: {
    height: '100%',
    position: 'relative',
    flexGrow: 1,
    '.react-flow__attribution': {
      display: 'none',
    },
    '.react-flow__minimap': {
      'border-radius': '10px',
      bottom: 0,
      right: 0,
    },
  },
};

export default styles;
