import React from 'react';
import { Box } from '@chakra-ui/react';

const DesktopModelUnderLine = () => (
  <React.Fragment>
    <Box
      w='264px'
      h='16px'
      mt='-12px'
      borderBottomRadius='xl'
      borderWidth='1'
      borderColor='grey.900'
      bg='white.900'
      zIndex={2}
    />
    <Box
      w='248px'
      h='16px'
      mt='-12px'
      borderBottomRadius='xl'
      borderWidth='1'
      borderColor='grey.900'
      bg='white.900'
      zIndex={1}
    />
  </React.Fragment>
);

export default DesktopModelUnderLine;
