import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const Chart = ({ width = 5, height = 5, color = 'green.900' }: Properties) => {
  return (
    <Icon viewBox='0 0 35 35' width={width} height={height} color={color}>
      <path d='M0 10.5H7.5V35H0V10.5ZM14 0H21V35H14V0ZM28 20H35V35H28V20Z' fill='currentColor' />
    </Icon>
  );
};

export default Chart;
