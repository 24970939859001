/* eslint-disable no-lonely-if */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unsafe-optional-chaining */
import { Box, Flex, Text } from '@chakra-ui/react';
import { ReactGrid } from '@silevis/reactgrid';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AddRowCellTemplate } from 'components/AddRowCell';
import { DateCellTemplate } from 'components/DateCell';
import DeleteGroupModal from 'components/DeleteGroupModal';
import { DeleteRowCellTemplate } from 'components/DeleteRowCell';
import { DropdownCellTemplate } from 'components/DropdownCell';
import { FiltersCellTemplate } from 'components/FiltersCell';
import FullScreenLoader from 'components/FullScreenLoader';
import { HeaderRowTemplate } from 'components/HeaderRow';
import { TextCellTemplate } from 'components/TextCell';
import PlanPermission from 'container/PlanPermission';
import {
  DeleteDimensionHeader,
  DeleteDimensionProperty,
  FetchDimensionGroup,
  FetchDimensionItems,
  FetchDimensionProperties,
  ReorderDimensionProperty,
  UpdateDimensionPropertyBulk,
  UpdateDimensionPropertyName,
  UpdateDimensionValues,
  UpdatePropertyHeader,
  getAddItem,
  getBaseScenario,
  getDimensionProperty,
  getFilteredDimensionItems,
  getLoaderStatus,
  getPropertiesSectionStatus,
  getSelectedDimension,
  getSettingsSectionStatus,
  handleAddItemStatus,
  handleDimensionItemsChange,
  resetBuilderDimension,
} from 'redux/BuilderModeSlice';
import { AddItemProperty, getDimensionFilters } from 'redux/ItemPageSlice';
// import { getTimeDataStatus, resetTimeStatusData } from 'redux/ModelsSlice';
import './Table.scss';

import { getColumns, getRows, reorderArray } from './TableHelpers';

interface Focus {
  rowId: number | string;
  columnId: number | string;
}

const DimensionBuilderTable = ({ planPermission }: any) => {
  const dispatch: any = useDispatch();
  const selectedDimension: any = useSelector(getSelectedDimension);
  const selectedScenario = useSelector(getBaseScenario);
  const filteredDimensionItem: any = useSelector(getFilteredDimensionItems);
  const dimensionProperties: any = useSelector(getDimensionProperty);
  const loaderStatus: any = useSelector(getLoaderStatus);
  const addItem: any = useSelector(getAddItem);
  const tableReference: any = useRef(null);
  // const timeStatusData = useSelector(getTimeDataStatus);
  const propertiesSectionStatus = useSelector(getPropertiesSectionStatus);
  const settingsSectionStatus = useSelector(getSettingsSectionStatus);
  const dimensionFilters = useSelector(getDimensionFilters);

  const [tableData, setTableData]: any = useState();

  const rows: any = useMemo(() => {
    return (
      dimensionProperties?.length >= 0 &&
      tableData?.length >= 0 &&
      getRows(tableData, dimensionProperties)
    );
  }, [tableData, dimensionProperties]);

  const memorizedColumns = useMemo(() => {
    return (
      dimensionProperties?.length >= 0 &&
      getColumns(dimensionProperties, tableReference?.current?.offsetWidth)
    );
  }, [dimensionProperties]);

  const [, setFocus] = useState<Focus>({ rowId: 'header', columnId: 'name' });
  const [columns, setColumns]: any = useState(memorizedColumns);
  const [filtersData, setFiltersData]: any = useState();

  const [gridProperties, setGridProperties] = useState({});

  useEffect(() => {
    if (tableReference?.current && dimensionProperties?.length >= 0) {
      setColumns(getColumns(dimensionProperties, tableReference?.current?.offsetWidth));
    }
  }, [propertiesSectionStatus, settingsSectionStatus]);

  useEffect(() => {
    dispatch(
      handleDimensionItemsChange({
        datas: filtersData?.data,
        cell: filtersData?.cell,
        dimensionFilters,
      }),
    );
  }, [filtersData]);

  const [deleteModal, setDeleteModal] = useState({
    itemId: '',
    itemName: '',
    type: '',
    status: false,
  });

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setTableData(filteredDimensionItem);
  }, [filteredDimensionItem]);

  useEffect(() => {
    setColumns(memorizedColumns);
  }, [memorizedColumns]);

  useEffect(() => {
    const getTableData = async () => {
      if (addItem) {
        dispatch(handleAddItemStatus(false));
      }
      if (selectedDimension && selectedScenario) {
        setLoader(true);
        await dispatch(
          FetchDimensionProperties({
            selectedId: selectedDimension?.id,
            parameters: { scenario_id: selectedScenario?.id },
          }),
        ).then((response: any) => {
          if (response?.payload?.properties) {
            response?.payload?.properties?.map((data: any) => {
              if (data?.data_format) {
                dispatch(
                  FetchDimensionGroup({
                    data,
                    parameters: { scenario_id: selectedScenario?.id },
                  }),
                );
              }
              return null;
            });
          }
        });
        await dispatch(
          FetchDimensionItems({
            selectedId: selectedDimension?.id,
            parameters: { scenario_id: selectedScenario?.id },
          }),
        );

        setLoader(false);
      }
    };
    getTableData();
  }, [selectedDimension, selectedScenario]);

  // const updateTimeScale = async () => {
  // if (selectedDimension?.name === 'Time') {
  // await dispatch(
  //   FetchDimensionItems({
  //     selectedId: selectedDimension?.id,
  //     parameters: { scenario_id: selectedScenario?.id },
  //   }),
  // );
  // await dispatch(resetTimeStatusData());
  // }
  // };

  // useEffect(() => {
  //   updateTimeScale();
  //   dispatch(resetTimeStatusData());
  // }, [timeStatusData]);

  useEffect(() => {
    setFocus({ rowId: 'header', columnId: 'name' });
  }, [selectedDimension]);

  const handleAddRow = () => {
    let object: any = {};
    for (const number of dimensionProperties) {
      object[number?.id] = '';
    }
    object = { ...object, name: '', id: '', position: '' };
    setTableData([...tableData, object]);
    setFocus({ rowId: rows?.length - 2, columnId: 'name' });
    setGridProperties({ focusLocation: { rowId: rows?.length - 2, columnId: 'name' } });
  };

  useEffect(() => {
    if (addItem) handleAddRow();
  }, [addItem]);

  useEffect(() => {
    return () => {
      setFocus({ rowId: 'header', columnId: 'name' });
      dispatch(resetBuilderDimension());
    };
  }, []);

  const updateHeading = async (data: any) => {
    await dispatch(
      UpdatePropertyHeader({
        propertyId: data?.columnId,
        data: {
          name: data?.newCell?.text,
        },
      }),
    ).then(() => {
      dispatch(
        FetchDimensionProperties({
          selectedId: selectedDimension?.id,
          parameters: { scenario_id: selectedScenario?.id },
        }),
      );
    });
  };

  const updateCell = async (changes: any) => {
    const change = changes[0];
    const existingRow = rows?.filter((data: any) => {
      return change?.rowId === data?.rowId;
    });
    if (change?.newCell?.colType && change?.previousCell?.colType) {
      updateHeading(change);
    } else if (
      change?.newCell?.property &&
      !change?.previousCell?.text &&
      !existingRow[0]?.itemId
    ) {
      await dispatch(
        AddItemProperty({
          dimension_id: Number(selectedDimension?.id),
          request_body: {
            name: change?.newCell?.text,
            scenario_id: selectedScenario?.id,
          },
          toaster: true,
        }),
      );
      await dispatch(
        FetchDimensionItems({
          selectedId: selectedDimension?.id,
          parameters: { scenario_id: selectedScenario?.id },
        }),
      );
    } else {
      if (change?.newCell?.property) {
        await dispatch(
          UpdateDimensionPropertyName({
            item_id: filteredDimensionItem[change?.rowId]?.id,
            name: change?.newCell?.text,
            toaster: true,
          }),
        );
        await dispatch(
          FetchDimensionItems({
            selectedId: selectedDimension?.id,
            parameters: { scenario_id: selectedScenario?.id },
          }),
        );
      } else {
        await dispatch(
          UpdateDimensionValues({
            item_id: filteredDimensionItem[change?.rowId]?.id,
            request_body: {
              property_id: change?.columnId,
              value: change?.newCell?.text,
              scenario_id: selectedScenario?.id,
            },
            toaster: true,
          }),
        );
        await dispatch(
          FetchDimensionItems({
            selectedId: selectedDimension?.id,
            parameters: { scenario_id: selectedScenario?.id },
          }),
        );
      }
    }
  };

  const updateCellBulk = async (changes: any) => {
    const newChanges: any[] = [];
    for (const change of changes) {
      const object = {
        item_id: `${filteredDimensionItem[change?.rowId]?.id}`,
        property_id: `${change?.columnId}`,
        value: change?.newCell?.text,
      };
      newChanges.push(object);
    }
    if (newChanges?.length > 0)
      await dispatch(
        UpdateDimensionPropertyBulk({
          request_body: {
            items: newChanges,
            dimension_id: selectedDimension?.id,
            scenario_id: selectedScenario?.id,
          },
          toaster: true,
        }),
      );
  };

  const updateCells = async (changes: any) => {
    if (addItem) dispatch(handleAddItemStatus(false));
    await (changes?.length > 1 ? updateCellBulk(changes) : updateCell(changes));
  };

  const handleLocationChanged = (event: any) => {
    if (Object.keys(gridProperties).length > 0) {
      setGridProperties({});
    }
    setFocus(event);
    return true;
  };

  const handleColumnResize = (ci: any, width: number) => {
    setColumns((column: any) => {
      const columnIndex = column.findIndex((element: any) => element.columnId === ci);
      const resizedColumn = column[columnIndex];
      const updatedColumn = { ...resizedColumn, width };
      column[columnIndex] = updatedColumn;
      return [...column];
    });
  };

  const handleDeleteRow = async (data: any) => {
    if (addItem) {
      dispatch(handleAddItemStatus(false));
    }
    if (data?.id === '') {
      dispatch(
        FetchDimensionItems({
          selectedId: selectedDimension?.id,
          parameters: { scenario_id: selectedScenario?.id },
        }),
      );
    } else {
      setDeleteModal({ itemId: data?.id, itemName: data?.itemName, type: 'row', status: true });
    }
  };

  const handleDeleteModal = async (type: string) => {
    if (type === 'header') {
      setDeleteModal({ itemId: '', itemName: '', type: '', status: false });
      dispatch(DeleteDimensionHeader({ item_id: deleteModal?.itemId })).then(() => {
        dispatch(
          FetchDimensionProperties({
            selectedId: selectedDimension?.id,
            parameters: { scenario_id: selectedScenario?.id },
          }),
        );
      });
    } else {
      if (deleteModal?.itemId) {
        setDeleteModal({ itemId: '', itemName: '', type: '', status: false });
        await dispatch(
          DeleteDimensionProperty({
            item_id: deleteModal?.itemId,
            parameters: { scenario_id: selectedScenario?.id },
          }),
        );
        dispatch(
          FetchDimensionItems({
            selectedId: selectedDimension?.id,
            parameters: { scenario_id: selectedScenario?.id },
          }),
        );
      }
    }
  };

  const handleContextMenu = (
    selectedRowIds: any,
    selectedColIds: any,
    selectionMode: any,
    menuOptions: any,
    selectedRanges: any,
  ) => {
    const deleteRow = {
      id: 'deleteRow',
      label: 'Delete row',
      handler: () => {
        const filteredRows = rows?.filter((data2: any) => {
          return data2?.rowId === selectedRanges[0][0]?.rowId && data2;
        });
        handleDeleteRow({ id: filteredRows[0]?.itemId, itemName: filteredRows[0]?.itemName });
      },
    };

    const deleteColumn = {
      id: 'deleteRow',
      label: 'Delete Column',
      handler: () => {
        const filteredColumn = columns?.filter((data2: any) => {
          return data2?.columnId === selectedRanges[0][0]?.columnId && data2;
        });
        setDeleteModal({
          itemId: filteredColumn[0]?.columnId,
          itemName: filteredColumn[0]?.name,
          type: 'header',
          status: true,
        });
      },
    };
    if (
      selectedRanges[0] &&
      selectedRanges[0][0]?.rowId === 'header' &&
      selectedRanges[0][0]?.columnId !== 'name'
    ) {
      return [deleteColumn];
    }
    if (
      selectedRanges[0] &&
      selectedRanges[0][0]?.rowId !== 'header' &&
      selectedRanges[0][0]?.rowId !== 'footer'
    ) {
      let selectedRows = false;
      for (let index = 0; index < selectedRanges[0]?.length; index += 1) {
        selectedRows = selectedRanges[0][0]?.rowId === selectedRanges[0][index]?.rowId;
      }
      if (selectedRanges[0][0]?.columnId !== 'delete' && selectedRows) {
        menuOptions = menuOptions.filter((object: any) => object.id !== 'cut');
        return [...menuOptions, deleteRow];
      }
      return selectedRanges[0][0]?.columnId === 'delete' && selectedRanges[0]?.length === 1
        ? [deleteRow]
        : menuOptions.filter((object: any) => object.id !== 'cut');
    }
    return [];
  };

  const handleCanReorderRows = (targetRowId: any) => {
    return targetRowId !== 'footer' || targetRowId !== 'header';
  };

  const handleRowsReorder = async (targetRowId: any, rowIds: any[]) => {
    const to = tableData?.findIndex((person: any) => person.position - 1 === targetRowId);
    const rowsIds = rowIds.map((id) =>
      tableData?.findIndex((person: any) => person.position - 1 === id),
    );
    const reorededArray = reorderArray(tableData, rowsIds, to);
    await dispatch(
      ReorderDimensionProperty({
        selectedDimension: selectedDimension?.id,
        reorededArr: reorededArray,
      }),
    );
    dispatch(
      FetchDimensionItems({
        selectedId: selectedDimension?.id,
        parameters: { scenario_id: selectedScenario?.id },
      }),
    );
  };

  const handleDeleteHeader = (data: any) =>
    setDeleteModal({ itemId: data?.id, itemName: data?.text, type: 'header', status: true });

  const handleFilterChange = (cell: any, data: any) => setFiltersData({ cell, data });

  return (
    <Flex
      height={'100%'}
      width={'100%'}
      flexDirection={'column'}
      ref={tableReference}
      flex={1}
      overflow={'hidden'}
    >
      <Text fontSize={'md'} width={'fit-content'}>
        Items
      </Text>
      {loader || loaderStatus ? (
        <FullScreenLoader height='inherit' bgColor='transparent' />
      ) : (
        <>
          {columns?.length && rows?.length ? (
            <Box
              className='md-table-container sm-scroll'
              width={'100%'}
              margin={'initial'}
              maxH={'initial'}
              mt={4}
              overflowY={rows.length > 6 ? 'auto' : 'initial'}
            >
              <ReactGrid
                rows={rows}
                columns={columns}
                enableFillHandle
                enableRangeSelection
                stickyRightColumns={columns?.length <= 2 ? 0 : 1}
                stickyLeftColumns={columns?.length <= 2 ? 0 : 1}
                stickyBottomRows={rows?.length <= 3 ? 0 : 1}
                stickyTopRows={rows?.length <= 3 ? 0 : 1}
                enableRowSelection={rows?.length > 3}
                verticalStickyBreakpoint={100}
                horizontalStickyBreakpoint={100}
                onRowsReordered={handleRowsReorder}
                {...gridProperties}
                onFocusLocationChanging={handleLocationChanged}
                onCellsChanged={planPermission ? undefined : updateCells}
                onContextMenu={planPermission ? undefined : handleContextMenu}
                onColumnResized={planPermission ? undefined : handleColumnResize}
                canReorderRows={handleCanReorderRows}
                customCellTemplates={{
                  header: new HeaderRowTemplate(),
                  delete: new DeleteRowCellTemplate(),
                  text: new TextCellTemplate(handleDeleteHeader),
                  date: new DateCellTemplate(),
                  addRow: new AddRowCellTemplate(handleAddRow, planPermission),
                  dropdown: new DropdownCellTemplate(handleDeleteRow, planPermission),
                  filter: new FiltersCellTemplate(handleFilterChange),
                }}
              />
            </Box>
          ) : (
            'NA'
          )}
        </>
      )}
      {deleteModal.status && (
        <DeleteGroupModal
          isOpen={deleteModal.status}
          onClose={() => setDeleteModal({ itemId: '', itemName: '', type: '', status: false })}
          itemName={deleteModal.itemName || 'some name'}
          onDelete={() => handleDeleteModal(deleteModal.type)}
          orgChart={true}
        />
      )}
    </Flex>
  );
};

export default PlanPermission(DimensionBuilderTable);
