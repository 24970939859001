import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody } from '@chakra-ui/react';

import PlanPermission from 'container/PlanPermission';
import { getBaseScenario } from 'redux/PlannerModeSlice';
import apiService from 'services';

import Map from './Map';

const MapTab = () => {
  const { blockId } = useParams();
  const [initialNodes, setInitialNodes] = useState([]);
  const [initialEdges, setInitialEdges] = useState([]);
  const baseScenario = useSelector(getBaseScenario);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiService.getBlockMap(blockId, {
          params: { scenario_id: baseScenario?.scenario_id },
        });

        if (response.data.length > 0) {
          const sourceElements = response.data;
          const nodes = sourceElements.reduce((accumulator: any, sourceElement: any) => {
            // eslint-disable-next-line no-prototype-builtins
            if (sourceElement.hasOwnProperty('data')) {
              sourceElement.type = 'indicator';
              sourceElement.id = String(sourceElement.id);
              sourceElement.position = { x: 0, y: 0 };
              accumulator.push(sourceElement);
            }
            return accumulator;
          }, []);

          const edges = sourceElements.reduce((accumulator: any, sourceElement: any) => {
            // eslint-disable-next-line no-prototype-builtins
            if (sourceElement.hasOwnProperty('source')) {
              sourceElement.type = 'smoothstep';
              sourceElement.animated = false;
              sourceElement.markerEnd = { type: 'arrow' };
              accumulator.push(sourceElement);
            }
            return accumulator;
          }, []);

          setInitialNodes(nodes);
          setInitialEdges(edges);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, [baseScenario, blockId]);

  return initialNodes?.length ? (
    <Card style={{ height: 'calc(100vh - 250px)', background: 'white' }}>
      <CardBody>
        <Map initialNodes={initialNodes} initialEdges={initialEdges} />
      </CardBody>
    </Card>
  ) : (
    <></>
  );
};

export default PlanPermission(MapTab);
