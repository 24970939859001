/* eslint-disable react/style-prop-object */
const CenterFocusStrongIcon = () => {
  return (
    <svg width='25px' height='25px' viewBox='0 0 22 22' id='svg4355' version='1.1'>
      <g id='layer1' transform='translate(-544.571 -742.934)'>
        <path d='M547.571 745.934v4h1v-3h3v-1h-3z' id='path4162' />

        <path d='M562.571 757.934v3h-3v1h4v-4z' id='rect4134' />

        <path id='path4170' d='M563.571 745.934v4h-1v-3h-3v-1h3z' />

        <path id='path4172' d='M548.571 757.934v3h3v1h-4v-4z' />

        <path
          d='M6 6v10h10V6H6zm1 1h8v8H7V7z'
          transform='translate(544.571 742.934)'
          id='rect4147'
        />
      </g>
    </svg>
  );
};

export default CenterFocusStrongIcon;
