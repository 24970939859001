import { Td, Tr } from '@chakra-ui/react';
import React, { useState } from 'react';

import ChevronDown from 'components/Icons/ChevronDown';
import ChevronRight from 'components/Icons/ChevronRight';
import { NewBlockOutput, NewTotalValues } from 'models/response/blockOutput.interface';
import formatValue from 'utils/FormatValues';

interface Properties {
  row: NewBlockOutput;
  expandkey: string;
}

const IndicatorRow = ({ row, expandkey }: Properties) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const onToggleCollapse = () => {
    setIsCollapsed((previousState) => !previousState);
  };

  const subData = row.dim_item_data
    ? Object.entries(row.dim_item_data).map((entry) => ({
        item_name: entry[1].name,
        item_values: entry[1]?.value || [],
      }))
    : [
        {
          item_name: 'Total',
          item_values: row.total_values || [],
        },
      ];

  // const getBackgroundStyle = (
  //   output_format: string,
  //   key: string,
  //   isExpandedColumn: boolean,
  //   isSubRow?: boolean,
  //   isActualValue?: boolean,
  // ) => {
  //   if (isExpandedColumn) {
  //     return {
  //       background: isActualValue
  //         ? output_format === 'subtotal'
  //           ? '#A7E6EB'
  //           : '#DEF2F3'
  //         : '#E7EBEF',
  //     };
  //   }
  //   if (key === expandkey && isSubRow) {
  //     return {
  //       background: isActualValue ? '#A7E6EB' : '#D1D9E0',
  //     };
  //   }
  //   if (key === expandkey && !isSubRow) {
  //     return {
  //       background: isActualValue ? '#DEF2F3' : '#D1D9E0',
  //     };
  //   }
  //   const color = isActualValue ? '#A7E6EB' : '#F4F7FF';
  //   return {
  //     background: output_format === 'subtotal' ? color : isActualValue ? '#EFFDFE' : '#FFF',
  //   };
  // };

  const getBackgroundStyle = (
    output_format: string,
    key: string,
    isExpandedColumn: boolean,
    isSubRow?: boolean,
    isActualValue?: boolean,
  ) => {
    const subTotalIndicator = isSubRow ? '#EFFDFE' : '#BCEEF2';
    const regularIndicator = '#EFFDFE';
    const subTotalIndicatorTimeExpansion = isSubRow ? '#DEF2F3' : '#A7E6EB';
    const regularIndicatorTimeExpansion = '#DEF2F3';

    if (isExpandedColumn) {
      return {
        background: isActualValue
          ? output_format === 'subtotal'
            ? subTotalIndicatorTimeExpansion
            : regularIndicatorTimeExpansion
          : '#E7EBEF',
      };
    }

    if (key === expandkey && isSubRow) {
      return {
        background: isActualValue
          ? output_format === 'subtotal'
            ? regularIndicatorTimeExpansion
            : regularIndicatorTimeExpansion
          : '#D1D9E0',
      };
    }

    if (key === expandkey && !isSubRow) {
      return {
        background: isActualValue
          ? output_format === 'subtotal'
            ? subTotalIndicatorTimeExpansion
            : regularIndicatorTimeExpansion
          : '#D1D9E0',
      };
    }

    const color = isActualValue ? subTotalIndicator : '#F4F7FF';

    return {
      background: output_format === 'subtotal' ? color : isActualValue ? regularIndicator : '#FFF',
    };
  };

  return (
    <>
      <Tr
        height={'8'}
        onClick={onToggleCollapse}
        fontWeight={row?.output_format === 'subtotal' ? 'semibold' : 'regular'}
      >
        <Td
          style={{
            ...getBackgroundStyle(row.output_format, '-', false, false, true),
            cursor: row.dim_item_data ? 'pointer' : 'default',
            paddingLeft: '1rem',
            verticalAlign: 'middle',
          }}
          fontSize={'2xs'}
          data-testid='table-header'
        >
          {row.dim_item_data ? isCollapsed ? <ChevronRight /> : <ChevronDown /> : <></>} {row.name}
        </Td>
        {Object.values(row.total_values as unknown as NewTotalValues[]).map((item, index) => {
          if (item.isShow) {
            if (item.value === null) {
              return (
                <Td
                  key={item.name + index}
                  style={getBackgroundStyle(
                    row.output_format,
                    item.name,
                    Boolean(item?.isExpandedColumn),
                    false,
                    Boolean(item?.isActualValue),
                  )}
                  fontSize={'2xs'}
                >
                  {row.format_properties.null_value}
                </Td>
              );
            }
            return (
              <Td
                key={item.name + index}
                paddingRight='1rem'
                style={getBackgroundStyle(
                  row.output_format,
                  item.name,
                  Boolean(item?.isExpandedColumn),
                  false,
                  Boolean(item?.isActualValue),
                )}
                verticalAlign='middle !important'
                textAlign={'start'}
                fontSize={'2xs'}
              >
                {formatValue(item?.value || 0, row?.format_properties)}
              </Td>
            );
          }
          return <React.Fragment key={item.name + index}></React.Fragment>;
        })}
      </Tr>

      {!isCollapsed &&
        row.dim_item_data &&
        subData?.map(
          (subRow, index) =>
            subRow.item_values &&
            subRow.item_values.length > 0 && (
              <Tr
                height={'9'}
                key={index}
                fontWeight={row?.output_format === 'subtotal' ? 'semibold' : 'regular'}
              >
                <Td
                  style={getBackgroundStyle(row.output_format, '-', false, true, true)}
                  pl='5'
                  color='grey.900'
                  fontSize={'2xs'}
                >
                  {subRow.item_name}
                </Td>
                {(subRow.item_values as NewTotalValues[]).map(
                  (itemValue: any, itemValueIndex: number) => {
                    if (itemValue.isShow) {
                      if (itemValue === null) {
                        return (
                          <Td
                            key={itemValue.name + itemValueIndex}
                            style={getBackgroundStyle(
                              row.output_format,
                              itemValue.name,
                              itemValue.isExpandedColumn,
                              true,
                              Boolean(itemValue?.isActualValue),
                            )}
                            color='grey.900'
                            fontSize={'2xs'}
                            textAlign={'start'}
                          >
                            {row.format_properties.null_value}
                          </Td>
                        );
                      }
                      return (
                        <Td
                          key={itemValue.name + index}
                          style={getBackgroundStyle(
                            row.output_format,
                            itemValue.name,
                            itemValue.isExpandedColumn,
                            true,
                            Boolean(itemValue?.isActualValue),
                          )}
                          color='grey.900'
                          fontSize={'2xs'}
                          textAlign={'start'}
                        >
                          {formatValue(itemValue?.value || 0, row?.format_properties)}
                        </Td>
                      );
                    }
                    return <React.Fragment key={itemValue.name + itemValueIndex}></React.Fragment>;
                  },
                )}
              </Tr>
            ),
        )}
    </>
  );
};

export default IndicatorRow;
