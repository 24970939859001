import { Box, Flex, Select, Switch, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDimensionProperty,
  getSelectedDimension,
  handleSettingsSection,
  UpdateDimensionName,
} from 'redux/BuilderModeSlice';
import CloseIconComponent from 'components/Icons/CloseIcon';

const BuilderSettingComponent = () => {
  const selectedDimension: any = useSelector(getSelectedDimension);
  const dimensionProperty: any = useSelector(getDimensionProperty);
  const dispatch: any = useDispatch();

  const handleClose = () => dispatch(handleSettingsSection());

  const handleSettingsChange = async (value: any, type: string) => {
    await dispatch(
      UpdateDimensionName({
        item_id: selectedDimension?.id,
        request_body: {
          [type]: `${value}`,
        },
      }),
    );
  };

  return (
    <Box
      backgroundColor={'white'}
      borderRadius={'md'}
      minW={'350px'}
      color={'black.900'}
      height={'100%'}
    >
      <Flex justifyContent={'end'} pt={4} pr={4}>
        <CloseIconComponent height={'15px'} width={'15px'} color={'black'} onClick={handleClose} />
      </Flex>
      <Box px={6}>
        <Text mb={4} fontSize={'md'} fontWeight={'600'} color={'violet.750'}>
          Settings
        </Text>
        <Flex mb={2} alignItems='center'>
          <Switch
            isChecked={selectedDimension?.enable_start_end}
            onChange={(event: any) =>
              handleSettingsChange(event.target.checked, 'enable_start_end')
            }
            mr='2'
          />
          <Text fontSize={'xs'}>Enable start and end dates</Text>
        </Flex>
        <Flex mb={2} alignItems='center'>
          <Switch
            isChecked={selectedDimension?.driver_page}
            onChange={(event: any) => handleSettingsChange(event.target.checked, 'driver_page')}
            mr='2'
          />
          <Text fontSize={'xs'}>Display dimension as driver page?</Text>
        </Flex>
        <Box mt={4}>
          <Text fontSize={'xs'}>Grouped by:</Text>
          <Select
            mt={2}
            width='100%'
            borderRadius={''}
            borderColor={'violet.400'}
            outline={'none'}
            _focus={{ borderColor: 'violet.400', boxShadow: 'none' }}
            fontSize={'14px'}
            height={'40px'}
            color='black.900'
            value={selectedDimension?.group_property_id}
            onChange={(event: any) => handleSettingsChange(event.target.value, 'group_property_id')}
          >
            <option value={null ?? ''}>Select</option>
            {dimensionProperty?.map(
              (data: any, index: number) =>
                data?.type === 'dimension' && (
                  <option key={index} value={data?.id}>
                    {data?.name}
                  </option>
                ),
            )}
          </Select>
        </Box>
      </Box>
    </Box>
  );
};
export default BuilderSettingComponent;
