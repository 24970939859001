import { DimItem } from 'models/dimensionItem.interface';

const addNewDimensionItem = (dimensionItems: any, scenarioId?: number) => {
  if (!dimensionItems) {
    return { name: '', id: 0, scenario_id: scenarioId };
  }

  const customItemsArray: DimItem[] = dimensionItems.filter((item: DimItem) =>
    item.name.includes('Item'),
  );

  return customItemsArray.length === 0
    ? { name: 'Item', scenario_id: scenarioId }
    : customItemsArray?.reduce(
        (accumulator: DimItem, item: DimItem) => {
          if (item.name.includes('Item')) {
            accumulator.name = `Item ${customItemsArray.length + 1}`;
            accumulator.scenario_id = scenarioId;
          }
          return accumulator;
        },
        { name: '', id: 0 },
      );
};

export default addNewDimensionItem;
