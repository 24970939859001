import { HStack, Button, useMediaQuery, useToast, Box } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import FormField from 'components/FormField';
import { ChangePassword, SetAuthPageType } from 'redux/AuthSlice';
import CustomToast from 'components/CustomToast';
import { ChangePasswordSchema } from 'validation/schemas/AuthenticationSchema';

const initialValues = {
  password: '',
  confirmPassword: '',
};

const ChangePasswordForm = () => {
  const [isMobile] = useMediaQuery('(max-width: 576px)');
  const [queryParameters] = useSearchParams();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const onSubmitForm = (form: any) => {
    dispatch(
      ChangePassword({
        request_body: {
          token: queryParameters.get('token'),
          password: form.password,
        },
      }),
    ).then((response: any) => {
      if (response.type.endsWith('/fulfilled')) {
        toast({
          duration: 2500,
          render: () => <CustomToast title={response?.payload.message} status='success' />,
        });
        localStorage.removeItem('BLOX_PASSWORD_TOKEN');
        navigate('/');
      } else {
        toast({
          duration: 2500,
          render: () => (
            <CustomToast
              title={response?.payload?.message || 'Something went wrong..'}
              status='error'
            />
          ),
        });
      }
    });
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      validationSchema={ChangePasswordSchema}
    >
      {({ errors, touched }) => (
        <Form>
          <HStack spacing='7' alignItems={'center'} flexWrap={'wrap'}>
            <FormField
              label='New Password:'
              name='password'
              type='password'
              labelStyles={{
                fontSize: '14px',
                color: '#121826',
              }}
              inputStyles={{
                maxWidth: '300px',
                boxShadow: '0px 0px 0px 1px #7A849E',
              }}
              placeholder='************'
              error={errors.password}
              touched={touched.password}
            >
              <Box width={'150px'}></Box>
            </FormField>
            <FormField
              label='Confirm New Password:'
              name='confirmPassword'
              type='password'
              labelStyles={{
                fontSize: '14px',
                color: '#121826',
              }}
              inputStyles={{
                maxWidth: '300px',
                boxShadow: '0px 0px 0px 1px #7A849E',
              }}
              styles={{
                marginTop: '.7rem',
              }}
              placeholder='************'
              error={errors.confirmPassword}
              touched={touched.confirmPassword}
            >
              <Box display={'flex'} flexDirection={'column'}>
                <Button
                  type='submit'
                  w='150px'
                  h='9'
                  borderRadius='xs'
                  fontSize='xs'
                  bgColor='green.900'
                >
                  Continue
                </Button>
                <small
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '.7rem',
                    cursor: 'pointer',
                    color: '#939393',
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    navigate('/');
                    dispatch(SetAuthPageType(1));
                  }}
                >
                  Back to login
                </small>
              </Box>
            </FormField>
            {isMobile && (
              <>
                <Button
                  margin={'1rem 0 0 0 !important'}
                  type='submit'
                  w='100%'
                  h='9'
                  borderRadius='xs'
                  fontSize='xs'
                  bgColor='green.900'
                >
                  Continue
                </Button>
                <small
                  style={{
                    width: '100%',
                    cursor: 'pointer',
                    color: '#939393',
                    textAlign: 'center',
                    margin: '.7rem 0 0 0',
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    navigate('/');
                    dispatch(SetAuthPageType(1));
                  }}
                >
                  Back to login
                </small>
              </>
            )}
          </HStack>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
