import { useState } from 'react';
import { Text, Box, Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import DeleteIcon from 'components/Icons/DeleteIcon';
import {
  DeleteBulkDimensionItems,
  DeleteBulkDimensionItemsReducer,
  FetchDimensionItems,
  getBaseScenario,
  getSelectedDimension,
  getTableCheckBox,
} from 'redux/BuilderModeSlice';
import DeleteGroupModal from 'components/DeleteGroupModal';
import { AppDispatch } from 'utils/GlobalHelpers';

const DeleteTable = () => {
  const dispatch: AppDispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const selectedDimension: any = useSelector(getSelectedDimension);
  const selectedScenario = useSelector(getBaseScenario);
  const tableCheckBox = useSelector(getTableCheckBox);

  const shouldShowDelete = tableCheckBox.some(
    (item: any) => item.status === true && item.id !== 'all' && item.id !== 'intermediate',
  );

  const numberOfSelectedRows = tableCheckBox.filter(
    (item: any) => item.status === true && item.id !== 'all' && item.id !== 'intermediate',
  ).length;
  const handleDelete = async () => {
    setDeleteModal(false);
    const trueIds = tableCheckBox
      .filter((item: any) => item.status && item.id !== 'all' && item.id !== 'intermediate')
      .map((item: any) => item.id);
    await dispatch(
      DeleteBulkDimensionItemsReducer({
        item_id: trueIds,
      }),
    );
    await dispatch(
      DeleteBulkDimensionItems({
        request_body: { item_id: trueIds, scenario_id: selectedScenario?.id },
      }),
    );
    await await dispatch(
      FetchDimensionItems({
        selectedId: selectedDimension?.id,
        parameters: { scenario_id: selectedScenario?.id },
      }),
    );
  };

  return (
    <>
      <Box h={'30px'} mb={3}>
        {shouldShowDelete ? (
          <Flex justifyContent={'end'} alignItems={'center'}>
            <Text mr={2}>
              {numberOfSelectedRows} row {numberOfSelectedRows > 1 && '(s)'} selected
            </Text>
            <DeleteIcon
              onClick={() => setDeleteModal(true)}
              width={16}
              height={16}
              color={'#fff'}
            />
          </Flex>
        ) : (
          <Text height={'24px'}></Text>
        )}
        <DeleteGroupModal
          isOpen={deleteModal}
          onClose={() => setDeleteModal(false)}
          itemName={
            numberOfSelectedRows > 1
              ? `${numberOfSelectedRows} row(s)`
              : `${numberOfSelectedRows} row`
          }
          onDelete={() => handleDelete()}
          orgChart={true}
        />
      </Box>
    </>
  );
};

export default DeleteTable;
