interface Properties {
  width?: any;
  height?: any;
  color?: string;
  marginRight?: string;
}

const ChartIconComponent = ({ width, height, color, marginRight }: Properties) => {
  return (
    <svg
      style={{ marginRight }}
      width={width}
      height={height}
      fill={color}
      viewBox='0 0 22 22'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='mask0_184_4679' maskUnits='userSpaceOnUse' x='0' y='0' width='22' height='22'>
        <rect x='0.80957' y='0.528564' width='21.0503' height='21.0503' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_184_4679)'>
        <path
          d='M5.19462 18.9473C4.71222 18.9473 4.29925 18.7755 3.95572 18.432C3.61219 18.0885 3.44043 17.6755 3.44043 17.1931V4.91374C3.44043 4.43134 3.61219 4.01837 3.95572 3.67484C4.29925 3.33131 4.71222 3.15955 5.19462 3.15955H13.0885V4.91374H5.19462V17.1931H17.474V9.29922H19.2282V17.1931C19.2282 17.6755 19.0564 18.0885 18.7129 18.432C18.3693 18.7755 17.9564 18.9473 17.474 18.9473H5.19462ZM6.94882 15.4389H8.70301V9.29922H6.94882V15.4389ZM10.4572 15.4389H12.2114V6.66793H10.4572V15.4389ZM13.9656 15.4389H15.7198V11.9305H13.9656V15.4389ZM15.7198 8.42213V6.66793H13.9656V4.91374H15.7198V3.15955H17.474V4.91374H19.2282V6.66793H17.474V8.42213H15.7198Z'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default ChartIconComponent;
