import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Flex,
  ModalHeader,
  ModalFooter,
  Box,
  VStack,
  Text,
  useMediaQuery,
  Button,
  useToast,
  Progress,
} from '@chakra-ui/react';
import Papa from 'papaparse';
import Dropzone from 'react-dropzone';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleDataFileUpload } from 'services/s3Service';
import Csv from 'components/Icons/Csv';
import { getSelectedDataFileDetails, postVersionFile, versionData } from 'redux/DataImportSlice';
import { importAcceptedFormat } from 'utils/FileFormats';
import CustomToast from 'components/CustomToast';
import { AppDispatch } from 'utils/GlobalHelpers';

const AddVersionModal = ({ setVersionModal, versionModal }: any) => {
  const dispatch: AppDispatch = useDispatch();
  const toast = useToast();
  const [isSmallLaptop] = useMediaQuery('(max-width: 1415px)');
  const [loader, setLoader] = useState(false);
  const [progress, setProgress] = useState(0);
  const selectedDataFileDetails = useSelector(getSelectedDataFileDetails);
  const dataSourceId = selectedDataFileDetails?.id;
  const handleUpload = async (file: any) => {
    if (file[0].size > 10 * 1024 * 1024) {
      toast({
        duration: 2500,
        render: () => <CustomToast title='File size exceeds 10MB limit.' status='error' />,
      });
      setLoader(false);
      return;
    }
    setLoader(true);
    const reader = new FileReader();
    reader.addEventListener('load', async (event) => {
      const content = event.target?.result as string;

      if (!content || /^\s*$/.test(content)) {
        toast({
          duration: 2500,
          render: () => <CustomToast title='The uploaded CSV file is empty.' status='error' />,
        });
        setLoader(false);
        return;
      }

      Papa.parse(content, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: async (result) => {
          const filteredData = await result.data
            .map((row: any) => {
              const cleanedRow: any = {};
              Object.entries(row).forEach(([key, value]) => {
                const cleanedKey = key.trim();
                if (cleanedKey !== '') {
                  cleanedRow[cleanedKey] = value?.toString().replace(/,\s*$/, '').trim();
                }
              });
              return cleanedRow;
            })
            .filter((cleanedRow: any) =>
              Object.values(cleanedRow).some(
                (value) => value !== null && value !== '' && value !== ' ' && value !== undefined,
              ),
            );
          const unParsedData = await Papa.unparse(filteredData);
          if (unParsedData) {
            const blob = new Blob([unParsedData], {
              type: 'text/csv;charset=utf-8',
            });
            const fileData = new File([blob], 'example.csv', { type: 'text/csv' });
            const data = await handleDataFileUpload(fileData, 'blox-data-import', (percentage) => {
              setProgress(percentage);
            });
            const requestBody = {
              s3_file_url: data,
            };
            await dispatch(
              postVersionFile({
                dataSourceId,
                request_body: requestBody,
                toaster: true,
              }),
            );
            await dispatch(versionData(dataSourceId));
            await setLoader(false);
            setVersionModal.off();
          }
        },
      });
    });
    reader.readAsText(file[0]);
  };
  const handleFileDrop = (acceptedFiles: File[], rejectedFiles: any) => {
    if (rejectedFiles.length > 0) {
      const errorMessage = rejectedFiles[0]?.errors[0]?.message;
      if (errorMessage) {
        toast({
          duration: 2500,
          render: () => (
            <CustomToast
              title='Please ensure that the file type is in the .csv format.'
              status='error'
            />
          ),
        });
      }
    } else {
      // Handle accepted files by calling handleUpload
      handleUpload(acceptedFiles);
    }
  };

  const textStyles = {
    fontFamily: 'regular_roboto',
    fontWeight: '400',
  };
  return (
    <>
      <Modal
        onClose={() => setVersionModal.off()}
        isOpen={versionModal}
        isCentered
        colorScheme='black'
      >
        <ModalOverlay />
        <ModalContent color={'#000'} maxW={'50rem'} height={'25rem'}>
          <ModalCloseButton />
          <ModalHeader />
          <ModalBody>
            {loader ? (
              <Flex
                h='full'
                direction='column'
                alignItems='center'
                justifyContent='center'
                justify='center'
                align='center'
              >
                <Text fontSize={'xl'} textAlign={'center'} p={4}>
                  {progress}%
                </Text>
                <Progress width={'80%'} maxW={'50rem'} hasStripe value={progress} />
              </Flex>
            ) : (
              <Box height={'100%'} py={4} px={10}>
                <Dropzone
                  onDrop={(acceptedFiles, rejectedFiles) =>
                    handleFileDrop(acceptedFiles, rejectedFiles)
                  }
                  multiple={false}
                  accept={importAcceptedFormat}
                >
                  {({ getRootProps, getInputProps }) => (
                    <Flex
                      align={'center'}
                      height={'100%'}
                      border={'2px dashed #6562CF'}
                      borderRadius={'lg'}
                      justifyContent={'space-around'}
                      {...getRootProps({ className: 'dropzone' })}
                    >
                      <input {...getInputProps()} />
                      <VStack justifyContent={'space-around'} alignItems={'center'}>
                        <Csv innerColor='#6562CF' width='70' height='70' />
                        <Text
                          my='4'
                          color='black'
                          {...textStyles}
                          fontSize={isSmallLaptop ? 'md' : 'lg'}
                          textAlign={'center'}
                          fontWeight={'400'}
                        >
                          Drag and drop your csv file here <br /> - OR -
                        </Text>

                        <Button
                          style={{ color: 'white', background: '#4a4889', width: 'fit-content' }}
                          minH={'50px'}
                          borderRadius={'15px'}
                          px={8}
                        >
                          <Text
                            {...textStyles}
                            fontSize={isSmallLaptop ? 'sm' : 'md'}
                            textAlign={'start'}
                            color='white'
                            textTransform='none'
                          >
                            Browse files
                          </Text>
                        </Button>
                      </VStack>
                    </Flex>
                  )}
                </Dropzone>
              </Box>
            )}
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddVersionModal;
