const palette = {
  primary: { main: '#7163d0', dark: '#4BB6B9', light: 'red' },
  secondary: { main: 'white', highlight: '#00ff93' },
  black: { main: '#212121', dark: '#000000' },
  white: { main: 'white' },
  red: { main: '#d56565', error: '#d32f2f' },
  grey: {
    main: '#b1b8d4',
    secondary: '#979797',
    border: '#d8def4',
    background: '#f6f6f6',
  },
  inputShadow: '0px 5px 15px rgba(89, 89, 89, 0.25)',
  cardShadow: '0px 12px 12px 6px #E6E6EC',
};
export default palette;
