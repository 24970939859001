import { Center, Icon } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

interface Properties {
  isChecked: boolean;
}

const CustomCheckboxIcon = ({ isChecked }: Properties) => {
  return (
    <Center w='14px' h='10px' bg='transparent'>
      {!isChecked ? null : <Icon as={CheckIcon} color='black' boxSize={'10px'} />}
    </Center>
  );
};

export default CustomCheckboxIcon;
