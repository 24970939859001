/* eslint-disable no-restricted-syntax */
import './Table.scss';
import { ReactGrid } from '@silevis/reactgrid';
import { useRef, useMemo, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Flex } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import {
  getSelectedCell,
  handleSelectedCell,
  getIndicatorsData,
  UpdateIndicator,
  AddIndicator,
  getPlannerBlock,
  getBaseScenario,
  FetchBlockOutputs,
  ReorderIndicator,
  DeleteIndicator,
  getIndicators,
  handleAddIndicator,
  getAddIndicator,
} from 'redux/PlannerModeSlice';
import PlanPermission from 'container/PlanPermission';
import DeleteGroupModal from 'components/DeleteGroupModal';
import { DateCellTemplate } from 'components/DateCell';
import { AddRowCellTemplate } from 'components/AddRowCell';
import { CheckboxCellTemplate } from 'components/CheckBoxCell';
import { DeleteRowCellTemplate } from 'components/DeleteRowCell';
import { HeaderRowTemplate } from 'components/HeaderRow';
import { TextCellTemplate } from 'components/TextCell';
import { DropdownCellTemplate } from 'components/DropdownCell';
import { FormulaCellTemplate } from 'components/FormulaCell';

import {
  getColumns,
  getRows,
  handleCanReorderRows,
  handleColumnResize,
  reorderArray,
} from './TableHelpers';

const BuilderModePropertyTable = ({ planPermission }: any) => {
  const { blockId } = useParams();
  const dispatch: any = useDispatch();
  const tableReference: any = useRef(null);

  const selectedCell = useSelector(getSelectedCell);
  const indicators = useSelector(getIndicators);
  const indicatorsData = useSelector(getIndicatorsData);
  const plannerBlock = useSelector(getPlannerBlock);
  const addIndicator = useSelector(getAddIndicator);
  const baseScenario = useSelector(getBaseScenario);
  const [tableData, setTableData]: any = useState([]);
  const [gridProperties, setGridProperties] = useState({});

  const columnsArray: any = [
    { id: 0, name: 'Block indicators' },
    { id: 1, name: 'Type' },
    { id: 2, name: 'Formula' },
    { id: 3, name: 'Help text' },
    { id: 4, name: 'Format' },
    { id: 5, name: 'Roll up type' },
    { id: 6, name: 'Display as output' },
    { id: 7, name: 'Display as sub-total' },
    { id: 8, name: 'Default chart type' },
  ];

  const [deleteModal, setDeleteModal] = useState({
    indicatorId: '',
    indicatorName: '',
    status: false,
  });

  const rows: any = useMemo(() => {
    return tableData?.length >= 0 && getRows(tableData, columnsArray, indicators);
  }, [tableData]);

  const [columns, setColumns]: any = useState();

  useEffect(() => {
    if (indicatorsData?.length >= 0) setTableData(indicatorsData || []);
  }, [indicatorsData]);

  useEffect(() => {
    if (tableReference.current) {
      setColumns(getColumns(columnsArray, tableReference.current?.offsetWidth));
    }
  }, [tableReference.current]);

  const handleDeleteRow = (data: any) => {
    setDeleteModal({ indicatorId: data?.id, indicatorName: data?.itemName, status: true });
  };

  const handleFocusLocationChanged = (data: any) => {
    if (selectedCell?.rowId !== data?.rowId) dispatch(handleSelectedCell(data));
  };

  const handleAddRow = () => {
    let object: any = {};
    for (const number of columnsArray) {
      object[number?.name] = '';
    }

    const rowId = rows?.length ? rows.length - 2 : 0;
    object = {
      ...object,
      name: '',
      id: rowId,
      position: '',
      output: false,
      'Display as output': false,
      isNewCell: true,
    };
    setTableData([...tableData, object]);
    setGridProperties({
      focusLocation: { rowId, columnId: '0' },
    });
    dispatch(handleAddIndicator(false));
  };

  useEffect(() => {
    if (addIndicator) handleAddRow();
  }, [addIndicator]);

  const fetchIndicatorsData = () => {
    const idArray = plannerBlock?.dimensions
      .filter((item: any) => item.name !== 'Time')
      .map((item: any) => item.id);
    const dimensionIds = idArray.join(', ');
    const payload = {
      blockId,
      params: {
        dim_id: dimensionIds,
        indicator_filter: 'all',
        scenario_id: baseScenario?.id,
      },
    };
    dispatch(FetchBlockOutputs(payload));
  };

  const updateIndicator = (changes: any, change: any) => {
    dispatch(
      UpdateIndicator({
        indicatorId: changes[0]?.rowId,
        data: {
          [change?.key]: change?.extraKey
            ? change?.checked
              ? 'subtotal'
              : 'default'
            : change?.checked !== undefined
            ? `${change?.checked}`
            : change?.text,
        },
      }),
    ).then(() => {
      dispatch(handleSelectedCell(selectedCell));
    });
  };

  const handleAddDimIndicator = (indicatorName: string) => {
    dispatch(
      AddIndicator({
        blockId,
        data: {
          name: indicatorName,
          type: 'Input',
        },
        toaster: true,
      }),
    ).then((response: any) => {
      if (!response?.error) {
        setGridProperties({
          focusLocation: { rowId: 'header', columnId: '0' },
        });
        fetchIndicatorsData();
      }
    });
  };

  const handleCellChange = (changes: any) => {
    const change = changes[0];
    if (change?.newCell?.isNewCell) {
      handleAddDimIndicator(change?.newCell?.text);
    } else {
      updateIndicator(changes, change?.newCell);
    }
  };

  const handleRowsReorder = async (targetRowId: any, rowIds: any[]) => {
    const to = tableData?.findIndex((person: any) => person.id === targetRowId);
    const rowsIds = rowIds.map((id) => tableData?.findIndex((person: any) => person.id === id));
    const reorededArray = reorderArray(tableData, rowsIds, to);
    dispatch(
      ReorderIndicator({
        blockId,
        data: { indicators: reorededArray },
      }),
    ).then((response: any) => {
      if (!response?.error) fetchIndicatorsData();
    });
  };

  const handleDeleteModal = () => {
    setDeleteModal({ indicatorId: '', indicatorName: '', status: false });
    dispatch(
      DeleteIndicator({
        indicatorId: deleteModal.indicatorId,
        toaster: {
          successMessage: 'Indicator deleted',
        },
      }),
    ).then((response: any) => {
      if (!response?.error) {
        setGridProperties({
          focusLocation: { rowId: 'header', columnId: '0' },
        });
        fetchIndicatorsData();
      }
    });
  };

  const handleContextMenu = (
    selectedRowIds: any,
    selectedColIds: any,
    selectionMode: any,
    menuOptions: any,
    selectedRanges: any,
  ) => {
    if (
      selectedRanges[0] &&
      selectedRanges[0][0]?.rowId !== 'header' &&
      selectedRanges[0][0]?.rowId !== 'footer'
    ) {
      const deleteRow = {
        id: 'deleteRow',
        label: 'Delete row',
        handler: () => {
          const myRow = rows?.filter((data2: any) => {
            return data2?.rowId === selectedRanges[0][0]?.rowId && data2;
          });
          handleDeleteRow({ id: myRow[0]?.itemId, itemName: myRow[0]?.itemName });
        },
      };
      let selectedRows = false;
      for (let index = 0; index < selectedRanges[0]?.length; index += 1) {
        selectedRows = selectedRanges[0][0]?.rowId === selectedRanges[0][index]?.rowId;
      }
      if (selectedRanges[0][0]?.columnId !== 'delete' && selectedRows) {
        menuOptions = menuOptions.filter((object: any) => object.id !== 'cut');
        return [...menuOptions, deleteRow];
      }
      return selectedRanges[0][0]?.columnId === 'delete' && selectedRanges[0]?.length === 1
        ? [deleteRow]
        : menuOptions.filter((object: any) => object.id !== 'cut');
    }
    return [];
  };

  const handleLocationChanged = () => {
    if (Object.keys(gridProperties).length > 0) {
      setGridProperties({});
    }
    return true;
  };

  return (
    <Flex
      ref={tableReference}
      height={'100%'}
      width={'100%'}
      flexDirection={'column'}
      flex={1}
      overflow={'hidden'}
    >
      {columns?.length && rows?.length && (
        <Box
          className='property-table-container sm-scroll'
          width={'100%'}
          margin={'initial'}
          maxH={'initial'}
          overflowY={rows.length > 10 ? 'auto' : 'initial'}
        >
          <ReactGrid
            rows={rows}
            columns={columns}
            enableRowSelection={rows?.length > 3}
            stickyTopRows={rows?.length <= 3 ? 0 : 1}
            stickyRightColumns={columns?.length <= 2 ? 0 : 1}
            stickyLeftColumns={columns?.length <= 2 ? 0 : 1}
            stickyBottomRows={rows?.length <= 3 ? 0 : 1}
            verticalStickyBreakpoint={100}
            horizontalStickyBreakpoint={100}
            canReorderRows={handleCanReorderRows}
            onRowsReordered={handleRowsReorder}
            {...gridProperties}
            onFocusLocationChanging={handleLocationChanged}
            onFocusLocationChanged={handleFocusLocationChanged}
            onCellsChanged={handleCellChange}
            onContextMenu={planPermission ? undefined : handleContextMenu}
            onColumnResized={
              planPermission
                ? undefined
                : (ci: any, width: any) => handleColumnResize(ci, width, setColumns)
            }
            customCellTemplates={{
              header: new HeaderRowTemplate(),
              delete: new DeleteRowCellTemplate(),
              text: new TextCellTemplate(),
              date: new DateCellTemplate(),
              addRow: new AddRowCellTemplate(handleAddRow, planPermission),
              dropdown: new DropdownCellTemplate(handleDeleteRow, planPermission),
              checkbox: new CheckboxCellTemplate(),
              formula: new FormulaCellTemplate(),
            }}
          />
        </Box>
      )}
      <DeleteGroupModal
        isOpen={deleteModal.status}
        onClose={() => setDeleteModal({ indicatorId: '', indicatorName: '', status: false })}
        itemName={deleteModal.indicatorName || 'some name'}
        onDelete={() => handleDeleteModal()}
        orgChart={true}
      />
    </Flex>
  );
};

export default PlanPermission(BuilderModePropertyTable);
