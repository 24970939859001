import { InfoIcon, CloseIcon } from '@chakra-ui/icons';
import { Flex, FormControl, VStack, Text } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import FormField from 'components/FormField';
import { ActionSteponeSchema } from 'validation/schemas/ActionStepSchema';
import {
  addSteponeFormData,
  handleActionStepNext,
  getFormValues,
  setActionCreateForm,
  clearStepperFormData,
  setClearSelectedAreasTable,
} from 'redux/DataImportSlice';

import { textStyles } from '../Helper';

import ActionStepFooter from './ActionStepFooter';

interface Properties {
  setHelpModalSeleceted: any;
  setactionCreate?: any;
}

const ActionStepOne = ({ setHelpModalSeleceted, setactionCreate }: Properties) => {
  const dispatch: any = useDispatch();
  const getFormValue = useSelector(getFormValues);
  const handleSubmit = (form: any, setSubmitting: (data: boolean) => void) => {
    dispatch(addSteponeFormData(form));
    setSubmitting(false);
    dispatch(handleActionStepNext());
  };

  return (
    <VStack w={'100%'} pt={3}>
      <Flex justifyContent={'space-between'} w={'full'} align={'center'}>
        <Text
          color={'#6562CF'}
          fontSize='lg'
          fontFamily={'regular_roboto'}
          fontWeight={'400'}
          textAlign={'start'}
        >
          Name your action
        </Text>
        <CloseIcon
          color={'black'}
          width={3}
          height={3}
          cursor={'pointer'}
          onClick={async () => {
            await dispatch(setActionCreateForm(false));
            await dispatch(clearStepperFormData());
            await dispatch(setClearSelectedAreasTable());
          }}
        />
      </Flex>
      <Formik
        initialValues={{
          name: '' || getFormValue.name,
          description: '' || getFormValue.description,
        }}
        validationSchema={ActionSteponeSchema}
        onSubmit={(form, { setSubmitting }) => {
          handleSubmit(form, setSubmitting);
        }}
        enableReinitialize={true}
      >
        {({ errors, touched, isSubmitting }: any) => (
          <Form style={{ width: '100%' }}>
            <VStack display='flex' justify={'center'} w={'100%'}>
              <VStack display='flex' justify={'center'} w={'100%'}>
                <FormControl>
                  <FormField
                    label='Name'
                    name='name'
                    type='text'
                    labelStyles={{
                      fontSize: '0.9rem',
                      color: 'black',
                    }}
                    inputStyles={{
                      height: '2.3rem',
                      boxShadow: '0px 0px 0px 0.6px #8B89B1',
                      fontSize: '0.8rem',
                      color: 'black',
                    }}
                    placeholder='Name'
                    error={errors.name}
                    touched={touched.name}
                    disabled={isSubmitting}
                  />
                </FormControl>

                <FormControl mt={4}>
                  <FormField
                    label='Description'
                    name='description'
                    type='text'
                    labelStyles={{
                      fontSize: '0.9rem',
                      color: 'black',
                    }}
                    inputStyles={{
                      fontSize: '0.8rem',
                      color: 'black',
                      height: '2.3rem',
                      boxShadow: '0px 0px 0px 0.6px #8B89B1',
                    }}
                    placeholder='Description'
                    disabled={isSubmitting}
                  />
                </FormControl>
              </VStack>
              <Flex alignItems={'center'} justifyContent={'space-between'} pt='5' pb={8} w={'100%'}>
                <Flex>
                  <Text
                    {...textStyles}
                    color={'black'}
                    textDecoration='underline'
                    onClick={() => {
                      setHelpModalSeleceted.on();
                    }}
                    display={'none'}
                  >
                    Help <InfoIcon color={'#8B89B1'} ms={3} />
                  </Text>
                </Flex>
                <ActionStepFooter
                  prevButtonName={'Cancel'}
                  nextButtonName={'Continue'}
                  isSubmitting={isSubmitting}
                  setactionCreate={setactionCreate}
                />
              </Flex>
            </VStack>
          </Form>
        )}
      </Formik>
    </VStack>
  );
};
export default ActionStepOne;
