import { Box, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import {
  getPropertiesSectionStatus,
  getSelectedDimension,
  getBaseScenario,
  getSettingsSectionStatus,
} from 'redux/BuilderModeSlice';

import DimensionBuilderModeHeader from './Header';
import BuilderSettingComponent from './Settings';
import BuilderPropertiesComponent from './Properties';
import DimensionBuilderSidebar from './Sidebar';
import NewDimensionBuilderTable from './NewTable';
import DimensionBuilderTable from './Table';

interface Properties {
  flags?: any;
}

const Dimensions = ({ flags }: Properties) => {
  const selectedDimension = useSelector(getSelectedDimension);
  const selectedScenario = useSelector(getBaseScenario);
  const settingsSectionStatus = useSelector(getSettingsSectionStatus);
  const propertiesSectionStatus = useSelector(getPropertiesSectionStatus);

  return (
    <Flex height={'100%'} gap={settingsSectionStatus || propertiesSectionStatus ? 4 : 0}>
      <Box width={settingsSectionStatus || propertiesSectionStatus ? 'calc(100% - 360px)' : '100%'}>
        <DimensionBuilderModeHeader />
        <Flex height={`calc(100% - 45px)`} width={'100%'} pt={6} gap={2}>
          {selectedScenario && <DimensionBuilderSidebar />}
          {selectedDimension && (
            <>{flags?.blox1392 ? <NewDimensionBuilderTable /> : <DimensionBuilderTable />}</>
          )}
        </Flex>
      </Box>
      <Box className={`settings-container${settingsSectionStatus ? ' visible' : ' hidden'}`}>
        {settingsSectionStatus && <BuilderSettingComponent />}
      </Box>
      <Box className={`properties-container${propertiesSectionStatus ? ' visible' : ' hidden'}`}>
        {propertiesSectionStatus && <BuilderPropertiesComponent />}
      </Box>
    </Flex>
  );
};

export default withLDConsumer()(Dimensions);
