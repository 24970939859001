import { NewTotalValues } from 'models/response/blockOutput.interface';

interface FormatTableDataParameters {
  indicatorTableData: any;
  expandKey: string;
  tableFilter: string;
  modelTimeGranularity?: string;
}

const formatIndicatorTableData = ({
  indicatorTableData,
  expandKey,
  tableFilter,
  modelTimeGranularity,
}: FormatTableDataParameters) => {
  const result: any = {};
  if (indicatorTableData) {
    const indicatorTableOutput = JSON.parse(JSON.stringify(indicatorTableData?.data));

    let keysToAdd: string[] = [];
    if (indicatorTableOutput && indicatorTableOutput.length > 0) {
      if (expandKey) {
        if (tableFilter === 'Y') {
          const year = expandKey;
          for (
            let index = 0;
            index < Object.values(indicatorTableData.time_granularity[year]).length;
            index += 1
          ) {
            const quarter: any = Object.values(indicatorTableData.time_granularity[year])[index];
            keysToAdd = keysToAdd.concat(quarter);
          }
        } else if (tableFilter === 'Q') {
          const year = expandKey.split('-')[1];
          const quarter: string = expandKey;
          keysToAdd = indicatorTableData.time_granularity[year][quarter].map((month: string) => {
            return `${month}`;
          });
        }
      }
      const tableDataArray = [];
      for (let index = 0; index < indicatorTableOutput?.length; index += 1) {
        const data = indicatorTableOutput[index];

        let filteredKeys: any = [];
        if (data?.dim_df && data?.dim_df.length > 0) {
          const items = (data.dim_df as NewTotalValues[]).map((item: any) => {
            if (data?.dim_df) {
              const itemKeys = (item.values as NewTotalValues[])?.map((subItem: any) => {
                let isShow = false;
                let isExpandedColumn = false;
                if (tableFilter === 'Q' && subItem.Time[0] === 'Q') {
                  isShow = true;
                } else if (tableFilter === 'Y' && subItem.Time.length === 4) {
                  isShow = true;
                } else if (subItem.Time.length === 6 && tableFilter === 'M') {
                  isShow = true;
                } else {
                  const check = keysToAdd.includes(subItem.Time);
                  isShow = keysToAdd && keysToAdd.length > 0 && check;
                  if (isShow) isExpandedColumn = true;
                }

                Object.assign(subItem, { isShow, isExpandedColumn });

                return subItem;
              });
              data.value = itemKeys;
            }
            return item;
          });

          data.dim_df = items;
        }
        const timeAggregation = modelTimeGranularity
          ? (modelTimeGranularity as string).toUpperCase()
          : 'M';
        if (data?.total && data?.total.length) {
          filteredKeys = (data.total as NewTotalValues[]).map((item: any) => {
            let isShow = false;
            let isExpandedColumn = false;
            let isShowExpandable = false;
            if (tableFilter === 'Q' && item.Time[0] === 'Q') {
              isShow = true;
              isShowExpandable = true;
            } else if (tableFilter === 'Y' && item.Time.length === 4) {
              isShow = true;
              isShowExpandable = true;
            } else if (item.Time.length === 6 && tableFilter === 'M') {
              isShow = true;
            } else {
              const check = keysToAdd.includes(item.Time);
              isShow = keysToAdd && keysToAdd.length > 0 && check;
              if (isShow) isExpandedColumn = true;
            }

            if (timeAggregation === 'Y') isShowExpandable = false;

            Object.assign(item, { isShow, isShowExpandable, isExpandedColumn });
            return item;
          });
        }

        data.total = filteredKeys;
        if (index === 0) {
          result.tableHeaderData = filteredKeys;
        }

        tableDataArray.push(data);
      }

      result.tableData = tableDataArray;
    } else {
      result.tableHeaderData = null;
      result.tableData = null;
    }
  }
  result.expandKey = expandKey || '';
  result.tableFilter = tableFilter || '';
  return result;
};

export default formatIndicatorTableData;
