import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Container from 'components/Container';
import Logo from 'components/Icons/Logo';
import LoginImage from 'components/Icons/LoginImage';
import {
  getAuthenticationToken,
  getSetPasswordsPageType,
  GetUserDetail,
  getUsersData,
  SetPasswordToken,
} from 'redux/AuthSlice';

import SetPasswordForm from './SetPasswordForm';
import UserDetailsForm from './UserDetailsForm';

const SetPasswordPage = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const confirmReference: any = useRef();
  const [queryParameters] = useSearchParams();

  const [alert, setAlert] = useState(false);

  const usersData = useSelector(getUsersData);
  const getToken = useSelector(getAuthenticationToken);
  const setPasswordPageType = useSelector(getSetPasswordsPageType);

  const [isMobile] = useMediaQuery('(max-width: 576px)');

  useEffect(() => {
    if (queryParameters.get('token')) {
      dispatch(SetPasswordToken(queryParameters.get('token')));
      localStorage.setItem('BLOX_PASSWORD_TOKEN', queryParameters.get('token') as string);
    } else {
      localStorage.removeItem('BLOX_PASSWORD_TOKEN');
    }
  }, [queryParameters]);

  useEffect(() => {
    const token = queryParameters.get('token') || getToken;
    dispatch(GetUserDetail({ request_body: token })).then((response: any) => {
      if (response.type.endsWith('/rejected')) {
        setAlert(true);
      }
    });
  }, []);

  if (!usersData)
    return (
      <AlertDialog
        closeOnOverlayClick={false}
        leastDestructiveRef={confirmReference}
        isOpen={alert}
        onClose={() => setAlert(!alert)}
        colorScheme='black'
      >
        <AlertDialogOverlay>
          <AlertDialogContent width={{ start: '90%' }}>
            <AlertDialogHeader fontSize='lg' fontWeight='bold' color={'black'}>
              Session expire
            </AlertDialogHeader>

            <AlertDialogBody color={'black'}>
              Your current session has been expired, Please reset your password again
            </AlertDialogBody>
            <AlertDialogFooter color={'black'} justifyContent={'center'}>
              <Button
                color={'black'}
                onClick={() => {
                  setAlert(!alert);
                  navigate('/');
                }}
                ml={3}
              >
                Ok
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    );

  return (
    <Container
      wrapperProperties={{
        px: 0,
        p: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      bgColor={'linear-gradient(180deg, rgba(101, 98, 207, 0.6) 0%, rgba(75, 182, 185, 0.6) 100%);'}
    >
      <Flex
        boxShadow={'0px 15px 15px rgba(0, 0, 0, 0.15)'}
        justifyContent={'space-between'}
        borderRadius={'15px'}
        bgColor={'white'}
        position={'relative'}
        minWidth={isMobile ? '' : '550px'}
      >
        <Flex width={'100%'} padding={'2rem'} direction={'column'}>
          <Box>
            <Logo height={10} width={'auto'} />
          </Box>
          {setPasswordPageType === 1 ? (
            <Box>
              <Heading
                display={'flex'}
                variant='secondary'
                fontFamily='bold'
                color={'#121826'}
                fontWeight={'medium'}
                fontSize={{ base: '20px', '1.5xl': '25px' }}
                marginTop={'2rem'}
              >
                Hi,
                <Text color={'green.900'} ml={'1'}>
                  {usersData?.firstname ?? ''}
                </Text>
              </Heading>
              <Text
                variant='secondary'
                fontFamily='regular_roboto'
                fontSize={{ base: '16px', '1.5xl': 'md' }}
                marginBottom={'1rem'}
                color={'#1A202C'}
                mt={3}
                mb={3}
                width={{ start: '60%', sm: '100%' }}
              >
                Set your password below to access Blox
              </Text>
            </Box>
          ) : (
            <Box>
              <Heading
                display={'flex'}
                variant='secondary'
                fontFamily='bold'
                color={'#121826'}
                fontSize={{ base: '20px', '1.5xl': '25px' }}
                marginTop={'2rem'}
              >
                One more thing...
              </Heading>
              <Text
                variant='secondary'
                fontFamily='regular_roboto'
                fontSize={{ base: '16px', '1.5xl': 'md' }}
                marginBottom={'1rem'}
                color={'#1A202C'}
                mt={2}
                mb={3}
                width={{ start: '60%', sm: '100%' }}
              >
                Please confirm your personal details below :
              </Text>
            </Box>
          )}
          <Box>
            {setPasswordPageType === 1 ? (
              <SetPasswordForm />
            ) : setPasswordPageType === 2 ? (
              <UserDetailsForm />
            ) : (
              ''
            )}
          </Box>
        </Flex>
        {/* {setPasswordPageType === 1 ? ( */}
        <Box position={'absolute'} top={'0'} right={'0'}>
          <LoginImage
            width={{ start: '180px', sm: '200px' }}
            height={{ start: '180px', sm: '200px' }}
            styles={{ borderRadius: '15px' }}
          />
        </Box>
      </Flex>
    </Container>
  );
};

export default SetPasswordPage;
