import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const ArrowRight = ({ width = 4.75, height = 4.75, color = 'white.900' }: Properties) => {
  return (
    <Icon viewBox='0 0 8 13' width={width} height={height} color={color}>
      <path
        d='M1.31681 0.227376L7.45898 6.36955L1.31681 12.5117L0.226577 11.4215L5.27851 6.36955L0.226576 1.31761L1.31681 0.227376Z'
        fill={color}
      />
    </Icon>
  );
};

export default ArrowRight;
