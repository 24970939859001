import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  Button,
  MenuOptionGroup,
  MenuList,
  MenuItemOption,
} from '@chakra-ui/react';
import { Form, Formik, ErrorMessage } from 'formik';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FormField from 'components/FormField';
import { AddTeamMember, getUserSubscriptionLimits, SetTabs } from 'redux/UserSlice';
import UserPermissions from 'utils/UserPermissions';
import { getTeamCount } from 'utils/TeamCount';
import { AddTeamMemberSchema } from 'validation/schemas/UserManagementSchema';

import UserHeading from './UserHeading';
import UserFooter from './UserFooter';
import InformationModal from './InformationModal';

interface FormValues {
  email: string;
  role: string;
}

const AddTeamMemberComponent = () => {
  const dispatch: any = useDispatch();
  const userSubscriptionLimits = useSelector(getUserSubscriptionLimits);
  const [inviteModal, setInviteModal] = useState({ modalStatus: false, userEmail: '' });

  const initialValues: FormValues = {
    email: '',
    role: '',
  };

  const handleSubmit = (data: any, setSubmitting: (data: boolean) => void) => {
    dispatch(
      AddTeamMember({
        request_body: {
          email: data.email,
          role: data.role,
        },
      }),
    ).then((response: any) => {
      setSubmitting(false);
      if (!response?.error) setInviteModal({ modalStatus: true, userEmail: data.email });
    });
  };

  const handleModalClose = () => {
    setInviteModal({ modalStatus: false, userEmail: '' });
    dispatch(SetTabs(3));
  };

  return (
    <UserPermissions scopes={['edit']} permissionType='user'>
      <Flex width={'100%'} height={'100%'} flexDir='column'>
        <Formik
          initialValues={initialValues}
          onSubmit={(form, { setSubmitting }) => {
            handleSubmit(form, setSubmitting);
          }}
          validationSchema={AddTeamMemberSchema}
        >
          {({ values, errors, touched, setFieldValue, isSubmitting }) => {
            return (
              <Form>
                <Box width={'100%'}>
                  <UserHeading heading='Add Team member' />
                  <Box backgroundColor='white.200' padding='.5rem 1rem' borderRadius='10px'>
                    <Text fontSize='2xs' color='grey.100'>
                      Team members
                    </Text>
                    <Text fontSize='xl' color='black' fontWeight='600'>
                      {getTeamCount(userSubscriptionLimits)}
                    </Text>
                  </Box>
                  <Box mt='4'>
                    <FormField
                      label='Email of the new team member'
                      name='email'
                      type='text'
                      labelStyles={{
                        fontSize: '15px',
                        color: '#212121',
                      }}
                      inputStyles={{
                        maxWidth: '100%',
                        boxShadow: '0px 0px 0px 1.5px #8B8AAE',
                        height: '35px',
                        paddingInlineStart: '8px',
                      }}
                      placeholder='Enter email'
                      error={errors.email}
                      touched={touched.email}
                      disabled={isSubmitting}
                    />
                    <Box mt='4'>
                      <Text color='#212121' mb='2' fontSize='15px'>
                        Roles
                      </Text>
                      <Menu closeOnSelect={true} isLazy>
                        <MenuButton
                          as={Button}
                          backgroundColor='white.200'
                          maxHeight={'40px'}
                          borderRadius='5px'
                          minWidth={'150px'}
                          textAlign='left'
                          padding='0 .8rem'
                          rightIcon={<ChevronDownIcon fontSize='md' />}
                          fontWeight='300'
                          fontSize='xs'
                          color='black.900'
                          w={'fit-content'}
                          disabled={isSubmitting}
                        >
                          {values.role || 'Choose role'}
                        </MenuButton>
                        <MenuList>
                          <MenuOptionGroup
                            value={values.role}
                            onChange={(role: any) => {
                              setFieldValue('role', role);
                            }}
                          >
                            <MenuItemOption value={''}>Choose role</MenuItemOption>
                            <MenuItemOption value={'admin'}>Admin</MenuItemOption>
                            <MenuItemOption value={'planner'}>Planner</MenuItemOption>
                          </MenuOptionGroup>
                        </MenuList>
                      </Menu>
                      <ErrorMessage name='role' component='div' className='error' />
                    </Box>
                  </Box>
                  <UserFooter
                    isSubmitting={isSubmitting}
                    disabled={Object.keys(errors).length > 0}
                    redirectTo={3}
                    buttonName='Send Invitation'
                  />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </Flex>
      <InformationModal
        modalHeading='Invitation'
        userName={inviteModal.userEmail}
        isOpen={inviteModal.modalStatus}
        onClose={handleModalClose}
      />
    </UserPermissions>
  );
};

export default AddTeamMemberComponent;
