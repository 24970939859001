import React, { useEffect } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import { useAuthContext } from 'context/authContext';

interface Properties {
  children: JSX.Element;
}

const ProtectedRoutes = ({ children }: Properties) => {
  const { isAuth } = useAuthContext();
  const location = useLocation();
  const { token } = useParams();

  useEffect(() => {
    if (token && !isAuth) {
      window.localStorage.setItem('model-token', token);
    }
  }, [isAuth, token]);

  if (isAuth) return children;

  if (token) return <Navigate to='/signup' state={{ from: location }} replace />;

  return <Navigate to='/' state={{ from: location }} replace />;
};

export default ProtectedRoutes;
