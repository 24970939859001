/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React, { useEffect, useRef, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BsArrowRight } from 'react-icons/bs';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Text,
  Flex,
  Popover,
  useBoolean,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useOutsideClick,
} from '@chakra-ui/react';

import Calendar from 'components/Icons/Calendar';
import DatePickerInputButton from 'pages/ModelOverviewPage/DatePickerWithButton';
import { getModelDetails } from 'redux/PlanPageSlice';
import { TimeProperties } from 'models/response/model.interface';
import { getTimescaleDate } from 'redux/ModelsSlice';
import {
  getWidgetData,
  getAllChartData,
  handleTimeChange,
  updateChartFilter,
  fetchDashboardGraph,
  fetchDashboardChart,
  handleAddAllChartData,
  getDashboardWidgetType,
  getDashboardSelectedScenario,
  // getTableIndicator,
} from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';
import { formateDate, getPreviousMonth, priorYear, convertDate, getDate } from 'utils/ConvertDate';

interface TimeFilter {
  name: string;
  operator: any;
  value: any;
  second_value?: any;
  filter_type: string;
  chart_id: number;
  id?: string | number;
}

interface TimeRangeFilterProperties {
  chartFilters?: any;
  chartId?: any;
}

const TimeRangeFilter = ({ chartFilters, chartId }: TimeRangeFilterProperties) => {
  const widgetData = useSelector(getWidgetData);
  const widgetType = useSelector(getDashboardWidgetType);
  const reference = useRef(null);
  const dispatch: AppDispatch = useDispatch();
  const getTimeDate = useSelector(getTimescaleDate);
  const model = useSelector(getModelDetails);
  const [timeDetail, setTimeDetail] = useState({});
  const [timescale, setTimescale] = useState('Y');
  const [endDate, setEndDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [timeScalePopover, setTimeScalePopover] = useBoolean();

  const allChartData = useSelector(getAllChartData);
  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const scenarioId = selectedScenario?.id;

  const formatTimeProperties = (properties: TimeProperties) => ({
    ...properties,
    time_granularity: properties?.time_granularity ?? 'M',
  });

  const properties = formatTimeProperties(model?.time_properties);
  const timeFilter = useMemo(() => {
    if (chartFilters) {
      const chartDataId = chartFilters
        ?.map((item: any) => {
          if (item.name === 'Time' && item.filter_type === 'primary') {
            return item;
          }
        })
        .filter((item: any) => item !== undefined);
      return chartDataId[0];
    }
    return null;
  }, [chartFilters]);

  const timeType = useMemo(() => {
    if (timeFilter?.length === 0) {
      setStartDate(new Date(getTimeDate?.startDate));
      setEndDate(new Date());
      return 'single date';
    }
    let timeStartDate = '';
    let timeEndDate = '';
    const letestActuals = getPreviousMonth(new Date(getTimeDate?.startDate));
    chartFilters?.forEach((item: any) => {
      if (item.name === 'Time' && item.filter_type === 'primary') {
        timeStartDate = item.value;
        timeEndDate = item.second_value;
      }
    });
    const newStartDate: any = getDate(timeStartDate);
    setStartDate(new Date(newStartDate));
    const newEndDate: any = getDate(timeEndDate);
    setEndDate(new Date(newEndDate));
    if (timeStartDate && timeEndDate) {
      return 'date range';
    }
    if (new Date(newStartDate).getTime() === new Date(letestActuals).getTime()) {
      return 'leteast actuals';
    }
    return 'single date';
  }, [chartFilters, getTimeDate]);

  const comparisionTimeFilterId = useMemo(() => {
    if (chartFilters) {
      const chartDataId = chartFilters?.find(
        (item: any) => item.name === 'Time' && item.filter_type === 'comparision',
      );
      return chartDataId?.id;
    }
    return null;
  }, [chartFilters]);

  useEffect(() => {
    setTimescale(properties?.time_granularity?.toUpperCase() ?? 'M');
  }, [properties]);

  const updateTieFilter = (payload: any, startIn: any) => {
    const oneYearPrior = priorYear(new Date(startIn));
    const timeFilterPaylaod = {
      ...payload,
      id: timeFilter?.id,
    };
    const comparisionFilter: TimeFilter = {
      name: 'Time',
      operator: 'between',
      value: convertDate(formateDate(oneYearPrior)),
      second_value: convertDate(formateDate(getPreviousMonth(new Date(startIn)))),
      filter_type: 'comparision',
      chart_id: chartId,
    };
    if (chartId) {
      dispatch(updateChartFilter(timeFilterPaylaod)).then(() => {
        if (widgetType === 1) {
          dispatch(fetchDashboardChart(chartId)).then((data: any) => {
            const updatedData = allChartData[data?.payload.id];
            const layoutData = {
              id: updatedData.id,
              x: Number(updatedData.x),
              y: Number(updatedData.y),
              h: updatedData.h || 2,
              w: updatedData.w || 2,
              chart_type: updatedData.chart_type,
            };
            dispatch(handleAddAllChartData({ ...data.payload, ...layoutData }));
            dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
            if (timeType === 'date range' && comparisionTimeFilterId) {
              dispatch(
                updateChartFilter({ ...comparisionFilter, id: comparisionTimeFilterId }),
              ).then(() => {
                dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
                dispatch(fetchDashboardChart(chartId)).then((chartData: any) => {
                  const updatedChartData = allChartData[chartData?.payload.id];
                  const layoutChartData = {
                    id: updatedChartData.id,
                    x: Number(updatedChartData.x),
                    y: Number(updatedChartData.y),
                    h: updatedChartData.h || 2,
                    w: updatedChartData.w || 2,
                    chart_type: updatedChartData.chart_type,
                  };
                  dispatch(handleAddAllChartData({ ...chartData.payload, ...layoutChartData }));
                });
              });
            }
          });
        } else {
          dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] })).then(
            (data: any) => {
              const updatedData = allChartData[chartId];
              const layoutData = {
                id: updatedData.id,
                x: Number(updatedData.x),
                y: Number(updatedData.y),
                h: updatedData.h || 2,
                w: updatedData.w || 2,
                chart_type: updatedData.chart_type,
              };
              dispatch(handleAddAllChartData({ ...data.payload, ...layoutData }));
              dispatch(fetchDashboardChart(chartId));
              if (timeType === 'date range' && comparisionTimeFilterId) {
                dispatch(
                  updateChartFilter({ ...comparisionFilter, id: comparisionTimeFilterId }),
                ).then(() => {
                  dispatch(fetchDashboardChart(chartId));
                  dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] })).then(
                    (chartData: any) => {
                      const updatedChartData = allChartData[chartId];
                      const layoutChartData = {
                        id: updatedChartData.id,
                        x: Number(updatedChartData.x),
                        y: Number(updatedChartData.y),
                        h: updatedChartData.h || 2,
                        w: updatedChartData.w || 2,
                        chart_type: updatedChartData.chart_type,
                      };
                      dispatch(handleAddAllChartData({ ...chartData.payload, ...layoutChartData }));
                    },
                  );
                });
              }
            },
          );
        }
      });
    }
  };

  const handleStartDateChange = async (date: any) => {
    await setStartDate(date);
    const formatStartDate = formateDate(date);
    await setTimeDetail({ ...timeDetail, start: formatStartDate, detail: 1 });
    await dispatch(
      handleTimeChange({
        ...widgetData.time,
        startDate: formatStartDate,
      }),
    );
    if (timeFilter?.id) {
      if (timeFilter?.operator === 'eq') {
        const payload = {
          name: 'Time',
          operator: 'eq',
          value: convertDate(formatStartDate),
          chart_id: chartId,
          filter_type: 'primary',
        };
        await updateTieFilter(payload, date);
      } else {
        const payload = {
          name: 'Time',
          operator: 'between',
          value: convertDate(formatStartDate),
          second_value: convertDate(formateDate(endDate)),
          chart_id: chartId,
          filter_type: 'primary',
        };
        await updateTieFilter(payload, date);
      }
    }
  };
  const handleEndDateChange = async (date: any) => {
    await setEndDate(date);
    const formatEndDate = formateDate(date);
    await setTimeDetail({ ...timeDetail, end: formatEndDate, detail: 1 });
    await dispatch(
      handleTimeChange({
        ...widgetData.time,
        endDate: formatEndDate,
      }),
    );
    if (timeFilter?.id) {
      const payload = {
        name: 'Time',
        operator: 'between',
        value: convertDate(formateDate(startDate)),
        second_value: convertDate(formatEndDate),
        chart_id: chartId,
        filter_type: 'primary',
      };
      await updateTieFilter(payload, startDate);
    }
  };

  const calculateShowData = (data: any) => {
    if (timescale === 'Y') {
      return data?.getFullYear();
    }
    return `${data?.toLocaleDateString('en-US', { month: 'short' })} ${data
      ?.getFullYear()
      .toString()
      .slice(-2)}`;
  };

  useOutsideClick({
    ref: reference,
    handler: () => setTimeScalePopover.off(),
  });

  return (
    <Flex
      gap={1}
      mr={2}
      bgColor='#f5f5f5'
      borderRadius='8px'
      p='0.4rem .5rem'
      minWidth={timeType === 'date range' ? '175px' : ''}
    >
      <Popover
        placement='bottom-start'
        closeOnBlur={false}
        isOpen={timeScalePopover}
        onOpen={setTimeScalePopover.on}
        onClose={setTimeScalePopover.off}
        isLazy
        lazyBehavior='keepMounted'
      >
        <Box ref={reference}>
          {timeType !== 'single date' && (
            <PopoverTrigger>
              <Flex gap={1} cursor='pointer' alignItems='center'>
                <Calendar style={{ marginRight: '.2rem' }} width={8} height={6} color='#69B4B8' />
                <Flex>
                  {timeType === 'leteast actuals' && (
                    <Box alignItems='center'>
                      <Text>Latest Actuals</Text>
                      <Text m={0} mr={1} ml={1} size='Roboto-12'>
                        {`(${calculateShowData(startDate)})`}
                      </Text>
                    </Box>
                  )}
                  {/* {timeType === 'single date' && (
                    <Flex alignItems='center'>
                      <Text mr={1} ml={1} size='Roboto-12'>
                        {calculateShowData(startDate)}
                      </Text>
                    </Flex>
                  )} */}
                  {timeType === 'date range' && (
                    <Flex alignItems='center'>
                      <Text mr={1} ml={1} size='Roboto-12'>
                        {calculateShowData(startDate)}
                      </Text>
                      <Text fontSize='14px' mx={1}>
                        -
                      </Text>
                      <Text mr={1} size='Roboto-12'>
                        {calculateShowData(endDate)}
                      </Text>
                    </Flex>
                  )}
                </Flex>
                <ChevronDownIcon w={5} h={5} />
              </Flex>
            </PopoverTrigger>
          )}
          <PopoverContent
            width={timeType === 'single date' ? '125px' : ''}
            filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))'
            bg='white'
            border='none'
            mt={3}
            borderRadius='8px'
          >
            {timeType === 'date range' && (
              <PopoverBody ps={4}>
                <Box pt={4} pb={2}>
                  <Text color='#6562CF' fontSize='xs' fontWeight='600'>
                    Timescale
                  </Text>
                </Box>
                <Flex justify='start' align='center' pb={3}>
                  <DatePickerInputButton
                    clearSection={false}
                    selected={startDate}
                    onChange={handleStartDateChange}
                    timescale={timescale}
                  />
                  <Flex px={2}>
                    <BsArrowRight color='black' style={{ width: '20px', height: '20px' }} />
                  </Flex>
                  <DatePickerInputButton
                    clearSection={false}
                    selected={endDate}
                    onChange={handleEndDateChange}
                    timescale={timescale}
                  />
                </Flex>
                {/* <Text color='#8B8AAB' fontSize='12px' ps={1}></Text> */}
              </PopoverBody>
            )}
          </PopoverContent>
        </Box>
      </Popover>
      {timeType === 'single date' && (
        <Flex alignItems={'center'} bg='white'>
          <Calendar style={{ marginRight: '.2rem' }} width={8} height={6} color='#69B4B8' />
          <DatePickerInputButton
            clearSection={false}
            selected={startDate}
            onChange={handleStartDateChange}
            timescale={timescale}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default TimeRangeFilter;
