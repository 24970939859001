import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const SaveModel = ({ width = 5, height = 5, color = 'green.900' }: Properties) => {
  return (
    <Icon viewBox='0 0 53 53' width={width} height={height} color={color}>
      <svg
        width='54'
        height='53'
        viewBox='0 0 54 53'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M41.6876 0.292969H6.76145C3.53079 0.292969 0.94043 2.91243 0.94043 6.11399V46.8612C0.94043 50.0627 3.53079 52.6822 6.76145 52.6822H47.5086C50.7102 52.6822 53.3296 50.0627 53.3296 46.8612V11.935L41.6876 0.292969ZM27.135 46.8612C22.3036 46.8612 18.4035 42.9611 18.4035 38.1296C18.4035 33.2982 22.3036 29.3981 27.135 29.3981C31.9665 29.3981 35.8666 33.2982 35.8666 38.1296C35.8666 42.9611 31.9665 46.8612 27.135 46.8612ZM35.8666 17.756H6.76145V6.11399H35.8666V17.756Z'
          fill='red'
        />
      </svg>
      ;
    </Icon>
  );
};

export default SaveModel;
