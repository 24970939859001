import { Button, Flex } from '@chakra-ui/react';

interface Properties {
  handleCreate: () => void;
  handleCancel: () => void;
}

const DashboardModalButtons = ({ handleCreate, handleCancel }: Properties) => {
  return (
    <Flex bgColor={'white'} justifyContent={'end'} minH={'45px'} p={'0 1rem 1rem 0'} gap={4}>
      <Button variant={'save_button'} onClick={handleCancel}>
        Cancel
      </Button>
      <Button variant={'save_button'} onClick={handleCreate}>
        Save
      </Button>
    </Flex>
  );
};

export default DashboardModalButtons;
