import { Box, Flex } from '@chakra-ui/react';
import { connect, useDispatch } from 'react-redux';

import Cross from 'components/Icons/Cross';
import GridView from 'components/Icons/GridView';
import TableView from 'components/Icons/TableView';
import { updateOrgType } from 'redux/ItemPageSlice';
import { setDriver } from 'redux/PlanPageSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

import DesktopPanel from '../ItemPage/DesktopPanel';
import TableViewComponent from '../ItemPage/DesktopPanel/TableView';

const OrganizationType = ({
  longClickCheck,
  isLongClicked,
  refetchRequest,
  orgChartType,
  updateOrg,
}: any) => {
  const dispatch: AppDispatch = useDispatch();
  const closeSection = () => {
    dispatch(setDriver(null));
  };
  return (
    <Box height={'100%'}>
      <Flex justifyContent={'space-between'}>
        <Box display={'flex'} justifyContent={'flex-end'} padding={'1rem'}>
          <button onClick={() => updateOrg(2)}>
            <TableView selected={orgChartType === 2} />
          </button>
          <button onClick={() => updateOrg(1)}>
            <GridView selected={orgChartType === 1} />
          </button>
        </Box>
        <Flex justifyContent={'end'} padding={'1rem'}>
          <Cross onClick={closeSection} />
        </Flex>
      </Flex>
      {orgChartType === 1 ? (
        <TableViewComponent refetchRequest={refetchRequest} />
      ) : (
        <DesktopPanel
          orgType={true}
          longClickCheck={longClickCheck}
          isLongClicked={isLongClicked}
          refetchRequest={refetchRequest}
        />
      )}
    </Box>
  );
};
const stateToProperty = (state: any) => {
  return {
    orgChartType: state.itemPageSlice.orgChartType,
  };
};

const mapDispatchToProperties = (dispatch: any) => {
  return {
    updateOrg: (data: any) => dispatch(updateOrgType(data)),
  };
};

export default connect(stateToProperty, mapDispatchToProperties)(OrganizationType);
