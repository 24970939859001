import { Box, Flex, Skeleton } from '@chakra-ui/react';

const PlanPageShimmer = () => (
  <>
    <Skeleton h={'45vh'} />
    <Flex direction='column' pt='6vh'>
      {[...new Array(5)].map((_, index) => (
        <Flex key={index} p={1}>
          {[...new Array(5)].map((__, subIndex: number) => (
            <Box key={subIndex} flex={1} mr={2}>
              <Skeleton height='20px' width='100%' />
            </Box>
          ))}
        </Flex>
      ))}
    </Flex>
  </>
);

export default PlanPageShimmer;
