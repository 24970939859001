import { ChevronDownIcon, CheckIcon } from '@chakra-ui/icons';
import {
  Flex,
  Stack,
  Divider,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  Box,
  useMediaQuery,
  Tooltip,
  useOutsideClick,
  Image,
  useBoolean,
} from '@chakra-ui/react';
import { connect, useSelector, useDispatch } from 'react-redux';
import React, { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import BranchIcon from 'components/Icons/BranchIcon';
import {
  deleteScenarios,
  duplicateScenarios,
  fetchAllScenarios,
  getAllScenarios,
  getBaseScenario,
  getCompareScenario,
  renameScenario,
  selectBaseScenario,
  selectCompareScenario,
  toggleCompareScenario,
} from 'redux/ScenarioSlice';
import { Scenario } from 'models/response/scenario.interface';
import PlanPermission from 'container/PlanPermission';
import DeleteModal from 'components/Modals/DeleteModal';
import { getUserSubscriptionLimits } from 'redux/UserSlice';
import ScenarioPermission from 'pages/PermissionComponent/ScenarioPermission';
import { selectBaseScenario as selectDimensionScenario } from 'redux/BuilderModeSlice';
import { selectBaseScenario as selectBuilderModeScenario } from 'redux/PlannerModeSlice';
import { FetchBlockItemDimensions } from 'redux/PlanPageSlice';

import ScenarioForm from '../../ScenarioForm';
import infoIcon from '../../ItemPage/Icons/infoIcon.svg';

interface BaseScenarioState {
  currentScenario: Scenario;
  isSelectCompare: boolean;
  selectScenario: (scenario: Scenario) => any;
  planPermission: boolean;
  getAllscenarios: any;
  deleteScenario: any;
  duplicateScenario: any;
  refetchRequest: () => void;
}

const ScenarioFilter = ({
  selectScenario,
  currentScenario,
  isSelectCompare,
  planPermission,
  getAllscenarios,
  deleteScenario,
  duplicateScenario,
  refetchRequest,
}: BaseScenarioState) => {
  const scenarios = useSelector(getAllScenarios);
  const { modelId, blockId } = useParams();
  const dispatch: any = useDispatch();
  const [isMobile] = useMediaQuery('(max-width: 681px)');

  const baseScenario = useSelector(getBaseScenario);
  const compareScenario = useSelector(getCompareScenario);
  const reference = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [addScenarioPopover, setAddScenarioPopover] = useBoolean();
  const [displayInfo, setDisplayInfo]: any = useState(null);
  const [renameItemId, setRenameItemId] = useState<number | null>(null);
  const [editScenarioData, setEditScenarioData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isPermissionComponent, setIsPermissionComponent] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteIdModal, setDeleteIdModal]: any = useState(null);
  const [DeleteNameModal, setDeleteNameModal] = useState('');

  const [isSmallLaptop] = useMediaQuery('(max-width: 1415px)');
  const [showPopover, setShowPopover] = useState(false);
  const UserSubscriptionLimitData = useSelector(getUserSubscriptionLimits);

  useEffect(() => {
    if (baseScenario) {
      const base = scenarios.find((scenario: Scenario) => {
        return scenario.id === baseScenario.id;
      });

      if (base) {
        dispatch(selectBaseScenario(base));
      } else if (scenarios.length > 0) {
        const baseSec = scenarios.find((scenario: Scenario) => {
          return scenario.is_base;
        });
        dispatch(selectBaseScenario(baseSec));
      }
    }
  }, [scenarios]);
  const toggleInfoPopup = (event: any, id: number | null) => {
    event.stopPropagation();
    if (displayInfo === id) {
      return setDisplayInfo(null);
    }
    return setDisplayInfo(id);
  };

  useOutsideClick({
    ref: reference,
    handler: () => {
      setShowPopover(false);
      setDisplayInfo(null);
      setRenameItemId(0);
      setAddScenarioPopover.off();
      setIsPermissionComponent(false);
      setEditScenarioData(null);
      setIsEdit(false);
    },
  });

  const scenariosLength = scenarios?.length ?? 0;

  const selectCurrentScenario = (scenario: Scenario) => {
    dispatch(selectBuilderModeScenario(scenario));
    dispatch(selectDimensionScenario(scenario));

    selectScenario(scenario).then(() =>
      dispatch(
        FetchBlockItemDimensions({
          blockId,
          parameters: {
            params: { scenario_id: scenario?.id },
          },
        }),
      ),
    );
    setShowPopover(false);
  };

  const handleCancel = () => {
    setAddScenarioPopover.off();
    setEditScenarioData(null);
    setIsEdit(false);
  };
  const renameItem = (event: any, scenario: any) => {
    event.stopPropagation();
    setDisplayInfo(null);
    if (renameItemId === scenario?.id) {
      setRenameItemId(0);
      setEditScenarioData(null);
      setIsEdit(false);
    }
    setRenameItemId(scenario?.id);
    setAddScenarioPopover.on();
    setEditScenarioData(scenario);
    setIsEdit(true);
  };
  const copyScenario = async (event: any, item: any) => {
    event.stopPropagation();
    const requestBody = {
      scenarioId: item?.id,
      data: {
        name: `${item?.name} - Copy`,
      },
      toaster: {
        successMessage: 'Successfully duplicated scenario',
        errorMessage: 'Failed to duplicate',
      },
    };
    if (UserSubscriptionLimitData?.subscription_features_limit?.scenario_per_model === null) {
      setIsPermissionComponent(false);
      await duplicateScenario(requestBody);
      await getAllscenarios(modelId);
    } else if (
      scenarios?.length ===
        UserSubscriptionLimitData?.subscription_features_limit?.scenario_per_model ||
      scenarios?.length >=
        UserSubscriptionLimitData?.subscription_features_limit?.scenario_per_model
    ) {
      setIsPermissionComponent(true);
    } else {
      setIsPermissionComponent(false);
      await duplicateScenario(requestBody);
      await getAllscenarios(modelId);
    }
  };

  const deleteScenarioModal = async (event: any, id: number, name: any) => {
    event.stopPropagation();
    setIsDeleteModal(true);
    setDeleteIdModal(id);
    setDeleteNameModal(name);
  };
  const onDeleteModel = async () => {
    await deleteScenario(deleteIdModal);
    await getAllscenarios(modelId);
    await setIsDeleteModal(false);
    await setDeleteIdModal(null);
    await setDeleteNameModal('');
    if (baseScenario?.id === deleteIdModal) {
      const randomScenario = scenarios.find((scenario: Scenario) => {
        return scenario?.id !== deleteIdModal;
      });
      if (randomScenario) {
        dispatch(selectBaseScenario(randomScenario));
      }
    }
    if (compareScenario?.id === deleteIdModal) {
      const nextCompare: Scenario | null = scenarios.find((scenario: Scenario) => {
        return scenario.id !== compareScenario.id && scenario.id !== baseScenario.id;
      });
      if (nextCompare) {
        dispatch(selectCompareScenario(nextCompare));
      } else {
        await dispatch(selectCompareScenario(null));
        await dispatch(toggleCompareScenario(false));
      }
    }
  };
  const OpenPopover = () => {
    if (UserSubscriptionLimitData?.subscription_features_limit?.scenario_per_model === null) {
      setIsPermissionComponent(false);
      setAddScenarioPopover.on();
      setDisplayInfo(null);
    } else if (
      scenarios?.length ===
        UserSubscriptionLimitData?.subscription_features_limit?.scenario_per_model ||
      scenarios?.length >=
        UserSubscriptionLimitData?.subscription_features_limit?.scenario_per_model
    ) {
      setAddScenarioPopover.off();
      setIsPermissionComponent(true);
    } else if (planPermission === true) {
      setAddScenarioPopover.off();
    } else {
      setIsPermissionComponent(false);
      setAddScenarioPopover.on();
      setDisplayInfo(null);
    }
    setEditScenarioData(null);
    setIsEdit(false);
  };

  useEffect(() => {
    setIsPermissionComponent(false);
  }, [UserSubscriptionLimitData?.subscription_features_limit?.scenario_per_model]);

  return (
    <>
      <Stack width={'fit-content'}>
        {scenarios?.length > 0 && (
          <Box ref={reference} height={'40px'} width={'150px'}>
            <Flex
              alignSelf='left'
              w='fit-content'
              minH={isSmallLaptop ? '36px' : '36px'}
              bgColor='#7163D0'
              p='0 8px'
              borderRadius={'7px'}
              filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))'
              transition='all 0.3s ease-out'
              justifyContent='space-between'
              alignItems='center'
              cursor='pointer'
              onClick={() => setShowPopover(!showPopover)}
            >
              <Flex width='fit-content' alignItems='center' justifyContent={'space-between'}>
                <Flex>
                  <BranchIcon height={5} width={5} />
                  <Tooltip label={currentScenario?.name}>
                    <Text
                      fontSize={isSmallLaptop ? '2xs' : 'xs'}
                      display={'flex'}
                      alignItems={'center'}
                      color='white'
                      whiteSpace='nowrap'
                      overflow='hidden'
                      ml='2'
                      textOverflow='ellipsis'
                      width={'5rem'}
                      fontFamily='regular_roboto'
                    >
                      {currentScenario?.name}
                    </Text>
                  </Tooltip>
                </Flex>
                <ChevronDownIcon boxSize={{ base: 6 }} color='white' alignSelf={'center'} />
              </Flex>
            </Flex>
            {showPopover && (
              <Box borderRadius='14px' width={'fit-content'} minH={isSmallLaptop ? '36px' : '36px'}>
                <Box
                  // overflowY='auto'
                  ml={{ base: '5', md: '3' }}
                  filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))'
                  bg='white'
                  border='none'
                  width={'250px'}
                  borderRadius='14px'
                  zIndex={'dropdown'}
                  position={'relative'}
                  maxHeight={'41vh'}
                  // overflowY={'auto'}
                  className={'scroll'}
                >
                  <Box
                    height={'fit-content'}
                    maxHeight={'30vh'}
                    m={'0.8rem'}
                    pt={'1rem'}
                    mb={0}
                    className='scroll'
                    overflowY={'scroll'}
                  >
                    {scenarios?.length > 0 &&
                      scenarios.map((scenario: Scenario, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            {((isSelectCompare && baseScenario?.id !== scenario?.id) ||
                              (!isSelectCompare && compareScenario?.id !== scenario?.id)) && (
                              <Flex
                                alignItems={'center'}
                                justifyContent={'space-between'}
                                key={scenario.id}
                                cursor='pointer'
                                borderRadius='7px'
                                px='1'
                                py='1'
                                onClick={() => selectCurrentScenario(scenario)}
                                bg={
                                  baseScenario?.id === scenario?.id ||
                                  compareScenario?.id === scenario?.id
                                    ? '#EFEEFE'
                                    : 'transparent'
                                }
                              >
                                <Box
                                  display={'flex'}
                                  color={'#8B89B1'}
                                  alignItems={'center'}
                                  width={'100%'}
                                >
                                  {scenario?.id === currentScenario?.id && (
                                    <CheckIcon height={4} width={4} />
                                  )}
                                  <Tooltip label={scenario?.name}>
                                    <Text
                                      px='2'
                                      py='0'
                                      color='black'
                                      fontSize={'xs'}
                                      overflow='hidden'
                                      // whiteSpace='nowrap'
                                      // textOverflow='ellipsis'
                                      width={'160px'}
                                      fontFamily='regular_roboto'
                                      noOfLines={2}
                                    >
                                      {scenario?.name}
                                    </Text>
                                  </Tooltip>
                                </Box>
                                <Flex alignItems='center' position='relative'>
                                  <Flex
                                    w='25px'
                                    height='25px'
                                    mr='15px'
                                    alignItems='center'
                                    cursor={planPermission ? 'not-allowed' : 'pointer'}
                                    onClick={
                                      planPermission
                                        ? undefined
                                        : (event) => {
                                            toggleInfoPopup(event, scenario.id);
                                            setAddScenarioPopover.off();
                                          }
                                    }
                                  >
                                    <Image
                                      src={infoIcon}
                                      w='20px'
                                      height='8px'
                                      className='infoButton'
                                    />
                                  </Flex>
                                  <Flex
                                    bgColor='white'
                                    width='fit-content'
                                    position='absolute'
                                    display={displayInfo === scenario.id ? 'block' : 'none'}
                                    zIndex='dropdown'
                                    top={
                                      scenariosLength > 3 && index >= scenariosLength - 3
                                        ? '-100px'
                                        : '28px'
                                    }
                                    right='0'
                                    p='5px 0'
                                    filter='drop-shadow(0px 0px 15.998px rgba(0, 0, 0, 0.25))'
                                    borderRadius='4px'
                                  >
                                    <Box
                                      p='5px 15px'
                                      _hover={{
                                        background: '#EEF2F6',
                                        color: 'black',
                                      }}
                                    >
                                      <Text
                                        color='black'
                                        fontSize={'xs'}
                                        onClick={
                                          planPermission
                                            ? undefined
                                            : (event) => renameItem(event, scenario)
                                        }
                                      >
                                        Edit
                                      </Text>
                                    </Box>
                                    <Box
                                      p='5px 15px'
                                      _hover={{
                                        background: '#EEF2F6',
                                        color: 'black',
                                      }}
                                    >
                                      <Text
                                        color='black'
                                        fontSize={'xs'}
                                        onClick={
                                          planPermission
                                            ? undefined
                                            : (event) => copyScenario(event, scenario)
                                        }
                                      >
                                        Duplicate
                                      </Text>
                                    </Box>
                                    {scenarios?.length !== 1 && (
                                      <Box
                                        p='5px 15px'
                                        _hover={{
                                          background: '#EEF2F6',
                                          color: 'black',
                                        }}
                                      >
                                        <Text
                                          color='black'
                                          fontSize={'xs'}
                                          onClick={
                                            planPermission
                                              ? undefined
                                              : (event) => {
                                                  deleteScenarioModal(
                                                    event,
                                                    scenario?.id,
                                                    scenario?.name,
                                                  );
                                                }
                                          }
                                        >
                                          Delete
                                        </Text>
                                      </Box>
                                    )}
                                  </Flex>
                                </Flex>
                              </Flex>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </Box>
                  <Divider orientation='horizontal' mt={'2'} color={'#e5e7ee'} />
                  <Popover
                    placement={isMobile ? 'bottom' : 'right-start'}
                    closeOnBlur={false}
                    isOpen={addScenarioPopover}
                    onOpen={planPermission ? undefined : OpenPopover}
                    onClose={setAddScenarioPopover.off}
                    isLazy
                    lazyBehavior='keepMounted'
                  >
                    <PopoverTrigger>
                      <Text
                        mt={scenarios?.length > 0 ? '2' : '0'}
                        color='#6562CF'
                        fontSize={'xs'}
                        fontWeight={'600'}
                        px={3}
                        cursor={planPermission ? 'not-allowed' : 'pointer'}
                        pb={2}
                      >
                        + New Scenario
                      </Text>
                    </PopoverTrigger>
                    <PopoverContent
                      p={5}
                      filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))'
                      bg='white'
                      borderRadius='14px'
                    >
                      <ScenarioForm
                        refetchRequest={refetchRequest}
                        onCancel={handleCancel}
                        editScenarioData={editScenarioData}
                        isEdit={isEdit}
                        setIsEdit={setIsEdit}
                      />
                    </PopoverContent>
                  </Popover>
                  {isPermissionComponent && (
                    <ScenarioPermission
                      ScenarioLimit={
                        UserSubscriptionLimitData?.subscription_features_limit?.scenario_per_model
                      }
                      CurrentScenarioLength={scenarios?.length}
                    />
                  )}
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Stack>
      <DeleteModal
        isOpen={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
        itemName={DeleteNameModal}
        onDelete={onDeleteModel}
      />
    </>
  );
};

const stateToProperty = (state: any) => {
  return {
    modelDetail: state.sharedSlice?.modelDetail,
    modelStatus: state.sharedSlice?.modelStatus,
    scenarioList: state.scenarioSlice?.scenarioList,
    scenarioListStatus: state.scenarioSlice?.scenarioListStatus,
  };
};

const mapDispatchToProperties = (dispatch: any) => {
  return {
    getAllscenarios: (modelId: number) => dispatch(fetchAllScenarios(modelId)),
    renameScenarioApi: (data: any) => dispatch(renameScenario(data)),
    duplicateScenario: (data: any) => dispatch(duplicateScenarios(data)),
    deleteScenario: (scenarioId: number) => dispatch(deleteScenarios(scenarioId)),
    seletctBaseScenario: (scenario: Scenario) => dispatch(selectBaseScenario(scenario)),
  };
};

export default connect(stateToProperty, mapDispatchToProperties)(PlanPermission(ScenarioFilter));
