import {
  Box,
  Flex,
  Menu,
  Text,
  Button,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragHandleIcon } from '@chakra-ui/icons';

import DimensionTabIcon from 'components/Icons/ModelOverviewTabs/DimensionTabIcon';
import {
  DeleteDimension,
  DuplicateDimension,
  FetchDimensionList,
  getAddDimension,
  getCurrentEditDimension,
  getDimensionLoaderStatus,
  getPropertiesSectionStatus,
  getSelectedDimension,
  getSettingsSectionStatus,
  handleBuilderSelectedDimension,
  handleDimensionItemsChange,
  handleEditDimensionItem,
  handlePropertySection,
  handleSettingsSection,
  UpdateDimensionName,
} from 'redux/BuilderModeSlice';
import InputField from 'components/InputField';
import HorizontalMenu from 'components/Icons/horizontalMenu';

const DimensionItemTab = ({ dimData, index }: any) => {
  const dispatch: any = useDispatch();
  const parameters = useParams().modelId;
  const inputReference: any = useRef(null);

  const addDimension = useSelector(getAddDimension);
  const selectedDimension: any = useSelector(getSelectedDimension);
  const dimensionLoader = useSelector(getDimensionLoaderStatus);
  const currentEditDimension = useSelector(getCurrentEditDimension);
  const settingsSectionStatus = useSelector(getSettingsSectionStatus);
  const propertiesSectionStatus = useSelector(getPropertiesSectionStatus);

  const [hover, setHover] = useState<boolean>(false);

  const { attributes, listeners, setNodeRef, transform, isDragging } = useSortable({
    id: dimData?.id,
  });
  const style = {
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1,
  };

  const updateDimensionName = (value: string, id: number) => {
    dispatch(
      UpdateDimensionName({
        item_id: id,
        request_body: {
          name: value,
        },
      }),
    ).then(() => {
      if (parameters) dispatch(FetchDimensionList(parameters));
      dispatch(handleEditDimensionItem(null));
    });
  };

  const handleDeleteDimension = (itemid: number) => {
    dispatch(DeleteDimension(itemid)).then(() => {
      if (parameters)
        dispatch(FetchDimensionList(parameters)).then((response: any) => {
          dispatch(handleBuilderSelectedDimension(response?.payload?.dimensions[0]));
        });
    });
  };

  const handleDimensionClick = (data: any) => {
    dispatch(handleDimensionItemsChange({ dimensionFilters: [] }));
    if (settingsSectionStatus) {
      dispatch(handleSettingsSection());
    }
    if (propertiesSectionStatus) {
      dispatch(handlePropertySection());
    }
    dispatch(handleBuilderSelectedDimension(data));
  };

  useEffect(() => {
    const setReference = () => {
      setTimeout(() => {
        if (addDimension && inputReference.current) {
          inputReference.current.focus();
        }
      }, 0);
    };
    setReference();
  }, [addDimension]);

  return (
    <Flex
      key={index}
      alignItems={'center'}
      justifyContent={'space-between'}
      background={selectedDimension?.id === dimData?.id ? 'green.900' : '#7163D0'}
      border={'1px #665da7 solid'}
      color={'white'}
      borderRadius={'10px'}
      px={4}
      height={'50px'}
      width={'350px'}
      cursor={'pointer'}
      boxShadow={'2px 3px 5px rgba(0,0,0,.25)'}
      ref={setNodeRef}
      style={style}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      position={'relative'}
    >
      {hover && (
        <DragHandleIcon
          {...listeners}
          {...attributes}
          cursor={'grab'}
          position={'absolute'}
          left={'2%'}
          color={'white'}
          _focus={{ outline: 'none' }}
        />
      )}
      <Flex
        height={'50px'}
        alignItems={'center'}
        width={'100%'}
        opacity={dimensionLoader ? 0.5 : 1}
        onClick={() => (dimensionLoader ? null : handleDimensionClick(dimData))}
        ml={3}
      >
        <DimensionTabIcon
          height={7}
          width={7}
          color={selectedDimension?.id === dimData?.id ? '#6562CF' : '#4BB6B9'}
        />
        {currentEditDimension === dimData?.id ? (
          <InputField
            width={'fit-content'}
            outline={'none !important'}
            boxShadow={'none !important'}
            border={'1px white solid !important'}
            padding={'15px 5px 15px 10px'}
            height={'28px'}
            fontSize={'15px !important'}
            fontFamily={'Roboto Bold'}
            defaultValue={dimData?.name}
            clickedOutside={() => {
              dispatch(handleEditDimensionItem(null));
            }}
            onKeyDown={(event: any) => {
              if (event?.keyCode === 13) updateDimensionName(event?.target?.value, dimData?.id);
            }}
            _placeholder={{ color: '#ffffff66' }}
            autoFocus
          />
        ) : (
          <Tooltip label={dimData?.name}>
            <Text fontSize={'sm'} width={'100%'} ml={3}>
              {dimData?.name}
            </Text>
          </Tooltip>
        )}
      </Flex>
      <Box>
        {dimData?.name !== 'Time' && dimData?.name !== 'time' && (
          <Menu closeOnSelect={true} variant='primary'>
            <MenuButton
              as={Button}
              border={'none'}
              width={'fit-content'}
              padding={0}
              minW={'fit-content'}
              mr={2}
              opacity={dimensionLoader ? 0.5 : 1}
              onClick={(event) => event.stopPropagation()}
            >
              <HorizontalMenu width={'30px'} height={'25px'} styles={{ filter: 'invert(1)' }} />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => dispatch(handleEditDimensionItem(dimData?.id))}>
                Rename
              </MenuItem>
              <MenuItem
                onClick={() =>
                  dispatch(
                    DuplicateDimension({
                      dimId: dimData?.id,
                      data: { name: '' },
                    }),
                  )
                }
              >
                Duplicate
              </MenuItem>
              <MenuItem onClick={() => handleDeleteDimension(dimData?.id)}>Delete</MenuItem>
            </MenuList>
          </Menu>
        )}
      </Box>
    </Flex>
  );
};

export default DimensionItemTab;
