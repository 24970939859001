import { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';

import Spinner from 'components/Spinner';
import Container from 'components/Container';
import {
  InvitationUserData,
  getInvitationDataStatus,
  getInvitationUserData,
} from 'redux/AuthSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

const InvitaionModal = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const confirmReference: any = useRef();
  const dispatch: AppDispatch = useDispatch();

  const [alert, setAlert] = useState(false);

  const invitationData = useSelector(getInvitationUserData);
  const invitationDataStatus = useSelector(getInvitationDataStatus);

  useEffect(() => {
    dispatch(InvitationUserData({ token }));
  }, [token]);

  useEffect(() => {
    if (invitationData?.already_registered === false) {
      navigate(`/signup`);
    }

    if (invitationData?.already_registered === true) {
      navigate(`/`);
    }
  }, [token, invitationData]);

  useEffect(() => {
    if (invitationDataStatus === 'FAILED') {
      setAlert(true);
    }
  }, [invitationDataStatus === 'FAILED']);

  return (
    <Container
      wrapperProperties={{
        px: 0,
        p: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
      }}
      bgColor={'linear-gradient(180deg, rgba(101, 98, 207, 0.6) 0%, rgba(75, 182, 185, 0.6) 100%);'}
    >
      {invitationDataStatus === 'FAILED' ? (
        <AlertDialog
          closeOnOverlayClick={false}
          leastDestructiveRef={confirmReference}
          isOpen={alert}
          onClose={() => setAlert(!alert)}
          colorScheme='black'
        >
          <AlertDialogOverlay>
            <AlertDialogContent
              width={{ start: '90%' }}
              display={'flex'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <AlertDialogHeader fontSize='lg' fontWeight='bold' color={'black'}>
                Link has been expired
              </AlertDialogHeader>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      ) : (
        <Spinner styleProperties={{ my: '4vh' }} />
      )}
    </Container>
  );
};

export default InvitaionModal;
