/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiService from 'services';

import {
  AddTeamMemberRequest,
  FetchUserOrganizationResponse,
  FetchUserTeamListResponse,
  GetUserTeamListResponseData,
  SignUpUser,
  UpdateUserOrgDetailRequest,
  UpdateUserTeamPermissionRequest,
  UserDataResponse,
  UserPaymentDataResponse,
  UserSubscriptionLimitsResponse,
} from '../models/UsersSlice.interface';

export const FetchUserDetails = createAsyncThunk(
  'fetchUserDetails/get',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response: UserDataResponse = await apiService.fetch_user();
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const UpdateUserDetails = createAsyncThunk(
  'updateUserDetails/patch',
  async ({ request_body }: SignUpUser, thunkAPI) => {
    try {
      const response: UserDataResponse = await apiService.update_user_details({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const FetchUserOrganizationDetails = createAsyncThunk(
  'fetchUserOrganizationDetails/get',
  async (_, thunkAPI) => {
    try {
      const response: FetchUserOrganizationResponse =
        await apiService.fetch_user_organization_details();
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const UpdateUserOrganizationDetails = createAsyncThunk(
  'updateUserOrganizationDetails/put',
  async ({ request_body }: UpdateUserOrgDetailRequest, thunkAPI) => {
    try {
      const response: FetchUserOrganizationResponse =
        await apiService.update_user_organization_details({
          request_body,
        });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const FetchUserTeamList = createAsyncThunk('fetchUserTeamList/get', async (_, thunkAPI) => {
  try {
    const response: FetchUserTeamListResponse = await apiService.get_user_team_list();
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const UpdateUserTeamPermissions = createAsyncThunk(
  'updateUserTeamPermissions/put',
  async ({ request_body }: UpdateUserTeamPermissionRequest, thunkAPI) => {
    try {
      const response = await apiService.update_user_team_permission({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const DeleteUser = createAsyncThunk(
  'deleteUser/delete',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.delete_user({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const AddTeamMember = createAsyncThunk(
  'addTeamMember/post',
  async ({ request_body }: AddTeamMemberRequest, thunkAPI) => {
    try {
      const response = await apiService.add_team_member({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const FetchUserPaymentDetails = createAsyncThunk(
  'fetchUserPaymentDetails/get',
  async (_, thunkAPI) => {
    try {
      const response: UserPaymentDataResponse = await apiService.fetch_user_payment_details();
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const FetchUserPlanDetails = createAsyncThunk(
  'fetchUserPlanDetails/get',
  async (_, thunkAPI) => {
    try {
      const response: any = await apiService.fetch_user_plan_detail();
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const SubscribeUser = createAsyncThunk(
  'subscribeUser/post',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.subscribe_user({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const CancelUserSubscriptions = createAsyncThunk(
  'cancelUserSubscriptions/post',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.cancel_user_subscription({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const FetchSubscriptionFeatureLimits = createAsyncThunk(
  'fetchSubscriptionFeatureLimits/get',
  async (_, thunkAPI) => {
    try {
      const response: UserSubscriptionLimitsResponse =
        await apiService.fetch_subscription_features_limits();
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const FetchUserInvitations = createAsyncThunk(
  'fetchUserInvitations/get',
  async (_, thunkAPI) => {
    try {
      const response: any = await apiService.fetch_user_invitations();
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const UpdateUserInvitations = createAsyncThunk(
  'updateUserInvitations/put',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response: any = await apiService.update_user_invitations({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export interface UserItems {
  id: number;
  name: string;
  description: string;
  subMenu: boolean;
  tabRoles: string[];
  userRoles: string[];
}

interface Properties {
  userData: any;
  userPlans: any;
  userOrganizationData: any;
  userTabs: number;
  userModelLimits: boolean;
  userInvitations: any;
  userPaymentData: any;
  isUserModalVisible: boolean;
  userSubscriptionLimits: any;
  userManagementItems: UserItems[];
  userTeamsData: GetUserTeamListResponseData[];
}

const initialState: Properties = {
  userTabs: 1,
  userPlans: {},
  userData: null,
  userOrganizationData: {},
  userModelLimits: false,
  userTeamsData: [],
  userInvitations: null,
  userPaymentData: {},
  isUserModalVisible: false,
  userSubscriptionLimits: {},
  userManagementItems: [
    {
      id: 1,
      name: 'Profile',
      description: 'Manage personal information',
      subMenu: false,
      tabRoles: ['view'],
      userRoles: ['view'],
    },
    {
      id: 2,
      name: 'Organisation',
      description: 'Manage organisation information',
      subMenu: false,
      tabRoles: ['view'],
      userRoles: ['edit'],
    },
    {
      id: 3,
      name: 'Team',
      description: 'Add or remove team members',
      subMenu: false,
      tabRoles: ['view'],
      userRoles: ['edit'],
    },
    {
      id: 4,
      name: 'Billing',
      description: 'Manage subscription and billing info',
      subMenu: false,
      tabRoles: ['edit'],
      userRoles: ['edit'],
    },
    {
      id: 5,
      name: 'Plan',
      description: 'Plan',
      subMenu: true,
      tabRoles: ['edit'],
      userRoles: ['edit'],
    },
  ],
};

const UserSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    SetTabs: (state, action) => {
      state.userTabs = action.payload;
    },
    SetUserModalVisible: (state, action) => {
      state.isUserModalVisible = action.payload;
    },
    SetModelLimit: (state, action) => {
      state.userModelLimits = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(FetchUserDetails.fulfilled, (state, action) => {
      state.userData = action.payload?.user;
    });
    builder.addCase(UpdateUserDetails.fulfilled, (state, action) => {
      state.userData = action.payload;
    });
    builder.addCase(FetchUserOrganizationDetails.fulfilled, (state, action) => {
      state.userOrganizationData = action.payload;
    });
    builder.addCase(UpdateUserOrganizationDetails.fulfilled, (state, action) => {
      state.userOrganizationData = action.payload;
    });
    builder.addCase(FetchUserTeamList.fulfilled, (state, action) => {
      state.userTeamsData = action.payload;
    });
    builder.addCase(UpdateUserTeamPermissions.fulfilled, (state, action) => {
      state.userTeamsData = action.payload;
    });
    builder.addCase(FetchUserPaymentDetails.fulfilled, (state, action) => {
      state.userPaymentData = action.payload;
    });
    builder.addCase(FetchUserPlanDetails.fulfilled, (state, action) => {
      state.userPlans = action.payload;
    });
    builder.addCase(FetchSubscriptionFeatureLimits.fulfilled, (state, action) => {
      state.userSubscriptionLimits = action.payload;
    });
    builder.addCase(DeleteUser.fulfilled, (state, action) => {
      state.userTeamsData = action.payload;
    });
    builder.addCase(FetchUserInvitations.fulfilled, (state, action) => {
      state.userInvitations = action.payload;
    });
  },
});

export const { SetTabs, SetUserModalVisible, SetModelLimit } = UserSlice.actions;
export const getUserPaymentData = (state: any) => state.userSlice?.userPaymentData;
export const getUserSubscriptionLimits = (state: any) => state.userSlice?.userSubscriptionLimits;
export const getUserOrganizationData = (state: any) => state.userSlice?.userOrganizationData;
export const showModelLimitPopup = (state: any) => state.userSlice.userModelLimits;
export const getUserInvitation = (state: any) => state.userSlice.userInvitations;
export const getUserTeams = (state: any) => state.userSlice.userTeamsData;
export const getUserData = (state: any) => state.userSlice?.userData;
export const getUserPlans = (state: any) => state.userSlice?.userPlans;

export default UserSlice.reducer;
