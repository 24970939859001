import { Icon, StyleProps } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
  wrapperProperties?: StyleProps;
  onClick?: () => void;
}

const Plus = ({
  width = 4,
  height = 4,
  color = 'white',
  wrapperProperties,
  onClick,
}: Properties) => {
  return (
    <Icon
      onClick={onClick}
      cursor={'pointer'}
      viewBox='0 0 10 10'
      color={color}
      width={width}
      height={height}
      {...wrapperProperties}
    >
      <path
        d='M10 5.71429H5.71429V10H4.28571V5.71429H0V4.28571H4.28571V0H5.71429V4.28571H10V5.71429Z'
        fill={color}
      />
    </Icon>
  );
};

export default Plus;
