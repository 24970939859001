import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiService from 'services';

interface Toast {
  id: any;
  message: string;
  status: 'success' | 'error';
}
const initialState = {
  toast: [] as Toast[],
  loading: false,
  authenticationModal: false,
  version: null,
};

export const fetchVersion = createAsyncThunk('version', async () => {
  const response = await apiService.version();
  return response.data;
});

const sharedSlice = createSlice({
  name: 'sharedSlice',
  initialState,
  reducers: {
    showToast: (state, action) => {
      state.toast.push({
        id: action.payload.id,
        message: action.payload.message,
        status: action.payload.status,
      });
    },
    hideToast: (state, action) => {
      state.toast = state.toast.filter((toast: any) => {
        return toast.id !== action.payload;
      });
    },
    showAuthenticationModal: (state) => {
      state.authenticationModal = true;
    },
    hideAuthenticationModal: (state) => {
      state.authenticationModal = false;
    },
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchVersion.fulfilled, (state, action) => {
      state.version = action.payload;
    });
  },
});

export const {
  showToast,
  hideToast,
  startLoading,
  stopLoading,
  showAuthenticationModal,
  hideAuthenticationModal,
} = sharedSlice.actions;

export const getAuthModalStatus = (state: any) => state.sharedSlice?.authenticationModal;
export const getVersion = (state: any) => state.sharedSlice?.version;

export default sharedSlice.reducer;
