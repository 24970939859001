import { Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { connect } from 'react-redux';

import ArrowLeftBlocks from 'components/Icons/ArrowLeftBlocks';
import Cross from 'components/Icons/Cross';
import { SetTabs, SetUserModalVisible } from 'redux/UserSlice';

interface Properties {
  heading: string;
  setUserTabs: (tab: number) => void;
  setUserModalVisible: (value: boolean) => void;
}

const UserHeading = ({ heading, setUserTabs, setUserModalVisible }: Properties) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  return (
    <>
      {isMobile ? (
        <Flex mb='5' alignItems={'center'} justifyContent={'space-between'}>
          <Flex alignItems={'center'}>
            {isMobile && (
              <ArrowLeftBlocks
                width={isMobile ? 4 : 6}
                height={isMobile ? 4 : 6}
                color='#6561a9'
                style={{ marginRight: '2', marginTop: '-1' }}
                onClick={() => (heading === 'Add Team member' ? setUserTabs(3) : setUserTabs(0))}
              />
            )}
            <Text
              fontFamily='Comfortaa bold'
              color='violet.700'
              fontSize={isMobile ? '22px' : '30px'}
              top='0'
            >
              {heading}
            </Text>
          </Flex>
          <Cross
            onClick={() => {
              setUserModalVisible(false);
              setUserTabs(0);
            }}
            styles={{ marginTop: '-1' }}
            width={isMobile ? 6 : 8}
            height={isMobile ? 6 : 8}
            bgColor='black.900'
          />
        </Flex>
      ) : (
        <Flex alignItems={'center'} mb='5' px='1'>
          <Text fontFamily='Comfortaa bold' color='violet.700' fontSize={'30px'} top='0'>
            {heading}
          </Text>
        </Flex>
      )}
    </>
  );
};

const mapDispatchToProperties = (dispatch: any) => {
  return {
    setUserTabs: (data: any) => dispatch(SetTabs(data)),
    setUserModalVisible: (data: boolean) => dispatch(SetUserModalVisible(data)),
  };
};

export default connect(null, mapDispatchToProperties)(UserHeading);
