import moment from 'moment';

// const convertToDate = (monthYearString: string) => {
//   try {
//     return Number.parseInt(monthYearString, 10);
//   } catch {
//     if (typeof monthYearString === 'string') {
//       const date = monthYearString.split('-');
//       return moment(`01-${date[0]}-${date[1]}`, 'DD-MMM-YY').toDate();
//     }
//   }

//   return monthYearString;
// };

const convertToDate = (monthYearString: string) => {
  if (typeof monthYearString === 'string') {
    const date = monthYearString.split('-');
    return moment(`01-${date[0]}-${date[1]}`, 'DD-MMM-YY').toDate();
  }
  return monthYearString;
};

export default convertToDate;
