import { Box, Flex, Input, Text } from '@chakra-ui/react';
import { KeyboardEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getShowNewGroupInput, setShowNewGroupInput } from 'redux/ItemPageSlice';
import { getSelectedDriver } from 'redux/PlanPageSlice';
import PlanPermission from 'container/PlanPermission';
import { AppDispatch } from 'utils/GlobalHelpers';

import Plus from '../../../../components/Icons/Plus';

interface NewGroupProperties {
  setAddGroupName: (argument: string | null) => void;
  addGroup: (event: any) => void;
  addGroupName: string | null;
  planPermission: boolean;
}

const NewGroup = ({
  setAddGroupName,
  addGroup,
  planPermission,
  addGroupName,
}: NewGroupProperties) => {
  const dispatch: AppDispatch = useDispatch();

  const showNewGroupInput = useSelector(getShowNewGroupInput);
  const currentDriver = useSelector(getSelectedDriver);

  const changeInputHandler = (event: any) => {
    setAddGroupName(event.target.value);
  };

  const OnKeyEnter = async (event: any) => {
    if (event && event.code === 'Enter' && event.target?.value.trim().length > 0) {
      addGroup(event);
    }
    return null;
  };

  const AddGroupNameOnBlur = async (event: any) => {
    if (addGroupName && event.target?.value.trim().length > 1) {
      addGroup(event);
    } else {
      dispatch(setShowNewGroupInput(false));
    }
  };
  return (
    <>
      {showNewGroupInput ? (
        <Flex justifyContent='center' alignItems='center' pb='15px' cursor='pointer'>
          <Input
            style={{
              border: '1px solid #4bb6b9',
              boxShadow: 'none',
            }}
            _placeholder={{ color: 'grey' }}
            placeholder='New Group'
            onChange={(event: any) => changeInputHandler(event)}
            onKeyDown={(event: KeyboardEvent) => OnKeyEnter(event)}
            onBlur={(event: any) => AddGroupNameOnBlur(event)}
            autoFocus
          />
        </Flex>
      ) : (
        <Flex
          justifyContent='center'
          alignItems='center'
          pb='15px'
          cursor={planPermission ? 'not-allowed' : 'pointer'}
          onClick={planPermission ? undefined : () => dispatch(setShowNewGroupInput(true))}
        >
          <Box mr='15px'>
            <Plus height={5} width={5} />
          </Box>

          <Text fontSize='1xl' pt='2px'>
            {currentDriver?.group_property}
          </Text>
        </Flex>
      )}
    </>
  );
};

export default PlanPermission(NewGroup);
