import { Radio, RadioGroup, Stack, Text, VStack, Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseIcon } from '@chakra-ui/icons';

import {
  clearStepperFormData,
  getFormValues,
  setActionCreateForm,
  setClearSelectedAreasTable,
  setImportindicatroType,
} from 'redux/DataImportSlice';

import { checkedRadioStyles, textStyles } from '../../Helper';

import SingleIndicators from './Indicators/SingleIndicators';
import MultipleIndicators from './Indicators/MultipleIndicators';
import SingleIndWoCol from './Indicators/SingleIndWoCol';

const DataStepsThree = () => {
  const dispatch: any = useDispatch();
  const getFormValue = useSelector(getFormValues);

  return (
    <VStack w={'100%'} pt={3}>
      <Flex justifyContent={'space-between'} w={'full'} align={'center'}>
        <Text
          color={'#6562CF'}
          fontSize='lg'
          fontFamily={'regular_roboto'}
          fontWeight={'400'}
          textAlign={'start'}
        >
          Select indicators
        </Text>
        <CloseIcon
          color={'black'}
          width={3}
          height={3}
          cursor={'pointer'}
          onClick={async () => {
            await dispatch(setActionCreateForm(false));
            await dispatch(clearStepperFormData());
            await dispatch(setClearSelectedAreasTable());
          }}
        />
      </Flex>
      <Text
        fontSize='0.9rem'
        color='black'
        fontFamily={'bold_roboto'}
        textAlign={'start'}
        width={'100%'}
      >
        Are you importing multiple indicators?
      </Text>
      <RadioGroup
        width={'100%'}
        pt={3}
        onChange={(value) => {
          dispatch(setImportindicatroType(value));
        }}
        value={getFormValue.importindicatroType}
      >
        <Stack spacing={5} direction='row'>
          <>
            <Radio _checked={checkedRadioStyles} value='1'>
              <Text color='black' {...textStyles}>
                Single
              </Text>
            </Radio>
            <Radio _checked={checkedRadioStyles} value='2'>
              <Text color='black' {...textStyles}>
                Multiple
              </Text>
            </Radio>
          </>
        </Stack>
      </RadioGroup>
      {getFormValue?.importindicatroType === '1' && getFormValue?.dataPeriod === '1' && (
        <SingleIndicators getFormValue={getFormValue} />
      )}
      {getFormValue?.importindicatroType === '1' && getFormValue?.selectedTimePeriod === 'row' && (
        <SingleIndicators getFormValue={getFormValue} />
      )}

      {getFormValue?.importindicatroType === '1' &&
        getFormValue?.selectedTimePeriod === 'column' && (
          <SingleIndWoCol getFormValue={getFormValue} />
        )}
      {getFormValue?.importindicatroType === '2' && (
        <MultipleIndicators getFormValue={getFormValue} />
      )}
    </VStack>
  );
};

export default DataStepsThree;
