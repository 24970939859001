/* eslint-disable class-methods-use-this */

import * as React from 'react';
import {
  Cell,
  keyCodes,
  Uncertain,
  Compatible,
  CellTemplate,
  getCellProperty,
  UncertainCompatible,
} from '@silevis/reactgrid';

import Checkbox from 'components/Checkbox';

export interface CheckboxCell extends Cell {
  type: 'checkbox';
  extraKey?: string;
  checked: boolean;
  checkedText?: string;
  uncheckedText?: string;
}

export class CheckboxCellTemplate implements CellTemplate<CheckboxCell> {
  getCompatibleCell(uncertainCell: Uncertain<CheckboxCell>): Compatible<CheckboxCell> {
    const checked = getCellProperty(uncertainCell, 'checked', 'boolean');
    const text = checked ? uncertainCell.checkedText ?? '1' : uncertainCell.uncheckedText ?? '';
    return { ...uncertainCell, checked: !!checked, value: checked ? 1 : Number.NaN, text };
  }

  handleKeyDown(
    cell: Compatible<CheckboxCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
  ): { cell: Compatible<CheckboxCell>; enableEditMode: boolean } {
    if (!shift && (keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER))
      return { cell: this.getCompatibleCell(this.toggleCheckboxCell(cell)), enableEditMode: false };
    return { cell, enableEditMode: false };
  }

  private toggleCheckboxCell(cell: Compatible<CheckboxCell>): Compatible<CheckboxCell> {
    return this.getCompatibleCell({ ...cell, checked: !cell.checked });
  }

  update(
    cell: Compatible<CheckboxCell>,
    cellToMerge: UncertainCompatible<CheckboxCell>,
  ): Compatible<CheckboxCell> {
    const checked = cellToMerge.type === 'checkbox' ? cellToMerge.checked : !!cellToMerge.value;
    return this.getCompatibleCell({ ...cell, checked });
  }

  getClassName(cell: Compatible<CheckboxCell>): string {
    return cell.className ?? '';
  }

  render(
    cell: Compatible<CheckboxCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<CheckboxCell>, commit: boolean) => void,
  ): React.ReactNode {
    return (
      <Checkbox
        variant='secondary'
        isChecked={cell.checked}
        label={<></>}
        onChange={() => onCellChanged(this.toggleCheckboxCell(cell), true)}
      />
    );
  }
}
