import {
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack,
  Divider,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import {
  handleActionStepNext,
  resetSingleIndicatorValue,
  setMultipleIndicatprType,
} from 'redux/DataImportSlice';
import { checkedRadioStyles, textStyles } from 'pages/ModelOverviewPage/Import/Helper';
import IndicatorRow from 'components/Icons/IndicatorMultipleTableImage/IndicatorRow';
import IndicatorColumn from 'components/Icons/IndicatorMultipleTableImage/IndicatorColumn';

import ActionStepFooter from '../../ActionStepFooter';

const validationSchema = yup.object().shape({
  multipleIndicatrosType: yup
    .string()
    .oneOf(['row', 'column'], 'Please select atleast one')
    .required('select time period'),
});

interface Properties {
  getFormValue: any;
}

const MultipleIndicators = ({ getFormValue }: Properties) => {
  const dispatch: any = useDispatch();

  const handleSubmit = (form: any, setSubmitting: (data: boolean) => void) => {
    dispatch(setMultipleIndicatprType(form)).then(() => {
      dispatch(resetSingleIndicatorValue());
      setSubmitting(false);
      dispatch(handleActionStepNext());
    });
  };
  return (
    <Formik
      initialValues={{
        multipleIndicatrosType: '' || `${getFormValue.multipleIndicatrosType}`,
      }}
      validationSchema={validationSchema}
      onSubmit={(form, { setSubmitting }) => handleSubmit(form, setSubmitting)}
      enableReinitialize={true}
    >
      {({ values, isSubmitting, setFieldValue }: any) => {
        return (
          <Form style={{ width: '100%' }}>
            <VStack display='flex' justify={'center'} w={'100%'}>
              <VStack display='flex' justify={'center'} w={'100%'}>
                <VStack w={'100%'} justify={'center'}>
                  <FormControl pt={2}>
                    <FormLabel fontSize='0.9rem' color='black' fontFamily={'bold_roboto'}>
                      Are your indicators in rows or columns?{' '}
                    </FormLabel>
                    <RadioGroup
                      width={'100%'}
                      pt={3}
                      onChange={(value) => {
                        setFieldValue('multipleIndicatrosType', value);
                      }}
                      value={values.multipleIndicatrosType}
                    >
                      <Stack spacing={5} direction='column'>
                        <Field name='multipleIndicatrosType'>
                          {() => (
                            <>
                              <IndicatorRow />
                              <Radio _checked={checkedRadioStyles} value='row'>
                                <Text color='black' {...textStyles}>
                                  Select Rows
                                </Text>
                              </Radio>
                              <Divider my='5' border={2} />
                              <IndicatorColumn />
                              <Radio _checked={checkedRadioStyles} value='column'>
                                <Text color='black' {...textStyles}>
                                  Select Column
                                </Text>
                              </Radio>
                            </>
                          )}
                        </Field>
                      </Stack>
                    </RadioGroup>
                    <ErrorMessage name='multipleIndicatrosType' component='div' className='error' />
                  </FormControl>
                </VStack>
              </VStack>
              <Flex alignItems={'center'} justifyContent={'flex-end'} pb={8} pt='5' w={'100%'}>
                <ActionStepFooter
                  nextButtonName='Continue'
                  prevButtonName='Previous'
                  isSubmitting={isSubmitting}
                />
              </Flex>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MultipleIndicators;
