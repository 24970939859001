import { VStack } from '@chakra-ui/react';
import { useState } from 'react';

import FinalDataSteps from './selectDimensionTwosteps/FinalDataSteps';
import DimensionSelectionSteps from './selectDimensionTwosteps/DimensionSelectionSteps';

interface Properties {
  getFormValue: any;
}
const SelectDimesnionColumn = ({ getFormValue }: Properties) => {
  const [isFinalStep, setIsFinalStep] = useState(false);
  const [selectedActionId, setSelectedActionId] = useState<number | null>(null);
  const [scenarioId, setScenarioId] = useState<number | null>(null);
  const [selectedMapType, setSelectedMapType] = useState('');
  return (
    <VStack display='flex' justify={'center'} w={'100%'} pt={3}>
      {isFinalStep ? (
        <FinalDataSteps
          setIsFinalStep={setIsFinalStep}
          selectedActionId={selectedActionId}
          selectedMapType={selectedMapType}
          scenarioId={scenarioId}
        />
      ) : (
        <DimensionSelectionSteps
          getFormValue={getFormValue}
          setIsFinalStep={setIsFinalStep}
          setSelectedActionId={setSelectedActionId}
          setSelectedMapType={setSelectedMapType}
          setScenarioId={setScenarioId}
        />
      )}
    </VStack>
  );
};
export default SelectDimesnionColumn;
