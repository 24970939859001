import { Box, Text, Tooltip } from '@chakra-ui/react';
import { Handle, Position } from 'react-flow-renderer';
import { useParams } from 'react-router-dom';

import styles from './styles';

interface MapIndicatorNodeProperties {
  data: {
    direction: string;
    label: string;
    block_name: string;
    obj_name: string;
    index: number;
    obj_id: number;
    formula: string;
  };
}

const MapIndicatorNode = ({ data }: MapIndicatorNodeProperties) => {
  let inputHandlePos: Position;
  let outputHandlePos: Position;
  const { blockId } = useParams();

  const isSameBlock = blockId === `${data.obj_id}`;

  if (data.direction === 'TB') {
    inputHandlePos = Position.Top;
    outputHandlePos = Position.Bottom;
  } else {
    inputHandlePos = Position.Left;
    outputHandlePos = Position.Right;
  }

  const formula = data?.formula?.replace(/["']/g, '') || '';

  return (
    <Box style={styles.node}>
      <Handle type='target' position={inputHandlePos} />
      <Tooltip
        placement='top'
        hasArrow
        // variant={'primary'}
        // bg='white'
        // color={'black'}
        arrowSize={15}
        fontSize={'md'}
        aria-label={formula || ''}
        label={formula || ''}
      >
        <Text
          sx={styles.label}
          title={data.label}
          aria-label={data.label}
          fontFamily={'regular_roboto'}
        >
          {data.label}
        </Text>
      </Tooltip>

      <Text
        sx={{ ...styles.chip, color: 'white' }}
        title={data.block_name}
        aria-label={data.obj_name}
        backgroundColor={isSameBlock ? '#7163d0' : '#4BB6B9'}
        fontFamily={'regular_roboto'}
      >
        {data.obj_name}
      </Text>

      <Handle type='source' position={outputHandlePos} />
    </Box>
  );
};
export default MapIndicatorNode;
