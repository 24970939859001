import { Tooltip } from '@chakra-ui/react';

interface DuplicateIconProperties {
  width?: number;
  height?: number;
  color?: string;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const DuplicateIconComponent: React.FC<DuplicateIconProperties> = ({
  width = 22,
  height = 22,
  color = 'white',
  onClick,
  style,
}: DuplicateIconProperties) => {
  return (
    <Tooltip placement='top' label={'Duplicate indicator'}>
      <svg
        onClick={onClick}
        style={style}
        cursor={'pointer'}
        width={width}
        height={height}
        viewBox='0 0 16 19'
        fill={color}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.15402 14.9234C5.66134 14.9234 5.23957 14.748 4.88872 14.3971C4.53787 14.0463 4.36245 13.6245 4.36245 13.1318V2.3824C4.36245 1.88971 4.53787 1.46795 4.88872 1.1171C5.23957 0.766245 5.66134 0.59082 6.15402 0.59082H14.2161C14.7088 0.59082 15.1306 0.766245 15.4814 1.1171C15.8323 1.46795 16.0077 1.88971 16.0077 2.3824V13.1318C16.0077 13.6245 15.8323 14.0463 15.4814 14.3971C15.1306 14.748 14.7088 14.9234 14.2161 14.9234H6.15402ZM6.15402 13.1318H14.2161V2.3824H6.15402V13.1318ZM2.57087 18.5066C2.07819 18.5066 1.65642 18.3311 1.30557 17.9803C0.954722 17.6294 0.779297 17.2077 0.779297 16.715V4.17397H2.57087V16.715H12.4245V18.5066H2.57087Z'
          fill={color}
        />
      </svg>
    </Tooltip>
  );
};

export default DuplicateIconComponent;
