import { UserSubscriptionLimit } from 'models/UsersSlice.interface';

const getTeamCount = (data: UserSubscriptionLimit) => {
  const currentUsers = data?.subscription_current_usage?.No_of_users;
  const userLimit = data?.subscription_features_limit?.No_of_users;
  if (!userLimit) {
    return currentUsers;
  }
  return `${currentUsers} / ${userLimit}`;
};

const hasPermission = (data: UserSubscriptionLimit) => {
  if (!data.subscription_features_limit?.No_of_users) {
    return true;
  }
  if (
    data.subscription_current_usage?.No_of_users >= data.subscription_features_limit?.No_of_users
  ) {
    return false;
  }
  return true;
};

export { getTeamCount, hasPermission };
