import { Text } from '@chakra-ui/react';
import { ReactNode } from 'react';

interface Properties {
  children: ReactNode;
  maxWidth?: string;
  ellipsis?: boolean;
  [key: string]: any;
}

const TextComponent = ({ children, maxWidth = '200px', ellipsis, ...rest }: Properties) => {
  return (
    <Text
      {...rest}
      style={
        ellipsis
          ? {
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth,
            }
          : {}
      }
      textOverflow={'ellipsis'}
      whiteSpace={'nowrap'}
      overflow={'hidden'}
    >
      {children}
    </Text>
  );
};
export default TextComponent;
