import { Image, StyleProps } from '@chakra-ui/react';

import AccountCircleFilled from 'assets/demo_user_icon.png';

interface Properties {
  width?: string;
  height?: string;
  color?: string;
  styles?: StyleProps;
}

const LightFilledUserAvatar = ({ width = '70', height = '70', styles }: Properties) => {
  return <Image src={AccountCircleFilled} w={width} h={height} {...styles} />;
};

export default LightFilledUserAvatar;
