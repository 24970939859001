import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
  selected?: boolean;
}

const GridView = ({ width = 10, height = 10, color = 'green.900', selected }: Properties) => {
  return (
    <Icon
      viewBox='0 0 30 35'
      width={width}
      height={height}
      color={color}
      style={{
        padding: '.3rem',
        backgroundColor: selected ? '#4bb6b9' : '',
        borderRadius: selected ? '8px' : '',
      }}
    >
      <svg
        width='30'
        height='35'
        viewBox='0 0 46 46'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g clipPath='url(#clip0_1077_48)'>
          <rect width='46' height='46' fill={selected ? '#4bb6b9' : '#796CD6'} />
          <mask
            id='mask0_1077_48'
            style={{ maskType: 'alpha' }}
            maskUnits='userSpaceOnUse'
            x='-5'
            y='-6'
            width='56'
            height='56'
          >
            <rect x='-4.5885' y='-5.4552' width='55.3005' height='55.3005' fill='#D9D9D9' />
          </mask>
          <g mask='url(#mask0_1077_48)'>
            <path
              d='M4.62815 40.6286C3.36085 40.6286 2.27596 40.1774 1.37349 39.2749C0.471012 38.3724 0.0197754 37.2875 0.0197754 36.0202V8.36994C0.0197754 7.10264 0.471012 6.01775 1.37349 5.11528C2.27596 4.2128 3.36085 3.76157 4.62815 3.76157H41.4952C42.7625 3.76157 43.8474 4.2128 44.7498 5.11528C45.6523 6.01775 46.1036 7.10264 46.1036 8.36994V36.0202C46.1036 37.2875 45.6523 38.3724 44.7498 39.2749C43.8474 40.1774 42.7625 40.6286 41.4952 40.6286H4.62815ZM4.62815 19.8909H13.8449V8.36994H4.62815V19.8909ZM18.4533 19.8909H27.67V8.36994H18.4533V19.8909ZM32.2784 19.8909H41.4952V8.36994H32.2784V19.8909ZM13.8449 36.0202V24.4993H4.62815V36.0202H13.8449ZM18.4533 36.0202H27.67V24.4993H18.4533V36.0202ZM32.2784 36.0202H41.4952V24.4993H32.2784V36.0202Z'
              fill='white'
            />
          </g>
        </g>
        <defs>
          <clipPath id='clip0_1077_48'>
            <rect width='46' height='46' fill='white' />
          </clipPath>
        </defs>
      </svg>
    </Icon>
  );
};

export default GridView;
