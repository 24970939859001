import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const Save = ({ width = 5, height = 5, color = 'green.900' }: Properties) => {
  return (
    <Icon viewBox='0 0 13 13' width={width} height={height} color={color}>
      <path
        d='M10.1111 0H1.44444C0.642778 0 0 0.65 0 1.44444V11.5556C0 12.35 0.642778 13 1.44444 13H11.5556C12.35 13 13 12.35 13 11.5556V2.88889L10.1111 0ZM6.5 11.5556C5.30111 11.5556 4.33333 10.5878 4.33333 9.38889C4.33333 8.19 5.30111 7.22222 6.5 7.22222C7.69889 7.22222 8.66667 8.19 8.66667 9.38889C8.66667 10.5878 7.69889 11.5556 6.5 11.5556ZM8.66667 4.33333H1.44444V1.44444H8.66667V4.33333Z'
        fill='currentColor'
      />
    </Icon>
  );
};

export default Save;
