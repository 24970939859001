import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import EditPencil from 'components/Icons/EditPencilGreen';
import { HEADER_HEIGHT_WITH_NAVIGATION, WELCOME_HEIGHT } from 'projectConstants';
import { resetDimension } from 'redux/DimensionPageSlice';
import { resetIndicator } from 'redux/IndicatorPageSlice';
import { resetItem, resetTableOrgChart } from 'redux/ItemPageSlice';
import { getBlockDriversData, getSelectedDriver, setDriver } from 'redux/PlanPageSlice';

import BaseScenario from './Plan/Filters/BaseScenario';

interface Properties {
  refetchRequest: () => void;
}

const DriversSection = ({ refetchRequest }: Properties) => {
  const blockDrivers = useSelector(getBlockDriversData);
  const selectedDriver = useSelector(getSelectedDriver);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const dispatch = useDispatch();

  const isSelectedDriver = (driver: any) => {
    let flag: boolean = false;
    if (!driver) return flag;
    if (
      driver?.type === 'indicator' &&
      selectedDriver?.type === 'indicator' &&
      driver?.id === selectedDriver?.id
    ) {
      flag = true;
    } else if (
      driver?.type === 'property' &&
      selectedDriver?.type === 'property' &&
      driver?.property_id === selectedDriver?.property_id
    ) {
      flag = true;
    } else if (
      driver?.type === 'dimension' &&
      selectedDriver?.type === 'dimension' &&
      driver?.dim_id === selectedDriver?.dim_id
    ) {
      flag = true;
    }
    return flag;
  };

  const selectDriver = async (driver: any) => {
    if (isSelectedDriver(driver)) {
      dispatch(setDriver(null));
    } else {
      if (selectedDriver?.type === 'indicator') {
        dispatch(setDriver(null));
        dispatch(resetIndicator());
      }
      if (selectedDriver?.type === 'property') {
        dispatch(setDriver(null));
        dispatch(resetDimension());
      }
      if (selectedDriver?.type === 'dimension') {
        dispatch(setDriver(null));
        dispatch(resetTableOrgChart());
        dispatch(resetItem());
      }
      dispatch(setDriver(driver));
    }
  };

  return (
    <Flex
      display='flex'
      direction='column'
      h={
        isMobile
          ? `calc(100vh - ${HEADER_HEIGHT_WITH_NAVIGATION} - ${WELCOME_HEIGHT})`
          : `calc(100vh - ${HEADER_HEIGHT_WITH_NAVIGATION})`
      }
      pb={5}
      pr={2}
      overflowY='auto'
      className='scroll'
    >
      {isMobile ? (
        <>
          <BaseScenario refetchRequest={refetchRequest} />
          <Text mt='2' fontFamily='regular_roboto' fontSize='2xs' lineHeight='9'>
            Enter and adjust your inputs here
          </Text>
        </>
      ) : (
        <>
          <Flex alignItems={'center'} justifyContent='space-between' mt={'2'}>
            <Text fontFamily='bold' fontSize='lg' lineHeight='9' ml={2}>
              <EditPencil /> Inputs
            </Text>
          </Flex>
          <Text mt='2' fontFamily='regular_roboto' fontSize='2xs' lineHeight='9'>
            Enter and adjust your inputs here
          </Text>
        </>
      )}

      <Box mt={4}>
        {blockDrivers.map((driver: any, index: number) => {
          return (
            <Flex
              key={index}
              onClick={() => selectDriver(driver)}
              textAlign='left'
              alignSelf='left'
              w='100%'
              minH='50px'
              bgColor={isSelectedDriver(driver) ? '#4BB6B9' : '#7163D0'}
              mb='10px'
              p='0 14px'
              borderRadius='14px'
              filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))'
              transition='all 0.3s ease-out'
              justifyContent='space-between'
              alignItems={'center'}
              cursor='pointer'
            >
              <Flex width={'80%'} fontSize={'xs'} whiteSpace='nowrap' overflow='hidden'>
                <Text color='white'>Enter your </Text>
                <Text
                  color={isSelectedDriver(driver) ? 'white' : '#4BB6B9'}
                  pl={1}
                  textOverflow='ellipsis'
                  whiteSpace='nowrap'
                  overflow='hidden'
                >
                  {`${
                    driver?.type === 'indicator'
                      ? driver?.name
                      : driver?.type === 'dimension'
                      ? driver?.dim_name
                      : driver?.property_name
                  }`}
                </Text>
              </Flex>
              <ChevronRightIcon boxSize={{ base: 6 }} color={'white'} />
            </Flex>
          );
        })}
      </Box>
    </Flex>
  );
};

export default DriversSection;
