import { Button, Flex, Image, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useLayoutEffect } from 'react';

import error404 from 'assets/404_warning_icon.png';
import Container from 'components/Container';

const NotFoundPage = () => {
  const navigate = useNavigate();

  useLayoutEffect(() => {
    document.title = 'Blox';
  }, []);

  const onBack = () => {
    navigate(-1);
  };

  return (
    <Container>
      <Flex h='100%' direction='column' align='center' justify='center'>
        <Image src={error404} alt='error icon' boxSize={40} />

        <Text fontSize={20} color='white.900' textAlign='center'>
          Page not found
        </Text>

        <Button variant='outlined' size='lg' mt={10} onClick={onBack}>
          Back
        </Button>
      </Flex>
    </Container>
  );
};

export default NotFoundPage;
