import { Field } from 'formik';
import { ChangeEvent } from 'react';
import { FormControl, FormErrorMessage, FormLabel, Textarea } from '@chakra-ui/react';

interface Properties {
  label: string;
  name: string;
  placeholder?: string;
  error?: string;
  touched?: boolean;
  onFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
  children?: any;
  inputStyles?: any;
  styles?: any;
  disabled?: any;
}

const TextArea = ({
  label,
  name,
  placeholder,
  error,
  touched,
  children,
  styles,
  disabled,
}: Properties) => {
  return (
    <FormControl
      isInvalid={!!error && touched}
      marginInlineStart={children && '0 !important'}
      style={styles}
    >
      <FormLabel htmlFor={name} fontFamily='regular_roboto' fontSize={'15px'} color={'#212121'}>
        {label}
      </FormLabel>
      <>
        <Field
          resize={'none'}
          boxShadow={'#EEF1F6 0px 0px 0px 1.5px'}
          maxW={'300px'}
          padding={'8px 15px 8px 8px'}
          as={Textarea}
          id={name}
          name={name}
          placeholder={placeholder}
          variant='filled'
          autoComplete='off'
          minH={'70px'}
          borderRadius='8px'
          fontFamily='regular_roboto'
          fontSize='2xs'
          color='black.900'
          bgColor='white.900'
          _focusVisible={{
            boxShadow: '',
            bgColor: 'white.900',
          }}
          _placeholder={{ color: 'grey.600' }}
          disabled={disabled}
        />
        <FormErrorMessage fontFamily='regular_roboto' fontSize='3xs' color='red.500'>
          {error}
        </FormErrorMessage>
      </>
    </FormControl>
  );
};

export default TextArea;
