import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';

// import { useAuthContext } from 'context/authContext';

import Logo from 'components/Icons/Logo';
import { getUserData } from 'redux/UserSlice';

import AuthForm from './AuthForm';

interface Properties {
  isOpen: boolean;
  onClose: () => void;
  setIsLogoTriggered?: (value: boolean) => void;
}

const AuthModal = ({ isOpen, onClose, setIsLogoTriggered }: Properties) => {
  const [isMobile] = useMediaQuery('(max-width: 575px)');
  // const { user } = useAuthContext();
  const user = useSelector(getUserData);

  const onCloseAuthModal = () => {
    onClose();
    setIsLogoTriggered?.(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseAuthModal} size='xl' blockScrollOnMount={false}>
      <ModalOverlay bg='none' backdropFilter='auto' backdropInvert='10%' backdropBlur='5px' />
      <ModalContent w='90vw' pb='6' my={{ base: '7.5', sm: '15' }} bg={'white !important'}>
        <ModalHeader position='relative' px='3' pb={{ base: '3', sm: '4' }}>
          {isMobile && <ModalCloseButton color='red.500' />}
          <Flex
            align='center'
            justify='space-between'
            mb={{ base: '3', sm: '5' }}
            pr={{ base: '10', sm: '0' }}
          >
            <Logo />
          </Flex>
          {user?.is_anonymous ? (
            <Text fontFamily='bold' fontSize={{ base: 'xs', sm: 'md' }} variant='secondary'>
              Get started with your free account...
            </Text>
          ) : (
            <Text fontFamily='bold' fontSize='md' variant='secondary'>
              Your account details
            </Text>
          )}
        </ModalHeader>

        <ModalBody px='3' pt={{ base: '0', sm: '2' }}>
          <AuthForm onClose={onClose} user={user} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AuthModal;
