import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useMediaQuery,
} from '@chakra-ui/react';
import { Field } from 'formik';
import { ChangeEvent } from 'react';

interface Properties {
  label: string;
  name: string;
  placeholder?: string;
  type?: 'text' | 'email' | 'password';
  error?: string;
  touched?: boolean;
  onFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
  children?: any;
  labelStyles?: any;
  inputStyles?: any;
  styles?: any;
  disabled?: any;
}

const FormField = ({
  label,
  name,
  placeholder,
  type = 'text',
  error,
  touched,
  onFocus,
  children,
  labelStyles,
  inputStyles,
  styles,
  disabled,
}: Properties) => {
  const [isMobile] = useMediaQuery('(max-width: 576px)');
  return (
    <FormControl
      isInvalid={!!error && touched}
      marginInlineStart={children && '0 !important'}
      style={styles}
    >
      <FormLabel
        style={labelStyles}
        htmlFor={name}
        fontFamily='regular_roboto'
        fontSize={'2xs'}
        color={'black.900'}
      >
        {label}
      </FormLabel>
      {children && !isMobile ? (
        <Box display={'flex'} alignItems={'baseline'} justifyContent={'space-between'}>
          <Box width={'100%'}>
            <Field
              as={Input}
              id={name}
              name={name}
              type={type}
              placeholder={placeholder}
              variant='filled'
              autoComplete='off'
              h='8'
              onFocus={onFocus}
              borderRadius='xs'
              boxShadow={error && touched ? 'none' : '0px 0px 0px 1px black'}
              fontFamily='regular_roboto'
              fontSize='2xs'
              color='black.900'
              bgColor='white.900'
              _focusVisible={{
                boxShadow: '0px 0px 0px 2px rgba(49, 130,206)',
                bgColor: 'white.900',
              }}
              style={inputStyles}
              _placeholder={{ color: 'grey.600' }}
              disabled={disabled}
            />
            <FormErrorMessage fontFamily='regular_roboto' fontSize='3xs' color='red.500'>
              {error}
            </FormErrorMessage>
          </Box>
          <Flex flexDir={'column'}>
            <Box marginLeft={10}>{children}</Box>
          </Flex>
        </Box>
      ) : (
        <>
          <Field
            style={inputStyles}
            as={Input}
            id={name}
            name={name}
            type={type}
            placeholder={placeholder}
            variant='filled'
            autoComplete='off'
            h='8'
            onFocus={onFocus}
            borderRadius='xs'
            boxShadow={error && touched ? 'none' : '0px 0px 0px 1px black'}
            fontFamily='regular_roboto'
            fontSize='2xs'
            color='black.900'
            bgColor='white.900'
            _focusVisible={{
              boxShadow: '0px 0px 0px 2px rgba(49, 130,206)',
              bgColor: 'white.900',
            }}
            _placeholder={{ color: 'grey.600' }}
            disabled={disabled}
          />
          <FormErrorMessage fontFamily='regular_roboto' fontSize='3xs' color='red.500'>
            {error}
          </FormErrorMessage>
        </>
      )}
    </FormControl>
  );
};

export default FormField;
