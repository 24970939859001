import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { IconButton } from '@chakra-ui/react';
import Slider from 'react-slick';

interface Properties {
  children: React.ReactNode;
  slidesToShow?: number;
  slidesToScroll?: number;
  rows?: number;
  slidesPerRow?: number;
  className?: string;
}

interface NavButtonProperties {
  direction: string;
  onClick?: () => void;
}

const NavigationButton = ({ onClick, direction }: NavButtonProperties) => {
  return (
    <IconButton
      aria-label={direction === 'next' ? 'next button' : 'previuos button'}
      onClick={onClick}
      variant='slider'
      size='slider'
      sx={{ opacity: onClick === null ? '0' : '1', transition: 'opacity .25s ease' }}
    >
      {direction === 'next' ? (
        <ChevronRightIcon boxSize={{ base: 7 }} />
      ) : (
        <ChevronLeftIcon boxSize={{ base: 7 }} />
      )}
    </IconButton>
  );
};

const Carousel = ({
  children,
  slidesToShow = 3,
  slidesToScroll = 3,
  rows = 1,
  slidesPerRow = 1,
  className = 'carousel',
}: Properties) => {
  const sliderSettings = {
    infinite: false,
    slidesToShow,
    slidesToScroll,
    rows,
    slidesPerRow,
    nextArrow: <NavigationButton direction='next' />,
    prevArrow: <NavigationButton direction='prev' />,
  };

  return (
    <Slider {...sliderSettings} className={className}>
      {children}
    </Slider>
  );
};

export default Carousel;
