import { Flex, Text } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import ChartIconComponent from 'components/Icons/ChartIcon';
import KPIIconComponent from 'components/Icons/KpiIcon';
import TableIcomComponent from 'components/Icons/TableIcon';
import TextBoxIconComponent from 'components/Icons/TextBoxIcon';
import { getDashboardWidgetType } from 'redux/DashboardSlice';

const DashboardModalHeading = () => {
  const dashboardWidgetType = useSelector(getDashboardWidgetType);

  return (
    <Flex alignItems={'center'} height={'fit-content'} mb={5} p={'0 1rem'}>
      {dashboardWidgetType === 1 ? (
        <>
          <Flex alignItems={'center'}>
            <KPIIconComponent
              width={'36px'}
              height={'36px'}
              color={'#787878'}
              marginRight='.5rem'
            />
            <Text mt={'2px'} size={'Comfortaa-bold-20'}>
              KPI
            </Text>
          </Flex>
        </>
      ) : dashboardWidgetType === 2 ? (
        <>
          <ChartIconComponent
            width={'36px'}
            height={'36px'}
            color={'#787878'}
            marginRight='.5rem'
          />
          <Text size={'Comfortaa-bold-20'}>Chart</Text>
        </>
      ) : dashboardWidgetType === 3 ? (
        <>
          <TableIcomComponent
            width={'36px'}
            height={'36px'}
            color={'#787878'}
            marginRight='.5rem'
          />
          <Text size={'Comfortaa-bold-20'}>Table</Text>
        </>
      ) : dashboardWidgetType === 4 ? (
        <>
          <TextBoxIconComponent
            width={'36px'}
            height={'36px'}
            color={'#787878'}
            marginRight='.5rem'
          />
          <Text size={'Comfortaa-bold-20'}>Text box</Text>
        </>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default DashboardModalHeading;
