import { HStack, Button, useMediaQuery, Box } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import FormField from 'components/FormField';
import { SetAuthPasswordPageType, SetAuthPassword, SetAuthPageType } from 'redux/AuthSlice';
import { SetPasswordSchema } from 'validation/schemas/AuthenticationSchema';

const initialValues = {
  password: '',
};

const SetPasswordForm = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const [isMobile] = useMediaQuery('(max-width: 576px)');

  const onSubmitForm = (form: any) => {
    dispatch(SetAuthPassword(form.password));
    dispatch(SetAuthPasswordPageType(2));
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      validationSchema={SetPasswordSchema}
    >
      {({ errors, touched }) => (
        <Form>
          <HStack spacing='7' alignItems={'center'} flexWrap={'wrap'}>
            <FormField
              label='Set password:'
              name='password'
              type='password'
              labelStyles={{
                fontSize: '14px',
                color: '#121826',
              }}
              inputStyles={{
                maxWidth: '300px',
                boxShadow: '0px 0px 0px 1px #7A849E',
              }}
              placeholder='Password'
              error={errors.password}
              touched={touched.password}
            >
              <Box display={'flex'} flexDirection={'column'}>
                <Button
                  type='submit'
                  w='150px'
                  height={'34px'}
                  borderRadius='xs'
                  fontSize='xs'
                  bgColor='green.900'
                >
                  Continue
                </Button>
                <small
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '.7rem',
                    cursor: 'pointer',
                    color: '#939393',
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    navigate('/');
                    dispatch(SetAuthPageType(1));
                  }}
                >
                  Back to login
                </small>
              </Box>
            </FormField>
            {isMobile && (
              <>
                <Button
                  margin={'1rem 0 0 0 !important'}
                  type='submit'
                  w='100%'
                  h='9'
                  borderRadius='xs'
                  fontSize='xs'
                  bgColor='green.900'
                >
                  Continue
                </Button>
                <small
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    margin: '.7rem 0 0 0',
                    cursor: 'pointer',
                    color: '#939393',
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    navigate('/');
                    dispatch(SetAuthPageType(1));
                  }}
                >
                  Back to login
                </small>
              </>
            )}
          </HStack>
        </Form>
      )}
    </Formik>
  );
};

export default SetPasswordForm;
