import {
  Box,
  Button,
  Flex,
  Heading,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useMediaQuery,
  VStack,
  useOutsideClick,
  Spinner,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import TagManager from 'react-gtm-module';
import { isExpired } from 'react-jwt';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from 'context/authContext';
import Logo from 'components/Icons/Logo';
import FormField from 'components/FormField';
import Checkbox from 'components/Checkbox';
import { SetAuthPageType, getInvitationUserData, SignUp } from 'redux/AuthSlice';
import { InvitedSingupSchema, SingupSchema } from 'validation/schemas/AuthenticationSchema';

const SignUpPage = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const { onAuthSuccess } = useAuthContext();
  const reference = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const [isMobile] = useMediaQuery('(max-width: 575px)');
  const [isSmallLaptop] = useMediaQuery('(max-width: 820px)');
  const [successModal, setSuccessModal] = useState({ status: false, name: '' });

  const invitationData = useSelector(getInvitationUserData);

  const initialValues = {
    firstName: '',
    lastName: '',
    organizationName: invitationData?.organization ?? '',
    job_role: '',
    signupEmail: invitationData?.username ?? '',
    setpassword: '',
    isChecked: false,
  };

  const setAuthToken = (authResponse: any) =>
    onAuthSuccess(authResponse?.token, authResponse?.expiry);

  const navigateUser = () => {
    const modelToken = window.localStorage.getItem('model-token');
    if (modelToken) {
      const isLinkExpired = isExpired(modelToken);
      if (isLinkExpired) {
        navigate('/home');
      } else {
        navigate(`/model/${modelToken}`);
      }
      localStorage.removeItem('model-token');
    } else {
      navigate('/home');
    }
  };

  useOutsideClick({
    ref: reference,
    handler: () => {
      if (successModal.status) {
        navigateUser();
      }
    },
  });

  const onSignup = (response: any) => {
    const tagManagerArguments = {
      gtmId: 'GTM-NP7FL5N',
      events: {
        event: 'signup',
        userId: response?.payload?.user?.id,
      },
    };

    TagManager.initialize(tagManagerArguments);
    setAuthToken(response?.payload);

    if (invitationData?.already_registered === false) {
      navigate('/home');
    } else {
      setSuccessModal({ status: true, name: response?.payload?.user?.username });
    }
  };

  const handleSubmit = (form: any, setSubmitting: (data: boolean) => void) => {
    if (invitationData?.already_registered === false) {
      dispatch(
        SignUp({
          request_body: {
            firstname: form?.firstName,
            lastname: form?.lastName,
            organization: form?.organizationName,
            job_role: form?.job_role,
            username: form?.signupEmail,
            password: form?.setpassword,
          },
          toaster: {
            successMessage: false,
            errorMessage: true,
          },
        }),
      ).then((response: any) => {
        setSubmitting(false);
        if (!response?.error) onSignup(response);
      });
    } else {
      dispatch(
        SignUp({
          request_body: {
            firstname: form?.firstName,
            lastname: form?.lastName,
            organization: form?.organizationName,
            username: form?.signupEmail,
          },
          toaster: {
            successMessage: false,
            errorMessage: true,
          },
        }),
      ).then((response: any) => {
        setSubmitting(false);
        if (!response?.error) onSignup(response);
      });
    }
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={(form, { setSubmitting }) => {
          handleSubmit(form, setSubmitting);
        }}
        validationSchema={
          invitationData?.already_registered === false ? InvitedSingupSchema : SingupSchema
        }
        enableReinitialize={true}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }: any) => (
          <Form>
            <VStack spacing='5' alignItems={'flex-start'} width={'100%'}>
              {isSmallLaptop ? (
                <>
                  <FormField
                    label='First Name'
                    name='firstName'
                    type='text'
                    labelStyles={{
                      fontSize: '14px',
                      color: '#121826',
                    }}
                    inputStyles={{
                      maxWidth: '300px',
                      boxShadow: '0px 0px 0px 1px #7A849E',
                    }}
                    placeholder='First name'
                    error={errors.firstName}
                    touched={touched.firstName}
                    disabled={isSubmitting}
                  />
                  <FormField
                    label='Last Name'
                    name='lastName'
                    type='text'
                    labelStyles={{
                      fontSize: '14px',
                      color: '#121826',
                    }}
                    inputStyles={{
                      maxWidth: '300px',
                      boxShadow: '0px 0px 0px 1px #7A849E',
                    }}
                    styles={{
                      marginTop: '.7rem',
                    }}
                    placeholder='Last name'
                    error={errors.lastName}
                    touched={touched.lastName}
                    disabled={isSubmitting}
                  />
                </>
              ) : (
                <HStack alignItems={'flex-start'} spacing='5' width={'100%'}>
                  <FormField
                    label='First Name'
                    name='firstName'
                    type='text'
                    labelStyles={{
                      fontSize: '14px',
                      color: '#121826',
                    }}
                    inputStyles={{
                      maxWidth: '150px',
                      boxShadow: '0px 0px 0px 1px #7A849E',
                    }}
                    placeholder='First name'
                    error={errors.firstName}
                    touched={touched.firstName}
                    disabled={isSubmitting}
                  />
                  <FormField
                    label='Last Name'
                    name='lastName'
                    type='text'
                    labelStyles={{
                      fontSize: '14px',
                      color: '#121826',
                    }}
                    inputStyles={{
                      maxWidth: '150px',
                      boxShadow: '0px 0px 0px 1px #7A849E',
                    }}
                    placeholder='Last name'
                    error={errors.lastName}
                    touched={touched.lastName}
                    disabled={isSubmitting}
                  />
                </HStack>
              )}
              {invitationData ? (
                <>
                  {isSmallLaptop ? (
                    <>
                      <FormField
                        label='Organization Name'
                        name='organizationName'
                        type='text'
                        labelStyles={{
                          fontSize: '14px',
                          color: '#121826',
                        }}
                        inputStyles={{
                          maxWidth: '300px',
                          boxShadow: '0px 0px 0px 1px #7A849E',
                        }}
                        placeholder='Organization name'
                        error={errors.organizationName}
                        touched={touched.organizationName}
                        disabled={!!invitationData?.organization || isSubmitting}
                      />
                      <FormField
                        label='Job Title'
                        name='job_role'
                        type='text'
                        labelStyles={{
                          fontSize: '14px',
                          color: '#121826',
                        }}
                        inputStyles={{
                          maxWidth: '300px',
                          boxShadow: '0px 0px 0px 1px #7A849E',
                        }}
                        styles={{
                          marginTop: '.7rem',
                        }}
                        placeholder='Job Role'
                        error={errors.job_role}
                        touched={touched.job_role}
                        disabled={isSubmitting}
                      />
                    </>
                  ) : (
                    <HStack alignItems={'flex-start'} spacing='5' width={'100%'}>
                      <FormField
                        label='Organization Name'
                        name='organizationName'
                        type='text'
                        labelStyles={{
                          fontSize: '14px',
                          color: '#121826',
                        }}
                        inputStyles={{
                          maxWidth: '150px',
                          boxShadow: '0px 0px 0px 1px #7A849E',
                        }}
                        placeholder='Organization name'
                        error={errors.organizationName}
                        touched={touched.organizationName}
                        disabled={!!invitationData?.organization || isSubmitting}
                      />
                      <FormField
                        label='Job Role'
                        name='job_role'
                        type='text'
                        labelStyles={{
                          fontSize: '14px',
                          color: '#121826',
                        }}
                        inputStyles={{
                          maxWidth: '150px',
                          boxShadow: '0px 0px 0px 1px #7A849E',
                        }}
                        placeholder='Job Role'
                        error={errors.job_role}
                        touched={touched.job_role}
                        disabled={isSubmitting}
                      />
                    </HStack>
                  )}
                </>
              ) : (
                <FormField
                  label='Organization Name'
                  name='organizationName'
                  type='text'
                  labelStyles={{
                    fontSize: '14px',
                    color: '#121826',
                  }}
                  inputStyles={{
                    maxWidth: '300px',
                    boxShadow: '0px 0px 0px 1px #7A849E',
                  }}
                  styles={{
                    marginTop: '.7rem',
                  }}
                  placeholder='Organization name'
                  error={errors.organizationName}
                  touched={touched.organizationName}
                  disabled={isSubmitting}
                />
              )}

              <FormField
                label='Work email'
                name='signupEmail'
                type='email'
                labelStyles={{
                  fontSize: '14px',
                  color: '#121826',
                }}
                inputStyles={{
                  maxWidth: '300px',
                  boxShadow: '0px 0px 0px 1px #7A849E',
                }}
                styles={{
                  marginTop: '.7rem',
                }}
                placeholder='name@email.com'
                error={errors.signupEmail}
                touched={touched.signupEmail}
                disabled={!!invitationData?.username || isSubmitting}
              />
              {invitationData && (
                <FormField
                  label='Set Password'
                  name='setpassword'
                  type='password'
                  labelStyles={{
                    fontSize: '14px',
                    color: '#121826',
                  }}
                  inputStyles={{
                    maxWidth: '300px',
                    boxShadow: '0px 0px 0px 1px #7A849E',
                  }}
                  styles={{
                    marginTop: '.7rem',
                  }}
                  placeholder='*******'
                  error={errors.setpassword}
                  touched={touched.setpassword}
                  disabled={isSubmitting}
                />
              )}

              <Checkbox
                styles={{
                  marginTop: '.6rem',
                }}
                isChecked={values?.isChecked}
                onChange={(event) => {
                  setFieldValue('isChecked', event.target.checked);
                }}
                label={
                  <Text
                    variant='secondary'
                    fontFamily='regular_roboto'
                    fontSize='3xs'
                    color={errors.isChecked && touched.isChecked ? 'red' : 'black'}
                  >
                    I have read and agree to the Blox
                    <a
                      href='https://www.bloxapp.io/terms-of-use'
                      target='_blank'
                      rel='noreferrer'
                      style={{ textDecoration: 'underline', margin: '0 .2rem' }}
                    >
                      Terms of Use
                    </a>
                    and
                    <a
                      href='https://www.bloxapp.io/privacy-policy'
                      target='_blank'
                      rel='noreferrer'
                      style={{ textDecoration: 'underline', marginLeft: '.2rem' }}
                    >
                      Privacy Policy
                    </a>
                  </Text>
                }
              />

              <Button
                type='submit'
                w='full'
                h='12'
                borderRadius='xs'
                fontSize='sm'
                bgColor='green.900'
                _hover={{ _disabled: {} }}
                disabled={isSubmitting}
              >
                Sign Up {isSubmitting && <Spinner ml={'4'} />}
              </Button>
              <Box
                mt={'2 !important'}
                color={'black.900'}
                fontSize='xs'
                display={'flex'}
                justifyContent={'center'}
                width={'100%'}
              >
                Already a user?
                <Text
                  onClick={() => {
                    navigate('/');
                    dispatch(SetAuthPageType(1));
                  }}
                  ml={1}
                  cursor={'pointer'}
                  textDecoration={'underline'}
                  color={'green.900'}
                >
                  Click here to login
                </Text>
              </Box>
            </VStack>
          </Form>
        )}
      </Formik>
      <Modal
        onClose={() => {
          setSuccessModal({ status: !successModal.status, name: '' });
        }}
        isOpen={successModal.status}
        isCentered
        size={isMobile ? 'xs' : 'lg'}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent minWidth={isMobile ? '90%' : 'fit-content'}>
          <ModalBody
            alignItems={'center'}
            p={isMobile ? 6 : 10}
            fontFamily='regular_roboto'
            boxShadow={'0 15px 15px rgba(0,0,0,15%)'}
          >
            <Flex direction='column' justify='center' align='center'>
              <Logo height={10} width={'fit-content'} />
              <Heading
                fontFamily='bold'
                color='black'
                as={isMobile ? 'h5' : 'h3'}
                size={'md'}
                textAlign='center'
                pt='6'
              >
                Email sent to
                <Text color='green.900' style={{ wordBreak: 'break-word' }}>
                  {successModal.name}
                </Text>
              </Heading>
              <Text
                wordBreak={'break-word'}
                color='#1A202C'
                pt='4'
                textAlign='center'
                fontSize={isMobile ? 'xs' : 'md'}
              >
                Please check your inbox and click on the link to set your password.
              </Text>
            </Flex>
            <Flex width={'100%'} justifyContent={'center'} ref={reference}>
              <Button
                width={'170px'}
                bgColor={'green.900'}
                mt={6}
                onClick={() => {
                  setSuccessModal({ status: !successModal.status, name: '' });
                  navigateUser();
                }}
                borderColor={'1px solid #7163D0'}
                borderRadius='10px'
              >
                <Text fontSize={'sm'} fontFamily='bold'>
                  Ok
                </Text>
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SignUpPage;
