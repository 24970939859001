/* eslint-disable array-callback-return */
import { PayloadAction, createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';

import apiService from 'services';
import bloxBiService from 'services/bloxBiService';
import { GetBlocksResponse } from 'models/ModelsSlice.interface';
import { NewTotalValues } from 'models/response/blockOutput.interface';
import { STATUS } from 'projectConstants';
// import getObjectValue from 'utils/getObjectValue';

interface BlockData {
  blockId: number;
  blockName: string;
}
export type FormattingKey =
  | 'textDecoration'
  | 'textColor'
  | 'cellColor'
  | 'textAlign'
  | 'cellBorder';
export interface UpdateFormattingPayload {
  id: string;
  key: FormattingKey;
  value: string | number | boolean;
}

interface IndicatorData {
  indicatorId: number;
  indicatorName: string;
}

interface DimensionItems {
  dimensionFilterName: string;
}
interface DimensionFilters {
  localId: number;
  dimensionName: string;
  dimensionId: number | null;
  dimensionItems: DimensionItems[];
}
interface DimItemColors {
  localId: number;
  dimensionName: string;
  dimensionId: number | null;
  dimensionItems: DimensionItems[];
}
interface ConditionalFormat {
  localId: number;
  condition: string;
  color: any;
  value: any;
  id: any;
}
interface ConditionalFormatting {
  localId: number;
  color: string;
  value: number | null;
  condition: number | null;
  id: any;
}
interface TableIndicatorData {
  id?: number;
  aggregate_function?: string;
  formatting?: any;
  indicator_name: string | null;
  block_id?: number;
  dimension_name?: string;
}
interface Properties {
  widgetArray: any[];
  allChartsIds: any;
  allChartData: any;
  allDashboardTableData: any;
  modalTabs: number;
  widgetType: number;
  indicatorFetchStatus: string;
  blockFetchStatus: string;
  chartData: any | null;
  kpiCharData: any | null;
  modalBlocks: BlockData[];
  dasahboardChartId: string | null;
  chartMetricId: string | null;
  timeGranularity: string | null;
  isEditModal: boolean;
  selectedScenario: any;

  modalData: any;
  blockIndicators: IndicatorData[];
  blockDimensions: any[];
  indicatorDimension: any[];
  isIndicatorDimension: boolean;
  dashboardBlock: BlockData[];
  selectedBlockDimension: any[] | null;
  pieDimensionFilter: any[];
  widgetData: {
    tableIndicator: TableIndicatorData[];
    blockData: BlockData | null;
    indicatorData: IndicatorData | null;
    pieChartDimension: any;
    chartType: number | null;
    time: {
      detail: number;
      endDate: string;
      startDate: string;
    };
    comparisionTime: {
      detail: number;
      endDate: string;
      startDate: string;
    };
    filters: boolean;
    addConditionalFormat: ConditionalFormat[];
    comparison: any;
    sparkLine: boolean;
    displayValues: boolean;
    stackBars: boolean;
    showActualsPeriod: boolean;
    showLabels: boolean;
    displayMarkers: boolean;
    units: number | null;
    decimalPlaces: number;
    donutHoleSize: number;
    negativeDisplay: number;
    conditionalFormatting: ConditionalFormatting[];
  };
  expandKey: string;
  dimensionFilter: DimensionFilters[] | null;
  dimItemColors: DimItemColors[] | null;
  tableFilter: string;
  pieChartData: any | null;
  tableData: any | null;
  selectedRow: any;
  tableHeaderData: any | null;
  indicatorTableOutput: any | null;
  modelDashboardId: string | null;
}

export const fetchDashboardID = createAsyncThunk(
  'fetchDashboardID/create',
  async ({ model_id }: any, thunkAPI) => {
    try {
      const response = await bloxBiService.fetch_dashboardid(model_id);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const createDashboardID = createAsyncThunk(
  'createDashboardID/create',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response = await bloxBiService.create_dashboard({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const createChartInstrument = createAsyncThunk(
  'createChartInstrument/create',
  async (request_body: any, thunkAPI) => {
    try {
      const response = await bloxBiService.createDashboardChart({ request_body });

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const updateChartInstrument = createAsyncThunk(
  'updateChartInstrument/create',
  async (request_body: any, thunkAPI) => {
    try {
      const response = await bloxBiService.updateDashboardChart({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const addChartMetric = createAsyncThunk(
  'addChartMetric/create',
  async (request_body: any, thunkAPI) => {
    try {
      const response = await bloxBiService.addChartMetric({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const updateChartMetric = createAsyncThunk(
  'updateChartMetric/create',
  async (request_body: any, thunkAPI) => {
    try {
      const response = await bloxBiService.updateChartMetric({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const addChartFilter = createAsyncThunk(
  'chartFilter/add',
  async (request_body: any, thunkAPI) => {
    try {
      const response = await bloxBiService.addChartFilter({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const addConditionalFormatting = createAsyncThunk(
  'addConditionalFormatting/add',
  async (request_body: any, thunkAPI) => {
    try {
      const response = await bloxBiService.addConditionalFormatting({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const updateConditionalFormatting = createAsyncThunk(
  'addConditionalFormatting/add',
  async (request_body: any, thunkAPI) => {
    try {
      const response = await bloxBiService.updateConditionalFormatting({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const deleteConditionalFormatting = createAsyncThunk(
  'condiionalFormatting/delete',
  async (conditionId: any, thunkAPI) => {
    try {
      const response = await bloxBiService.deleteConditionalFormatting(conditionId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const updateChartFilter = createAsyncThunk(
  'chartFilter/update',
  async (request_body: any, thunkAPI) => {
    try {
      const response = await bloxBiService.updateChartFilter({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const deleteChartFilter = createAsyncThunk(
  'chartFilter/delete',
  async (filterId: string, thunkAPI) => {
    try {
      const response = await bloxBiService.deleteChartFilter(filterId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchDashboardGraph = createAsyncThunk(
  'fetchChartInstrument/get',
  async ({ chartId, scenarioId, requestBody }: any, thunkAPI) => {
    try {
      const response = await bloxBiService.getDashboardGraph(chartId, scenarioId, requestBody);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const FetchModelBlocks = createAsyncThunk(
  'dashboardModal/get',
  async ({ modelId }: any, thunkAPI) => {
    try {
      const response: GetBlocksResponse = await apiService.getDashboardBlock(modelId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);
export const fetchAllCharts = createAsyncThunk(
  'fetchallcharts/get',
  async (dashboardId: any, thunkAPI) => {
    try {
      const response = await bloxBiService.getAllCharts(dashboardId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const fetchDashboardChart = createAsyncThunk(
  'fetchDashboardChart/get',
  async (chartId: any, thunkAPI) => {
    try {
      const response = await bloxBiService.getDashboardChart(chartId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const deleteChart = createAsyncThunk(
  'delete/deleteChart',
  async ({ chartId }: any, thunkAPI) => {
    try {
      const response = await bloxBiService.deleteChart(chartId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
export const delteChartMetric = createAsyncThunk(
  'deleteChartMetric/delete',
  async (metricId: any, thunkAPI) => {
    try {
      const response = await bloxBiService.deleteChartMetric(metricId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const reorderChart = createAsyncThunk(
  'reorderChart/put',
  async (request_body: any, thunkAPI) => {
    try {
      const response = await bloxBiService.reorderChartMetric({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const formatTableData = (state: any) => {
  if (state.indicatorTableOutput) {
    const indicatorTableOutput = JSON.parse(JSON.stringify(state.indicatorTableOutput?.data));

    let keysToAdd: string[] = [];
    if (indicatorTableOutput && indicatorTableOutput.length > 0) {
      if (state.expandKey) {
        if (state.tableFilter === 'Y') {
          const year = state.expandKey;
          for (
            let index = 0;
            index < Object.values(state.indicatorTableOutput.time_granularity[year]).length;
            index += 1
          ) {
            const quarter: any = Object.values(state.indicatorTableOutput.time_granularity[year])[
              index
            ];
            keysToAdd = keysToAdd.concat(quarter);
          }
        } else if (state.tableFilter === 'Q') {
          const year = state.expandKey.split('-')[1];
          const quarter: string = state.expandKey;

          keysToAdd = state.indicatorTableOutput.time_granularity[year][quarter].map(
            (month: string) => {
              return `${month}`;
            },
          );
        }
      }
      const tableDataArray = [];
      for (let index = 0; index < indicatorTableOutput?.length; index += 1) {
        const data = indicatorTableOutput[index];

        let filteredKeys: any = [];
        if (data?.dim_df && data?.dim_df.length > 0) {
          const items = (data.dim_df as NewTotalValues[]).map((item: any) => {
            if (data?.dim_df) {
              const itemKeys = (item.values as NewTotalValues[])?.map((subItem: any) => {
                let isShow = false;
                let isExpandedColumn = false;
                if (state.tableFilter === 'Q' && subItem.Time[0] === 'Q') {
                  isShow = true;
                } else if (state.tableFilter === 'Y' && subItem.Time.length === 4) {
                  isShow = true;
                } else if (subItem.Time.length === 6 && state.tableFilter === 'M') {
                  isShow = true;
                } else {
                  const check = keysToAdd.includes(subItem.Time);
                  isShow = keysToAdd && keysToAdd.length > 0 && check;
                  if (isShow) isExpandedColumn = true;
                }

                Object.assign(subItem, { isShow, isExpandedColumn });

                return subItem;
              });
              data.value = itemKeys;
            }
            return item;
          });

          data.dim_df = items;
        }
        const timeAggregation = state.modelDetail?.time_properties?.time_granularity
          ? (state.modelDetail?.time_properties?.time_granularity as string).toUpperCase()
          : 'M';
        if (data?.total && data?.total.length) {
          filteredKeys = (data.total as NewTotalValues[]).map((item: any) => {
            let isShow = false;
            let isExpandedColumn = false;
            let isShowExpandable = false;
            if (state.tableFilter === 'Q' && item.Time[0] === 'Q') {
              isShow = true;
              isShowExpandable = true;
            } else if (state.tableFilter === 'Y' && item.Time.length === 4) {
              isShow = true;
              isShowExpandable = true;
            } else if (item.Time.length === 6 && state.tableFilter === 'M') {
              isShow = true;
            } else {
              const check = keysToAdd.includes(item.Time);
              isShow = keysToAdd && keysToAdd.length > 0 && check;
              if (isShow) isExpandedColumn = true;
            }

            if (timeAggregation === 'Y') isShowExpandable = false;

            Object.assign(item, { isShow, isShowExpandable, isExpandedColumn });
            return item;
          });
        }

        data.total = filteredKeys;
        if (index === 0) {
          state.tableHeaderData = filteredKeys;
        }

        tableDataArray.push(data);
      }

      state.tableData = tableDataArray;
    } else {
      state.tableHeaderData = null;
      state.tableData = null;
    }
  }
};

const initialState: Properties = {
  widgetArray: [],
  allChartsIds: [],
  allChartData: {},
  allDashboardTableData: {},
  modalTabs: 1, // 1. Setup 2. Display, 3. Format
  widgetType: 0, // 1: KPI, 2: Chart, 3: Table, 4: Text box
  modalBlocks: [], // Model Blocks
  modalData: [],
  blockIndicators: [], // Block Indicators
  blockDimensions: [], // Block Dimensions
  indicatorDimension: [], // Indicator Dimension
  isIndicatorDimension: true,
  dashboardBlock: [], // Dashboard Blocks For Dimension filter
  selectedBlockDimension: null,
  pieDimensionFilter: [],
  chartData: null,
  kpiCharData: null,
  dasahboardChartId: null,
  chartMetricId: null,
  timeGranularity: 'M',
  isEditModal: false,
  widgetData: {
    blockData: null,
    tableIndicator: [],
    indicatorData: null,
    pieChartDimension: null,
    chartType: 0, // 1: Pie chart, 2: line, 3: bar chart, 4: combo chart
    time: {
      detail: 1, // 1. Date Range, 2. Single month, 3. Latest actuals
      endDate: 'Dec 2024',
      startDate: 'Jan 2024',
    },
    comparisionTime: {
      detail: 0, //  1. Date Range, 2. Single month, 3. Latest actuals
      startDate: 'Jan 2024',
      endDate: 'Dec 2024',
    },
    units: 4, // 1: Thousands, 2: Millions, 3: Billions, 4: Auto
    filters: false,
    addConditionalFormat: [],
    sparkLine: false,
    displayValues: false,
    stackBars: false,
    showActualsPeriod: false,
    showLabels: false,
    displayMarkers: false,
    comparison: {},
    decimalPlaces: 0,
    donutHoleSize: 0,
    negativeDisplay: 1,
    conditionalFormatting: [],
  },
  expandKey: '',
  dimensionFilter: [],
  dimItemColors: [],
  selectedRow: null,
  tableData: null,
  tableFilter: 'M',
  pieChartData: null,
  tableHeaderData: null,
  indicatorTableOutput: null,
  selectedScenario: null,
  modelDashboardId: null,

  blockFetchStatus: STATUS.IDLE,
  indicatorFetchStatus: STATUS.IDLE,
};

const dashboardSlice = createSlice({
  name: 'dashboardSlice',
  initialState,
  reducers: {
    handleAddTableIndicator: (state, action) => {
      state.widgetData.tableIndicator = action.payload;
    },

    handleSelectedRow: (state, action) => {
      state.selectedRow = action.payload;
    },
    updateFormatting: (state, action: PayloadAction<UpdateFormattingPayload>) => {
      const { id, key, value } = action.payload;
      const metricItem = state.widgetData.tableIndicator.find((item: any) => item.id === id);
      if (metricItem) {
        metricItem.formatting[key] = value;
      }
    },
    handleRemoveTableIndicator: (state, action) => {
      const deletedId = action.payload;

      const tableIndicator = current(state.widgetData.tableIndicator);
      if (tableIndicator) {
        state.widgetData.tableIndicator = tableIndicator.filter(
          (item: any) => item.id !== deletedId,
        );
      }
    },
    handleAddPieChartDimension: (state, action) => {
      state.widgetData.pieChartDimension = action.payload;
    },

    setDashboardTableFilter: (state, action) => {
      state.expandKey = '';
      state.tableFilter = action.payload;
      formatTableData(state);
    },
    setDashboardTableExpandKey: (state, action) => {
      state.expandKey = action.payload;
      formatTableData(state);
    },
    addAllDashboardTableData: (state, action) => {
      state.allDashboardTableData = {
        ...state.allDashboardTableData,
        [action.payload.id]: action.payload,
      };
    },

    handleWidgetChange: (state, action) => {
      state.widgetArray = action.payload;
    },
    handleAddWidget: (state, action) => {
      state.widgetArray = action.payload;
    },
    handleWidgetType: (state, action) => {
      state.widgetType = action.payload;
      state.dimensionFilter = [];
      state.dimItemColors = [];
      if (action.payload !== 0) {
        state.kpiCharData = null;
        state.widgetData.comparisionTime.detail = 0;
      } else {
        state.isEditModal = false;
      }
    },
    handleChartType: (state, action) => {
      state.widgetData.chartType = action.payload;
    },
    handleModalTabs: (state, action) => {
      state.modalTabs = action.payload;
    },
    handleAddFilter: (state, action) => {
      state.widgetData.filters = action.payload;
    },
    handleAddConditionalFormat: (state) => {
      const addConditionalFormat = current(state.widgetData.addConditionalFormat);
      state.widgetData.addConditionalFormat.push({
        localId: addConditionalFormat.length + 1,
        color: '',
        condition: 'is Less Then',
        value: '',
        id: '',
      });
    },
    handleDimFilter: (state, action) => {
      state.dimensionFilter = action.payload;
    },
    handleTimeGranulairity: (state, action) => {
      state.timeGranularity = action.payload;
    },
    handleDimItemColor: (state, action) => {
      state.dimItemColors = action.payload;
    },
    handleConditionalFormat: (state, action) => {
      state.widgetData.conditionalFormatting = action.payload;
    },
    handleAddConditionalFormatting: (state) => {
      const conditionalFormatting = current(state.widgetData.conditionalFormatting);
      state.widgetData.conditionalFormatting.push({
        localId: conditionalFormatting.length + 1,
        condition: null,
        color: '',
        value: null,
        id: '',
      });
    },
    handleRemoveConditionalFormatting: (state, action) => {
      const formattingToRemove = action.payload;
      const formatting = current(state.widgetData.conditionalFormatting);
      state.widgetData.conditionalFormatting = formatting.filter(
        (filter: any) => filter.localId !== formattingToRemove,
      );
    },
    handleTimeChange: (state, action) => {
      state.widgetData.time = action.payload;
    },
    handleComparisionTimeChange: (state, action) => {
      state.widgetData.comparisionTime = action.payload;
    },
    handleWidgetsBlock: (state, action) => {
      state.widgetData.filters = false;
      state.widgetData.blockData = action.payload;
      const modalData = current(state.modalData);
      const { blockId } = action.payload;
      const blockData = modalData?.find((item: any) => item.block_id === blockId);
      const dimensions = blockData?.dimensions;
      state.blockDimensions = dimensions;
      if (state.isEditModal === false) {
        state.dimensionFilter = [];
        state.dimItemColors = [];
      }
    },
    handleWidgetsIndicator: (state, action) => {
      state.widgetData.indicatorData = action.payload;
      if (state.widgetType === 2) {
        const indicatorDims = action.payload?.indicator_dimensions.map((dimension: any) => ({
          dimension_id: dimension.indicator_dimension_id,
          dimension_name: dimension.indicator_dimension_name,
          dimension_items: dimension.indicator_dimension_items.map((item: any) => ({
            dimension_item_id: item.indicator_dimension_item_id,
            dimension_item_name: item.indicator_dimension_item_name,
          })),
        }));
        state.indicatorDimension = indicatorDims;
        state.isIndicatorDimension = indicatorDims.length > 0;
      }
    },
    handleWidgetSparkLine: (state, action) => {
      state.widgetData.sparkLine = action.payload;
    },
    handleWidgetDisplayValues: (state, action) => {
      state.widgetData.displayValues = action.payload;
    },
    handleWidgetDonutHoleSize: (state, action) => {
      state.widgetData.donutHoleSize = action.payload;
    },
    handleWidgetStackBars: (state, action) => {
      state.widgetData.stackBars = action.payload;
    },
    handleWidgetShowActualsPeriod: (state, action) => {
      state.widgetData.showActualsPeriod = action.payload;
    },
    handleWidgetShowLabels: (state, action) => {
      state.widgetData.showLabels = action.payload;
    },
    handleWidgetDisplayMarkers: (state, action) => {
      state.widgetData.displayMarkers = action.payload;
    },
    handleWidgetDecimalPlaces: (state, action) => {
      state.widgetData.decimalPlaces = action.payload;
    },
    handleWidgetUnits: (state, action) => {
      state.widgetData.units = action.payload;
    },
    handleWidgetNegativeDisplay: (state, action) => {
      state.widgetData.negativeDisplay = action.payload;
    },
    handleAddAllChartData: (state, action) => {
      state.allChartData = { ...state.allChartData, [action.payload.id]: action.payload };
    },
    handleDeleteDashboardChart: (state, action) => {
      const newAllChartData = { ...state.allChartData };
      delete newAllChartData[action.payload];
      return {
        ...state,
        allChartData: newAllChartData,
      };
    },
    handleAddCurrentModalChart: (state, action) => {
      state.dasahboardChartId = action.payload;
    },
    handleIsEditModal: (state, action) => {
      state.isEditModal = action.payload;
    },
    fetchBlockIndicator: (state, action) => {
      const modalData = current(state.modalData);
      const indicatorData = modalData?.find((item: any) => item.block_id === action.payload);
      state.indicatorFetchStatus = STATUS.IDLE;
      state.blockIndicators = indicatorData?.indicators;
    },

    selectDashboardScenario: (state, action) => {
      state.selectedScenario = action.payload;
    },
    dashboardBlockSelect: (state, action) => {
      const modalData = current(state.modalData);
      const { blockId } = action.payload;
      const blockData = modalData?.find((item: any) => item.block_id === blockId);
      const dimensions = blockData?.dimensions;
      state.dashboardBlock = dimensions;
    },
    handlePieDimFilter: (state, action) => {
      state.pieDimensionFilter = action.payload;
    },
    resetDashboardModal: (state) => {
      state.widgetData = {
        blockData: null,
        indicatorData: null,
        tableIndicator: [],
        chartType: 0,
        pieChartDimension: null,
        time: {
          detail: 1,
          startDate: 'Jan 2024',
          endDate: 'Dec 2024',
        },
        comparisionTime: {
          detail: 0,
          startDate: 'Jan 2024',
          endDate: 'Dec 2024',
        },
        filters: false,
        addConditionalFormat: [],
        comparison: {},
        sparkLine: false,
        displayValues: false,
        stackBars: false,
        showActualsPeriod: false,
        showLabels: false,
        displayMarkers: false,
        units: null,
        decimalPlaces: 0,
        donutHoleSize: 0,
        negativeDisplay: 1,
        conditionalFormatting: [],
      };
      state.kpiCharData = null;
      state.selectedRow = null;
      state.tableData = null;
      state.indicatorTableOutput = null;
      state.tableHeaderData = null;
      state.selectedBlockDimension = null;
      state.expandKey = '';
      state.tableFilter = 'M';
      state.dasahboardChartId = null;
      state.chartMetricId = null;
    },
    resetDashboard: (state) => {
      state.widgetArray = [];
      state.allChartData = {};
      state.allChartsIds = [];
      state.widgetType = 0;
      state.modalTabs = 1;
      state.selectedScenario = null;
      state.modelDashboardId = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardGraph.fulfilled, (state, action) => {
      const instrumentType = state.widgetType;
      if (instrumentType === 3) {
        state.indicatorTableOutput = action.payload;
        state.expandKey = '';
        formatTableData(state);
      } else if (state.widgetData.chartType === 1 && instrumentType === 2) {
        state.indicatorTableOutput = action.payload;
        state.pieChartData = action.payload;
      } else if (state.widgetData.chartType === 3 && instrumentType === 2) {
        state.pieChartData = action.payload;
        state.indicatorTableOutput = action.payload;
      } else if (instrumentType === 2) {
        state.indicatorTableOutput = action.payload;
        state.expandKey = '';
        formatTableData(state);
      } else {
        state.chartData = action.payload;
      }
      state.chartData = action.payload;
    });

    builder.addCase(FetchModelBlocks.pending, (state) => {
      state.blockFetchStatus = STATUS.LOADING;
    });
    builder.addCase(createChartInstrument.fulfilled, (state, action) => {
      state.dasahboardChartId = action.payload.id;
      state.chartMetricId = action.payload.chart_metrics[0]?.id;
    });
    builder.addCase(fetchAllCharts.fulfilled, (state, action) => {
      state.allChartsIds = action.payload;
    });
    builder.addCase(FetchModelBlocks.fulfilled, (state, action) => {
      const modalBlocks = action.payload;
      state.modalData = modalBlocks;

      const blocks = modalBlocks?.map((item: any) => {
        return { blockId: item.block_id, blockName: item.block_name };
      });
      state.blockFetchStatus = STATUS.SUCCESS;
      state.modalBlocks = blocks;
    });
    builder.addCase(fetchDashboardChart.fulfilled, (state, action) => {
      state.kpiCharData = action.payload;
    });
    builder.addCase(fetchDashboardID.fulfilled, (state, action) => {
      state.modelDashboardId = action.payload.id;
    });
    builder.addCase(createDashboardID.fulfilled, (state, action) => {
      state.modelDashboardId = action.payload.id;
    });
  },
});

export const {
  resetDashboard,
  handleModalTabs,
  handleDimFilter,
  handleTimeGranulairity,
  handleAddWidget,
  handleAddFilter,
  handleAddConditionalFormat,
  handleDimItemColor,
  handleConditionalFormat,
  handleChartType,
  handleWidgetType,
  handleTimeChange,
  handleWidgetUnits,
  handleWidgetChange,
  handleWidgetsBlock,
  handleIsEditModal,
  handleDeleteDashboardChart,
  handleAddCurrentModalChart,
  fetchBlockIndicator,
  resetDashboardModal,
  handleWidgetSparkLine,
  dashboardBlockSelect,
  handlePieDimFilter,
  handleWidgetStackBars,
  handleWidgetShowLabels,
  handleWidgetsIndicator,
  handleAddTableIndicator,
  setDashboardTableFilter,
  handleWidgetDisplayValues,
  handleWidgetDecimalPlaces,
  handleWidgetDonutHoleSize,
  handleAddPieChartDimension,
  handleWidgetDisplayMarkers,
  setDashboardTableExpandKey,
  handleRemoveTableIndicator,
  handleWidgetNegativeDisplay,
  handleComparisionTimeChange,
  handleWidgetShowActualsPeriod,
  handleAddConditionalFormatting,
  handleRemoveConditionalFormatting,
  updateFormatting,
  handleSelectedRow,
  handleAddAllChartData,
  addAllDashboardTableData,
  selectDashboardScenario,
} = dashboardSlice.actions;

export const getBlocks = (state: any) => state.dashboardSlice?.modalBlocks;
export const getDashboardChartId = (state: any) => state.dashboardSlice?.dasahboardChartId;
export const getModalData = (state: any) => state.dashboardSlice?.modalData;
export const getChartMetricId = (state: any) => state.dashboardSlice?.chartMetricId;
export const getChartData = (state: any) => state.dashboardSlice?.chartData;
export const getKpiChartData = (state: any) => state.dashboardSlice?.kpiCharData;
export const getModalTabs = (state: any) => state.dashboardSlice?.modalTabs;
export const getWidgetData = (state: any) => state.dashboardSlice?.widgetData;
export const getIndicators = (state: any) => state.dashboardSlice?.blockIndicators;
export const getIsEditModal = (state: any) => state.dashboardSlice?.isEditModal;
export const getDimensions = (state: any) => state.dashboardSlice?.blockDimensions;
export const getIndicatorDimensions = (state: any) => state.dashboardSlice?.indicatorDimension;
export const getIsIndicatorDimension = (state: any) => state.dashboardSlice?.isIndicatorDimension;
export const getDimItems = (state: any) => state.dashboardSlice?.dashboardBlock;
export const gePieDimFilter = (state: any) => state.dashboardSlice?.pieDimensionFilter;
export const getTimeGranularity = (state: any) => state.dashboardSlice?.timeGranularity;
export const getBlock = (state: any) => state.dashboardSlice?.widgetData.blockData;
export const getDashboardWidget = (state: any) => state.dashboardSlice?.widgetArray;
export const getDashboardTableData = (state: any) => state.dashboardSlice.tableData;
export const getDashboardPieChartData = (state: any) => state.dashboardSlice.pieChartData;
export const getDashboardWidgetType = (state: any) => state.dashboardSlice?.widgetType;
export const getChartType = (state: any) => state.dashboardSlice?.widgetData.chartType;
export const getDashboardTableFilter = (state: any) => state.dashboardSlice.tableFilter;
export const getFetchblockStatus = (state: any) => state.dashboardSlice.blockFetchStatus;
export const getDimensionFilters = (state: any) => state.dashboardSlice?.dimensionFilter;
export const getDimItemColor = (state: any) => state.dashboardSlice?.dimItemColors;
export const getIndicator = (state: any) => state.dashboardSlice?.widgetData?.indicatorData;
export const getDashboardTableHeaderData = (state: any) => state.dashboardSlice.tableHeaderData;
export const getFetchIndicatorStatus = (state: any) => state.dashboardSlice.indicatorFetchStatus;
export const getTableIndicator = (state: any) => state.dashboardSlice.indicatorTableOutput;
export const getSelectedRow = (state: any) => state.dashboardSlice.selectedRow;
export const getAllChartData = (state: any) => state.dashboardSlice.allChartData;
export const getAllChartIds = (state: any) => state.dashboardSlice.allChartsIds;
export const getAllDashboardTableData = (state: any) => state.dashboardSlice.allDashboardTableData;
export const getConditionalFormatting = (state: any) =>
  state.dashboardSlice?.widgetData.conditionalFormatting;
export const getDashboardTableExpandKey = (state: any) => state.dashboardSlice.expandKey;
export const getDashboardSelectedScenario = (state: any) => state.dashboardSlice.selectedScenario;
export const getModelDashboardId = (state: any) => state.dashboardSlice.modelDashboardId;
export default dashboardSlice.reducer;
