import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { GetMappingData } from 'models/requestBody/updatePropertyValues.interface';
import { STATUS } from 'projectConstants';
import apiService from 'services';

export const uploadDataImportFile = createAsyncThunk(
  'uploadFile/post',
  async ({ modelId, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.uploadExcelFileData({ modelId, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);
export const getMetaDataFile = createAsyncThunk(
  'getMetaDataFile/get',
  async (modelId: any, thunkAPI) => {
    try {
      const response = await apiService.getMetadata(modelId);
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const getDataImportFiles = createAsyncThunk(
  'getDataImportFiles/get',
  async (modelId: any, thunkAPI) => {
    try {
      const response = await apiService.getExcelFiles(modelId);
      return response?.data?.data_source;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const getSingleDataImportFiles = createAsyncThunk(
  'getSingleDataImportFiles/fetch',
  async ({ modelId, fileId }: any, thunkAPI) => {
    try {
      const response = await apiService.getSingleExcelFile(modelId, fileId);
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const deleteDataImportFile = createAsyncThunk(
  'deleteDataImportFile/delete',
  async ({ modelId, fileId }: any, thunkAPI) => {
    try {
      const response = await apiService.deleteSingleExcelFile(modelId, fileId);
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const editDataImportFile = createAsyncThunk(
  'deleteDataImportFile/delete',
  async ({ modelId, fileId, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.editExcelFile({ modelId, fileId, request_body });
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const getActionData = createAsyncThunk(
  'getActionData/fetch',
  async (dataSourceId: number, thunkAPI) => {
    try {
      const response = await apiService.allActionData(dataSourceId);
      return response?.data?.action;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const deleteActionData = createAsyncThunk(
  'getActionData/delete',
  async ({ dataSourceId, actionId }: any, thunkAPI) => {
    try {
      const response = await apiService.deleteDataAction(dataSourceId, actionId);
      return response?.data?.action;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const getOneActionData = createAsyncThunk(
  'getOneActionData/fetch',
  async ({ dataSourceId, actionId }: any, thunkAPI) => {
    try {
      const response = await apiService.getDataAction(dataSourceId, actionId);
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const createAction = createAsyncThunk(
  'createAction/post',
  async ({ dataSourceId, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.createActionData({ dataSourceId, request_body });
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const putActionData = createAsyncThunk(
  'putActionData/put',
  async ({ dataSourceId, actionId, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.editActionData({ dataSourceId, actionId, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const editActionDetails = createAsyncThunk(
  'editActionDetails/post',
  async ({ dataSourceId, actionId, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.editActionData({ dataSourceId, actionId, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const versionData = createAsyncThunk(
  'versionData/get',
  async (dataSourceId: number, thunkAPI) => {
    try {
      const response = await apiService.getVersionData(dataSourceId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const postVersionFile = createAsyncThunk(
  'postVersionFile/post',
  async ({ dataSourceId, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.postVersionFile({ dataSourceId, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const editVersionFile = createAsyncThunk(
  'postVersionFile/post',
  async ({ dataSourceId, versionId, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.editVersionFile({ dataSourceId, versionId, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const deleteVersionIFile = createAsyncThunk(
  'deleteVersionIFile/delete',
  async ({ dataSourceId, versionId }: any, thunkAPI) => {
    try {
      const response = await apiService.deleteVersionFile(dataSourceId, versionId);
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const getMappingDataFile = createAsyncThunk(
  'getMappingData/post',
  async ({ dataSourceId, actionId, parameters }: GetMappingData, thunkAPI) => {
    try {
      const response = await apiService.getDataMapping(dataSourceId, actionId, {
        params: parameters,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);
export const postMappingData = createAsyncThunk(
  'postMappingData/post',
  async ({ dataSourceId, actionId, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.runDataMapping({ dataSourceId, actionId, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const duplicateSourceFile = createAsyncThunk(
  'duplicateSourceFile/post',
  async (dataSourceId: number, thunkAPI) => {
    try {
      const response = await apiService.duplicateSourceData(dataSourceId);
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const duplicateActionFile = createAsyncThunk(
  'duplicateActionFile/post',
  async ({ dataSourceId, actionId }: any, thunkAPI) => {
    try {
      const response = await apiService.duplicateActionData(dataSourceId, actionId);
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchDimensionItems = createAsyncThunk(
  'fetchDimensionItems/fetch',
  async ({ dimensionId, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.get_dimension_items(dimensionId, {
        params: parameters,
      });
      return response.data.items;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
const initialState: any = {
  importDataFiles: null,
  metadata: null,
  actionSteps: 0,
  isActionPopoverOpen: false,
  actionFormData: {
    name: '',
    description: '',
    dataType: '',
    dimension: '',
    blockId: null,
    selectedScenario: null,
    column: '',
    propertdata: null,
    dataPeriod: '1',
    startDate: '',
    selectedTimePeriod: null,
    singleTimePeriodValue: null,
    multiTimePeriodValue: null,
    importindicatroType: '1',
    singleIndicatorValue: null,
    multipleIndicatprType: null,
    rowsIndicatorMappedData: null,
    columnIndicatorMappedData: null,
    columnNumericValueoofRowsIndicatorType: null,
    selectedDimensionColumnofTable: null,
    dimensionItemsData: null,
  },
  selectedDataFileDetails: {
    id: null,
    name: null,
    deatsilsPage: false,
  },
  tableHeaderList: [],
  versionList: [],
  selectedVersionDetails: {},
  actionListData: null,
  mappedDataFile: {},
  mappedDataFileStatus: STATUS.IDLE,
  postMappingDataStatus: STATUS.IDLE,
  selectedares: {
    selectedColumnOption: '',
  },
  isEdit: {
    isEditForm: false,
    actionId: null,
    actionType: null,
    scenarioId: null,
  },
  selectedAction: {
    actionId: null,
  },
  actionCreateForm: false,
  noHeaders: false,
  distinctCSV: null,
  steps: 3,
};

const dataImportSlice: any = createSlice({
  initialState,
  name: 'dataImportSlice',
  reducers: {
    resetDataAll: (state) => {
      state.importDataFiles = null;
      state.distinctCSV = null;
      state.steps = 3;
      state.selectedDataFileDetails = {
        id: null,
        name: null,
        deatsilsPage: false,
      };
      state.versionList = [];
      state.selectedVersionDetails = {};
      state.selectedares = {
        selectedColumnOption: '',
      };
      state.isEdit = {
        isEditForm: false,
        actionId: null,
        actionType: null,
        scenarioId: null,
      };
      state.selectedAction = {
        actionId: null,
      };
      state.metadata = null;
      state.actionFormData = initialState.actionFormData;
      state.actionSteps = 0;
      state.noHeaders = false;
      state.tableHeaderList = [];
      state.actionListData = null;
      state.mappedDataFile = {};
      state.mappedDataFileStatus = STATUS.IDLE;
      state.postMappingDataStatus = STATUS.IDLE;
      state.actionCreateForm = false;
      state.noHeaders = false;
    },
    stepsLength: (state, action) => {
      state.steps = action.payload;
    },
    setDistinctCSV: (state, action) => {
      state.distinctCSV = action.payload;
    },
    setNoheaders: (state, action) => {
      state.noHeaders = action.payload;
    },
    setDataPeriodType: (state, action) => {
      state.actionFormData = {
        ...state.actionFormData,
        dataPeriod: action.payload,
      };
    },
    resetDataPeriodValuesForSingleType: (state) => {
      state.actionFormData = {
        ...state.actionFormData,
        column: '',
        dimension: '',
        propertdata: null,
        selectedTimePeriod: null,
        // singleTimePeriodValue: null,
        multiTimePeriodValue: null,
        // importindicatroType: '1',
        // singleIndicatorValue: null,
        // multipleIndicatprType: null,
        // rowsIndicatorMappedData: null,
        // columnIndicatorMappedData: null,
        // columnNumericValueoofRowsIndicatorType: null,
        // selectedDimensionColumnofTable: null,
        // dimensionItemsData: null,
      };
    },
    resetDataPeriodValuesForMultipleType: (state) => {
      state.actionFormData = {
        ...state.actionFormData,
        column: '',
        dimension: '',
        propertdata: null,
        startDate: '',
        // singleTimePeriodValue: null,
        // multiTimePeriodValue: null,
        // importindicatroType: '1',
        // singleIndicatorValue: null,
        // multipleIndicatprType: null,
        // rowsIndicatorMappedData: null,
        // columnIndicatorMappedData: null,
        // columnNumericValueoofRowsIndicatorType: null,
        // selectedDimensionColumnofTable: null,
        // dimensionItemsData: null,
      };
    },
    setImportindicatroType: (state, action) => {
      state.actionFormData = {
        ...state.actionFormData,
        importindicatroType: action.payload,
      };
    },
    resetDataImport: (state) => {
      state.selectedDataFileDetails = {
        id: null,
        name: null,
        deatsilsPage: false,
      };
      state.steps = 3;
      state.actionListData = null;
      state.versionList = [];
      state.selectedVersionDetails = {};
      state.selectedares = {
        selectedColumnOption: '',
      };
      state.isEdit = {
        isEditForm: false,
        actionId: null,
        actionType: null,
        scenarioId: null,
      };
      state.selectedAction = {
        actionId: null,
      };
      state.metadata = null;
    },
    selectedFileId: (state, action) => {
      state.selectedDataFileDetails = action.payload;
    },
    handleActionStepNext: (state) => {
      state.actionSteps += 1;
    },
    handleActionStepPrevious: (state) => {
      state.actionSteps -= 1;
    },
    handleActionPopover: (state, action) => {
      state.isActionPopoverOpen = action.payload;
    },
    addSteponeFormData: (state, action) => {
      state.actionFormData = {
        ...state.actionFormData,
        name: action.payload?.name,
        description: action.payload?.description,
        duplicateAction: action.payload?.duplicateAction,
      };
    },
    addModalFormData: (state, action) => {
      state.actionFormData = {
        ...state.actionFormData,
        dataType: action.payload,
      };
    },
    addStepTwoFormData: (state, action) => {
      state.actionFormData = {
        ...state.actionFormData,
        dataType: action.payload?.selectedDataType,
        dimension: action.payload?.selecetedDimension,
        column: action.payload?.selectedColumnOption,
        blockId: action.payload?.selectedBlock,
        selectedScenario: action.payload?.selectedScenario,
      };
    },
    addDataStepOneFormSignleDataPeriod: (state, action) => {
      state.actionFormData = {
        ...state.actionFormData,
        startDate: action.payload?.selectedStartDate
          ? new Date(action.payload?.selectedStartDate).toDateString()
          : '',
      };
    },
    addDataStepOneFormMultipleDataPeriod: (state, action) => {
      state.actionFormData = {
        ...state.actionFormData,
        selectedTimePeriod: action.payload?.selectedTimePeriod,
      };
    },
    setSingleTimePeriodValue: (state, action) => {
      state.actionFormData = {
        ...state.actionFormData,
        singleTimePeriodValue: action.payload?.singleTimePeriodValue,
      };
    },
    resetSingleTimePeriodValue: (state) => {
      state.actionFormData = {
        ...state.actionFormData,
        singleTimePeriodValue: null,
      };
    },
    resetMultipleTimePeriodValue: (state) => {
      state.actionFormData = {
        ...state.actionFormData,
        multiTimePeriodValue: null,
      };
    },
    setMultiTimePeriodValue: (state, action) => {
      state.actionFormData = {
        ...state.actionFormData,
        multiTimePeriodValue: action.payload,
      };
    },
    setSingleIndicatorValue: (state, action) => {
      state.actionFormData = {
        ...state.actionFormData,
        singleIndicatorValue: action.payload?.singleIndicatorValue,
        columnNumericValueoofRowsIndicatorType:
          action.payload?.columnNumericValueoofRowsIndicatorType,
      };
    },
    resetSingleIndicatorValue: (state) => {
      state.actionFormData = {
        ...state.actionFormData,
        singleIndicatorValue: null,
      };
    },

    setMultipleIndicatprType: (state, action) => {
      state.actionFormData = {
        ...state.actionFormData,
        multipleIndicatrosType: action.payload?.multipleIndicatrosType,
      };
    },
    resetMultipleIndicatprType: (state) => {
      state.actionFormData = {
        ...state.actionFormData,
        multipleIndicatrosType: null,
      };
    },
    setRowsIndicatorMappedData: (state, action) => {
      state.actionFormData = {
        ...state.actionFormData,
        rowsIndicatorTableColumn: action.payload?.rowsIndicatorTableColumn,
        rowsIndicatorMappedData: action.payload?.data,
      };
    },
    setColumnNumericValueoofRowsIndicatorType: (state, action) => {
      state.actionFormData = {
        ...state.actionFormData,
        columnNumericValueoofRowsIndicatorType:
          action.payload?.columnNumericValueoofRowsIndicatorType,
      };
    },
    resetRowsIndicatorMappedData: (state) => {
      state.actionFormData = {
        ...state.actionFormData,
        rowsIndicatorTableColumn: null,
        rowsIndicatorMappedData: null,
        columnNumericValueoofRowsIndicatorType: null,
      };
    },
    setColumnIndicatorMappedData: (state, action) => {
      state.actionFormData = {
        ...state.actionFormData,
        columnIndicatorMappedData: action.payload,
      };
    },
    resetColumnIndicatorMappedData: (state) => {
      state.actionFormData = {
        ...state.actionFormData,
        columnIndicatorMappedData: null,
      };
    },
    setSelectedDimensionColumnofTable: (state, action) => {
      state.actionFormData = {
        ...state.actionFormData,
        selectedDimensionColumnofTable: action.payload?.selectedDimensionColumnofTable,
      };
    },
    clearStepperFormData: (state) => {
      state.actionFormData = initialState.actionFormData;
      state.isEdit = initialState.isEdit;
      state.actionSteps = 0;
      state.noHeaders = false;
    },
    clearStepperFormPropertyData: (state) => {
      state.actionFormData = {
        ...state.actionFormData,
        propertdata: {},
      };
    },
    setTableHeaderName: (state, action) => {
      state.tableHeaderList = action.payload;
    },
    setSelectedVersionDetails: (state, action) => {
      state.selectedVersionDetails = action.payload;
    },
    setSelectedAreas: (state, action) => {
      state.selectedares = {
        ...state.selectedares,
        selectedColumnOption: action.payload?.selectedColumnOption,
      };
    },
    setSelectedAreasProperties: (state, action) => {
      state.selectedares = {
        ...state.selectedares,
        ...action.payload,
      };
    },
    setClearSelectedAreasTable: (state) => {
      state.selectedares = initialState.selectedares;
    },
    setActionFormEdit: (state, action) => {
      state.isEdit = action.payload;
    },
    setActionDetails: (state, action) => {
      state.selectedAction = action.payload;
    },
    resetMappingData: (state) => {
      state.mappedDataFile = initialState.mappedDataFile;
      state.mappedDataFileStatus = initialState.mappedDataFileStatus;
    },
    setActionCreateForm: (state, action) => {
      state.actionCreateForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDataImportFiles.fulfilled, (state, action) => {
      if (action.payload?.length === 1) {
        state.selectedDataFileDetails = {
          id: action.payload[0].id,
          name: action.payload[0].file_name,
          deatsilsPage: true,
        };
      }
      state.importDataFiles = action.payload;
    });
    builder.addCase(versionData.fulfilled, (state, action) => {
      state.versionList = action.payload;
      state.selectedVersionDetails = action.payload?.versions[0];
    });
    builder.addCase(getMetaDataFile.fulfilled, (state, action) => {
      state.metadata = action.payload;
    });
    builder.addCase(getActionData.fulfilled, (state, action) => {
      state.actionListData = action.payload;
    });
    builder.addCase(getMappingDataFile.fulfilled, (state, action) => {
      state.mappedDataFile = action.payload;
    });
    builder.addCase(getMappingDataFile.rejected, (state) => {
      state.mappedDataFileStatus = STATUS.FAILED;
    });
    builder.addCase(postMappingData.fulfilled, (state) => {
      state.postMappingDataStatus = STATUS.SUCCESS;
    });
    builder.addCase(postMappingData.rejected, (state) => {
      state.postMappingDataStatus = STATUS.FAILED;
    });
    builder.addCase(getOneActionData.fulfilled, (state, action) => {
      const mappingData = action.payload?.mappings[0];
      const dimId = Object.keys(mappingData)[0];
      let reversedColumnMappingData: any = {};
      if (action.payload?.indicator_mapping_type === 'column') {
        reversedColumnMappingData = Object.keys(mappingData?.indicator_map).map((key) => {
          return {
            key: mappingData?.indicator_map[key],
            value: Number.parseInt(key, 10),
          };
        });
      }
      reversedColumnMappingData =
        Object.keys(reversedColumnMappingData).length > 0 ? reversedColumnMappingData : null;
      let reversedRowMappingData: any = {};
      if (action.payload?.indicator_mapping_type === 'row' && mappingData?.indicator_map) {
        Object.keys(mappingData?.indicator_map).forEach((key) => {
          reversedRowMappingData[mappingData?.indicator_map[key]] = key;
        });
      }
      reversedRowMappingData =
        Object.keys(reversedRowMappingData).length > 0 ? reversedRowMappingData : null;
      state.actionFormData = {
        name: action.payload?.name,
        description: action.payload?.description,
        dataType: action.payload?.action_type === 'dimension' ? '1' : '2',
        dimension: dimId,
        column: mappingData[dimId],
        blockId: mappingData?.block_id,
        selectedTimePeriod: action.payload?.mapping_type,
        propertdata: mappingData?.property_map,
        startDate: action.payload?.period_start_date,
        dataPeriod: action.payload?.period_type === 'single' ? '1' : '2',
        selectedScenario: action.payload?.scenario_id,
        singleTimePeriodValue: action.payload?.time_column,
        importindicatroType: action.payload?.is_multiple_indicator ? '2' : '1',
        multipleIndicatrosType: action.payload?.indicator_mapping_type,
        rowsIndicatorTableColumn: mappingData?.indicator_column,
        columnNumericValueoofRowsIndicatorType: mappingData?.value_column,
        columnIndicatorMappedData: reversedColumnMappingData,
        rowsIndicatorMappedData: reversedRowMappingData,
        singleIndicatorValue: mappingData?.indicator,
        multiTimePeriodValue: mappingData?.time_column_map,
        dimensionItemsData: mappingData?.block_dimension_map,
        mapItemsManually: action.payload?.mapItemsManually,
      };

      state.noHeaders = action.payload?.missing_header;
      state.selectedares =
        action.payload?.action_type === 'dimension'
          ? {
              selectedColumnOption: mappingData[dimId],
              ...mappingData?.property_map,
            }
          : mappingData?.shaddedArea;
    });
  },
});

export const {
  selectedFileId,
  handleActionPopover,
  handleActionStepNext,
  handleActionStepPrevious,
  addSteponeFormData,
  addStepTwoFormData,
  setTableHeaderName,
  addModalFormData,
  resetDataImport,
  setSelectedVersionDetails,
  clearStepperFormData,
  setSelectedAreas,
  setSelectedAreasProperties,
  setClearSelectedAreasTable,
  clearStepperFormPropertyData,
  setActionFormEdit,
  setActionDetails,
  resetMappingData,
  setActionCreateForm,
  addDataStepOneFormSignleDataPeriod,
  setNoheaders,
  resetDataAll,
  setDataPeriodType,
  addDataStepOneFormMultipleDataPeriod,
  setSingleTimePeriodValue,
  setMultiTimePeriodValue,
  setImportindicatroType,
  setSingleIndicatorValue,
  setMultipleIndicatprType,
  setRowsIndicatorMappedData,
  setColumnIndicatorMappedData,
  setColumnNumericValueoofRowsIndicatorType,
  setSelectedDimensionColumnofTable,
  setDistinctCSV,
  resetDataPeriodValuesForSingleType,
  resetDataPeriodValuesForMultipleType,
  resetSingleTimePeriodValue,
  resetMultipleTimePeriodValue,
  resetMultipleIndicatprType,
  resetSingleIndicatorValue,
  resetColumnIndicatorMappedData,
  resetRowsIndicatorMappedData,
  stepsLength,
} = dataImportSlice.actions;
export const getImportDataFiles = (state: any) => state.dataImportSlice.importDataFiles;
export const getSelectedDataFileDetails = (state: any) =>
  state.dataImportSlice.selectedDataFileDetails;

export const getFormValues = (state: any) => state.dataImportSlice.actionFormData;
export const getTableHeader = (state: any) => state.dataImportSlice.tableHeaderList;
export const getVersionList = (state: any) => state.dataImportSlice.versionList;
export const getSelectedVersionDetails = (state: any) =>
  state.dataImportSlice.selectedVersionDetails;
export const getActionList = (state: any) => state.dataImportSlice.actionListData;
export const getMappedDataFile = (state: any) => state.dataImportSlice.mappedDataFile;
export const getPostMappingDataStatus = (state: any) => state.dataImportSlice.postMappingDataStatus;
export const getSelectedAreaOfTable = (state: any) => state.dataImportSlice.selectedares;
export const getEditActionForm = (state: any) => state.dataImportSlice.isEdit;
export const getSelectedAction = (state: any) => state.dataImportSlice.selectedAction;
export const getMappedDataFileStatus = (state: any) => state.dataImportSlice.mappedDataFileStatus;
export const getActionCreateStatus = (state: any) => state.dataImportSlice.actionCreateForm;
export const getMetadatas = (state: any) => state.dataImportSlice.metadata;
export const getNoheadersStatus = (state: any) => state.dataImportSlice.noHeaders;
export const getDistinctCSV = (state: any) => state.dataImportSlice.distinctCSV;
export const getStepsLength = (state: any) => state.dataImportSlice.steps;

export default dataImportSlice.reducer;
