import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';

import { STATUS } from 'projectConstants';
import apiService from 'services';

import {
  GetBlocksResponse,
  ModelsInitialValue,
  UpdateModelTimeResponse,
} from '../models/ModelsSlice.interface';

export const GetUserModels = createAsyncThunk('userModels/get', async (_: any, thunkAPI) => {
  try {
    const response = await apiService.get_user_models();
    return response.data?.models;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const RenameUserModels = createAsyncThunk(
  'renameUserModel/post',
  async ({ model_id, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.update_model({ model_id, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const DuplicateUserModels = createAsyncThunk(
  'duplicatedUserModel/post',
  async ({ modelId, modelName }: any, thunkAPI) => {
    try {
      const response = await apiService.addModelFromLibrary(modelId, modelName);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const DeleteUserModels = createAsyncThunk(
  'deleteUserModel/post',
  async ({ modelId }: any, thunkAPI) => {
    try {
      const response = await apiService.delete_model(modelId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const FetchModelBlocks = createAsyncThunk(
  'models/get',
  async ({ modelId }: any, thunkAPI) => {
    try {
      const response: GetBlocksResponse = await apiService.get_model(modelId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const AddModelBlock = createAsyncThunk(
  'addModelBlock/post',
  async ({ modelId, data }: any, thunkAPI) => {
    try {
      const response = await apiService.add_block(modelId, data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const UpdateModelTime = createAsyncThunk(
  'updateModelTime/get',
  async ({ requestObject }: any, thunkAPI) => {
    try {
      const response: UpdateModelTimeResponse = await apiService.update_model_time(requestObject);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const UpdateBlock = createAsyncThunk(
  'block/put',
  async ({ blockId, data }: any, thunkAPI) => {
    try {
      const response: UpdateModelTimeResponse = await apiService.updateBlock(blockId, data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const ReorderBlocks = createAsyncThunk(
  'reorderBlocks/put',
  async ({ modelId, data }: any, thunkAPI) => {
    try {
      const response: UpdateModelTimeResponse = await apiService.reorder_blocks(modelId, data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const UpdateModel = createAsyncThunk(
  'model/put',
  async ({ modelId, data }: any, thunkAPI) => {
    try {
      const response: UpdateModelTimeResponse = await apiService.updateModel(modelId, data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const AddModel = createAsyncThunk('addblock/post', async ({ data }: any, thunkAPI) => {
  try {
    const response: UpdateModelTimeResponse = await apiService.add_models(data);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const DeleteModelsBlock = createAsyncThunk(
  'deleteModelsBlock/post',
  async ({ blockId }: any, thunkAPI) => {
    try {
      const response = await apiService.delete_block(blockId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const DuplicateModelsBlock = createAsyncThunk(
  'duplicateModelsBlock/post',
  async ({ blockId }: any, thunkAPI) => {
    try {
      const response = await apiService.duplicate_block(blockId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

const modelsSlice = createSlice({
  name: 'modelsSlice',
  initialState: {
    models: [],
    blocks: {},
    duplicateModels: [],
    timescaleDate: {
      startDate: '',
      endDate: '',
    },
    timeStatus: false,
    timeData: STATUS.IDLE,
  } as ModelsInitialValue,
  reducers: {
    StartDuplicateModel: (state, action) => {
      state.duplicateModels.push(action.payload);
    },
    StopDuplicateModel: (state) => {
      state.duplicateModels.splice(0, 1);
    },
    resetTimeStatus: (state) => {
      state.timeStatus = false;
    },
    resetTimeStatusData: (state) => {
      state.timeData = STATUS.IDLE;
    },
    handleTimscaleDate: (state, action) => {
      state.timescaleDate = action.payload;
    },
    resetBlock: (state) => {
      state.blocks = {};
    },
  },
  extraReducers(builder) {
    builder.addCase(GetUserModels.fulfilled, (state, action) => {
      state.models = action.payload;
    });
    builder.addCase(DuplicateUserModels.fulfilled, (state, action) => {
      state.models = action.payload?.all_models_data;
    });
    builder.addCase(AddModel.fulfilled, (state, action) => {
      const models = current(state.models) || [];
      state.models = [action.payload, ...models];
    });
    builder.addCase(UpdateModel.fulfilled, (state, action) => {
      const models = current(state.models) || [];
      const index = models?.findIndex((item: any) => item?.id === action.payload?.id);
      if (index !== -1) {
        state.models[index] = action.payload;
      }
    });
    builder.addCase(UpdateModelTime.fulfilled, (state, action) => {
      const timeProperties = action.payload?.time_properties;
      state.blocks = { ...state.blocks, time_properties: timeProperties };
      state.timeData = STATUS.SUCCESS;
    });
    builder.addCase(UpdateModelTime.rejected, (state) => {
      state.timeStatus = true;
      state.timeData = STATUS.FAILED;
    });
    builder.addCase(FetchModelBlocks.fulfilled, (state, action) => {
      state.blocks = action.payload;
    });
    builder.addCase(AddModelBlock.fulfilled, (state, action) => {
      const currentBlocks = current(state.blocks) || [];
      state.blocks = { ...currentBlocks, blocks: action.payload.blocks };
    });
    builder.addCase(DeleteModelsBlock.fulfilled, (state, action) => {
      const blocks = current(state.blocks) || [];
      const currentBlocks = current(state.blocks?.blocks) || [];
      const index = currentBlocks?.findIndex((item: any) => item?.id === action.payload?.id);
      if (index !== -1) {
        const updatedBlocks = [...currentBlocks.slice(0, index), ...currentBlocks.slice(index + 1)];
        state.blocks = { ...blocks, blocks: updatedBlocks };
      }
    });
    builder.addCase(DuplicateModelsBlock.fulfilled, (state, action) => {
      const blocks = current(state.blocks) || [];
      const updatedBlocks = {
        ...blocks,
        blocks: [...(blocks?.blocks ?? []), action.payload],
      };
      state.blocks = updatedBlocks;
    });
  },
});

export const getModels = (state: any) => state.modelsSlice.models;
export const getDuplicateModels = (state: any) => state.modelsSlice.duplicateModels;
export const getTimeStatus = (state: any) => state.modelsSlice.timeStatus;
export const getTimeDataStatus = (state: any) => state.modelsSlice.timeData;
export const getBlocks = (state: any) => state.modelsSlice.blocks;
export const getTimescaleDate = (state: any) => state.modelsSlice.timescaleDate;

export const {
  StartDuplicateModel,
  StopDuplicateModel,
  resetTimeStatus,
  handleTimscaleDate,
  resetBlock,
  resetTimeStatusData,
} = modelsSlice.actions;

export default modelsSlice.reducer;
