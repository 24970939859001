/* eslint-disable unicorn/prefer-array-index-of */
import * as React from 'react';
import { Box, Flex, Text, Stack, Input, Button, Checkbox } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FixedSizeList as List } from 'react-window';

import DimensionTabIcon from 'components/Icons/ModelOverviewTabs/DimensionTabIcon';
import { getDimensionFilters, handleDimFiltersChange } from 'redux/ItemPageSlice';

const height = 700;
const itemHeight = 30;
const width = '100%';
const FiltersCheckBox = ({ checkboxData, handleFilterChanges, cancelFilter }: any) => {
  const dispatch: any = useDispatch();
  const [searchValue, setSearchValue] = useState<string | undefined>();

  const filters = useSelector(getDimensionFilters);

  const findIndex = filters?.findIndex((item: any) => item?.id === String(checkboxData?.id));

  const [ischeckedItemsChecked, setCheckedItemsChecked] = useState(
    filters[findIndex]?.data
      ? filters[findIndex]?.data?.map((item: any) => {
          return { name: item?.name, status: item?.status, search: true };
        })
      : [],
  );

  const allChecked = ischeckedItemsChecked.every((item: any) => item.status);
  const isIndeterminate = ischeckedItemsChecked.some((item: any) => item.status) && !allChecked;

  const getVisibleItems = (data: any) => {
    const filteredItems = data
      .map((item: any) => (item.search ? item : null))
      .filter((item: any) => item !== null);
    return filteredItems;
  };

  useEffect(() => {
    if (searchValue) {
      const searchTerm = searchValue.toLowerCase();
      const updatedItems = ischeckedItemsChecked?.map((item: any) => ({
        ...item,
        search: item?.name?.toLowerCase()?.includes(searchTerm),
      }));
      setCheckedItemsChecked(updatedItems);
    } else {
      const updatedItems = ischeckedItemsChecked?.map((item: any) => ({
        ...item,
        search: true,
      }));
      setCheckedItemsChecked(updatedItems);
    }
  }, [searchValue]);

  const handleCheckBoxChange = (user: any, event: any) => {
    setCheckedItemsChecked((previousState: any) => {
      const newState = previousState.map((item: any) =>
        item.name === user ? { ...item, status: event.target.checked } : item,
      );
      return newState;
    });
  };
  const handleAllCheckBoxChange = (event: any) => {
    const allDimension = ischeckedItemsChecked?.map((item: any) => {
      return {
        name: item?.name,
        status: event.target.checked,
        search: true,
      };
    });
    setCheckedItemsChecked(allDimension);
  };
  const handleCancelFilter = () => cancelFilter();
  const handleApplyFilter = async () => {
    const modifiedData = ischeckedItemsChecked?.map((item: any) => ({
      ...item,
      search: true,
    }));

    setCheckedItemsChecked(modifiedData);
    dispatch(
      handleDimFiltersChange({ ischeckedItemsChecked: modifiedData, id: checkboxData?.id }),
    ).then(() => {
      handleFilterChanges(modifiedData);
    });
    handleCancelFilter();
  };

  const Row = React.memo((properties: any) => {
    const { index, style, data } = properties;

    return (
      <>
        {data[index]?.search && (
          <Box style={style} key={index} onMouseMove={(event: any) => event.stopPropagation()}>
            <Checkbox
              pl={6}
              variant={'filters'}
              isChecked={data[index]?.status}
              onChange={(event) => {
                handleCheckBoxChange(data[index]?.name, event);
              }}
            >
              <Text maxW={'100px'} className='text-overflow' size={'Roboto-14'}>
                {data[index]?.name}
              </Text>
            </Checkbox>
          </Box>
        )}
      </>
    );
  });

  return (
    <Stack
      onMouseMove={(event: any) => event.stopPropagation()}
      minW={'200px'}
      maxW={'200px'}
      position={'relative'}
    >
      <Box
        position={'sticky'}
        top={'0'}
        backgroundColor={'white'}
        zIndex={1}
        display={'flex'}
        flexDirection={'column'}
      >
        <Flex>
          <DimensionTabIcon color={'#4BB6B9'} />
          <Text variant={'primary_sub_heading'}>{checkboxData?.text} </Text>
        </Flex>
        <Input
          my={3}
          placeholder='Search'
          height={7}
          fontSize={'14px'}
          onChange={(event: any) => setSearchValue(event.target.value)}
        />
        {!searchValue && (
          <Checkbox
            variant={'filters'}
            className='filters-all-checkbox'
            isChecked={allChecked}
            isIndeterminate={isIndeterminate}
            onChange={handleAllCheckBoxChange}
          >
            Select all
          </Checkbox>
        )}
      </Box>
      <Stack maxH={'150px'} overflowX={'auto'}>
        <List
          height={height}
          itemData={getVisibleItems(ischeckedItemsChecked)}
          itemCount={getVisibleItems(ischeckedItemsChecked)?.length}
          itemSize={itemHeight}
          width={width}
          className='filters_scroll'
        >
          {Row}
        </List>
      </Stack>
      <Flex>
        <Button variant={'cancel_button'} onClick={handleCancelFilter} mr={3}>
          Cancel
        </Button>
        <Button variant={'save_button'} onClick={handleApplyFilter}>
          Apply
        </Button>
      </Flex>
    </Stack>
  );
};

export default FiltersCheckBox;
