interface Properties {
  width?: number;
  height?: number;
  color?: string;
  direction?: boolean;
}

const ChevronDownIconComponent = ({
  width = 18,
  height = 20,
  color = 'white',
  direction = false,
}: Properties) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 14 8'
      transform={direction ? 'rotate(270)' : ''}
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.96515 7.77315L0.630371 1.74832L2.10849 0.342529L6.96515 4.96156L11.8218 0.342529L13.2999 1.74832L6.96515 7.77315Z'
        fill={color}
        fillOpacity='0.3'
      />
    </svg>
  );
};

export default ChevronDownIconComponent;
