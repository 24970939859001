import { useState, useEffect } from 'react';

export interface Properties {
  data: Array<any>;
}

export const useItemMapping = ({ data }: Properties): boolean => {
  const [allMapped, setAllMapped] = useState(false);

  useEffect(() => {
    if (data) {
      const allMappedStatus = data.every((item: any) => !item || Object.values(item)[0] !== null);
      setAllMapped(allMappedStatus);
    } else {
      setAllMapped(true);
    }
  }, [data]);

  return allMapped;
};
