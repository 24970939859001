import {
  Box,
  Divider,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import chartImage from 'assets/blox_model_icon.png';
import sizes from 'styles/theme/sizes';
import {
  AddModel,
  DeleteUserModels,
  DuplicateUserModels,
  getModels,
  GetUserModels,
  RenameUserModels,
  StartDuplicateModel,
  StopDuplicateModel,
  UpdateModel,
} from 'redux/ModelsSlice';
import BoxShimmer from 'components/FullScreenLoader/BoxShimmer';
import { getUserSubscriptionLimits, SetModelLimit } from 'redux/UserSlice';
import PlanPermission from 'container/PlanPermission';
import DeleteModal from 'components/Modals/DeleteModal';
import InfoIconComponent from 'components/Icons/InfoIcon';
import { AppDispatch } from 'utils/GlobalHelpers';
import CreateModelModal from 'components/Modals/CreateModelModal';

import DesktopModelFooter from './DesktopModelFooter';
import DesktopModelUnderLine from './DesktopModelUnderLine';

interface Properties {
  model: any;
  isListMode?: boolean;
  planPermission: boolean;
}

const DesktopModel = ({ model, planPermission, isListMode = false }: Properties) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [menu, setMenu]: any = useState('');
  const [addModel, setAddModel] = useState({ type: '', status: false, modelData: {} });

  const models = useSelector(getModels);
  const permissionData = useSelector(getUserSubscriptionLimits);

  const onCardClick = (event: any) => {
    if (event.ctrlKey) {
      // Open the link in a new window
      window.open(`/model/${model.id}/home`, '_blank');
    } else {
      // Navigate within the same window
      navigate(`/model/${model.id}/home`);
    }
  };

  const onOpenEditMode = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsEditMode((previousState) => !previousState);
  };

  const onBlur = () => {
    setIsEditMode(false);
  };

  const onOpenDeleteModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsDeleteModal(true);
  };

  const onDeleteModel = async () => {
    setIsDeleteModal(false);
    await dispatch(DeleteUserModels({ modelId: model.id }));
    dispatch(GetUserModels({}));
  };

  const onRenameModel = async (value: string) => {
    setIsEditMode(false);
    await dispatch(
      RenameUserModels({
        model_id: model.id,
        request_body: { name: value },
        toaster: true,
      }),
    );
    dispatch(GetUserModels({}));
  };

  const copyModel = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (
      permissionData?.subscription_features_limit?.No_of_models &&
      !(permissionData?.subscription_features_limit?.No_of_models > models?.length)
    ) {
      dispatch(SetModelLimit(true));
    } else {
      dispatch(
        StartDuplicateModel({
          id: '',
          name: '',
        }),
      );
      dispatch(
        DuplicateUserModels({
          modelId: model?.id,
          modelName: { name: `${model?.name} - Copy`, all_models_data: true },
          toaster: true,
        }),
      ).then(() => {
        dispatch(StopDuplicateModel());
      });
    }
  };

  const handleMenuClick = (event: any) => {
    event.stopPropagation();
  };

  const handleAddModel = (form: any, shouldUpdate: boolean, modalData: any) => {
    setAddModel({ modelData: {}, type: '', status: false });
    if (!shouldUpdate) {
      dispatch(
        AddModel({
          data: form,
          toaster: { successMessage: 'Added successfully', errorMessage: true },
        }),
      );
    } else {
      dispatch(UpdateModel({ modelId: modalData?.id, data: form, toaster: true }));
    }
  };

  return (
    <>
      <Box marginTop='0 !important'>
        {model?.id ? (
          <Flex
            direction='column'
            position='relative'
            alignItems='center'
            w='fit-content'
            h='fit-content'
            justifyContent='center'
          >
            <Flex
              position='relative'
              padding='0 !important'
              direction='column'
              w='270px'
              minH={isListMode ? '70px' : '215px'}
              align='center'
              justify='space-between'
              py={isListMode ? '2' : '4'}
              px='2'
              pb={1}
              borderRadius='sm'
              borderWidth='1'
              borderColor='grey.900'
              bg='white.900'
              cursor='pointer'
              zIndex={model?.id === menu ? 4 : 3}
              onClick={!isEditMode ? onCardClick : undefined}
            >
              {!isListMode && (
                <Image
                  style={{
                    height: '130px',
                    width: '100%',
                    borderRadius: '10px 10px 0 0',
                    objectFit: 'contain',
                  }}
                  src={model.icon || chartImage}
                  mb='2'
                />
              )}

              <Flex direction='column' w='full' flex='1' padding={'5px 10px'}>
                <Flex w='full'>
                  {isEditMode ? (
                    <Editable
                      w='full'
                      defaultValue={model.name}
                      onSubmit={(event) => {
                        if (event.trim() !== '') onRenameModel(event);
                      }}
                      startWithEditView={isEditMode}
                      onBlur={onBlur}
                      mb='2'
                      h='6'
                      fontFamily='bold_roboto'
                      fontSize='11px'
                      lineHeight='13px'
                      color='black.900'
                    >
                      <EditablePreview w='full' />
                      <EditableInput
                        _focusVisible={{
                          h: 'full',
                          px: '1',
                          borderWidth: '3',
                          borderRadius: 'xs',
                          borderColor: 'blue.500',
                        }}
                        required
                      />
                    </Editable>
                  ) : (
                    <Tooltip label={model.name} borderRadius='4' fontSize='3xs'>
                      <Text
                        variant='secondary'
                        mb='2'
                        fontFamily='bold_roboto'
                        fontSize='11px'
                        lineHeight='13px'
                        noOfLines={2}
                        w='full'
                      >
                        {model.name}
                      </Text>
                    </Tooltip>
                  )}
                  <Menu
                    closeOnSelect={true}
                    onOpen={() => setMenu(model?.id)}
                    onClose={() => setMenu('')}
                    variant='primary'
                    placement='bottom-end'
                  >
                    <MenuButton onClick={(event: any) => handleMenuClick(event)}>
                      <InfoIconComponent color='#BDBCD0' />
                    </MenuButton>
                    <MenuList minWidth={'160px !important'} zIndex={5}>
                      <MenuItem onClick={onOpenEditMode} isDisabled={isEditMode || planPermission}>
                        Rename
                      </MenuItem>
                      <MenuItem onClick={copyModel} isDisabled={planPermission}>
                        Duplicate
                      </MenuItem>
                      <MenuItem onClick={onOpenDeleteModal} isDisabled={planPermission}>
                        Delete
                      </MenuItem>
                      <MenuItem
                        onClick={(event: any) => {
                          event.stopPropagation();
                          setAddModel({
                            type: 'edit',
                            status: true,
                            modelData: {
                              id: model?.id,
                              name: model?.name,
                              description: model?.description,
                              icon: model?.icon,
                              createdAt: model?.date_created,
                              ownerImage: model?.owner?.profile_image_url,
                              ownerName: `${model?.owner?.firstname} ${model?.owner?.lastname}`,
                            },
                          });
                        }}
                        isDisabled={planPermission}
                      >
                        Properties
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>

                <Flex justify='space-between' alignItems='flex-start' align='flex-end' mt='auto'>
                  <Text
                    fontFamily='regular_roboto'
                    fontSize='11px'
                    lineHeight='13px'
                    color='grey.900'
                  >
                    {` Created: ${dayjs(model.date_created).format('D MMM, YYYY')}`}
                  </Text>
                </Flex>
              </Flex>
              <Divider w='270px' borderColor='grey' mt={sizes[1]} mb={sizes[1]} />
              <DesktopModelFooter model={model} isEditMode={isEditMode} />
            </Flex>
            {model.blocks.length > 1 && <DesktopModelUnderLine />}
          </Flex>
        ) : (
          <BoxShimmer
            width='270px'
            minH={isListMode ? '70px' : '215px'}
            height={isListMode ? '82.75px' : '220px'}
            isList={isListMode}
          />
        )}

        {isDeleteModal && (
          <DeleteModal
            isOpen={isDeleteModal}
            onClose={() => setIsDeleteModal(false)}
            itemName={model.name}
            onDelete={onDeleteModel}
          />
        )}
      </Box>

      <CreateModelModal
        modalData={addModel.modelData}
        modalType={addModel.type}
        isOpen={addModel.status}
        onClose={() => setAddModel({ status: false, type: '', modelData: {} })}
        onSave={handleAddModel}
      />
    </>
  );
};

export default PlanPermission(DesktopModel);
