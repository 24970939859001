import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
  marginLeft?: string;
}

const BlocksTabIcon = ({ width = 5, height = 6, color = '#4BB6B9' }: Properties) => {
  return (
    <Icon viewBox='0 0 21 31' width={width} height={height}>
      <path
        d='M14.9758 9.54673H11.5076V5.23138C11.506 3.92523 10.9858 2.67302 10.0612 1.74943C9.13652 0.825844 7.88288 0.306274 6.57522 0.304688H0.0683594V24.4191C0.0702654 25.9562 0.682493 27.4297 1.77073 28.5165C2.85896 29.6033 4.33434 30.2145 5.87317 30.2161H15.0598C16.5667 30.2145 18.0115 29.6159 19.077 28.5515C20.1425 27.4872 20.7418 26.0442 20.7434 24.539V15.3089C20.7419 13.7814 20.1337 12.3169 19.0525 11.2366C17.9712 10.1564 16.5051 9.54864 14.9758 9.54673ZM2.25492 2.48873H6.57522C7.30348 2.48937 8.00176 2.77845 8.51694 3.29259C9.03213 3.80673 9.32215 4.50396 9.32342 5.23138V9.54673H2.25372L2.25492 2.48873ZM2.25492 11.7296H9.30422V18.7876H2.25372L2.25492 11.7296ZM5.87437 28.032C4.91473 28.0311 3.99466 27.6499 3.31608 26.9721C2.63751 26.2943 2.25587 25.3753 2.25492 24.4167V20.9764H9.30422V28.0344L5.87437 28.032ZM18.5593 24.539C18.5583 25.4657 18.1893 26.3543 17.5333 27.0096C16.8772 27.6649 15.9877 28.0335 15.0598 28.0344H11.5076V20.9764H18.5593V24.539ZM11.5472 18.7852L17.5344 12.8048C18.1912 13.4722 18.5593 14.3707 18.5593 15.3065V18.7828L11.5472 18.7852Z'
        fill={color}
        fillOpacity={color === 'white' ? '0.5' : '1'}
      />
    </Icon>
  );
};

export default BlocksTabIcon;
