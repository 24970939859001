import { Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BreadCrumbComponent from 'components/BreadCrumb';
import { GetUserModels } from 'redux/ModelsSlice';
import { getPlannerMode, handlePlannerMode } from 'redux/PlannerModeSlice';
import Switch from 'components/Switch';

const NavigationHeader = () => {
  const [mobileVersion] = useMediaQuery('(max-width: 1024px)');
  const dispatch: any = useDispatch();
  const plannerMode = useSelector(getPlannerMode);

  useEffect(() => {
    dispatch(GetUserModels({}));
  }, []);

  return (
    <Flex
      h={mobileVersion ? '40px' : '34px'}
      justifyContent={'space-between'}
      textAlign={'center'}
      height={'1rem'}
    >
      <BreadCrumbComponent hasBlock={true} />
      {!mobileVersion && (
        <Flex alignItems={'center'}>
          <Text whiteSpace={'nowrap'} mr={3}>
            Builder mode
          </Text>
          <Switch
            isSelected={plannerMode}
            handleOnChange={(event: any) => {
              dispatch(handlePlannerMode(event.target.checked));
            }}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default NavigationHeader;
