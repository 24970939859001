const numericStyles = { '&[data-is-numeric=true]': { textAlign: 'end' } };

export default {
  variants: {
    base: {
      table: {},
      thead: {
        position: 'sticky',
        top: 0,
        h: 5,
        bgColor: 'green.800',
        zIndex: 20,
      },
      th: {
        ...numericStyles,
        px: 1,
        py: 1,
        fontSize: { base: '3xs', md: 'xs' },
        lineHeight: 1,
        textAlign: 'end',
        color: 'white.900',
        _first: {
          position: 'sticky',
          left: 0,
          bgColor: 'green.800',
          zIndex: 10,
        },
      },
      tbody: {
        bgColor: 'white.900',
        tr: {
          position: 'relative',
          _notLast: {
            borderBottomWidth: 1,
            borderBottomColor: 'grey.800',
          },
          _last: { h: 7 },
        },
      },
      td: {
        ...numericStyles,
        p: 1,
        fontSize: { base: '3xs', md: 'xs' },
        lineHeight: 1,
        textAlign: 'end',
        color: 'black.900',
        _first: {
          position: 'sticky',
          left: 0,
          textAlign: 'start',
          cursor: 'pointer',
          bgColor: 'white.900',
          bgClip: 'padding-box',
          pr: '2',
          minWidth: '100px',
        },
      },
    },
    dimension: {
      thead: {
        position: 'sticky',
        top: 0,
        bgColor: { base: 'violet.500', md: 'violet.800' },
      },
      th: {
        px: 0,
        py: 3,
        fontFamily: 'bold_roboto',
        fontSize: '10',
        lineHeight: 4,
        textTransform: 'none',
        color: 'white.900',
        _last: { textAlign: 'end' },
      },
      tbody: {
        tr: {
          borderBottomWidth: 1,
          borderBottomColor: 'white.400',
          _hover: {
            td: {
              _first: {
                '& .pc_icon': {
                  opacity: 1,
                  _hover: {
                    fill: 'white.900',
                    cursor: 'pointer',
                  },
                },
              },
            },
          },
        },
        td: {
          px: 0,
          py: 2.5,
          fontFamily: 'regular_roboto',
          fontSize: '2xs',
          lineHeight: 3,
          textTransform: 'none',
          color: 'white.900',
          _first: {
            '& .pc_icon': {
              opacity: 0,
              fill: 'violet.900',
            },
            '& .mobile_icon': {
              opacity: 1,
              fill: 'violet.900',
            },
          },
          _last: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          },
        },
      },
    },
  },
  defaultProps: { variant: 'base' },
};
