import { Flex, Text } from '@chakra-ui/react';
import MultiRangeSlider from 'multi-range-slider-react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import '../Slider.css';
import {
  getCompareMaxSliderValue,
  getCompareMinSliderValue,
  handleCompareMinMaxSliderValue,
} from 'redux/ScenarioSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

export interface Properties {
  labels: string[];
}

const CompareCustomRangeSlider = ({ labels }: Properties) => {
  const minValue = useSelector(getCompareMinSliderValue);
  const maxValue = useSelector(getCompareMaxSliderValue);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(100);

  const dispatch: AppDispatch = useDispatch();
  const handleInput = (event: any) => {
    if (event.minValue !== minValue || event.maxValue !== maxValue) {
      dispatch(handleCompareMinMaxSliderValue({ min: event.minValue, max: event.maxValue }));
    }
  };

  return (
    <>
      <MultiRangeSlider
        style={{
          paddingBottom: '5px',
          outline: 'none',
          boxShadow: 'none',
          border: 'none',
          color: 'black',
          fontSize: '12px',
          whiteSpace: 'nowrap',
          paddingTop: '12px',
        }}
        className='multi-range-slider'
        labels={labels}
        min={0}
        max={labels.length - 1}
        step={1}
        ruler={false}
        stepOnly={false}
        label={false}
        minCaption={labels[min]}
        maxCaption={labels[max]}
        minValue={minValue}
        maxValue={labels.length < maxValue ? labels.length - 1 : maxValue}
        onChange={(event: any) => {
          if (event) handleInput(event);
        }}
        onInput={(event: any) => {
          if (event) {
            setMin(event.minValue);
            setMax(event.maxValue);
          }
        }}
        barLeftColor={'#transparent'}
        barRightColor={'transparent'}
        barInnerColor={'#dedcdc00'}
        thumbLeftColor={'white'}
        thumbRightColor={'white'}
      />
      <Flex justifyContent='space-between'>
        <Text color='black' fontSize='10px'>
          {labels[minValue]}
        </Text>
        <Text color='black' fontSize='10px'>
          {labels[maxValue]}
        </Text>
      </Flex>
    </>
  );
};

export default CompareCustomRangeSlider;
