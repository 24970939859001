export default {
  variants: {
    primary: {
      padding: '.5rem 1rem',
      border: '1px #E23F3F solid',
      color: '#E23F3F',
      backgroundColor: '#F6D2D2',
    },
  },
};
