import { Image, StyleProps } from '@chakra-ui/react';

import AccountCircle from 'assets/demo_user_border_icon.png';

interface Properties {
  width?: string;
  height?: string;
  color?: string;
  styles?: StyleProps;
}

const LightUserAvatar = ({ width = '70', height = '70', styles }: Properties) => {
  return <Image src={AccountCircle} w={width} h={height} {...styles} />;
};

export default LightUserAvatar;
