import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
}

const Reset = ({ width = 4, height = 4 }: Properties) => {
  return (
    <Icon viewBox='0 0 13 15' width={width} height={height}>
      <path
        d='M5.55989 14.9851C3.99054 14.7905 2.69044 14.1062 1.65959 12.9322C0.628231 11.7587 0.112549 10.3808 0.112549 8.79846C0.112549 7.94245 0.281157 7.12197 0.618374 6.33704C0.95559 5.55262 1.43548 4.86859 2.05803 4.28494L3.16695 5.39387C2.6741 5.83484 2.30134 6.34715 2.04869 6.9308C1.79552 7.51444 1.66893 8.137 1.66893 8.79846C1.66893 9.93981 2.03209 10.9481 2.7584 11.8233C3.48471 12.699 4.41854 13.2342 5.55989 13.4287V14.9851ZM7.11628 14.9851V13.4287C8.24466 13.2212 9.17511 12.6829 9.90765 11.814C10.6407 10.945 11.0072 9.93981 11.0072 8.79846C11.0072 7.50147 10.5533 6.39903 9.6454 5.49114C8.73751 4.58325 7.63507 4.12931 6.33809 4.12931H6.27972L7.13573 4.98532L6.04626 6.07479L3.32259 3.35111L6.04626 0.627441L7.13573 1.71691L6.27972 2.57292H6.33809C8.07605 2.57292 9.54813 3.17602 10.7543 4.38222C11.9605 5.58842 12.5636 7.0605 12.5636 8.79846C12.5636 10.3678 12.0482 11.7395 11.0174 12.9135C9.98599 14.0871 8.68563 14.7776 7.11628 14.9851Z'
        fill='white'
      />
    </Icon>
  );
};

export default Reset;
