import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  AddDimensionItem,
  FetchPropertyValue,
} from 'models/requestBody/addDimensionItem.interface';
import { UpdateDimensionItem } from 'models/requestBody/updateDimensionItem.interface';
import { UpdatePropertyValues } from 'models/requestBody/updatePropertyValues.interface';
import { STATUS } from 'projectConstants';
import apiService from 'services';

const initialState: any = {
  propertyValue: [],
  propertyValueStatus: STATUS.IDLE,
  editNameMode: {
    state: false,
    itemId: null,
  },
};

export const fetchPropertyValue = createAsyncThunk(
  'dimensionDetail/fetch',
  async ({ propertyId, parameters }: FetchPropertyValue, thunkAPI) => {
    try {
      const response = await apiService.get_dimension_property_values(propertyId, {
        params: parameters,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const addDimesionItem = createAsyncThunk(
  'addDimesionItem/update',
  async ({ dimension_id, request_body }: AddDimensionItem, thunkAPI) => {
    try {
      const response = await apiService.add_dimension_item({ dimension_id, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const deleteDimensionItem = createAsyncThunk(
  'deleteDimensionItem/delete',
  async ({ itemId, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.delete_dimension_item(itemId, { params: parameters });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const updatePropertyValue = createAsyncThunk(
  'updatePropertyValue/update',
  async ({ item_id, request_body }: UpdatePropertyValues, thunkAPI) => {
    try {
      const response = await apiService.update_property_values({ item_id, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const updateDimensionItem = createAsyncThunk(
  'updateDimensionItem/update',
  async ({ item_id, request_body }: UpdateDimensionItem, thunkAPI) => {
    try {
      const response = await apiService.update_dimension_item({ item_id, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

const dimensionPageSlice = createSlice({
  initialState,
  name: 'dimensionPageSlice',
  reducers: {
    setEditNameMode: (state, action) => {
      state.editNameMode = action.payload;
    },
    resetDimension: (state) => {
      state.propertyValue = [];
      state.propertyValueStatus = STATUS.IDLE;
      state.editNameMode = {
        state: false,
        itemId: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPropertyValue.fulfilled, (state, action) => {
        state.propertyValue = action.payload;
        state.propertyValueStatus = STATUS.SUCCESS;
      })
      .addCase(addDimesionItem.fulfilled, (state, action) => {
        const response = action.payload;
        state.editNameMode = { state: true, itemId: response.id };
      })
      .addCase(deleteDimensionItem.fulfilled, () => {})
      .addCase(updatePropertyValue.fulfilled, () => {})
      .addCase(updateDimensionItem.fulfilled, () => {});
  },
});
export const { setEditNameMode, resetDimension } = dimensionPageSlice.actions;

export const getPropertyValues = (state: any) => state.dimensionPageSlice.propertyValue;

export const getPropertyValueStatus = (state: any) => state.dimensionPageSlice.propertyValueStatus;
export const getEditMode = (state: any) => state.dimensionPageSlice.editNameMode;
export default dimensionPageSlice.reducer;
