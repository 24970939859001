import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const ArrowRightTable = ({ width = 3, height = 3, color = 'white.900' }: Properties) => {
  return (
    <Icon viewBox='0 0 14 11' width={width} height={height} color={color} mr='1'>
      <path
        d='M8.78186 10.3467L7.57332 9.09978L10.6378 6.04689L0.149414 6.04689L0.149414 4.32695L10.6378 4.32695L7.57332 1.27406L8.78186 0.0270996L13.9613 5.18692L8.78186 10.3467Z'
        fill='white'
      />
    </Icon>
  );
};

export default ArrowRightTable;
