/* eslint-disable array-callback-return */
import * as React from 'react';
import { Flex, Stack, Box, Text, Input, Checkbox, Button } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import DimensionTabIcon from 'components/Icons/ModelOverviewTabs/DimensionTabIcon';
import { AppDispatch } from 'utils/GlobalHelpers';
import {
  updateChartFilter,
  fetchDashboardChart,
  handleAddAllChartData,
  handlePieDimFilter,
  getAllChartData,
  gePieDimFilter,
} from 'redux/DashboardSlice';

interface Properties {
  handleClose: any;
  handleApply: any;
  clearButton?: any;
  checkboxData?: any;
  isMenuOpen?: any;
  dimItems?: any;
  chartType?: any;
  pieGraphData?: any;
}

const PreviewSelectCheckBox = ({
  handleClose,
  pieGraphData,
  handleApply,
  clearButton,
  chartType,
  checkboxData,
  dimItems,
  isMenuOpen,
}: Properties) => {
  const dispatch: AppDispatch = useDispatch();

  const allChartData = useSelector(getAllChartData);
  const [searchValue, setSearchValue]: any = useState('');
  const [selectAll, setSelectAll]: any = useState(true);
  const pieDimeFilter = useSelector(gePieDimFilter);

  const [ischeckedItemsChecked, setCheckedItemsChecked] = useState(
    checkboxData?.dimension_items?.map((item: any) => {
      return {
        name: item?.dimension_item_name,
        status: chartType === 'pie' || chartType === 'bar',
        search: true,
      };
    }),
  );
  useEffect(() => {
    if (chartType === 'pie' || chartType === 'bar') {
      const FilterCheckedItems = ischeckedItemsChecked?.map((item1: any) => {
        const item2 = pieDimeFilter?.find((item: any) => item.name === item1.name);
        return item2 ? { ...item1, status: item2.status } : item1;
      });
      setCheckedItemsChecked(FilterCheckedItems);
    } else {
      setCheckedItemsChecked(
        checkboxData?.dimension_items?.map((item: any) => {
          return {
            name: item?.dimension_item_name,
            status: item?.dimension_item_name === dimItems?.item_name,
            search: true,
          };
        }),
      );
    }
  }, [checkboxData, pieGraphData, dimItems]);

  useEffect(() => {
    if (!isMenuOpen && searchValue) {
      setSearchValue('');
    }
  }, [isMenuOpen]);

  useEffect(() => {
    if (clearButton) {
      setCheckedItemsChecked(
        checkboxData?.dimension_items?.map((item: any) => {
          return { name: item?.dimension_item_name, status: false, search: true };
        }),
      );
    }
  }, [clearButton]);

  useEffect(() => {
    if (searchValue) {
      const searchTerm = searchValue.toLowerCase();
      const updatedItems = ischeckedItemsChecked?.map((item: any) => ({
        ...item,
        search: item.name.toLowerCase().includes(searchTerm),
      }));
      setCheckedItemsChecked(updatedItems);
    } else {
      const updatedItems = ischeckedItemsChecked?.map((item: any) => ({
        ...item,
        search: true,
      }));
      setCheckedItemsChecked(updatedItems);
    }
  }, [searchValue]);

  const handleCheckBoxChange = (user: any, event: any) => {
    if (chartType === 'kpi') {
      if (checkboxData?.chart_id) {
        const payload = {
          id: checkboxData?.id,
          name: checkboxData?.dimension_name,
          operator: 'eq',
          filter_type: 'dimension',
          value: user,
          chart_id: checkboxData?.chart_id,
        };
        dispatch(updateChartFilter(payload)).then(() => {
          dispatch(fetchDashboardChart(checkboxData?.chart_id)).then((data: any) => {
            const updatedChartData = allChartData[data?.payload.id];
            const layoutChartData = {
              id: updatedChartData.id,
              x: Number(updatedChartData.x),
              y: Number(updatedChartData.y),
              h: updatedChartData.h || 2,
              w: updatedChartData.w || 2,
              chart_type: 'kpi',
            };
            dispatch(handleAddAllChartData({ ...data.payload, ...layoutChartData }));
          });
        });
      }
      const find = ischeckedItemsChecked.findIndex((item: any) => item.name === user);
      setCheckedItemsChecked((previousState: any) => {
        const newState = [...previousState];
        if (find !== -1) {
          newState[find].status = event.target.checked;
        }
        newState.forEach((item: any, index: number) => {
          if (index !== find) {
            item.status = false;
          }
        });
        return newState;
      });
    } else if (chartType === 'pie' || chartType === 'bar') {
      const find = ischeckedItemsChecked.findIndex((item: any) => item.name === user);
      setCheckedItemsChecked((previousState: any) => {
        const newState = [...previousState];
        newState[find].status = event.target.checked;
        return newState;
      });
    }
  };
  useEffect(() => {
    const isSelectAll = ischeckedItemsChecked?.some((item: any) => item.status === false);
    setSelectAll(!isSelectAll);
  }, [ischeckedItemsChecked]);

  const handleSelectAllChekbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedItemsChecked((previousState: any) => {
      const newState = previousState?.map((item: any) => {
        return {
          ...item,
          status: event.target.checked,
        };
      });
      return newState;
    });
  };

  const handleApplyFilter = () => {
    dispatch(handlePieDimFilter(ischeckedItemsChecked));
    handleApply();
  };
  const handleCloseMenu = () => {
    handleClose();
  };

  return (
    <Stack minW={'200px'} maxW={'200px'} position={'relative'}>
      <Box position={'sticky'} top={'0'} backgroundColor={'white'} zIndex={1}>
        <Flex>
          <DimensionTabIcon width={7} height={6} color={'#4BB6B9'} />
          <Text variant={'primary_sub_heading'}>{checkboxData?.dimension_name}</Text>
        </Flex>
        {ischeckedItemsChecked?.length > 0 && (
          <React.Fragment>
            <Input
              my={3}
              placeholder='Search'
              height={7}
              fontSize={'14px'}
              value={searchValue}
              onChange={(event: any) => setSearchValue(event.target.value)}
            />
          </React.Fragment>
        )}
        {chartType === 'pie' || chartType === 'bar' ? (
          <Flex ms={2}>
            <Checkbox isChecked={selectAll} onChange={handleSelectAllChekbox} variant={'filters'}>
              Select All
            </Checkbox>
          </Flex>
        ) : (
          <></>
        )}
      </Box>
      {ischeckedItemsChecked?.length > 0 ? (
        <>
          <Stack maxH={'150px'} overflowX={'auto'} className={'filters_scroll'}>
            {ischeckedItemsChecked?.map((user: any, index: number) => (
              <React.Fragment key={index}>
                {user?.search && (
                  <Checkbox
                    pl={6}
                    variant={'filters'}
                    isChecked={ischeckedItemsChecked[index]?.status}
                    onChange={(event) => {
                      handleCheckBoxChange(user?.name, event);
                    }}
                  >
                    <Text maxW={'100px'} className='text-overflow' size={'Roboto-14'}>
                      {user?.name}
                    </Text>
                  </Checkbox>
                )}
              </React.Fragment>
            ))}
          </Stack>
          <Flex justifyContent={'flex-end'}>
            <Button onClick={handleCloseMenu} variant={'cancel_button'} mr={3}>
              Cancel
            </Button>
            <Button onClick={handleApplyFilter} variant={'save_button'}>
              Apply
            </Button>
          </Flex>
        </>
      ) : (
        <Box
          display={'flex'}
          flexDirection={'column'}
          justifyContent={'center'}
          alignContent={'center'}
        >
          <Text>No Dimension items</Text>
        </Box>
      )}
    </Stack>
  );
};

export default PreviewSelectCheckBox;
