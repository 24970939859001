// Cases:
// 1. While dispatching if you send as dispatch(actionName({payload, toaster: true})): then your toaster message will be picked from api success/error message.
// 2. While dispatching if you send as dispatch(actionName({payload, toaster: {successMessage: true}})): then your toaster message will be picked only from api success not error message.
// 3. While dispatching if you send as dispatch(actionName({payload, toaster: {successMessage: 'Hey, Show me'}})): then your toaster message will be picked only from successMessage not api response.
// 4. While dispatching if you send as dispatch(actionName({payload, toaster: {errorMessage: true}})): then your toaster message will be picked only from api error message.
// 5. While dispatching if you send as dispatch(actionName({payload, toaster: {errorMessage: 'Hey, Show me'}})): then your toaster message will be picked only from errorMessage not api response.
// 6. While dispatching if you send as dispatch(actionName({payload, toaster: {successMessage: 'Hey, Show me', errorMessage: true}})): then if your api reponse is success then toast will say : 'Hey, show me' and if api response is error then message from api's error response will be shown.
// 7. While dispatching if you send as dispatch(actionName({payload, toaster: {successMessage: true, errorMessage: 'show me}})): then if your api reponse is success then toast will show api's success message and if api response is error then 'show me' will be shown.
// 8. While dispatching if you send as dispatch(actionName({payload, toaster: {successMessage: 'Hey, Show me', errorMessage: 'Show me as well'}})): then if api response is success then we'll show 'Hey, show me' and if api response is error then we'll show 'Show me as well'
// 9. While dispatching if you send as dispatch(actionName({payload, toaster: {successMessage: true, errorMessage: true}})): then if api response is success then api's success message will be shown and if api response is error then api's error message will be shown

import { capitalizeFirstLetter } from 'utils/GlobalHelpers';

import { hideToast, showToast } from '../SharedSlice';

const ToasterMiddleware = (store: any) => (next: any) => async (action: any) => {
  if (action.type.endsWith('/fulfilled') && action?.meta?.arg?.toaster) {
    const id = Date.now().toString();

    const apiSuccessMessage = action?.payload?.message;
    const userSuccessMessage = action?.meta?.arg?.toaster?.successMessage;

    const toastMessage =
      typeof action?.meta?.arg?.toaster === 'boolean'
        ? apiSuccessMessage
        : typeof action?.meta?.arg?.toaster?.successMessage === 'string'
        ? userSuccessMessage
        : typeof action?.meta?.arg?.toaster?.successMessage === 'boolean'
        ? apiSuccessMessage
        : '';

    if (toastMessage) {
      store.dispatch(
        showToast({
          id,
          message: capitalizeFirstLetter(toastMessage) || 'Successfully Updated',
          status: 'success',
        }),
      );

      setTimeout(() => {
        store.dispatch(hideToast(id));
      }, 3000);
    }
  } else if (action.type.endsWith('/rejected') && action?.meta?.arg?.toaster) {
    const id = Date.now().toString();

    const apiErrorMessage = action?.payload?.message;
    const userErrorMessage = action?.meta?.arg?.toaster?.errorMessage;

    const toastMessage =
      typeof action?.meta?.arg?.toaster === 'boolean'
        ? apiErrorMessage
        : typeof action?.meta?.arg?.toaster?.errorMessage === 'string'
        ? userErrorMessage
        : typeof action?.meta?.arg?.toaster?.errorMessage === 'boolean'
        ? apiErrorMessage
        : '';
    if (toastMessage) {
      store.dispatch(
        showToast({
          id,
          message: capitalizeFirstLetter(toastMessage) || 'Internal server error',
          status: 'error',
        }),
      );

      setTimeout(() => {
        store.dispatch(hideToast(id));
      }, 3000);
    }
  }

  return next(action);
};

export default ToasterMiddleware;
