import React, { useRef, useState } from 'react';
import {
  Flex,
  Text,
  Menu,
  Tooltip,
  Image,
  MenuList,
  MenuButton,
  MenuItem,
  useOutsideClick,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import InputField from 'components/InputField';
import { getBaseScenario } from 'redux/ScenarioSlice';
import { fetchBlockDrivers, getSelectedDriver } from 'redux/PlanPageSlice';
import { AppDispatch } from 'utils/GlobalHelpers';
import {
  DeleteGroupDimName,
  DeleteGroupItemName,
  fetchDimensionItemsGroup,
  UpdateGroupDimName,
  UpdateGroupItemName,
} from 'redux/ItemPageSlice';
import DeleteGroupModal from 'components/DeleteGroupModal';

import infoIcon from '../Icons/infoIcon.svg';

interface Properties {
  planPermission: boolean;
  longClickCheck: any;
  isLongClicked: boolean;
  refetchRequest: () => void;
  dataKey: any;
}

const DesktopItemHeader = ({
  longClickCheck,
  dataKey,
  isLongClicked,
  planPermission,
}: Properties) => {
  const { blockId }: any = useParams();
  const dispatch: AppDispatch = useDispatch();

  const reference = useRef() as React.MutableRefObject<HTMLInputElement>;

  const baseScenario = useSelector(getBaseScenario);
  const currentDriver = useSelector(getSelectedDriver);

  const [renameGroup, setRenameGroup]: any = useState(null);
  const [displayGroupInfo, setDisplayGroupInfo]: any = useState(null);
  const [renameGroupValue, setRenameGroupValue] = useState<string>('');
  const [isDeleteModal, setIsDeleteModal] = useState({
    status: false,
    id: null,
    type: '',
    name: '',
  });

  const UpdateItemApi = async (item_id: Number, type: string) => {
    const requestBody = { name: renameGroupValue, scenario_id: baseScenario?.id };
    if (type === 'item') {
      await dispatch(
        UpdateGroupItemName({
          item_id,
          requestBody,
          toaster: {
            successMessage: 'Successfully Updated',
            errorMessage: 'An item with this name already exists',
          },
        }),
      );
      await dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));

      await setRenameGroup(null);
    }
    if (type === 'dimension') {
      await dispatch(
        UpdateGroupDimName({
          item_id,
          requestBody,
          toaster: {
            successMessage: 'Successfully Updated',
            errorMessage: 'An item with this name already exists',
          },
        }),
      );
      await dispatch(
        fetchBlockDrivers({
          blockId,
          parameters: {
            scenario_id: baseScenario?.id,
          },
        }),
      );
      await dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));

      setRenameGroup(null);
    }
  };

  const onKeyPressAction = async (event: any, item_id: number, type: string) => {
    if (event.code === 'Enter') {
      await setRenameGroup(item_id);
      await UpdateItemApi(item_id, type);
    }
    if (event.code === 'Escape') await setRenameGroup(0);
  };

  const toggleInfoPopup = (id: number | null) => {
    if (displayGroupInfo === id) {
      return setDisplayGroupInfo(null);
    }
    return setDisplayGroupInfo(id);
  };

  const renameItem = (event: any, id: number) => {
    event.stopPropagation();
    setDisplayGroupInfo(null);
    if (renameGroup === id) {
      return setRenameGroup(0);
    }
    return setRenameGroup(id);
  };

  const onOpenDeleteModal = (event: any, name: any, id: any, type: any) => {
    event.stopPropagation();
    setIsDeleteModal({ status: true, id, type, name });
  };

  useOutsideClick({
    ref: reference,
    handler: () => setDisplayGroupInfo(null),
  });

  const DeleteDimItem = async () => {
    if (isDeleteModal.type === 'dimension') {
      await dispatch(DeleteGroupDimName(isDeleteModal.id));
      await dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));
      setIsDeleteModal({ status: false, id: null, type: '', name: '' });
    }
    if (isDeleteModal.type === 'item') {
      await dispatch(
        DeleteGroupItemName({
          ItemId: isDeleteModal.id,
          parameters: { scenario_id: baseScenario?.id },
        }),
      );
      await dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));
      setIsDeleteModal({ status: false, id: null, type: '', name: '' });
    }
  };

  const onDeleteModel = () => {
    DeleteDimItem();
  };

  return (
    <>
      <Flex
        justifyContent='space-between'
        alignItems='center'
        width={'100%'}
        pt={1}
        paddingBottom='1.5rem'
      >
        <Flex
          justifyContent='space-between'
          w='100%'
          alignItems='center'
          {...longClickCheck()}
          onDoubleClick={(event) => {
            setRenameGroup(dataKey?.dim_id);
            event.stopPropagation();
          }}
        >
          {renameGroup === dataKey?.dim_id ? (
            <InputField
              width={'fit-content'}
              outline={'none !important'}
              boxShadow={'none !important'}
              border={'1px white solid !important'}
              padding={'15px 5px 15px 10px'}
              height={'28px'}
              fontSize={'16px !important'}
              fontFamily={'Roboto Bold'}
              defaultValue={dataKey?.name}
              clickedOutside={() => {
                setRenameGroup(0);
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setRenameGroupValue(event.target.value);
              }}
              onKeyDown={(event: React.ChangeEvent<HTMLInputElement>) => {
                onKeyPressAction(event, dataKey?.dim_id, dataKey?.type);
              }}
              _placeholder={{ color: '#ffffff66' }}
              autoFocus
            />
          ) : (
            <Tooltip label={dataKey?.name}>
              <Text
                fontFamily='Roboto Regular'
                fontSize='22px'
                lineHeight='9'
                width={'90%'}
                maxW={'100%'}
                cursor='pointer'
                overflow={'hidden'}
                textOverflow={'ellipsis'}
                noOfLines={isLongClicked ? 0 : 1}
              >
                {dataKey?.name}
              </Text>
            </Tooltip>
          )}
        </Flex>
        <Flex alignItems='center' position='relative' ref={reference}>
          <Menu isOpen={displayGroupInfo === dataKey?.dim_id}>
            <MenuButton
              width={'auto'}
              marginRight='5px'
              cursor={planPermission ? 'not-allowed' : 'pointer'}
              onClick={
                planPermission
                  ? undefined
                  : (event) => {
                      event.stopPropagation();
                      toggleInfoPopup(dataKey?.dim_id);
                    }
              }
            >
              <Image src={infoIcon} w='28px' height='8px' className='infoButton' />
            </MenuButton>
            <MenuList>
              <MenuItem
                onClick={(event) => renameItem(event, dataKey?.dim_id)}
                padding='.5rem 1.5rem'
              >
                Rename
              </MenuItem>
              <MenuItem
                onClick={(event) => {
                  onOpenDeleteModal(event, dataKey?.name, dataKey?.dim_id, dataKey?.type);
                }}
                padding='.5rem 1.5rem'
              >
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Flex>
      {isDeleteModal && (
        <DeleteGroupModal
          isOpen={isDeleteModal.status}
          onClose={() => setIsDeleteModal({ status: false, id: null, type: '', name: '' })}
          itemName={isDeleteModal.name}
          onDelete={onDeleteModel}
        />
      )}
    </>
  );
};

export default DesktopItemHeader;
