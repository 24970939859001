import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  Flex,
  useMediaQuery,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getBlockMetadata,
  groupByFilterChange,
  overallGroupByFilter,
  setEmptyData,
} from 'redux/PlanPageSlice';
import { getCompareScenario } from 'redux/ScenarioSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

const GroupByFilter = () => {
  const dispatch: AppDispatch = useDispatch();
  const selectedGroupBy = useSelector(overallGroupByFilter);
  const metadata = useSelector(getBlockMetadata);
  const compareScenario = useSelector(getCompareScenario);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const handleChange = (value: string) => {
    if (selectedGroupBy !== value) {
      dispatch(setEmptyData(true));
      dispatch(groupByFilterChange(value));
    }
  };

  const displayName = () => {
    const dimension: any = metadata.dimensions.find((dim: any) => dim.id === selectedGroupBy);
    if (!dimension) return 'Total';
    return dimension.name;
  };

  return (
    <Menu>
      <MenuButton
        height={isMobile ? '25px' : '30px'}
        paddingRight='2'
        paddingLeft={'2'}
        as={Button}
        rightIcon={<ChevronDownIcon fontSize={'sm'} />}
        width='fit-content'
        textTransform='none'
        fontWeight='400'
        fontSize={isMobile ? 'xs' : 'sm'}
        color={'white'}
        border='none'
        bg='transaparant'
        boxShadow='none'
        isDisabled={compareScenario}
      >
        <Flex alignItems={'center'}>
          <Text size={'Roboto-12'} color={'rgba(255, 255, 255, 0.6)'} mr={2}>
            Group by:
          </Text>
          <Text size={'Roboto-12'}>{displayName()}</Text>
        </Flex>
      </MenuButton>
      <MenuList zIndex={'dropdown'} maxW='fit-content' borderRadius={'10px'}>
        <MenuOptionGroup type='radio' value={String(selectedGroupBy)}>
          <MenuItemOption
            key={'Total'}
            minH='30px'
            value={'Total'}
            onClick={() => handleChange('Total')}
            isChecked={selectedGroupBy === 'Total'}
            data-testid='GroupByfilter-test'
          >
            <Text
              fontFamily='regular_roboto'
              fontSize={isMobile ? '2xs' : 'xs'}
              textAlign={'start'}
            >
              Total
            </Text>
          </MenuItemOption>
          {metadata.dimensions.map((dimension: any, index: number) => {
            return (
              <MenuItemOption
                key={index}
                minH='30px'
                value={String(dimension?.id)}
                onClick={() => handleChange(dimension?.id)}
                isChecked={selectedGroupBy === dimension?.id}
                data-testid={`groupBy-filter-${dimension?.name}`}
              >
                <Text
                  fontFamily='regular_roboto'
                  fontSize={isMobile ? '2xs' : 'xs'}
                  textAlign={'start'}
                >
                  {dimension?.name}
                </Text>
              </MenuItemOption>
            );
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

export default GroupByFilter;
