import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const EditPencilIcon = ({ width = 5, height = 5, color = 'green.900' }: Properties) => {
  return (
    <Icon viewBox='0 0 21 23' width={width} height={height} color={color}>
      <path
        d='M2.39746 18.3099V15.2674H17.6843V18.3099H2.39746ZM3.92614 13.7461V10.8937L10.8434 4.00988L13.7097 6.86228L6.79243 13.7461H3.92614ZM5.45483 12.2248H6.14274L11.5696 6.86228L10.8434 6.13968L5.45483 11.5402V12.2248ZM14.5696 6.02558L11.7033 3.17318L13.0791 1.80403C13.2193 1.6519 13.3976 1.579 13.6142 1.58534C13.8307 1.59168 14.0091 1.66457 14.1492 1.80403L15.9454 3.59153C16.0856 3.73098 16.1556 3.90529 16.1556 4.11447C16.1556 4.32365 16.0856 4.5043 15.9454 4.65643L14.5696 6.02558Z'
        fill='white'
        fillOpacity='0.4'
      />
    </Icon>
  );
};

export default EditPencilIcon;
