import { Flex, Button, useMediaQuery, Image } from '@chakra-ui/react';
import Dropzone from 'react-dropzone';

import DeleteIcon from 'components/Icons/DeleteIcon';
import { handleFileUpload, handleFileDelete } from 'services/s3Service';
import LightUserAvatar from 'components/Icons/LightUserAvatar';
import { acceptedFormats } from 'utils/FileFormats';

const dndStyle: any = {
  width: '160px',
  height: '8',
  borderRadius: '10px',
  fontSize: 'xs',
  color: '#5850a1',
  fontWeight: '500',
  marginRight: '10px',
  padding: '2px',
  boxShadow: '0px 0px 0px 1.5px #8B8AAE',
  cursor: 'pointer',
  textAlign: 'center',
};

interface Properties {
  type: string;
  imageUrl: string;
  setFormValue: (value: string) => void;
  imageLoading: boolean;
  handleImageLoading: (value: boolean, data?: any) => void;
  handleImageDelete: () => void;
  bucketName: string;
  disabled?: boolean;
}

const UserImage = ({
  type,
  imageUrl,
  setFormValue,
  handleImageLoading,
  handleImageDelete,
  bucketName,
  disabled,
}: Properties) => {
  const [isLaptop] = useMediaQuery('(max-width: 1024px)');
  const handleDrop = async (file: any) => {
    handleImageLoading(true);
    const data = await handleFileUpload(file[0], bucketName);
    handleImageLoading(false, data);
    setFormValue(data);
  };
  const handleDelete = async (file: any) => {
    await handleFileDelete(file, bucketName);
    handleImageDelete();
  };
  return (
    <Flex
      width={isLaptop ? 'fit-content' : '30%'}
      flexDirection={'column'}
      ml={!isLaptop ? '8' : '0'}
      alignItems={'center'}
    >
      {imageUrl ? (
        <Image
          boxShadow={'1px 1px 10px #d9d9d9'}
          src={imageUrl}
          borderRadius={'50%'}
          width={isLaptop ? '100px' : '160px'}
          height={isLaptop ? '100px' : '160px'}
          mb='4'
        />
      ) : (
        <LightUserAvatar
          height={isLaptop ? '100px' : '160px'}
          width={isLaptop ? '100px' : '160px'}
          styles={{ marginBottom: '.5rem' }}
        />
      )}
      <Flex
        width={'100%'}
        mt='4'
        alignItems={'center'}
        justifyContent={isLaptop ? 'flex-start' : 'center'}
        mb={isLaptop ? '4' : ''}
        flexDir={'column'}
      >
        <Dropzone onDrop={handleDrop} multiple={false} disabled={disabled} accept={acceptedFormats}>
          {({ getRootProps, getInputProps }) => (
            <div
              style={{
                ...dndStyle,
                cursor: disabled ? 'not-allowed !important' : 'pointer',
                height: 'fit-content',
              }}
              {...getRootProps({ className: disabled ? 'disabled' : 'dropzone' })}
            >
              <input {...getInputProps()} />
              {type === 'profile' ? 'Upload Profile' : 'Upload Logo'}
            </div>
          )}
        </Dropzone>
        <Button
          mt={'3'}
          rightIcon={<DeleteIcon height={15} width={15} color='#a9a8a8' />}
          sx={{
            cursor: disabled ? 'not-allowed !important' : 'pointer',
            color: 'grey.100',
            width: 'fit-content',
            boxShadow: 'none',
            height: 'fit-content',
          }}
          disabled={!imageUrl || disabled}
          onClick={() => {
            handleDelete(imageUrl);
          }}
          _disabled={{ boxShadow: '', opacity: '.5', cursor: 'not-allowed' }}
        >
          Remove
        </Button>
      </Flex>
    </Flex>
  );
};
export default UserImage;
