import { Text, Flex } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { getPlannerBlock } from 'redux/PlannerModeSlice';

import PlannerModeBlockSetting from './BlockSetting';
import PlannerModeScenarioForecast from './ScenarioForecast';

const PlannerModeHeader = () => {
  const plannerBlock = useSelector(getPlannerBlock);

  return (
    <Flex alignItems={'center'} gap={6} height={'28px'} my={7}>
      <Text size={'Comfortaa-28'}>{plannerBlock?.name}</Text>
      <PlannerModeScenarioForecast />
      <PlannerModeBlockSetting />
    </Flex>
  );
};

export default PlannerModeHeader;
