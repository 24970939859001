const LockOpenIcon = () => {
  return (
    <svg fill='#000000' height='25px' width='25px' version='1.1' id='Capa_1' viewBox='0 0 320 320'>
      <g>
        <path
          d='M160,103.695c-18.802,0-36.461,5.146-51.731,14.14V64.523c0-24.551,18.025-44.523,40.183-44.523h23.098
		c22.157,0,40.183,19.973,40.183,44.523c0,5.522,4.478,10,10,10s10-4.478,10-10C231.731,28.945,204.733,0,171.549,0h-23.098
		c-33.185,0-60.183,28.945-60.183,64.523v68.591c-20.308,19.738-33.011,47.731-33.011,78.733C55.258,271.483,102.245,320,160,320
		s104.742-48.517,104.742-108.152S217.755,103.695,160,103.695z M160,300c-46.727,0-84.742-39.545-84.742-88.152
		s38.016-88.152,84.742-88.152s84.742,39.545,84.742,88.152S206.727,300,160,300z'
        />
        <path
          d='M189.504,195.563c0-16.273-13.235-29.513-29.505-29.513c-16.268,0-29.503,13.239-29.503,29.513
		c0,8.318,3.452,16.06,9.343,21.557l-7.075,31.729c-0.159,0.715-0.239,1.444-0.239,2.177c0,8.667,8.488,15.202,19.744,15.202h15.467
		c11.254,0,19.74-6.535,19.74-15.202c0-0.732-0.08-1.462-0.24-2.177l-7.076-31.729C186.051,211.622,189.504,203.881,189.504,195.563
		z M153.84,246.227l6.159-27.622l6.161,27.622H153.84z M164.36,204.014c-1.944,1.01-3.443,2.591-4.361,4.455
		c-0.918-1.864-2.417-3.445-4.361-4.455c-3.171-1.647-5.142-4.886-5.142-8.451c0-5.245,4.263-9.513,9.503-9.513
		c5.241,0,9.505,4.268,9.505,9.513C169.504,199.127,167.533,202.365,164.36,204.014z'
        />
      </g>
    </svg>
  );
};
export default LockOpenIcon;
