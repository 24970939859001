import { Image, StyleProps } from '@chakra-ui/react';

import menu from 'assets/menu_icon.png';

interface Properties {
  width?: number | string;
  height?: number | string;
  styles?: StyleProps;
}

const HorizontalMenu = ({ width = 34, height = 27, styles }: Properties) => {
  return <Image src={menu} w={width} h={height} {...styles} />;
};

export default HorizontalMenu;
