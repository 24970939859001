interface Properties {
  width?: number;
  height?: number;
  color?: string;
  style?: React.CSSProperties;
}

const RightArrowIconComponent = ({
  width = 20,
  height = 20,
  color = 'black.900',
  style,
}: Properties) => {
  return (
    <svg
      width={width}
      height={height}
      fill={color}
      style={style}
      viewBox='0 0 20 10'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M15.0125 3.75H0V6.25H15.0125V10L20 5L15.0125 0V3.75Z' fill={color} />
    </svg>
  );
};

export default RightArrowIconComponent;
