import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import 'matchmedia-polyfill';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import AuthModal from 'components/AuthModal';
import FullScreenLoader from 'components/FullScreenLoader';
import HideIconComponent from 'components/Icons/HideIcon';
import ShowIconComponent from 'components/Icons/ShowIcon';
import NavigationHeader from 'pages/NavigationHeader';
import { HEADER_HEIGHT_WITH_NAVIGATION, STATUS } from 'projectConstants';
import { resetDimension } from 'redux/DimensionPageSlice';
import { resetIndicator } from 'redux/IndicatorPageSlice';
import { resetItem } from 'redux/ItemPageSlice';
import { GetUserModels } from 'redux/ModelsSlice';
import {
  ShowSidePanel,
  blockDetailStatus,
  blockDriversStatus,
  blockMetadataStatus,
  fetchAnalysisSectionData,
  fetchBlock,
  fetchBlockDrivers,
  fetchBlockMetadata,
  fetchModel,
  fetchTableData,
  getAppliedFilters,
  getBlockDriversData,
  getBlockMetadata,
  getDimension,
  handleSidePanel,
  inputsPageShow,
  modelDetailStatus,
  overallGroupByFilter,
  overallIndicatorFilter,
  overallTimeFilter,
  planPageShow,
  reset,
  setDriver,
  showAuthModel,
  toggleAuthModel,
} from 'redux/PlanPageSlice';
import {
  fetchAllScenarios,
  fetchCompareAnalysisSectionData,
  fetchCompareTableData,
  fetchScenarioStatus,
  getAllScenarios,
  getBaseScenario,
  getCompareScenario,
  resetScenario,
  selectBaseScenario,
  updateModelandBlock,
} from 'redux/ScenarioSlice';
import { GetInputAdjustments, resetDriverAdjustmentSlider } from 'redux/driverAdjustmentSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

import InputSection from './Inputs/InputsSection';
import MobileLayout from './MobileLayout';
import PlanSection from './Plan/planSection';
import PlanPageHeader from './PlanPageHeader';
import DriversSection from './driversSection';

const PlanPageScenario = () => {
  const { pathname } = useLocation();
  const { modelId, blockId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const reference = useRef() as React.MutableRefObject<HTMLInputElement>;
  const dimension = useSelector(getDimension);
  const showPlanPage = useSelector(planPageShow);
  const metadata = useSelector(getBlockMetadata);
  const showSidePanel = useSelector(ShowSidePanel);
  const baseScenario = useSelector(getBaseScenario);
  const showInputPage = useSelector(inputsPageShow);
  const isAuthModalOpen = useSelector(showAuthModel);
  const modelStatus = useSelector(modelDetailStatus);
  const blockStatus = useSelector(blockDetailStatus);
  const selectedYear = useSelector(overallTimeFilter);
  const blockDrivers = useSelector(getBlockDriversData);
  const driversStatus = useSelector(blockDriversStatus);
  const metadataStatus = useSelector(blockMetadataStatus);
  const compareScenario = useSelector(getCompareScenario);
  const inputAdjustments = useSelector(GetInputAdjustments);
  const selectedGroupBy = useSelector(overallGroupByFilter);
  const allScenarioStatus = useSelector(fetchScenarioStatus);
  const indicatorFilter = useSelector(overallIndicatorFilter);
  const scenarios = useSelector(getAllScenarios);
  const appliedFilters = useSelector(getAppliedFilters);

  const [isTablet] = useMediaQuery('(max-width: 768px)');
  const [isLaptop] = useMediaQuery('(max-width: 1024px)');

  const isLoading =
    modelStatus === STATUS.LOADING ||
    blockStatus === STATUS.LOADING ||
    driversStatus === STATUS.LOADING ||
    metadataStatus === STATUS.LOADING ||
    allScenarioStatus === STATUS.LOADING;

  const initialDataLoad =
    metadataStatus === STATUS.LOADING ||
    allScenarioStatus === STATUS.LOADING ||
    modelStatus === STATUS.LOADING ||
    blockStatus === STATUS.LOADING;

  const onToggleAuthModal = () => dispatch(toggleAuthModel());

  useEffect(() => {
    if (baseScenario) {
      const base = scenarios.find((scenario: any) => {
        return scenario.id === baseScenario.id;
      });

      if (base) {
        dispatch(selectBaseScenario(base));
      } else if (scenarios.length > 0) {
        const baseSec = scenarios.find((scenario: any) => {
          return scenario.is_base;
        });

        dispatch(selectBaseScenario(baseSec));
      }
    }
  }, [scenarios]);

  const refetchChartData = () => {
    if (modelId && blockId) {
      dispatch(
        fetchAnalysisSectionData({
          blockId: Number(blockId),
          parameters: {
            params: {
              dim_id:
                selectedGroupBy === 'Total'
                  ? null
                  : dimension && !selectedGroupBy
                  ? dimension?.id
                  : selectedGroupBy
                  ? Number.parseInt(selectedGroupBy, 10)
                  : null,
              dim_sort: 'ASC',
              time_filter: selectedYear || null,
              time_aggregation: 'Y,Q,M',
              indicator_filter: indicatorFilter,
              ...(Object.keys(inputAdjustments).length > 0 && {
                input_adjustments: inputAdjustments,
              }),
              scenario_id: baseScenario?.id,
              dim_filter: JSON.stringify(appliedFilters),
            },
          },
        }),
      );
    }
  };

  const refetchCompareChartData = () => {
    if (modelId && blockId) {
      dispatch(
        fetchCompareAnalysisSectionData({
          blockId: Number(blockId),
          parameters: {
            params: {
              dim_id:
                selectedGroupBy === 'Total'
                  ? null
                  : dimension && !selectedGroupBy
                  ? dimension?.id
                  : selectedGroupBy
                  ? Number.parseInt(selectedGroupBy, 10)
                  : null,
              dim_sort: 'ASC',
              time_filter: selectedYear || null,
              time_aggregation: 'Y,Q,M',
              indicator_filter: indicatorFilter,
              ...(Object.keys(inputAdjustments).length > 0 && {
                input_adjustments: inputAdjustments,
              }),
              scenario_id: baseScenario?.id,
              compare_scenario_id: compareScenario?.id,
              dim_filter: JSON.stringify(appliedFilters),
            },
          },
        }),
      );
    }
  };

  const refetchCompareTableData = () => {
    if (modelId && blockId) {
      dispatch(
        fetchCompareTableData({
          blockId: Number(blockId),
          parameters: {
            params: {
              dim_id:
                selectedGroupBy === 'Total'
                  ? null
                  : dimension && !selectedGroupBy
                  ? dimension?.id
                  : selectedGroupBy
                  ? Number.parseInt(selectedGroupBy, 10)
                  : null,
              dim_sort: 'ASC',
              time_aggregation: 'Y,Q,M',
              indicator_filter: indicatorFilter,
              ...(Object.keys(inputAdjustments).length > 0 && {
                input_adjustments: inputAdjustments,
              }),
              scenario_id: baseScenario?.id,
              compare_scenario_id: compareScenario?.id,
              dim_filter: JSON.stringify(appliedFilters),
            },
          },
        }),
      );
    }
  };

  const refetchTableData = () => {
    if (modelId && blockId) {
      dispatch(
        fetchTableData({
          blockId: Number(blockId),
          parameters: {
            params: {
              dim_id:
                selectedGroupBy === 'Total'
                  ? null
                  : dimension && !selectedGroupBy
                  ? dimension?.id
                  : selectedGroupBy
                  ? Number.parseInt(selectedGroupBy, 10)
                  : null,
              dim_sort: 'ASC',
              time_aggregation: 'Y,Q,M',
              indicator_filter: indicatorFilter,
              ...(Object.keys(inputAdjustments).length > 0 && {
                input_adjustments: inputAdjustments,
              }),
              scenario_id: baseScenario?.id,
              dim_filter: JSON.stringify(appliedFilters),
            },
          },
        }),
      );
    }
  };

  const onTogglePanel = async () => {
    if (showInputPage) {
      dispatch(setDriver(null));
    }
    dispatch(handleSidePanel());
  };

  const refetchRequest = () => {
    setTimeout(() => {
      if (!compareScenario) {
        refetchChartData();
        refetchTableData();
      } else {
        refetchCompareChartData();
        refetchCompareTableData();
      }
    }, 100);
  };

  useEffect(() => {
    dispatch(GetUserModels({}));
  }, []);

  useEffect(() => {
    dispatch(reset());
    dispatch(resetScenario());
    dispatch(resetDriverAdjustmentSlider());
    dispatch(setDriver(null));
    dispatch(resetItem());
    dispatch(resetDimension());
    dispatch(resetIndicator());
  }, [pathname]);

  useEffect(() => {
    if (modelId && blockId) {
      dispatch(updateModelandBlock({ modelId, blockId }));
      dispatch(fetchModel(Number(modelId)));
      dispatch(fetchBlock(Number(blockId)));
      dispatch(fetchAllScenarios(Number(modelId)));
      dispatch(
        fetchBlockDrivers({
          blockId,
          parameters: {
            scenario_id: baseScenario?.id,
          },
        }),
      );
    }
  }, [pathname]);

  useEffect(() => {
    if (blockId && baseScenario?.id) {
      dispatch(
        fetchBlockMetadata({
          blockId: Number(blockId),
          parameters: { scenario_id: baseScenario?.id },
        }),
      );
    }
  }, [baseScenario?.id]);

  useEffect(() => {
    if (baseScenario?.id) {
      dispatch(
        fetchBlockDrivers({
          blockId,
          parameters: {
            scenario_id: baseScenario?.id,
          },
        }),
      );
    }
  }, [!initialDataLoad]);

  useEffect(() => {
    if (!isLoading && !compareScenario) {
      refetchChartData();
    }
    if (!isLoading && compareScenario) {
      refetchCompareChartData();
    }
  }, [
    selectedYear,
    selectedGroupBy,
    indicatorFilter,
    inputAdjustments,
    baseScenario?.id,
    isLoading,
    compareScenario,
  ]);
  useEffect(() => {
    if (showPlanPage) refetchRequest();
  }, [showPlanPage]);

  useEffect(() => {
    if (!isLoading && !compareScenario) {
      refetchTableData();
    }

    if (!isLoading && compareScenario) {
      refetchCompareTableData();
    }
  }, [
    selectedGroupBy,
    indicatorFilter,
    inputAdjustments,
    metadata,
    baseScenario?.id,
    isLoading,
    compareScenario,
  ]);

  useEffect(() => {
    return () => {
      dispatch(reset());
      dispatch(resetScenario());
      dispatch(resetDriverAdjustmentSlider());
      dispatch(setDriver(null));
      dispatch(resetItem());
      dispatch(resetDimension());
      dispatch(resetIndicator());
    };
  }, []);

  if (isLoading) return <FullScreenLoader />;

  if (isLaptop) {
    return (
      <Box>
        <NavigationHeader />
        {blockDrivers?.length > 0 && <MobileLayout refetchRequest={refetchRequest} />}
        {blockDrivers?.length === 0 && <PlanSection refetchRequest={refetchRequest} />}
      </Box>
    );
  }

  return (
    <Box px={isTablet ? 4 : 7} py={4}>
      <>
        <NavigationHeader />
        <PlanPageHeader refetchRequest={refetchRequest} />
      </>
      {blockDrivers?.length > 0 ? (
        <Flex
          width={'100%'}
          className='scroll'
          h={`calc(100vh - ${HEADER_HEIGHT_WITH_NAVIGATION})`}
          borderTop={'2px #50489B solid'}
        >
          {/* Side Panel */}
          <Box position={'relative'} pr={showSidePanel ? 0 : 8}>
            {blockDrivers && (
              <Box position={'absolute'} right={showSidePanel ? '5px' : ''} top={'5px'} zIndex={1}>
                {showSidePanel ? (
                  <ShowIconComponent onClick={onTogglePanel} />
                ) : (
                  <HideIconComponent onClick={onTogglePanel} />
                )}
              </Box>
            )}
            {showSidePanel && (
              <Box
                width={{ base: '350px', '8xl': '450px !important' }}
                minW={{ base: '350px', '8xl': '450px !important' }}
              >
                <DriversSection refetchRequest={refetchRequest} />
              </Box>
            )}
          </Box>
          {showInputPage && (
            <Box
              minW={{ base: '350px', '8xl': '450px !important' }}
              ref={reference}
              background={'linear-gradient(180deg, #776ED0 0%, #776ED0 100%)'}
              transition={'.5s width ease-in-out'}
              width={showInputPage && showPlanPage ? '350px' : 'calc(100% - 350px)'}
            >
              <InputSection refetchRequest={refetchRequest} />
            </Box>
          )}
          {/* Plan Page */}
          {showPlanPage && (
            <Box width={'100%'}>
              {showInputPage ? (
                <motion.div
                  initial={{ opacity: 0, x: -100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: 100 }}
                  transition={{ duration: 1 }}
                >
                  <PlanSection refetchRequest={refetchRequest} />
                </motion.div>
              ) : (
                <motion.div
                  initial={{ opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -100 }}
                  transition={{ duration: 1 }}
                >
                  <PlanSection refetchRequest={refetchRequest} />
                </motion.div>
              )}
            </Box>
          )}
        </Flex>
      ) : (
        <Flex
          width={'fill-available'}
          className='scroll'
          h={`calc(100vh - ${HEADER_HEIGHT_WITH_NAVIGATION})`}
          justifyContent='space-around'
        >
          <PlanSection refetchRequest={refetchRequest} />
        </Flex>
      )}
      <AuthModal isOpen={isAuthModalOpen} onClose={onToggleAuthModal} />
    </Box>
  );
};

export default PlanPageScenario;
