import { useSelector } from 'react-redux';
import { Flex } from '@chakra-ui/react';

import { getFormValues } from 'redux/DataImportSlice';

import ColumnType from './MultipleIndicatorType/ColumnType';
import RowsType from './MultipleIndicatorType/RowsType';

const DataStepsFourth = () => {
  const getFormValue = useSelector(getFormValues);
  return (
    <Flex w={'full'}>
      {getFormValue?.multipleIndicatrosType === 'row' ? <RowsType /> : <ColumnType />}
    </Flex>
  );
};

export default DataStepsFourth;
