// eslint-disable-next-line consistent-return
const getFilterCondition = (timeGranularityFilter: string) => {
  if (timeGranularityFilter === 'M' || timeGranularityFilter === '') {
    return (entry: any) => entry.Time.length === 6;
  }
  if (timeGranularityFilter === 'Q') {
    return (entry: any) => entry.Time.includes('Q');
  }
  if (timeGranularityFilter === 'Y') {
    return (entry: any) => entry.Time.includes('Y') && !entry.Time.includes('Q');
  }
};
export default getFilterCondition;
