import { useSelector } from 'react-redux';
import { Flex } from '@chakra-ui/react';

import { getFormValues } from 'redux/DataImportSlice';

import RowTypeSecondStep from './MultipleIndicatorType/RowTypeSecondStep';
import SelectDimesnionColumn from './SelectDimesnionColumn';

const DataStepsFive = () => {
  const getFormValue = useSelector(getFormValues);
  const activeActionStep = useSelector((state: any) => state.dataImportSlice.actionSteps);
  return (
    <Flex w={'full'}>
      {getFormValue?.multipleIndicatrosType === 'row' &&
        getFormValue?.dataPeriod === '2' &&
        getFormValue?.selectedTimePeriod === 'row' &&
        getFormValue?.importindicatroType === '2' &&
        activeActionStep === 6 && <RowTypeSecondStep getFormValue={getFormValue} />}
      {getFormValue?.multipleIndicatrosType === 'row' &&
        getFormValue?.dataPeriod === '1' &&
        getFormValue?.importindicatroType === '2' &&
        activeActionStep === 5 && <RowTypeSecondStep getFormValue={getFormValue} />}

      {getFormValue?.multipleIndicatrosType === 'column' &&
        getFormValue?.dataPeriod === '2' &&
        getFormValue?.selectedTimePeriod === 'row' &&
        getFormValue?.importindicatroType === '2' &&
        activeActionStep === 6 && <SelectDimesnionColumn getFormValue={getFormValue} />}
      {getFormValue?.multipleIndicatrosType === 'row' &&
        getFormValue?.dataPeriod === '2' &&
        getFormValue?.selectedTimePeriod === 'column' &&
        getFormValue?.importindicatroType === '2' &&
        activeActionStep === 6 && <SelectDimesnionColumn getFormValue={getFormValue} />}

      {getFormValue?.multipleIndicatrosType === 'column' &&
        getFormValue?.dataPeriod === '2' &&
        getFormValue?.selectedTimePeriod === 'column' &&
        getFormValue?.importindicatroType === '2' &&
        activeActionStep === 6 && <SelectDimesnionColumn getFormValue={getFormValue} />}

      {getFormValue?.multipleIndicatrosType === 'column' &&
        getFormValue?.dataPeriod === '1' &&
        getFormValue?.importindicatroType === '2' &&
        activeActionStep === 5 && <SelectDimesnionColumn getFormValue={getFormValue} />}
    </Flex>
  );
};

export default DataStepsFive;
