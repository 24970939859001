import { ChevronDownIcon, InfoIcon } from '@chakra-ui/icons';
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ErrorMessage, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import {
  getNoheadersStatus,
  addStepTwoFormData,
  handleActionStepNext,
  getFormValues,
  getTableHeader,
  setSelectedAreas,
  clearStepperFormPropertyData,
  setNoheaders,
} from 'redux/DataImportSlice';
import { ActionSteptwoSchema } from 'validation/schemas/ActionStepSchema';
import { GetTableProperties } from 'redux/ItemPageSlice';
import CustomCheckboxIcon from 'components/Icons/CustomCheckboxIcon';
import { getBuilderDimensions } from 'redux/BuilderModeSlice';

import { textStyles } from '../../Helper';
import ActionStepFooter from '../ActionStepFooter';

interface Properties {
  setHelpModalSeleceted: any;
  dataType: string;
}

const DimensionType = ({ setHelpModalSeleceted, dataType }: Properties) => {
  const dispatch: any = useDispatch();
  const [selectedDimId, setSelectedDimId] = useState<number | null>();
  const getFormValue = useSelector(getFormValues);
  const dimesnsionList = useSelector(getBuilderDimensions);
  const headerNames = useSelector(getTableHeader);
  const [selectedDimensionDetails, setSelectedDimensionDetails] = useState<any>(null);
  const [customHeaders, setCustomHeaders] = useState<any[]>(headerNames ?? []);
  const isHeader = useSelector(getNoheadersStatus);
  useEffect(() => {
    if (isHeader) {
      const array: string[] = new Array(26)
        .fill(1)
        .map((_: string, index: number) => String.fromCodePoint(65 + index));
      const newHeaders = array
        .slice(0, headerNames.length)
        .map((item: string, headerIndex: number) => {
          return { current: `${headerNames[headerIndex]}`, modified: `Column ${item}` };
        });
      setCustomHeaders(newHeaders);
    } else {
      setCustomHeaders(
        headerNames.map((item: string) => {
          return { current: `${item}`, modified: `${item}` };
        }),
      );
    }
  }, [isHeader, headerNames]);

  useMemo(() => {
    const calculateSelectedDimensionDetails = dimesnsionList?.find((item: any) => {
      if (selectedDimId && !getFormValue.dimension && item?.id === selectedDimId) {
        return item;
      }
      if (
        getFormValue?.dimension &&
        !selectedDimId &&
        item?.id === Number(getFormValue?.dimension)
      ) {
        return item;
      }
      if (getFormValue?.dimension && selectedDimId && item?.id === selectedDimId) {
        return item;
      }
      return null;
    });
    setSelectedDimensionDetails(calculateSelectedDimensionDetails);
  }, [selectedDimId, getFormValue.dimension]);

  useEffect(() => {
    if (getFormValue.column) {
      dispatch(setSelectedAreas({ selectedColumnOption: getFormValue.column }));
      dispatch(GetTableProperties({ selectedId: selectedDimId || getFormValue?.dimension }));
    }
  }, [getFormValue.column, selectedDimId]);
  const handleSubmit = (form: any, setSubmitting: (data: boolean) => void) => {
    dispatch(addStepTwoFormData(form));
    setSubmitting(false);
    dispatch(handleActionStepNext());
  };

  const getSelectedColumnOption = (value: string) => {
    const data = customHeaders.find((item) => item.current === value);
    return data?.modified;
  };
  return (
    <VStack w={'100%'} pt={3}>
      <Formik
        initialValues={{
          selectedDataType: '' || dataType || getFormValue.dataType,
          selecetedDimension: '' || getFormValue.dimension,
          selectedColumnOption: '' || getFormValue.column,
        }}
        validationSchema={ActionSteptwoSchema}
        onSubmit={(form, { setSubmitting }) => handleSubmit(form, setSubmitting)}
        enableReinitialize={true}
      >
        {({ values, errors, isSubmitting, setFieldValue }: any) => {
          return (
            <Form style={{ width: '100%' }}>
              <VStack display='flex' justify={'center'} w={'100%'}>
                <VStack w={'100%'} justify={'center'}>
                  <ErrorMessage name='selectedDataType' component='div' className='error' />
                  {values.selectedDataType === '1' && (
                    <>
                      {values.selectedDataType && (
                        <FormControl mt={5}>
                          <FormLabel
                            fontSize='0.9rem'
                            color='black'
                            htmlFor='selecetDimension'
                            fontFamily={'Roboto Regular'}
                          >
                            Select the dimension you want to import to
                          </FormLabel>
                          <Menu closeOnSelect={true} isLazy>
                            <MenuButton
                              as={Button}
                              backgroundColor='white'
                              maxHeight={'40px'}
                              borderRadius='5px'
                              minWidth={'150px'}
                              textAlign='left'
                              padding='0 .8rem'
                              boxShadow={'rgb(139, 137, 177) 0px 0px 0px 0.6px'}
                              rightIcon={<ChevronDownIcon fontSize='md' />}
                              fontWeight='300'
                              fontSize='xs'
                              color='black.900'
                              w={'100%'}
                              border={
                                errors.selecetedDimension
                                  ? '2px solid #fe6e6e'
                                  : '0.6px solid #8B89B1'
                              }
                              disabled={isSubmitting}
                            >
                              {selectedDimensionDetails?.name || (
                                <Text opacity={0.5}>Choose dimension</Text>
                              )}
                            </MenuButton>
                            <MenuList
                              height={'fit-content'}
                              maxHeight={'20vh'}
                              overflowY={'auto'}
                              className='scroll'
                            >
                              <MenuOptionGroup value={values.selecetedDimension}>
                                <MenuItemOption value={''}>Choose dimension</MenuItemOption>
                                {dimesnsionList &&
                                  dimesnsionList?.map((item: any, index: number) => (
                                    <MenuItemOption
                                      key={index}
                                      value={item?.id}
                                      onClick={async () => {
                                        await setFieldValue('selecetedDimension', item?.id);
                                        await setSelectedDimId(item?.id);
                                        await dispatch(clearStepperFormPropertyData());
                                      }}
                                    >
                                      {item?.name}
                                    </MenuItemOption>
                                  ))}
                              </MenuOptionGroup>
                            </MenuList>
                          </Menu>
                          <ErrorMessage
                            name='selecetedDimension'
                            component='div'
                            className='error'
                          />
                        </FormControl>
                      )}
                      {values.selecetedDimension && (
                        <>
                          <FormControl mt={5} pt={2}>
                            <FormLabel
                              fontSize='0.9rem'
                              color='black'
                              htmlFor='selectColumnOption'
                              fontFamily={'Roboto Regular'}
                            >
                              Select the column in the table with the item names
                            </FormLabel>
                            <Menu closeOnSelect={true} isLazy>
                              <MenuButton
                                as={Button}
                                backgroundColor='white'
                                maxHeight={'40px'}
                                borderRadius='5px'
                                minWidth={'150px'}
                                textAlign='left'
                                padding='0 .8rem'
                                boxShadow={'rgb(139, 137, 177) 0px 0px 0px 0.6px'}
                                rightIcon={<ChevronDownIcon fontSize='md' />}
                                fontWeight='300'
                                fontSize='xs'
                                color='black.900'
                                w={'100%'}
                                border={
                                  errors.selectedColumnOption
                                    ? '2px solid #fe6e6e'
                                    : '0.6px solid #8B89B1'
                                }
                                disabled={isSubmitting}
                              >
                                {getSelectedColumnOption(values?.selectedColumnOption) || (
                                  <Text opacity={0.5}>Choose column</Text>
                                )}
                              </MenuButton>
                              <MenuList
                                height={'fit-content'}
                                maxHeight={'20vh'}
                                overflowY={'auto'}
                                className='scroll'
                              >
                                <MenuOptionGroup
                                  value={values.selectedColumnOption}
                                  onChange={async (role: any) => {
                                    await setFieldValue('selectedColumnOption', role);
                                    await dispatch(
                                      setSelectedAreas({ selectedColumnOption: role }),
                                    );
                                  }}
                                >
                                  <MenuItemOption value={''}>Choose column</MenuItemOption>
                                  {headerNames &&
                                    headerNames?.map((item: any, index: number) => (
                                      <MenuItemOption key={`${index}+${item}`} value={item}>
                                        {customHeaders[index]?.modified}
                                      </MenuItemOption>
                                    ))}
                                </MenuOptionGroup>
                              </MenuList>
                            </Menu>
                            <ErrorMessage
                              name='selectedColumnOption'
                              component='div'
                              className='error'
                            />
                          </FormControl>
                          <Flex gap={2} w={'100%'} pt={2}>
                            <Checkbox
                              className='checkboxStyle'
                              background={'transparent'}
                              borderColor={'black'}
                              border={'2px solid black'}
                              icon={<CustomCheckboxIcon isChecked={isHeader} />}
                              isChecked={isHeader}
                              onChange={() => {
                                dispatch(setNoheaders(!isHeader));
                              }}
                            ></Checkbox>
                            <Text fontSize='0.9rem' color='black' fontFamily={'Roboto Regular'}>
                              File has no column headers
                            </Text>
                          </Flex>
                        </>
                      )}
                    </>
                  )}
                </VStack>
                <Flex
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  pb={8}
                  pt='5'
                  w={'100%'}
                >
                  <Flex>
                    <Text
                      {...textStyles}
                      color={'black'}
                      textDecoration='underline'
                      onClick={() => {
                        setHelpModalSeleceted.on();
                      }}
                      display={'none'}
                    >
                      Help <InfoIcon color={'#8B89B1'} ms={3} />
                    </Text>
                  </Flex>
                  <ActionStepFooter
                    nextButtonName='Continue'
                    prevButtonName='Previous'
                    isSubmitting={isSubmitting}
                  />
                </Flex>
              </VStack>
            </Form>
          );
        }}
      </Formik>
    </VStack>
  );
};

export default DimensionType;
