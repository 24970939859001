import { Flex } from '@chakra-ui/react';

import Spinner from 'components/Spinner';

interface Properties {
  isGridElement?: boolean;
}

const FullScreenLoad = ({ isGridElement }: Properties) => {
  return (
    <>
      {isGridElement ? (
        <Flex
          h={'100vh'}
          position='absolute'
          align='center'
          left='50%'
          transform='translateX(-50%)'
        >
          <Spinner />
        </Flex>
      ) : (
        <Flex justify='center' align='center' h={'100vh'} bg={'gradient.body'}>
          <Spinner />
        </Flex>
      )}
    </>
  );
};

export default FullScreenLoad;
