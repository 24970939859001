import { Box, Flex, Skeleton } from '@chakra-ui/react';

interface Properties {
  key: number;
}

const SkeletonLoader = ({ key }: Properties) => {
  return (
    <Flex
      key={key}
      className='dimension'
      mr='15px'
      flexDirection='column'
      minW='310px'
      alignSelf='flex-start'
      alignItems='flex-start'
      backgroundColor='rgba(67, 64, 111, 0.5)'
      borderRadius='18px'
      mb='15px'
      padding='5px 10px 0 10px'
      maxWidth={{ base: '400px', md: '400px' }}
    >
      <Box width={'100%'} pt={1} paddingBottom='1.5rem'>
        <Skeleton height='20px' width='100%' mb={'1.5rem'} />
        <Skeleton height='62px' width='100%' mb={'10px'} />
        <Skeleton height='20px' width='100%' />
      </Box>
    </Flex>
  );
};

export default SkeletonLoader;
