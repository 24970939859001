import { Box } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { useLongPress } from 'use-long-press';
import { useState } from 'react';

import { getSelectedDriver } from 'redux/PlanPageSlice';

// import IndicatorPage from './IndicatorPage';
import DimesionPage from './DimensionPage';
import OrganisationType from './OrganisationType';
import IndicatorPageMultiDim from './IndicatorPageMultiDim';

export interface Properties {
  refetchRequest: () => void;
}

const InputSection = ({ refetchRequest }: Properties) => {
  const [isLongClicked, setIsLongClicked] = useState<boolean>(false);

  const longClickCheck = useLongPress(() => {
    setIsLongClicked((previousState: any) => !previousState);
  });

  const currentDriver = useSelector(getSelectedDriver);

  const onDataChange = () => refetchRequest();

  const showCurrentPage = () => {
    if (currentDriver?.type === 'dimension') {
      return (
        <>
          <OrganisationType
            longClickCheck={longClickCheck}
            isLongClicked={isLongClicked}
            refetchRequest={refetchRequest}
          />
        </>
      );
    }
    if (currentDriver?.type === 'indicator') {
      return <IndicatorPageMultiDim refetchRequest={onDataChange} />;
    }
    return <DimesionPage refetchRequest={onDataChange} />;
  };

  return (
    // <Box h={'100%'} overflowY='auto' className='scroll'>
    <Box h={'100%'} className='scroll'>
      <Box height={'100%'}>{showCurrentPage()}</Box>
    </Box>
  );
};

export default InputSection;
