interface Properties {
  width?: string | number;
  height?: string | number;
  color?: string;
}

const HomeIcon = ({ width = 15, height = 15, color = 'green.900' }: Properties) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='16 0 40 40'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M22.662 0.75L44.9268 20.4303H0L22.662 0.75Z' fill='white' />
      <rect x='6.56116' y='18.2902' width='32.7999' height='6.71249' fill='white' />
      <rect x='6.56116' y='23.8562' width='11.9272' height='15.2605' fill='white' />
      <rect x='27.4346' y='23.8562' width='11.9272' height='15.2605' fill='white' />
    </svg>
  );
};

export default HomeIcon;
