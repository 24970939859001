/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable consistent-return */
import { Box, Menu, Button, Text, Flex, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { GoChevronDown } from 'react-icons/go';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useMemo, useEffect } from 'react';
import moment from 'moment';

import RightArrowIconComponent from 'components/Icons/RightArrowIcon';
import {
  getWidgetData,
  addChartFilter,
  getDashboardChartId,
  updateChartFilter,
  fetchDashboardChart,
  handleTimeChange,
  getKpiChartData,
  fetchDashboardGraph,
  getDashboardSelectedScenario,
} from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';
import DatePickerInputButton from 'pages/ModelOverviewPage/DatePickerWithButton';
import { getBaseScenario, getAllScenarios } from 'redux/ScenarioSlice';
import { getTimescaleDate, getBlocks } from 'redux/ModelsSlice';
import {
  formateDate,
  getPreviousMonth,
  priorYear,
  getDate,
  totalMonthsGap,
  convertDate,
} from 'utils/ConvertDate';

interface TimeFilter {
  name: string;
  operator: any;
  value: any;
  second_value?: any;
  filter_type: string;
  chart_id: number;
  id?: string | number;
}

const DashboardModalTime = ({ chartFilter, isEditKpi }: any) => {
  const dispatch: AppDispatch = useDispatch();
  const baseScenario = useSelector(getBaseScenario);
  const getTimeDate = useSelector(getTimescaleDate);
  const startDateObject = new Date(baseScenario?.start_date);
  const kpiChartData = useSelector(getKpiChartData);
  const chartId = useSelector(getDashboardChartId);
  const widgetData = useSelector(getWidgetData);
  const models = useSelector(getBlocks);

  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const scenarioId = selectedScenario?.id;

  const [startIn, setStartIn] = useState<Date | any>(new Date());
  const [endIn, setEndIn] = useState<Date | any>(new Date());
  const [timescale, setTimescale] = useState('M');

  const [maxDate, setMaxDate] = useState<Date | null>(null);
  const [timeDetail, setTimeDetail] = useState({
    detail: widgetData.time.detail,
    start: startIn,
    end: endIn,
  });

  const letestActuals = useMemo(() => {
    startDateObject.setMonth(startDateObject.getMonth() - 1);
    const formatLeteastActuals = formateDate(startDateObject);
    return formatLeteastActuals;
  }, [startDateObject]);

  useMemo(() => {
    if (chartFilter && isEditKpi) {
      const starDate: any = getDate(chartFilter?.value);
      const endDate: any = getDate(chartFilter?.second_value);
      if (!endDate) {
        if (letestActuals === formateDate(starDate)) {
          setTimeDetail({ ...timeDetail, detail: 3 });
        } else {
          setTimeDetail({ ...timeDetail, detail: 2 });
        }
      }
      setStartIn(new Date(starDate));
      setEndIn(new Date(endDate));
    }
  }, [chartFilter]);

  const timeFilterId = useMemo(() => {
    if (kpiChartData) {
      const chartDataId = kpiChartData?.chart_filters
        ?.map((item: any) => {
          if (item.name === 'Time' && item.filter_type === 'primary') {
            return item.id;
          }
        })
        .filter((item: any) => item !== undefined);
      return chartDataId[0];
    }
    return null;
  }, [kpiChartData]);

  const comparisionTimeFilterId = useMemo(() => {
    if (kpiChartData) {
      const chartDataId = kpiChartData?.chart_filters
        ?.map((item: any) => {
          if (item.name === 'Time' && item.filter_type === 'comparision') {
            return item.id;
          }
        })
        .filter((item: any) => item !== undefined);
      return chartDataId[0];
    }
    return null;
  }, [kpiChartData]);

  useEffect(() => {
    const formattedStartDate = formateDate(getTimeDate?.startDate);
    const formattedEndDate = formateDate(getTimeDate?.endDate);
    setStartIn(new Date(getTimeDate?.startDate));
    setEndIn(new Date(getTimeDate?.endDate));
    if (widgetData.time.detail === 1) {
      dispatch(
        handleTimeChange({
          ...widgetData.time,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        }),
      );
    }
  }, [getTimeDate]);

  const updateTimeFilter = (payload: any, startDate: any) => {
    const forcastStartDate = new Date(getTimeDate?.startDate);
    const months = totalMonthsGap(forcastStartDate, new Date(startDate));
    const oneYearPrior = priorYear(new Date(startDate));
    const timeUpdatePayload = {
      ...payload,
      id: timeFilterId,
    };
    const comparisionFilter: TimeFilter = {
      name: 'Time',
      operator: 'between',
      value: convertDate(formateDate(oneYearPrior)),
      second_value: convertDate(formateDate(getPreviousMonth(new Date(startDate)))),
      filter_type: 'comparision',
      chart_id: chartId,
    };
    if (timeFilterId) {
      dispatch(updateChartFilter(timeUpdatePayload)).then(() => {
        dispatch(fetchDashboardChart(chartId));
        dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
        if (widgetData.comparisionTime.detail === 1 && months !== null && months >= 12) {
          dispatch(updateChartFilter({ ...comparisionFilter, id: comparisionTimeFilterId })).then(
            () => {
              dispatch(fetchDashboardChart(chartId));
            },
          );
        }
      });
    } else {
      dispatch(addChartFilter(payload)).then(() => {
        dispatch(fetchDashboardChart(chartId));
        dispatch(fetchDashboardGraph({ chartId, scenarioId, requestBody: [] }));
        if (widgetData.comparisionTime.detail === 1 && months !== null && months >= 12) {
          dispatch(addChartFilter(comparisionFilter)).then(() => {
            dispatch(fetchDashboardChart(chartId));
          });
        }
      });
    }
  };

  const handleTime = (timeDetails: any) => {
    if (timeDetails === 3) {
      setTimeDetail({ ...timeDetail, start: letestActuals, detail: timeDetails });
      dispatch(
        handleTimeChange({
          ...widgetData.time,
          startDate: letestActuals,
          detail: timeDetails,
        }),
      );
      if (chartId) {
        const payload = {
          name: 'Time',
          operator: 'eq',
          value: convertDate(letestActuals),
          chart_id: chartId,
          filter_type: 'primary',
        };
        updateTimeFilter(payload, new Date(widgetData.time.startDate));
      }
    } else {
      dispatch(
        handleTimeChange({
          ...widgetData.time,
          detail: timeDetails,
        }),
      );
      setTimeDetail({ ...timeDetail, detail: timeDetails });
    }
  };

  const onSelectStartDate = async (date: Date) => {
    await setStartIn(date);
    const formatStartDate = formateDate(date);
    const forcastStartDate = new Date(getTimeDate?.startDate);
    const months = totalMonthsGap(forcastStartDate, date);
    await setTimeDetail({ ...timeDetail, start: formatStartDate });
    await dispatch(handleTimeChange({ ...widgetData.time, startDate: formatStartDate }));
    if (chartId) {
      if (widgetData.time.detail !== 1) {
        const payload = {
          name: 'Time',
          operator: widgetData.time.detail === 1 ? 'between' : 'eq',
          value: convertDate(formatStartDate),
          chart_id: chartId,
          filter_type: 'primary',
        };
        await updateTimeFilter(payload, date);
      } else {
        const payload = {
          name: 'Time',
          operator: widgetData.time.detail === 1 ? 'between' : 'eq',
          value: convertDate(formatStartDate),
          second_value: convertDate(formateDate(endIn)),
          chart_id: chartId,
          filter_type: 'primary',
        };
        await updateTimeFilter(payload, date);
      }
    }
  };
  const onSelectEndDate = async (date: Date) => {
    await setEndIn(date);
    const formatEndDate = formateDate(date);
    await setTimeDetail({ ...timeDetail, end: formatEndDate });
    await dispatch(handleTimeChange({ ...widgetData.time, endDate: formatEndDate }));
    if (chartId) {
      if (widgetData.time.detail !== 1) {
        const payload = {
          name: 'Time',
          operator: widgetData.time.detail === 1 ? 'between' : 'eq',
          value: convertDate(formateDate(startIn)),
          chart_id: chartId,
          filter_type: 'primary',
        };
        await updateTimeFilter(payload, new Date(widgetData.time.startDate));
      } else {
        const payload = {
          name: 'Time',
          operator: widgetData.time.detail === 1 ? 'between' : 'eq',
          value: convertDate(formateDate(startIn)),
          second_value: convertDate(formatEndDate),
          chart_id: chartId,
          filter_type: 'primary',
        };
        await updateTimeFilter(payload, new Date(widgetData.time.startDate));
      }
    }
  };

  useEffect(() => {
    if (getTimeDate) {
      setStartIn(new Date(widgetData?.time.startDate));
      setEndIn(new Date(widgetData?.time.endDate));
    }
  }, [widgetData?.time]);

  useEffect(() => {
    const updatedMaxDate = new Date(
      moment(models?.time_properties?.starting_period)
        .add(models?.time_properties?.plan_duration_periods, 'months')
        .format('YYYY-MM-DD'),
    );

    setMaxDate(updatedMaxDate);
  }, [models?.time_properties?.starting_period]);
  return (
    <Flex flexDir={'column'}>
      <Text color={'black.900'} fontWeight={'600'} mb={2}>
        Time
      </Text>
      <Menu variant={'dashboardingModal'}>
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              padding={'.3rem .5rem'}
              variant={'dashboardingModal'}
              rightIcon={<GoChevronDown size={18} />}
            >
              {timeDetail.detail === 1
                ? 'Date Range'
                : timeDetail.detail === 2
                ? 'Single month'
                : 'Latest actuals'}
            </MenuButton>
            {isOpen && (
              <MenuList>
                <MenuItem onClick={() => handleTime(1)}>Date Range</MenuItem>
                <MenuItem onClick={() => handleTime(2)}>Single month</MenuItem>
                <MenuItem onClick={() => handleTime(3)}>Latest actuals</MenuItem>
              </MenuList>
            )}
          </>
        )}
      </Menu>
      {timeDetail.detail === 1 ? (
        <Flex mt={3} alignItems='center'>
          <DatePickerInputButton
            clearSection={false}
            minDate={new Date(models?.time_properties?.starting_period)}
            maxDate={maxDate}
            selected={startIn}
            onChange={onSelectStartDate}
            timescale={timescale}
            styles={{ borderColor: '#D9D9D9', backgroundColor: '#FAFAFA', color: '#787878' }}
          />
          <RightArrowIconComponent color='#787878' style={{ margin: '0 1rem' }} />
          <DatePickerInputButton
            clearSection={false}
            minDate={new Date(models?.time_properties?.starting_period)}
            maxDate={maxDate}
            selected={endIn}
            onChange={onSelectEndDate}
            timescale={timescale}
            styles={{ borderColor: '#D9D9D9', backgroundColor: '#FAFAFA', color: '#787878' }}
          />
        </Flex>
      ) : timeDetail.detail === 2 ? (
        <Box mt={3}>
          <DatePickerInputButton
            minDate={new Date(models?.time_properties?.starting_period)}
            maxDate={maxDate}
            clearSection={false}
            selected={startIn}
            onChange={onSelectStartDate}
            timescale={timescale}
            styles={{ borderColor: '#D9D9D9', backgroundColor: '#FAFAFA', color: '#787878' }}
          />
        </Box>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default DashboardModalTime;
