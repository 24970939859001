/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Chart as ReactChart } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';

import { NewDimItemData, NewTotalValues } from 'models/response/blockOutput.interface';
import { IndicatorDriver } from 'models/response/driver.interface';
import { CHART_COLORS } from 'projectConstants';
import {
  ShowSidePanel,
  getBlockDriversData,
  getChartFilter,
  getChartType,
  getPlanPageChartinnerWidth,
  getSelectedDriver,
  getValuesForSlider,
  inputsPageShow,
  planPageShow,
  setChartType,
  setPlanPageInnerWidth,
} from 'redux/PlanPageSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';
import getChartOptionsNew from 'utils/getChartOptionsNew';
import { AppDispatch } from 'utils/GlobalHelpers';

import ChartTypeSwitcher from './ChartTypeSwitcher';
import CustomRangeSlider from './CustomRangeSlider';

ChartJS.register(...registerables);

interface Properties {
  chartData: NewDimItemData[];
  chartLabels: string[];
  tabData: any;
  activeTabIndex: any;
}

const Chart = ({ chartData, chartLabels }: Properties) => {
  const [isMobile] = useMediaQuery('(min-width: 375px)' && '(max-width: 649px)');
  const [chartKey, setChartKey] = useState(0);
  const [isLegend, setIsLegend] = useState(true);
  const dispatch: AppDispatch = useDispatch();
  const showInputPage = useSelector(inputsPageShow);
  const showPlanPage = useSelector(planPageShow);
  const currentDriver = useSelector(getSelectedDriver);
  const sliderLables = useSelector(getValuesForSlider);

  const containerReference = useRef<HTMLDivElement>(null);
  const innerWidth = useSelector(getPlanPageChartinnerWidth);
  const baseScenario = useSelector(getBaseScenario);
  const chartFilter = useSelector(getChartFilter);
  const showSidePanel = useSelector(ShowSidePanel);
  const blockDrivers = useSelector(getBlockDriversData);
  const filteredDrivers = blockDrivers?.filter(
    (driver: IndicatorDriver) => driver.type === 'indicator',
  );
  // Convert to Date object
  const startDateObject = new Date(baseScenario?.start_date);
  startDateObject.setMonth(startDateObject.getMonth() - 1);

  // Format 1: Year 2023
  const yearFormat = `FY${startDateObject.getFullYear().toString().slice(-2)}`;

  // Format 2: Quarter-wise like Q4-23
  const quarterFormat = `Q${Math.ceil((startDateObject.getMonth() + 1) / 3)}-${String(
    yearFormat,
  ).slice(-2)}`;

  // Format 3: Nov-23
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const monthFormat = `${monthNames[startDateObject.getMonth()]}-${String(yearFormat).slice(-2)}`;

  const targetDate = () => {
    if (chartFilter === 'M') {
      return monthFormat;
    }
    if (chartFilter === 'Q') {
      return quarterFormat;
    }
    return yearFormat;
  };

  useEffect(() => {
    setChartKey((previousKey) => previousKey + 1);
  }, [chartFilter, baseScenario]);

  const chartTypeState = useSelector(getChartType);

  const toggleChartType = (newType: string) => dispatch(setChartType(newType));

  const legendMargin = {
    id: 'legendMargin',
    beforeInit(chart: any) {
      const originalFit = chart.legend.fit;

      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 25;
      };
    },
  };

  const datasets = chartData.map((entry: NewDimItemData, index: number) => {
    const data = entry?.value?.map((value: NewTotalValues) => value.value) || [];
    if (data.some((value) => value !== 0)) {
      while (data.length > 0 && data[data.length - 1] === 0) {
        data.pop();
      }
    }
    return {
      label: entry.name,
      data,
      backgroundColor: CHART_COLORS[index],
      borderColor: CHART_COLORS[index],
    };
  });

  const dataOfChart = {
    labels: chartLabels,
    datasets,
  };

  useLayoutEffect(() => {
    const updateContainerWidth = () => {
      setTimeout(() => {
        if (containerReference.current) {
          const containerWidth = containerReference.current.offsetWidth;
          const containerPadding =
            Number.parseInt(window.getComputedStyle(containerReference.current).paddingLeft, 10) +
            Number.parseInt(window.getComputedStyle(containerReference.current).paddingRight, 10);
          const widthWithoutPadding = containerWidth - containerPadding;
          if (widthWithoutPadding) dispatch(setPlanPageInnerWidth(widthWithoutPadding));
        }
      }, 200);
    };
    updateContainerWidth();
    window.addEventListener('resize', updateContainerWidth);
    return () => {
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, [containerReference?.current, dataOfChart, currentDriver, showInputPage, showPlanPage]);

  const drawBackgroundPlugin = {
    id: 'drawBackground',
    beforeDraw: ({ ctx, chartArea, data, scales }: any) => {
      ctx.fillStyle = 'transparent';

      const targetLabel = targetDate();
      const targetIndex = data.labels.indexOf(`${targetLabel}`);
      if (targetIndex !== -1) {
        // Get the x-axis pixel position for the specified dataset and index
        const xPosition = scales.x.getPixelForValue(data.labels[targetIndex]);

        ctx.fillStyle = '#EEF2F6';
        ctx.fillRect(
          chartArea.left,
          chartArea.top,
          xPosition - chartArea.left,
          chartArea.bottom - chartArea.top,
        );
      } else {
        ctx.fillRect(
          chartArea.left,
          chartArea.top,
          chartArea.right - chartArea.left,
          chartArea.bottom - chartArea.top,
        );
      }
    },
  };

  return (
    <>
      <Flex
        position='relative'
        marginBottom='1%'
        padding='1%'
        height={isMobile ? (innerWidth ? `${innerWidth / 1.5}px` : '35vh') : '35vh'}
      >
        <ReactChart
          key={chartKey}
          type={chartTypeState === 'line' ? 'line' : 'bar'}
          data={dataOfChart}
          options={getChartOptionsNew({
            chartType: chartTypeState === 'line' ? 'line' : 'stacked bar',
            isLegend: false,
            aspectRatio: 1,
          })}
          plugins={[legendMargin, drawBackgroundPlugin]}
        />
      </Flex>
      <Flex
        ref={containerReference}
        display={'flex'}
        justifyContent={isMobile ? 'center' : 'space-between'}
        alignItems='self-start'
        flexDirection='row'
        pt={isMobile ? '0.7rem' : 0}
      >
        <Flex maxW={isMobile ? '100&' : '70%'} width={isMobile ? '100&' : '70%'} flexWrap={'wrap'}>
          {isLegend &&
            datasets.map((_data: any, index: number) => (
              <Flex alignItems={'center'} p={'2px'} ml={1} key={index}>
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  bg={CHART_COLORS[index]}
                  h='13px'
                  w='13px'
                  mr='0.1rem'
                ></Box>
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'} h='13px'>
                  <Text
                    color='black'
                    fontSize={'13px'}
                    fontFamily={'regular_roboto'}
                    fontWeight={'500'}
                  >
                    {_data?.label}
                  </Text>
                </Box>
              </Flex>
            ))}
        </Flex>
        {!isMobile && (
          <Flex>
            <ChartTypeSwitcher
              viewMode={chartTypeState}
              onChange={toggleChartType}
              isLegend={isLegend}
              setIsLegend={setIsLegend}
            />
          </Flex>
        )}
      </Flex>
      {dataOfChart?.labels && (
        <Box>
          <CustomRangeSlider labels={sliderLables} />
        </Box>
      )}
      {isMobile && (
        <Flex
          ps='0.6rem'
          py='0.8rem'
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <ChartTypeSwitcher
            viewMode={chartTypeState}
            onChange={toggleChartType}
            isLegend={isLegend}
            setIsLegend={setIsLegend}
          />
        </Flex>
      )}
    </>
  );
};

export default Chart;
