import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  HStack,
  IconButton,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { MdShowChart } from 'react-icons/md';
import { RiBarChartFill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';

import Calender from 'components/Icons/Calender';
import OrderdList from 'components/Icons/OrderdList';
import {
  getChartFilter,
  getModelDetails,
  overallTimeFilter,
  setChartFilter,
} from 'redux/PlanPageSlice';
import {
  getCompareScenario,
  getVarianceFilterChart,
  setCompareChartFilter,
  toggleVarianceFilterChart,
} from 'redux/ScenarioSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

import TabsFilter from './Filters/TabsFilter';

interface Properties {
  viewMode: string;
  onChange: (value: string) => void;
  isLegend: any;
  setIsLegend: (value: any) => void;
}

const ChartTypeSwitcher = ({ viewMode, onChange, isLegend, setIsLegend }: Properties) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const dispatch: AppDispatch = useDispatch();
  const modelDetail = useSelector(getModelDetails);
  const timeAggregation = modelDetail?.time_properties?.time_granularity
    ? (modelDetail?.time_properties?.time_granularity as String).toUpperCase()
    : 'M';
  const selectedYear = useSelector(overallTimeFilter);
  const chartFilter = useSelector(getChartFilter);
  const varianceFilter = useSelector(getVarianceFilterChart);
  const compareScenario = useSelector(getCompareScenario);

  useEffect(() => {
    dispatch(setCompareChartFilter(chartFilter));
  }, [chartFilter]);

  const onChangeSummeryMode = (value: string) => {
    dispatch(setChartFilter(value));
  };

  const handleVarinaceChange = () => {
    dispatch(toggleVarianceFilterChart());
  };

  return (
    <HStack spacing='1' color='#8B89B4' marginTop={0} height={'20px'}>
      {compareScenario && (
        <>
          <Flex>
            <ButtonGroup isAttached variant='none' size={isMobile ? 'xs' : 'xs'} display='flex'>
              <Button
                p='2'
                fontSize={'2xs'}
                fontWeight={varianceFilter ? '600' : '500'}
                color={varianceFilter ? '#4BB6B9' : '#8B89B4'}
                sx={{
                  background: varianceFilter ? '#e8e8ed' : 'transparent',
                  borderRadius: '5px',
                  '&:hover': {
                    background: '#e8e8ed',
                  },
                }}
                onClick={() => handleVarinaceChange()}
                borderRadius={0}
              >
                %
              </Button>
            </ButtonGroup>
          </Flex>
          <Divider
            orientation='vertical'
            colorScheme='#D0CFE7'
            variant='solid'
            borderWidth='1px'
            borderColor='#8B89B485'
            height={'0.8rem'}
          />
        </>
      )}
      <Flex marginEnd={'0.5rem'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Text fontSize={'2xs'} color={'#8B89B4'} pe={2}>
          Legend
        </Text>

        <ButtonGroup
          isAttached
          variant='solid'
          size={isMobile ? 'xs' : 'xs'}
          display='flex'
          gap='1'
        >
          <IconButton
            aria-label='line chart'
            icon={<OrderdList color={isLegend ? '#4BB6B9' : '#8B89B4'} />}
            p='1'
            bgColor={isLegend ? '#e8e8ed' : 'transparent'}
            borderRadius={5}
            onClick={() => setIsLegend(!isLegend)}
          />
        </ButtonGroup>
      </Flex>
      <Divider
        orientation='vertical'
        colorScheme='#D0CFE7'
        variant='solid'
        borderWidth='1px'
        borderColor='#8B89B485'
        height={'0.8rem'}
      />
      <Box px={1} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Calender color={'#8B89B4'} />
      </Box>
      {timeAggregation !== 'Y' && (
        <TabsFilter
          bg={'#e8e8ed'}
          fontColor={'#8B89B4'}
          timeGranularity={chartFilter}
          selectedYear={selectedYear}
          color={isMobile ? 'white' : '#8B89B4'}
          onChangeSummeryMode={onChangeSummeryMode}
        />
      )}
      <Box pe={'0.6rem'}>
        <Divider
          orientation='vertical'
          colorScheme='#D0CFE7'
          variant='solid'
          borderWidth='1px'
          borderColor='#8B89B485'
          height={'0.8rem'}
        />
      </Box>

      <Flex gap={'1'}>
        <ButtonGroup
          isAttached
          variant='solid'
          size={isMobile ? 'xs' : 'xs'}
          display='flex'
          gap='1'
        >
          <IconButton
            aria-label='line chart'
            icon={<MdShowChart />}
            p='1'
            color={viewMode === 'line' ? '#4BB6B9' : '#8B89B4'}
            bgColor={viewMode === 'line' ? '#e8e8ed' : 'transparent'}
            borderRadius={5}
            onClick={() => onChange('line')}
          />
        </ButtonGroup>

        <ButtonGroup
          isAttached
          variant='solid'
          size={isMobile ? 'xs' : 'xs'}
          display='flex'
          gap='1'
        >
          <IconButton
            aria-label='bar chart'
            icon={<RiBarChartFill />}
            p='1'
            color={viewMode === 'bar' ? '#4BB6B9' : '#8B89B4'}
            bgColor={viewMode === 'bar' ? '#e8e8ed' : 'transparent'}
            onClick={() => onChange('bar')}
            borderRadius={5}
          />
        </ButtonGroup>
      </Flex>
    </HStack>
  );
};

export default ChartTypeSwitcher;
