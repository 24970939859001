import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
  marginLeft?: string;
}

const DimensionTabIcon = ({ width, height, color = '#4BB6B9' }: Properties) => {
  return (
    <Icon viewBox='0 0 34 35' width={width} height={height} mr={1}>
      <path
        d='M15.9195 27.7542L8.9004 23.7183C8.45586 23.4609 8.11076 23.1217 7.86509 22.7005C7.61942 22.2794 7.49659 21.8115 7.49659 21.2967V13.2248C7.49659 12.7101 7.61942 12.2421 7.86509 11.821C8.11076 11.3999 8.45586 11.0606 8.9004 10.8032L15.9195 6.76728C16.364 6.50991 16.8319 6.38123 17.3233 6.38123C17.8146 6.38123 18.2825 6.50991 18.7271 6.76728L25.7461 10.8032C26.1907 11.0606 26.5358 11.3999 26.7814 11.821C27.0271 12.2421 27.1499 12.7101 27.1499 13.2248V21.2967C27.1499 21.8115 27.0271 22.2794 26.7814 22.7005C26.5358 23.1217 26.1907 23.4609 25.7461 23.7183L18.7271 27.7542C18.2825 28.0116 17.8146 28.1403 17.3233 28.1403C16.8319 28.1403 16.364 28.0116 15.9195 27.7542ZM15.9195 24.5255V18.068L10.3042 14.8041V21.2967L15.9195 24.5255ZM18.7271 24.5255L24.3423 21.2967V14.8041L18.7271 18.068V24.5255ZM3.28516 10.2417V6.03028C3.28516 5.25818 3.56007 4.59722 4.1099 4.0474C4.65972 3.49757 5.32068 3.22266 6.09278 3.22266H10.3042V6.03028H6.09278V10.2417H3.28516ZM10.3042 31.2989H6.09278C5.32068 31.2989 4.65972 31.024 4.1099 30.4741C3.56007 29.9243 3.28516 29.2633 3.28516 28.4912V24.2798H6.09278V28.4912H10.3042V31.2989ZM24.3423 31.2989V28.4912H28.5537V24.2798H31.3614V28.4912C31.3614 29.2633 31.0865 29.9243 30.5366 30.4741C29.9868 31.024 29.3258 31.2989 28.5537 31.2989H24.3423ZM28.5537 10.2417V6.03028H24.3423V3.22266H28.5537C29.3258 3.22266 29.9868 3.49757 30.5366 4.0474C31.0865 4.59722 31.3614 5.25818 31.3614 6.03028V10.2417H28.5537ZM17.3233 15.6464L22.8683 12.3825L17.3233 9.18885L11.7782 12.3825L17.3233 15.6464Z'
        fill={color}
        fillOpacity={color === 'white' ? '0.5' : '1'}
      />
    </Icon>
  );
};

export default DimensionTabIcon;
