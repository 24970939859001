import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Container from 'components/Container';
import Logo from 'components/Icons/Logo';
import { useAuthContext } from 'context/authContext';
import { getAuthenticationPageType, SetAuthPageType } from 'redux/AuthSlice';
import { BLOX_SIGNUP_HEADING_BOTTOM, BLOX_SIGNUP_HEADING_TOP } from 'projectConstants';

import SignInPage from './SignInPage';
import SignUpPage from './SignUpPage';
import ResetPasswordPage from './ResetPasswordPage';
import AuthenticationIllustration from './AuthenticationIllustration';

interface Properties {
  type: number;
}

const Authentication = ({ type }: Properties) => {
  const navigate = useNavigate();
  const { isAuth } = useAuthContext();
  const dispatch: any = useDispatch();

  const [isLaptop] = useMediaQuery('(max-width: 768px)');
  const [isMobile] = useMediaQuery('(max-width: 576px)');

  const authPageType = useSelector(getAuthenticationPageType);

  useEffect(() => {
    if (type === 1) {
      dispatch(SetAuthPageType(1));
    } else {
      dispatch(SetAuthPageType(2));
    }
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('BLOX_USER_TOKEN');
    if (token && isAuth) {
      navigate('/home');
    }
  }, [navigate]);

  return (
    <Container
      wrapperProperties={{
        px: 0,
        p: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        height: '100%',
      }}
      bgColor={'linear-gradient(180deg, rgba(101, 98, 207, 0.6) 0%, rgba(75, 182, 185, 0.6) 100%);'}
    >
      <Flex
        boxShadow={'0px 15px 15px rgba(0, 0, 0, 0.15)'}
        justifyContent={'space-between'}
        borderRadius={'15px'}
        bgColor={'white'}
        width={'fit-content'}
        minWidth={'300px'}
        position={'relative'}
        overflowY={'hidden'}
      >
        <Flex
          maxWidth={authPageType === 3 ? '' : '450px'}
          width={isLaptop ? '100%' : authPageType === 3 ? '100%' : '60%'}
          padding={authPageType === 3 ? '2rem' : isMobile ? '2rem' : '2rem 0 2rem 3rem'}
          direction={'column'}
          minWidth={!isMobile && authPageType === 3 ? '550px' : ''}
        >
          <Box style={{ marginTop: authPageType === 3 ? '1rem' : '' }} data-testid='app-test'>
            <Logo height={10} width={'auto'} />
          </Box>
          {authPageType === 3 ? (
            <>
              <Text
                variant='secondary'
                fontFamily='bold'
                fontSize={{ base: '20px', '1.5xl': '25px' }}
                width={{ start: '70%', laptop: '100%' }}
                marginTop={'2rem'}
                color={'#121826'}
              >
                Reset your password
              </Text>
              {!isMobile ? (
                <>
                  <Text
                    variant='secondary'
                    marginTop={'1rem'}
                    color='#1A202C'
                    fontFamily='regular_roboto'
                    fontSize={'md'}
                    lineHeight={{ sm: '1' }}
                  >
                    We'll email you instructions on how to
                  </Text>
                  <Text
                    variant='secondary'
                    color='#1A202C'
                    fontFamily='regular_roboto'
                    fontSize={'md'}
                    marginBottom={'1rem'}
                  >
                    reset your password
                  </Text>
                </>
              ) : (
                <Text
                  variant='secondary'
                  marginTop={'1rem'}
                  color='#1A202C'
                  fontFamily='regular_roboto'
                  marginBottom={'1rem'}
                  fontSize={'16px'}
                  lineHeight={{ sm: '1' }}
                >
                  We'll email you instructions on how to reset your password
                </Text>
              )}
            </>
          ) : (
            <>
              <Text
                variant='secondary'
                fontFamily='bold'
                fontSize={'3xl'}
                width={{ start: '60%', laptop: '100%' }}
                marginTop={'2rem'}
                color={'#121826'}
              >
                {BLOX_SIGNUP_HEADING_TOP}
              </Text>
              <Text
                variant='secondary'
                fontFamily='bold'
                fontSize={'3xl'}
                color={'#6562cf'}
                marginBottom={'2rem'}
              >
                {BLOX_SIGNUP_HEADING_BOTTOM}
              </Text>
            </>
          )}
          <Box maxWidth={authPageType === 3 ? '' : '300px'}>
            {authPageType === 1 ? (
              <SignInPage />
            ) : authPageType === 2 ? (
              <SignUpPage />
            ) : authPageType === 3 ? (
              <ResetPasswordPage />
            ) : (
              ''
            )}
          </Box>
        </Flex>

        <Flex
          width={'fit-content'}
          maxWidth={authPageType === 3 ? '300px' : '400px'}
          justifyContent={'end'}
        >
          <AuthenticationIllustration />
        </Flex>
      </Flex>
    </Container>
  );
};

export default Authentication;
