import { Box, Flex, Image, Slide, Text, Tooltip } from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InputField from 'components/InputField';
import {
  AddDimnesionGroupItem,
  PostDeleteDimensionItem,
  UpdateItemName,
  fetchDimensionItemsGroup,
  getDataIndicators,
  getDataKey,
  getDisplayInfo,
  getGroupItem,
  getLoadNewRole,
  getNewItemName,
  getRenameItemName,
  getStepNumber,
  setDisplayInfo,
  setGroupItem,
  setNewItemName,
  setRenameItemName,
  setStepNumber,
} from 'redux/ItemPageSlice';
import { getSelectedDriver } from 'redux/PlanPageSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';
import { Scenario } from 'models/response/scenario.interface';
import PlanPermission from 'container/PlanPermission';
import { AppDispatch } from 'utils/GlobalHelpers';

import ArrowLeftBlocks from '../../../../../components/Icons/ArrowLeftBlocks';
import ArrowRight from '../../../../../components/Icons/ArrowRight';
import Plus from '../../../../../components/Icons/Plus';
import infoIcon from '../../Icons/infoIcon.svg';
import arrowDown from '../../Icons/arrowDown.svg';

interface StepTwoProperties {
  setItemIndicator: (argument: any) => void;
  planPermission: boolean;
  loadingDriver: boolean;
  longClickCheck: any;
  isLongClicked: boolean;
  refetchRequest: () => void;
}

const StepTwo = ({
  setItemIndicator,
  loadingDriver,
  longClickCheck,
  isLongClicked,
  refetchRequest,
  planPermission,
}: StepTwoProperties) => {
  const dispatch: AppDispatch = useDispatch();
  const stepNumber = useSelector(getStepNumber);
  const dataKey = useSelector(getDataKey);
  const dataIndicators = useSelector(getDataIndicators);

  const displayInfo = useSelector(getDisplayInfo);
  const renameItemName = useSelector(getRenameItemName);
  const newItemName = useSelector(getNewItemName);
  const groupItem = useSelector(getGroupItem);
  const currentDriver = useSelector(getSelectedDriver);
  const loadNewRole = useSelector(getLoadNewRole);
  const baseScenario: Scenario = useSelector(getBaseScenario);
  const [itemName, setItemName] = useState('');
  const renameRole = async (event: any, id: number) => {
    event.stopPropagation();
    if (renameItemName === id) {
      dispatch(setRenameItemName(null));
    }
    dispatch(setRenameItemName(id));
  };
  const handleRole = (name: string): void => {
    if (groupItem === name) {
      dispatch(setGroupItem(null));
    }
    dispatch(setGroupItem(name));
  };
  const toggleInfoPopup = (event: any, id: number | null) => {
    event.stopPropagation();
    if (displayInfo === id) {
      return dispatch(setDisplayInfo(null));
    }
    return dispatch(setDisplayInfo(id));
  };
  const updateItemName = async (item_id: Number) => {
    if (newItemName && newItemName?.trim()) {
      const requestBody = { name: newItemName, scenario_id: baseScenario?.id };
      await dispatch(
        UpdateItemName({
          item_id,
          request_body: requestBody,
          toaster: {
            successMessage: 'Successfully Updated',
            errorMessage: 'An item with this name already exists',
            show: true,
          },
        }),
      );
      await dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));
      refetchRequest();
    }
  };

  const deleteItem = async (event: any, id: any) => {
    event.stopPropagation();
    await dispatch(
      PostDeleteDimensionItem({ ItemId: id, parameters: { scenario_id: baseScenario?.id } }),
    );
    await dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));
    refetchRequest();
  };
  const dimensionId = currentDriver?.dim_id;
  const requestBody = {
    name: itemName,
    properties: [
      {
        property_id: currentDriver?.group_property_id,
        value: groupItem?.name,
      },
    ],
    scenario_id: baseScenario?.id,
  };
  const AddItem = async () => {
    await dispatch(
      AddDimnesionGroupItem({
        dimension_id: dimensionId,
        request_body: requestBody,
        toaster: {
          successMessage: 'Successfully Added',
          errorMessage: 'An item with this name already exists',
          show: true,
        },
      }),
    );
    refetchRequest();
    setItemName('');
    await dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));
  };
  const onKeyPressAction = async (event: any, item_id: number) => {
    if (event.keyCode === 13) {
      await dispatch(setRenameItemName(item_id));
      await updateItemName(item_id);
    }
    if (event.code === 'Escape') dispatch(setRenameItemName(0));
  };

  return (
    <Slide
      direction='right'
      in={stepNumber === 1 || stepNumber === 2}
      unmountOnExit
      style={{
        position: stepNumber === 1 || stepNumber === 2 ? 'fixed' : 'relative',
        height: '100%',
        transform: 'translateX(-100%)',
        zIndex: '200',
      }}
    >
      <Box
        w='100%'
        height={'100%'}
        bg='linear-gradient(180deg, #3D3380 0%, #7163D0 60%)'
        p='20px'
        overflow={'auto'}
      >
        <Flex justifyContent='space-between' alignItems='center' mb='40px'>
          <Flex alignItems='center' cursor='pointer' onClick={() => dispatch(setStepNumber(0))}>
            <Box mr='12px' paddingBottom='6px'>
              <ArrowLeftBlocks />
            </Box>
            <Text fontSize='18px'>Groups</Text>
          </Flex>
        </Flex>
        <Box mb='12px'>
          <Tooltip label={dataKey?.name}>
            <Text>{dataKey?.name}</Text>
          </Tooltip>
        </Box>
        {dataIndicators &&
          dataIndicators[dataKey?.name]?.items.map((element: any, index: number) => {
            return (
              <Flex
                key={index}
                textAlign='left'
                alignSelf='left'
                w='100%'
                minH='50px'
                bgColor='#7163D0'
                mb='10px'
                p='0'
                flexDirection='column'
                borderRadius='14px'
                boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.35) '}
                transition='all 0.3s ease-out'
                cursor='pointer'
                position='relative'
                zIndex={displayInfo === element?.id ? '350' : '0'}
              >
                <Box p='16px' position='relative'>
                  <Flex
                    onClick={async () => {
                      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                      renameItemName && renameItemName === element?.id
                        ? ''
                        : dispatch(setStepNumber(2));
                      setItemIndicator(element);
                      dispatch(setDisplayInfo(null));
                    }}
                    justifyContent='space-between'
                    alignItems='center'
                    onBlur={() => dispatch(setDisplayInfo(null))}
                    {...longClickCheck()}
                  >
                    {!planPermission && renameItemName && renameItemName === element?.id ? (
                      <InputField
                        placeholder='Rename'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          dispatch(setNewItemName(event.target.value));
                        }}
                        onKeyDown={(event: React.ChangeEvent<HTMLInputElement>) =>
                          onKeyPressAction(event, element?.id)
                        }
                        onBlur={() =>
                          newItemName
                            ? updateItemName(element?.id)
                            : dispatch(setRenameItemName(null))
                        }
                        enterKeyHint='done'
                        autoFocus
                        sx={{
                          marginRight: '10px',
                        }}
                      />
                    ) : (
                      <Tooltip label={element?.name}>
                        <Text
                          fontFamily='Roboto Regular'
                          fontSize='1xl'
                          lineHeight='9'
                          maxW='200px'
                          noOfLines={isLongClicked ? 0 : 1}
                        >
                          {element?.name}
                        </Text>
                      </Tooltip>
                    )}
                    <Flex alignItems='center'>
                      <Image
                        src={infoIcon}
                        w='28px'
                        height='8px'
                        mr='20px'
                        cursor={planPermission ? 'not-allowed' : 'pointer'}
                        onClick={
                          planPermission
                            ? undefined
                            : (event) => toggleInfoPopup(event, element?.id)
                        }
                      />
                      <Flex
                        bgColor='white'
                        width='fit-content'
                        position='absolute'
                        display={displayInfo === element?.id ? 'block' : 'none'}
                        zIndex='450'
                        top='36px'
                        p='5px 0'
                        borderRadius='4px'
                      >
                        <Box
                          p='5px 15px'
                          _hover={{
                            background: '#EEF2F6',
                            color: 'black',
                          }}
                        >
                          <Text
                            color='black'
                            onClick={
                              planPermission ? undefined : (event) => renameRole(event, element?.id)
                            }
                          >
                            Rename
                          </Text>
                        </Box>
                        <Box
                          p='5px 15px'
                          _hover={{
                            background: '#EEF2F6',
                            color: 'black',
                          }}
                        >
                          <Text
                            color='black'
                            onClick={
                              planPermission ? undefined : (event) => deleteItem(event, element?.id)
                            }
                          >
                            Delete
                          </Text>
                        </Box>
                      </Flex>
                      <Box>
                        <ArrowRight color='#FFFFFF' />
                      </Box>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            );
          })}
        {groupItem === dataKey || !loadingDriver || !loadNewRole ? (
          <Flex
            textAlign='left'
            alignSelf='left'
            w='100%'
            minH='50px'
            bgColor='#7163D0'
            mb='10px'
            p='0'
            flexDirection='column'
            borderRadius='14px'
            transition='all 0.3s ease-out'
          >
            <Box position='relative' p='16px'>
              <Flex justifyContent='space-between'>
                <Text fontFamily='Roboto Regular' fontSize='1xl' lineHeight='9'>
                  <InputField
                    placeholder='Add new Item'
                    onChange={(event: any) => {
                      setItemName(event.target.value);
                    }}
                    onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                      return (event.code === 'Enter' || event.key === 'Enter') &&
                        event.target.value.trim().length > 0
                        ? AddItem()
                        : null;
                    }}
                  />
                </Text>
                <Image src={arrowDown} w='12px' onClick={AddItem} />
              </Flex>
            </Box>
          </Flex>
        ) : null}
        <Flex
          alignItems='center'
          pb='15px'
          cursor={planPermission ? 'not-allowed' : 'pointer'}
          onClick={planPermission ? undefined : () => handleRole(dataKey)}
        >
          <Box mr='15px'>
            <Plus height={5} width={5} />
          </Box>
          <Tooltip label={currentDriver?.dim_name}>
            <Text fontSize='1xl' pt='2px'>
              {currentDriver?.dim_name}
            </Text>
          </Tooltip>
        </Flex>
      </Box>
    </Slide>
  );
};
export default PlanPermission(StepTwo);
