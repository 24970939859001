import { Box } from '@chakra-ui/react';

interface Properties {
  width?: string;
  height?: string;
}
const TrendingDownIcon = ({ width = '16px', height = '16px' }: Properties) => {
  return (
    <Box background={'#F7CACA'} borderRadius={'50%'} p={1}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        viewBox='0 0 17 17'
        fill='none'
      >
        <mask id='mask0_184_4831' maskUnits='userSpaceOnUse' x='0' y='0'>
          <rect
            width={width}
            height={height}
            transform='matrix(1 0 0 -1 0.140625 16.8096)'
            fill='#D9D9D9'
          />
        </mask>
        <g mask='url(#mask0_184_4831)'>
          <path
            d='M2.49891 4.3212L1.52759 5.29252L6.66174 10.4614L9.43696 7.68615L13.0447 11.2592H11.2408V12.6469H15.4037V8.48402H14.0161V10.2879L9.43696 5.70881L6.66174 8.48402L2.49891 4.3212Z'
            fill='#E26161'
          />
        </g>
      </svg>
    </Box>
  );
};

export default TrendingDownIcon;
