export const checkedRadioStyles = {
  _before: {
    content: '""',
    display: 'inline-block',
    width: '7px',
    height: '7px',
    borderRadius: '50%',
    background: 'black',
  },
  _checked: {
    borderColor: 'black',
    color: 'black',
    boxShadow: 'none',
  },
};
export const versionCheckedRadioStyles = {
  _before: {
    content: '""',
    display: 'inline-block',
    width: '7px',
    height: '7px',
    borderRadius: '50%',
    background: '#4BB6B9;',
  },
  _checked: {
    borderColor: '#4BB6B9;',
    color: '#4BB6B9;',
    boxShadow: 'none',
  },
};

export const textStyles = {
  fontSize: '0.9rem',
  fontFamily: 'regular_roboto',
  fontWeight: '400',
};
