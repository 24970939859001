import { extendTheme } from '@chakra-ui/react';

import BORDER_STYLERS from './border-styles';
import BORDER_WIDTHS from './border-width';
import BREAKPOINTS from './breakpoints';
import COLORS from './colors';
import BUTTON from './components/button.theme';
import MENU from './components/menu.theme';
import CHECKBOX from './components/checkbox.theme';
import RADIO from './components/radio.theme';
import SLIDER from './components/slider.theme';
import TABLE from './components/table.theme';
import CARD from './components/card.theme';
import TABS from './components/tabs.theme';
import TOOLTIP from './components/tooltip.theme';
import TAG from './components/tag.theme';
import TEXT from './components/text.theme';
import LIST from './components/list.theme';
import DIVIDER from './components/divider.theme';
import FONT_SIZES from './font-sizes';
import FONTS from './fonts';
import GLOBAL from './global';
import LINE_HEIGHT from './line-height';
import RADII from './radii';
import SIZES from './sizes';
import SPACES from './spaces';

const theme = extendTheme({
  borderWidths: BORDER_WIDTHS,
  borderStyles: BORDER_STYLERS,
  breakpoints: BREAKPOINTS,
  fonts: FONTS,
  fontSizes: FONT_SIZES,
  lineHeights: LINE_HEIGHT,
  colors: COLORS,
  radii: RADII,
  space: SPACES, // margins & paddings
  sizes: SIZES, // widths & heights
  styles: { global: GLOBAL },
  components: {
    Button: BUTTON,
    Menu: MENU,
    Checkbox: CHECKBOX,
    Text: TEXT,
    Card: CARD,
    Radio: RADIO,
    Slider: SLIDER,
    Tabs: TABS,
    Tag: TAG,
    Table: TABLE,
    List: LIST,
    Tooltip: TOOLTIP,
    Divider: DIVIDER,
  },
});

export default theme;
