import {
  Box,
  Button,
  ButtonGroup,
  Divider,
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useMediaQuery,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { FiDownload } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ArrowLeftTable from 'components/Icons/ArrowLeftTable';
import ArrowRightTable from 'components/Icons/ArrowRightTable';
import { useAuthContext } from 'context/authContext';
import { ExportDataRequestBody } from 'models/requestBody/createExportData.interface';
import {
  getDimension,
  getModelDetails,
  getTableFilter,
  overallIndicatorFilter,
  setTableFilter,
} from 'redux/PlanPageSlice';
import apiService from 'services';
import sizes from 'styles/theme/sizes';
import saveFile from 'utils/file';
import Search from 'components/Icons/Search';
import {
  getBaseScenario,
  getCompareScenario,
  getExpandKey,
  getTableData,
  getTableHeaderData,
  getVarianceFilterTable,
  setCompareTableFilter,
  setExpandKey,
  toggleVarianceFilterTable,
} from 'redux/ScenarioSlice';
import Calender from 'components/Icons/Calender';
import { AppDispatch } from 'utils/GlobalHelpers';

import TabsFilter from '../Filters/TabsFilter';

import CompareIndicatorRow from './CompareIndicatorRow';

export interface Properties {
  onToggleAuthModal: () => void;
}
const CompareIndicatorTable = ({ onToggleAuthModal }: Properties) => {
  const [isMobile] = useMediaQuery('(max-width: 575px)');
  const [isTablet] = useMediaQuery('(max-width: 1023px)');
  const { blockId } = useParams();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const modelDetail = useSelector(getModelDetails);
  const tableFilter = useSelector(getTableFilter);
  const varianceFilter = useSelector(getVarianceFilterTable);
  const handleVarinaceChange = () => {
    dispatch(toggleVarianceFilterTable());
  };

  useEffect(() => {
    dispatch(setCompareTableFilter(tableFilter));
  }, [tableFilter]);

  const timeAggregation = modelDetail?.time_properties?.time_granularity
    ? (modelDetail?.time_properties?.time_granularity as String).toUpperCase()
    : 'M';
  const dimension = useSelector(getDimension);
  const tableData = useSelector(getTableData);
  const expandkey = useSelector(getExpandKey);
  const filter = useSelector(overallIndicatorFilter);
  const baseScenario = useSelector(getBaseScenario);
  const isLoading = !tableData;
  const tableHeaderData = useSelector(getTableHeaderData);
  const { user } = useAuthContext();
  const compareScenario = useSelector(getCompareScenario);

  const onChangeSummeryMode = (value: string) => {
    dispatch(setTableFilter(value));
  };

  const expand = (value: any) => {
    if (value.isShowExpandable && timeAggregation !== 'Y') {
      if (expandkey !== value.name && (tableFilter === 'Y' || tableFilter === 'Q')) {
        dispatch(setExpandKey(value.name));
      } else {
        dispatch(setExpandKey(''));
      }
    }
  };

  const requestBody: ExportDataRequestBody = {
    orient: 'table',
    query_params: {
      rows: [{ name: 'Indicators', filter }, { name: dimension?.name || null }],
      columns: [{ name: 'Time', display_levels: [tableFilter] }],
      filters: [],
    },
    scenario_id: baseScenario?.id,
  };

  const { refetch: fetchExportData } = useQuery(
    ['export', blockId, baseScenario?.id],
    () => apiService.create_block_export({ block_id: Number(blockId), request_body: requestBody }),
    {
      enabled: false,
      onError: (response: any) => {
        if (response.response.data.message) {
          navigate('/error');
        }
      },
    },
  );

  const saveResponseToFile = async () => {
    if (user?.is_anonymous) {
      onToggleAuthModal();
    } else {
      const { data: exportData } = await fetchExportData();
      if (exportData) {
        const fileName = `Blox-${blockId}.xlsx`;
        const blob = [exportData.data];
        const contentType =
          exportData.headers?.['content-type'] ??
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        saveFile(fileName, blob, contentType);
      }
    }
  };

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      {tableHeaderData && (
        <Box
          mt={{ base: 0, md: 0 }}
          display='flex'
          justifyContent='space-between'
          mb={{ base: sizes['1'] }}
          alignItems='center'
        >
          <Flex alignItems={'center'}>
            <Search />
            <Text size='modalContent' ml={2}>
              Expand the table to drill into the values for each of your items
            </Text>
          </Flex>
          <Flex display='flex' alignItems='center'>
            {compareScenario && (
              <Flex mr={2}>
                <ButtonGroup isAttached variant='none' size={isMobile ? 'xs' : 'xs'} display='flex'>
                  <Button
                    p='2'
                    fontSize={'2xs'}
                    fontWeight={varianceFilter ? '600' : '500'}
                    color={varianceFilter ? '#4BB6B9' : '#D0CFE7'}
                    sx={{
                      background: varianceFilter ? '#F7F7F815' : 'transparent',
                      borderRadius: '5px',
                      '&:hover': {
                        background: '#F7F7F815',
                      },
                    }}
                    onClick={() => handleVarinaceChange()}
                    borderRadius={0}
                  >
                    %
                  </Button>
                </ButtonGroup>
              </Flex>
            )}
            <Box pe={2} display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Calender color={'white'} />
            </Box>
            {timeAggregation !== 'Y' && (
              <TabsFilter
                bg={'#F7F7F815'}
                fontColor={'#D0CFE7'}
                color='white'
                timeGranularity={tableFilter}
                onChangeSummeryMode={onChangeSummeryMode}
              />
            )}

            <Divider
              orientation='vertical'
              colorScheme='whiteAlpha'
              variant='solid'
              borderWidth='1px'
              borderColor='rgba(208, 207, 231, 1)'
              height={'0.8rem'}
            />

            <IconButton
              aria-label='export'
              variant='unstyled'
              mr={0}
              width='fit-content'
              display='flex'
              justifyContent='center'
              alignItems={'center'}
              onClick={saveResponseToFile}
            >
              <FiDownload size={sizes[isMobile ? 4 : 4.5]} />
            </IconButton>
          </Flex>
        </Box>
      )}
      {tableHeaderData && (
        <Box borderRadius='10px' overflowY='auto' overflowX='auto'>
          <TableContainer
            maxH='229px'
            mb={{ base: 4.75, md: 0 }}
            mt={{ base: 0, md: 0 }}
            borderRadius='10px'
            overflowY='auto'
            overflowX='auto'
            className={`${isMobile || isTablet ? '' : 'table_scroll'} indicator-table`}
          >
            <Table variant='base'>
              <Thead height={'9'}>
                <Tr>
                  <Th></Th>
                  {tableHeaderData?.map(
                    (date: any) =>
                      date.isShow && (
                        <>
                          <Th
                            fontFamily={'regular_roboto'}
                            fontSize={'xs'}
                            fontWeight={500}
                            textTransform='none'
                            style={{
                              cursor: date.isShowExpandable ? 'pointer' : 'default',
                            }}
                            onClick={() => {
                              expand(date);
                            }}
                            key={date.name}
                            justifyContent='space-around'
                            textAlign={'start'}
                          >
                            {date.isShowExpandable}
                            {date.isShowExpandable &&
                              (expandkey === date.name ? <ArrowRightTable /> : <ArrowLeftTable />)}
                            {date.name}
                          </Th>
                          <Th></Th>
                        </>
                      ),
                  )}
                </Tr>
              </Thead>

              {tableData && (
                <Tbody>
                  {tableData?.map((row: any) => (
                    <CompareIndicatorRow key={row.id} row={row} expandkey={expandkey} />
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </Box>
      )}
    </>
  );
};

export default CompareIndicatorTable;
