export default {
  variants: {
    primary: {
      control: {
        borderColor: '#6562CF',
        _focus: {
          boxShadow: 'none',
        },
        _checked: {
          background: '#6562CF',
          borderColor: '#6562CF',
          _hover: {
            background: '#6562CF',
            borderColor: '#6562CF',
          },
        },
      },
      icon: {
        background: '#6562CF',
        borderColor: '#6562CF',
      },
    },
    secondary: {
      control: {
        borderColor: '#69B4B8',
        borderWidth: 3,
        _hover: {
          background: '#69B4B8',
          borderColor: '#69B4B8',
        },
        _focus: {
          boxShadow: 'none',
        },
        _checked: {
          borderColor: '#69B4B8',
          _hover: {
            background: '#69B4B8',
            borderColor: '#69B4B8',
          },
        },
      },
      icon: {
        color: '#69B4B8',
        borderColor: '#6562CF',
        fontSize: '.6rem',
      },
    },
    filters: {
      control: {
        borderWidth: '2px',
        borderColor: '#8E8DB0',
        height: '15px',
        width: '15px',
        _focus: {
          boxShadow: 'none',
        },
        _checked: {
          color: '#8E8DB0',
          background: '#fff',
          borderColor: '#8E8DB0',
          _hover: {
            background: '#fff',
            borderColor: '#8E8DB0',
          },
        },
        _indeterminate: {
          color: '#8E8DB0',
          background: '#fff',
          borderColor: '#8E8DB0',
          _hover: {
            background: '#fff',
            borderColor: '#8E8DB0',
          },
        },
      },
      icon: {
        background: '#fff',
        borderColor: '#8E8DB0',
      },
    },
    delete: {
      control: {
        borderWidth: '1px',
        borderColor: 'rgba(255, 255, 255, 0.3) !important',
        height: '17px',
        width: '17px',
        _focus: {
          boxShadow: 'none',
        },
        _checked: {
          color: '#69B4B8',
          background: '#796cd6',
          borderColor: '#69B4B8 !important',
          _hover: {
            background: 'transparent',
            borderColor: '#69B4B8',
          },
        },
        _indeterminate: {
          color: 'rgba(255, 255, 255, 0.3) !important',
          background: 'transparent',
          borderColor: 'rgba(255, 255, 255, 0.3) !important',
        },
      },
      icon: {
        width: '12px !important',
        height: '16px',
      },
    },
  },
  baseStyle: {
    control: {
      borderWidth: '1',
      borderRadius: '2xs',
      borderColor: 'green.900',
    },
    label: { ml: 1.5, color: 'black.900' },
  },
  sizes: {
    sm: {
      control: {
        h: 2.75,
        w: 2.75,
      },
      label: { fontSize: '3xs' },
      icon: { fontSize: '0.35rem' },
    },
    md: {
      control: {
        h: 3,
        w: 3,
      },
      label: { fontSize: '2xs' },
      icon: { fontSize: '0.45rem' },
    },
    lg: {
      control: {
        h: 3.5,
        w: 3.5,
      },
      label: {
        fontFamily: 'regular_roboto',
        fontSize: '11px',
        lineHeight: '14px',
        color: 'grey.900',
      },
      icon: { fontSize: '0.45rem' },
    },
  },
  defaultProps: {
    size: 'md',
    colorScheme: 'teal',
  },
};
