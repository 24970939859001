/* eslint-disable unicorn/filename-case */
/* eslint-disable unicorn/better-regex */
/* eslint-disable unicorn/filename-case */
/* eslint-disable unicorn/prefer-switch */
/* eslint-disable unicorn/prevent-abbreviations */
import 'draft-js/dist/Draft.css'; // Import Draft.js default styles
import './FormulaBarIntellisense.css'; // Import your custom styles
import { useDispatch, useSelector } from 'react-redux';
import {
  Editor,
  EditorState,
  RichUtils,
  CompositeDecorator,
  Modifier,
  Entity,
  ContentState,
  convertToRaw,
} from 'draft-js';
import { Text, Box, Flex, Button, Link, Tooltip } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';

import {
  FetchBlockOutputs,
  getBaseScenario,
  getFormulaList,
  getPlannerBlock,
  getSelectedIndicators,
  UpdateIndicatorFormula,
} from 'redux/PlannerModeSlice';
import FunctionIconComponent from 'components/Icons/FunctionIcon';
import QuestionIcon from 'components/Icons/QuestionIcon';

const DraftJSFormula = ({ setIsExpanded, isExpanded }) => {
  const dispatch = useDispatch();
  const { blockId } = useParams();
  const [message, setMessage] = useState({ type: '', text: '', errorStatus: false });
  const [functions, setFunctions] = useState([]);
  const [isEsc, setIsEsc] = useState(false);
  const [dimensionFormula, setDimensionFormula] = useState([]);
  const [dimensionItemsFormula, setDimensionItemsFormula] = useState([]);
  // const [dimensionValuesFormula, setDimensionValuesFormula] = useState([]);
  const [currBlockIndicator, setCurrBlockIndicator] = useState([]);
  const [otherBlocks, setOtherBlocks] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [decorator1, setDecorator1] = useState(null);
  const formulaList = useSelector(getFormulaList);
  const baseScenario = useSelector(getBaseScenario);
  const plannerBlock = useSelector(getPlannerBlock);
  const [suggestionsList, setSuggestionsList] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionBoxStyle] = useState({});
  const editorRef = useRef(null);
  const editorContainerRef = useRef(null);
  const selectedIndicators = useSelector(getSelectedIndicators);
  const cleanString = (str) => str?.replace(/\s+/g, ' ').trim();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [initialDecorator, setInitialDecorator] = useState([]);
  const [combinedDatass, setCombinedData] = useState([]);
  useEffect(() => {
    editorRef.current.blur();
    const updateData = () => {
      if (formulaList) {
        const func =
          formulaList?.functions?.map((fn) => ({
            name: cleanString(fn),
            type: 'function',
          })) || [];
        setFunctions(func || []);
        const othersBlocksIndicator = formulaList?.blocks?.filter(
          (item) => item?.id !== Number(blockId),
        );
        const othersBlocksFormulaData =
          (othersBlocksIndicator.length > 0 &&
            othersBlocksIndicator?.flatMap((data) =>
              data?.indicators?.map((indicator) => ({
                blockname: cleanString(data.name),
                indicator: cleanString(indicator.name),
                type: 'othersblockIndicator',
              })),
            )) ||
          [];
        setOtherBlocks([...othersBlocksFormulaData]);
        const dimensionsFormulaData =
          formulaList.dimensions?.flatMap((dimension) =>
            dimension.properties.map((propertie) => ({
              dimName: cleanString(dimension.name),
              dimPropertie: cleanString(propertie.name),
              type: 'dimension',
            })),
          ) || [];
        const dimensionsFormulaItemsData =
          formulaList.dimensions?.flatMap((dimension) =>
            dimension.items.map((item) => ({
              dimName: cleanString(dimension.name),
              dimItem: cleanString(item.name),
              type: 'dimensionItem',
            })),
          ) || [];
        setDimensionFormula([...dimensionsFormulaData]);
        setDimensionItemsFormula([...dimensionsFormulaItemsData]);
        // const dimensionsFormulaValuesData =
        //   formulaList?.value?.map((item) => ({
        //     dimValue: cleanString(item),
        //     type: 'dimensionValue',
        //   })) || [];
        // setDimensionValuesFormula([...dimensionsFormulaValuesData]);
        const currentBlock = formulaList?.blocks?.filter((item) => item?.id === Number(blockId));
        const currentBlockIndicator =
          currentBlock[0]?.indicators?.map((item) => ({
            indName: cleanString(item.name),
            type: 'currentBlockIndicator',
          })) || [];
        setCurrBlockIndicator(currentBlockIndicator);
        const combinedData = [
          ...func.map((item) => cleanString(item.name)),
          ...othersBlocksFormulaData.map((item) => cleanString(item.blockname)),
          ...othersBlocksFormulaData.map((item) => cleanString(item.indicator)), // Concatenate blockname and indicator
          ...dimensionsFormulaData.map((item) => cleanString(item.dimName)), // Concatenate dimension name and property
          ...dimensionsFormulaData.map((item) => cleanString(item.dimPropertie)),
          ...dimensionsFormulaItemsData.map((item) => cleanString(item.dimName)), // Concatenate dimension name and property
          ...dimensionsFormulaItemsData.map((item) => cleanString(item.dimItem)),
          // ...dimensionsFormulaValuesData.map((item) => cleanString(item.dimValue)),
          ...currentBlockIndicator.map((item) => cleanString(item.indName)), // Extract current block indicator names
        ];

        // Set the combined data to the state
        setCombinedData(combinedData);
      }
    };

    updateData();
  }, [formulaList]);
  useEffect(() => {
    setSuggestions([
      ...(functions?.map(({ name, type }) => ({ name, type })) || []),
      ...(otherBlocks?.map(({ blockname, indicator, type }) => ({ blockname, indicator, type })) ||
        []),
      ...(dimensionFormula?.map(({ dimName, dimPropertie, type }) => ({
        dimName,
        dimPropertie,
        type,
      })) || []),
      ...(dimensionItemsFormula?.map(({ dimName, dimItem, type }) => ({
        dimName,
        dimItem,
        type,
      })) || []),
      // ...(dimensionValuesFormula?.map(({ dimValue, type }) => ({
      //   dimValue,
      //   type,
      // })) || []),
      ...(currBlockIndicator?.map(({ indName, type }) => ({ indName, type })) || []),
    ]);
  }, [
    otherBlocks,
    functions,
    dimensionFormula,
    currBlockIndicator,
    dimensionItemsFormula,
    // dimensionValuesFormula,
  ]);
  const escapeRegExp = (string) => {
    return string?.replace(/[$()*+./?[\\\]^{|}-]/g, '\\$&'); // Escape special regex characters
  };

  const findWithRegex = (regex, contentBlock, callback) => {
    const text = contentBlock.getText();

    // Escape special characters and handle combinedDatass
    const ddd = combinedDatass?.length
      ? combinedDatass.map((item) => escapeRegExp(item)).sort((a, b) => b.length - a.length)
      : [];

    // Check if combinedDatass is not empty to construct regexadd
    if (ddd.length > 0) {
      const regexadd = new RegExp(`\\b(${ddd.join('|')})(?: \\(\\d+\\))?(?=\\W|$)`, 'gi'); // Case insensitive

      // Combine regexadd with the passed regex (forcing case insensitivity with 'i')
      const combinedPattern = [regexadd.source, regex.source].filter(Boolean).join('|');
      const combinedRegex = new RegExp(combinedPattern, 'gi'); // Case insensitive

      // Perform regex matching with the combined regex
      let matchArr = combinedRegex.exec(text);
      while (matchArr !== null) {
        const start = matchArr.index;
        const end = start + matchArr[0].length;
        callback(start, end);
        matchArr = combinedRegex.exec(text); // Continue matching
      }
    } else {
      // Fallback: Use only the provided regex (forcing case insensitivity)
      const caseInsensitiveRegex = new RegExp(regex.source, 'gi'); // Ensure case insensitivity

      let matchArr = caseInsensitiveRegex.exec(text);
      while (matchArr !== null) {
        const start = matchArr.index;
        const end = start + matchArr[0].length;
        callback(start, end);
        matchArr = caseInsensitiveRegex.exec(text); // Continue matching
      }
    }
  };

  const functionStrategy = (contentBlock, callback) => {
    const functionNames = functions
      ?.map((f) => escapeRegExp(f.name)) // Use 'name' key and escape special characters
      .sort((a, b) => b.length - a.length) // Sort by length (longest first)
      .join('|'); // Join into a single regex pattern

    const regex = new RegExp(`\\b(${functionNames})(?: \\(\\d+\\))?(?=\\W|$)`, 'gi');
    findWithRegex(regex, contentBlock, callback);
  };

  const blocknameStrategy = (contentBlock, callback) => {
    const blocknames = otherBlocks
      ?.map((f) => escapeRegExp(f.blockname))
      .sort((a, b) => b.length - a.length)
      .join('|');
    const regex = new RegExp(`\\b(${blocknames})(?: \\(\\d+\\))?(?=\\W|$)`, 'gi');
    findWithRegex(regex, contentBlock, callback);
  };

  const indicatorStrategy = (contentBlock, callback) => {
    const blocknames = otherBlocks
      ?.map((f) => escapeRegExp(f.indicator))
      .sort((a, b) => b.length - a.length)
      .join('|');
    const regexPattern = `\\b(${blocknames})(?: \\(\\d+\\))?(?=\\W|$)`;
    const regex = new RegExp(regexPattern, 'gi');
    findWithRegex(regex, contentBlock, callback);
  };

  const dimensionStrategy = (contentBlock, callback) => {
    const sortedDimensions = dimensionFormula
      ?.map(({ dimName }) => escapeRegExp(dimName)) // Escape the individual dimension names
      ?.sort((a, b) => b.length - a.length); // Sort by length, longest first

    if (sortedDimensions && sortedDimensions.length > 0) {
      const escapedIndicator = sortedDimensions.join('|'); // Join them with '|'
      findWithRegex(
        new RegExp(`\\b(${escapedIndicator})(?: \\(\\d+\\))?(?=\\W|$)`, 'gi'),
        contentBlock,
        callback,
      );
    }
  };

  const dimensionPropertyStrategy = (contentBlock, callback) => {
    const sortedProperties = dimensionFormula
      ?.map(({ dimPropertie }) => escapeRegExp(dimPropertie))
      ?.sort((a, b) => b.length - a.length)
      ?.join('|'); // Sort by length, longest first
    const regexPattern = `\\b(${sortedProperties})(?: \\(\\d+\\))?(?=\\W|$)`;
    const regex = new RegExp(regexPattern, 'gi');
    findWithRegex(regex, contentBlock, callback);
  };

  const dimensionNameStrategy = (contentBlock, callback) => {
    const sortedDimensions = dimensionItemsFormula
      ?.map(({ dimName }) => escapeRegExp(dimName)) // Escape the individual dimension names
      ?.sort((a, b) => b.length - a.length); // Sort by length, longest first

    if (sortedDimensions && sortedDimensions.length > 0) {
      const escapedIndicator = sortedDimensions.join('|'); // Join them with '|'
      findWithRegex(
        new RegExp(`\\b(${escapedIndicator})(?: \\(\\d+\\))?(?=\\W|$)`, 'gi'),
        contentBlock,
        callback,
      );
    }
  };

  const dimensionItemStrategy = (contentBlock, callback) => {
    const sortedItems = dimensionItemsFormula
      ?.map(({ dimItem }) => escapeRegExp(dimItem))
      ?.sort((a, b) => b.length - a.length)
      ?.join('|'); // Sort by length, longest first
    const regexPattern = `\\b(${sortedItems})(?: \\(\\d+\\))?(?=\\W|$)`;
    const regex = new RegExp(regexPattern, 'gi');
    findWithRegex(regex, contentBlock, callback);
  };

  // const dimensionValuesStrategy = (contentBlock, callback) => {
  //   const sortedItems = dimensionValuesFormula
  //     ?.map(({ dimValue }) => escapeRegExp(dimValue))
  //     ?.sort((a, b) => b.length - a.length)
  //     ?.join('|'); // Sort by length, longest first
  //   const regexPattern = `\\b(${sortedItems})(?: \\(\\d+\\))?(?=\\W|$)`;
  //   const regex = new RegExp(regexPattern, 'gi');
  //   findWithRegex(regex, contentBlock, callback);
  // };

  const currBlockIndicatorStrategy = (contentBlock, callback) => {
    const sortedIndicators = currBlockIndicator
      ?.map(({ indName }) => escapeRegExp(indName))
      .sort((a, b) => b.length - a.length)
      .join('|'); // Escape the individual indicator names
    const regexPattern = `\\b(${sortedIndicators})(?: \\(\\d+\\))?(?=\\W|$)`;
    const regex = new RegExp(regexPattern, 'gi');
    findWithRegex(regex, contentBlock, callback);
  };

  const FunctionSpan = (props) => {
    const { contentState, entityKey, children } = props;
    if (!entityKey) {
      return <span>{children}</span>;
    }
    const entity = contentState?.getEntity(entityKey); // This line may be causing the error
    const { type } = entity.data;
    const className = `function-${type}`;

    return <span className={className}>{children}</span>;
  };

  const BlocknameSpan = (props) => {
    const { contentState, entityKey, children } = props;
    if (!entityKey) {
      return <span>{children}</span>;
    }
    const entity = contentState?.getEntity(entityKey); // This line may be causing the error
    const { type } = entity.data;
    const className = `blockname-${type}`;
    return <span className={className}>{children}</span>;
  };

  const IndicatorSpan = (props) => {
    const { contentState, entityKey, children } = props;
    if (!entityKey) {
      return <span>{children}</span>;
    }
    const entity = contentState?.getEntity(entityKey); // This line may be causing the error
    const { type } = entity.data;
    const className = `indicator-${type}`;
    return <span className={className}>{children}</span>;
  };
  const DimensionSpan = (props) => {
    const { contentState, entityKey, children } = props;
    if (!entityKey) {
      return <span>{children}</span>;
    }
    const entity = contentState?.getEntity(entityKey); // This line may be causing the error
    const { type } = entity.data;
    const className = `dimeItem-${type}`;
    return <span className={className}>{children}</span>;
  };
  const DimensionPropertySpan = (props) => {
    const { contentState, entityKey, children } = props;
    if (!entityKey) {
      return <span>{children}</span>;
    }
    const entity = contentState.getEntity(entityKey); // This line may be causing the error
    const { type } = entity.data;
    const className = `dimProperty-${type}`;
    return <span className={className}>{children}</span>;
  };
  const DimensionItemSpan = (props) => {
    const { contentState, entityKey, children } = props;
    if (!entityKey) {
      return <span>{children}</span>;
    }
    const entity = contentState.getEntity(entityKey); // This line may be causing the error
    const { type } = entity.data;
    const className = `dimItem-${type}`;
    return <span className={className}>{children}</span>;
  };

  // const DimensionValueSpan = (props) => {
  //   const { contentState, entityKey, children } = props;
  //   if (!entityKey) {
  //     return <span>{children}</span>;
  //   }
  //   const entity = contentState.getEntity(entityKey); // This line may be causing the error
  //   const { type } = entity.data;
  //   const className = `dimValue-${type}`;
  //   return <span className={className}>{children}</span>;
  // };

  const currBlockIndicatorSpan = (props) => {
    const { contentState, entityKey, children } = props;
    if (!entityKey) {
      return <span>{children}</span>;
    }
    const entity = contentState?.getEntity(entityKey); // This line may be causing the error
    const { type } = entity.data;
    const className = `currBlockIndicator-${type}`;
    return <span className={className}>{children}</span>;
  };

  useEffect(() => {
    if (isExpanded === false) {
      setShowSuggestions(false);
    }
    if (selectedIndicators) {
      setShowSuggestions(false);
    }
  }, [isExpanded, selectedIndicators]);

  useEffect(() => {
    const decoratorStrategies = {};

    if (otherBlocks.length > 0) {
      decoratorStrategies.othersblockIndicator = [
        {
          strategy: blocknameStrategy,
          component: BlocknameSpan,
        },
        {
          strategy: indicatorStrategy,
          component: IndicatorSpan,
        },
      ];
    }

    if (dimensionFormula.length > 0) {
      decoratorStrategies.dimension = [
        {
          strategy: dimensionStrategy,
          component: DimensionSpan,
        },
        {
          component: DimensionPropertySpan,
          strategy: dimensionPropertyStrategy,
        },
      ];
    }

    if (dimensionItemsFormula.length > 0) {
      decoratorStrategies.dimensionItem = [
        {
          strategy: dimensionNameStrategy,
          component: DimensionSpan,
        },
        {
          component: DimensionItemSpan,
          strategy: dimensionItemStrategy,
        },
      ];
    }

    // if (dimensionValuesFormula.length > 0) {
    //   decoratorStrategies.dimensionValue = [
    //     {
    //       strategy: dimensionValuesStrategy,
    //       component: DimensionValueSpan,
    //     },
    //   ];
    // }

    if (currBlockIndicator.length > 0) {
      decoratorStrategies.currentBlockIndicator = [
        {
          strategy: currBlockIndicatorStrategy,
          component: currBlockIndicatorSpan,
        },
      ];
    }
    if (functions.length > 0) {
      decoratorStrategies.function = [
        {
          strategy: functionStrategy,
          component: FunctionSpan,
        },
      ];
    }

    setDecorator1(decoratorStrategies);
  }, [
    functions,
    otherBlocks,
    dimensionFormula,
    currBlockIndicator,
    dimensionItemsFormula,
    // dimensionValuesFormula,
  ]);
  const createCombinedDecorator = (newType) => {
    // Get the current decorator
    const currentDecorator = editorState.getDecorator();
    const newStrategies = decorator1[newType] || [];
    // If there is an existing decorator, merge its strategies with the new ones
    let existingStrategies = [];
    /* eslint-disable no-underscore-dangle */
    if (currentDecorator) {
      existingStrategies = currentDecorator._decorators.map((decorator) => ({
        strategy: decorator.strategy,
        component: decorator.component,
      }));
    }
    let pre = [];

    if (initialDecorator) {
      pre = initialDecorator._decorators.map((decorator) => ({
        strategy: decorator.strategy,
        component: decorator.component,
      }));
    }
    /* eslint-disable no-underscore-dangle */
    // Combine existing strategies with new ones
    const combinedStrategies = [...pre, ...newStrategies, ...existingStrategies];
    // Return a new CompositeDecorator with all strategies
    return new CompositeDecorator(combinedStrategies);
  };

  const createEntity = (type) => {
    const entityKey = Entity.create('TYPE', 'MUTABLE', { type });
    return entityKey;
  };
  const [tooltipContent, setTooltipContent] = useState('');
  const createDecorator = (types) => {
    const decorators = types.flatMap((type) => decorator1[type] || []);
    return new CompositeDecorator(decorators);
  };

  useEffect(() => {
    if (selectedIndicators && decorator1 && Object.keys(decorator1).length > 0) {
      setMessage({ type: '', text: '', errorStatus: false });
      let data = selectedIndicators?.formula || '';
      data = data.replace(/["']/g, ''); // This will remove all single quotes from the string
      setTooltipContent(data);
      const formulaParsing = selectedIndicators?.calculated_formula?.formula_map || [];
      // Clear the existing content
      let contentState = ContentState.createFromText('');
      let selection = contentState.getSelectionAfter();
      let startOffset = 0;
      let remainingText = data;

      formulaParsing.forEach((suggestion) => {
        const textToInsert = suggestion.name;
        const entityKey = createEntity(suggestion.type);
        // Find the position of the suggestion in the remaining text
        const matchIndex = remainingText.toLowerCase().indexOf(textToInsert.toLowerCase());
        if (matchIndex !== -1) {
          // Insert the text before the match as plain text
          const unmatchedText = remainingText.slice(0, matchIndex);
          if (unmatchedText) {
            selection = selection.merge({
              anchorOffset: startOffset,
              focusOffset: startOffset,
            });
            contentState = Modifier.insertText(contentState, selection, unmatchedText);
            startOffset += unmatchedText.length;
          }

          // Insert the matched text with the entity
          selection = selection.merge({
            anchorOffset: startOffset,
            focusOffset: startOffset,
          });
          contentState = Modifier.insertText(
            contentState,
            selection,
            textToInsert,
            null,
            entityKey,
          );
          startOffset += textToInsert.length;

          // Update the remaining text
          remainingText = remainingText.slice(matchIndex + textToInsert.length);
        }
      });

      // Insert any remaining unmatched text as plain text
      if (remainingText.length > 0) {
        selection = selection.merge({
          anchorOffset: startOffset,
          focusOffset: startOffset,
        });
        contentState = Modifier.insertText(contentState, selection, remainingText);
        startOffset += remainingText.length; // Update the startOffset to include the remaining text length
      }
      // Move the cursor to the end of the inserted content
      selection = selection.merge({
        anchorOffset: startOffset,
        focusOffset: startOffset,
      });
      const newEditorState = EditorState.push(editorState, contentState, 'insert-characters');
      const uniqueTypes = [...new Set(formulaParsing.map((f) => f.Type || f.type))];

      // Create decorator with unique types
      const combinedDecorator = createDecorator(uniqueTypes);
      setInitialDecorator(combinedDecorator);
      setEditorState(EditorState.set(newEditorState, { decorator: combinedDecorator }));
      editorRef.current.blur();
    }
  }, [decorator1, selectedIndicators, isEsc]);

  const handleKeyCommand = (command) => {
    if (command === 'split-block') {
      // 'split-block' corresponds to the Enter key
      return 'handled'; // Prevent the default behavior of adding a new line
    }
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return 'handled';
    }
    return 'not-handled';
  };

  const handleBeforeInput = (chars) => {
    const bracketsMap = {
      '{': '}',
      '[': ']',
      '(': ')',
      '"': '"',
      "'": "'",
    };

    if (bracketsMap[chars]) {
      const currentContent = editorState.getCurrentContent();
      const selection = editorState.getSelection();

      const newContent = Modifier.insertText(currentContent, selection, chars + bracketsMap[chars]);

      const newEditorState = EditorState.push(editorState, newContent, 'insert-characters');
      setEditorState(
        EditorState.forceSelection(
          newEditorState,
          newContent
            .getSelectionAfter()
            .set('anchorOffset', selection.getAnchorOffset() + 1)
            .set('focusOffset', selection.getAnchorOffset() + 1),
        ),
      );
      return 'handled';
    }

    return 'not-handled';
  };
  const [isDimensionItemsShow, setIsDimensionItemsShow] = useState(false);
  const isCursorInsideBrackets = (text, cursorIndex) => {
    const openingIndex = text.lastIndexOf('[', cursorIndex);
    const closingIndex = text.indexOf(']', cursorIndex);
    // Check if both indices are valid and the cursor is between them
    return (
      openingIndex !== -1 &&
      closingIndex !== -1 &&
      openingIndex < cursorIndex &&
      closingIndex > cursorIndex
    );
  };

  const handleChange = (state) => {
    if (isExpanded) {
      const contentState = state.getCurrentContent();
      const selection = state.getSelection();
      const anchorKey = selection.getAnchorKey();
      const currentContentBlock = contentState.getBlockForKey(anchorKey);
      const blockText = currentContentBlock.getText();
      const blockTextUntilCursor = blockText.slice(0, selection.getAnchorOffset());
      const focusOffset = selection?.getFocusOffset();
      // Check if the content is empty
      if (blockText === '' && blockTextUntilCursor === '') {
        // Clear filtered suggestions and handle the empty state
        setShowSuggestions(false);
        setEditorState(state);

        // Adjust the height of the editor container
        const editorContainer = editorContainerRef.current;
        if (editorContainer) {
          editorContainer.style.height = 'auto';
          editorContainer.style.height = `${editorContainer.scrollHeight}px`;
        }
        return;
      }

      // Regex to capture the last typed word or phrase
      const lastWordMatch = blockTextUntilCursor.match(/(\b\w+\b)$/);
      const isDimItemShow = isCursorInsideBrackets(`${blockText}`, focusOffset - 1);
      setIsDimensionItemsShow(isDimItemShow);
      if (lastWordMatch) {
        const lastPhrase = lastWordMatch[0]?.trim();
        const filteredSuggestions = suggestions?.filter((suggestion) => {
          if (typeof suggestion === 'string') {
            return suggestion?.toLowerCase().includes(lastPhrase?.toLowerCase());
          }
          switch (suggestion.type) {
            case 'function': {
              return suggestion?.name.toLowerCase()?.includes(lastPhrase?.toLowerCase());
            }
            case 'othersblockIndicator': {
              const fullBlockIndicatorName = `${suggestion?.blockname} . ${suggestion?.indicator}`
                .trim()
                .toLowerCase();
              return fullBlockIndicatorName?.toLowerCase()?.includes(lastPhrase.toLowerCase());
            }

            case 'dimension': {
              const fullDimensionName = `${suggestion?.dimName} . ${suggestion?.dimPropertie}`
                .trim()
                .toLowerCase();
              return fullDimensionName?.toLowerCase()?.includes(lastPhrase.toLowerCase());
            }
            case 'dimensionItem': {
              const fullDimensionItem = `${suggestion?.dimName} . ${suggestion?.dimItem}`
                .trim()
                .toLowerCase();
              return fullDimensionItem?.toLowerCase()?.includes(lastPhrase.toLowerCase());
            }

            case 'dimensionValue': {
              const fullDimensionValue = `${suggestion?.dimValue}`.trim().toLowerCase();
              return fullDimensionValue?.toLowerCase()?.includes(lastPhrase.toLowerCase());
            }

            case 'currentBlockIndicator': {
              return suggestion?.indName.toLowerCase()?.includes(lastPhrase?.toLowerCase());
            }

            default:
              return false;
          }
        });
        setSuggestionsList(filteredSuggestions);
        setShowSuggestions(true);
      } else {
        // No last word match, handle as needed
        setShowSuggestions(false);
      }

      setEditorState(state);

      // Adjust the height of the editor container
      const editorContainer = editorContainerRef.current;
      if (editorContainer) {
        editorContainer.style.height = 'auto';
        editorContainer.style.height = `${editorContainer.scrollHeight}px`;
      }
    }
  };

  useEffect(() => {
    if (isExpanded === true) {
      const editorContainer = editorContainerRef.current;
      if (editorContainer) {
        editorContainer.style.height = 'auto';
        editorContainer.style.height = `${editorContainer.scrollHeight}px + '25px`;
        editorContainer.style.width = `100%`;
      }
    }
  }, [editorState, isExpanded]);

  const handleSuggestionClick = (suggestion) => {
    if (isExpanded) {
      const entityKey = createEntity(suggestion?.type);
      const currentContent = editorState?.getCurrentContent();
      const selection = editorState?.getSelection();
      const anchorOffset = selection?.getAnchorOffset();
      const focusOffset = selection?.getFocusOffset();
      const startOffset = Math?.min(anchorOffset, focusOffset);
      const endOffset = Math?.max(anchorOffset, focusOffset);

      const blockKey = selection?.getAnchorKey();
      const block = currentContent?.getBlockForKey(blockKey);
      const blockText = block?.getText();
      const blockTextBeforeCursor = blockText?.slice(0, startOffset);
      const lastWordMatch = blockTextBeforeCursor?.match(/(\w+)$/);
      const lastWordStart = lastWordMatch ? lastWordMatch?.index : startOffset;
      const suggestionText =
        typeof suggestion === 'string'
          ? suggestion
          : suggestion?.type === 'function'
          ? `${suggestion?.name}`
          : suggestion?.type === 'othersblockIndicator'
          ? `${suggestion?.blockname} . ${suggestion?.indicator}`
          : suggestion?.type === 'dimension'
          ? `${suggestion?.dimName} . ${suggestion?.dimPropertie}`
          : suggestion?.type === 'dimensionItem'
          ? `${suggestion?.dimName} . ${suggestion?.dimItem}`
          : suggestion?.type === 'dimensionValue'
          ? `${suggestion?.dimValue}`
          : suggestion?.type === 'currentBlockIndicator'
          ? `${suggestion?.indName}`
          : '';
      const newContent = Modifier?.replaceText(
        currentContent,
        selection.merge({
          anchorOffset: lastWordStart,
          focusOffset: endOffset,
        }),
        suggestionText,
        null, // Entity type
        entityKey,
      );
      let newEditorState = EditorState?.push(editorState, newContent, 'insert-characters');
      const combinedDecorator = createCombinedDecorator(suggestion.type);
      newEditorState = EditorState?.set(newEditorState, { decorator: combinedDecorator });
      // // Apply the new editor state with the combined decorator
      // setEditorState(
      //   EditorState.set(newEditorState, { decorator: combinedDecorator })
      // );
      setEditorState(
        EditorState.forceSelection(
          newEditorState,
          newContent
            .getSelectionAfter()
            .set('anchorOffset', lastWordStart + suggestionText.length)
            .set('focusOffset', lastWordStart + suggestionText.length),
        ),
      );

      setShowSuggestions(false);

      setTimeout(() => {
        editorRef.current.focus();
      }, 0);
    }
  };
  const renderSuggestions = () => {
    // Check if there's any content for each section
    const hasCurrentBlockIndicators = suggestionsList?.some(
      (suggestion) => suggestion?.type === 'currentBlockIndicator',
    );
    const hasOtherBlockIndicators = suggestionsList?.some(
      (suggestion) => suggestion?.type === 'othersblockIndicator',
    );
    const hasDimensions = suggestionsList?.some((suggestion) => suggestion?.type === 'dimension');
    const hasDimensionsItems = suggestionsList?.some(
      (suggestion) => suggestion?.type === 'dimensionItem',
    );
    // const hasDimensionsValues = suggestionsList?.some(
    //   (suggestion) => suggestion?.type === 'dimensionValue',
    // );
    const hasFunctions = suggestionsList?.some((suggestion) => suggestion?.type === 'function');

    return (
      <div>
        {hasCurrentBlockIndicators && (
          <Box borderBottom={'1px solid grey'} p={3}>
            <Text
              className='formula-list-ignore'
              fontSize={'14px'}
              fontWeight={'600'}
              mb={2}
              color={'black'}
            >
              Indicators in this block
            </Text>
            {suggestionsList
              ?.filter((suggestion) => suggestion?.type === 'currentBlockIndicator')
              ?.map((suggestion, index) => (
                <Box
                  px={2}
                  key={index}
                  className={`suggestion-item`}
                  onClick={() => handleSuggestionClick(suggestion)}
                  py={'0.5rem'}
                  cursor={'pointer'}
                  width={'100%'}
                >
                  <Text
                    className='currBlockIndicator-currentBlockIndicator'
                    width={'fit-content'}
                    color={'black'}
                    px={'1rem'}
                  >
                    {suggestion?.indName}
                  </Text>
                </Box>
              ))}
          </Box>
        )}

        {hasOtherBlockIndicators && (
          <Box p={3} borderBottom={'1px solid grey'}>
            <Text
              className='formula-list-ignore'
              fontSize={'14px'}
              fontWeight={'600'}
              mb={2}
              color={'black'}
            >
              Indicators in other blocks
            </Text>
            {suggestionsList
              ?.filter((suggestion) => suggestion?.type === 'othersblockIndicator')
              ?.map((suggestion, index) => (
                <Box
                  key={index}
                  className={`suggestion-item`}
                  onClick={() => handleSuggestionClick(suggestion)}
                  py={'0.5rem'}
                  cursor={'pointer'}
                >
                  <Text>
                    <span
                      className='blockname-othersblockIndicator'
                      style={{ width: 'fit-content', paddingLeft: '1rem', paddingRight: '1rem' }}
                    >
                      {suggestion?.blockname}
                    </span>{' '}
                    <span
                      className='blockname-othersblockIndicator'
                      style={{ width: 'fit-content', paddingLeft: '1rem', paddingRight: '1rem' }}
                    >
                      {suggestion?.indicator}
                    </span>
                  </Text>
                </Box>
              ))}
          </Box>
        )}

        {hasDimensions && (
          <Box p={3} borderBottom={'1px solid grey'}>
            <Text
              className='formula-list-ignore'
              fontSize={'14px'}
              fontWeight={'600'}
              mb={2}
              color={'black'}
            >
              Dimensions properties
            </Text>
            {suggestionsList
              ?.filter((suggestion) => suggestion?.type === 'dimension')
              ?.map((suggestion, index) => (
                <Box
                  key={index}
                  className={`suggestion-item`}
                  onClick={() => handleSuggestionClick(suggestion)}
                  py={'0.5rem'}
                  cursor={'pointer'}
                >
                  <Text>
                    <span
                      className='dimeItem-dimension'
                      style={{ width: 'fit-content', paddingLeft: '1rem', paddingRight: '1rem' }}
                    >
                      {suggestion?.dimName}
                    </span>{' '}
                    <span
                      className='dimeItem-dimension'
                      style={{ width: 'fit-content', paddingLeft: '1rem', paddingRight: '1rem' }}
                    >
                      {suggestion?.dimPropertie}
                    </span>
                  </Text>
                </Box>
              ))}
          </Box>
        )}

        {isDimensionItemsShow === true && hasDimensionsItems && (
          <Box p={3} borderBottom={'1px solid grey'}>
            <Text
              className='formula-list-ignore'
              fontSize={'14px'}
              fontWeight={'600'}
              mb={2}
              color={'black'}
            >
              Dimensions Items
            </Text>
            {suggestionsList
              ?.filter((suggestion) => suggestion?.type === 'dimensionItem')
              ?.map((suggestion, index) => (
                <Box
                  key={index}
                  className={`suggestion-item`}
                  onClick={() => handleSuggestionClick(suggestion)}
                  py={'0.5rem'}
                  cursor={'pointer'}
                >
                  <Text>
                    <span
                      className='dimeItem-dimensionItem'
                      style={{ width: 'fit-content', paddingLeft: '1rem', paddingRight: '1rem' }}
                    >
                      {suggestion?.dimName}
                    </span>{' '}
                    <span
                      className='dimeItem-dimensionItem'
                      style={{ width: 'fit-content', paddingLeft: '1rem', paddingRight: '1rem' }}
                    >
                      {suggestion?.dimItem}
                    </span>
                  </Text>
                </Box>
              ))}
          </Box>
        )}

        {/* {hasDimensionsValues && (
          <Box p={3} borderBottom={'1px solid grey'}>
            <Text
              className='formula-list-ignore'
              fontSize={'14px'}
              fontWeight={'600'}
              mb={2}
              color={'black'}
            >
              Dimensions Values
            </Text>
            {suggestionsList
              ?.filter((suggestion) => suggestion?.type === 'dimensionValue')
              ?.map((suggestion, index) => (
                <Box
                  key={index}
                  className={`suggestion-item`}
                  onClick={() => handleSuggestionClick(suggestion)}
                  py={'0.5rem'}
                  cursor={'pointer'}
                >
                  <Text>
                    <span
                      className='dimensionValue-dimensionValue'
                      style={{ width: 'fit-content', paddingLeft: '1rem', paddingRight: '1rem' }}
                    >
                      {suggestion?.dimValue}
                    </span>
                  </Text>
                </Box>
              ))}
          </Box>
        )} */}

        {hasFunctions && (
          <Box p={3} borderBottom={'1px solid grey'}>
            <Text
              className='formula-list-ignore'
              fontSize={'14px'}
              fontWeight={'600'}
              mb={2}
              color={'black'}
            >
              Functions
            </Text>
            {suggestionsList
              ?.filter((suggestion) => suggestion.type === 'function')
              ?.map((suggestion, index) => (
                <Box
                  px={2}
                  key={index}
                  className={`suggestion-item`}
                  onClick={() => handleSuggestionClick(suggestion)}
                  py={'0.5rem'}
                  width={'100%'}
                  cursor={'pointer'}
                >
                  <Text
                    width={'fit-content'}
                    className='function-function'
                    px={'1rem'}
                    color={'black'}
                  >
                    {suggestion?.name}
                  </Text>
                </Box>
              ))}
          </Box>
        )}
      </div>
    );
  };
  // It fetches Indicator data after submitting formula.
  const fetchIndicatorsData = () => {
    const idArray = plannerBlock?.dimensions
      ?.filter((item) => item?.name !== 'Time')
      ?.map((item) => item?.id);
    const dimensionIds = idArray?.join(', ');
    const payload = {
      blockId,
      params: {
        dim_id: dimensionIds,
        indicator_filter: 'all',
        scenario_id: baseScenario?.id,
      },
    };
    dispatch(FetchBlockOutputs(payload));
  };
  const handleFieldChanges = async (updatedFormula) => {
    const indicatorFormula = await dispatch(
      UpdateIndicatorFormula({
        indicatorId: selectedIndicators?.id,
        data: {
          formula: updatedFormula,
        },
      }),
    );
    if (indicatorFormula?.error) {
      fetchIndicatorsData();
      setMessage({ type: 'error', text: indicatorFormula?.payload, errorStatus: true });
    } else {
      fetchIndicatorsData();
      setMessage({ type: 'success', text: 'Formula parsed successfully', errorStatus: false });
      setTimeout(() => {
        setMessage({ type: '', text: '', errorStatus: false });
      }, 4000);
    }
    setIsExpanded(false);
  };
  const handleReturn = () => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    // Extract blocks and entityMap from raw content
    const { blocks, entityMap } = rawContent;
    // Initialize an array to hold the formatted text
    let formattedText = '';
    blocks.forEach((block) => {
      const blockText = block.text;
      const entities = block.entityRanges;
      // Sort entityRanges by their start offset to maintain correct order
      entities.sort((a, b) => a.offset - b.offset);
      let currentOffset = 0;
      entities.forEach((entityRange) => {
        const { offset, length, key } = entityRange;
        const entity = entityMap[key];
        // Append text before this entity
        if (offset > currentOffset) {
          formattedText += blockText.slice(currentOffset, offset);
        }
        // Append entity text with formatting
        const entityText = blockText.slice(offset, offset + length);
        const entityType = entity.data.type;
        // Apply formatting based on entity type
        if (entityType === 'othersblockIndicator') {
          formattedText += `'${entityText}'`;
        } else if (entityType === 'dimension') {
          formattedText += `'${entityText}'`;
        } else if (entityType === 'dimensionItem') {
          formattedText += `'${entityText}'`;
        } else if (entityType === 'dimensionValue') {
          formattedText += `'${entityText}'`;
        } else if (entityType === 'currentBlockIndicator') {
          formattedText += `'${entityText}'`;
        } else if (entityType === 'function') {
          formattedText += `'${entityText}'`;
        } else if (entityType === undefined) {
          formattedText += `'${entityText}'`;
        }
        // Update currentOffset
        currentOffset = offset + length;
      });
      // Append any remaining text after the last entity
      if (currentOffset < blockText.length) {
        formattedText += blockText.slice(currentOffset);
      }
      // // Add a space or delimiter between blocks if necessary
      // formattedText += 'a';
    });
    formattedText = formattedText
      .split(' . ')
      .map((segment, index) => (index === 0 ? `${segment}'` : `'${segment}'`))
      .join('.');
    // Remove the trailing space and log the result
    formattedText = formattedText
      .trim()
      .replace(/(?=\s)/g, '')
      .slice(0, -1);
    const output = formattedText;
    handleFieldChanges(output);
    setShowSuggestions(false);
  };
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        // Allow Shift + Enter to create a new line
        const newState = RichUtils.insertSoftNewline(editorState);
        setEditorState(newState);
      } else {
        handleReturn();
      }
      e.preventDefault(); // Prevent the default behavior (like inserting a new line)
    } else if (e.key === 'Escape') {
      setIsEsc(true);
      setIsExpanded(false);
      e.preventDefault(); // Prevent the default behavior
    }
  };

  return (
    <Flex
      display={'flex'}
      justifyContent={'start'}
      alignItems={'center'}
      width={'100%'}
      transition='width 0.3s ease-in-out'
    >
      <FunctionIconComponent
        color='white'
        style={{ margin: '0 10px 0 15px', height: 30, minWidth: 30 }}
      />
      <div
        className={`${isExpanded ? 'editor-container1' : 'editor-container'}`}
        style={{
          position: 'relative',
          transition: 'width 0.3s ease-in-out',
        }}
      >
        <div
          ref={editorContainerRef}
          style={{
            transition: 'width 0.3s ease-in-out',
            marginTop: isExpanded ? '25px' : '0px',
          }}
          onKeyDown={handleKeyDown}
        >
          <Tooltip label={isExpanded ? '' : tooltipContent} hasArrow>
            <Flex
              alignItems={'center'}
              className={'editor'}
              onClick={() => setIsExpanded(true)}
              width={isExpanded ? '100%' : '90%'}
              transition='width 0.6s ease-in-out'
              border={`1px solid ${message?.errorStatus ? 'red' : '#ccc'}`}
            >
              <Editor
                editorState={editorState}
                handleKeyCommand={handleKeyCommand}
                handleBeforeInput={handleBeforeInput}
                onChange={handleChange}
                // handleKeyDown={handleKeyDown}
                editorRef={editorRef}
                readOnly={isExpanded !== true}
              />
              {isExpanded && (
                <Link href='https://blox.tawk.help/category/formulas/blox-functions' isExternal>
                  <QuestionIcon />
                </Link>
              )}
            </Flex>
          </Tooltip>

          <Flex display='flex' justifyItems={'center'} justifyContent={'space-between'}>
            <Flex display='flex' justifyItems={'center'}>
              {message?.errorStatus && message?.type === 'error' && (
                <Text color={'red'}>{message?.text}</Text>
              )}
              {message?.type === 'success' && <Text color={'green'}>Formula Submitted</Text>}
            </Flex>
            {isExpanded && (
              <Flex justifyContent='center'>
                <Text color='white'>
                  <Text as='span' fontWeight='bold'>
                    Enter
                  </Text>{' '}
                  to Submit,
                </Text>
                <Text color='white'>
                  <Text as='span' fontWeight='bold'>
                    &nbsp;Shift+Enter
                  </Text>{' '}
                  to add a new line
                </Text>
              </Flex>
            )}
          </Flex>
          {showSuggestions && (
            <div
              className='suggestion-box'
              style={{
                ...suggestionBoxStyle,
                position: 'fixed',
                zIndex: 1000,
                width: '50%',
                backgroundColor: 'white',
                // border: '1px solid #ccc',
                maxHeight: '350px',
                overflowY: 'auto',
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: '11px',
              }}
            >
              {renderSuggestions()}
            </div>
          )}
        </div>
      </div>
      {isExpanded && (
        <Button
          bgColor='#7163D0'
          ms={'1rem'}
          px={2}
          borderRadius={'4px'}
          border='1px solid transparent'
          outline='none'
          width='100px'
          height='40px'
          filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))'
          onClick={handleReturn}
        >
          Submit
        </Button>
      )}
    </Flex>
  );
};

export default DraftJSFormula;
