import { Flex, Text, Box, useMediaQuery } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import LoginImage from 'components/Icons/LoginImage';
import { getAuthenticationPageType } from 'redux/AuthSlice';

const AuthenticationIllustration = () => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const authPageType = useSelector(getAuthenticationPageType);

  return (
    <>
      {authPageType === 3 ? (
        <Box position={'absolute'} top={'0'} right={'0'}>
          <LoginImage
            width={{ start: '180px', sm: '200px' }}
            height={{ start: '180px', sm: '200px' }}
            styles={{ borderRadius: '15px' }}
          />
        </Box>
      ) : !isMobile ? (
        <Flex flexDir={'column'} alignItems={'center'} width={'100%'}>
          <LoginImage
            width={'100%'}
            height={'70%'}
            styles={{ borderRadius: '15px', objectFit: authPageType === 1 ? 'none' : 'cover' }}
          />
          {authPageType !== 3 && (
            <Text color={'green.900'} fontSize='2xl' fontFamily={'bold'}>
              Start your planning!
            </Text>
          )}
        </Flex>
      ) : (
        <Box position={'absolute'} top={'0'} right={'0'}>
          <LoginImage
            width={{ start: '180px', sm: '200px' }}
            height={{ start: '180px', sm: '200px' }}
            styles={{ borderRadius: '15px' }}
          />
        </Box>
      )}
    </>
  );
};

export default AuthenticationIllustration;
