import { Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { useDispatch } from 'react-redux';

import { SetTabs, SetUserModalVisible } from 'redux/UserSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

const TimeScalePermission = ({ maxValue, timescale, modelType }: any) => {
  const [isSmallDevice] = useMediaQuery('(max-width: 820px)');
  const dispatch: AppDispatch = useDispatch();
  const fontStyle = {
    color: '#FFF',
    fontFamily: 'regular_roboto',
    fontWeight: 400,
  };

  return (
    <Flex
      bg={'#3BB8BC'}
      justify={'center'}
      align={'start'}
      borderRadius={'6px'}
      my={2}
      width={'270px'}
      alignSelf={'center'}
      flexDirection={'column'}
      height={'50px'}
      p={2}
    >
      <Flex m={0} p={0}>
        <InfoOutlineIcon color={'white'} />
        <Text {...fontStyle} fontSize={isSmallDevice ? '2xs' : 'xs'} ms={1}>
          {modelType} model limits to {maxValue} {timescale === 'M' ? `months` : `years`},
        </Text>
      </Flex>
      <Flex m={0} p={0}>
        <InfoOutlineIcon visibility={'hidden'} color={'white'} />
        <Text
          {...fontStyle}
          fontSize={isSmallDevice ? '2xs' : 'xs'}
          fontWeight={600}
          textDecoration={'underline'}
          cursor={'pointer'}
          onClick={async () => {
            await dispatch(SetUserModalVisible(true));
            await dispatch(SetTabs(5));
          }}
          ms={1}
        >
          Upgrade
        </Text>
        <Text {...fontStyle} fontSize={isSmallDevice ? '2xs' : 'xs'} ms={1}>
          to unlock more.
        </Text>
      </Flex>
    </Flex>
  );
};

export default TimeScalePermission;
