import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const OrderdList = ({ width = 4, height = 4, color = 'green.900' }: Properties) => {
  return (
    <Icon viewBox='0 0 38 38' width={width} height={height} color={color}>
      <path
        d='M14.3734 29.8725V26.7923H33.378V29.8725H14.3734ZM14.3734 20.6319V17.5517H33.378V20.6319H14.3734ZM14.3734 11.3913V8.3111H33.378V11.3913H14.3734ZM8.03852 31.4126C7.16748 31.4126 6.42181 31.111 5.80153 30.5078C5.18124 29.9046 4.87109 29.1795 4.87109 28.3324C4.87109 27.4854 5.18124 26.7602 5.80153 26.157C6.42181 25.5538 7.16748 25.2522 8.03852 25.2522C8.90957 25.2522 9.65523 25.5538 10.2755 26.157C10.8958 26.7602 11.206 27.4854 11.206 28.3324C11.206 29.1795 10.8958 29.9046 10.2755 30.5078C9.65523 31.111 8.90957 31.4126 8.03852 31.4126ZM8.03852 22.172C7.16748 22.172 6.42181 21.8704 5.80153 21.2672C5.18124 20.664 4.87109 19.9389 4.87109 19.0918C4.87109 18.2448 5.18124 17.5196 5.80153 16.9164C6.42181 16.3132 7.16748 16.0116 8.03852 16.0116C8.90957 16.0116 9.65523 16.3132 10.2755 16.9164C10.8958 17.5196 11.206 18.2448 11.206 19.0918C11.206 19.9389 10.8958 20.664 10.2755 21.2672C9.65523 21.8704 8.90957 22.172 8.03852 22.172ZM8.03852 12.9314C7.16748 12.9314 6.42181 12.6298 5.80153 12.0266C5.18124 11.4234 4.87109 10.6983 4.87109 9.8512C4.87109 9.00414 5.18124 8.27901 5.80153 7.67581C6.42181 7.0726 7.16748 6.771 8.03852 6.771C8.90957 6.771 9.65523 7.0726 10.2755 7.67581C10.8958 8.27901 11.206 9.00414 11.206 9.8512C11.206 10.6983 10.8958 11.4234 10.2755 12.0266C9.65523 12.6298 8.90957 12.9314 8.03852 12.9314Z'
        fill={color}
      />
    </Icon>
  );
};

export default OrderdList;
