import './Switch.scss';

interface Properties {
  handleChange: () => void;
}

const Switch = ({ handleChange }: Properties) => (
  <div className='switch-button' onClick={handleChange}>
    <input className='switch-button-checkbox' type='checkbox'></input>
    <label className='switch-button-label'>
      <span className='switch-button-label-span'>Monthly</span>
    </label>
  </div>
);

export default Switch;
