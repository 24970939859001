import { Box, Flex, Image, Skeleton, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import Expand from 'assets/expand_icon.png';
import MultiDimSetting from 'pages/PlanPageForecast/Inputs/IndicatorPageMultiDim/MultiDimSetting';
import MultiDimensionTable from 'pages/PlanPageForecast/Inputs/IndicatorPageMultiDim/MultiDimensionTable';
import { getExandSideInputStatus, handleExpandSideInput } from 'redux/BuilderModeSlice';
import {
  FetchBlockItemDimensions,
  FetchPlangPageIndicatorTableData,
  fetchBlock,
} from 'redux/PlanPageSlice';
import { getBaseScenario, getSelectedIndicators } from 'redux/PlannerModeSlice';
import { fetchAllScenarios } from 'redux/ScenarioSlice';

interface Properties {
  refetchRequest: () => void;
  isPercentage: any;
}

const IndicatorTable = ({ refetchRequest, isPercentage }: Properties) => {
  const dispatch: any = useDispatch();
  const { modelId, blockId } = useParams();
  // const indicatorTableData = useSelector(getIndica)
  const currentDriver = useSelector(getSelectedIndicators);

  // const dimensionItems = useSelector(getDimensionItems);
  // const plannerModel = useSelector(getPlannerModel);
  // const plannerBlock = useSelector(getPlannerBlock);
  // const dataInputsValue: any = useSelector(getDataInput);
  // const isExpand = useSelector(getExpandValue);
  // const currentDimensionIndex = useSelector(getCurrentDimensionIndex);
  const baseScenario = useSelector(getBaseScenario);
  const { pathname } = useLocation();
  const isExpand = useSelector(getExandSideInputStatus);

  useEffect(() => {
    if (modelId && blockId) {
      dispatch(fetchBlock(Number(blockId)));
      dispatch(
        FetchBlockItemDimensions({
          blockId,
        }),
      );
      dispatch(fetchAllScenarios(Number(modelId)));
    }
  }, [pathname]);

  useEffect(() => {
    if (currentDriver && baseScenario) {
      dispatch(
        FetchPlangPageIndicatorTableData({
          indicator_id: currentDriver?.id,
          parameters: {
            scenario_id: baseScenario?.id,
            format_excel_data: true,
          },
        }),
      );
    }
  }, [currentDriver, baseScenario?.id, isExpand]);

  // useEffect(() => {
  //   if (isExpand) refetchRequest();
  // }, [isExpand]);

  // const commonObjects =
  //   plannerBlock?.dimensions?.length > 1
  //     ? plannerModel?.dimensions?.filter((object1: any) =>
  //         plannerBlock?.dimensions?.some(
  //           (object2: any) => object1.id === object2.id && object1.name !== 'Time',
  //         ),
  //       )
  //     : plannerModel?.dimensions?.filter((object1: any) =>
  //         plannerBlock?.dimensions?.some((object2: any) => object1.id === object2.id),
  //       );

  // const dimensionId =
  //   currentDriver?.data_input?.dimensions[currentDimensionIndex] || commonObjects[0]?.id;
  // const fetchDimItemsData = () => {
  //   dispatch(
  //     fetchDimensionItems({
  //       dimensionId,
  //       parameters: {
  //         scenario_id: baseScenario?.id,
  //       },
  //     }),
  //   );
  // };
  // useEffect(() => {
  //   if (currentDriver && currentDriver?.data_input?.dimensions?.length > 0) {
  //     fetchDimItemsData();
  //   }
  // }, [pathname, currentDriver?.id, baseScenario?.id, currentDimensionIndex, dimensionId]);
  // useEffect(() => {
  //   if (currentDriver) {
  //     fetchDimItemsData();
  //   }
  // }, [pathname, dimensionId]);
  // useEffect(() => {
  //   dispatch(
  //     FetchDataInput({
  //       indicator_id: currentDriver?.id,
  //       parameters: {
  //         scenario_id: baseScenario?.id,
  //         format_excel_data: true,
  //       },
  //     }),
  //   );
  // }, [indicator?.input[0].type, plannerBlock?.dimensions?.length > 1, baseScenario]);
  // useEffect(() => {
  //   dispatch(setExpand(dataInputsValue?.dimensions?.length > 0));
  // }, [dataInputsValue?.dimensions]);

  if (!currentDriver) {
    return (
      <Flex direction='column' pb='1'>
        {[...new Array(6)].map((_, index) => (
          <Flex key={index} p={2} justifyContent={'space-between'}>
            {[...new Array(4)].map((__, subIndex: number) => (
              <Box key={subIndex} flex={1} mr={2}>
                <Skeleton startColor='white' endColor='#CCD4E8' height='35px' width={'100%'} />
              </Box>
            ))}
          </Flex>
        ))}
      </Flex>
    );
  }

  return (
    <>
      <Flex
        my={1.5}
        justifyContent={'space-between'}
        flexDirection={isExpand ? 'row-reverse' : 'row'}
      >
        <Box>
          {isExpand ? (
            <Box
              // width='100%'
              // justifyContent={'end'}
              // alignItems={'center'}
              cursor={'pointer'}
              display='flex'
              onClick={() => dispatch(handleExpandSideInput(false))}
            >
              <Text fontSize={'xs'}>Collapse view</Text>
              <Image height={'1.2rem'} ml={1} src={Expand} />
            </Box>
          ) : (
            <Box
              // width='100%'
              // justifyContent={'end'}
              // alignItems={'center'}
              display='flex'
              cursor={'pointer'}
              onClick={() => dispatch(handleExpandSideInput(true))}
            >
              <Text fontSize={'xs'}>Expand view</Text>
              <Image height={'1.2rem'} ml={1} src={Expand} />
            </Box>
          )}
        </Box>
        <MultiDimSetting indicatorId={currentDriver?.id} refetchRequest={refetchRequest} />
      </Flex>
      <Flex h={'fit-content'}>
        <MultiDimensionTable
          isBuilderMode={true}
          refetchRequest={refetchRequest}
          isPercentage={isPercentage}
        />
      </Flex>
    </>
  );
};

export default IndicatorTable;
