declare global {
  interface Window {
    Tawk_API: any;
    Tawk_LoadStart: Date;
  }
}

function TawkTo(propertyId: string, email: string, name: string, userId: string) {
  if (!window) throw new Error('DOM is unavailable');

  window.Tawk_API = window.Tawk_API || {};
  window.Tawk_LoadStart = new Date();

  // pass attributes to tawk.to on widget load
  window.Tawk_API.onLoad = () => {
    window.Tawk_API.setAttributes({
      propertyId,
      name,
      email,
      userId,
    });
  };

  const tawk = document.querySelector('#tawkId');
  if (tawk) return window.Tawk_API;

  const script = document.createElement('script');
  script.async = true;
  script.src = `https://embed.tawk.to/65d5d8b59131ed19d96f54a3/1hn5ld1qq`;
  script.charset = 'utf8';
  script.setAttribute('crossorigin', '*');

  const firstScriptTag = document.querySelectorAll('script')[0];
  if (!firstScriptTag || !firstScriptTag.parentNode) {
    throw new Error('DOM is unavailable');
  }

  firstScriptTag.parentNode.insertBefore(script, firstScriptTag);
}

export default TawkTo;
