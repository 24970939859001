import { Image, StyleProps } from '@chakra-ui/react';

import addicon from 'assets/add_icon.png';

interface Properties {
  width?: number;
  height?: number;
  styles?: StyleProps;
}

const AddIcon = ({ width = 34, height = 27, styles }: Properties) => {
  return <Image src={addicon} w={width} h={height} {...styles} />;
};

export default AddIcon;
