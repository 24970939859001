const LightCalender = () => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      width='20'
      height='20'
      fill='white'
    >
      <g>
        <g>
          <defs>
            <polyline id='SVGID_1_' points='2.9,1.6 17.4,1.6 17.4,17.2 2.9,17.2 2.9,1.6 			' />
          </defs>
          <path
            d='M6.9,12.5
			c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.2,0.3-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6c0.2,0.1,0.3,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2
			c0.2-0.1,0.2-0.3,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6C7.3,12.6,7.1,12.5,6.9,12.5 M10.1,12.5c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.2,0.3-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6c0.2,0.1,0.3,0.2,0.6,0.2s0.4-0.1,0.6-0.2c0.2-0.1,0.2-0.3,0.2-0.6
			c0-0.2-0.1-0.4-0.2-0.6C10.5,12.6,10.4,12.5,10.1,12.5 M13.3,12.5c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.2,0.3-0.2,0.6
			c0,0.2,0.1,0.4,0.2,0.6c0.2,0.1,0.3,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.2-0.3,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6
			C13.8,12.6,13.6,12.5,13.3,12.5 M6.9,9.4c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.2,0.3-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6
			c0.2,0.1,0.3,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.2-0.3,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6C7.3,9.5,7.1,9.4,6.9,9.4
			 M10.1,9.4c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.1-0.2,0.3-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6c0.2,0.1,0.3,0.2,0.6,0.2s0.4-0.1,0.6-0.2
			c0.2-0.1,0.2-0.3,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6C10.5,9.5,10.4,9.4,10.1,9.4 M13.3,9.4c-0.2,0-0.4,0.1-0.6,0.2
			c-0.2,0.1-0.2,0.3-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6c0.2,0.1,0.3,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2c0.2-0.1,0.2-0.3,0.2-0.6
			c0-0.2-0.1-0.4-0.2-0.6C13.8,9.5,13.6,9.4,13.3,9.4 M4.5,15.6V7.8h11.3v7.8H4.5 M4.5,6.3V4.7h11.3v1.6H4.5 M14.9,1.6h-1.6v1.6H6.9
			V1.6H5.3v1.6H4.5c-0.4,0-0.8,0.2-1.1,0.5C3,3.9,2.9,4.3,2.9,4.7v10.9c0,0.4,0.2,0.8,0.5,1.1c0.3,0.3,0.7,0.5,1.1,0.5h11.3
			c0.4,0,0.8-0.2,1.1-0.5c0.3-0.3,0.5-0.7,0.5-1.1V4.7c0-0.4-0.2-0.8-0.5-1.1c-0.3-0.3-0.7-0.5-1.1-0.5h-0.8V1.6'
          />
        </g>
      </g>
    </svg>
  );
};

export default LightCalender;
