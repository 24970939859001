import { Box, Text, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import moment from 'moment';
import dayjs from 'dayjs';

import DatePickerCustom from '../../../../components/DatePicker';

const DatePickerDrivers = ({ values, onSubmitValue, id }: any) => {
  const isDateValid = (date: string) => {
    return !Number.isNaN(new Date(date).getTime()) && date;
  };

  const startDateKey = Object.keys(values).find((key) => {
    if (
      `${key}`.toLowerCase().trim() === 'start date' ||
      `${key}`.toLowerCase().trim() === 'start'
    ) {
      return key;
    }
    return false;
  });

  const endDateKey = Object.keys(values).find((key) => {
    if (`${key}`.toLowerCase().trim() === 'end date' || `${key}`.toLowerCase().trim() === 'end') {
      return key;
    }
    return false;
  });

  const possibleFormats: Array<string> = ['MMM-YY', 'MMM YY', 'MMM YYYY', 'MMM-YYYY'];

  const [startIn, setStartIn] = useState<Date | null>(() => {
    return startDateKey && isDateValid(values[startDateKey])
      ? new Date(moment(values[startDateKey], possibleFormats, 'locale').toDate())
      : null;
  });

  const [endIn, setEndIn] = useState<Date | null>(() => {
    return endDateKey && isDateValid(values[endDateKey])
      ? new Date(moment(values[endDateKey], possibleFormats, 'locale').toDate())
      : null;
  });

  const clearDateInput = (
    dateId: number,
    deleteStart: boolean,
    startDate = startIn,
    endDate = endIn,
  ) => {
    if (deleteStart) {
      onSubmitValue(null, dateId, startDateKey);
      setStartIn(null);
      setEndIn(endDate);
    }
    if (!deleteStart) {
      onSubmitValue(null, dateId, endDateKey);
      setStartIn(startDate);
      setEndIn(null);
    }
  };
  const handleEnddateChange = (date: any) => {
    const startDate = moment(startIn);
    const endDate = moment(date);
    if (startDate.isBefore(endDate)) {
      setEndIn(new Date(date));
    } else if (startDate.isAfter(endDate)) {
      setEndIn(endIn);
    } else {
      setEndIn(new Date(date));
    }
  };
  const handleStartdateChange = (date: any) => {
    const startDate = moment(date);
    const endDate = moment(endIn);
    if (startDate.isBefore(endDate)) {
      setStartIn(new Date(date));
    } else if (startDate.isAfter(endDate)) {
      setStartIn(startIn);
    } else {
      setStartIn(new Date(date));
    }
  };

  return (
    <Flex justifyContent='space-between'>
      {startDateKey ? (
        <Box>
          <Text fontFamily='Roboto Regular' fontSize='xs' pb='8px'>
            Start Date
          </Text>
          <DatePickerCustom
            timescale='M'
            dateFormat='M'
            selected={startIn}
            onChange={(date: Date) => {
              handleStartdateChange(date);
            }}
            onSelect={(date: Date) => onSubmitValue(dayjs(date).format('MMM-YY'), id, startDateKey)}
            onSubmitValue={onSubmitValue}
            dateId={id}
            setStartIn={setStartIn}
            clearDateInput={clearDateInput}
            startIn={startIn}
            endIn={endIn}
            deleteStart={true}
          />
        </Box>
      ) : (
        <></>
      )}

      {endDateKey ? (
        <Box>
          <Text fontFamily='Roboto Regular' fontSize='xs' pb='8px'>
            End Date
          </Text>
          <DatePickerCustom
            timescale='M'
            dateFormat='M'
            selected={endIn}
            onChange={(date: Date) => {
              handleEnddateChange(date);
            }}
            onSelect={(date: Date) => onSubmitValue(dayjs(date).format('MMM-YY'), id, endDateKey)}
            onSubmitValue={onSubmitValue}
            dateId={id}
            setStartIn={setStartIn}
            clearDateInput={clearDateInput}
            startIn={startIn}
            endIn={endIn}
            deleteStart={false}
          />
        </Box>
      ) : (
        <></>
      )}
    </Flex>
  );
};

export default DatePickerDrivers;
