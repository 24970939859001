import * as yup from 'yup';

import * as MESSAGES from '../messages';

const registerSchema = yup.object().shape({
  firstName: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  lastName: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  role: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  company: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  email: yup.string().email(MESSAGES.EMAIL_MESSAGE).required(MESSAGES.REQUIRE_MESSAGE),
  password: yup.string().required(MESSAGES.REQUIRE_MESSAGE).min(6).max(20),
});

export default registerSchema;
