import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Tooltip,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import DimensionTabIcon from 'components/Icons/ModelOverviewTabs/DimensionTabIcon';
import { handleBuilderModeTab } from 'redux/BuilderModeSlice';
import {
  FetchBlockOutputs,
  getBaseScenario,
  getPlannerBlock,
  getPlannerModel,
  UpdateBlockDimension,
} from 'redux/PlannerModeSlice';
import { fetchBlock, FetchBlockItemDimensions } from 'redux/PlanPageSlice';

const PlannerModeBlockSetting = () => {
  const { blockId, modelId } = useParams();
  const dispatch: any = useDispatch();
  const baseScenario = useSelector(getBaseScenario);
  const plannerModel = useSelector(getPlannerModel);
  const plannerBlock = useSelector(getPlannerBlock);
  const navigate = useNavigate();

  const doesDimensionExist = (dim: any, dimArray: any[]) => {
    return dimArray?.some((data) => data?.id === dim?.id);
  };

  const fetchIndicatorsData = (dimensions: any) => {
    const idArray = dimensions;
    const array2Ids = plannerBlock?.dimensions.find((object: any) => object.name === 'Time');
    const arrayIndex = idArray?.indexOf(array2Ids?.id);
    if (arrayIndex !== -1) {
      idArray.splice(arrayIndex, 1);
    }
    const dimensionIds = idArray.join(', ');
    const payload = {
      blockId,
      params: {
        dim_id: dimensionIds,
        indicator_filter: 'all',
        scenario_id: baseScenario?.id,
      },
    };
    dispatch(FetchBlockOutputs(payload));
  };

  const handleSettingChange = async (data: any, event: any) => {
    const dimensionsList = plannerBlock?.dimensions.filter((item: any) => item.name !== 'Time');
    let dimensions: any[] = plannerBlock?.dimensions || [];
    const dataIndex = dimensions.findIndex((item) => item?.id === data?.id);
    dimensions =
      dataIndex !== -1
        ? dimensions.filter((item, index) => index !== dataIndex)
        : [...dimensions, data];
    dimensions = dimensions.map((item) => item.id);
    await dispatch(
      UpdateBlockDimension({
        blockId,
        request_body: { dimension_id: dimensions },
        toaster: true,
      }),
    ).then((response: any) => {
      if (!response?.error) {
        fetchIndicatorsData(dimensions);
        dispatch(fetchBlock(Number(blockId)));
      }
    });
    await dispatch(
      FetchBlockItemDimensions({
        blockId,
      }),
    );
    if ((event.target.checked && dimensionsList?.length === 0) || !event.target.checked) {
      let dimension: any[] = plannerBlock?.dimensions || [];
      const dataIndexes = dimension.findIndex((item) => item?.id === data?.id);
      dimension =
        dataIndexes !== -1
          ? dimension.filter((item, index) => index !== dataIndexes)
          : [...dimension, data];
      dimension = dimension.map((item) => item.id);
      dispatch(UpdateBlockDimension({ blockId, request_body: { dimension_id: dimension } })).then(
        (response: any) => {
          if (!response?.error) {
            fetchIndicatorsData(dimension);
          }
        },
      );
    }
  };

  const renderMenuList = (list: any) => {
    const timeObject = list?.find((item: any) => item?.name === 'Time');
    const remainingList = list?.filter(
      (item: any) => !plannerBlock?.dimensions.some((element: any) => element?.id === item?.id),
    );
    const dimensionsList = plannerBlock?.dimensions?.filter((item: any) => item?.name !== 'Time');
    const filteredList = [
      ...(timeObject ? [timeObject] : []),
      ...(dimensionsList || []),
      ...(remainingList || []),
    ];
    return (
      <Box maxH={'190px'} overflowY={'auto'} className='scroll'>
        {filteredList?.map((data: any, index: number) => (
          <MenuItem key={index} padding={'.5rem 0'} as={'li'}>
            <Switch
              mr={2}
              disabled={data?.name === 'time' || data?.name === 'Time'}
              isChecked={doesDimensionExist(data, plannerBlock?.dimensions)}
              onChange={(event: any) => handleSettingChange(data, event)}
            ></Switch>
            {data?.name}
          </MenuItem>
        ))}
      </Box>
    );
  };

  const redirectToDimension = () => {
    navigate(`/model/${modelId}/home`);
    dispatch(handleBuilderModeTab(1));
  };

  return (
    <Flex alignItems={'center'}>
      <Menu closeOnSelect={false} variant={'primary'}>
        <Tooltip label='Block Dimensions'>
          <MenuButton
            pl={2}
            pr={3}
            py={2}
            color={'white'}
            fontSize={'16px'}
            transition='all 0.2s'
            borderWidth='1px'
          >
            <DimensionTabIcon width={7} height={6} color='#4BB6B9' /> (
            {plannerBlock?.dimensions?.length}) <ChevronDownIcon />
          </MenuButton>
        </Tooltip>
        <MenuList w={'230px'} pb={0} px={4} maxH={'250px'} zIndex={10}>
          {renderMenuList(plannerModel?.dimensions)}
          <Divider color={'#dfdfdf'} mt={2} />
          <Button
            fontSize={'xs'}
            px={0}
            pt={3}
            pb={2}
            variant={'primary'}
            onClick={() => redirectToDimension()}
          >
            Edit dimensions
          </Button>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default PlannerModeBlockSetting;
