import {
  Box,
  Image,
  Text,
  Flex,
  useBoolean,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useMediaQuery,
  Tooltip,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, Suspense, lazy } from 'react';
import { AddIcon } from '@chakra-ui/icons';

import infoIcon from 'assets/info_icon.svg';
import noAction from 'assets/no_action_icon.png';
import {
  clearStepperFormData,
  deleteActionData,
  duplicateActionFile,
  editActionDetails,
  getActionData,
  getActionList,
  getOneActionData,
  getSelectedAction,
  getSelectedDataFileDetails,
  setActionCreateForm,
  setActionDetails,
  setActionFormEdit,
  setClearSelectedAreasTable,
  versionData,
} from 'redux/DataImportSlice';
import DataSet from 'components/Icons/DataSet';
import Update from 'components/Icons/Update';
import CustomInputField from 'components/CustomInput';
import DataImportIcon from 'components/Icons/DataImportIcon';
import DeleteModal from 'components/Modals/DeleteModal';

const LazyMappingDimensionModal = lazy(() => import('./MappingDimensionModal'));
const LazyMappingDataModal = lazy(() => import('./MappingDataModel'));

const commonStyles = {
  display: 'flex',
  alignItems: 'center',
};

const ActionData = ({ selectedFileDetails, setactionCreate }: any) => {
  const [isSmallDevice] = useMediaQuery('(max-width: 900px)');
  const dispatch: any = useDispatch();
  const selectedSourceDetails = useSelector(getSelectedDataFileDetails);
  const actionData = useSelector(getActionList);
  const [renameItemId, setRenameItemId] = useState<number | null>(null);
  const [renameItemValue, setRenameItemValue] = useState<string | null>(null);
  const [dimensionMappingModal, setDimensionMappingModal] = useBoolean();
  const [dataMappingModal, setDataMappingModal] = useBoolean();
  const [selectedActionId, setSelectedActionId] = useState<number | null>(null);
  const [selectedMapType, setSelectedMapType] = useState('');
  const [scenarioId, setScenarioId] = useState<number | null>(null);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteIdModal, setDeleteIdModal]: any = useState(null);
  const [DeleteNameModal, setDeleteNameModal] = useState('');
  const dataSourceId = selectedSourceDetails?.id;
  const selectedAction = useSelector(getSelectedAction);
  useEffect(() => {
    if (dataSourceId) {
      dispatch(versionData(dataSourceId));
      dispatch(getActionData(Number(dataSourceId)));
    }
  }, [dataSourceId]);

  const renameItem = (event: any, id: number) => {
    event.stopPropagation();
    if (renameItemId === id) {
      setRenameItemId(0);
    }
    setRenameItemId(id);
  };

  const duplicateItem = async (event: any, id: number) => {
    event.stopPropagation();
    await dispatch(duplicateActionFile({ dataSourceId, actionId: id }));
    await dispatch(getActionData(dataSourceId));
  };
  const UpdateItemApi = async (item_id: Number) => {
    const requestBody = {
      name: renameItemValue,
    };
    await dispatch(
      editActionDetails({
        dataSourceId,
        actionId: item_id,
        request_body: requestBody,
        toaster: {
          successMessage: 'Successfully updated',
          errorMessage: 'An Source with this name already exists',
        },
      }),
    );
    await setRenameItemId(0);
    await dispatch(getActionData(dataSourceId));
  };

  const onKeyPressAction = async (event: any, item_id: number) => {
    event.stopPropagation();
    if (event.keyCode === 13) {
      await UpdateItemApi(item_id);
      setRenameItemValue(null);
    }
    if (event.key === 'Escape') setRenameItemValue(null);
  };
  const deleteScenarioModal = async (event: any, id: number, name: any) => {
    event.stopPropagation();
    setIsDeleteModal(true);
    setDeleteIdModal(id);
    setDeleteNameModal(name);
  };
  const onDeleteModel = async () => {
    await dispatch(deleteActionData({ dataSourceId, actionId: deleteIdModal }));
    await dispatch(getActionData(dataSourceId));
    await setIsDeleteModal(false);
    await setDeleteIdModal(null);
    await setDeleteNameModal('');
  };
  const run = (item: any) => {
    if (item.action_type === 'dimension') {
      setDimensionMappingModal.on();
    } else {
      setDataMappingModal.on();
    }

    setSelectedActionId(item?.id);
    setSelectedMapType(item?.action_type);
    setScenarioId(item?.scenario_id);
  };

  return (
    <>
      <Flex
        flexDirection={'column'}
        maxH={`35vh`}
        overflowX={'hidden'}
        overflowY={'auto'}
        className='scroll'
        mt={'1rem'}
      >
        <Flex flexDirection={'column'} maxH={'full'} pe={2}>
          {actionData && actionData?.length > 0 ? (
            actionData.map((item: any, index: number) => (
              <Box
                w={'100%'}
                minH='45px'
                {...commonStyles}
                px={3}
                gap={3}
                borderRadius={'9px'}
                width='full'
                cursor={'pointer'}
                mb={'11.50px'}
                key={index}
                bg={selectedAction?.actionId === item?.id ? '#4BB6B9' : '#7163D0'}
                display={'flex'}
                justifyContent={'space-between'}
                boxShadow='2px 3px 5px rgba(0, 0, 0, 0.25)'
              >
                <Flex
                  w={'50%'}
                  gap={3}
                  align={'center'}
                  onClick={() => {
                    dispatch(setActionDetails({ actionId: item.id }));
                    dispatch(getOneActionData({ dataSourceId, actionId: item.id }));
                  }}
                >
                  {item.action_type === 'data' ? (
                    <Flex
                      height={'29px'}
                      width={'21px'}
                      justify={'center'}
                      align={'center'}
                      ps={'0.2rem'}
                    >
                      <DataImportIcon
                        color={
                          selectedAction?.actionId === item?.id
                            ? 'rgba(101, 98, 207, 1)'
                            : '#4BB6B9'
                        }
                      />
                    </Flex>
                  ) : (
                    <DataSet
                      color={
                        selectedAction?.actionId === item?.id ? 'rgba(101, 98, 207, 1)' : '#4BB6B9'
                      }
                    />
                  )}
                  <Flex w={'100%'} noOfLines={1} align={'center'}>
                    {renameItemId === item?.id ? (
                      <CustomInputField
                        alignContent='center'
                        fontSize={isSmallDevice ? '2xs' : 'xs'}
                        whiteSpace='nowrap'
                        overflow='hidden'
                        width={'100%'}
                        outline={'none !important'}
                        boxShadow={'none !important'}
                        border={'1px #fff solid !important'}
                        padding={'13px 5px 13px 10px'}
                        defaultValue={item?.name}
                        height='0.9rem'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setRenameItemValue(event.target.value);
                        }}
                        onKeyDown={(event: React.ChangeEvent<HTMLInputElement>) => {
                          if (event.target.value.trim().length > 0)
                            onKeyPressAction(event, item?.id);
                        }}
                        _placeholder={{ color: 'white' }}
                        autoFocus
                        color='white'
                        onBlur={() => {
                          setRenameItemId(0);
                        }}
                        required
                      />
                    ) : (
                      <Tooltip label={item?.name}>
                        <Text fontSize={isSmallDevice ? '2xs' : 'xs'}>{item?.name}</Text>
                      </Tooltip>
                    )}
                  </Flex>
                </Flex>
                <Flex gap={3} align={'center'} w={'50%'} justifyContent={'end'}>
                  <Menu closeOnSelect={true} variant='primary'>
                    <MenuButton
                      as={Button}
                      border={'none'}
                      width={'fit-content'}
                      padding={0}
                      minW={'fit-content'}
                      mr={2}
                    >
                      <Image
                        src={infoIcon}
                        w='18px'
                        h='18px'
                        className='infoButton'
                        cursor={'pointer'}
                      />
                    </MenuButton>
                    <MenuList>
                      <MenuItem
                        onClick={(event) => renameItem(event, item.id)}
                        fontSize={isSmallDevice ? '11px' : '14px'}
                        fontFamily='regular_roboto'
                      >
                        Rename
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          dispatch(getOneActionData({ dataSourceId, actionId: item.id }));
                          setactionCreate(true);
                          dispatch(setActionCreateForm(true));
                          dispatch(
                            setActionFormEdit({
                              isEditForm: true,
                              actionId: item?.id,
                              actionType: item?.action_type,
                              scenarioId: item?.scenario_id,
                            }),
                          );
                        }}
                        fontSize={isSmallDevice ? '11px' : '14px'}
                        fontFamily='regular_roboto'
                      >
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => {
                          deleteScenarioModal(event, item?.id, item?.name);
                        }}
                        // onClick={(event) => {
                        //   actionDelete(event, item?.id);
                        // }}
                        fontSize={isSmallDevice ? '11px' : '14px'}
                        fontFamily='regular_roboto'
                      >
                        Delete
                      </MenuItem>
                      <MenuItem
                        onClick={(event) => duplicateItem(event, item.id)}
                        fontSize={isSmallDevice ? '11px' : '14px'}
                        fontFamily='regular_roboto'
                      >
                        Duplicate
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <Text
                    fontSize={isSmallDevice ? '2xs' : 'xs'}
                    onClick={() => {
                      run(item);
                    }}
                  >
                    Run
                  </Text>
                  <Flex
                    onClick={() => {
                      run(item);
                    }}
                  >
                    <Update color={selectedAction?.actionId === item?.id ? 'white' : '#4BB6B9'} />
                  </Flex>
                </Flex>
              </Box>
            ))
          ) : (
            <Flex flexDirection={'column'} mt={1}>
              <Box
                minH='40px'
                {...commonStyles}
                px={3}
                gap={3}
                borderRadius={'9px'}
                width='full'
                border={'1px dashed rgba(255, 255, 255, 0.40)'}
                cursor={'pointer'}
                onClick={() => {
                  setactionCreate(true);
                  dispatch(setActionCreateForm(true));
                }}
                boxShadow='2px 3px 5px rgba(0, 0, 0, 0.25)'
              >
                + Add action
              </Box>
              <Box
                width='full'
                display={'flex'}
                justifyContent={'end'}
                alignItems={'center'}
                py={5}
              >
                <Box width={'50%'}>
                  <Text textAlign={'start'}>
                    Now add an action to your source to import data from your file
                  </Text>
                </Box>
                <Box width={'40%'}>
                  <Image h={30} w={30} src={noAction} />
                </Box>
              </Box>
            </Flex>
          )}
        </Flex>
      </Flex>
      {actionData && selectedFileDetails?.deatsilsPage === true && actionData?.length > 0 && (
        <Flex
          ps={'10px'}
          pt={'1.5rem'}
          cursor={'pointer'}
          onClick={async () => {
            await dispatch(clearStepperFormData());
            await dispatch(setClearSelectedAreasTable());
            await setactionCreate(true);
            await dispatch(setActionCreateForm(true));
          }}
        >
          <AddIcon />
        </Flex>
      )}
      <DeleteModal
        isOpen={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
        itemName={DeleteNameModal}
        onDelete={onDeleteModel}
      />
      <Suspense fallback={<div>Loading...</div>}>
        {dimensionMappingModal && (
          <LazyMappingDimensionModal
            mappingModal={dimensionMappingModal}
            setMappingModal={setDimensionMappingModal}
            selectedActionId={selectedActionId}
            dataSourceId={dataSourceId}
            selectedMapType={selectedMapType}
            scenarioId={scenarioId}
          />
        )}
      </Suspense>
      <Suspense fallback={<div>Loading...</div>}>
        {dataMappingModal && (
          <LazyMappingDataModal
            mappingModal={dataMappingModal}
            setMappingModal={setDataMappingModal}
            selectedActionId={selectedActionId}
            dataSourceId={dataSourceId}
            selectedMapType={selectedMapType}
            scenarioId={scenarioId}
          />
        )}
      </Suspense>
    </>
  );
};

export default ActionData;
