import { memo } from 'react';
import { Button, ButtonGroup, HStack, Text } from '@chakra-ui/react';
import { FiGrid, FiList } from 'react-icons/fi';

interface Properties {
  viewMode: string;
  onChange: (value: string) => void;
}

const buttonStyles = {
  w: 'auto',
  h: '100%',
  display: 'flex',
  alignItems: 'center',
  borderWidth: '1',
  borderColor: 'grey.800',
  borderRadius: '7px',
  fontFamily: 'regular',
  fontSize: '12px',
  lineHeight: '11px',
  letterSpacing: '-0.015em',
};

const ModelsViewMode = memo(({ viewMode, onChange }: Properties) => (
  <HStack spacing='4' ml='auto'>
    <Text
      fontFamily='regular'
      fontSize='12px'
      lineHeight='15px'
      letterSpacing='-0.015em'
      color='grey.900'
      whiteSpace={'nowrap'}
    >
      View by:
    </Text>

    <ButtonGroup isAttached variant='outline' size='sm' display='flex' h='25px'>
      <Button
        leftIcon={<FiGrid fontSize='16' />}
        onClick={() => onChange('grid')}
        sx={{
          ...buttonStyles,
          color: viewMode === 'grid' ? 'violet.750' : 'grey.900',
          bgColor: viewMode === 'grid' ? 'violet.75' : 'tranparent',
        }}
      >
        Grid
      </Button>
      <Button
        leftIcon={<FiList fontSize='16' />}
        onClick={() => onChange('list')}
        sx={{
          ...buttonStyles,
          color: viewMode === 'list' ? 'violet.750' : 'grey.900',
          bgColor: viewMode === 'list' ? 'violet.75' : 'tranparent',
        }}
      >
        List
      </Button>
    </ButtonGroup>
  </HStack>
));

export default ModelsViewMode;
