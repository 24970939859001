import { useToast } from '@chakra-ui/react';
import moment from 'moment';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import CustomToast from 'components/CustomToast';
import FullScreenLoad from 'components/FullScreenLoader/FullScreen';
import { useAuthContext } from 'context/authContext';

const WelcomeComponent = () => {
  const [queryParameters] = useSearchParams();
  const { onAuthSuccess } = useAuthContext();
  const toast = useToast();
  const navigate = useNavigate();
  useEffect(() => {
    const token: any = queryParameters.get('token');
    const redirectTo: any = queryParameters.get('redirect_to');
    if (token && redirectTo) {
      const now = moment();
      const future = now.add(14, 'days');
      const formattedDate = future.format('YYYY-MM-DD HH:mm');

      onAuthSuccess(token, formattedDate);
      toast({
        duration: 2500,
        render: () => <CustomToast title='Successfully Added' status='success' />,
      });
      navigate(`/${redirectTo}`);
    } else {
      toast({
        duration: 2500,
        render: () => <CustomToast title='Failed' status='error' />,
      });
    }
  }, []);
  return <FullScreenLoad />;
};
export default WelcomeComponent;
