import { ChevronRightIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';

const LibraryButton = () => (
  <Button
    as='a'
    href='https://www.bloxapp.io/models?beta=true'
    target='_blank'
    rightIcon={<ChevronRightIcon w='6' h='10' />}
    h='8'
    mt='2.5'
    justifyContent='space-between'
    fontFamily='bold'
    fontSize='xs'
    textTransform='none'
    bg='violet.500'
    boxShadow='0px 3.86011px 3.86011px rgba(0, 0, 0, 0.25)'
  >
    Library Blocks
  </Button>
);

export default LibraryButton;
