import * as yup from 'yup';

import * as MESSAGES from '../messages';

const UpdateBlockSchema = yup.object().shape({
  name: yup.string().trim().required(MESSAGES.REQUIRE_MESSAGE),
  icon: yup.string(),
  description: yup.string(),
});

export default UpdateBlockSchema;
