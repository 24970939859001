interface Properties {
  width?: any;
  height?: any;
  color?: string;
  marginRight?: string;
}

const TableIcomComponent = ({ width, height, color, marginRight }: Properties) => {
  return (
    <svg
      style={{ marginRight }}
      width={width}
      height={height}
      fill={color}
      viewBox='0 0 22 22'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='mask0_184_4685' maskUnits='userSpaceOnUse' x='0' y='0' width='22' height='22'>
        <rect x='0.80957' y='0.248169' width='21.0503' height='21.0503' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_184_4685)'>
        <path
          d='M5.19462 18.6671C4.71222 18.6671 4.29925 18.4954 3.95572 18.1518C3.61219 17.8083 3.44043 17.3953 3.44043 16.9129V4.63359C3.44043 4.15118 3.61219 3.73822 3.95572 3.39469C4.29925 3.05116 4.71222 2.87939 5.19462 2.87939H17.474C17.9564 2.87939 18.3693 3.05116 18.7129 3.39469C19.0564 3.73822 19.2282 4.15118 19.2282 4.63359V16.9129C19.2282 17.3953 19.0564 17.8083 18.7129 18.1518C18.3693 18.4954 17.9564 18.6671 17.474 18.6671H5.19462ZM5.19462 16.9129H8.11097V13.9966H5.19462V16.9129ZM9.86516 16.9129H12.8034V13.9966H9.86516V16.9129ZM14.5576 16.9129H17.474V13.9966H14.5576V16.9129ZM5.19462 12.2424H8.11097V9.30413H5.19462V12.2424ZM9.86516 12.2424H12.8034V9.30413H9.86516V12.2424ZM14.5576 12.2424H17.474V9.30413H14.5576V12.2424ZM5.19462 7.54993H8.11097V4.63359H5.19462V7.54993ZM9.86516 7.54993H12.8034V4.63359H9.86516V7.54993ZM14.5576 7.54993H17.474V4.63359H14.5576V7.54993Z'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default TableIcomComponent;
