export default {
  sizes: {
    primary: {
      fontFamily: 'regular',
      fontSize: 'xl',
      lineHeight: '10',
      letterSpacing: '-0.015em',
    },
    secondary: {
      fontFamily: 'regular_roboto',
      fontSize: 'xs',
      lineHeight: '6',
    },
    subTitle: {
      fontFamily: 'bold_roboto',
      fontSize: 'xs',
      lineHeight: '5',
    },
    modalTitle: {
      fontFamily: 'regular',
      fontSize: 'md',
      lineHeight: '5',
    },
    modalContent: {
      fontFamily: 'regular_roboto',
      fontSize: '2xs',
      lineHeight: '3',
    },
    addToDashboard: {
      fontFamily: 'regular',
      fontSize: 'xl',
      lineHeight: '5',
    },
    section: {
      fontFamily: 'bold',
      fontSize: 'lg',
      lineHeight: '9',
      borderBottomWidth: 1,
      borderBottomColor: 'white.400',
    },
    assistant_base: {
      fontFamily: 'regular_roboto',
      fontSize: '3xs',
      lineHeight: '3',
    },
    assistant_md: {
      fontFamily: 'regular_roboto',
      fontSize: 'sm',
      lineHeight: '7',
    },
    subTitle_2: {
      fontFamily: 'bold_roboto',
      fontSize: '2xs',
      lineHeight: '1',
    },
    property_help: {
      fontFamily: 'regular_roboto',
      fontSize: '11px',
      lineHeight: '2',
    },
    home_page_subtitle: {
      fontFamily: 'regular',
      fontSize: '15px',
      lineHeight: '4',
      letterSpacing: '-0.015em',
    },
    home_section_title: {
      fontFamily: 'regular',
      fontSize: 'calc(.5em + .65vw)',
      lineHeight: '7',
      letterSpacing: '-0.015em',
    },
    'Comfortaa-10': {
      fontFamily: 'Comfortaa Regular',
      fontSize: '3xs',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-12': {
      fontFamily: 'Comfortaa Regular',
      fontSize: '2xs',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-14': {
      fontFamily: 'Comfortaa Regular',
      fontSize: 'xs',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-16': {
      fontFamily: 'Comfortaa Regular',
      fontSize: 'sm',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-18': {
      fontFamily: 'Comfortaa Regular',
      fontSize: 'md',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-20': {
      fontFamily: 'Comfortaa Regular',
      fontSize: 'lg',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-24': {
      fontFamily: 'Comfortaa Regular',
      fontSize: 'xl',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-26': {
      fontFamily: 'Comfortaa Regular',
      fontSize: '2xl',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-28': {
      fontFamily: 'Comfortaa Regular',
      fontSize: '3xl',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-30': {
      fontFamily: 'Comfortaa Regular',
      fontSize: '4xl',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-bold-10': {
      fontFamily: 'Comfortaa Bold',
      fontSize: '3xs',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-bold-12': {
      fontFamily: 'Comfortaa Bold',
      fontSize: '2xs',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-bold-14': {
      fontFamily: 'Comfortaa Bold',
      fontSize: 'xs',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-bold-16': {
      fontFamily: 'Comfortaa Bold',
      fontSize: 'sm',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-bold-18': {
      fontFamily: 'Comfortaa Bold',
      fontSize: 'md',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-bold-20': {
      fontFamily: 'Comfortaa Bold',
      fontSize: 'lg',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-bold-24': {
      fontFamily: 'Comfortaa Bold',
      fontSize: 'xl',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-bold-26': {
      fontFamily: 'Comfortaa Bold',
      fontSize: '2xl',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-bold-28': {
      fontFamily: 'Comfortaa Bold',
      fontSize: '3xl',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Comfortaa-bold-30': {
      fontFamily: 'Comfortaa Bold',
      fontSize: '4xl',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-10': {
      fontFamily: 'Roboto Regular',
      fontSize: '3xs',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-12': {
      fontFamily: 'Roboto Regular',
      fontSize: '2xs',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-14': {
      fontFamily: 'Roboto Regular',
      fontSize: 'xs',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-16': {
      fontFamily: 'Roboto Regular',
      fontSize: 'sm',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-18': {
      fontFamily: 'Roboto Regular',
      fontSize: 'md',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-20': {
      fontFamily: 'Roboto Regular',
      fontSize: 'lg',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-24': {
      fontFamily: 'Roboto Regular',
      fontSize: 'xl',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-26': {
      fontFamily: 'Roboto Regular',
      fontSize: '2xl',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-28': {
      fontFamily: 'Roboto Regular',
      fontSize: '3xl',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-30': {
      fontFamily: 'Roboto Regular',
      fontSize: '4xl',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-bold-10': {
      fontFamily: 'Roboto Bold',
      fontSize: '3xs',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-bold-12': {
      fontFamily: 'Roboto Bold',
      fontSize: '2xs',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-bold-14': {
      fontFamily: 'Roboto Bold',
      fontSize: 'xs',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-bold-16': {
      fontFamily: 'Roboto Bold',
      fontSize: 'sm',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-bold-18': {
      fontFamily: 'Roboto Bold',
      fontSize: 'md',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-bold-20': {
      fontFamily: 'Roboto Bold',
      fontSize: 'lg',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-bold-24': {
      fontFamily: 'Roboto Bold',
      fontSize: 'xl',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-bold-26': {
      fontFamily: 'Roboto Bold',
      fontSize: '2xl',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-bold-28': {
      fontFamily: 'Roboto Bold',
      fontSize: '3xl',
      height: 'fit-content',
      // lineHeight: '6',
    },
    'Roboto-bold-30': {
      fontFamily: 'Roboto Bold',
      fontSize: '4xl',
      height: 'fit-content',
      // lineHeight: '6',
    },
  },
  variants: {
    primary_sub_heading: {
      fontFamily: 'regular_roboto',
      fontWeight: 'bold',
      fontSize: 'sm',
      lineHeight: '10',
      color: '#594DA7',
    },
    heading: {
      fontFamily: 'Comfortaa Regular',
      fontSize: '2rem',
    },
    h3: {
      fontFamily: 'Roboto Regular',
      fontSize: 'xl',
    },
    h4: {
      fontFamily: 'Roboto Regular',
      fontSize: 'lg',
    },
    h5: {
      fontFamily: 'Roboto Regular',
      fontSize: '15px',
    },
    h6: {
      fontFamily: 'Roboto Regular',
      fontSize: 'xl',
    },
    modals: {
      fontFamily: 'Comfortaa Regular',
      fontSize: '36px',
      color: '#675ba6',
      fontWeight: '400',
    },
    primary: { color: 'white.900' },
    secondary: { color: 'black.900' },
    dime: { color: 'grey.800' },
    grey: { color: 'grey.900' },
    green: { color: 'green.900' },
    violet: { color: 'violet.750' },
    error: { color: 'red.500' },
    modal: { color: 'violet.700' },
    blockName: {
      alignItems: 'start',
      marginBottom: '0',
      display: 'inline-block',
      width: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      color: 'black',
      fontWeight: 'bold',
      lineHeight: '13px',
      fontSize: '12px',
      fontFamily: 'bold_roboto',
    },
  },
  defaultProps: {
    size: 'main',
    variant: 'main',
  },
};
