import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  Tooltip,
  useOutsideClick,
} from '@chakra-ui/react';
import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import { Scenario } from 'models/response/scenario.interface';
import FormField from 'components/FormField';
import { CreateScenarios, FetchScenarios, RenameScenario } from 'redux/BuilderModeSlice';
import ScenarioDatePicker from 'pages/PlanPageForecast/Plan/Filters/ScenarioDatePicker';
import { getModelDetails } from 'redux/PlanPageSlice';
import { getBlocks } from 'redux/ModelsSlice';

const validationSchema = Yup.object({
  scenarioName: Yup.string().required('Scenario name is required'),
  scenarioDescription: Yup.string(),
  // startDate: Yup.string().required('StartDate is required'),
});

const ScenarioFormForecast = ({
  scenarios,
  baseScenarios,
  createScenario,
  getAllscenarios,
  editScenarioData,
  isEdit,
  renameScenarioApi,
  onCancel,
}: any) => {
  const { modelId } = useParams();
  const [startIn, setStartIn] = useState<string>('');
  const modelDetails = useSelector(getModelDetails);
  const blockDetails = useSelector(getBlocks);
  const reference = useRef() as React.MutableRefObject<HTMLInputElement>;
  const blockData = Object.keys(blockDetails).length > 0 ? blockDetails : modelDetails;
  const [selectedScenarioId, setSelectedScenarioId] = useState<number | null>(baseScenarios?.id);
  const selectedScenario = scenarios.find(
    (scenario: Scenario) => scenario?.id === selectedScenarioId,
  );
  useEffect(() => {
    setSelectedScenarioId(baseScenarios?.id);
  }, []);
  useEffect(() => {
    if (isEdit) {
      setStartIn(editScenarioData?.start_date);
    }
  }, [isEdit === true]);

  const handleSubmit = async (values: any, { resetForm }: any) => {
    if (isEdit) {
      let requestBody = {};
      if (values.scenarioName !== editScenarioData?.name) {
        requestBody = { ...requestBody, name: values?.scenarioName };
      }
      if (values.scenarioDescription !== editScenarioData?.description) {
        requestBody = { ...requestBody, description: values?.scenarioDescription };
      }
      if (values.startDate !== editScenarioData?.start_date) {
        requestBody = { ...requestBody, start_date: values?.startDate };
      }

      if (Object.keys(requestBody).length === 0) {
        onCancel();
        resetForm();
        return;
      }
      await renameScenarioApi({
        item_id: editScenarioData?.id,
        requestBody,
        toaster: {
          successMessage: 'Successfully updated',
          errorMessage: 'An scenario with this name already exists',
        },
      });
    } else {
      const crearteRequestBody = {
        modelId,
        data: {
          name: values?.scenarioName,
          description: values?.scenarioDescription,
          duplicate_id: selectedScenarioId,
          start_date: values?.startDate,
        },
        toaster: {
          successMessage: 'Successfully added scenario',
          errorMessage: 'A scenario with this name already exists',
        },
      };
      await createScenario(crearteRequestBody);
    }
    await getAllscenarios(modelId);
    resetForm();
    onCancel();
  };
  const handleCancel = (resetForm: () => void) => {
    onCancel();
    resetForm();
  };
  const handleClearSelection = () => {
    setStartIn('');
  };

  useOutsideClick({
    ref: reference,
    handler: () => {
      setStartIn('');
    },
  });

  return (
    <Box ref={reference}>
      <Text color='#6562CF' fontSize={'xs'} fontWeight={'600'} pb={'1.5'}>
        Enter Details
      </Text>
      <Formik
        initialValues={{
          scenarioName: editScenarioData?.name || '',
          scenarioDescription: editScenarioData?.description || '',
          duplicateScenarioId: '',
          startDate: editScenarioData?.start_date || '',
        }}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue, resetForm }: any) => (
          <Form>
            <FormControl>
              <FormField
                label='Name'
                name='scenarioName'
                type='text'
                labelStyles={{
                  fontSize: '0.9rem',
                  color: 'black',
                }}
                inputStyles={{
                  maxWidth: '300px',
                  height: '2.3rem',
                  boxShadow: '0px 0px 0px 0.6px #8B89B1',
                  fontSize: '0.8rem',
                  color: 'black',
                }}
                placeholder='Name'
                error={errors.scenarioName}
                touched={touched.scenarioName}
              />
            </FormControl>

            <FormControl mt={4}>
              <FormField
                label='Description'
                name='scenarioDescription'
                type='text'
                labelStyles={{
                  fontSize: '0.9rem',
                  color: 'black',
                }}
                inputStyles={{
                  maxWidth: '300px',
                  fontSize: '0.8rem',
                  color: 'black',
                  height: '2.3rem',
                  boxShadow: '0px 0px 0px 0.6px #8B89B1',
                }}
                placeholder='Description'
              />
            </FormControl>
            {!isEdit && (
              <FormControl mt={4}>
                <FormLabel
                  fontSize='0.9rem'
                  color='black'
                  htmlFor='duplicateScenario'
                  fontFamily={'Roboto Regular'}
                >
                  Duplicate Scenario
                </FormLabel>
                <Menu>
                  <MenuButton
                    style={{
                      border: '0.6px solid #8B89B1',
                      borderRadius: '7px',
                      fontSize: '0.9rem',
                      color: 'black',
                      maxWidth: '260px',
                      fontWeight: 300,
                      height: '2.4rem',
                      textTransform: 'capitalize',
                      textAlign: 'left',
                    }}
                    color='black'
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    <Tooltip label={selectedScenario?.name}>
                      <Box
                        overflow='hidden'
                        whiteSpace='nowrap'
                        textOverflow='ellipsis'
                        maxWidth='100%'
                      >
                        {selectedScenario?.name}
                      </Box>
                    </Tooltip>
                  </MenuButton>
                  <MenuList
                    borderRadius={'10px'}
                    color='#212121'
                    style={{ border: '0.6px solid #8B89B1' }}
                    minWidth={'auto'}
                    height={'fit-content'}
                    maxHeight={'30vh'}
                    width={'fit-content'}
                    maxWidth={'260px'}
                    overflowX={'hidden'}
                    overflowY={'auto'}
                    className='scroll'
                    position='fixed'
                    left={0}
                    bottom={0}
                  >
                    {scenarios?.map((scenario: Scenario) => (
                      <MenuOptionGroup
                        key={scenario?.id}
                        bg={selectedScenario === scenario ? '#EFEEFE' : 'transparent'}
                        color='black'
                        fontSize={'xs'}
                        value={selectedScenario?.name}
                        type='radio'
                      >
                        <MenuItemOption
                          className='scenario-menu'
                          bg={selectedScenario === scenario ? '#EFEEFE' : 'transparent'}
                          width={'100%'}
                          value={scenario.name}
                          onClick={() => {
                            setFieldValue('duplicateScenarioId', scenario?.id);
                            setSelectedScenarioId(scenario?.id);
                          }}
                        >
                          <Tooltip label={scenario?.name}>
                            <Text
                              overflow='hidden'
                              whiteSpace='nowrap'
                              textOverflow='ellipsis'
                              width={'100%'}
                            >
                              {scenario.name}
                            </Text>
                          </Tooltip>
                        </MenuItemOption>
                      </MenuOptionGroup>
                    ))}
                  </MenuList>
                </Menu>
              </FormControl>
            )}
            <FormControl mt={4}>
              <FormLabel
                fontSize='0.9rem'
                color='black'
                htmlFor='selecetDimension'
                fontFamily={'Roboto Regular'}
              >
                Forecast start date
              </FormLabel>
              <Flex bgColor={'white'}>
                <ScenarioDatePicker
                  clearSection={false}
                  clearDateInput={handleClearSelection}
                  selected={startIn ? new Date(startIn) : null}
                  onChange={(date: Date) => {
                    setStartIn(date.toDateString());
                    setFieldValue('startDate', date.toDateString());
                  }}
                  timescale={blockData?.time_properties?.time_granularity === 'Y' ? 'Y' : 'M'}
                />
              </Flex>
              <ErrorMessage name='startDate' component='div' className='error' />
            </FormControl>
            <Flex justifyContent={'flex-end'} pt='3'>
              <Flex
                mt={2}
                color={'black.900'}
                fontSize='xs'
                display={'flex'}
                width={'65%'}
                alignContent={'center'}
              >
                <Button
                  type='reset'
                  onClick={() => handleCancel(resetForm)}
                  width={'80px'}
                  height={'30px'}
                  borderRadius='xs'
                  fontSize='xs'
                  bgColor='#EFEEFE'
                  color='#6562CF'
                  fontWeight='400'
                  me={'1rem'}
                  fontFamily={'Roboto Regular'}
                  px='10'
                  py='4'
                >
                  Cancel
                </Button>
                <Button
                  type='submit'
                  width={'80px'}
                  height={'30px'}
                  borderRadius='xs'
                  fontSize='xs'
                  bgColor='#6562CF'
                  fontWeight='400'
                  fontFamily={'Roboto Regular'}
                  px='10'
                  py='4'
                >
                  Save
                </Button>
              </Flex>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

const mapStateToProperties = (state: any) => {
  return {
    scenarios: state.builderModeSlice?.allScenarios,
    baseScenarios: state.builderModeSlice?.baseScenario,
  };
};
const mapDispatchToProperties = (dispatch: any) => {
  return {
    createScenario: (requestBody: any) => dispatch(CreateScenarios(requestBody)),
    renameScenarioApi: (data: any) => dispatch(RenameScenario(data)),
    getAllscenarios: (modelId: number) => dispatch(FetchScenarios(modelId)),
  };
};

export default connect(mapStateToProperties, mapDispatchToProperties)(ScenarioFormForecast);
