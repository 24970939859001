import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Stack,
  VStack,
  Text,
  Image,
  Box,
  Button,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import CoordinationBlox from 'assets/coordination_icon.png';
import {
  FetchSubscriptionFeatureLimits,
  FetchUserDetails,
  getUserInvitation,
  UpdateUserInvitations,
} from 'redux/UserSlice';
import { GetUserTeamListResponseData } from 'models/UsersSlice.interface';
import LightUserAvatar from 'components/Icons/LightUserAvatar';
import { getAuthModalStatus, hideAuthenticationModal } from 'redux/SharedSlice';

interface ModalState {
  status: boolean;
  type: number;
  newOwnerId: number | null;
}

const textStyles = {
  fontFamily: 'regular',
  fontWeight: '700',
  color: '#6562CF',
};

const paragraphStyles = {
  fontSize: 'sm',
  fontFamily: 'regular_roboto',
  fontWeight: '500',
  color: '#000',
};

const AppWrapper = ({ children }: any) => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();
  const userInvitation = useSelector(getUserInvitation);
  const authModal = useSelector(getAuthModalStatus);
  const [currentModal, setCurrentModal] = useState<ModalState>({
    status: true,
    type: 1,
    newOwnerId: null,
  });

  const handleInvitation = (type: string, newOwnerId?: number | null) => {
    if (type === 'cancel') {
      dispatch(
        UpdateUserInvitations({
          request_body: {
            invite_response: false,
          },
        }),
      ).then(() => {
        setCurrentModal({ status: false, type: currentModal.type, newOwnerId: null });
      });
    } else if (type !== 'cancel' && newOwnerId) {
      dispatch(
        UpdateUserInvitations({
          request_body: {
            invite_response: true,
            new_owner_id: newOwnerId,
          },
        }),
      ).then(() => {
        setCurrentModal({ status: false, type: currentModal.type, newOwnerId: null });
        navigate('/');
        dispatch(FetchUserDetails({}));
        dispatch(FetchSubscriptionFeatureLimits());
      });
    } else {
      dispatch(
        UpdateUserInvitations({
          request_body: {
            invite_response: true,
          },
        }),
      ).then(async () => {
        setCurrentModal({ status: false, type: currentModal.type, newOwnerId: null });
        navigate('/');
        await dispatch(FetchUserDetails({}));
        await dispatch(FetchSubscriptionFeatureLimits());
      });
    }
  };

  return (
    <>
      <>
        {authModal && (
          <Modal isOpen onClose={() => {}} isCentered size={{ base: 'xs', md: '2xl' }}>
            <ModalOverlay />
            <ModalContent color={'#000'} maxW={'60rem'} width={'100%'}>
              <ModalHeader> </ModalHeader>
              <ModalBody>
                <Stack direction={{ base: 'column', md: 'row' }} px={{ base: 0, md: 4 }}>
                  <VStack width={'auto'} justify={'space-between'} alignItems={'start'}>
                    <Box>
                      <Text {...textStyles} mb={1} fontSize={{ base: 'md', md: 'xl' }}>
                        Your plan has been downgraded
                      </Text>
                      <Text {...paragraphStyles} fontSize={{ base: 'sm', md: 'sm' }}>
                        You are on view access right now so please contact owner and update your
                        plan.
                      </Text>
                    </Box>
                    <Flex width={'100%'} justifyContent={'space-around'}>
                      <Button
                        width={'fit-content'}
                        backgroundColor={'green.700'}
                        borderRadius={'10px'}
                        padding='0 4rem'
                        fontSize={'sm'}
                        onClick={() => {
                          dispatch(hideAuthenticationModal());
                        }}
                      >
                        Ok
                      </Button>
                    </Flex>
                  </VStack>
                  <VStack width='auto'>
                    <Box p={2}>
                      <Image src={CoordinationBlox} />
                    </Box>
                  </VStack>
                </Stack>
              </ModalBody>
              <ModalFooter> </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </>
      {userInvitation && currentModal.type === 1 && currentModal.status ? (
        <Modal isOpen onClose={() => {}} isCentered size={{ base: 'xs', md: '3xl' }}>
          <ModalOverlay />
          <ModalContent color={'#000'} maxW={'50rem'} width={'100%'}>
            <ModalHeader> </ModalHeader>
            <ModalBody>
              <Stack direction={{ base: 'column', md: 'row' }} px={{ base: 0, md: 4 }}>
                <VStack width={'auto'} justify={'space-between'} alignItems={'start'}>
                  <Box>
                    <Text {...textStyles} mb={1} fontSize={{ base: 'md', md: 'xl' }}>
                      Invitation
                    </Text>
                    <Text {...paragraphStyles} fontSize={{ base: 'sm', md: 'sm' }}>
                      You have been invited by{' '}
                      <Text as='span' size='subTitle'>
                        {userInvitation?.invited_to?.organization}{' '}
                      </Text>{' '}
                      to join their organisation. Would to like to leave your current organisation
                      to join them.{' '}
                    </Text>
                  </Box>
                  <Flex width={'100%'} justifyContent={'space-around'}>
                    <Button
                      width={'fit-content'}
                      backgroundColor={'green.700'}
                      borderRadius={'10px'}
                      padding='0 4rem'
                      fontSize={'sm'}
                      onClick={() => {
                        if (
                          userInvitation?.present_organization?.role === 'owner' &&
                          userInvitation?.present_organization?.team?.length > 0
                        ) {
                          setCurrentModal({
                            status: currentModal.status,
                            type: 2,
                            newOwnerId: null,
                          });
                        } else {
                          handleInvitation('submit');
                        }
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      width={'fit-content'}
                      backgroundColor={'green.700'}
                      borderRadius={'10px'}
                      padding='0 4rem'
                      fontSize={'sm'}
                      onClick={() => {
                        handleInvitation('cancel');
                      }}
                    >
                      No
                    </Button>
                  </Flex>
                </VStack>
                <VStack width='auto'>
                  <Box p={2}>
                    <Image src={CoordinationBlox} />
                  </Box>
                </VStack>
              </Stack>
            </ModalBody>
            <ModalFooter> </ModalFooter>
          </ModalContent>
        </Modal>
      ) : userInvitation && currentModal.type === 2 && currentModal.status ? (
        <Modal isOpen onClose={() => {}} isCentered size={{ base: 'xs', md: '3xl' }}>
          <ModalOverlay />
          <ModalContent
            color={'#000'}
            maxW={'50rem'}
            maxH={'80%'}
            width={'100%'}
            height={'100%'}
            overflow={'hidden'}
          >
            <ModalBody
              height={'100%'}
              overflowX='auto'
              overflowY='auto'
              padding={'0 1rem'}
              margin={'2rem .5rem'}
            >
              <Text {...textStyles} mb={1} fontSize={{ base: 'md', md: 'xl' }}>
                choose owner before leaving
              </Text>
              <Table
                variant={'base'}
                borderRadius='10px'
                backgroundColor='white'
                mt='8'
                color='black'
                fontFamily='Roboto Regular'
              >
                <Thead backgroundColor='white' width='100%' fontFamily='Roboto Regular'>
                  <Tr borderBottomWidth='1' borderBottomColor='white.200'>
                    <Th
                      _first={{
                        backgroundColor: 'white',
                        position: 'sticky',
                        left: '0px',
                        zIndex: '10',
                      }}
                    ></Th>
                    <Th
                      _first={{
                        backgroundColor: 'white',
                        position: 'sticky',
                        left: '0px',
                        zIndex: '10',
                      }}
                      color='black'
                      fontWeight='600'
                      textAlign='left'
                      padding='1rem 0'
                      width={'45%'}
                      fontFamily='Roboto Regular'
                      fontSize='14px'
                    >
                      Name
                    </Th>
                    <Th
                      color='black'
                      fontWeight='600'
                      textAlign='left'
                      padding='1rem 0'
                      width={'45%'}
                      fontFamily='Roboto Regular'
                      fontSize='14px'
                    >
                      Email
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {userInvitation?.present_organization?.team?.map(
                    (data: GetUserTeamListResponseData, index: number) => {
                      return (
                        <Tr
                          borderBottomWidth='1'
                          borderBottomColor='white.200'
                          key={index}
                          height='50px !important'
                        >
                          <Td>
                            <Checkbox
                              isChecked={data.id === currentModal.newOwnerId}
                              name='org'
                              onChange={() =>
                                setCurrentModal({
                                  type: currentModal.type,
                                  status: currentModal.status,
                                  newOwnerId: data.id,
                                })
                              }
                            ></Checkbox>
                          </Td>
                          <Td padding='1rem 0' textAlign='left' fontSize={'xs'} lineHeight='18px'>
                            <Flex alignItems={'center'}>
                              {data.profile_image_url ? (
                                <Image
                                  boxShadow={'1px 1px 10px #d9d9d9'}
                                  src={data.profile_image_url}
                                  borderRadius={'50%'}
                                  width={'30px'}
                                  height={'30px'}
                                  mr='.5rem'
                                />
                              ) : (
                                <LightUserAvatar
                                  height={'30px'}
                                  width={'30px'}
                                  styles={{ marginRight: '.5rem' }}
                                />
                              )}
                              {data.firstname}
                            </Flex>
                          </Td>
                          <Td
                            padding='1rem 0'
                            textAlign='left'
                            fontSize={'xs'}
                            verticalAlign='middle !important'
                            lineHeight='18px'
                          >
                            {data.username}
                          </Td>
                        </Tr>
                      );
                    },
                  )}
                </Tbody>
              </Table>
            </ModalBody>
            <ModalFooter>
              <Flex gap='8'>
                <Button
                  width={'100px'}
                  backgroundColor={'green.700'}
                  borderRadius={'10px'}
                  fontSize={'sm'}
                  disabled={!currentModal.newOwnerId}
                  _hover={{}}
                  onClick={() => {
                    handleInvitation('submit', currentModal.newOwnerId);
                  }}
                >
                  Confirm
                </Button>
                <Button
                  width={'100px'}
                  backgroundColor={'green.700'}
                  borderRadius={'10px'}
                  fontSize={'sm'}
                  onClick={() => {
                    handleInvitation('cancel');
                  }}
                >
                  No
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : (
        ''
      )}
      <>{children}</>
    </>
  );
};
export default AppWrapper;
