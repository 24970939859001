import { QuestionIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  HStack,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useMediaQuery,
  useOutsideClick,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';

import assistant from 'assets/assistant_icon.png';
import Carousel from 'components/Carousel';
import NoOutputIndicatorIcon from 'components/Icons/NoOutputIndicator';
import { BlockOutput } from 'models/response/blockOutput.interface';
import { STATUS } from 'projectConstants';
import { getActiveTabIndex, setActiveTabIndex } from 'redux/PlanPageSlice';
import {
  scenarioChartOutputStatus,
  compareAnalysisOutput,
  getChartLabels,
  getIndicatorTableStatus,
  getVarianceFilterChart,
} from 'redux/ScenarioSlice';
import formatValue from 'utils/FormatValues';
import { AppDispatch } from 'utils/GlobalHelpers';

import CompareChart from './CompareChart';

const CompareChartTabs = () => {
  const dispatch: AppDispatch = useDispatch();
  const [isSmallDevice] = useMediaQuery('(max-width: 1350px)');
  const [isMediumLaptop] = useMediaQuery('(max-width: 1600px)');
  const [isSmalllaptop] = useMediaQuery('(max-width: 1500px)');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isSmallMobile] = useMediaQuery('(max-width: 576px)');
  const tabsData: BlockOutput[] = useSelector(compareAnalysisOutput);
  const activeTabIndex = useSelector(getActiveTabIndex);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const chartStatus = useSelector(scenarioChartOutputStatus);
  const tableStatus = useSelector(getIndicatorTableStatus);
  const chartLabels = useSelector(getChartLabels);
  const indicatorInfo = tabsData?.length ? tabsData[activeTabIndex]?.help : '';
  const indicatorName = tabsData?.length ? tabsData[activeTabIndex]?.name : '';
  const defaultTabIndex = tabsData?.findIndex((data: any) => data?.is_default_KPI === true) || 0;
  const tooltipReference = useRef<any>(null);
  const varianceFilter = useSelector(getVarianceFilterChart);

  const handleTabIndexChange = (index: number) => {
    dispatch(setActiveTabIndex(index));
  };

  useOutsideClick({
    ref: tooltipReference,

    handler: () => {
      setIsTooltipOpen(false);
    },
  });

  useEffect(() => {
    if (activeTabIndex === -1 && tabsData) {
      if (defaultTabIndex > -1) {
        handleTabIndexChange(defaultTabIndex);
      } else {
        handleTabIndexChange(0);
      }
    }
  }, [tabsData?.length > 0, defaultTabIndex, activeTabIndex]);

  if (
    tabsData &&
    tabsData.length === 0 &&
    chartStatus === STATUS.SUCCESS &&
    tableStatus === STATUS.SUCCESS
  ) {
    return (
      <Box w='100%' mt='2' display='flex' justifyContent='center' alignItems='center'>
        <NoOutputIndicatorIcon />
      </Box>
    );
  }

  const removeSign = (value: number) => {
    return value < 0 ? -1 * value : value;
  };

  return (
    <Tabs
      size='file'
      variant='file'
      onChange={handleTabIndexChange}
      position='relative'
      px={isMobile ? 2 : 4}
      pt={isMobile ? 2 : 1}
      pb={isMobile ? 2 : 3}
      borderRadius='15px'
      backgroundColor={'#FFFFFF'}
      zIndex={1}
      index={activeTabIndex}
    >
      <TabList display='block' w={'100%'} h='6rem' pt={'0.3rem'} justifyContent={'start'}>
        <Carousel
          className='carousel_bg_white'
          slidesToScroll={1}
          slidesToShow={
            tabsData?.length < 4
              ? isMobile
                ? isSmallMobile
                  ? 2
                  : 3
                : isSmalllaptop
                ? 3
                : 4
              : isMobile
              ? 2
              : isSmalllaptop
              ? 3
              : isMediumLaptop
              ? 4
              : 5
          }
        >
          {tabsData?.map((object: any, index: number) => (
            <Flex display={'flex !important'} flexDirection={'column'} alignItems={'start'}>
              <Tab
                key={object.id}
                color='#57519C'
                borderBottom={'1px solid #8D8BCE'}
                _selected={{
                  borderBottom: '3px solid #4BB6B9',
                  background: '#EEF2F6',
                  borderTopRadius: '5px',
                  fontWeight: 800,
                }}
                maxWidth={tabsData.length < 4 ? '280px' : '300px'}
                w={'100%'}
                h={'45px'}
                paddingInlineStart={0}
                paddingInlineEnd={0}
                pl={0}
                pr={0}
                ml={0}
                mr={0}
                lineHeight='20px'
              >
                <Flex
                  direction='row'
                  justify={isSmallDevice ? 'space-around' : 'space-between'}
                  p='3px'
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  width={'100%'}
                  height={'50px'}
                >
                  <Tooltip label={object.name} borderRadius='4' fontSize='3xs'>
                    <Text
                      fontSize={isSmallDevice ? 'xs' : 'md'}
                      marginRight={3}
                      fontFamily={'regular_roboto'}
                      fontWeight={activeTabIndex === index ? 600 : 500}
                      lineHeight='18px'
                      noOfLines={2}
                    >
                      {object.name}
                    </Text>
                  </Tooltip>

                  {index === activeTabIndex && (
                    <Tooltip
                      isOpen={isTooltipOpen}
                      hasArrow
                      arrowSize={10}
                      bg='white'
                      placement='right'
                      boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
                      label={
                        <VStack
                          bg={'white'}
                          h='inherit'
                          p='3'
                          justify='space-between'
                          maxWidth={'300px'}
                          width={'180px'}
                          alignItems={'center'}
                          alignContent={'center'}
                          gap={1}
                          zIndex={'dropdown'}
                        >
                          <Text
                            mb='2.5'
                            fontFamily='bold'
                            fontSize='xs'
                            lineHeight='4'
                            noOfLines={2}
                            color='black'
                          >
                            Your {indicatorName} tells you...
                          </Text>
                          <Image src={assistant} height={isSmallDevice ? 15 : 20} />
                          <Text
                            size='modalContent'
                            fontFamily='regular_roboto'
                            noOfLines={3}
                            color='black'
                            fontSize='2xs'
                          >
                            {indicatorInfo}
                          </Text>
                        </VStack>
                      }
                      borderRadius='4'
                      width={'-moz-min-content'}
                    >
                      <QuestionIcon
                        ref={tooltipReference}
                        onBlur={() => setIsTooltipOpen(!isTooltipOpen)}
                        boxSize={'1.8em'}
                        onClick={() => {
                          setIsTooltipOpen(!isTooltipOpen);
                        }}
                      />
                    </Tooltip>
                  )}
                </Flex>
              </Tab>
              <Box
                pt={'2px'}
                pb={'0'}
                maxWidth={tabsData.length < 4 ? '280px' : '300px'}
                width={tabsData.length < 4 ? '280px' : '300px'}
                w={'100%'}
                h={'60px'}
              >
                <Tooltip
                  label={
                    varianceFilter
                      ? `${formatValue(object.total, object.format_properties)} ${'  '} ${Number(
                          object.varience,
                        ).toFixed(1)}%`
                      : `${formatValue(object.total, object.format_properties)} ${'  '} ${Number(
                          object.diff,
                        ).toFixed(1)}`
                  }
                  borderRadius='4'
                  fontSize='3xs'
                >
                  <VStack alignItems={'center'} justifyContent={'center'}>
                    <Text
                      fontSize={isSmallDevice ? 'md' : 'lg'}
                      noOfLines={1}
                      mt={3}
                      mb={0}
                      color={activeTabIndex === index ? '#57519C' : '#8B89B4'}
                      onClick={() => handleTabIndexChange(index)}
                      cursor={'pointer'}
                    >
                      {formatValue(object.total, object.format_properties)}
                    </Text>
                    {(varianceFilter ? Number(object.varience) : Number(object.diff)) !== 0 && (
                      <HStack justifyContent='space-around' style={{ marginTop: '0px' }} pt='0'>
                        {varianceFilter && (
                          <Text
                            style={{ marginTop: '0px' }}
                            pt='0'
                            fontSize={'3xs'}
                            noOfLines={1}
                            color={Number(object.varience) > 0 ? '#62B5BA' : 'red'}
                          >
                            {removeSign(Number(object.varience)).toFixed(1)}%
                          </Text>
                        )}
                        {!varianceFilter && (
                          <Text
                            style={{ marginTop: '0px' }}
                            pt='0'
                            fontSize={'3xs'}
                            noOfLines={1}
                            color={Number(object.diff) > 0 ? '#62B5BA' : 'red'}
                          >
                            {removeSign(Number(object.diff)).toFixed(1)}
                          </Text>
                        )}
                        {(varianceFilter ? Number(object.varience) : Number(object.diff)) !== 0 && (
                          <>
                            {!varianceFilter &&
                              (Number(object?.diff) > 0 ? (
                                <AiOutlineArrowUp fontSize={'6xs'} color={'#62B5BA'} />
                              ) : (
                                <AiOutlineArrowDown fontSize={'6xs'} color={'red'} />
                              ))}
                            {varianceFilter &&
                              (Number(object?.varience) > 0 ? (
                                <AiOutlineArrowUp fontSize={'6xs'} color={'#62B5BA'} />
                              ) : (
                                <AiOutlineArrowDown fontSize={'6xs'} color={'red'} />
                              ))}
                          </>
                        )}
                      </HStack>
                    )}
                  </VStack>
                </Tooltip>
              </Box>
            </Flex>
          ))}
        </Carousel>
      </TabList>
      {tabsData && tabsData?.length && (
        <TabPanels>
          {tabsData.map((object: any) => (
            <TabPanel key={object.id} pt={{ md: 0 }} position={{ base: 'static', md: 'relative' }}>
              {chartLabels?.length > 0 && (
                <CompareChart
                  chartData={
                    object.dim_item_data ?? [{ value: object.total_values, name: 'Total' }]
                  }
                  chartLabels={chartLabels}
                  tabData={object}
                  activeTabIndex={activeTabIndex}
                />
              )}
            </TabPanel>
          ))}
        </TabPanels>
      )}
    </Tabs>
  );
};

export default CompareChartTabs;
