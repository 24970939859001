import { IconButton, Stack } from '@chakra-ui/react';
import React from 'react';
import { useStoreApi, useReactFlow } from 'react-flow-renderer';

import AlignHorizontalLeftIcon from '../Icons/AlignHorizontalLeftIcon';
import ZoomInMapIcon from '../Icons/ZoomInMapIcon';
import ZoomOutMapIcon from '../Icons/ZoomOutMapIcon';
import CenterFocusStrongIcon from '../Icons/CenterFocusStrongIcon';
import LockCloseIcon from '../Icons/LockCloseIcon';
import LockOpenIcon from '../Icons/LockOpenIcon';
import AlignVerticalBottomIcon from '../Icons/AlignVerticalBottomIcon';

import styles from './styles';

interface Properties {
  layoutDirection: string;
  onLayout: (layout: string) => void;
  isDraggable: any;
  setIsDraggable: (parameter: boolean | ((previous: boolean) => boolean)) => void;
}

const MapControls = ({ layoutDirection, onLayout, isDraggable, setIsDraggable }: Properties) => {
  const store = useStoreApi();
  const { zoomIn, zoomOut, setCenter } = useReactFlow();

  const focusNode = () => {
    const { nodeInternals } = store.getState();
    const nodes = Array.from(nodeInternals).map(([, node]) => node);

    if (nodes.length > 0) {
      const node = nodes[0];

      const { x } = node.position;
      const y = node.position.y + (node?.height ? node.height / 0.5 : 0);
      const zoom = 1;

      setCenter(x, y, { zoom, duration: 500 });
    }
  };

  const toggleInteractivity = () => {
    setIsDraggable((previousState: any) => !previousState);
  };

  return (
    <Stack sx={styles.mapControls}>
      <IconButton
        style={{ height: '30px', width: '30px', borderColor: '#4BB6B9', borderRadius: '5px' }}
        title='zoom in'
        onClick={() => zoomIn({ duration: 500 })}
        aria-label='Zoom in'
        icon={<ZoomOutMapIcon />}
      ></IconButton>
      <IconButton
        style={{ height: '30px', width: '30px', borderColor: '#4BB6B9', borderRadius: '5px' }}
        title='zoom out'
        aria-label='zoom out'
        onClick={() => zoomOut({ duration: 500 })}
        icon={<ZoomInMapIcon />}
      ></IconButton>
      <IconButton
        style={{ height: '30px', width: '30px', borderColor: '#4BB6B9', borderRadius: '5px' }}
        title='fit view'
        onClick={focusNode}
        aria-label='fit view'
        icon={<CenterFocusStrongIcon />}
      ></IconButton>
      <IconButton
        style={{ height: '30px', width: '30px', borderColor: '#4BB6B9', borderRadius: '5px' }}
        title='toggle interactivity'
        onClick={toggleInteractivity}
        aria-label='toggle interactivity'
        icon={isDraggable ? <LockOpenIcon /> : <LockCloseIcon />}
      ></IconButton>
      <IconButton
        style={{
          height: '30px',
          width: '30px',
          borderColor: '#4BB6B9',
          borderRadius: '5px',
          backgroundColor: layoutDirection === 'TB' ? '#4BB6B9' : 'white',
        }}
        title='vertical layout'
        aria-label='Vertical layout'
        onClick={() => onLayout('TB')}
        icon={<AlignVerticalBottomIcon />}
      ></IconButton>

      <IconButton
        style={{
          height: '30px',
          width: '30px',
          borderColor: '#4BB6B9',
          borderRadius: '5px',
          backgroundColor: layoutDirection === 'LR' ? '#4BB6B9' : 'white',
        }}
        title='horizontal layout'
        aria-label='Horizontal layout'
        onClick={() => onLayout('LR')}
        icon={<AlignHorizontalLeftIcon />}
      ></IconButton>
    </Stack>
  );
};

export default MapControls;
