/* eslint-disable no-unsafe-optional-chaining */
import { Box } from '@chakra-ui/react';
import { useEffect } from 'react';
import { Chart as ReactChart } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

interface KPIChartProperties {
  kpiLabelData: any;
  kpiChartData: any;
}

ChartJS.register(ArcElement, ChartDataLabels);

const KPIChart = ({ kpiLabelData, kpiChartData }: KPIChartProperties) => {
  useEffect(() => {}, [kpiLabelData, kpiChartData]);

  const average = kpiChartData?.reduce((a: any, b: any) => a + b, 0) / kpiChartData?.length;
  return (
    <Box>
      <ReactChart
        type='line'
        data={{
          labels: kpiLabelData,
          datasets: [
            {
              label: 'KPI',
              data: kpiChartData,
              fill: false,
              backgroundColor: '#4BB6B9',
              borderColor: '#4BB6B9',
              borderWidth: 1.5,
              pointRadius: 0,
            },
            {
              label: 'KPI',
              data: new Array(kpiChartData?.length).fill(average),
              fill: false,
              backgroundColor: '#4BB6B9',
              borderColor: '#4BB6B9',
              borderDash: [4, 3],
              borderWidth: 1,
              pointRadius: 0,
            },
          ],
        }}
        options={{
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: false,
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
            y: {
              beginAtZero: true,
              grid: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          },
        }}
      />
    </Box>
  );
};

export default KPIChart;
