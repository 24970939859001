interface Properties {
  width?: number;
  height?: number;
  color?: string;
  marginRight?: string;
}

const LineChartIconComponent = ({
  width = 18,
  height = 18,
  color = 'white.900',
  marginRight,
}: Properties) => {
  return (
    <svg
      style={{ marginRight }}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      color={color}
      viewBox='0 0 15 10'
      fill='none'
    >
      <path
        d='M1.125 9.735L5.625 5.2275L8.625 8.2275L15 1.0575L13.9425 0L8.625 5.9775L5.625 2.9775L0 8.61L1.125 9.735Z'
        fill='#787878'
      />
    </svg>
  );
};

export default LineChartIconComponent;
