interface Properties {
  width?: number;
  height?: number;
  onClick?: () => void;
}

const ShowIconComponent = ({ width = 34, height = 27, onClick }: Properties) => {
  return (
    <svg
      cursor={'pointer'}
      onClick={onClick}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.941 12.0453L14.4954 8.49078L12.8771 6.84359L7.67548 12.0453L12.8771 17.2469L14.4954 15.5997L10.941 12.0453ZM11.7212 0.48601C13.3202 0.486011 14.8229 0.789439 16.2293 1.3963C17.6357 2.00316 18.859 2.82676 19.8994 3.86709C20.9397 4.90742 21.7633 6.13077 22.3702 7.53715C22.977 8.94352 23.2805 10.4462 23.2805 12.0453C23.2805 13.6443 22.977 15.147 22.3702 16.5534C21.7633 17.9597 20.9397 19.1831 19.8994 20.2234C18.859 21.2637 17.6357 22.0873 16.2293 22.6942C14.8229 23.3011 13.3202 23.6045 11.7212 23.6045C10.1222 23.6045 8.61949 23.3011 7.21311 22.6942C5.80674 22.0873 4.58338 21.2637 3.54305 20.2234C2.50272 19.1831 1.67913 17.9597 1.07227 16.5534C0.465405 15.147 0.161977 13.6443 0.161977 12.0452C0.161977 10.4462 0.465406 8.94352 1.07227 7.53715C1.67913 6.13077 2.50272 4.90742 3.54305 3.86709C4.58339 2.82675 5.80674 2.00316 7.21311 1.3963C8.61949 0.789439 10.1222 0.48601 11.7212 0.48601ZM11.7212 2.79786C9.13965 2.79786 6.95303 3.6937 5.16135 5.48538C3.36966 7.27706 2.47382 9.46369 2.47382 12.0453C2.47382 14.6268 3.36966 16.8134 5.16135 18.6051C6.95303 20.3968 9.13965 21.2926 11.7212 21.2926C14.3028 21.2926 16.4894 20.3968 18.2811 18.6051C20.0728 16.8134 20.9686 14.6268 20.9686 12.0453C20.9686 9.46369 20.0728 7.27706 18.2811 5.48538C16.4894 3.6937 14.3028 2.79786 11.7212 2.79786Z'
        fill='#776ED0'
      />
    </svg>
  );
};

export default ShowIconComponent;
