import {
  Box,
  Divider,
  Flex,
  Grid,
  GridItem,
  Skeleton,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';

import { IndicatorDriver } from 'models/response/driver.interface';
import { Scenario } from 'models/response/scenario.interface';
import { STATUS, HEADER_HEIGHT_WITH_NAVIGATION } from 'projectConstants';
import {
  chartOutputStatus,
  getBlockDriversData,
  getIndicatorTableStatus,
  groupByFilterChange,
  inputsPageShow,
  planPageShow,
  setEmptyData,
  toggleAuthModel,
} from 'redux/PlanPageSlice';
import {
  scenarioChartOutputStatus,
  compareAnalysisOutput,
  getAllScenarios,
  getBaseScenario,
  getCompareIndicatorTableOutput,
  getCompareScenario,
  getToggleScenario,
  selectBaseScenario,
  selectCompareScenario,
  toggleCompareScenario,
} from 'redux/ScenarioSlice';
import { AppDispatch } from 'utils/GlobalHelpers';
import HideIconComponent from 'components/Icons/HideIcon';
import ShowIconComponent from 'components/Icons/ShowIcon';

import ActionSection from './ActionSection';
import CompareAnalysisSection from './Compare/CompareAnalysisSection';
import CompareIndicatorTable from './Compare/CompareIndicatorTable';
import CustomToggle from './CustomToggle';
import EditDriversSection from './EditDriverSection';
import OverAllFilter from './Filters/OverallFilters';
import IndicatorTable from './IndicatorTable';
import PlanPageShimmer from './PlanPageShimmer';
import ScenarioFilter from './Filters/ScenarioFilter';
import ChartTabs from './ChartTabs';

interface Properties {
  refetchRequest: () => void;
}

const PlanSection = ({ refetchRequest }: Properties) => {
  const [isMobile] = useMediaQuery('(max-width: 1023px)');
  const [isEditDriverSectionShow, setIsEditDriverSectionShow] = useState(true);
  const dispatch: AppDispatch = useDispatch();
  const showInputPage = useSelector(inputsPageShow);
  const compareChartOutput = useSelector(compareAnalysisOutput);
  const compareTableOutput = useSelector(getCompareIndicatorTableOutput);
  const scenarios = useSelector(getAllScenarios);

  const baseScenario = useSelector(getBaseScenario);
  const compareScenario = useSelector(getCompareScenario);
  const scenarioToggle = useSelector(getToggleScenario);
  const showPlanPage = useSelector(planPageShow);
  const blockDrivers = useSelector(getBlockDriversData);
  const chartStatus = useSelector(chartOutputStatus);
  const scenarioChartStatus = useSelector(scenarioChartOutputStatus);
  const tableStatus = useSelector(getIndicatorTableStatus);
  const filteredDrivers = blockDrivers?.filter(
    (driver: IndicatorDriver) => driver.type === 'indicator',
  );

  const isLoading = compareScenario
    ? !compareChartOutput || !compareTableOutput || scenarioChartStatus === STATUS.LOADING
    : chartStatus === STATUS.LOADING || tableStatus === STATUS.LOADING;

  const onToggleAuthModel = () => dispatch(toggleAuthModel());

  const onToggle = () => {
    setIsEditDriverSectionShow(!isEditDriverSectionShow);
  };

  return (
    <Grid
      templateColumns={{
        base: '100%',
        md:
          filteredDrivers.length <= 0
            ? '100%'
            : showInputPage
            ? '100%'
            : !isEditDriverSectionShow
            ? '96% 4%'
            : '70% 30%',
      }}
      overflow={isMobile ? 'auto' : 'hidden'}
      h={isMobile ? `calc(100vh - 61px)` : `calc(100vh - ${HEADER_HEIGHT_WITH_NAVIGATION})`}
      justifyContent={'center'}
      minWidth='-webkit-fill-available'
    >
      {isMobile && (
        <GridItem bg={{ md: 'gradient.body_dark' }} h='full'>
          <Box display={{ base: 'block', md: 'none' }} position='relative' px='4' pb='5'>
            <Stack
              direction={isMobile ? 'column' : 'row'}
              spacing={{ base: 'auto', md: '20' }}
              justifyContent={'space-between'}
              mb='5'
              p='0'
              mt={4}
            >
              <Text fontFamily='bold' fontSize='lg' lineHeight='9' mb={2}>
                Analytics
              </Text>
              <OverAllFilter refetchRequest={refetchRequest} />
            </Stack>

            {scenarios.length > 0 && baseScenario && (
              <Flex alignItems={'center'} justifyContent={'start'} gap={'3'} mb={'2rem'}>
                <Box fontFamily='bold' fontSize='lg' lineHeight='9' width={'8rem'}>
                  <ScenarioFilter
                    isSelectCompare={false}
                    currentScenario={baseScenario}
                    refetchRequest={refetchRequest}
                    selectScenario={(scenario: Scenario) => dispatch(selectBaseScenario(scenario))}
                  />
                </Box>
                <CustomToggle
                  onChange={(value: any) => {
                    dispatch(toggleCompareScenario(value));
                  }}
                  defaultValue={scenarioToggle}
                />
                {scenarioToggle && (
                  <Box fontFamily='bold' fontSize='lg' lineHeight='9' width={'8rem'}>
                    <ScenarioFilter
                      isSelectCompare={true}
                      currentScenario={compareScenario || ''}
                      selectScenario={(scenario: Scenario) => {
                        dispatch(groupByFilterChange('Total'));
                        dispatch(setEmptyData(true));
                        dispatch(selectCompareScenario(scenario));
                      }}
                      refetchRequest={refetchRequest}
                    />
                  </Box>
                )}
              </Flex>
            )}
            {isLoading && (
              <>
                <Skeleton startColor='white' endColor='#CCD4E8' h={'40vh'} />
                <Flex direction='column' pt='6vh'>
                  {[...new Array(5)].map((_, index) => (
                    <Flex key={index} p={1}>
                      {[...new Array(5)].map((__, subIndex: number) => (
                        <Box key={subIndex} flex={1} mr={2}>
                          <Skeleton
                            startColor='white'
                            endColor='#CCD4E8'
                            height='20px'
                            width='100%'
                          />
                        </Box>
                      ))}
                    </Flex>
                  ))}
                </Flex>
              </>
            )}

            <>
              {compareScenario && (
                <>
                  {!isLoading && <CompareAnalysisSection />}
                  <Divider my='5' />
                  {filteredDrivers && filteredDrivers.length > 0 && (
                    <EditDriversSection drivers={filteredDrivers} />
                  )}

                  <CompareIndicatorTable onToggleAuthModal={onToggleAuthModel} />
                  <ActionSection />
                </>
              )}
              {!compareScenario && (
                <>
                  {!isLoading && <ChartTabs />}

                  <Divider my='5' />
                  {filteredDrivers && filteredDrivers.length > 0 && (
                    <Box>
                      <EditDriversSection drivers={filteredDrivers} />
                    </Box>
                  )}

                  <IndicatorTable onToggleAuthModal={onToggleAuthModel} />
                  <ActionSection />
                </>
              )}
            </>
          </Box>
        </GridItem>
      )}
      <GridItem
        overflowY='auto'
        className='scroll'
        h='full'
        display={{ base: 'none', md: 'block' }}
        p={2}
      >
        {/* Plan Page Shimmer */}
        {isLoading ? (
          <PlanPageShimmer />
        ) : (
          <>
            {compareScenario ? (
              <>
                <CompareAnalysisSection />
                <CompareIndicatorTable onToggleAuthModal={onToggleAuthModel} />
              </>
            ) : (
              <>
                <ChartTabs />
                <IndicatorTable onToggleAuthModal={onToggleAuthModel} />
              </>
            )}
          </>
        )}
      </GridItem>

      {/* Plan Page Edit Inputs */}
      {filteredDrivers.length > 0 && (
        <GridItem
          hidden={showInputPage}
          className={`${
            !showInputPage && showPlanPage ? 'w3-animate-right' : 'w3-animate-left'
          } scroll`}
          display={{ base: 'none', md: 'flex' }}
          flexDirection='column'
          // pr={2}
          maxH='100vh'
          overflow={'auto'}
          width={isEditDriverSectionShow ? '100%' : 'fit-content'}
        >
          <Box display={'flex'} width={'35px'} margin={'5px'}>
            {filteredDrivers.length > 0 && isEditDriverSectionShow ? (
              <HideIconComponent onClick={onToggle} />
            ) : (
              <ShowIconComponent onClick={onToggle} />
            )}
          </Box>
          {filteredDrivers.length > 0 && isEditDriverSectionShow && (
            <EditDriversSection drivers={filteredDrivers} onToggleAuthModal={onToggleAuthModel} />
          )}
        </GridItem>
      )}
    </Grid>
  );
};

export default PlanSection;
