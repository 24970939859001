export default {
  baseStyle: { filledTrack: { bgColor: 'transparent' } },
  sizes: {
    sm: {
      thumb: {
        w: 4,
        h: 4,
        _focus: { boxShadow: 'none' },
        _before: {
          content: '""',
          position: 'absolute',
          w: 8,
          h: 8,
          borderRadius: 'full',
          bgColor: 'grey.600',
          opacity: 0.24,
        },
      },
      track: { h: 2, bgColor: 'grey.400' },
    },
    md: {
      thumb: {
        w: 3.5,
        h: 3.5,
      },
      track: { h: 2 },
    },
  },
  defaultProps: { size: 'md' },
};
