import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  VStack,
  FormControl,
  Button,
  Flex,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import { textStyles } from 'pages/ModelOverviewPage/Import/Helper';
import {
  clearStepperFormData,
  getNoheadersStatus,
  getTableHeader,
  handleActionStepNext,
  setActionCreateForm,
  setClearSelectedAreasTable,
  setColumnNumericValueoofRowsIndicatorType,
  setSelectedAreas,
} from 'redux/DataImportSlice';

import ActionStepFooter from '../../ActionStepFooter';

interface Properties {
  getFormValue: any;
}
const validationSchema = Yup.object().shape({
  columnNumericValueoofRowsIndicatorType: Yup.string().required('Time value is required'),
});

const RowTypeSecondStep = ({ getFormValue }: Properties) => {
  const dispatch: any = useDispatch();
  const headerNames = useSelector(getTableHeader);
  const isHeader = useSelector(getNoheadersStatus);
  const [customHeaders, setCustomHeaders] = useState<any[]>(headerNames ?? []);
  useEffect(() => {
    if (isHeader) {
      const array: string[] = new Array(26)
        .fill(1)
        .map((_: string, index: number) => String.fromCodePoint(65 + index));
      const newHeaders = array
        .slice(0, headerNames.length)
        .map((item: string, headerIndex: number) => {
          return { current: `${headerNames[headerIndex]}`, modified: `Column ${item}` };
        });
      setCustomHeaders(newHeaders);
    } else {
      setCustomHeaders(
        headerNames.map((item: string) => {
          return { current: `${item}`, modified: `${item}` };
        }),
      );
    }
  }, [isHeader, headerNames]);

  const getSelectedColumnOption = (value: string) => {
    const data = customHeaders.find((item: any) => item.current === value);
    return data?.modified;
  };

  const handleSubmit = (form: any, setSubmitting: (data: boolean) => void) => {
    dispatch(setColumnNumericValueoofRowsIndicatorType(form)).then(() => {
      setSubmitting(false);
      dispatch(handleActionStepNext());
    });
  };

  return (
    <VStack display='flex' justify={'center'} w={'100%'} pt={3}>
      <Flex justifyContent={'space-between'} w={'full'} align={'center'}>
        <Text
          color={'#6562CF'}
          fontSize='lg'
          fontFamily={'regular_roboto'}
          fontWeight={'400'}
          textAlign={'start'}
        >
          Select indicator values
        </Text>
        <CloseIcon
          color={'black'}
          width={3}
          height={3}
          cursor={'pointer'}
          onClick={async () => {
            await dispatch(setActionCreateForm(false));
            await dispatch(clearStepperFormData());
            await dispatch(setClearSelectedAreasTable());
          }}
        />
      </Flex>
      <Text color={'black'} {...textStyles} textAlign={'start'} width={'100%'}>
        Now select the column in your table that contains the numerical values you want to import
      </Text>
      <Formik
        initialValues={{
          columnNumericValueoofRowsIndicatorType:
            getFormValue?.columnNumericValueoofRowsIndicatorType || '',
        }}
        validationSchema={validationSchema}
        onSubmit={(form, { setSubmitting }) => handleSubmit(form, setSubmitting)}
        enableReinitialize={true}
      >
        {({ values, setFieldValue, isSubmitting, errors }: any) => {
          return (
            <Form
              style={{
                width: '100%',
              }}
            >
              <FormControl pt={2}>
                <Menu closeOnSelect={true} isLazy>
                  <MenuButton
                    as={Button}
                    backgroundColor='white'
                    maxHeight={'40px'}
                    borderRadius='5px'
                    minWidth='140px'
                    textAlign='left'
                    padding='0 .8rem'
                    boxShadow='rgb(139, 137, 177) 0px 0px 0px 0.6px'
                    rightIcon={<ChevronDownIcon fontSize='md' />}
                    fontWeight='300'
                    fontSize='xs'
                    color='black.900'
                    w='100%'
                    border={
                      errors.columnNumericValueoofRowsIndicatorType
                        ? '2px solid #fe6e6e'
                        : '0.6px solid #8B89B1'
                    }
                  >
                    <Text me={3} noOfLines={1} height={'fit-content'}>
                      {getSelectedColumnOption(values?.columnNumericValueoofRowsIndicatorType) ?? (
                        <Text opacity={0.5}>Select...</Text>
                      )}
                    </Text>
                  </MenuButton>
                  <MenuList
                    height='fit-content'
                    maxHeight='20vh'
                    overflowY='auto'
                    className='scroll'
                  >
                    <MenuOptionGroup
                      defaultValue={values?.columnNumericValueoofRowsIndicatorType}
                      onChange={async (role: any) => {
                        await setFieldValue('columnNumericValueoofRowsIndicatorType', role);
                        await dispatch(setSelectedAreas({ selectedColumnOption: role }));
                      }}
                    >
                      <MenuItemOption value=''>Select...</MenuItemOption>
                      {customHeaders.map((header: any) => (
                        <MenuItemOption key={header} value={header.current}>
                          {header.modified}
                        </MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
                <ErrorMessage
                  name='columnNumericValueoofRowsIndicatorType'
                  component='div'
                  className='error'
                />
              </FormControl>
              <Flex alignItems={'center'} justifyContent={'flex-end'} pb={8} pt='5' w={'100%'}>
                <ActionStepFooter
                  nextButtonName='Continue'
                  prevButtonName='Previous'
                  isSubmitting={isSubmitting}
                />
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </VStack>
  );
};

export default RowTypeSecondStep;
