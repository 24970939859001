import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  Menu,
  Flex,
  Image,
  MenuButton,
  MenuItem,
  MenuList,
  useMediaQuery,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import Dropzone from 'react-dropzone';
import { useState } from 'react';
import moment from 'moment';

import FormField from 'components/FormField';
import TextArea from 'components/Textarea';
import UpdateBlockSchema from 'validation/schemas/UpdateBlockSchema';
import Pencil from 'components/Icons/Pencil';
import { handleFileDelete, handleFileUpload } from 'services/s3Service';
import FullScreenLoader from 'components/FullScreenLoader';
import { acceptedFormats } from 'utils/FileFormats';

import blockImage from '../../assets/blox_logo.png';

interface Properties {
  isOpen: boolean;
  onClose: () => void;
  onSave: (form: any, shouldUpdate: boolean, modalData: any) => void;
  modalType: string;
  modalData: any;
}

const CreateModelModal = ({ isOpen, onClose, onSave, modalType, modalData }: Properties) => {
  const [loader, setLoader] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 575px)');

  const handleSubmit = async (form: any) => {
    const { name: formName, ...payload } = form;
    const dataToSave = formName === modalData?.name ? payload : form;
    onSave(dataToSave, Object.keys(modalData).length > 0, modalData);
  };

  const formatDate = (date: string) => {
    return moment(date).format('DD MMMM YYYY');
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} blockScrollOnMount={false} isCentered>
      <ModalOverlay />
      <ModalContent p={3} maxW={isMobile ? '95%' : '50rem'}>
        <Formik
          initialValues={{
            name: modalType === 'edit' ? modalData?.name : '',
            description: modalType === 'edit' ? modalData?.description : '',
            icon: modalType === 'edit' ? modalData?.icon : '',
          }}
          onSubmit={(form) => handleSubmit(form)}
          validationSchema={UpdateBlockSchema}
        >
          {({ errors, values, touched, setFieldValue }: any) => {
            return (
              <Form>
                <ModalHeader position='relative' px={6} pt={3} pb={0}>
                  <Text variant='modals'>
                    {modalType === 'add' ? 'Create new model' : 'Model properties'}
                  </Text>
                </ModalHeader>
                <>
                  <ModalBody>
                    <Flex gap={4} color='black' flexWrap={isMobile ? 'wrap' : 'initial'}>
                      <Flex alignItems={'flex-start'} gap='5' width={'100%'} flexDir={'column'}>
                        <FormField
                          label='Model name'
                          name='name'
                          type='text'
                          labelStyles={{
                            fontSize: '15px',
                            color: '#212121',
                          }}
                          inputStyles={{
                            maxWidth: '300px',
                            boxShadow: '0px 0px 0px 1.5px #EEF1F6',
                            height: '35px',
                            paddingInlineStart: '8px',
                          }}
                          touched={touched.name}
                          error={errors.name}
                        />
                        <TextArea label='Model description' name='description' />
                        <Box mt={4}>
                          <Flex mb={1}>
                            <Text fontSize={'16px'} color='black'>
                              Created:{' '}
                            </Text>
                            <Text fontSize={'16px'} color={'violet.700'} ml={2}>
                              {formatDate(modalData?.createdAt)}
                            </Text>
                          </Flex>
                          <Flex alignItems={'center'} mt={1}>
                            {modalData?.ownerName && (
                              <Text fontSize={'16px'} color='black'>
                                Owner:
                              </Text>
                            )}
                            <Flex alignItems={'center'}>
                              {modalData?.ownerImage && (
                                <Image
                                  height={13}
                                  width={13}
                                  ml={5}
                                  borderRadius={'50%'}
                                  src={modalData?.ownerImage}
                                />
                              )}
                              {modalData?.ownerName && (
                                <Text fontSize={'16px'} color={'violet.700'} ml={2}>
                                  {modalData?.ownerName}
                                </Text>
                              )}
                            </Flex>
                          </Flex>
                        </Box>
                      </Flex>
                      <Flex minWidth={'330px'} flexDir='column'>
                        <Text mb={2}>Thumbnail</Text>
                        <Flex
                          minH={isMobile ? '120px' : 'auto'}
                          height={'100%'}
                          width={'100%'}
                          position={'relative'}
                          justifyContent={'end'}
                        >
                          {loader ? (
                            <FullScreenLoader
                              bgColor='linear-gradient(#FFFFFF, #F3F3F3)'
                              style={{ position: 'absolute' }}
                              height='100%'
                              width='100%'
                            />
                          ) : (
                            <Image
                              position={'absolute'}
                              borderRadius={'12px'}
                              height={'100%'}
                              width={'100%'}
                              objectFit={'contain'}
                              bg={'linear-gradient(#FFFFFF, #F3F3F3)'}
                              src={values?.icon || blockImage}
                            />
                          )}
                          <Menu variant='primary' placement='bottom-end'>
                            <MenuButton
                              height={'fit-content'}
                              zIndex={2}
                              mr={4}
                              mt={2}
                              type='button'
                            >
                              <Pencil
                                width={5}
                                height={6}
                                color='#bdbdbd'
                                style={{
                                  cursor: 'pointer',
                                }}
                              />
                            </MenuButton>
                            <MenuList minWidth={'160px !important'} zIndex={2}>
                              <MenuItem>
                                <Dropzone
                                  accept={acceptedFormats}
                                  onDrop={async (file: any) => {
                                    setLoader(true);
                                    const data = await handleFileUpload(
                                      file[0],
                                      'blox-user-assets',
                                    );
                                    setLoader(false);
                                    setFieldValue('icon', data);
                                  }}
                                  multiple={false}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <section style={{ width: '100%' }}>
                                      <div style={{ width: '100%' }} {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>Update</p>
                                      </div>
                                    </section>
                                  )}
                                </Dropzone>
                              </MenuItem>
                              {values.icon && (
                                <MenuItem
                                  onClick={async () => {
                                    setLoader(true);
                                    await handleFileDelete(values?.icon, 'blox-user-assets').then(
                                      (response: any) => {
                                        if (!response?.error) {
                                          setFieldValue('icon', '');
                                        }
                                      },
                                    );
                                    setLoader(false);
                                  }}
                                >
                                  Remove image
                                </MenuItem>
                              )}
                            </MenuList>
                          </Menu>
                        </Flex>
                      </Flex>
                    </Flex>
                  </ModalBody>
                  <ModalFooter pb='0'>
                    <HStack spacing='5'>
                      <Button
                        px={'10'}
                        w='fit-content'
                        h={'10'}
                        borderRadius='10px'
                        fontSize='xs'
                        color='violet.800'
                        bgColor='grey.500'
                        onClick={onClose}
                      >
                        CANCEL
                      </Button>
                      <Button
                        px={'10'}
                        _hover={{ _disabled: {} }}
                        type='submit'
                        w='fit-content'
                        h={'10'}
                        borderRadius='10px'
                        fontSize='xs'
                        bgColor='violet.800'
                        disabled={loader}
                      >
                        SAVE
                      </Button>
                    </HStack>
                  </ModalFooter>
                </>
              </Form>
            );
          }}
        </Formik>
      </ModalContent>
    </Modal>
  );
};

export default CreateModelModal;
