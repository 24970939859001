interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const UserIcon = ({ width = 4.75, height = 4.75, color = 'white.900' }: Properties) => {
  return (
    <svg width={width} height={height} viewBox='0 0 26 27' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.9177 7.24024C14.1656 7.24024 15.1768 8.25147 15.1768 9.49937C15.1768 10.7473 14.1656 11.7585 12.9177 11.7585C11.6698 11.7585 10.6586 10.7473 10.6586 9.49937C10.6586 8.25147 11.6698 7.24024 12.9177 7.24024ZM12.9177 16.9222C16.1128 16.9222 19.4799 18.4928 19.4799 19.1813V20.3647H6.35549V19.1813C6.35549 18.4928 9.72267 16.9222 12.9177 16.9222ZM12.9177 5.19627C10.5403 5.19627 8.61462 7.12191 8.61462 9.49937C8.61462 11.8768 10.5403 13.8025 12.9177 13.8025C15.2952 13.8025 17.2208 11.8768 17.2208 9.49937C17.2208 7.12191 15.2952 5.19627 12.9177 5.19627ZM12.9177 14.8782C10.0454 14.8782 4.31152 16.3198 4.31152 19.1813V22.4087H21.5239V19.1813C21.5239 16.3198 15.79 14.8782 12.9177 14.8782Z'
        fill={color}
      />
    </svg>
  );
};

export default UserIcon;
