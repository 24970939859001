import { Tooltip } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
  type?: string;
  style?: React.CSSProperties;
  onClick?: (data: any) => void;
}

const PropertyViewIconComponent = ({
  width = 35,
  height = 35,
  color = '#4BB6B9',
  type,
  style,
  onClick,
}: Properties) => {
  return (
    <Tooltip placement='top' label={'Data view'}>
      <svg
        style={style}
        cursor={'pointer'}
        width={type === 'Data' ? width : 22}
        height={type === 'Data' ? height : 22}
        fill={type === 'Data' ? color : 'white'}
        viewBox='0 0 17 17'
        onClick={onClick}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M0.896973 16.1629V0.93457H16.1253V16.1629H0.896973ZM14.7817 14.8193V9.22056H9.18296V14.8193H14.7817ZM14.7817 2.27823H9.18296V7.87692H14.7817V2.27823ZM2.24063 2.27823V7.87692H7.83932V2.27823H2.24063ZM2.24063 14.8193H7.83932V9.22056H2.24063V14.8193Z'
          fill={type === 'Data' ? color : 'white'}
        />
      </svg>
    </Tooltip>
  );
};

export default PropertyViewIconComponent;
