/* eslint-disable unicorn/no-array-for-each */
/* eslint-disable class-methods-use-this */
import 'react-datepicker/dist/react-datepicker.css';

import { Box, Button, Flex } from '@chakra-ui/react';
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  keyCodes,
} from '@silevis/reactgrid';
import moment from 'moment';
import * as React from 'react';
import { MouseEventHandler, ReactNode, forwardRef, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';

import Calendar from 'components/Icons/Calendar';
import datePickerStyle from 'components/DatePicker/style';
import { getModelDetails } from 'redux/PlanPageSlice';
import { getBlocks } from 'redux/ModelsSlice';

export interface TextCell extends Cell {
  type: 'text';
  text: string;
  placeholder?: string;
  validator?: (text: string) => boolean;
  renderer?: (text: string) => React.ReactNode;
  errorMessage?: string;
}

interface Properties {
  value?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const DatePickerInput = forwardRef<HTMLButtonElement, Properties>(
  ({ value, onClick }, reference) => (
    <Button
      ref={reference}
      onClick={onClick}
      rightIcon={<Calendar color={'white'} />}
      width={'100%'}
      height={'100%'}
      variant='picker'
      border={'none'}
      fontSize={'15px'}
    >
      {value || ''}
    </Button>
  ),
);

const CustomDate = ({ cell, onCellChanged }: any) => {
  const modelDetails = useSelector(getModelDetails);
  const blockDetails = useSelector(getBlocks);
  const blockData = Object.keys(blockDetails)?.length > 0 ? blockDetails : modelDetails;

  let date: any = null;
  if (cell?.text) {
    date = moment(cell?.text, 'MMM-YY').toDate();
  }

  const [testState, setTestState] = useState(true);
  useEffect(() => {
    const elements = document.querySelectorAll('.react-datepicker');
    const handleClick = (event: Event) => {
      event.stopPropagation();
      event.preventDefault();
    };

    elements.forEach((element) => {
      element.addEventListener('pointerdown', handleClick);
    });

    return () => {
      elements.forEach((element) => {
        element.removeEventListener('pointerdown', handleClick);
      });
    };
  }, [testState]);

  const datePickerReference = React.useRef<any>(null);
  return (
    <Box
      sx={datePickerStyle}
      className='datecell'
      style={{ position: 'relative', width: '100%', height: '100%' }}
      onClick={() => setTestState(!testState)}
    >
      <DatePicker
        ref={datePickerReference}
        selected={date}
        showMonthYearPicker={blockData?.time_properties?.time_granularity.toLowerCase() === 'm'}
        showYearPicker={blockData?.time_properties?.time_granularity.toLowerCase() === 'y'}
        dateFormat={
          blockData?.time_properties?.time_granularity.toLowerCase() === 'y'
            ? 'yyyy'
            : 'MMM yyyy' || 'MMM yyyy'
        }
        customInput={<DatePickerInput />}
        onChange={(event) => onCellChanged({ ...cell, text: moment(event).format('MMM-YY') })}
        disabled={cell.nonEditable}
      >
        <Flex
          marginBottom='15px'
          borderTop='1px solid rgba(112, 138, 138, 0.3)'
          width='90%'
          display='flex'
          justifyContent='center'
          alignItems='center'
          paddingTop='10px'
          color='purple'
          cursor={'pointer'}
          onClick={() => onCellChanged({ ...cell, text: '' })}
        >
          <Box>Clear Selection</Box>
        </Flex>
      </DatePicker>
    </Box>
  );
};

export class DateCellTemplate implements CellTemplate<TextCell> {
  getCompatibleCell(uncertainCell: Uncertain<TextCell>): Compatible<TextCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    let placeholder: string | undefined;
    try {
      placeholder = getCellProperty(uncertainCell, 'placeholder', 'string');
    } catch {
      placeholder = '';
    }
    const value = Number.parseFloat(text);
    return { ...uncertainCell, text, value, placeholder };
  }

  update(
    cell: Compatible<TextCell>,
    cellToMerge: UncertainCompatible<TextCell>,
  ): Compatible<TextCell> {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text,
      placeholder: cellToMerge.placeholder,
    });
  }

  handleKeyDown(
    cell: Compatible<TextCell>,
    keyCode: number,
  ): { cell: Compatible<TextCell>; enableEditMode: boolean } {
    return { cell, enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER };
  }

  getClassName(cell: Compatible<TextCell>): string {
    const isValid = cell.validator ? cell.validator(cell.text) : true;
    const className = cell.className || '';
    return `${className} ${isValid ? 'valid' : 'invalid'} ${
      cell.placeholder && cell.text === '' ? 'placeholder' : ''
    }`;
  }

  render(
    cell: Compatible<TextCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<TextCell>, commit: boolean) => void,
  ): React.ReactNode {
    return (
      <React.Fragment>
        <CustomDate
          cell={cell}
          onCellChanged={(cell_: any) => onCellChanged(this.getCompatibleCell(cell_), true)}
        />
      </React.Fragment>
    );
  }
}
