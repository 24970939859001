import { useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useLDClient, withLDConsumer } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';

import ErrorPage from 'pages/ErrorPage';
import HomeComponent from 'pages/HomePage';
import NotFoundPage from 'pages/NotFoundPage';
import RedirectionPage from 'pages/RedirectionPage';
import SplashScreen from 'pages/SplashScreen';
import WelcomePage from 'pages/WelcomePage';
import WelcomeComponent from 'pages/SetPasswordPage/welcome';
import Authentication from 'pages/Authentication';
import ChangePasswordPage from 'pages/Authentication/ChangePasswordPage';
import SetPasswordPage from 'pages/Authentication/SetPasswordPage';
import InvitaionModal from 'pages/Authentication/InvitaionModal';
import { getUserData } from 'redux/UserSlice';
import ModelOverviewPage from 'pages/ModelOverviewPage';
import PlanPageForecast from 'pages/PlanPageForecast';
import TawkTo from 'utils/Tawk';
import ProtectedRoutes from 'utils/ProtectedRoutes';
import PlanPageOutputOptimization from 'pages/PlanPageOutputOptimization';

const Navigation = ({ flags }: any) => {
  const { REACT_APP_GTM_ID, REACT_APP_TAWK_KEY }: any = process.env;
  const location = useLocation();
  const user = useSelector(getUserData);
  const ldClient = useLDClient();

  useEffect(() => {
    if (user) {
      const tagManagerArguments = {
        gtmId: REACT_APP_GTM_ID,
        events: {
          userId: user.id,
        },
      };
      TagManager.initialize(tagManagerArguments);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (ldClient && user) {
      ldClient.identify({
        name: user.username,
        custom: {
          key: user.id,
        },
      });
    }
  }, [ldClient, user]);

  useEffect(() => {
    if (user) {
      TawkTo(REACT_APP_TAWK_KEY, user?.username, `${user?.firstname} ${user?.lastname}`, user?.id);
    }
  }, [user]);

  return (
    <Routes>
      <Route index element={<Authentication type={1} />} />
      <Route path='signup' element={<Authentication type={2} />} />
      <Route path='create/:modelId' element={<SplashScreen />} />
      <Route
        path='welcome'
        element={
          <ProtectedRoutes>
            <WelcomePage />
          </ProtectedRoutes>
        }
      />
      <Route path='change-password' element={<ChangePasswordPage />} />
      <Route path='set-password' element={<SetPasswordPage />} />
      <Route path='set-new-password' element={<SetPasswordPage />} />
      <Route path='user/welcome' element={<WelcomeComponent />} />
      <Route
        path='home'
        element={
          <ProtectedRoutes>
            <HomeComponent />
          </ProtectedRoutes>
        }
      />
      <Route path='share-model/:token/:emailToken' element={<RedirectionPage />} />
      <Route path='share-model/:token' element={<RedirectionPage />} />

      <Route
        path='model/:modelId/home'
        element={
          <ProtectedRoutes>
            <ModelOverviewPage />
          </ProtectedRoutes>
        }
      />
      <Route
        path='model/:modelId/block/:blockId/plan'
        element={
          <ProtectedRoutes>
            {flags?.revampOutputApi ? <PlanPageOutputOptimization /> : <PlanPageForecast />}
          </ProtectedRoutes>
        }
      />
      <Route
        path='/error'
        element={
          <ProtectedRoutes>
            <ErrorPage />
          </ProtectedRoutes>
        }
      />
      <Route path='/invitation/:token' element={<InvitaionModal />} />
      <Route path='/*' element={<NotFoundPage />} />
    </Routes>
  );
};

export default withLDConsumer()(Navigation);
