import React from 'react';
import { useSelector } from 'react-redux';

import { getUserData } from 'redux/UserSlice';

interface UserPermissionsType {
  [key: string]: string[];
}

const USERPERMISSIONS: UserPermissionsType = {
  planner: ['view'],
  admin: ['view', 'edit', 'create', 'delete'],
  owner: ['view', 'edit', 'create', 'delete'],
};

interface Properties {
  children: any;
  permissionType: 'user' | 'plan';
  scopes: string[];
  renderError?: any;
}

export const hasUserPermission = (userRoles: string, scopes: string[]) => {
  const userPermissions = USERPERMISSIONS[userRoles];
  const scopesMap: any = {};

  scopes.forEach((scope: string) => {
    scopesMap[scope] = true;
  });

  return userPermissions?.some((permission: string) => scopesMap[permission]);
};

const UserPermissions = ({ children, permissionType, scopes = [], renderError }: Properties) => {
  const userData: any = useSelector(getUserData);

  if (permissionType === 'user') {
    const hasUserPermissions = hasUserPermission(userData?.role, scopes);
    return hasUserPermissions ? children : renderError || <></>;
  }

  return <></>;
};

export default UserPermissions;
