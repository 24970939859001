import { Flex, Button, Box, Text, useMediaQuery } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import FreePlanImage from 'components/Icons/FreePlan';
import RocketLaunch from 'components/Icons/RocketLaunch';
import { SetTabs } from 'redux/UserSlice';

import UserHeading from './UserHeading';

interface Properties {
  heading: string;
  subHeading1: string;
  subHeading2?: string;
  cancelButton?: boolean;
  handleCancelButton?: () => void;
}

const UserUpgradeModal = ({ heading, subHeading1, subHeading2 }: Properties) => {
  const dispatch: any = useDispatch();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Flex width={'100%'} height={'100%'} flexDir='column'>
      <UserHeading heading={'Team'} />
      <Flex>
        <Box mt='8' mr='2'>
          <Text fontSize={'xl'} color='black' fontWeight='600'>
            {heading}
          </Text>
          <Text fontSize={isMobile ? 'sm' : 'xl'} color='black' fontWeight='500' mt='4'>
            {subHeading1}
          </Text>
          {subHeading2 && (
            <Text fontSize={isMobile ? 'sm' : 'xl'} color='black' fontWeight='500' mt='4'>
              {subHeading2}
            </Text>
          )}
          <Flex>
            <Button
              bgColor='green.700'
              borderRadius='10px'
              leftIcon={<RocketLaunch height={8} width={8} />}
              paddingInline='0'
              padding='.5rem 1rem'
              width='fit-content'
              mt={'8'}
              onClick={() => dispatch(SetTabs(5))}
            >
              <Text fontSize={isMobile ? 'md' : 'xl'} fontWeight='500'>
                VIEW PAID PLANS
              </Text>
            </Button>
          </Flex>
        </Box>
        {!isMobile && (
          <Box ml='8'>
            <FreePlanImage height='250px' width='250px' />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default UserUpgradeModal;
