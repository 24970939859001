import React, { useState } from 'react';
import { Menu, Button, MenuItem, MenuList, MenuButton } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import AddIconComponent from 'components/Icons/AddIcon';
import ArrorDropdownIconComponent from 'components/Icons/ArrowDropdownIcon';
import { getUserSubscriptionLimits, SetModelLimit } from 'redux/UserSlice';
import CreateModelModal from 'components/Modals/CreateModelModal';
import { AppDispatch } from 'utils/GlobalHelpers';
import { AddModel, getModels, UpdateModel } from 'redux/ModelsSlice';

const CreateNewModel = () => {
  const dispatch: AppDispatch = useDispatch();

  const [addModel, setAddModel] = useState({ type: '', status: false, modelData: {} });

  const models = useSelector(getModels);
  const permissionData = useSelector(getUserSubscriptionLimits);

  const addModelForm = () => {
    if (
      permissionData?.subscription_features_limit?.No_of_models &&
      !(permissionData?.subscription_features_limit?.No_of_models > models?.length)
    ) {
      dispatch(SetModelLimit(true));
    } else {
      setAddModel({ status: true, type: 'add', modelData: {} });
    }
  };

  const addLibraryModel = () => {
    if (
      permissionData?.subscription_features_limit?.No_of_models &&
      !(permissionData?.subscription_features_limit?.No_of_models > models?.length)
    ) {
      dispatch(SetModelLimit(true));
    } else {
      window.open('https://www.blox.so/models', '_blank');
    }
  };

  const handleAddModel = (form: any, shouldUpdate: boolean, modalData: any) => {
    setAddModel({ modelData: {}, type: '', status: false });
    if (!shouldUpdate) {
      dispatch(
        AddModel({
          data: form,
          toaster: { successMessage: 'Added successfully', errorMessage: true },
        }),
      );
    } else {
      dispatch(UpdateModel({ modelId: modalData?.id, data: form, toaster: true }));
    }
  };

  return (
    <React.Fragment>
      <Menu closeOnSelect={true} variant='primary'>
        <MenuButton
          ml={8}
          mr={2}
          backgroundColor={'#5A58AB'}
          padding={'.5rem 1rem'}
          as={Button}
          border={'none'}
          rightIcon={
            <ArrorDropdownIconComponent
              style={{ margin: '0 .5rem' }}
              height={12}
              width={12}
              color='white'
            />
          }
          leftIcon={
            <AddIconComponent
              style={{ margin: '0 .5rem' }}
              color='#62B5BA'
              height={18}
              width={18}
            />
          }
          fontSize={'md'}
          fontWeight={'400'}
          width={'fit-content'}
          height={'40px'}
        >
          Create
        </MenuButton>
        <MenuList zIndex={4}>
          <MenuItem onClick={() => addModelForm()}>New blank model</MenuItem>
          <MenuItem onClick={() => addLibraryModel()}>New model from library</MenuItem>
        </MenuList>
      </Menu>

      <CreateModelModal
        modalData={addModel.modelData}
        modalType={addModel.type}
        isOpen={addModel.status}
        onClose={() => setAddModel({ status: false, type: '', modelData: {} })}
        onSave={handleAddModel}
      />
    </React.Fragment>
  );
};

export default CreateNewModel;
