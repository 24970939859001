export default {
  normal: 'normal',
  none: 1,
  1: '.75rem', // 12px
  2: '.875rem', // 14px
  3: '1rem', // 16px
  4: '1.125rem', // 18px
  5: '1.25rem', // 20px
  6: '1.375rem', // 22px
  7: '1.5rem', // 24px
  8: '1.625rem', // 26px
  9: '1.75rem', // 28px
  10: '1.875rem', // 30px
  11: '2rem', // 32px
};
