import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

interface Properties {
  modalHeading?: string | null;
  userName?: string | null;
  isOpen: boolean;
  onClose: () => void;
}

const getHeading = (name: string | null | undefined) => (
  <Text variant='modal' size='modalContent' textAlign={'center'}>
    Invitation has been sent to{' '}
    <Text as='span' size='subTitle'>
      {name}
    </Text>
  </Text>
);

const InformationModal = ({ modalHeading, userName, isOpen, onClose }: Properties) => (
  <Modal isOpen={isOpen} onClose={onClose} size='xs'>
    <ModalOverlay />
    <ModalContent pb={3} width={62.5} color='black'>
      <ModalHeader position='relative'>
        <Text variant='modal' size='subTitle' align='center'>
          {modalHeading}
        </Text>
      </ModalHeader>

      <ModalBody>
        <Text variant='modal' size='modalContent'>
          {getHeading(userName)}
        </Text>
      </ModalBody>

      <ModalFooter pb='0' margin={'auto'}>
        <HStack spacing='5'>
          <Button variant='clear' size='auto' fontSize='xs' color='red.500' onClick={onClose}>
            Ok
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default InformationModal;
