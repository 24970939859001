export default {
  variants: {
    vertical: {
      orientation: 'vertical',
      size: '2xs',
      //   colorScheme: 'grey',
      color: 'black',
      height: '12px',
      alignSelf: 'center',
    },
  },
  defaultProps: {},
};
