import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useBoolean,
  useMediaQuery,
  useOutsideClick,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Calendar from 'components/Icons/Calendar';
import Money from 'components/Icons/Money';
import PlanPermission from 'container/PlanPermission';
import TimeScalePermission from 'pages/PermissionComponent/TimeScalePermission';
import { CURRENCY_OPTIONS, MONTH_NAMES } from 'projectConstants';
import {
  FetchDimensionProperties,
  getBaseScenario,
  getSelectedDimension,
} from 'redux/BuilderModeSlice';
import {
  RenameUserModels,
  UpdateModelTime,
  getTimeStatus,
  resetTimeStatus,
  handleTimscaleDate,
} from 'redux/ModelsSlice';
import { getUserSubscriptionLimits } from 'redux/UserSlice';

import DatePickerInputButton from '../DatePickerWithButton';

export interface TimeProperties {
  calendar_type: string;
  time_granularity: 'M' | 'Y' | 'm' | 'y';
  plan_duration_periods: number;
  starting_period: string;
  ending_period?: string;
  fy_start_month?: string;
  date_format: string | null;
}

export interface Properties {
  modelId?: string;
  planPermission: boolean;
  properties: TimeProperties;
  reporting_currency?: string | null;
  is_time_granularity_editable: boolean;
}

const BlockInput = ({
  planPermission,
  properties,
  reporting_currency,
  is_time_granularity_editable,
}: Properties) => {
  const [isMobile]: any = useMediaQuery('(max-width: 768px)');
  const { modelId } = useParams();
  const [timescale, setTimescale] = useState('Y');
  const [periodAmount, setPeriodAmount] = useState('3');
  const [maxValue, setMaxValue] = useState(3);
  const [isPermissionTimescaleModal, setIsPermissionTimescaleModal] = useState(false);
  const [startIn, setStartIn] = useState<Date | any>(new Date());
  const [endIn, setEndIn] = useState<Date | any>(new Date());
  const [currency, setCurrency] = useState('£');
  const [currencyName, setCurrencyName] = useState('£-Pounds');
  const [timeScalePopover, setTimeScalePopover] = useBoolean();
  const UserSubscriptionLimitData = useSelector(getUserSubscriptionLimits);
  const timeStatusError = useSelector(getTimeStatus);
  const selectedDimension: any = useSelector(getSelectedDimension);
  const selectedScenario: any = useSelector(getBaseScenario);
  const timeGranualityLimit =
    UserSubscriptionLimitData?.subscription_features_limit?.model_timescale;
  const dispatch: any = useDispatch();

  const reference = useRef() as React.MutableRefObject<HTMLInputElement>;

  useEffect(() => {
    if (
      timeGranualityLimit === null &&
      UserSubscriptionLimitData?.current_subscription_name !== 'Ultimate'
    ) {
      setMaxValue(-1);
    } else {
      if (timescale.toLowerCase() === 'm') {
        setMaxValue(timeGranualityLimit);
      }
      if (timescale.toLowerCase() === 'y') {
        setMaxValue(timeGranualityLimit / 12);
      }
    }
  }, [timescale, UserSubscriptionLimitData, timeGranualityLimit]);

  const getButtonStyle = () => {
    return {
      borderRadius: 10,
      fontSize: isMobile ? 12 : 'sm',
      fontWeight: 'normal',
      backgroundColor: 'transparent',
      color: 'rgba(255, 255, 25r5, 1)',
      padding: '0.1rem .2rem',
    };
  };

  const cardStyle = {
    backgroundColor: '#312b66',
    borderRadius: 4,
    padding: 5,
  };

  const menuListColor = {
    color: 'black',
  };

  const onChangePeriod = async (valueDiff: any) => {
    const newValue = valueDiff;

    if (Number(newValue) <= maxValue) {
      setPeriodAmount(newValue);
    }
    if (maxValue === -1) {
      setPeriodAmount(newValue);
    }
    if (
      Number(newValue) >= maxValue &&
      UserSubscriptionLimitData?.current_subscription_name !== 'Ultimate'
    ) {
      setIsPermissionTimescaleModal(true);
      setTimeout(() => {
        const timeGranularity = properties?.time_granularity ?? 'M';
        const value = Number.isNaN(properties?.plan_duration_periods)
          ? timeGranularity.toLowerCase() === 'm'
            ? 36
            : 3
          : Number(properties?.plan_duration_periods);
        setPeriodAmount(value.toString());
      }, 1000);
      setTimeout(() => {
        setIsPermissionTimescaleModal(false);
      }, 5000);
    }
  };
  useEffect(() => {
    if (properties) {
      const timeGranularity = properties?.time_granularity ?? 'M';
      setTimescale(timeGranularity.toUpperCase());
      const value = Number.isNaN(properties?.plan_duration_periods)
        ? timeGranularity.toLowerCase() === 'm'
          ? 36
          : 3
        : Number(properties?.plan_duration_periods);
      setPeriodAmount(value.toString());
      setStartIn(properties?.starting_period ? new Date(properties?.starting_period) : null);
    }
    if (properties && properties?.starting_period) {
      const endDate = new Date(properties?.starting_period);
      const timeGranularity = properties?.time_granularity ?? 'M';

      const value = Number.isNaN(properties?.plan_duration_periods)
        ? timeGranularity.toLowerCase() === 'm'
          ? 36
          : 3
        : Number(properties?.plan_duration_periods);
      const targetValue = timeGranularity.toLowerCase() === 'm' ? value : value * 12;
      const dateEnd = endDate.setMonth(endDate.getMonth() + targetValue);
      setEndIn(new Date(dateEnd));
      dispatch(
        handleTimscaleDate({ startDate: startIn?.toISOString(), endDate: endIn?.toISOString() }),
      );
    } else {
      setEndIn(null);
    }

    return () => {
      dispatch(resetTimeStatus());
    };
  }, [properties, isPermissionTimescaleModal === true, timeStatusError === true]);

  const changeTimeInput = useCallback(
    (time: string, amount: string, startDate: Date | null, endDate?: Date | null) => {
      const requestObject: any = {
        model_id: Number(modelId),
        request_body: {
          time_granularity: time,
          plan_duration_periods: amount,
        },
      };
      if (endDate) {
        const endInDate = endDate.toDateString();
        requestObject.request_body.ending_period =
          time === 'Y' ? dayjs(endInDate).format('YYYY') : dayjs(endInDate).format('DD MMM YY');
      }
      if (startDate !== null) {
        const startInDate = startDate.toDateString();
        requestObject.request_body.starting_period =
          time === 'Y' ? dayjs(startInDate).format('YYYY') : dayjs(startInDate).format('DD MMM YY');
      }
      if (!isPermissionTimescaleModal)
        dispatch(UpdateModelTime({ requestObject, toaster: true })).then(() => {
          dispatch(
            FetchDimensionProperties({
              selectedId: selectedDimension?.id,
              parameters: { scenario_id: selectedScenario?.id },
            }),
          );
        });
    },
    [modelId, selectedDimension, selectedScenario],
  );

  const onChangeTimescale = useCallback(
    (value: string) => {
      setTimescale(value);
      setPeriodAmount(value === 'M' ? '36' : '3');

      setStartIn(new Date());
      const endDate = new Date();
      const dateEnd = endDate.setMonth(endDate.getMonth() + 36);
      setEndIn(new Date(dateEnd));
      changeTimeInput(value, value === 'M' ? '36' : '3', new Date(), new Date(dateEnd));
    },
    [changeTimeInput, properties],
  );

  const startDateChange = (startDate: Date) => {
    if (timescale.toLowerCase() === 'm') {
      const monthsApart =
        (endIn.getFullYear() - startDate.getFullYear()) * 12 +
        (endIn.getMonth() - startDate.getMonth());
      setPeriodAmount(`${monthsApart}`);
      onChangePeriod(monthsApart);
      return `${monthsApart}`;
    }
    const yearDifference = endIn.getFullYear() - startDate.getFullYear();
    setPeriodAmount(yearDifference.toString());
    onChangePeriod(`${yearDifference}`);
    return `${yearDifference}`;
  };

  const endDateChange = (endDate: Date) => {
    if (timescale.toLowerCase() === 'm') {
      const monthsApart =
        (endDate.getFullYear() - startIn.getFullYear()) * 12 +
        (endDate.getMonth() - startIn.getMonth());
      setPeriodAmount(`${monthsApart}`);
      onChangePeriod(monthsApart);
      return `${monthsApart}`;
    }
    const yearDifference = endDate.getFullYear() - startIn.getFullYear();
    setPeriodAmount(yearDifference.toString());
    onChangePeriod(`${yearDifference}`);
    return `${yearDifference}`;
  };

  const onSelectStartDate = async (date: Date) => {
    if (!planPermission) {
      setStartIn(date);
      const diff = startDateChange(date);
      onChangePeriod(Number(diff));
      changeTimeInput(timescale, diff, date, endIn);
    }
  };

  const onSelectEndDate = useCallback(
    (date: Date) => {
      if (!planPermission) {
        setEndIn(date);
        const diff = endDateChange(date);
        onChangePeriod(Number(diff));
        changeTimeInput(timescale, diff, startIn, date);
      }
    },
    [changeTimeInput, periodAmount, timescale],
  );

  const onChangeCurrency = useCallback(
    (value: string) => {
      const object = CURRENCY_OPTIONS.find((option) => {
        return option.value === value;
      });
      dispatch(
        RenameUserModels({
          model_id: Number(modelId),
          request_body: {
            reporting_currency: value || '£',
          },
        }),
      ).then(() => {
        setCurrencyName(object ? `${object.value} ${object.label}` : '£ Pounds');
        setCurrency(value);
      });
    },
    [modelId],
  );

  const handleCurrencyChange = (value: string) => {
    onChangeCurrency(value);
  };

  useEffect(() => {
    if (reporting_currency) {
      const object = CURRENCY_OPTIONS.find((option) => {
        return option.value === reporting_currency;
      });
      setCurrencyName(object ? `${object.value} ${object.label}` : '£ Pounds');
      setCurrency(reporting_currency);
    }
  }, [setCurrency, reporting_currency]);

  const handleClearSelection = () => {
    setStartIn(null);
  };

  const calculateShowData = (data: any) => {
    if (timescale === 'Y') {
      return data?.getFullYear();
    }

    return `${data?.toLocaleDateString('en-US', { month: 'short' })} ${data
      ?.getFullYear()
      .toString()
      .slice(-2)}`;
  };

  useOutsideClick({
    ref: reference,
    handler: () => {
      setTimeScalePopover.off();
    },
  });

  const onChangeFiscalYear = (month: string) => {
    const requestObject = {
      model_id: Number(modelId),
      request_body: {
        time_granularity: timescale,
        plan_duration_periods: properties?.plan_duration_periods,
        ending_period:
          timescale.toLowerCase() === 'y'
            ? dayjs(endIn).format('YYYY')
            : dayjs(endIn).format('DD MMM YY'),
        starting_period:
          timescale.toLowerCase() === 'y'
            ? dayjs(startIn).format('YYYY')
            : dayjs(startIn).format('DD MMM YY'),
        fy_start_month: `${month}`,
      },
    };
    dispatch(UpdateModelTime({ requestObject, toaster: true }));
  };

  return (
    <Stack
      direction={{ base: 'row', isMobile: 'column' }}
      mt={isMobile && 2}
      w={'100%'}
      alignItems={'center'}
      flexWrap={'wrap'}
    >
      <Text fontSize={'14px'} fontWeight={'400'} mr={4}>
        You are planning for
      </Text>
      <Box
        w={'fit-content'}
        style={cardStyle}
        height='100%'
        minWidth='fit-content'
        padding={'0 .5rem !important'}
        bgColor='#332663 !important'
        borderRadius={'10px !important'}
      >
        <Flex align={'center'} gap={3}>
          <Flex ps={0} gap={1}>
            <Popover
              placement='bottom-start'
              closeOnBlur={false}
              isOpen={timeScalePopover}
              onOpen={setTimeScalePopover.on}
              onClose={setTimeScalePopover.off}
              isLazy
              lazyBehavior='keepMounted'
            >
              <Box ref={reference}>
                <PopoverTrigger>
                  <Flex cursor={'pointer'} alignItems={'center'}>
                    <Calendar
                      style={{
                        marginRight: '.2rem',
                      }}
                      width={6}
                      height={4}
                      color='#69B4B8'
                    />
                    <ChevronDownIcon w={5} h={5} />
                    <Text ml={1} size={'Roboto-12'}>
                      {calculateShowData(startIn)}
                    </Text>
                    <Text fontSize={'14px'} mx={1}>
                      -
                    </Text>
                    <Text size={'Roboto-12'}>{calculateShowData(endIn)}</Text>
                  </Flex>
                </PopoverTrigger>
                <PopoverContent
                  filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))'
                  bg='white'
                  border='none'
                  mt={3}
                  borderRadius={'8px'}
                >
                  <PopoverBody ps={4}>
                    <Text color='#6562CF' fontSize={'xs'} fontWeight={'600'} py={2}>
                      Select
                    </Text>
                    <Menu closeOnSelect={true}>
                      <MenuButton
                        border={'1px solid #8B89B1'}
                        borderRadius={'5px !important'}
                        minWidth={'100px'}
                        maxHeight={'30px'}
                        isDisabled={!is_time_granularity_editable || planPermission}
                        cursor={planPermission ? 'not-allowed' : 'pointer'}
                        _disabled={{ bg: 'white' }}
                        rightIcon={
                          is_time_granularity_editable ? (
                            <ChevronDownIcon fontSize='md' />
                          ) : undefined
                        }
                        as={Button}
                        mt={{ base: '1', md: '0' }}
                        style={getButtonStyle()}
                        w={'100px'}
                        textTransform='none'
                        color={'#1A202C !important'}
                      >
                        {timescale === 'M' ? 'Monthly' : 'Yearly'}{' '}
                      </MenuButton>
                      <MenuList style={menuListColor}>
                        <MenuOptionGroup value={timescale} type='radio'>
                          <MenuItemOption value='M' onClick={() => onChangeTimescale('M')}>
                            Monthly
                          </MenuItemOption>
                          <MenuItemOption value='Y' onClick={() => onChangeTimescale('Y')}>
                            Yearly
                          </MenuItemOption>
                        </MenuOptionGroup>
                      </MenuList>
                    </Menu>
                    {timescale === 'M' && (
                      <>
                        <Box pt={4} pb={2}>
                          <Text color='#6562CF' fontSize={'xs'} fontWeight={'600'}>
                            Fiscal year start month
                          </Text>
                        </Box>

                        <Menu closeOnSelect={true}>
                          <MenuButton
                            border={'1px solid #8B89B1'}
                            borderRadius={'5px !important'}
                            minWidth={'100px'}
                            maxHeight={'30px'}
                            isDisabled={!is_time_granularity_editable || planPermission}
                            cursor={planPermission ? 'not-allowed' : 'pointer'}
                            _disabled={{ bg: 'white' }}
                            rightIcon={
                              is_time_granularity_editable ? (
                                <ChevronDownIcon fontSize='md' />
                              ) : undefined
                            }
                            as={Button}
                            mt={{ base: '1', md: '0' }}
                            style={getButtonStyle()}
                            w={'100px'}
                            textTransform='none'
                            color={'#1A202C !important'}
                          >
                            {properties?.fy_start_month}
                          </MenuButton>
                          <MenuList
                            style={menuListColor}
                            maxH='200px'
                            overflow='scroll'
                            className='sm-scroll'
                            p={'2'}
                          >
                            <MenuOptionGroup value={properties?.fy_start_month} type='radio'>
                              {MONTH_NAMES.map((month: string) => (
                                <MenuItemOption
                                  key={month}
                                  value={month}
                                  onClick={() => onChangeFiscalYear(month)}
                                >
                                  {month}
                                </MenuItemOption>
                              ))}
                            </MenuOptionGroup>
                          </MenuList>
                        </Menu>
                      </>
                    )}
                    <Box pt={4} pb={2}>
                      <Text color='#6562CF' fontSize={'xs'} fontWeight={'600'}>
                        Timescale
                      </Text>
                    </Box>
                    <Flex justify={'start'} align={'center'} pb={3}>
                      <DatePickerInputButton
                        clearSection={false}
                        clearDateInput={handleClearSelection}
                        selected={startIn}
                        onChange={onSelectStartDate}
                        timescale={timescale}
                        isDisabled={planPermission}
                      />
                      <Flex px={2}>
                        <BsArrowRight color={'black'} style={{ width: '20px', height: '20px' }} />
                      </Flex>
                      <DatePickerInputButton
                        clearSection={false}
                        clearDateInput={handleClearSelection}
                        selected={endIn}
                        onChange={onSelectEndDate}
                        timescale={timescale}
                        isDisabled={planPermission}
                      />
                    </Flex>
                    <Text color={'#8B8AAB'} fontSize={'12px'} ps={1}>
                      {timescale === 'M'
                        ? `${Number(periodAmount) + 1} months`
                        : `${Number(periodAmount) + 1} years`}
                    </Text>
                    {isPermissionTimescaleModal && (
                      <TimeScalePermission
                        maxValue={maxValue}
                        timescale={timescale}
                        modelType={UserSubscriptionLimitData?.current_subscription_name}
                      />
                    )}
                  </PopoverBody>
                </PopoverContent>
              </Box>
            </Popover>
          </Flex>
          <Divider orientation='vertical' mx={2} height={'10px'} border={'1px solid #9C9DBA'} />
          <Menu closeOnSelect={true}>
            <MenuButton
              border={'none !important'}
              isDisabled={planPermission}
              _disabled={{ bg: 'white' }}
              data-testid='currencyFilter-test'
              as={Button}
              maxHeight={'35px'}
              cursor={planPermission ? 'not-allowed' : 'pointer'}
              leftIcon={
                <Money
                  style={{
                    marginRight: '.1rem',
                  }}
                />
              }
              rightIcon={<ChevronDownIcon fontSize='md' />}
              style={getButtonStyle()}
              textDecoration='none'
              m='auto'
              w={'fit-content'}
              textAlign='start'
              textTransform='none'
              fontSize={'14px !important'}
            >
              <Text size={'Roboto-12'}>{currencyName}</Text>
            </MenuButton>

            <MenuList>
              <MenuOptionGroup value={currency}>
                {CURRENCY_OPTIONS.map((option: any) => {
                  return (
                    <MenuItemOption
                      data-testid={`currencyFilterValue-test-${option.value}`}
                      value={option.value}
                      key={option.value}
                      aria-checked={currency === option.value}
                      onClick={() => {
                        handleCurrencyChange(option.value);
                      }}
                    >
                      {option.value} {option.label}
                    </MenuItemOption>
                  );
                })}
              </MenuOptionGroup>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
    </Stack>
  );
};

export default PlanPermission(BlockInput);
