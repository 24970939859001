/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';

import { STATUS } from 'projectConstants';
import apiService from 'services';

export const FetchDimensionList = createAsyncThunk(
  'fetchDimensionList/get',
  async (modelId: string, thunkAPI) => {
    try {
      const response = await apiService.getBuilderDimension(modelId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const FetchDimensionProperties = createAsyncThunk(
  'fetchDimensionItemsProperties/get',
  async ({ selectedId, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.getDimensionProperties(selectedId, { params: parameters });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const FetchDimensionItems = createAsyncThunk(
  'fetchTableItemsProperties/fetch',
  async ({ selectedId, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.getDimensionItemsWithProperties(selectedId, {
        params: parameters,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const FetchDimensionGroup = createAsyncThunk(
  'fetchDimensionGroup/get',
  async ({ data, parameters }: any, thunkAPI) => {
    try {
      const dimId = JSON.parse(data?.data_format);
      const response = await apiService.get_dimension_group(dimId?.dimension, {
        params: parameters,
      });
      return { id: data?.id, arr: response?.data?.items };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const DeleteDimensionProperty = createAsyncThunk(
  'deleteDimensionProperty/post',
  async ({ item_id, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.delete_property(item_id, { params: parameters });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const AddDimensionProperty = createAsyncThunk(
  'addDimensionProperty/post',
  async ({ dimension_id, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.additem_property({ dimension_id, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const DimensionReorderingData = createAsyncThunk(
  'dimensionReorderingData/patch',
  async ({ modelId, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.dimensionReorderingData({ modelId, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const UpdateDimensionPropertyName = createAsyncThunk(
  'updateDimensionPropertyName/post',
  async ({ item_id, name }: any, thunkAPI) => {
    try {
      const response = await apiService.update_property_name({ item_id, name });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const UpdateDimensionValues = createAsyncThunk(
  'updateDimensionValues/post',
  async ({ item_id, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.update_property({ item_id, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const UpdateDimensionProperties = createAsyncThunk(
  'updateDimensionProperties/post',
  async ({ property_id, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.update_dimension_property(property_id, request_body);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const UpdateDimensionPropertyBulk = createAsyncThunk(
  'updateDimensionPropertyBulk/post',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.update_property_bulk({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const ReorderDimensionProperty = createAsyncThunk(
  'reorderDimensionProperty/post',
  async ({ selectedDimension, reorededArr }: any, thunkAPI) => {
    try {
      const response = await apiService.reorder_property(selectedDimension, { items: reorededArr });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const UpdateDimensionName = createAsyncThunk(
  'updateDimensionName/post',
  async ({ item_id, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.update_group_dim_name({
        item_id,
        request_body,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const DeleteDimension = createAsyncThunk(
  'deleteDimension/delete',
  async (ItemId: any, thunkAPI) => {
    try {
      const response = await apiService.delete_group_dim_name(ItemId);
      return response.data.items;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const AddBuilderDimension = createAsyncThunk(
  'addBuilderDimension/delete',
  async ({ modelId, data }: any, thunkAPI) => {
    try {
      const response = await apiService.addBuilderDimension(modelId, data);
      return response.data.items;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const UpdatePropertyHeader = createAsyncThunk(
  'updatePropertyHeader/delete',
  async ({ propertyId, data }: any, thunkAPI) => {
    try {
      const response = await apiService.update_dimension_property(propertyId, data);
      return response.data.items;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const DeleteDimensionHeader = createAsyncThunk(
  'deleteDimensionHeader/delete',
  async ({ item_id }: any, thunkAPI) => {
    try {
      const response = await apiService.deleteDimensionProperty(item_id);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const AddDimensionHeader = createAsyncThunk(
  'addDimensionHeader/post',
  async ({ dimensionId, data }: any, thunkAPI) => {
    try {
      const response = await apiService.updateDimensionProperties(dimensionId, data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const DuplicateDimension = createAsyncThunk(
  'duplicateDimension/patch',
  async ({ dimId, data }: any, thunkAPI) => {
    try {
      const response = await apiService.duplicate_dimension(dimId, data);
      return response.data?.dimensions;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const FetchScenarios = createAsyncThunk(
  'fetchScenarios/fetch',
  async (modelId: any, thunkAPI) => {
    try {
      const response = await apiService.get_scenarios(modelId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const RenameScenario = createAsyncThunk(
  'scenarioRename/fetch',
  async (data: any, thunkAPI) => {
    try {
      const response = await apiService.renameScenario(data?.item_id, data.requestBody);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const DeleteScenarios = createAsyncThunk(
  'scenarioDelete/fetch',
  async (scenarioId: number, thunkAPI) => {
    try {
      const response = await apiService.deleteScenario(scenarioId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const CreateScenarios = createAsyncThunk(
  'scenarioCreate/fetch',
  async (data: any, thunkAPI) => {
    try {
      const response = await apiService.createScenario(data?.modelId, data.data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const DuplicateScenarios = createAsyncThunk(
  'scenarioDuplicate/fetch',
  async (data: any, thunkAPI) => {
    try {
      const response = await apiService.createDuplicateScenario(data?.scenarioId, data.data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const FetchSingleScenarios = createAsyncThunk(
  'singleScenario/fetch',
  async (scenarioId: number, thunkAPI) => {
    try {
      const response = await apiService.getSingleScenario(scenarioId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const DeleteBulkDimensionItems = createAsyncThunk(
  'DeleteBulkDimensionItems/delete',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.delete_multiple_property(request_body);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

interface BuilderModeSliceInterface {
  builderModeSlice: BuilderModeInterface;
}

interface BuilderModeInterface {
  builderModeTab: number;
  editDimension: string | null;
  selectedDimension: string | null;
  addDimension: boolean;
  addItem: boolean;
  addProperty: boolean;
  settingsSection: boolean;
  propertySection: boolean;
  scenarios: [];
  dimensions: any[];
  dimensionItems: [];
  filteredDimensionItems: any;
  dimensionProperties: any[];
  allScenarios: any[];
  scenarioFetchStatus: any;
  blockScenarioMap: any;
  modelId: any;
  blockId: null;
  baseScenario: any;
  scenarioList: any;
  scenarioListStatus: any;
  selectedSceanrioId: any;
  isIndicatorAvailabe: boolean;
  loader: boolean;
  expandSideInput: boolean;
  selectedTableCheckBox: any;
  filterItems: any;
  dimensionLoader: boolean;
}

const initialState: BuilderModeInterface = {
  builderModeTab: 0,
  selectedDimension: null,
  editDimension: null,
  addDimension: false,
  addItem: false,
  addProperty: false,
  settingsSection: false,
  propertySection: false,
  dimensionItems: [],
  filteredDimensionItems: null,
  dimensionProperties: [],
  dimensions: [],
  scenarios: [],
  allScenarios: [],
  modelId: null,
  blockId: null,
  scenarioFetchStatus: STATUS.IDLE,
  blockScenarioMap: {},
  baseScenario: null,
  scenarioList: null,
  scenarioListStatus: null,
  selectedSceanrioId: null,
  isIndicatorAvailabe: false,
  loader: false,
  expandSideInput: false,
  selectedTableCheckBox: [],
  filterItems: [],
  dimensionLoader: false,
};
const applyFilters = (state: any, dimensionItems: any) => {
  // const dimensionItems = current(state.dimensionItems) || [];
  const dimensionItemsCopy = JSON.parse(JSON.stringify(dimensionItems));
  const request = state.filterItems;
  const filteredArray = dimensionItemsCopy.filter((item: any) => {
    return request?.every((filter: any) => {
      const filterData = filter.data.find((data: any) => data.name === item[filter.id]);
      return filterData ? filterData.status : false;
    });
  });
  state.filteredDimensionItems = filteredArray || [];
  const filteredData = JSON.parse(JSON.stringify(filteredArray)).map((item: any) => {
    const filteredItem = {
      name: item.name,
      id: item.id,
      status: false,
    };
    return filteredItem;
  });
  const getCheckedItems = current(state.selectedTableCheckBox);
  state.selectedTableCheckBox = [
    ...filteredData,
    { name: 'All', id: 'all', status: false },
    { name: 'Intermediate', id: 'intermediate', status: false },
  ];
  state.selectedTableCheckBox.forEach((item: any) => {
    const matchingItem = getCheckedItems.find((data: any) => data.id === item.id);
    if (matchingItem) {
      item.status = matchingItem.status;
    }
  });
};

const BuilderModeSlice = createSlice({
  name: 'builderModeSlice',
  initialState,
  reducers: {
    handleExpandSideInput: (state, action) => {
      state.expandSideInput = action.payload;
    },
    handleBuilderModeTab: (state, action) => {
      state.builderModeTab = action.payload;
    },
    handleBuilderSelectedDimension: (state, action) => {
      state.selectedDimension = action.payload;
    },
    handleEditDimensionItem: (state, action) => {
      state.editDimension = action.payload;
    },
    handleAddDimensionStatus: (state, action) => {
      state.addDimension = action.payload;
    },
    handleAddItemStatus: (state, action) => {
      state.addItem = action.payload;
    },
    handleAddPropertyStatus: (state, action) => {
      state.addProperty = action.payload;
    },
    handleSettingsSection: (state) => {
      if (state.propertySection && !state.settingsSection) {
        state.propertySection = false;
      }
      state.settingsSection = !state.settingsSection;
    },
    handlePropertySection: (state) => {
      if (state.settingsSection && !state.propertySection) {
        state.settingsSection = false;
      }
      state.propertySection = !state.propertySection;
    },
    currentScenario: (state, action) => {
      state.selectedSceanrioId = action.payload;
    },
    indicatorAvailable: (state, action) => {
      state.isIndicatorAvailabe = action.payload;
    },
    selectBaseScenario: (state, action) => {
      state.baseScenario = action.payload;
      state.blockScenarioMap[`${state.modelId}`] = state.baseScenario?.id;
    },
    handleLoader: (state, action) => {
      state.loader = action.payload;
    },
    // On filters change
    handleDimensionItemsChange: (state, action) => {
      // const dimensionItems = current(state.dimensionItems) || [];
      const isFilterApplied = action.payload?.dimensionFilters.some(
        (item: any) => item?.isFiltersApplied,
      );
      state.filterItems = isFilterApplied ? action.payload?.dimensionFilters : [];
      applyFilters(state, state.dimensionItems);
    },
    handleDimensionLoader: (state, action) => {
      state.dimensionLoader = action.payload;
    },
    handleTableCheckBoxChange: (state, action) => {
      const getCheckBox = current(state.selectedTableCheckBox) || [];
      const selectedTableCheckBox = [...getCheckBox];
      const { itemId, status } = action.payload;
      const updatedSelectedTableCheckBox: any = selectedTableCheckBox.map((item: any) => {
        if (item.id === itemId) {
          return { ...item, status };
        }
        return item;
      });

      const allTrueExceptAll = updatedSelectedTableCheckBox.every(
        (item: any) => item.status || item.name === 'All' || item.name === 'Intermediate',
      );

      const intermediate =
        updatedSelectedTableCheckBox.some(
          (item: any) =>
            item.status === true && item.name !== 'All' && item.name !== 'Intermediate',
        ) && !allTrueExceptAll;

      updatedSelectedTableCheckBox[updatedSelectedTableCheckBox.length - 2] = {
        ...updatedSelectedTableCheckBox[updatedSelectedTableCheckBox.length - 2],
        status: allTrueExceptAll,
      };

      updatedSelectedTableCheckBox[updatedSelectedTableCheckBox.length - 1] = {
        ...updatedSelectedTableCheckBox[updatedSelectedTableCheckBox.length - 1],
        status: intermediate,
      };

      state.selectedTableCheckBox = updatedSelectedTableCheckBox;
    },
    handleTableCheckBoxChangeAll: (state, action) => {
      const getCheckBox = current(state.selectedTableCheckBox) || [];
      const selectedTableCheckBox = [...getCheckBox];
      const { status } = action.payload;
      const isAllChecked = selectedTableCheckBox.some((item: any) => {
        return item.status === true && item.id !== 'all' && item.id !== 'intermediate';
      });
      const updatedSelectedTableCheckBox: any = selectedTableCheckBox.map((item: any) => {
        if (item.id === 'intermediate') {
          return { ...item, status: false };
        }
        return { ...item, status };
      });
      state.selectedTableCheckBox = updatedSelectedTableCheckBox;
    },
    DeleteBulkDimensionItemsReducer: (state, action) => {
      const dimensionItems = current(state.filteredDimensionItems) || [];
      const itemId = action.payload.item_id;
      const updatedData = dimensionItems.filter((item: any) => !itemId.includes(item.id));
      state.filteredDimensionItems = updatedData;
    },
    resetBuilderDimension: (state) => {
      state.dimensionItems = [];
      state.filteredDimensionItems = null;
      state.dimensionProperties = [];
      state.dimensions = [];
      state.scenarios = [];
      state.selectedDimension = null;
      state.addDimension = false;
      state.settingsSection = false;
      state.propertySection = false;
      state.editDimension = null;
      state.addItem = false;
      state.addProperty = false;
      state.addDimension = false;
      state.allScenarios = [];
      state.blockScenarioMap = {};
      state.expandSideInput = false;
      state.filterItems = [];
      state.selectedTableCheckBox = [];
      // state.baseScenario = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(FetchDimensionList.fulfilled, (state, action) => {
        state.dimensions = action.payload?.dimensions;
      })
      .addCase(FetchScenarios.fulfilled, (state, action) => {
        state.allScenarios = action.payload.scenarios;
        state.scenarioFetchStatus = STATUS.SUCCESS;
        const userSpecificScenario = state.blockScenarioMap[`${state?.modelId}`];
        if (userSpecificScenario && state?.blockId) {
          state.baseScenario =
            state.allScenarios &&
            state.allScenarios.find((scenario: any) => {
              return scenario?.id === userSpecificScenario;
            });
        } else if (!state.baseScenario) {
          state.baseScenario =
            state.allScenarios &&
            state.allScenarios.find((scenario: any) => {
              return scenario.is_base;
            });
          if (state?.modelId) state.blockScenarioMap[`${state.modelId}`] = state.baseScenario?.id;
        }
      })
      .addCase(FetchScenarios.rejected, (state) => {
        state.scenarios = [];
      })
      .addCase(FetchDimensionList.rejected, (state) => {
        state.dimensions = [];
      })
      .addCase(FetchDimensionProperties.fulfilled, (state, action) => {
        state.dimensionProperties = action.payload?.properties;
      })
      .addCase(FetchDimensionItems.fulfilled, (state, action) => {
        const dimensionItems = action.payload;
        if (state.filterItems.length === 0) {
          state.dimensionItems = dimensionItems;
          state.filteredDimensionItems = dimensionItems || [];
        } else {
          state.dimensionItems = dimensionItems;

          applyFilters(state, action.payload);
        }
        if (state.filterItems.length === 0) {
          const filteredData = dimensionItems.map((item: any) => {
            const filteredItem = {
              name: item.name,
              id: item.id,
              status: false,
            };
            return filteredItem;
          });
          state.selectedTableCheckBox = [
            ...filteredData,
            { name: 'All', id: 'all', status: false },
            { name: 'Intermediate', id: 'intermediate', status: false },
          ];
        }
      })
      .addCase(FetchDimensionProperties.rejected, (state) => {
        state.dimensionProperties = [];
      })
      .addCase(FetchDimensionItems.rejected, (state) => {
        state.dimensionItems = [];
        state.filteredDimensionItems = null;
      })
      .addCase(UpdateDimensionPropertyBulk.fulfilled, (state, action) => {
        state.dimensionItems = action.payload;
        state.filteredDimensionItems = action.payload || [];
      })
      .addCase(UpdateDimensionValues.fulfilled, (state, action) => {
        // state.dimensionItems = action.payload;
        if (state.filterItems.length === 0) {
          state.filteredDimensionItems = action.payload || [];
        } else applyFilters(state, action.payload);
      })
      .addCase(DuplicateDimension.fulfilled, (state, action) => {
        state.dimensions = action.payload;
      })
      .addCase(UpdateDimensionProperties.fulfilled, (state, action) => {
        const dimProperties = current(state.dimensionProperties);
        const index = dimProperties.findIndex((object: any) => object.id === action.payload?.id);
        if (index !== -1) {
          state.dimensionProperties[index] = action.payload;
        }
      })
      .addCase(UpdateDimensionName.fulfilled, (state, action) => {
        // state.settingsSection = false;
        const dimensions = current(state.dimensions);
        const index = dimensions?.findIndex((object: any) => object?.id === action.payload?.id);
        if (index !== -1) {
          state.dimensions[index] = action.payload;
          state.selectedDimension = action.payload;
        }
      })
      .addCase(FetchDimensionGroup.fulfilled, (state, action) => {
        const tableProperties = current(state.dimensionProperties).map((data: any) => {
          if (data?.id === action?.payload?.id) {
            return { ...data, items: action?.payload?.arr };
          }
          return data;
        });
        state.dimensionProperties = tableProperties;
      });
  },
});

export const getBuilderModeTab = (state: BuilderModeSliceInterface) =>
  state.builderModeSlice?.builderModeTab;
export const getScenarios = (state: BuilderModeSliceInterface) => state.builderModeSlice.scenarios;
export const getDimensionItem = (state: BuilderModeSliceInterface) =>
  state.builderModeSlice.dimensionItems;
export const getFilteredDimensionItems = (state: BuilderModeSliceInterface) =>
  state.builderModeSlice.filteredDimensionItems;
export const getDimensionProperty = (state: BuilderModeSliceInterface) =>
  state.builderModeSlice.dimensionProperties;
export const getBuilderDimensions = (state: BuilderModeSliceInterface) =>
  state.builderModeSlice.dimensions;
export const getSelectedDimension = (state: BuilderModeSliceInterface) =>
  state.builderModeSlice.selectedDimension;
export const getCurrentEditDimension = (state: BuilderModeSliceInterface) =>
  state.builderModeSlice.editDimension;
export const getAddDimension = (state: BuilderModeSliceInterface) =>
  state.builderModeSlice.addDimension;
export const getAddItem = (state: BuilderModeSliceInterface) => state.builderModeSlice.addItem;
export const getAddProperty = (state: BuilderModeSliceInterface) =>
  state.builderModeSlice.addProperty;
export const getSettingsSectionStatus = (state: BuilderModeSliceInterface) =>
  state.builderModeSlice.settingsSection;
export const getPropertiesSectionStatus = (state: BuilderModeSliceInterface) =>
  state.builderModeSlice.propertySection;
export const getAllScenarios = (state: BuilderModeSliceInterface) =>
  state.builderModeSlice?.allScenarios;
export const getBaseScenario = (state: BuilderModeSliceInterface) =>
  state.builderModeSlice?.baseScenario;
export const getExandSideInputStatus = (state: BuilderModeSliceInterface) =>
  state.builderModeSlice?.expandSideInput;
export const getLoaderStatus = (state: BuilderModeSliceInterface) => state.builderModeSlice?.loader;
export const getDimensionLoaderStatus = (state: BuilderModeSliceInterface) =>
  state.builderModeSlice?.dimensionLoader;
export const getTableCheckBox = (state: BuilderModeSliceInterface) =>
  state.builderModeSlice.selectedTableCheckBox;

export const {
  handleLoader,
  selectBaseScenario,
  handleAddItemStatus,
  handleBuilderModeTab,
  handleSettingsSection,
  handlePropertySection,
  resetBuilderDimension,
  handleDimensionLoader,
  handleExpandSideInput,
  handleEditDimensionItem,
  handleAddPropertyStatus,
  handleAddDimensionStatus,
  handleTableCheckBoxChange,
  handleDimensionItemsChange,
  handleTableCheckBoxChangeAll,
  handleBuilderSelectedDimension,
  DeleteBulkDimensionItemsReducer,
} = BuilderModeSlice.actions;

export default BuilderModeSlice.reducer;
