/* eslint-disable unicorn/prefer-add-event-listener */
/* eslint-disable unicorn/prefer-dom-node-append */
import './header.css';

import {
  Box,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import AuthModalContainer from 'components/AuthModal/AuthModalContainer';
import Logo from 'components/Icons/Logo';
import QuestionIcon from 'components/Icons/QuestionIcon';
import SaveModel from 'components/Icons/SaveModel';
import LogoutModal from 'components/LogoutModal';
import UserManagement from 'pages/UserManagement';
import { getUserData } from 'redux/UserSlice';

import ShareButtonWithPopup from './ShareButtonWithPopup';

declare global {
  interface Window {
    Headway: {
      init: (config: any) => void;
    };
  }
}

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [isLogoTriggered, setIsLogoTriggered] = useState(false);
  const [modalState, setModalState] = useState({
    authModal: false,
    logoutModal: false,
  });

  const [isMobile] = useMediaQuery('(max-width: 575px)');

  const user = useSelector(getUserData);

  const isPlanPage = pathname.includes('/plan');
  const isInputPage = pathname.includes('/inputs');
  const isModelOverview = pathname.includes('/home') && pathname.includes('model');
  const isErrorPage = pathname === '/error';

  const alertUser = (error: any) => {
    const confirmationMessage =
      'Are you sure you want to leave this page? Changes you made may not be saved.';
    error.preventDefault();
    error.returnValue = confirmationMessage;
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://cdn.headwayapp.co/widget.js';
    document.head.appendChild(script);
    const config = {
      selector: '.HW_badge_cont',
      account: 'xbAnKy',
    };
    script.onload = () => {
      window.Headway.init(config);
    };
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (user?.is_anonymous === true) {
      window.addEventListener('beforeunload', alertUser);
      window.addEventListener('popstate', alertUser);
      return () => {
        window.removeEventListener('beforeunload', alertUser);
        window.removeEventListener('popstate', alertUser);
      };
    }
  }, [user]);

  const toggleLogoutModal = () => {
    setModalState((previousState) => ({
      ...previousState,
      logoutModal: !previousState.logoutModal,
    }));
  };

  const toggleAuthModal = () => {
    setModalState((previousState) => ({
      ...previousState,
      logoutModal: false,
      authModal: !previousState.authModal,
    }));
  };

  const onLogoClick = () => {
    if (user?.is_anonymous) {
      toggleLogoutModal();
      setIsLogoTriggered(true);
    } else {
      navigate('/home');
    }
  };

  const handleMenuItemClick = (url: string) => {
    window.open(url, '_blank');
  };

  return (
    <>
      <Flex h={'28px'} px={4} align='center' justify='space-between'>
        <Logo onClick={onLogoClick} styles={{ cursor: 'pointer' }} />

        {!isErrorPage && (
          <HStack spacing='3'>
            {(isPlanPage || isModelOverview) && user?.is_anonymous && (
              <Flex
                h={isMobile ? '20px' : '28px'}
                borderRadius='5px'
                fontFamily='regular_roboto'
                alignItems={'center'}
                justifyContent='space-between'
                cursor='pointer'
                onClick={toggleAuthModal}
                background='#f7e3e37d'
                p={'5px 10px'}
              >
                <Text color='red' fontSize={isMobile ? '9px' : '12px'} fontWeight='semibold' pr='2'>
                  {isMobile ? 'Save' : `Unsaved changes. Click here to save`}
                </Text>
                <SaveModel width={3} height={3} />
              </Flex>
            )}

            {(isPlanPage || isModelOverview || isInputPage) && <ShareButtonWithPopup />}

            {!isMobile && (
              <Box display={'flex'} alignItems={'center'} pr={2} fontSize={'14px'}>
                <Menu>
                  {({ isOpen }) => {
                    return (
                      <>
                        <MenuButton height={'20px'}>
                          <QuestionIcon />
                        </MenuButton>
                        {isOpen && (
                          <MenuList zIndex={9}>
                            <MenuItem
                              padding={'0 12px'}
                              onClick={() => handleMenuItemClick('https://blox.tawk.help/')}
                            >
                              Help Center
                            </MenuItem>
                            <MenuItem padding={'0 12px'}>
                              <span id='HW_badge_cont' className='HW_badge_cont'>
                                <span
                                  id='HW_badge'
                                  className='HW_badge HW_textBadge'
                                  data-count-unseen=''
                                >
                                  What's new
                                </span>
                              </span>
                            </MenuItem>
                            <MenuItem
                              padding={'0 12px'}
                              onClick={() => handleMenuItemClick('https://www.blox.so/contact')}
                            >
                              Contact Support
                            </MenuItem>
                          </MenuList>
                        )}
                      </>
                    );
                  }}
                </Menu>
              </Box>
            )}

            <UserManagement />
          </HStack>
        )}
      </Flex>

      <LogoutModal
        isOpen={modalState.logoutModal}
        onClose={toggleLogoutModal}
        onOpenAuthModal={toggleAuthModal}
        isLogoTriggered={isLogoTriggered}
        setIsLogoTriggered={setIsLogoTriggered}
      />
      <AuthModalContainer
        isOpen={modalState.authModal}
        onClose={toggleAuthModal}
        setIsLogoTriggered={setIsLogoTriggered}
      />
    </>
  );
};

export default Header;
