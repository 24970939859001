import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Flex,
  StyleProps,
} from '@chakra-ui/react';

import Assistant from 'components/Icons/Assistant';
import QuestionMarkIcon from 'components/Icons/QuestionMarkIcon';

interface Properties {
  helpText: any;
  style: StyleProps;
}

const HelpComponent = ({ helpText, style }: Properties) => {
  return (
    <Accordion
      width={style.width}
      fontFamily='regular_roboto'
      lineHeight='4'
      allowMultiple
      style={{ background: '#8885DD', borderRadius: '10px' }}
    >
      <AccordionItem style={{ border: 'none' }}>
        {({ isExpanded }) => (
          <>
            <AccordionButton
              padding={isExpanded ? '1rem .8rem 0 .8rem' : '1rem .8rem 1rem .8rem'}
              _hover={{ background: 'none' }}
            >
              <Flex
                width={'100%'}
                flexDir={'row'}
                alignItems='center'
                justifyContent={'space-between'}
              >
                <Flex alignItems={'center'}>
                  <QuestionMarkIcon height={23} width={23} color='white' />
                  <small style={{ marginLeft: '.5rem', fontSize: '14px' }}>Help</small>
                </Flex>
                <Box>
                  <small style={{ marginRight: '.5rem' }}>{isExpanded ? 'Hide' : 'Show'}</small>
                  <AccordionIcon />
                </Box>
              </Flex>
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Flex justifyContent={'space-between'}>
                <p style={{ fontSize: '14px' }}>{helpText}</p>
                <Assistant width={15} height={15} />
              </Flex>
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};
export default HelpComponent;
