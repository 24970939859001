import { ChevronDownIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  VStack,
  FormControl,
  Button,
  Flex,
  FormLabel,
  Tooltip,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { BsArrowRight } from 'react-icons/bs';
import { useState, useEffect } from 'react';

import { textStyles } from 'pages/ModelOverviewPage/Import/Helper';
import {
  clearStepperFormData,
  getDistinctCSV,
  getFormValues,
  handleActionStepNext,
  resetColumnIndicatorMappedData,
  setActionCreateForm,
  setClearSelectedAreasTable,
  setRowsIndicatorMappedData,
  setSelectedAreasProperties,
} from 'redux/DataImportSlice';
import { getBlockInputIndicator } from 'redux/PlanPageSlice';

import ActionStepFooter from '../../ActionStepFooter';

const validationSchema = Yup.object().shape({
  rowsIndicatorTableColumn: Yup.string().required('Time value is required'),
});

const RowsType = () => {
  const dispatch: any = useDispatch();
  const distinctCSVValues = useSelector(getDistinctCSV);
  const blockInputIndicator = useSelector(getBlockInputIndicator);
  const getFormValue = useSelector(getFormValues);
  const [formValue, setFormValue] = useState(getFormValue?.rowsIndicatorMappedData || {});
  const [selectedColumn, setSelectedColumn] = useState<any>(null);
  const modifyFormValue = (key: any, value: any) => {
    const object: any = JSON.parse(JSON.stringify(formValue));
    object[`${key}`] = value;
    setFormValue(object);
  };

  useEffect(() => {
    if (getFormValue?.rowsIndicatorTableColumn) {
      const foundItem: any = distinctCSVValues.find((column: any) => {
        return column.current === getFormValue?.rowsIndicatorTableColumn;
      });
      if (foundItem) setSelectedColumn(foundItem);
    }
  }, [getFormValue?.rowsIndicatorTableColumn]);

  const getIndicatorInput = (id: number) => {
    const data = blockInputIndicator.find((item: any) => item.id === Number(id));

    return data?.name ?? '';
  };
  useEffect(() => {
    if (selectedColumn && !(formValue && Object.keys(formValue)?.length)) {
      const updatedFormValue = { ...formValue };

      selectedColumn?.distinctValues?.forEach((item: any) => {
        if (item !== undefined && item !== '\n') {
          const trimmedItem = item.trim(); // Trim any extra spaces

          updatedFormValue[item] = '';
          const matchingBlock = blockInputIndicator.find(
            (block: any) => block.name.trim().toLowerCase() === trimmedItem.toLowerCase(),
          );

          if (matchingBlock) {
            updatedFormValue[item] = matchingBlock.id;
          }
        }
      });

      setFormValue(updatedFormValue);
    }
  }, [selectedColumn, formValue, blockInputIndicator]);

  const handleSubmit = (form: any, setSubmitting: (data: boolean) => void) => {
    dispatch(setRowsIndicatorMappedData(form)).then(() => {
      dispatch(resetColumnIndicatorMappedData());
      setSubmitting(false);
      dispatch(handleActionStepNext());
    });
  };

  return (
    <VStack display='flex' justify={'center'} w={'100%'} pt={3}>
      <Flex justifyContent={'space-between'} w={'full'} align={'center'}>
        <Text
          color={'#6562CF'}
          fontSize='lg'
          fontFamily={'regular_roboto'}
          fontWeight={'400'}
          textAlign={'start'}
        >
          Select indicators
        </Text>
        <CloseIcon
          color={'black'}
          width={3}
          height={3}
          cursor={'pointer'}
          onClick={async () => {
            await dispatch(setActionCreateForm(false));
            await dispatch(clearStepperFormData());
            await dispatch(setClearSelectedAreasTable());
          }}
        />
      </Flex>

      <Text color={'black'} {...textStyles} textAlign={'start'} width={'100%'}>
        Select the column in your table that relates to your indicators using the drop down menu
        below
      </Text>
      <Formik
        initialValues={{
          rowsIndicatorTableColumn: selectedColumn?.current ?? '',
          data: formValue,
        }}
        validationSchema={validationSchema}
        onSubmit={(form, { setSubmitting }) => handleSubmit(form, setSubmitting)}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue, isSubmitting }: any) => {
          return (
            <Form
              style={{
                width: '100%',
              }}
            >
              <FormControl pt={2}>
                <Menu closeOnSelect={true} isLazy>
                  <MenuButton
                    as={Button}
                    backgroundColor='white'
                    maxHeight={'40px'}
                    borderRadius='5px'
                    minWidth='140px'
                    textAlign='left'
                    padding='0 .8rem'
                    boxShadow='rgb(139, 137, 177) 0px 0px 0px 0.6px'
                    rightIcon={<ChevronDownIcon fontSize='md' />}
                    fontWeight='300'
                    fontSize='xs'
                    color='black.900'
                    w='100%'
                    border={
                      errors.rowsIndicatorTableColumn ? '2px solid #fe6e6e' : '0.6px solid #8B89B1'
                    }
                  >
                    <Text me={3} noOfLines={1} height='fit-content'>
                      {selectedColumn?.modified || <Text opacity={0.5}>Select column </Text>}
                    </Text>
                  </MenuButton>
                  <MenuList
                    height='fit-content'
                    maxHeight='20vh'
                    overflowY='auto'
                    className='scroll'
                  >
                    <MenuOptionGroup defaultValue={values.rowsIndicatorTableColumn || ''}>
                      {distinctCSVValues &&
                        distinctCSVValues?.map((item: any) => (
                          <MenuItemOption
                            key={item?.current}
                            value={item?.current}
                            onClick={() => {
                              setFieldValue('rowsIndicatorTableColumn', item?.current);
                              setSelectedColumn(item);
                              dispatch(
                                setSelectedAreasProperties({
                                  rowsIndicatorTableColumn: item?.current,
                                }),
                              );
                            }}
                          >
                            {item?.modified}
                          </MenuItemOption>
                        ))}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
                <ErrorMessage name='rowsIndicatorTableColumn' component='div' className='error' />
              </FormControl>
              <Text
                color={'#6562CF'}
                fontSize='lg'
                fontFamily={'regular_roboto'}
                fontWeight={'400'}
                textAlign={'start'}
                py={3}
              >
                Map the values into Blox
              </Text>
              {Object.keys(formValue).length > 0 && (
                <Flex justifyContent={'space-between'} align={'center'} w='full'>
                  <Text
                    fontSize='1rem'
                    color='black'
                    fontFamily={'regular_roboto'}
                    fontWeight={'600'}
                  >
                    Name in File
                  </Text>
                  <Flex w={'41%'}>
                    <Text
                      fontSize='1rem'
                      color='black'
                      fontFamily={'regular_roboto'}
                      fontWeight={'600'}
                    >
                      Indicator in Blox
                    </Text>
                  </Flex>
                </Flex>
              )}

              {selectedColumn?.distinctValues?.length > 0 ? (
                <Flex
                  flexDirection={'column'}
                  maxH={'25vh'}
                  overflowY={selectedColumn.distinctValues.length > 4 ? 'scroll' : 'unset'}
                  className={'scroll'}
                  pe='0.4rem'
                >
                  {selectedColumn?.distinctValues
                    .filter((item: any) => item !== undefined && item !== '\n')
                    .map((item: any) => (
                      <FormControl key={item}>
                        <Flex justifyContent={'space-between'} align={'center'} pt={3}>
                          <Tooltip label={item}>
                            <FormLabel
                              fontSize='1rem'
                              color='black'
                              fontFamily={'Roboto Regular'}
                              m={0}
                              maxW={'fit-content'}
                              noOfLines={1}
                              h={'fit-content'}
                              fontWeight={500}
                            >
                              {item}
                            </FormLabel>
                          </Tooltip>
                          <Flex align={'center'} gap={2} justify={'end'} w={'50%'}>
                            <BsArrowRight
                              color={'black'}
                              style={{ width: '20px', height: '20px' }}
                            />
                            <Menu closeOnSelect={true} isLazy>
                              <MenuButton
                                placeholder={item.name}
                                as={Button}
                                backgroundColor='white'
                                maxHeight={'40px'}
                                borderRadius='5px'
                                minWidth={'150px'}
                                textAlign='left'
                                padding='0 .8rem'
                                boxShadow={'rgb(139, 137, 177) 0px 0px 0px 0.6px'}
                                rightIcon={<ChevronDownIcon fontSize='md' />}
                                fontWeight='300'
                                fontSize='xs'
                                color='black.900'
                                w={'70%'}
                                border={'0.6px solid #8B89B1'}
                              >
                                <Text me={3} noOfLines={1} height={'fit-content'}>
                                  {values?.data[item] && getIndicatorInput(values?.data[item]) ? (
                                    getIndicatorInput(values?.data[item])
                                  ) : (
                                    <Text opacity={0.5}>Select indicator</Text>
                                  )}
                                </Text>
                              </MenuButton>
                              <MenuList
                                height={'fit-content'}
                                maxHeight={'20vh'}
                                overflowY={'auto'}
                                className='scroll'
                              >
                                <MenuOptionGroup
                                  defaultValue={item}
                                  onChange={(role: any) => {
                                    modifyFormValue(item, role);
                                    setFieldValue(item, role);
                                  }}
                                >
                                  <MenuItemOption>Select...</MenuItemOption>
                                  {blockInputIndicator &&
                                    blockInputIndicator?.length > 0 &&
                                    blockInputIndicator
                                      .filter(
                                        (header: any) =>
                                          !Object.values(formValue).includes(header?.id),
                                      )
                                      .map((header: any) => (
                                        <MenuItemOption key={header.id} value={header.id}>
                                          {header.name}
                                        </MenuItemOption>
                                      ))}
                                  {/* {distinctCSVValues
                                .filter(
                                  (header: any) =>
                                    !Object.values(formValue).includes(header?.current),
                                )
                                .map((header: any) => (
                                  <MenuItemOption key={header} value={header.current}>
                                    {header.modified}
                                  </MenuItemOption>
                                ))} */}
                                </MenuOptionGroup>
                              </MenuList>
                            </Menu>
                          </Flex>
                        </Flex>
                      </FormControl>
                    ))}
                </Flex>
              ) : (
                <Text {...textStyles} color={'black'}>
                  No properties found
                </Text>
              )}
              <Flex
                alignItems={'center'}
                justifyContent={'flex-end'}
                pb={8}
                pt='5'
                w={'100%'}
                h={'fit-content'}
              >
                <ActionStepFooter
                  nextButtonName='Continue'
                  prevButtonName='Previous'
                  isSubmitting={isSubmitting}
                />
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </VStack>
  );
};
export default RowsType;
