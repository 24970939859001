import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  Heading,
  Button,
  useMediaQuery,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

// import { useAuthContext } from 'context/authContext';

import { getUserData } from 'redux/UserSlice';
import Logo from 'components/Icons/Logo';

import NewAuthModal from './NewAuthModal';

import AuthModal from '.';

interface Properties {
  isOpen: boolean;
  onClose: () => void;
  setIsLogoTriggered?: (value: boolean) => void;
}

const AuthModalContainer = ({ isOpen, onClose, setIsLogoTriggered }: Properties) => {
  const [successModal, setSuccessModal] = useState({ status: false, name: '' });
  const [isMobile] = useMediaQuery('(max-width: 575px)');
  // const { user } = useAuthContext();
  const user = useSelector(getUserData);

  return (
    <>
      {user?.is_anonymous ? (
        <NewAuthModal
          isOpen={isOpen}
          onClose={onClose}
          setIsLogoTriggered={setIsLogoTriggered}
          successModal={(data: any) => {
            setSuccessModal(data);
          }}
        />
      ) : (
        <AuthModal isOpen={isOpen} onClose={onClose} setIsLogoTriggered={setIsLogoTriggered} />
      )}
      <Modal
        onClose={() => {
          setSuccessModal({ ...successModal, status: !successModal.status });
        }}
        isOpen={successModal.status}
        isCentered
        size={isMobile ? 'xs' : 'sm'}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent minWidth={isMobile ? '90%' : 'fit-content'}>
          <ModalBody
            alignItems={'center'}
            p={isMobile ? 6 : 10}
            fontFamily='regular_roboto'
            boxShadow={'0 15px 15px rgba(0,0,0,15%)'}
          >
            <Flex direction='column' justify='center' align='center'>
              <Logo height={10} width={'fit-content'} />
              <Heading
                fontFamily='bold'
                color='black'
                as={isMobile ? 'h5' : 'h3'}
                size={isMobile ? 'md' : 'lg'}
                textAlign='center'
                pt='6'
              >
                Email sent to
                <Text color='green.900' style={{ wordBreak: 'break-word' }}>
                  {successModal.name}
                </Text>
              </Heading>
              <Text
                wordBreak='break-word'
                color='#1A202C'
                pt='4'
                textAlign='center'
                fontSize={isMobile ? 'xs' : 'md'}
              >
                Please check your inbox and click on the link to set your password.
              </Text>
            </Flex>
            <Flex width={'100%'} justifyContent={'center'}>
              <Button
                bgColor={'green.900'}
                mt={6}
                onClick={() => {
                  setSuccessModal({ ...successModal, status: !successModal.status });
                }}
                borderColor={'1px solid #7163D0'}
                width={'170px'}
                borderRadius='10px'
              >
                <Text fontSize={'sm'} fontFamily='bold'>
                  Ok
                </Text>
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
export default AuthModalContainer;
