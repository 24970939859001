import { Flex, Divider, useMediaQuery } from '@chakra-ui/react';
import { useSelector } from 'react-redux';

import { getBlockMetadata } from 'redux/PlanPageSlice';

import GroupByFilter from './GroupByFilter';
import MultipleIndicatorFilters from './MultipleIndicatorFilters';
import TimeScaleFilter from './TimeScaleFilter';

export interface Properties {
  refetchRequest: () => void;
}

const OverAllFilter = ({ refetchRequest }: Properties) => {
  const metadata = useSelector(getBlockMetadata);

  const [isMobile] = useMediaQuery('(max-width: 420px)');

  return (
    <Flex
      alignItems={isMobile ? 'start' : 'center'}
      height={'auto'}
      cursor='pointer'
      bg='#332663'
      borderRadius={'10px'}
      px={2}
      py={isMobile ? 2 : 1}
      width='fit-content'
    >
      <Flex alignItems={'center'} flexWrap={'wrap'}>
        {metadata?.years && metadata?.years.length > 0 && (
          <TimeScaleFilter refetchRequest={refetchRequest} />
        )}
        {metadata?.dimensions && metadata?.dimensions.length > 0 && (
          <>
            <MultipleIndicatorFilters />
            <Divider
              orientation='vertical'
              size='2xs'
              colorScheme='grey'
              height='12px'
              alignSelf='center'
              display={isMobile ? 'none' : 'block'}
            />
            <GroupByFilter />
          </>
        )}
      </Flex>
    </Flex>
  );
};

export default OverAllFilter;
