export default {
  variants: {
    group: {
      width: '45px',
      minWidth: '',
      padding: '0 .8rem',
      fontSize: '14px',
      fontFamily: 'regular_roboto',
      borderRadius: '8px',
    },
    outlined: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: 'xl',
      borderColor: 'white.900',
      color: 'white.900',
      bgColor: 'transparent',
      fontWeight: 700,
    },
    clear: {
      bgColor: 'transparent',
      color: 'white.900',
    },
    contained: {
      borderRadius: 'xl',
      borderColor: 'green.900',
      color: 'white.900',
      bgColor: 'green.900',
    },
    secure: {
      borderRadius: 'md',
      borderColor: 'violet.900',
      color: 'white.900',
      bgColor: 'violet.900',
      fontWeight: 400,
    },
    header: {
      borderRadius: 'sm',
      borderColor: 'violet.300',
      color: 'white.900',
      bgColor: 'violet.300',
    },
    stat: {
      borderRadius: 'xs',
      borderColor: 'white.900',
      color: 'white.900',
      bgColor: 'transparent',

      _active: {
        borderColor: 'green.900',
        bgColor: 'green.900',
      },
    },
    ghost: {
      _hover: {
        color: 'green.900',
        bgColor: 'transparent',
      },
      _active: {
        color: 'green.900',
        bgColor: 'transparent',
      },
    },
    picker: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: 'sm',
      borderColor: 'white.900',
      p: 3,
      color: 'white.900',
      bgColor: 'transparent',
      textTransform: 'capitalize',
      fontWeight: 400,
      fontSize: '2xs',
      lineHeight: '3',
    },
    tab: {
      borderRadius: 'none',
      color: 'white.900',
      fontWeight: 400,
      fontSize: '2xs',
    },
    action: {
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: 'xs',
      borderColor: 'green.900',
      color: 'white.900',
      textTransform: 'capitalize',
      fontWeight: 400,
      fontSize: '2xs',
      lineHeight: '4',
    },
    plan_action: {
      px: 2,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: 'sm',
      borderColor: 'green.900',
      color: 'white.900',
      fontWeight: 700,
      fontSize: 'sm',
      lineHeight: '7',
    },
    plan_action_md: {
      px: 4,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: 'base',
      borderColor: 'white.900',
      color: 'white.900',
      fontWeight: 700,
      fontSize: 'md',
      lineHeight: '7',
    },
    plan_action_lg: {
      px: 3,
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: 'lg',
      borderColor: 'green.900',
      color: 'white.900',
      fontWeight: 700,
      fontSize: 'lg',
      lineHeight: '7',
    },
    section: {
      px: 1,
      borderRadius: 'xs',
      borderColor: 'violet.500',
      color: 'white.900',
      bgColor: 'violet.500',
      fontWeight: 400,
      fontSize: '5px',
      lineHeight: '2',
    },
    slider: {
      bgColor: 'transparent',
      color: 'white.900',
    },
    primary: {
      width: 'fit-content',
      height: 'fit-content',
      color: '#7163D0',
      _hover: {},
    },
    clear_button: {
      width: 'fit-content',
      height: 'fit-content',
      backgroundColor: 'transparent',
      color: '#8C88A9',
      borderRadius: '6px',
      fontWeight: '400',
      padding: '.5rem 1.2rem',
      _hover: {},
    },
    save_button: {
      width: 'fit-content',
      height: 'fit-content',
      backgroundColor: '#7163D0',
      color: 'white',
      borderRadius: '6px',
      fontWeight: '400',
      padding: '.5rem 1.2rem',
      _hover: {},
    },
    cancel_button: {
      width: 'fit-content',
      height: 'fit-content',
      backgroundColor: 'grey.500',
      color: 'violet.800',
      borderRadius: '6px',
      padding: '.5rem 1.2rem',
      fontWeight: '400',
      _hover: {},
    },
    dashboardingModal: {
      width: 'fit-content',
      height: 'fit-content',
      color: 'black.600',
      border: '1px solid',
      borderRadius: '6px',
      borderColor: 'grey.700',
      fontWeight: '400',
      backgroundColor: '#FAFAFA',
    },
    default: {
      width: 'fit-content',
      height: 'fit-content',
      color: '#fff',
      borderRadius: '6px',
      padding: '.5rem 1.2rem',
      fontWeight: '400',
      backgroundColor: 'violet.100',
    },
  },
  sizes: {
    ghost: {
      w: 5,
      h: 5,
    },
    header: {
      h: 7,
      w: 20,
    },
    secure: {
      h: 7.5,
      w: 25,
    },
    stat: {
      h: 35,
      w: 90,
    },
    sm: {
      h: 7,
      w: 35,
    },
    md: {
      h: 12,
      w: 34.5,
    },
    lg: {
      h: 12,
      w: 73,
      fontSize: '2xs',
    },
    lg_full: {
      width: 'full',
      height: 12,
      fontSize: '2xs',
    },
    tab: {
      w: 'full',
      h: 7.5,
    },
    action: {
      w: 22,
      h: 6.5,
    },
    plan_action: {
      w: 'auto',
      h: 8.5,
    },
    plan_action_md: {
      w: 'auto',
      h: 12,
    },
    plan_action_lg: {
      w: 'auto',
      h: 15,
    },
    section: {
      w: 9.5,
      h: 3.5,
    },
    slider: {},
  },
  defaultProps: {
    size: 'lg',
    variant: 'outlined',
  },
};
