export default {
  variants: {
    block: {
      container: {
        bg: 'white',
        outline: 'none',
        boxShadow: 'none',
      },
      body: {
        margin: '0',
        padding: '0',
      },
    },
  },
};
