/* eslint-disable @typescript-eslint/lines-between-class-members */
/* eslint-disable class-methods-use-this */

import { Box, IconButton } from '@chakra-ui/react';
import { CellTemplate, Cell, Compatible, Uncertain, getCellProperty } from '@silevis/reactgrid';

import HorizontalMenu from 'components/Icons/horizontalMenu';

export interface FlagCell extends Cell {
  type: 'flag';
  text: string;
  rowId: string;
  isDeleteRow: boolean;
}

export class DeleteRowCellTemplate implements CellTemplate<FlagCell> {
  getCompatibleCell(uncertainCell: Uncertain<FlagCell>): Compatible<FlagCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const value = Number.parseFloat(text);
    return { ...uncertainCell, text, value } as Compatible<FlagCell>;
  }

  render() {
    return (
      <Box style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <IconButton
          width={'25px'}
          aria-label='delete model'
          size='ghost'
          variant='ghost'
          icon={<HorizontalMenu width={'25px'} height={'25px'} styles={{ filter: 'invert(1)' }} />}
        />
      </Box>
    );
  }
}
