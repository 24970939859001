import { Flex, List, ListItem, Text } from '@chakra-ui/react';
import { isAlphaNumericKey, isNavigationKey, keyCodes } from '@silevis/reactgrid';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const PropertyFormula = ({ cell, onCellChanged, getCompatibleCell }: any) => {
  const [filterValue, setfilterValue]: any = useState(null);
  const [formula, setFormula] = useState(cell?.text);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const inputReference: any = useRef(null);

  useEffect(() => {
    setFormula(cell?.text);
  }, [cell?.text]);

  useEffect(() => {
    const shouldVisible = Boolean(filterValue);
    setIsOptionsVisible(shouldVisible);
  }, [filterValue, formula]);

  const handleFormulaChange = (event: any) => {
    const value = event?.currentTarget?.value;
    setfilterValue(value ? value[0] : '');
    const lastTypedIndicator = value?.match(/\b(\w+)$/g);
    setFormula(value);
    setfilterValue(lastTypedIndicator ? lastTypedIndicator[0] : '');
  };

  const selectOption = useCallback(
    (option: string) => {
      if (inputReference?.current) {
        inputReference?.current?.focus();
      }
      const numberOfOptionWords = option.split(' ').length;
      const formulaArray = formula.split(' ');
      formulaArray.splice(-1, 1, option);
      if (numberOfOptionWords > 1) {
        const formulaString = formulaArray
          .map((element: any) => {
            if (element === option) {
              return `'${option}' `;
            }
            return element;
          })
          .join(' ');
        setFormula(formulaString);
      }
      if (numberOfOptionWords === 1) {
        const formulaString = formulaArray.join(' ');
        setFormula(formulaString);
      }
      setfilterValue('');
    },
    [formula],
  );

  const optionList = useMemo(() => {
    return cell?.options?.reduce((accumulator: any, option: any) => {
      if (option?.name?.toLowerCase()?.includes(filterValue?.toLowerCase())) {
        return [
          ...accumulator,
          <ListItem
            cursor={'pointer'}
            key={option?.id}
            color={'black'}
            onClick={() => selectOption(option?.name)}
          >
            <p>{option?.name}</p>
          </ListItem>,
        ];
      }
      return accumulator;
    }, []);
  }, [filterValue]);

  if (!cell) return <></>;

  return (
    <Flex position={'relative'}>
      <input
        disabled={cell?.nonEditable}
        style={{
          backgroundColor: '#423c82',
          color: 'white',
        }}
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
          }
        }}
        defaultValue={cell?.text}
        onChange={handleFormulaChange}
        // onChange={(event) =>
        //   onCellChanged(getCompatibleCell({ ...cell, text: event.currentTarget.value }), false)
        // }
        onBlur={(event) =>
          onCellChanged(
            getCompatibleCell({ ...cell, text: event.currentTarget.value }),
            (event as any).view?.event?.keyCode !== keyCodes.ESCAPE,
          )
        }
        onCopy={(event) => event.stopPropagation()}
        onCut={(event) => event.stopPropagation()}
        onPaste={(event) => event.stopPropagation()}
        onPointerDown={(event) => event.stopPropagation()}
        placeholder={cell.placeholder}
        onKeyDown={(event) => {
          if (isAlphaNumericKey(event.keyCode) || isNavigationKey(event.keyCode))
            event.stopPropagation();
        }}
      />
      {isOptionsVisible && (
        <List
          py={2}
          px={5}
          borderRadius={'8px'}
          position='absolute'
          top='40px'
          width='100%'
          zIndex='3'
          boxShadow='md'
          mt={1}
          bg='white'
          spacing={1}
        >
          <Text fontSize={'12px'} color={'#796cd6'}>
            CURRENT BLOCK
          </Text>
          {optionList}
        </List>
      )}
    </Flex>
  );
};

export default PropertyFormula;
