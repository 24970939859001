import { ChevronDownIcon } from '@chakra-ui/icons';
import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, Switch, Tooltip } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getBlockDetails,
  getBlockDimensions,
  getIndicatorTableData,
  resetMultiDimFilter,
  setFilterItems,
  setResetFilter,
  UpdatePlangPageIndicatorTableData,
} from 'redux/PlanPageSlice';
import DimensionTabIcon from 'components/Icons/ModelOverviewTabs/DimensionTabIcon';
import { getBaseScenario } from 'redux/ScenarioSlice';

interface Properties {
  refetchRequest: () => void;
  indicatorId: any;
}

const MultiDimSetting = ({ indicatorId, refetchRequest }: Properties) => {
  const dispatch: any = useDispatch();
  const indicatorTableData = useSelector(getIndicatorTableData);
  const baseScenario = useSelector(getBaseScenario);

  const dimensionList = useSelector(getBlockDetails);
  const dimensionItemList = useSelector(getBlockDimensions);
  const [dimensionData, setDimensionData] = useState(dimensionList?.dimensions || []);
  const [activeDim, setActiveDim] = useState<any>();

  useEffect(() => {
    const dimensionsList = dimensionList?.dimensions;
    setDimensionData(dimensionsList);
  }, [dimensionList?.dimensions]);
  useEffect(() => {
    const active = indicatorTableData?.dimensions?.length
      ? Number(indicatorTableData?.dimensions?.length) + 1
      : 1;
    setActiveDim(active);
  }, [indicatorTableData]);

  const doesDimensionExist = (dim: any, dimArray: any[]) => {
    if (dim?.name?.toLowerCase() === 'time') return true;
    const exists = dimArray?.some((data) => Number(data?.id) === dim?.id);
    return exists;
  };
  function addDim(currentDim: any, newDim: any) {
    let found = false;
    const updatedDim = JSON.parse(JSON.stringify(currentDim));
    updatedDim.forEach((dim: any, index: any) => {
      if (dim.id === String(newDim.id)) {
        updatedDim.splice(index, 1);
        found = true;
      }
    });
    if (!found) {
      updatedDim.push({ id: String(newDim.id) });
    }
    return updatedDim;
  }

  const handleSettingChange = async (data: any) => {
    const dim = indicatorTableData?.dimensions;
    const result = await addDim(dim, data);
    const payload = {
      indicator_id: indicatorId,
      request_body: {
        dimensions: result,
        bulk_input: true,
        type: indicatorTableData?.type,
        format_excel_data: true,
        scenario_id: baseScenario?.id,
      },
    };
    await dispatch(resetMultiDimFilter());
    await dispatch(setFilterItems([]));
    await dispatch(setResetFilter(true));
    await dispatch(UpdatePlangPageIndicatorTableData(payload));
    await refetchRequest();
  };

  const hasDimensionItems = (id: number) => {
    const dimension = dimensionItemList?.dimensions?.find((dim: any) => dim.id === id);

    if (dimension?.items.length > 0) {
      return true;
    }
    return false;
  };

  const renderMenuList = (list: any) => {
    const filteredList: any = [...(list || [])];
    return (
      <Box maxH={'190px'} overflowY={'auto'} className='scroll'>
        {filteredList?.map((data: any, index: number) => (
          <MenuItem key={index} padding={'.5rem 0'} as={'li'}>
            <Switch
              mr={2}
              disabled={
                data?.name === 'time' || data?.name === 'Time' || !hasDimensionItems(data?.id)
              }
              isChecked={doesDimensionExist(data, indicatorTableData?.dimensions)}
              onChange={() => handleSettingChange(data)}
            ></Switch>
            {!hasDimensionItems(data?.id) ? (
              data?.name === 'time' || data?.name === 'Time' ? (
                data?.name
              ) : (
                <Tooltip label='Dimension has no items'>
                  <p> {data?.name}</p>
                </Tooltip>
              )
            ) : (
              data?.name
            )}
          </MenuItem>
        ))}
      </Box>
    );
  };

  return (
    <Flex alignItems={'center'}>
      <Menu closeOnSelect={false} variant={'primary'}>
        <Tooltip label='Block Dimensions'>
          <MenuButton
            pl={2}
            pr={3}
            // py={2}
            color={'white'}
            fontSize={'14px'}
            transition='all 0.2s'
            borderWidth='1px'
          >
            <DimensionTabIcon width={7} height={6} color='#4BB6B9' /> ({activeDim} /
            {dimensionData?.length || 0}) <ChevronDownIcon />
          </MenuButton>
        </Tooltip>
        <MenuList w={'230px'} pb={0} px={4} maxH={'250px'}>
          {renderMenuList(dimensionData)}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default MultiDimSetting;
