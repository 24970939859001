interface Properties {
  width?: any;
  height?: any;
  color?: string;
  marginRight?: string;
}

const TextBoxIconComponent = ({ width, height, color, marginRight }: Properties) => {
  return (
    <svg
      style={{ marginRight }}
      width={width}
      height={height}
      fill={color}
      viewBox='0 0 22 23'
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='mask0_184_4682' maskUnits='userSpaceOnUse' x='0' y='0' width='22' height='23'>
        <rect x='0.80957' y='0.967651' width='21.0503' height='21.0503' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_184_4682)'>
        <path
          d='M2.56348 19.3865V17.6323H20.1054V19.3865H2.56348ZM17.4741 15.8781V3.59875H18.7898V15.8781H17.4741ZM4.31767 15.8781L8.92243 3.59875H11.1152L15.7199 15.8781H13.6149L12.5185 12.7206H7.56293L6.4227 15.8781H4.31767ZM8.1769 10.9664H11.8607L10.0627 5.87921H9.97494L8.1769 10.9664Z'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default TextBoxIconComponent;
