import {
  Box,
  Flex,
  Modal,
  ModalBody,
  MenuButton,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  MenuList,
  MenuItem,
  Stack,
  Text,
  Menu,
  Button,
  useMediaQuery,
  Image,
  ModalFooter,
  Textarea,
} from '@chakra-ui/react';
import Fingerprint2 from 'fingerprintjs2';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import axios from 'axios';
import { BiSend } from 'react-icons/bi';

import { Block } from 'models/response/model.interface';
import { getVersion } from 'redux/SharedSlice';
import AddIconComponent from 'components/Icons/AddIcon';
import ArrorDropdownIconComponent from 'components/Icons/ArrowDropdownIcon';
import { AddModelBlock, FetchModelBlocks, ReorderBlocks } from 'redux/ModelsSlice';
import { getUserData } from 'redux/UserSlice';
import BeatLoader from 'components/ThreeDotSpinner/BeatLoader';
import UserChatIcon from 'components/Icons/UserChatIcon';
import VoiceFlowGenerateIcon from 'components/Icons/VoiceFlowGenerateIcon';
import assistant from 'assets/assistant_icon.png';
import { ADD_INFORMATION } from 'projectConstants';
import blockImage from 'assets/blox_logo.png';

import Typewriter from '../TypeWriter';

import NewBlockCard from './newBlockCard';

export interface Properties {
  blocks: Block[];
  flags?: any;
}

const BlockList = ({ blocks, flags }: Properties) => {
  const { REACT_APP_PLANNING_GPT_ENV, REACT_APP_VOICEFLOW_API_KEY }: any = process.env;

  const dispatch: any = useDispatch();
  const { modelId } = useParams();
  const [fingerprint, setFingerprint] = useState('');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isSmallLaptop] = useMediaQuery('(max-width: 1415px)');
  const carouselReference = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenVoiceFlow, setIsOpenVoiceFlow] = useState(false);
  const [message, setMesssage]: any = useState([]);
  const [inputMessage, setInputMesssage]: any = useState('');
  const userData = useSelector(getUserData);
  const [loading, setLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);

  const [myBlocks, setBlocks] = useState(blocks);
  const token = localStorage.getItem('BLOX_USER_TOKEN');
  const versionData = useSelector(getVersion);
  const containerReference = useRef<any>();
  const scrollling = () => {
    setTimeout(() => {
      containerReference.current.scrollTop = containerReference?.current?.scrollHeight;
    }, 1000);
  };

  const body = {
    action: {
      type: 'launch',
      payload: null,
    },
    state: {
      variables: {
        blox_token: `${token}`,
        model_id: `${modelId}`,
        planninggpt_env: REACT_APP_PLANNING_GPT_ENV,
      },
    },
  };

  function urlify(text: any) {
    const urlRegex = /(https?:\/\/\S+)/g;
    const strongpattern = /\*\*(.*?)\*\*/g;
    const strongPatternString = text?.replace(strongpattern, '<strong>$1</strong>');
    const inputString = strongPatternString?.replace(/\n/g, '<br>');
    return `<div>  ${inputString?.replace(urlRegex, (url: any) => {
      return `<a href='${url}'>${url}</a>`;
    })} </div>`;
  }

  async function startInteract() {
    setTimeout(() => {
      setLoading(true);
    }, 2300);

    // Start a conversation
    await axios({
      method: 'POST',
      baseURL: 'https://general-runtime.voiceflow.com',
      url: `/state/user/${token}/interact`,
      headers: {
        Authorization: REACT_APP_VOICEFLOW_API_KEY,
      },
      data: body,
    })
      .then((response: any) => {
        setMesssage(JSON.parse(JSON.stringify(response?.data)));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const textStyle = {
    color: 'white',
    fontFamily: 'Comfortaa Regular',
  };

  useEffect(() => {
    dispatch(FetchModelBlocks({ modelId: Number(modelId) }));
  }, []);

  useEffect(() => {
    setBlocks(blocks);
  }, [blocks]);

  useEffect(() => {
    const generateFingerprint = async () => {
      const components: Array<any> = await Fingerprint2.getPromise();
      const values = components.map((component, index: number) => (
        <React.Fragment key={index}>{component.value}</React.Fragment>
      ));
      const fp: string = Fingerprint2.x64hash128(values.join(''), 31);
      setFingerprint(fp);
    };
    generateFingerprint();
  }, []);

  useEffect(() => {
    if (fingerprint && versionData) {
      versionData?.fingerprints.includes(fingerprint);
    }
  }, [versionData, fingerprint]);

  const modalBodyReference = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (modalBodyReference.current) {
        const configScript = document.createElement('script');
        configScript.text = `
        var myLandbot = new Landbot.Container({
          container: '#myLandbot',
          configUrl: 'https://storage.googleapis.com/landbot.pro/v3/H-1605358-LLBTCLEPEZTBMQIJ/index.json',
          customData : { 'blox_token': '${token}', "model_id": ${modelId} }
        });
      `;

        const targetElement = document.querySelector('#myLandbot');
        if (targetElement) {
          targetElement.innerHTML = '';
          targetElement.append(configScript);
        }
      }
    }, 100);
  }, [isOpen, modalBodyReference]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const onClose = () => setIsOpen(false);

  const onCloseVoiceFlow = () => {
    dispatch(FetchModelBlocks({ modelId: Number(modelId) }));
    setIsOpenVoiceFlow(false);
  };

  const handleAddNewBlock = () => {
    dispatch(
      AddModelBlock({
        modelId,
        data: {
          name: 'New Block',
        },
      }),
    );
  };

  const handleDragEnd = (event: any) => {
    const { active, over } = event;
    const blockIds = myBlocks.map((block: Block) => block.id);
    const oldIndex = blockIds.indexOf(active.id);
    const newIndex = blockIds.indexOf(over.id);
    setBlocks([...arrayMove(myBlocks, oldIndex, newIndex)]);
    dispatch(
      ReorderBlocks({ modelId, data: { blocks: [...arrayMove(blockIds, oldIndex, newIndex)] } }),
    ).then((response: any) => {
      if (response?.error)
        dispatch(
          FetchModelBlocks({
            modelId: Number(modelId),
            loader: true,
            toaster: { errorMessage: 'Internal Server Error' },
          }),
        );
    });
  };

  if (isMobile) {
    return (
      <>
        <Flex justifyContent={'space-between'}>
          <Text
            fontSize={isSmallLaptop ? 'lg' : 'md'}
            pb={5}
            style={{ ...textStyle, fontFamily: 'Roboto regular' }}
            align='start'
          >
            {ADD_INFORMATION}
          </Text>
          <Menu closeOnSelect={true} variant='primary'>
            <MenuButton
              backgroundColor={'#5A58AB'}
              padding={'.5rem 1rem'}
              as={Button}
              border={'none'}
              rightIcon={
                <ArrorDropdownIconComponent
                  style={{ margin: '0 .5rem' }}
                  height={12}
                  width={12}
                  color='white'
                />
              }
              leftIcon={
                <AddIconComponent
                  style={{ margin: '0 .5rem' }}
                  color='#62B5BA'
                  height={18}
                  width={18}
                />
              }
              fontSize={'md'}
              fontWeight={'400'}
              width={'fit-content'}
              height={'45px'}
            >
              Create
            </MenuButton>
            <MenuList>
              <MenuItem onClick={handleAddNewBlock}>New Block</MenuItem>
              {/* {flags?.antonGpt && (
                <MenuItem onClick={() => setIsOpen(true)}>+ Add new block</MenuItem>
              )} */}
            </MenuList>
          </Menu>
        </Flex>
        <Stack style={{ padding: isMobile ? 1 : 6 }}>
          <Flex mt={'2'} gap={'1rem'} flexWrap={'wrap'}>
            {myBlocks &&
              myBlocks?.map((block: Block, index: number) => (
                <React.Fragment key={index}>
                  <NewBlockCard block={block} key={index} />
                </React.Fragment>
              ))}
          </Flex>
        </Stack>
      </>
    );
  }

  const startInteractWithVoiceFlow = () => {
    setMesssage([{ payload: { message: 'Hi Hello', type: 'text' } }]);
    startInteract();
    setIsOpenVoiceFlow(true);
  };

  const lastIndexData = message[message.length - 1];

  const onChoiceType = async (action: any) => {
    const newTextObject = {
      type: 'text',
      userMessage: true,
      payload: {
        message: `${action?.payload?.label}`,
      },
    };
    message.pop();
    message.push(newTextObject);
    setChatLoading(true);
    const request = { action };
    const response1 = await axios({
      method: 'POST',
      baseURL: 'https://general-runtime.voiceflow.com',
      url: `/state/user/${token}/interact`,
      headers: {
        Authorization: REACT_APP_VOICEFLOW_API_KEY,
      },
      data: request,
    });
    setMesssage([...message, ...JSON.parse(JSON.stringify(response1?.data))]);
    setChatLoading(false);
    scrollling();
  };

  const transformString = (input: string) => {
    return input.replace(/[\n\r]+/g, ' ');
  };

  const inputMessageData = async () => {
    const newMessage = transformString(inputMessage.trim());

    const newTextObject = {
      type: 'text',
      userMessage: true,
      payload: {
        message: `${inputMessage?.trim()}`,
      },
    };
    message.push(newTextObject);
    setChatLoading(true);

    const bodyData = {
      action: {
        type: 'text',
        payload: newMessage?.trim(),
      },
    };
    scrollling();
    setTimeout(() => {
      setInputMesssage('');
    }, 0);
    const response1 = await axios({
      method: 'POST',
      baseURL: 'https://general-runtime.voiceflow.com',
      url: `/state/user/${token}/interact`,
      headers: {
        Authorization: REACT_APP_VOICEFLOW_API_KEY,
      },
      data: bodyData,
    });

    setMesssage([...message, ...JSON.parse(JSON.stringify(response1?.data))]);
    setChatLoading(false);
    scrollling();
  };

  return (
    <>
      <Flex justifyContent={'space-between'}>
        <Text fontSize={'md'}>{ADD_INFORMATION}</Text>
        <Flex gap={3}>
          {flags?.antonGpt && (
            <Flex
              borderRadius='25px'
              background='linear-gradient(180deg, #6F6DC0 52%, rgba(59, 184, 188, 0.36) 100%)'
              align={'center'}
              justify={'center'}
              padding={'0.5rem 1rem'}
              cursor={'pointer'}
              onClick={startInteractWithVoiceFlow}
              height={'45px'}
            >
              <Flex align={'center'} gap={3}>
                <VoiceFlowGenerateIcon />
                <Text fontSize={'md'} fontWeight={'400'} fontFamily='Roboto regular'>
                  Generate
                </Text>
              </Flex>
            </Flex>
          )}
          <Menu closeOnSelect={true} variant='primary'>
            <MenuButton
              backgroundColor={'#5A58AB'}
              padding={'.5rem 1rem'}
              as={Button}
              border={'none'}
              rightIcon={
                <ArrorDropdownIconComponent
                  style={{ margin: '0 .5rem' }}
                  height={12}
                  width={12}
                  color='white'
                />
              }
              leftIcon={
                <AddIconComponent
                  style={{ margin: '0 .5rem' }}
                  color='#62B5BA'
                  height={18}
                  width={18}
                />
              }
              fontSize={'md'}
              fontWeight={'400'}
              width={'fit-content'}
              height={'45px'}
            >
              Create
            </MenuButton>
            <MenuList>
              <MenuItem onClick={handleAddNewBlock}>New Block</MenuItem>
              {/* {flags?.antonGpt && (
                <MenuItem onClick={() => setIsOpen(true)}>+ Add new block</MenuItem>
              )} */}
            </MenuList>
          </Menu>
        </Flex>
      </Flex>

      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        {myBlocks?.length > 0 ? (
          <SortableContext items={myBlocks} strategy={rectSortingStrategy}>
            <Box ref={carouselReference}>
              <Flex mt={'2'} gap={'1rem'} flexWrap={'wrap'}>
                {myBlocks.map((block: Block, index: number) => (
                  <React.Fragment key={index}>
                    <NewBlockCard block={block} key={index} />
                  </React.Fragment>
                ))}
              </Flex>
            </Box>
          </SortableContext>
        ) : (
          <Box textAlign={'center'} p={5}>
            <Text>No Block Found</Text>
          </Box>
        )}
      </DndContext>

      <Modal onClose={onClose} isOpen={isOpen} blockScrollOnMount={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody p={0} m={0}>
            <div
              id='myLandbot'
              style={{ width: '100%', height: '100vh' }}
              ref={modalBodyReference}
            ></div>
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        onClose={onCloseVoiceFlow}
        isOpen={isOpenVoiceFlow}
        blockScrollOnMount={false}
        size='xl'
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent height={'600px'} width={'90vw'} borderTopRadius={'25px'} maxWidth={'100vh'}>
          <ModalCloseButton color={'rgba(255, 255, 255, 0.30)'} />
          <Flex bg={'#3d3381'} borderTopRadius={'20px'} height={'130px'} align={'center'} gap={5}>
            <Flex ms={5}>
              <Image src={assistant} w={25} h={25} style={{ transform: 'scaleX(-1)' }} />
            </Flex>
            <Flex flexDirection={'column'}>
              <Flex fontWeight={400} fontFamily='Roboto regular' color={'#fff'}>
                Blox AI Assistant
              </Flex>
              <Flex fontFamily='Roboto regular' color={'#fff'}>
                Custom AI generated models in seconds
              </Flex>
            </Flex>
          </Flex>
          <ModalBody
            ref={containerReference}
            p={0}
            m={0}
            height='75%'
            overflowY={'auto'}
            className={'scroll'}
            bg={'white'}
          >
            <Flex flexDirection={'column'} gap={3} mt={5} mb={3}>
              <Flex align={'center'} justify={'left'}>
                <Flex ms={3} align={'center'}>
                  <Flex align={'end'} height={'35px'} width={'35px'} minWidth={'35px'} me={3}>
                    <Image
                      src={blockImage}
                      height={'35px'}
                      width={'35px'}
                      bg={'#F2F0FD'}
                      borderRadius={'25px'}
                    />
                  </Flex>
                  {/* <Box
                    fontFamily='Roboto regular'
                    p={'0.5rem'}
                    bg={'#fff'}
                    borderRadius={'10px 10px 10px 0'}
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                    fontSize={'14px'}
                    alignSelf={'center'}
                    color={'black'}
                    border={'1px solid #DDDBEC'}
                    dangerouslySetInnerHTML={{
                      __html: `Hi! I'm Anton, the Blox AI assistant ☀️`,
                    }}
                  ></Box> */}
                  <Typewriter text={`Hi! I'm Anton, the Blox AI assistant ☀️`} delay={50} />
                </Flex>
              </Flex>
            </Flex>
            {loading ? (
              <Flex maxWidth={'70%'} gap={1} align={'center'} mt={3}>
                <Flex align={'end'} height={'35px'} width={'35px'} minWidth={'35px'} me={3} ms={3}>
                  <Image
                    src={blockImage}
                    height={'35px'}
                    width={'35px'}
                    bg={'#F2F0FD'}
                    borderRadius={'25px'}
                  />
                </Flex>
                <BeatLoader />
              </Flex>
            ) : (
              <Flex flexDirection={'column'} gap={3} mb={3}>
                {message &&
                  message.length > 0 &&
                  message.map((item: any, index_: number) => {
                    const innerhtmlData = urlify(item?.payload?.message);
                    return (
                      <Flex
                        key={index_}
                        align={'center'}
                        justify={item?.userMessage ? 'end' : 'left'}
                      >
                        {item?.type === 'text' && (
                          <Flex maxWidth={'70%'} gap={1} align={'end'}>
                            {item?.userMessage ? (
                              <Flex pe={2} align={'center'}>
                                <Box
                                  fontFamily='Roboto regular'
                                  p={'0.5rem'}
                                  borderRadius={'10px 10px 0 10px'}
                                  overflow={'hidden'}
                                  textOverflow={'ellipsis'}
                                  fontSize={'14px'}
                                  alignSelf={'center'}
                                  border={'1px solid #DDDBEC'}
                                  color='#7A73AF'
                                  bg={'#F2F0FD'}
                                  me={2}
                                  dangerouslySetInnerHTML={{ __html: innerhtmlData }}
                                ></Box>
                                <Flex
                                  justify={'end'}
                                  align={'center'}
                                  height={'35px'}
                                  width={'35px'}
                                  minWidth={'35px'}
                                >
                                  {userData?.profile_image_url && (
                                    <Image
                                      src={userData?.profile_image_url}
                                      height={'35px'}
                                      width={'35px'}
                                      bg={'#F2F0FD'}
                                      borderRadius={'25px'}
                                    />
                                  )}

                                  {!userData?.profile_image_url && <UserChatIcon />}
                                </Flex>
                              </Flex>
                            ) : (
                              <Flex ms={3} align={'center'}>
                                <Flex
                                  align={'end'}
                                  height={'35px'}
                                  width={'35px'}
                                  minWidth={'35px'}
                                  me={3}
                                >
                                  <Image
                                    src={blockImage}
                                    height={'35px'}
                                    width={'35px'}
                                    bg={'#F2F0FD'}
                                    borderRadius={'25px'}
                                  />
                                </Flex>

                                <Box
                                  dangerouslySetInnerHTML={{ __html: innerhtmlData }}
                                  fontFamily='Roboto regular'
                                  p={'0.5rem'}
                                  bg={'#fff'}
                                  borderRadius={'10px 10px 10px 0'}
                                  overflow={'hidden'}
                                  textOverflow={'ellipsis'}
                                  fontSize={'14px'}
                                  alignSelf={'center'}
                                  color={'black'}
                                  border={'1px solid #DDDBEC'}
                                ></Box>
                              </Flex>
                            )}
                          </Flex>
                        )}
                        {item?.type === 'choice' && item?.payload?.buttons && (
                          <Flex flexDirection={'column'} ps={14}>
                            {item?.payload?.buttons?.map((button: any, index: number) => (
                              <Button
                                color={'black'}
                                mb={2}
                                fontFamily='Roboto regular'
                                key={index}
                                onClick={() => onChoiceType(button?.request)}
                                bg={'#fff'}
                                padding={'.5rem 1rem'}
                                fontSize={'sm'}
                                fontWeight={'400'}
                                border={'1px solid #DDDBEC'}
                              >
                                {button?.name}
                              </Button>
                            ))}
                          </Flex>
                        )}
                        {item?.type === 'end' && (
                          <Flex width={'100%'} justify={'center'} align={'center'}>
                            <Text
                              mt={5}
                              textAlign={'center'}
                              fontSize={'xs'}
                              color={'grey'}
                              fontFamily='Roboto regular'
                            >
                              You have ended chat
                            </Text>
                          </Flex>
                        )}
                      </Flex>
                    );
                  })}
                {chatLoading && (
                  <Flex maxWidth={'70%'} gap={1} align={'end'} mt={5}>
                    <Flex align={'end'} height={'35px'} width={'35px'}>
                      <Image src={blockImage} height={'35px'} width={'35px'} />
                    </Flex>
                    <BeatLoader />
                  </Flex>
                )}
              </Flex>
            )}
          </ModalBody>

          <ModalFooter bg={'white'} mb={3} p={0} borderRadius={'inherit'} mx={3}>
            {lastIndexData && lastIndexData?.type === 'end' ? (
              <Button
                bg={'#F2F0FD'}
                outline={'grey !important'}
                boxShadow={'none !important'}
                width={'100%'}
                fontWeight={400}
                color={'#7A73AF'}
                onClick={startInteractWithVoiceFlow}
                fontSize={'sm'}
                fontFamily='Roboto regular'
                padding={'2rem'}
                mb='2'
              >
                Start New chat
              </Button>
            ) : lastIndexData && lastIndexData?.type === 'choice' ? (
              <></>
            ) : (
              <>
                <Textarea
                  ms={2}
                  whiteSpace={'pre-wrap'}
                  overflow={'auto'}
                  className='scroll'
                  value={inputMessage}
                  alignContent='center'
                  fontSize='sm'
                  width={'100%'}
                  border='none'
                  boxShadow={'none !important'}
                  py={'1rem'}
                  px={'2rem'}
                  height='3rem'
                  maxH='3rem'
                  fontFamily='Roboto regular'
                  placeholder={'Type a message...'}
                  _placeholder={{ color: '#4b3b86' }}
                  autoFocus
                  color='#7A73AF'
                  bg={'#F2F0FD'}
                  resize='none'
                  onKeyDown={(event: any) => {
                    if (event?.keyCode === 13 && event.target.value.trim().length > 0)
                      inputMessageData();
                  }}
                  onChange={(event: any) => {
                    setInputMesssage(event.target.value);
                  }}
                />
                <Flex
                  width={'35px'}
                  ms={'1rem'}
                  me={2}
                  cursor={'pointer'}
                  onClick={inputMessageData}
                >
                  <BiSend color='#D7D5E6' cursor={'pointer'} fontSize={100} />
                </Flex>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default withLDConsumer()(BlockList);
