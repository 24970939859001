import { Flex, Text } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Container from 'components/Container';
import Logo from 'components/Icons/Logo';
import Spinner from 'components/Spinner';
import { useAuthContext } from 'context/authContext';
import { CreateGuestUser } from 'models/requestBody/createGuestUser.interface';
import { CreateModelCopy } from 'models/requestBody/createModelCopy.interface';
import apiService from 'services';
import { SetModelLimit } from 'redux/UserSlice';
import { showAuthenticationModal } from 'redux/SharedSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

const SplashScreen = () => {
  const navigate = useNavigate();
  const { modelId } = useParams();
  const queryClient = useQueryClient();
  const { onAuthSuccess } = useAuthContext();
  const dispatch: AppDispatch = useDispatch();
  useLayoutEffect(() => {
    document.title = 'Blox';
  }, []);

  const token = localStorage.getItem('BLOX_USER_TOKEN');

  const { data: model } = useQuery(
    ['model', modelId],
    () => apiService.get_model(Number(modelId)),
    { enabled: !!token },
  );

  const { data: userModels } = useQuery(['models'], () => apiService.get_user_models(), {
    enabled: !!token,
  });
  const { mutate: createModelCopy } = useMutation(
    ({ model_id, request_body }: CreateModelCopy) =>
      apiService.create_model_copy({ model_id, request_body }),
    {
      onSuccess: (response) => {
        const { id } = response.data;
        navigate(`/model/${id}/home`);
      },
      onError: (response: any) => {
        if (response?.response?.status === 403) {
          navigate(`/home`);
          dispatch(showAuthenticationModal());
        }
        if (
          response.response.data.message ===
          'Models limit reached for this organization, please upgrade'
        ) {
          dispatch(SetModelLimit(true));
          navigate(`/home`);
        }
      },
    },
  );

  const { mutate: createGuestUser } = useMutation(
    ({ request_body }: CreateGuestUser) => apiService.create_guest_user({ request_body }),
    {
      onSuccess: (response) => {
        if (response.data.status.toLowerCase() === 'authenticated') {
          onAuthSuccess(response.data.token, response.data.expiry);
          queryClient.invalidateQueries(['user']);
        }
      },
    },
  );

  useEffect(() => {
    if (token && model && userModels) {
      const filteredUserModels = userModels?.data.models.filter((userModel: any) =>
        userModel.name.includes(model?.data.name),
      );

      const newModel =
        filteredUserModels.length === 0
          ? { name: model?.data.name, reporting_currency: model?.data.reporting_currency }
          : {
              name: `${model?.data.name} (${filteredUserModels.length + 1})`,
              reporting_currency: model?.data.reporting_currency,
            };

      createModelCopy({
        model_id: Number(modelId),
        request_body: newModel,
      });
    }

    if (!token) {
      // createGuestUser({
      //   request_body: { ip_address: '', browser_version: navigator.userAgent },
      // });
      window.location.href = 'https://www.blox.so/demo';
    }
  }, [
    createGuestUser,
    createModelCopy,
    model,
    modelId,
    token,
    userModels,
    userModels?.data.models,
  ]);

  return (
    <Container>
      <Flex h='full' direction='column' justify='center' align='center'>
        <Logo color='white.900' width='34vw' height='auto' />
        <Spinner styleProperties={{ my: '4vh' }} />
        <Text fontFamily='regular' fontSize='calc(.5rem + 1vw)' letterSpacing='-0.015em'>
          Creating your model...
        </Text>
      </Flex>
    </Container>
  );
};

export default SplashScreen;
