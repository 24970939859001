/* eslint-disable @typescript-eslint/lines-between-class-members */
/* eslint-disable class-methods-use-this */
import * as React from 'react';
import './index.scss';
import {
  CellTemplate,
  Cell,
  Compatible,
  Uncertain,
  getCellProperty,
  keyCodes,
} from '@silevis/reactgrid';

import HeaderComponent from './HeaderComponent';

export interface HeaderCell extends Cell {
  type: 'flag';
  text: string;
  rowId: string;
}

export class HeaderRowTemplate implements CellTemplate<HeaderCell> {
  getCompatibleCell(uncertainCell: Uncertain<HeaderCell>): Compatible<HeaderCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const value = Number.parseFloat(text);
    return { ...uncertainCell, text, value } as Compatible<HeaderCell>;
  }

  handleKeyDown(
    cell: Compatible<HeaderCell>,
    keyCode: number,
  ): { cell: Compatible<HeaderCell>; enableEditMode: boolean } {
    if (keyCode === keyCodes.ENTER) {
      return { cell, enableEditMode: true };
    }
    return { cell, enableEditMode: false };
  }

  getClassName(cell: any) {
    const isValid = cell.validator ? cell.validator(cell.text) : true;
    const className = cell.className ?? '';
    return `${isValid ? 'valid' : 'invalid'} ${
      cell.placeholder && cell.text === '' ? 'placeholder' : ''
    } ${className}`;
  }

  render(cell: Compatible<HeaderCell>) {
    return <HeaderComponent cell={cell} />;
  }
}
// Todo : add a functionality to edit a row as well
