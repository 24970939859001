const ZoomInMapIcon = () => {
  return (
    <svg width='25px' height='25px' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 0h48v48H0z' fill='none' />
      <g id='Shopicon'>
        <g>
          <polygon
            points='39.999,37.171 29.414,26.586 26.586,29.414 37.171,39.999 29.999,39.999 29.999,43.999 43.999,43.999 
			43.999,29.999 39.999,29.999 		'
          />
          <polygon points='30,8 37.172,8 26.586,18.586 29.414,21.414 40,10.828 40,18 44,18 44,4 30,4 		' />
          <polygon
            points='7.999,10.827 18.586,21.414 21.414,18.586 10.827,7.999 17.999,7.999 17.999,3.999 3.999,3.999 3.999,17.999 
			7.999,17.999 		'
          />
          <polygon points='18,40 10.907,40 21.414,29.493 18.585,26.665 8,37.251 8,30.002 4,30.002 4,44 18,44 		' />
        </g>
      </g>
    </svg>
  );
};

export default ZoomInMapIcon;
