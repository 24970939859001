import { CloseIcon } from '@chakra-ui/icons';
import { Flex, Radio, RadioGroup, Stack, Text, VStack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import {
  setActionCreateForm,
  clearStepperFormData,
  setClearSelectedAreasTable,
  getFormValues,
} from 'redux/DataImportSlice';

import { checkedRadioStyles, textStyles } from '../Helper';

import DimensionType from './ActionTwoSteps/DimensionType';
import DataType from './ActionTwoSteps/DataType';

interface Properties {
  setHelpModalSeleceted: any;
  flags?: any;
}

const ActionStepTwo = ({ setHelpModalSeleceted, flags }: Properties) => {
  const dispatch: any = useDispatch();
  const getFormValue = useSelector(getFormValues);
  const [dataType, setDataType] = useState<any>(
    null || getFormValue.dataType === '' ? null : getFormValue.dataType,
  );

  return (
    <VStack w={'100%'} pt={3}>
      <Flex justifyContent={'space-between'} w={'full'} align={'center'}>
        <Text
          color={'#6562CF'}
          fontSize='lg'
          fontFamily={'regular_roboto'}
          fontWeight={'400'}
          textAlign={'start'}
        >
          {dataType === null && 'Select data type'}
          {dataType === '2' && 'Select block'}
          {dataType === '1' && 'Select dimensions'}
        </Text>
        <CloseIcon
          color={'black'}
          width={3}
          height={3}
          cursor={'pointer'}
          onClick={async () => {
            await dispatch(setActionCreateForm(false));
            await dispatch(clearStepperFormData());
            await dispatch(setClearSelectedAreasTable());
          }}
        />
      </Flex>

      <VStack display='flex' justify={'center'} w={'100%'}>
        <VStack display='flex' justify={'center'} w={'100%'}>
          <RadioGroup
            width={'100%'}
            pt={3}
            onChange={(value) => {
              setDataType(value);
            }}
            value={dataType}
          >
            <Stack spacing={5} direction='row'>
              <Radio _checked={checkedRadioStyles} value='1'>
                <Text color='black' {...textStyles}>
                  Dimension
                </Text>
              </Radio>
              {flags?.importAsDataType && (
                <Radio _checked={checkedRadioStyles} value='2'>
                  <Text color='black' {...textStyles}>
                    Data
                  </Text>
                </Radio>
              )}
            </Stack>
          </RadioGroup>
          {dataType === '1' ? (
            <DimensionType setHelpModalSeleceted={setHelpModalSeleceted} dataType={dataType} />
          ) : (
            <DataType setHelpModalSeleceted={setHelpModalSeleceted} dataType={dataType} />
          )}
        </VStack>
      </VStack>
    </VStack>
  );
};

export default withLDConsumer()(ActionStepTwo);
