import { Td, Tr, Flex, Text, Box } from '@chakra-ui/react';
import { useState } from 'react';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { useSelector } from 'react-redux';

import ChevronDown from 'components/Icons/ChevronDown';
import ChevronRight from 'components/Icons/ChevronRight';
import { NewBlockOutput, NewTotalValues } from 'models/response/blockOutput.interface';
import formatValue from 'utils/FormatValues';
import { getVarianceFilterTable } from 'redux/ScenarioSlice';

interface Properties {
  row: NewBlockOutput;
  expandkey: string;
}

const CompareIndicatorRow = ({ row, expandkey }: Properties) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const varianceFilter = useSelector(getVarianceFilterTable);

  const onToggleCollapse = () => {
    setIsCollapsed((previousState) => !previousState);
  };

  const removeSign = (value: number) => {
    return value < 0 ? -1 * value : value;
  };

  const getBackgroundStyle = (
    output_format: string,
    key: string,
    isExpandedColumn: boolean,
    isSubRow?: boolean,
  ) => {
    if (isExpandedColumn) {
      return {
        background: '#E7EBEF',
      };
    }
    if (key === expandkey && isSubRow) {
      return {
        background: '#D1D9E0',
      };
    }
    if (key === expandkey && !isSubRow) {
      return {
        background: '#D1D9E0',
      };
    }
    return {
      background: output_format === 'subtotal' ? '#F4F7FF' : '#FFF',
    };
  };

  return (
    <Tr
      onClick={onToggleCollapse}
      fontWeight={row?.output_format === 'subtotal' ? 'semibold' : 'regular'}
    >
      <Td
        style={{
          ...getBackgroundStyle(row.output_format, '-', false),
          cursor: row.dim_item_data ? 'pointer' : 'default',
          paddingLeft: '1rem',
          verticalAlign: 'middle',
        }}
        fontSize={'2xs'}
      >
        {row.dim_item_data ? isCollapsed ? <ChevronRight /> : <ChevronDown /> : <></>} {row.name}
      </Td>
      {Object.values(row.total_values as unknown as NewTotalValues[]).map((item, index) => {
        if (item.isShow) {
          if (item.value === null) {
            return (
              <>
                <Td
                  key={item.name + index}
                  style={getBackgroundStyle(
                    row.output_format,
                    item.name,
                    Boolean(item.isExpandedColumn),
                  )}
                  fontSize={'2xs'}
                >
                  {row.format_properties.null_value}
                </Td>
                <Td></Td>
              </>
            );
          }
          return (
            <>
              <Td
                key={item.name + index}
                style={getBackgroundStyle(
                  row.output_format,
                  item.name,
                  Boolean(item?.isExpandedColumn),
                )}
                verticalAlign='middle !important'
                textAlign={'start'}
                width={'25px'}
              >
                <Flex alignItems={'center'}>
                  <Box width='max-content' textAlign={'start'}>
                    <Text noOfLines={1} fontSize={'2xs'}>
                      {formatValue(item?.value || 0, row?.format_properties)}
                    </Text>
                  </Box>
                </Flex>
              </Td>
              <Td
                verticalAlign='middle !important'
                textAlign={'start'}
                paddingLeft={0}
                paddingRight={'1.5rem'}
                style={getBackgroundStyle(
                  row.output_format,
                  item.name,
                  Boolean(item?.isExpandedColumn),
                )}
              >
                <Flex width='max-content' textAlign={'start'} alignItems={'center'}>
                  {(varianceFilter ? Number(item?.varience) : Number(item?.diff)) !== 0 && (
                    <>
                      {!varianceFilter &&
                        (Number(item?.diff) > 0 ? (
                          <AiOutlineArrowUp fontSize={'6xs'} color={'#62B5BA'} />
                        ) : (
                          <AiOutlineArrowDown fontSize={'6xs'} color={'red'} />
                        ))}
                      {varianceFilter &&
                        (Number(item?.varience) > 0 ? (
                          <AiOutlineArrowUp fontSize={'6xs'} color={'#62B5BA'} />
                        ) : (
                          <AiOutlineArrowDown fontSize={'6xs'} color={'red'} />
                        ))}
                    </>
                  )}
                  {(varianceFilter ? Number(item?.varience) : Number(item?.diff)) !== 0 && (
                    <>
                      {varianceFilter && (
                        <Text
                          noOfLines={1}
                          fontSize={'2xs'}
                          color={Number(item?.varience) > 0 ? '#62B5BA' : 'red'}
                        >
                          {removeSign(Number(item?.varience)).toFixed(1)}%
                        </Text>
                      )}
                      {!varianceFilter && (
                        <Text
                          noOfLines={1}
                          fontSize={'2xs'}
                          color={Number(item?.diff) > 0 ? '#62B5BA' : 'red'}
                        >
                          {removeSign(Number(item?.diff)).toFixed(1)}
                        </Text>
                      )}
                    </>
                  )}
                </Flex>
              </Td>
            </>
          );
        }
        return <></>;
      })}
    </Tr>
  );
};

export default CompareIndicatorRow;
