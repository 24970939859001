interface Properties {
  width?: string;
  height?: string;
  color?: string;
  style?: React.CSSProperties;
}

const ArrowRightIcon = ({
  width = '10',
  height = '10',
  color = 'white.900',
  style,
}: Properties) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      color={color}
      viewBox='0 0 64 32'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M48.04 12H0V20H48.04V32L64 16L48.04 0V12Z' fill={color} />
    </svg>
  );
};

export default ArrowRightIcon;
