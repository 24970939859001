import {
  Flex,
  Text,
  VStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Button,
} from '@chakra-ui/react';
import { ChevronDownIcon, AddIcon, InfoIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { BsArrowRight } from 'react-icons/bs';
import { Formik, Form, FieldArray, ErrorMessage } from 'formik';
import { useState } from 'react';

import {
  getTableHeader,
  handleActionStepNext,
  resetSingleTimePeriodValue,
  setMultiTimePeriodValue,
  setSelectedAreasProperties,
} from 'redux/DataImportSlice';
import { getModelTime } from 'redux/IndicatorPageSlice';
import { textStyles } from 'pages/ModelOverviewPage/Import/Helper';

import ActionStepFooter from '../../ActionStepFooter';

interface Properties {
  setHelpModalSeleceted?: any;
  getFormValue?: any;
}
// const validationSchema = Yup.object().shape({
//   selectedData: Yup.array().of(
//     Yup.object().shape({
//       key: Yup.string().required('Key is required'),
//       value: Yup.string(),
//     }),
//   ),
// });

const Columns = ({ setHelpModalSeleceted, getFormValue }: Properties) => {
  const dispatch: any = useDispatch();
  const headerNames = useSelector(getTableHeader);
  const modelTime = useSelector(getModelTime);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  const handleSubmit = async (form: any, setSubmitting: (data: boolean) => void) => {
    // const submittedData = form.selectedData.reduce((accumulator: any, item: any) => {
    //   accumulator[item.key] = item.value || null;
    //   return accumulator;
    // }, {});
    await dispatch(setMultiTimePeriodValue(form.selectedData));
    await dispatch(resetSingleTimePeriodValue());
    await setSubmitting(false);
    await dispatch(handleActionStepNext());
  };

  return (
    <Flex w={'full'} flexDirection={'column'}>
      <Text color={'black'} {...textStyles} textAlign={'start'} width={'100%'}>
        Now select the columns in your table that relate to your time periods using the drop down
        menu belows
      </Text>
      <Text
        color={'#6562CF'}
        fontSize='lg'
        fontFamily={'regular_roboto'}
        fontWeight={'400'}
        textAlign={'start'}
        py={3}
      >
        Map the values into Blox
      </Text>
      <Formik
        initialValues={{
          selectedData:
            getFormValue?.multiTimePeriodValue && getFormValue?.multiTimePeriodValue?.length
              ? getFormValue?.multiTimePeriodValue
              : [{ key: headerNames[0], value: null }],
        }}
        onSubmit={(form, { setSubmitting }) => handleSubmit(form, setSubmitting)}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue, isSubmitting }: any) => {
          return (
            <Form
              style={{
                width: '100%',
              }}
            >
              <VStack w={'full'}>
                <Flex justifyContent={'space-between'} align={'center'} w='full'>
                  <Text
                    fontSize='1rem'
                    color='black'
                    fontFamily={'regular_roboto'}
                    fontWeight={'600'}
                  >
                    Column in File
                  </Text>
                  <Flex w={'41%'}>
                    <Text
                      fontSize='1rem'
                      color='black'
                      fontFamily={'regular_roboto'}
                      fontWeight={'600'}
                    >
                      Period in Blox
                    </Text>
                  </Flex>
                </Flex>
                <FieldArray
                  name='selectedData'
                  render={() => (
                    <>
                      {values.selectedData.map((selectedItems: any, index: number) => (
                        <Flex
                          justifyContent='space-between'
                          align='center'
                          pt={3}
                          key={index}
                          w={'100%'}
                        >
                          <Flex align='center' w='45%' justify='start'>
                            <Menu closeOnSelect={true} isLazy>
                              <MenuButton
                                as={Button}
                                backgroundColor='white'
                                maxHeight={'40px'}
                                borderRadius='5px'
                                minWidth='140px'
                                textAlign='left'
                                padding='0 .8rem'
                                boxShadow='rgb(139, 137, 177) 0px 0px 0px 0.6px'
                                rightIcon={<ChevronDownIcon fontSize='md' />}
                                fontWeight='300'
                                fontSize='xs'
                                color='black.900'
                                w='100%'
                                border={
                                  errors.selecetedDimension
                                    ? '2px solid #fe6e6e'
                                    : '0.6px solid #8B89B1'
                                }
                              >
                                <Text me={3} noOfLines={1} height='fit-content'>
                                  <Text>{selectedItems?.key}</Text>
                                </Text>
                              </MenuButton>
                              <MenuList
                                height='fit-content'
                                maxHeight='20vh'
                                overflowY='auto'
                                className='scroll'
                              >
                                <MenuOptionGroup defaultValue={selectedItems.key}>
                                  {headerNames
                                    ?.filter(
                                      (header: any) =>
                                        !values?.selectedData?.some(
                                          (selected: any) => selected?.key === header,
                                        ),
                                    )
                                    ?.map((header: any) => (
                                      <MenuItemOption
                                        key={header}
                                        value={header}
                                        onClick={() => {
                                          setFieldValue(`selectedData.${index}.key`, header);
                                          dispatch(
                                            setSelectedAreasProperties({
                                              [`selectedData.${index}.key`]: header,
                                            }),
                                          );
                                        }}
                                      >
                                        {header}
                                      </MenuItemOption>
                                    ))}
                                </MenuOptionGroup>
                              </MenuList>
                            </Menu>
                          </Flex>
                          <Flex align='center' gap={2} justify='end' w='50%'>
                            <BsArrowRight color='black' style={{ width: '20px', height: '20px' }} />
                            <Menu closeOnSelect={true} isLazy>
                              <MenuButton
                                as={Button}
                                backgroundColor='white'
                                maxHeight={'40px'}
                                borderRadius='5px'
                                minWidth='140px'
                                textAlign='left'
                                padding='0 .8rem'
                                boxShadow='rgb(139, 137, 177) 0px 0px 0px 0.6px'
                                rightIcon={<ChevronDownIcon fontSize='md' />}
                                fontWeight='300'
                                fontSize='xs'
                                color='black.900'
                                w='100%'
                                border='0.6px solid #8B89B1'
                              >
                                <Text me={3} noOfLines={1} height='fit-content'>
                                  <Text>{selectedItems?.value}</Text>
                                </Text>
                              </MenuButton>
                              <MenuList
                                height='fit-content'
                                maxHeight='20vh'
                                overflowY='auto'
                                className='scroll'
                              >
                                <MenuOptionGroup defaultValue={selectedItems.value || ''}>
                                  {modelTime &&
                                    modelTime?.time_range
                                      ?.filter(
                                        (time: any) =>
                                          !values.selectedData.some(
                                            (selected: any) => selected.value === time,
                                          ),
                                      )
                                      ?.map((time: any) => (
                                        <MenuItemOption
                                          key={time}
                                          value={time}
                                          onClick={() =>
                                            setFieldValue(`selectedData.${index}.value`, time)
                                          }
                                        >
                                          {time}
                                        </MenuItemOption>
                                      ))}
                                </MenuOptionGroup>
                              </MenuList>
                            </Menu>
                          </Flex>
                        </Flex>
                      ))}
                    </>
                  )}
                />
                <ErrorMessage name='selectedData' component='div' className='error' />
                <Flex pt={'.5rem'} w={'full'} align={'flex-start'}>
                  <AddIcon
                    color={'black'}
                    cursor={'pointer'}
                    // onClick={() => {
                    //   const currentSelectedData = values.selectedData;
                    //   setFieldValue('selectedData', [
                    //     ...currentSelectedData,
                    //     { key: '', value: null },
                    //   ]);
                    // }}
                    onClick={() => {
                      const currentSelectedData = values.selectedData;
                      const lastSelectedItem = currentSelectedData[currentSelectedData.length - 1];
                      if (lastSelectedItem.value !== null) {
                        setFieldValue('selectedData', [
                          ...currentSelectedData,
                          { key: '', value: null },
                        ]);
                        setShowErrorMessage(false);
                      } else {
                        setShowErrorMessage(true);
                      }
                    }}
                  />
                </Flex>
                {showErrorMessage && (
                  <Text color={'red'} fontSize={'2xs'} textAlign={'start'} w={'100%'}>
                    Value must be filled before adding a new column.
                  </Text>
                )}
              </VStack>

              <Flex alignItems={'center'} justifyContent={'space-between'} pb={8} pt='5' w={'100%'}>
                <Flex>
                  <Text
                    {...textStyles}
                    color={'black'}
                    textDecoration='underline'
                    onClick={() => {
                      setHelpModalSeleceted.on();
                    }}
                    display={'none'}
                  >
                    Help <InfoIcon color={'#8B89B1'} ms={3} />
                  </Text>
                </Flex>
                <ActionStepFooter
                  nextButtonName='Continue'
                  prevButtonName='Previous'
                  isSubmitting={isSubmitting}
                />
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
};

export default Columns;
