import { useMemo, useRef } from 'react';

export type UseClickAndDoubleClickHandler = (event: any, id: any) => void;

const useClickAndDoubleClickHandler = (
  onSingleClick: UseClickAndDoubleClickHandler,
  onDoubleClick: UseClickAndDoubleClickHandler,
  latency = 250,
) => {
  const clickCount = useRef(0);

  return useMemo(() => {
    return (event: any, id: any) => {
      clickCount.current += 1;
      setTimeout(() => {
        if (clickCount.current === 1) {
          // @ts-ignore
          onSingleClick(id);
        } else if (clickCount.current === 2) {
          onDoubleClick(event, id);
        }
        clickCount.current = 0;
      }, latency);
    };
  }, [onSingleClick, onDoubleClick, latency]);
};

export default useClickAndDoubleClickHandler;
