import { Flex, Image, Text, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import csvgreen from 'assets/csvgreen_icon.png';
import keyboardReturn from 'assets/enter_button_icon.png';
import { FetchDimensionList } from 'redux/BuilderModeSlice';
import {
  getActionCreateStatus,
  getActionList,
  getDataImportFiles,
  getImportDataFiles,
  getSelectedDataFileDetails,
  getStepsLength,
  resetDataImport,
} from 'redux/DataImportSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

import ActionData from './ActionData';
import ActionCreateForm from './ActionSteps/ActionCreateForm';
import AddAction from './AddAction';
import AddSourceModal from './AddSourceModal';
import DataImportDetails from './DataImportDetails';
import Header from './Header';
import Source from './Source';

const DataImport = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { modelId } = useParams();
  const [isActionUI] = useMediaQuery('(max-width: 1610px)');

  const [isLaptop] = useMediaQuery('(max-width: 1500px)');
  const [isSmallLaptop] = useMediaQuery('(max-width: 1270px)');

  const [isMobile] = useMediaQuery('(max-width: 1156px)');
  const ImportDataFiles = useSelector(getImportDataFiles);

  const selectedFileDetails = useSelector(getSelectedDataFileDetails);
  const ActionList = useSelector(getActionList);
  const [actionCreate, setactionCreate] = useState(false);
  const actionCreateStatus = useSelector(getActionCreateStatus);
  const stepLength = useSelector(getStepsLength);

  useEffect(() => {
    setactionCreate(actionCreateStatus);
  }, [actionCreate !== actionCreateStatus]);

  // Calling imported files and dimension list for that particular model
  useEffect(() => {
    if (modelId) {
      dispatch(getDataImportFiles(modelId));
      dispatch(FetchDimensionList(modelId));
    }
  }, [modelId]);

  return (
    <Flex width='100%' align='flex-start' justify='center' height={'100%'}>
      <Flex w={'100%'} flexDirection={'column'} justifyContent='flex-start' align='start'>
        {actionCreate === false && <Header onOpen={onOpen} />}
        <Flex w={'full'} align={'start'}>
          {actionCreate === true ? (
            <Flex
              w={isMobile ? '50%' : isSmallLaptop ? '40%' : isLaptop ? '30%' : '25%'}
              flexDirection={'column'}
              me={'10px'}
            >
              <Flex align={'center'} gap={3} pb={2}>
                <Image src={csvgreen} height={9} width={9} />
                <Text fontSize={'md'}>{selectedFileDetails.name}</Text>
              </Flex>
              <ActionCreateForm setactionCreate={setactionCreate} />
            </Flex>
          ) : (
            <>
              <Flex w={isMobile ? '30%' : '20%'} flexDirection={'column'} me={'10px'}>
                <Flex align={'center'} gap={3} height={'22px'}>
                  {selectedFileDetails && selectedFileDetails.deatsilsPage === true ? (
                    <>
                      <Image
                        src={keyboardReturn}
                        height={8}
                        width={8}
                        mt={'3px'}
                        cursor={'pointer'}
                        onClick={() => {
                          dispatch(resetDataImport());
                        }}
                      />
                      <Text fontSize={'sm'} textAlign={'start'}>
                        Source
                      </Text>
                    </>
                  ) : (
                    <Text fontSize={'sm'} textAlign={'start'}>
                      Sources
                    </Text>
                  )}
                </Flex>
                <Source onOpen={onOpen} selectedFileDetails={selectedFileDetails} />
              </Flex>
              {selectedFileDetails && selectedFileDetails.deatsilsPage === true && (
                <Flex w={isMobile ? '30%' : '20%'} flexDirection={'column'} me={'10px'}>
                  <Text fontSize={'sm'} textAlign={'start'} height={'22px'}>
                    {`Action (${ActionList ? ActionList.length : 0})`}
                  </Text>
                  <ActionData
                    selectedFileDetails={selectedFileDetails}
                    setactionCreate={setactionCreate}
                  />
                </Flex>
              )}
              {ImportDataFiles &&
                ImportDataFiles.length > 0 &&
                selectedFileDetails &&
                selectedFileDetails.deatsilsPage === false && (
                  <Flex w={isMobile ? '30%' : '20%'} flexDirection={'column'} me={'10px'}>
                    <Text fontSize={'sm'} textAlign={'start'} height={'22px'}>
                      Action
                    </Text>
                    <AddAction />
                  </Flex>
                )}
            </>
          )}
          {selectedFileDetails && selectedFileDetails.deatsilsPage === true && (
            <Flex
              w={
                actionCreate
                  ? isMobile
                    ? '50%'
                    : isSmallLaptop
                    ? '60%'
                    : isLaptop
                    ? '70%'
                    : '75%'
                  : isMobile
                  ? '40%'
                  : '58%'
              }
              flexDirection={'column'}
              me={'10px'}
            >
              <Text
                fontSize={'sm'}
                textAlign={'start'}
                pt={actionCreate ? (isActionUI && stepLength > 3 ? '3.7rem' : '3.6rem') : 0}
                height={actionCreate ? (isActionUI && stepLength > 3 ? '110px' : '87px') : '22px'}
              >
                Preview
              </Text>
              <DataImportDetails />
            </Flex>
          )}
        </Flex>
      </Flex>
      <AddSourceModal
        onClose={() => {
          onClose();
        }}
        isOpen={isOpen}
      />
    </Flex>
  );
};

export default DataImport;
