/* eslint-disable class-methods-use-this */

import React from 'react';
import {
  Cell,
  Compatible,
  Uncertain,
  getCellProperty,
  keyCodes,
  isAlphaNumericKey,
  CellTemplate,
  UncertainCompatible,
  getCharFromKeyCode,
} from '@silevis/reactgrid';

import PropertyFormula from './PropertyFormula';

export interface FormulaCell extends Cell {
  type: 'text';
  text: string;
  placeholder?: string;
  validator?: (text: string) => boolean;
  renderer?: (text: string) => React.ReactNode;
  errorMessage?: string;
  property?: string;
  rows?: number;
  colType?: string;
  format?: string;
  isDraggable?: boolean;
}

export class FormulaCellTemplate implements CellTemplate<FormulaCell> {
  getCompatibleCell(uncertainCell: Uncertain<FormulaCell>): Compatible<FormulaCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    let placeholder: string | undefined;
    try {
      placeholder = getCellProperty(uncertainCell, 'placeholder', 'string');
    } catch {
      placeholder = '';
    }
    const value = Number.parseFloat(text);
    return { ...uncertainCell, text, value, placeholder };
  }

  update(
    cell: Compatible<FormulaCell>,
    cellToMerge: UncertainCompatible<FormulaCell>,
  ): Compatible<FormulaCell> {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text,
      placeholder: cellToMerge.placeholder,
    });
  }

  handleKeyDown(
    cell: Compatible<FormulaCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
  ): { cell: Compatible<FormulaCell>; enableEditMode: boolean } {
    const char = getCharFromKeyCode(keyCode, shift);
    if (!ctrl && !alt && isAlphaNumericKey(keyCode) && !(shift && keyCode === keyCodes.SPACE))
      return {
        cell: this.getCompatibleCell({ ...cell, text: shift ? char : char.toLowerCase() }),
        enableEditMode: true,
      };
    return { cell, enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER };
  }

  getClassName(cell: Compatible<FormulaCell>): string {
    const isValid = cell.validator ? cell.validator(cell.text) : true;
    const className = cell.className || '';
    return `${className} ${isValid ? 'valid' : 'invalid'} ${
      cell.placeholder && cell.text === '' ? 'placeholder' : ''
    }`;
  }

  render(
    cell: Compatible<FormulaCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<FormulaCell>, commit: boolean) => void,
  ): React.ReactNode {
    if (!isInEditMode) {
      return (
        <div
          style={{
            width: '100%',
            fontSize: '15px',
          }}
        >
          <p>{`${cell?.text}` || cell.placeholder || ''}</p>
        </div>
      );
    }

    return (
      <PropertyFormula
        cell={cell}
        onCellChanged={onCellChanged}
        getCompatibleCell={this.getCompatibleCell}
      />
    );
  }
}
