import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const UserDetailsSchema = yup.object().shape({
  firstname: yup.string().trim().required(MESSAGES.REQUIRE_MESSAGE),
  lastname: yup.string().trim().required(MESSAGES.REQUIRE_MESSAGE),
  job_role: yup.string(),
  profile_image_url: yup.string(),
  username: yup.string().email(MESSAGES.EMAIL_MESSAGE).trim().required(MESSAGES.REQUIRE_MESSAGE),
  password: yup.string().min(6).max(20),
  confirmPassword: yup.string().oneOf([yup.ref('password'), undefined], 'Passwords must match'),
});

export const AddTeamMemberSchema = yup.object().shape({
  email: yup.string().email(MESSAGES.EMAIL_MESSAGE).trim().required(MESSAGES.REQUIRE_MESSAGE),
  role: yup.string().trim().required(MESSAGES.REQUIRE_MESSAGE),
});
