import { Flex, Text } from '@chakra-ui/react';

import Error from 'components/Icons/Error';
import Success from 'components/Icons/Success';

interface Properties {
  title: string;
  status: 'success' | 'error';
}

const CustomToast = ({ title, status }: Properties) => {
  const getBackground = (alertStatus: string) => {
    if (alertStatus === 'error') return 'red.500';

    return 'green.900';
  };

  const getAlertIcon = (alertStatus: string) => {
    if (alertStatus === 'error') return <Error />;

    return <Success />;
  };

  return (
    <Flex
      w='75'
      align='center'
      py='3'
      pl='4'
      pr='8'
      borderRadius='md'
      color='white.900'
      bgColor={getBackground(status)}
      boxShadow='lg'
      overflowWrap='break-word'
      overflow='hidden'
    >
      {getAlertIcon(status)}

      <Text ml='2' fontFamily='bold_roboto' fontSize='sm'>
        {title}
      </Text>
    </Flex>
  );
};

export default CustomToast;
