import { InfoIcon } from '@chakra-ui/icons';
import { Flex, FormControl, FormLabel, Text, VStack } from '@chakra-ui/react';
import { ErrorMessage, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import * as yup from 'yup';

import {
  addDataStepOneFormSignleDataPeriod,
  handleActionStepNext,
  resetDataPeriodValuesForSingleType,
} from 'redux/DataImportSlice';
import { textStyles } from 'pages/ModelOverviewPage/Import/Helper';

import ActionStepFooter from '../../ActionStepFooter';
import DataDatePicker from '../DataDatePicker';

interface Properties {
  setHelpModalSeleceted: any;
  getFormValue: any;
}
const validationSchema = yup.object().shape({
  selectedStartDate: yup.string().trim().required('This is field required'),
});

const Single = ({ setHelpModalSeleceted, getFormValue }: Properties) => {
  const dispatch: any = useDispatch();
  const [startIn, setStartIn] = useState<string>(getFormValue?.startDate ?? '');
  const handleClearSelection = () => {
    setStartIn('');
  };
  const handleSubmit = async (form: any, setSubmitting: (data: boolean) => void) => {
    await dispatch(addDataStepOneFormSignleDataPeriod(form));
    if (getFormValue?.dataPeriod === '1') {
      await dispatch(resetDataPeriodValuesForSingleType());
    }
    setSubmitting(false);
    dispatch(handleActionStepNext());
  };
  return (
    <Formik
      initialValues={{
        selectedStartDate: getFormValue?.startDate ? new Date(getFormValue?.startDate) : '',
      }}
      validationSchema={validationSchema}
      onSubmit={(form, { setSubmitting }) => handleSubmit(form, setSubmitting)}
      enableReinitialize={true}
    >
      {({ isSubmitting, setFieldValue }: any) => {
        return (
          <Form style={{ width: '100%' }}>
            <VStack display='flex' justify={'center'} w={'100%'}>
              <VStack display='flex' justify={'center'} w={'100%'}>
                <VStack w={'100%'} justify={'center'}>
                  <FormControl mt={5} pt={2}>
                    <FormLabel
                      fontSize='0.9rem'
                      color='black'
                      htmlFor='selecetDimension'
                      fontFamily={'Roboto Regular'}
                    >
                      Select the dates for your data
                    </FormLabel>
                    <Flex bgColor={'white'}>
                      <DataDatePicker
                        clearSection={false}
                        clearDateInput={handleClearSelection}
                        selected={startIn ? new Date(startIn) : null}
                        onChange={(date: Date) => {
                          setStartIn(date.toDateString());
                          setFieldValue('selectedStartDate', date.toDateString());
                        }}
                        timescale={'M'}
                      />
                    </Flex>
                    <ErrorMessage name='selectedStartDate' component='div' className='error' />
                  </FormControl>
                </VStack>
              </VStack>
              <Flex alignItems={'center'} justifyContent={'space-between'} pb={8} pt='5' w={'100%'}>
                <Flex>
                  <Text
                    {...textStyles}
                    color={'black'}
                    textDecoration='underline'
                    onClick={() => {
                      setHelpModalSeleceted.on();
                    }}
                    display={'none'}
                  >
                    Help <InfoIcon color={'#8B89B1'} ms={3} />
                  </Text>
                </Flex>
                <ActionStepFooter
                  nextButtonName='Continue'
                  prevButtonName='Previous'
                  isSubmitting={isSubmitting}
                />
              </Flex>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Single;
