import { ChevronDownIcon, ChevronRightIcon, EditIcon } from '@chakra-ui/icons';
import {
  Box,
  Icon,
  Flex,
  Menu,
  Text,
  Button,
  Portal,
  MenuItem,
  MenuList,
  MenuButton,
  useMediaQuery,
  useDisclosure,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { Block } from 'models/response/model.interface';
import colors from 'styles/theme/colors';
import sizes from 'styles/theme/sizes';

interface Properties {
  model: any;
  isEditMode: boolean;
}

const DesktopModelFooter = ({ model, isEditMode }: Properties) => {
  const navigate = useNavigate();
  const { isOpen, onClose, onToggle } = useDisclosure();

  const [isMiniMac] = useMediaQuery('(max-height: 700px)');
  const [isLaptop] = useMediaQuery('(max-height: 1200px)');

  const onMenuItemClick = (block: Block) => {
    if (!isEditMode) {
      navigate(`/model/${model.id}/block/${block.id}/plan`);
    }
  };

  const onStackedCardClick = (event: any) => {
    event.stopPropagation();
    if (!isEditMode) {
      if (model.blocks.length > 1) {
        onToggle();
        return;
      }
      navigate(`/model/${model.id}/home`);
    }
  };

  return (
    <Flex
      direction='row'
      justify='space-between'
      align='flex-end'
      mt='auto'
      onClick={onStackedCardClick}
      w='full'
      p={sizes[1]}
    >
      <Menu isOpen={isOpen} onClose={onClose} strategy='fixed'>
        <MenuButton
          as={Button}
          rightIcon={model.blocks.length > 1 ? <ChevronDownIcon /> : <></>}
          color={colors.black[900]}
          p={0}
          m={0}
          w='fit-content'
          h='fit-content'
          minW='auto'
          textTransform='none'
          fontWeight='normal'
        >
          {`${model.blocks.length} ${model.blocks.length > 1 ? 'Blocks' : 'Block'}`}
        </MenuButton>
        <Flex direction='row' columnGap={sizes['0.5']} overflow='hidden'>
          {model.blocks.map((_: any, index: number) => (
            <Icon viewBox='0 0 200 200' color='#4bb6b9' key={index}>
              <path
                fill='currentColor'
                d='M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0'
              />
            </Icon>
          ))}
        </Flex>
        {isOpen && (
          <Portal>
            <MenuList
              className='scroll'
              overflowY='auto'
              color={colors.black[900]}
              minW='270px'
              left={`-${sizes[3]}`}
              top={sizes[4]}
              borderRadius='sm'
              maxHeight={isMiniMac ? '160px' : isLaptop ? '200px' : `calc(100vh - 55vh)`}
              scrollBehavior='smooth'
            >
              {model.blocks?.map((block: any) => (
                <MenuItem
                  display='flex'
                  icon={<EditIcon verticalAlign='text-top' />}
                  alignItems='center'
                  key={block.id}
                  justifyContent='space-between'
                  onClick={() => onMenuItemClick(block)}
                >
                  <Box width='100%' display='flex'>
                    <Text as='span' fontSize='xs'>
                      {block.name}
                    </Text>
                    <Text as='span' ml='auto'>
                      <ChevronRightIcon textColor='#4bb6b9' fontSize='lg' />
                    </Text>
                  </Box>
                </MenuItem>
              ))}
            </MenuList>
          </Portal>
        )}
      </Menu>
    </Flex>
  );
};

export default DesktopModelFooter;
