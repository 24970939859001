/* eslint-disable class-methods-use-this */

import * as React from 'react';
import { Box } from '@chakra-ui/react';
import {
  CellTemplate,
  Cell,
  Compatible,
  Uncertain,
  getCellProperty,
  isAlphaNumericKey,
  isNavigationKey,
} from '@silevis/reactgrid';

import { TableCheckBox } from 'utils/ReactGridUtils';

export interface SelectCell extends Cell {
  text: string;
  isHeading: boolean;
  itemId: number;
}

export class SelectCellTemplate implements CellTemplate<SelectCell> {
  getCompatibleCell(uncertainCell: Uncertain<SelectCell>): Compatible<SelectCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const value = Number.parseFloat(text);
    return { ...uncertainCell, text, value } as Compatible<SelectCell>;
  }

  getClassName(cell: Compatible<SelectCell>): string {
    const className = cell.className || '';
    return `${className}`;
  }

  render(cell: Compatible<SelectCell>): React.ReactNode {
    const { isHeading, itemId } = cell || {};

    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (isAlphaNumericKey(event.keyCode) || isNavigationKey(event.keyCode))
        event.stopPropagation();
    };

    const handleClick = (event: any) => {
      if (isAlphaNumericKey(event.keyCode) || isNavigationKey(event.keyCode))
        event.stopPropagation();
    };

    return (
      <Box onClick={handleClick} onKeyDown={handleKeyDown}>
        <TableCheckBox isHeading={isHeading} itemId={itemId} />
      </Box>
    );
  }
}
