import { VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import ScrollContainer from 'react-indiana-drag-scroll';
import { useDispatch, useSelector } from 'react-redux';

import Cross from 'components/Icons/Cross';
import {
  AddDimnesionGroupItem,
  fetchDimensionItemsGroup,
  fetchDimensionPropeties,
  getDataIndicatorsKeys,
  getDimensionDataGroupStatus,
  getIsItemRenamed,
  getNewItemName,
  getRenameItemName,
  setDisplayInfo,
  setIsItemRenamed,
  setRenameItemName,
  UpdateDimensionProperty,
  UpdateItemName,
} from 'redux/ItemPageSlice';
import { closeInputSection, getSelectedDriver, setDriver } from 'redux/PlanPageSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';
import PlanPermission from 'container/PlanPermission';
import FullScreenLoader from 'components/FullScreenLoader';
import { HEADER_HEIGHT } from 'projectConstants';
import { AppDispatch } from 'utils/GlobalHelpers';

import NewGroup from './NewGroup';
import SkeletonLoader from './SkeletonLoader';
import DesktopItems from './DesktopItems';

interface Properties {
  planPermission: boolean;
  longClickCheck: any;
  isLongClicked: boolean;
  refetchRequest: () => void;
  orgType?: boolean;
}

const DesktopPanel = ({
  longClickCheck,
  isLongClicked,
  refetchRequest,
  planPermission,
  orgType,
}: Properties) => {
  const dispatch: AppDispatch = useDispatch();

  const currentDriver = useSelector(getSelectedDriver);
  const baseScenario = useSelector(getBaseScenario);

  const [addGroupName, setAddGroupName] = useState<string | null>(null);

  const dataIndicatorsKeys = useSelector(getDataIndicatorsKeys);
  const isLoading = useSelector(getDimensionDataGroupStatus);

  const renameItemName = useSelector(getRenameItemName);
  const isItemRenamed = useSelector(getIsItemRenamed);
  const newItemName = useSelector(getNewItemName);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (currentDriver && currentDriver.type === 'dimension') {
      dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));
      dispatch(fetchDimensionPropeties(currentDriver));
    }
  }, [currentDriver, baseScenario?.id]);

  const addGroup = async (event: any) => {
    event.preventDefault();
    await dispatch(
      AddDimnesionGroupItem({
        dimension_id: currentDriver?.connected_dimension,
        request_body: {
          name: addGroupName,
          properties: [
            {
              property_id: currentDriver?.group_property_id,
              value: addGroupName,
            },
          ],
          scenario_id: baseScenario?.id,
        },
        toaster: {
          successMessage: 'Successfully Added',
          errorMessage: 'An item with this name already exists',
        },
      }),
    );
    await dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));
    refetchRequest();
  };

  const renameItemOnBlur = async () => {
    if (isItemRenamed) {
      await dispatch(
        UpdateItemName({
          item_id: renameItemName,
          request_body: { name: newItemName, scenario_id: baseScenario?.id },
        }),
      );
      dispatch(setIsItemRenamed(false));
      refetchRequest();
    }
  };

  const closeSection = () => {
    dispatch(closeInputSection());
    dispatch(setDriver(null));
  };

  const handleIssueDrop = async (properties: any) => {
    const droppableId = JSON.parse(properties?.destination.droppableId);
    const draggableId = JSON.parse(properties?.draggableId);
    setLoader(true);
    await dispatch(
      UpdateDimensionProperty({
        item_id: draggableId?.item_id,
        request_body: {
          property_id: draggableId?.property_id,
          value: droppableId?.name,
        },
      }),
    ).then(async () => {
      await dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));
      setLoader(false);
    });
  };

  if (isLoading === 'IDLE') {
    return <FullScreenLoader bgColor='#796CD6' height={`calc(100vh - ${HEADER_HEIGHT} - 98px)`} />;
  }

  return (
    <VStack
      width='100%'
      justifyContent='flex-start'
      alignItems='flex-start'
      padding={'16px 14px 0 14px'}
      position='relative'
      height={`calc(100vh - 64px - 170px)`}
      onClick={() => {
        setDisplayInfo(null);
        dispatch(setRenameItemName(0));
        renameItemOnBlur();
      }}
    >
      {currentDriver?.group_property_id && currentDriver?.group_property && (
        <NewGroup
          {...{
            addGroupName,
            setAddGroupName,
            addGroup,
          }}
        />
      )}

      {!orgType && (
        <Cross
          styles={{ position: 'absolute', top: '10px', right: '10px' }}
          height={'25px'}
          width={'25px'}
          onClick={closeSection}
        />
      )}
      <DragDropContext onDragEnd={handleIssueDrop}>
        <ScrollContainer
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'center',
            position: 'relative',
            height: '100%',
            flexDirection: 'row',
            marginBottom: '1rem',
          }}
          hideScrollbars={false}
          ignoreElements='div'
          className='horizontal_scroll'
        >
          {loader ? (
            <>
              {dataIndicatorsKeys &&
                dataIndicatorsKeys?.map((data: any) => <SkeletonLoader key={data?.dim_id} />)}
            </>
          ) : (
            <DesktopItems
              isLongClicked={isLongClicked}
              longClickCheck={longClickCheck}
              planPermission={planPermission}
              refetchRequest={refetchRequest}
            />
          )}
        </ScrollContainer>
      </DragDropContext>
    </VStack>
  );
};

export default PlanPermission(DesktopPanel);
