import { Box, Button, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

import {
  FetchUserPaymentDetails,
  getUserPaymentData,
  getUserSubscriptionLimits,
  SetTabs,
  SubscribeUser,
} from 'redux/UserSlice';
import { UserPaymentData, UserSubscriptionLimit } from 'models/UsersSlice.interface';
import UserPermissions from 'utils/UserPermissions';
import { getTeamCount } from 'utils/TeamCount';
import ArrowRightIcon from 'components/Icons/Arrow';

import UserHeading from './UserHeading';
import UserShimmer from './UserManagementShimmer';

const UserBillingComponent = () => {
  const dispatch: any = useDispatch();
  const [loader, setLoader] = useState(true);
  const [isLaptop] = useMediaQuery('(max-width: 768px)');

  const userPaymentData: UserPaymentData = useSelector(getUserPaymentData);
  const userSubscriptionLimits: UserSubscriptionLimit = useSelector(getUserSubscriptionLimits);

  useEffect(() => {
    const getPaymentData = async () => {
      await dispatch(FetchUserPaymentDetails());
      setLoader(false);
    };
    getPaymentData();
  }, []);

  const formatAmount = (amountInCents: number) => {
    if (typeof amountInCents === 'number') {
      return (amountInCents / 100)?.toFixed(2);
    }
    return 'N/A';
  };

  const formatDate = (date: string) => {
    return moment(date).format('Do MMMM YYYY');
  };

  if (loader) return <UserShimmer />;

  return (
    <UserPermissions scopes={['edit']} permissionType='user'>
      <Flex width={'100%'} height={'100%'} flexDir='column'>
        <UserHeading heading='Billing' />
        <Box height={'100%'} overflow={'auto'}>
          <Flex width={'100%'} gap='4' flexWrap={'wrap'} justifyContent={'space-between'}>
            <Box
              width={isLaptop ? '100%' : '33%'}
              minW={'250px'}
              backgroundColor='white.200'
              padding='.5rem 1rem'
              borderRadius='10px'
              minH={'108px'}
            >
              <Text fontSize='2xs' color='grey.100'>
                Current Subscription
              </Text>
              <Text fontSize='23px' fontWeight='600'>
                {userPaymentData?.product_name}
              </Text>
              <Flex justifyContent={'space-between'} alignItems='center' mt='3'>
                <Box>
                  <Text fontSize='sm' fontWeight={'600'}>
                    £{userPaymentData?.price?.toFixed(2)}
                  </Text>
                  <Text fontSize='xs' fontWeight={'600'}>
                    per user per {userPaymentData?.duration === 'monthly' ? ' month' : ' year'}
                  </Text>
                </Box>
                {userSubscriptionLimits.current_subscription_name !== 'Free' && (
                  <Button
                    height={'fit-content'}
                    width='fit-content'
                    padding='.5rem 2rem'
                    backgroundColor={'green.700'}
                    borderRadius='10px'
                    disabled={userPaymentData?.created_at === ''}
                    onClick={() => {
                      dispatch(
                        SubscribeUser({
                          request_body: {
                            price_id: '',
                          },
                        }),
                      ).then((response: any) => {
                        window.location.href = response?.payload?.url;
                      });
                    }}
                  >
                    Edit plan
                  </Button>
                )}
              </Flex>
            </Box>
            <Box
              width={isLaptop ? '100%' : '30%'}
              minW={'250px'}
              backgroundColor='white.200'
              padding='.5rem 1rem'
              borderRadius='10px'
              minH={'108px'}
            >
              <Text fontSize='2xs' color='grey.100'>
                Team members
              </Text>
              <Text fontSize='xl' color='black' fontWeight='600' mb='2'>
                {getTeamCount(userSubscriptionLimits)}
              </Text>
              <Flex
                mt='6'
                fontSize='xs'
                fontWeight='500'
                color='green.700'
                cursor='pointer'
                alignItems={'center'}
                onClick={() => dispatch(SetTabs(3))}
              >
                Manage Team
                <span>
                  <ArrowRightIcon
                    color={'#3bb8bc'}
                    width='20px'
                    height='18px'
                    style={{ marginLeft: '.5rem' }}
                  />
                </span>
              </Flex>
            </Box>
            <Box
              width={isLaptop ? '100%' : '30%'}
              minW={'250px'}
              backgroundColor='white.200'
              padding='.5rem 1rem'
              borderRadius='10px'
              position={'relative'}
              minH={'108px'}
            >
              <Text fontSize='2xs' color='grey.100'>
                Next payment
              </Text>
              {userPaymentData.cancel_at ? (
                <>
                  <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <Text fontSize='23px' fontWeight='600'>
                      £0.00
                    </Text>
                    <Text
                      backgroundColor={'#f7e4e4'}
                      borderRadius={'10px'}
                      color={'#c24747'}
                      padding={'0 1rem'}
                      fontSize='12px'
                    >
                      Subscription cancelled
                    </Text>
                  </Flex>
                  <Text fontSize='12px'>
                    Your plan is active until {formatDate(userPaymentData?.next_billing_date)}.
                    After this date you will be moved to free plan.
                  </Text>
                </>
              ) : userPaymentData?.next_billing_date ? (
                <>
                  <Flex justifyContent={'space-between'} alignItems={'center'}>
                    <Text fontSize='23px' fontWeight='600'>
                      £{userPaymentData?.next_payment_price?.toFixed(2)}
                    </Text>
                    <Text fontSize='14px'>
                      {`${
                        userSubscriptionLimits?.subscription_current_usage?.No_of_users
                      } users, £${userPaymentData.price.toFixed(2)}/user`}
                    </Text>
                  </Flex>
                  <Text fontSize='sm' fontWeight={'600'} mt='6'>
                    Due {formatDate(userPaymentData?.next_billing_date)}
                  </Text>
                </>
              ) : (
                <Flex
                  position={'absolute'}
                  top='0'
                  left='0'
                  height={'100%'}
                  width={'100%'}
                  justifyContent={'center'}
                  alignItems={'center'}
                >
                  <Text
                    padding={'.2rem 1.5rem'}
                    backgroundColor={'grey.500'}
                    fontSize='xs'
                    borderRadius={'5px'}
                  >
                    Not available
                  </Text>
                </Flex>
              )}
            </Box>
          </Flex>
          <Flex mt='6' mb='8' gap={'8'} flexWrap={isLaptop ? 'wrap' : 'nowrap'}>
            <Box
              backgroundColor='white.200'
              padding='.5rem 1rem'
              borderRadius='10px'
              width={isLaptop ? '100%' : '50%'}
            >
              <Text fontSize='2xs' color='grey.100'>
                Current usage
              </Text>
              <Flex
                borderBottom={'2px #A9A8A8 solid'}
                justifyContent='space-between'
                padding={'.5rem 0'}
              >
                <Text fontSize={'md'} fontWeight={'600'}>
                  Users
                </Text>
                <Text fontSize={'md'} fontWeight={'600'}>
                  {userSubscriptionLimits.subscription_features_limit?.No_of_users
                    ? `${userSubscriptionLimits.subscription_current_usage?.No_of_users} /
                  ${userSubscriptionLimits.subscription_features_limit?.No_of_users}`
                    : userSubscriptionLimits.subscription_current_usage?.No_of_users}
                </Text>
              </Flex>
              <Flex justifyContent='space-between' padding={'.5rem 0'}>
                <Text fontSize={'md'} fontWeight={'600'}>
                  Models
                </Text>
                <Text fontSize={'md'} fontWeight={'600'}>
                  {userSubscriptionLimits.subscription_features_limit?.No_of_models
                    ? `${userSubscriptionLimits.subscription_current_usage?.No_of_models} /
                  ${userSubscriptionLimits?.subscription_features_limit?.No_of_models}`
                    : userSubscriptionLimits?.subscription_current_usage?.No_of_models}
                </Text>
              </Flex>
            </Box>
            <Flex flexDir={'column'} height={'100%'} width={isLaptop ? '100%' : '50%'} gap={'6'}>
              <Flex
                backgroundColor='white.200'
                padding='.5rem 1rem'
                borderRadius='10px'
                width='100%'
                height={'200px'}
                position={'relative'}
                flexDir={'column'}
              >
                <Text fontSize='2xs' color='grey.100'>
                  Payment history
                </Text>
                {userPaymentData?.last_payment?.amount ? (
                  <Flex mt='4' height={'100%'} flexDir='column' justifyContent={'space-between'}>
                    <Box>
                      <Text fontSize='sm' fontWeight={'600'}>
                        Last payment
                      </Text>
                      <Text mt='2' fontSize='sm'>{`£${formatAmount(
                        userPaymentData?.last_payment?.amount,
                      )} paid on ${formatDate(userPaymentData?.last_payment.billing_date)}`}</Text>
                    </Box>
                    <Text
                      fontSize='14px'
                      fontWeight='500'
                      color='green.700'
                      textAlign={'end'}
                      ml='2'
                      cursor={'pointer'}
                      onClick={() => window.open(userPaymentData?.invoice_url, '_blank')}
                    >
                      {'View invoices ->'}
                    </Text>
                  </Flex>
                ) : (
                  <Flex
                    position={'absolute'}
                    top='0'
                    left='0'
                    height={'100%'}
                    width={'100%'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Text
                      padding={'.2rem 1.5rem'}
                      backgroundColor={'grey.500'}
                      fontSize='xs'
                      borderRadius={'5px'}
                    >
                      Not available
                    </Text>
                  </Flex>
                )}
              </Flex>
              <Box
                backgroundColor='white.200'
                padding='.5rem 1rem'
                borderRadius='10px'
                width='100%'
                height={'200px'}
                position={'relative'}
              >
                <Text fontSize='2xs' color='grey.100'>
                  Payment methods
                </Text>
                {userPaymentData?.payment_method?.length > 0 ? (
                  userPaymentData?.payment_method?.map((data: any, index: number) => {
                    return (
                      <Flex mt='4' key={index}>
                        <Text fontSize='14px'>
                          {data?.brand} {data?.last4}
                        </Text>
                        <Text fontSize='14px' ml='6'>
                          expires on {data?.exp_month}/{data?.exp_year}
                        </Text>
                      </Flex>
                    );
                  })
                ) : (
                  <Flex
                    position={'absolute'}
                    top='0'
                    left='0'
                    height={'100%'}
                    width={'100%'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Text
                      padding={'.2rem 1.5rem'}
                      backgroundColor={'grey.500'}
                      fontSize='xs'
                      borderRadius={'5px'}
                    >
                      Not available
                    </Text>
                  </Flex>
                )}
              </Box>
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </UserPermissions>
  );
};

export default UserBillingComponent;
