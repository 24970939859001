/* eslint-disable no-else-return */
/* eslint-disable no-unsafe-optional-chaining */

const calculateTableWidth = (totalElements: number, totalWidth: number, selectedDimension: any) => {
  const nameField: number = 150;
  const selectField: number = 50;
  const columnsWidth =
    totalElements * 150 + nameField + selectedDimension?.name !== 'Time' ? selectField : 0;
  return totalWidth > columnsWidth
    ? (totalWidth - (selectedDimension?.name !== 'Time' ? selectField : 0)) / (totalElements + 1)
    : 150;
};

const tableHeaderRow = (tableItemsProperty_: any, tableProperty_: any, selectedDimension: any) => {
  const nameOptions = tableItemsProperty_
    .filter((item: any) => item?.name !== null)
    .reduce((accumulator: any, item: any) => {
      if (!accumulator.includes(item?.name)) {
        accumulator.push(item?.name);
      }
      return accumulator;
    }, []);

  const cells = [
    {
      type: 'filter',
      options: nameOptions,
      id: 'name',
      text: 'Name',
      hasDelete: false,
      hasEdit: false,
      nonEditable: true,
      className: 'header-cell header-first',
    },
    ...(tableProperty_?.length
      ? tableProperty_?.map((data: any) => {
          const options = tableItemsProperty_
            .filter((item: any) => item[data?.id] !== null)
            .reduce((accumulator: any, item: any) => {
              if (!accumulator.includes(item[data?.id])) {
                accumulator.push(item[data?.id]);
              }
              return accumulator;
            }, []);
          const row = {
            type: 'filter',
            options,
            id: data?.id,
            hasDelete: true,
            hasEdit: true,
            colType: 'header',
            text: data?.name,
            className: `header-cell `,
          };
          return row;
        })
      : []),
  ];

  if (selectedDimension?.name !== 'Time') {
    cells.unshift({
      type: 'select',
      text: '',
      itemId: 0,
      placeholder: 'Enter value',
      property: 'select',
      className: 'select header-select',
      hasFormulaError: false,
      isHeading: true,
    });
  }

  return {
    rowId: 'header',
    height: 50,
    cells,
  };
};

const tableFooterRow = (tableProperty_: any, selectedDimension: any) => {
  const cells = [
    { type: 'addRow', text: '', className: 'footer-first', nonEditable: true },
    ...(tableProperty_?.length
      ? tableProperty_?.map(() => {
          const row = {
            type: 'text',
            text: '',
            nonEditable: true,
            className: `footer `,
            hasFormulaError: false,
          };
          return row;
        })
      : []),
  ];

  if (selectedDimension?.name !== 'Time') {
    cells.splice(1, 0, {
      type: 'text',
      text: '',
      nonEditable: true,
      className: `footer `,
      hasFormulaError: false,
    });
  }
  return {
    rowId: 'footer',
    height: 50,
    cells,
  };
};

const getTableRows = (tableItemsProperty_: any, tableProperty_: any, selectedDimension: any) => {
  return [
    tableHeaderRow(tableItemsProperty_, tableProperty_, selectedDimension),
    ...(tableItemsProperty_?.length
      ? tableItemsProperty_?.map((item: any, index: any) => {
          const cells = [
            {
              type: 'text',
              placeholder: 'Enter value',
              text: `${item?.name}` ?? '',
              property: 'name',
              rows: tableItemsProperty_?.length,
              className: 'name',
              hasFormulaError: false,
              index,
            },
            ...(tableProperty_?.length
              ? tableProperty_?.map((data: any) => {
                  if (
                    data?.name.trim().toLowerCase() === 'start' ||
                    data?.name.trim().toLowerCase() === 'end' ||
                    data?.name.trim().toLowerCase() === 'start date' ||
                    data?.name.trim().toLowerCase() === 'end date'
                  ) {
                    const row = {
                      type: 'date',
                      text: item[`${data?.id}`] || '',
                      date: new Date(),
                      nonEditable: !item?.id,
                    };
                    return row;
                  } else if (data?.items) {
                    const row = {
                      type: 'dropdown',
                      nonEditable: !item?.id,
                      selectOption: true,
                      selectedValue: item[`${data?.id}`],
                      values: [
                        ...(data?.items?.length
                          ? data?.items?.map((value: any) => ({
                              label: value?.name,
                              value: value?.name,
                            }))
                          : []),
                      ],
                      index,
                      operation: '',
                      data,
                    };
                    return row;
                  } else {
                    const row = {
                      type: 'text',
                      rows: tableItemsProperty_?.length,
                      text: item[`${data?.id}`] ?? '',
                      index,
                      data,
                      nonEditable: !item?.id,
                      isHeading: false,
                      hasFormulaError: false,
                    };
                    return row;
                  }
                })
              : []),
          ];
          if (selectedDimension?.name !== 'Time') {
            cells.unshift({
              type: 'select',
              text: '',
              placeholder: 'Enter value',
              itemId: item?.id,
              property: 'select',
              className: 'select',
              hasFormulaError: false,
              isHeading: false,
              index,
            });
          }
          return {
            rowId: index,
            height: 50,
            itemId: item?.id,
            itemName: item?.name,
            reorderable: true,
            cells,
          };
        })
      : []),
    tableFooterRow(tableProperty_, selectedDimension),
  ];
};

const getTableColumns = (tableProperty_: any, tableReference: any, selectedDimension: any) => {
  const columns = [
    {
      columnId: 'name',
      width:
        tableReference || tableProperty_
          ? calculateTableWidth(tableProperty_?.length, tableReference, selectedDimension)
          : 150,
      resizable: true,
    },
    ...(tableProperty_?.length
      ? tableProperty_?.map((data: any) => {
          const row = {
            columnId: `${data?.id}`,
            name: data?.name,
            width: tableReference
              ? calculateTableWidth(tableProperty_?.length, tableReference, selectedDimension)
              : 150,
            resizable: true,
          };
          return row;
        })
      : []),
  ];

  if (selectedDimension?.name !== 'Time') {
    columns.unshift({
      columnId: 'select',
      width: 50,
      resizable: true,
    });
  }

  return columns;
};

const reorderArray = <T extends {}>(array: T[], idxs: number[], to: number) => {
  const movedElements = array.filter((_, index) => idxs.includes(index));
  const targetIndex =
    Math.min(...idxs) < to ? (to += 1) : (to -= idxs.filter((index) => index < to).length);
  const leftSide = array.filter((_, index) => index < targetIndex && !idxs.includes(index));
  const rightSide = array.filter((_, index) => index >= targetIndex && !idxs.includes(index));
  const reorderedArray = [...leftSide, ...movedElements, ...rightSide];
  const idsOnlyArray = reorderedArray.map((item: any) => item.id);
  return idsOnlyArray;
};

export { getTableRows, getTableColumns, reorderArray };
