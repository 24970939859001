import { showAuthenticationModal } from '../SharedSlice';
import { FetchSubscriptionFeatureLimits, FetchUserDetails } from '../UserSlice';

const PermissionMiddleware = (store: any) => (next: any) => async (action: any) => {
  if (action?.payload?.message === 'Forbidden!!') {
    store.dispatch(showAuthenticationModal());
    store.dispatch(FetchUserDetails({}));
    store.dispatch(FetchSubscriptionFeatureLimits());
  }
  next(action);
};

export default PermissionMiddleware;
