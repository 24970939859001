import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
  style?: React.CSSProperties;
}

const Calendar = ({ width = 4.5, height = 5, color, style }: Properties) => {
  return (
    <Icon viewBox='0 0 18 21' width={width} height={height} style={style}>
      <path
        d='M16.2 2.36896H15.3V0.550781H13.5V2.36896H4.5V0.550781H2.7V2.36896H1.8C0.81 2.36896 0 3.18714 0 4.18714V18.7326C0 19.7326 0.81 20.5508 1.8 20.5508H16.2C17.19 20.5508 18 19.7326 18 18.7326V4.18714C18 3.18714 17.19 2.36896 16.2 2.36896ZM16.2 18.7326H1.8V6.91442H16.2V18.7326Z'
        fill={color}
      />
    </Icon>
  );
};

export default Calendar;
