import React from 'react';

interface Properties {
  width?: number;
  height?: number;
}

const AddToDashboard = ({ width, height }: Properties) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 96 96'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_184_3600)'>
        <path
          d='M52 28H44V44H28V52H44V68H52V52H68V44H52V28ZM48 8C25.92 8 8 25.92 8 48C8 70.08 25.92 88 48 88C70.08 88 88 70.08 88 48C88 25.92 70.08 8 48 8ZM48 80C30.36 80 16 65.64 16 48C16 30.36 30.36 16 48 16C65.64 16 80 30.36 80 48C80 65.64 65.64 80 48 80Z'
          fill='#D9D9D9'
        />
      </g>
      <defs>
        <clipPath id='clip0_184_3600'>
          <rect width='96' height='96' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddToDashboard;
