import { Image, StyleProps } from '@chakra-ui/react';

import FreePlanImg from 'assets/free_plan_icon.png';

interface Properties {
  width?: string;
  height?: string;
  color?: string;
  styles?: StyleProps;
}

const FreePlanImage = ({ width = '70', height = '70', styles }: Properties) => {
  return <Image src={FreePlanImg} w={width} h={height} {...styles} />;
};

export default FreePlanImage;
