import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const Search = ({ width = 4, height = 4, color = 'green.900' }: Properties) => {
  return (
    <Icon viewBox='0 0 39 39' width={width} height={height} color={color}>
      <path
        d='M27.8731 24.5283H26.1115L25.4871 23.9262C27.6724 21.3842 28.988 18.084 28.988 14.494C28.988 6.48885 22.4991 0 14.494 0C6.48885 0 0 6.48885 0 14.494C0 22.4991 6.48885 28.988 14.494 28.988C18.084 28.988 21.3842 27.6724 23.9262 25.4871L24.5283 26.1115V27.8731L35.6775 39L39 35.6775L27.8731 24.5283ZM14.494 24.5283C8.94168 24.5283 4.45969 20.0463 4.45969 14.494C4.45969 8.94168 8.94168 4.45969 14.494 4.45969C20.0463 4.45969 24.5283 8.94168 24.5283 14.494C24.5283 20.0463 20.0463 24.5283 14.494 24.5283Z'
        fill='currentColor'
      />
    </Icon>
  );
};

export default Search;
