import * as yup from 'yup';

import * as MESSAGES from '../messages';

const OrganisationSchema = yup.object().shape({
  orgId: yup.string(),
  companyName: yup.string().trim().required(MESSAGES.REQUIRE_MESSAGE),
  industry: yup.string(),
  website: yup.string(),
  imageUrl: yup.string(),
  uniqueName: yup.string(),
});

export default OrganisationSchema;
