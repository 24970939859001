import {
  Box,
  Button,
  Flex,
  Menu,
  Text,
  MenuItem,
  MenuList,
  Input,
  useOutsideClick,
  Modal,
  ModalBody,
  MenuButton,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Image,
  Textarea,
} from '@chakra-ui/react';
import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import axios from 'axios';
import { BiSend } from 'react-icons/bi';

import AddIconComponent from 'components/Icons/AddIcon';
import ArrorDropdownIconComponent from 'components/Icons/ArrowDropdownIcon';
import FilterIconComponent from 'components/Icons/FilterIcon';
import SettingIconComponent from 'components/Icons/SettingIcon';
import {
  AddDimensionHeader,
  FetchDimensionProperties,
  FetchScenarios,
  getAddProperty,
  getPropertiesSectionStatus,
  getSelectedDimension,
  getBaseScenario,
  getSettingsSectionStatus,
  handleAddDimensionStatus,
  handleAddItemStatus,
  handleAddPropertyStatus,
  handlePropertySection,
  handleSettingsSection,
  FetchDimensionList,
} from 'redux/BuilderModeSlice';
import { getUserData } from 'redux/UserSlice';
import VoiceFlowGenerateIcon from 'components/Icons/VoiceFlowGenerateIcon';
import assistant from 'assets/assistant_icon.png';
// import InputField from 'components/InputField';
import BeatLoader from 'components/ThreeDotSpinner/BeatLoader';
import UserChatIcon from 'components/Icons/UserChatIcon';
import blockImage from 'assets/blox_logo.png';
import Typewriter from 'utils/TypeWriter';

import ScenarioForecast from './ScenarioForecast';

const { REACT_APP_PLANNING_GPT_ENV, REACT_APP_VOICEFLOW_API_KEY }: any = process.env;

const DimensionBuilderModeHeader = ({ flags }: any) => {
  const parameters: any = useParams().modelId;
  const { modelId } = useParams();
  const settingsSectionStatus = useSelector(getSettingsSectionStatus);
  const addProperty = useSelector(getAddProperty);
  const propertiesSectionStatus = useSelector(getPropertiesSectionStatus);
  const selectedDimension: any = useSelector(getSelectedDimension);
  const selectedScenario: any = useSelector(getBaseScenario);
  const dispatch: any = useDispatch();
  const inputReference: any = useRef(null);
  const reference = useRef(null);
  const [isOpenVoiceFlow, setIsOpenVoiceFlow] = useState(false);
  const [message, setMesssage]: any = useState([]);
  const [inputMessage, setInputMesssage]: any = useState(null);
  const [loading, setLoading] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const token = localStorage.getItem('BLOX_USER_TOKEN');
  const containerReference = useRef<any>();
  const userData = useSelector(getUserData);
  useEffect(() => {
    const getScenario = async () => {
      if (parameters) await dispatch(FetchScenarios(parameters));
    };
    getScenario();
  }, []);

  const scrollling = () => {
    setTimeout(() => {
      containerReference.current.scrollTop = containerReference?.current?.scrollHeight;
    }, 1000);
  };

  useOutsideClick({
    ref: reference,
    handler: () => {
      if (addProperty) dispatch(handleAddPropertyStatus(false));
    },
  });
  const onCloseVoiceFlow = () => {
    setIsOpenVoiceFlow(false);
    dispatch(FetchDimensionList(parameters));
  };

  const handleAddProperty = (value: string) => {
    dispatch(handleAddPropertyStatus(false));
    dispatch(
      AddDimensionHeader({
        dimensionId: selectedDimension?.id,
        data: { name: value },
        toaster: true,
      }),
    ).then(() => {
      dispatch(
        FetchDimensionProperties({
          selectedId: selectedDimension?.id,
          parameters: { scenario_id: selectedScenario?.id },
        }),
      );
    });
  };

  const body = {
    action: {
      type: 'launch',
      payload: null,
    },
    state: {
      variables: {
        blox_token: `${token}`,
        model_id: `${modelId}`,
        planninggpt_env: REACT_APP_PLANNING_GPT_ENV,
      },
    },
  };

  function urlify(text: any) {
    const urlRegex = /(https?:\/\/\S+)/g;
    const strongpattern = /\*\*(.*?)\*\*/g;
    const strongPatternString = text?.replace(strongpattern, '<strong>$1</strong>');
    const inputString = strongPatternString?.replace(/\n/g, '<br>');
    return `<div>  ${inputString?.replace(urlRegex, (url: any) => {
      return `<a href='${url}'>${url}</a>`;
    })} </div>`;
  }

  async function startInteract() {
    setTimeout(() => {
      setLoading(true);
    }, 2300);
    // Start a conversation
    await axios({
      method: 'POST',
      baseURL: 'https://general-runtime.voiceflow.com',
      url: `/state/user/${token}/interact`,
      headers: {
        Authorization: REACT_APP_VOICEFLOW_API_KEY,
      },
      data: body,
    })
      .then((response: any) => {
        setMesssage(JSON.parse(JSON.stringify(response?.data)));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }

  const lastIndexData = message[message.length - 1];

  const onChoiceType = async (action: any) => {
    const newTextObject = {
      type: 'text',
      userMessage: true,
      payload: {
        message: `${action?.payload?.label}`,
      },
    };
    message.pop();
    message.push(newTextObject);
    setChatLoading(true);
    const request = { action };
    const response1 = await axios({
      method: 'POST',
      baseURL: 'https://general-runtime.voiceflow.com',
      url: `/state/user/${token}/interact`,
      headers: {
        Authorization: REACT_APP_VOICEFLOW_API_KEY,
      },
      data: request,
    });
    setMesssage([...message, ...JSON.parse(JSON.stringify(response1?.data))]);
    setChatLoading(false);
    scrollling();
  };

  const transformString = (input: string) => {
    return input.replace(/[\n\r]+/g, ' ');
  };

  const inputMessageData = async () => {
    const newMessage = transformString(inputMessage.trim());
    const newTextObject = {
      type: 'text',
      userMessage: true,
      payload: {
        message: inputMessage?.trim(),
      },
    };
    message.push(newTextObject);
    setChatLoading(true);

    const bodyData = {
      action: {
        type: 'text',
        payload: newMessage?.trim(),
      },
    };
    setTimeout(() => {
      setInputMesssage('');
    }, 0);
    scrollling();
    const response1 = await axios({
      method: 'POST',
      baseURL: 'https://general-runtime.voiceflow.com',
      url: `/state/user/${token}/interact`,
      headers: {
        Authorization: REACT_APP_VOICEFLOW_API_KEY,
      },
      data: bodyData,
    });

    setMesssage([...message, ...JSON.parse(JSON.stringify(response1?.data))]);
    setChatLoading(false);
    scrollling();
  };

  const startInteractWithVoiceFlow = () => {
    setMesssage([]);
    startInteract();
    setIsOpenVoiceFlow(true);
  };

  return (
    <Flex alignItems={'flex-start'} width={'100%'} justifyContent={'space-between'}>
      <Flex alignItems={'flex-start'}>
        <Text fontSize={'md'} width={'368px'}>
          Edit your dimension and items here
        </Text>
        <ScenarioForecast />
      </Flex>
      <Flex alignItems={'center'}>
        {addProperty && (
          <Box pr={4} ref={reference}>
            <Input
              ref={inputReference}
              onKeyDown={(event: any) => {
                if (event?.keyCode === 13) handleAddProperty(event?.target?.value);
              }}
              placeholder='New Property'
            />
          </Box>
        )}
        <Box
          onClick={() => dispatch(handlePropertySection())}
          mr={2}
          padding={'.2rem .2rem .3rem .3rem'}
          bgColor={propertiesSectionStatus ? 'violet.750' : ''}
          borderRadius={'sm'}
        >
          <FilterIconComponent color='white' height={30} width={30} />
        </Box>
        <Box
          onClick={() => dispatch(handleSettingsSection())}
          padding={'.2rem .2rem .3rem .3rem'}
          bgColor={settingsSectionStatus ? 'violet.750' : ''}
          borderRadius={'sm'}
          mr={2}
        >
          <SettingIconComponent color='white' height={30} width={30} />
        </Box>
        {flags?.antonGpt && (
          <Flex
            borderRadius='25px'
            background='linear-gradient(180deg, #6F6DC0 52%, rgba(59, 184, 188, 0.36) 100%)'
            align={'center'}
            justify={'center'}
            padding={'0.5rem 1rem'}
            cursor={'pointer'}
            onClick={startInteractWithVoiceFlow}
            me={3}
            height={'45px'}
          >
            <Flex align={'center'} gap={3}>
              <VoiceFlowGenerateIcon />
              <Text fontSize={'md'} fontWeight={'400'} fontFamily='Roboto regular'>
                Generate
              </Text>
            </Flex>
          </Flex>
        )}
        <Menu closeOnSelect={true} variant='primary'>
          <MenuButton
            backgroundColor={'#5A58AB'}
            padding={'.5rem 1rem'}
            as={Button}
            border={'none'}
            rightIcon={
              <ArrorDropdownIconComponent
                style={{ margin: '0 .5rem' }}
                height={12}
                width={12}
                color='white'
              />
            }
            leftIcon={
              <AddIconComponent
                style={{ margin: '0 .5rem' }}
                color='#62B5BA'
                height={18}
                width={18}
              />
            }
            fontSize={'md'}
            fontWeight={'400'}
            width={'fit-content'}
            height={'45px'}
          >
            Create
          </MenuButton>
          <MenuList zIndex={6}>
            <MenuItem onClick={() => dispatch(handleAddDimensionStatus(true))}>Dimension</MenuItem>
            <MenuItem onClick={() => dispatch(handleAddItemStatus(true))}>Item</MenuItem>
            <MenuItem
              onClick={() => {
                dispatch(handleAddPropertyStatus(true)).then(() => {
                  setTimeout(() => {
                    if (inputReference?.current) inputReference.current.focus();
                  }, 0);
                });
              }}
            >
              Property
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <Modal
        onClose={onCloseVoiceFlow}
        isOpen={isOpenVoiceFlow}
        blockScrollOnMount={false}
        size='xl'
        isCentered
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent height={'600px'} width={'90vw'} borderTopRadius={'25px'} maxWidth={'100vh'}>
          <ModalCloseButton color={'rgba(255, 255, 255, 0.30)'} />
          <Flex bg={'#3d3381'} borderTopRadius={'20px'} height={'130px'} align={'center'} gap={5}>
            <Flex ms={3}>
              <Image src={assistant} w={25} h={25} style={{ transform: 'scaleX(-1)' }} />
            </Flex>
            <Flex flexDirection={'column'}>
              <Flex fontWeight={400} fontFamily='Roboto regular' color={'#fff'}>
                Blox AI Assistant
              </Flex>
              <Flex fontFamily='Roboto regular' color={'#fff'}>
                Custom AI generated models in seconds
              </Flex>
            </Flex>
          </Flex>
          <ModalBody
            ref={containerReference}
            p={0}
            m={0}
            height='75%'
            overflowY={'auto'}
            className={'scroll'}
            bg={'white'}
          >
            <Flex flexDirection={'column'} gap={3} mt={5} mb={3}>
              <Flex align={'center'} justify={'left'}>
                <Flex ms={3} align={'center'}>
                  <Flex align={'end'} height={'35px'} width={'35px'} minWidth={'35px'} me={3}>
                    <Image
                      src={blockImage}
                      height={'35px'}
                      width={'35px'}
                      bg={'#F2F0FD'}
                      borderRadius={'25px'}
                    />
                  </Flex>
                  {/* <Box
                    fontFamily='Roboto regular'
                    p={'0.5rem'}
                    bg={'#fff'}
                    borderRadius={'10px 10px 10px 0'}
                    overflow={'hidden'}
                    textOverflow={'ellipsis'}
                    fontSize={'14px'}
                    alignSelf={'center'}
                    color={'black'}
                    border={'1px solid #DDDBEC'}
                    dangerouslySetInnerHTML={{
                      __html: `Hi! I'm Anton, the Blox AI assistant ☀️`,
                    }}
                  ></Box> */}
                  <Typewriter text={`Hi! I'm Anton, the Blox AI assistant ☀️`} delay={50} />
                </Flex>
              </Flex>
            </Flex>
            {loading ? (
              <Flex maxWidth={'70%'} gap={1} align={'center'} mt={3}>
                <Flex align={'end'} height={'35px'} width={'35px'} minWidth={'35px'} me={3} ms={3}>
                  <Image
                    src={blockImage}
                    height={'35px'}
                    width={'35px'}
                    bg={'#F2F0FD'}
                    borderRadius={'25px'}
                  />
                </Flex>
                <BeatLoader />
              </Flex>
            ) : (
              <Flex flexDirection={'column'} gap={3} mb={3}>
                {message &&
                  message.length > 0 &&
                  message.map((item: any, index: number) => {
                    const innerhtmlData = urlify(item?.payload?.message);
                    return (
                      <Flex
                        key={index}
                        align={'center'}
                        justify={item?.userMessage ? 'end' : 'left'}
                      >
                        {item?.type === 'text' && (
                          <Flex maxWidth={'70%'} gap={1} align={'end'}>
                            {item?.userMessage ? (
                              <Flex pe={2} align={'center'}>
                                <Box
                                  fontFamily='Roboto regular'
                                  p={'0.5rem'}
                                  borderRadius={'10px 10px 0 10px'}
                                  overflow={'hidden'}
                                  textOverflow={'ellipsis'}
                                  fontSize={'14px'}
                                  alignSelf={'center'}
                                  border={'1px solid #DDDBEC'}
                                  color='#7A73AF'
                                  bg={'#F2F0FD'}
                                  me={2}
                                  dangerouslySetInnerHTML={{ __html: innerhtmlData }}
                                ></Box>
                                <Flex
                                  justify={'end'}
                                  align={'center'}
                                  height={'35px'}
                                  width={'35px'}
                                  minWidth={'35px'}
                                >
                                  {userData?.profile_image_url && (
                                    <Image
                                      src={userData?.profile_image_url}
                                      height={'35px'}
                                      width={'35px'}
                                      bg={'#F2F0FD'}
                                      borderRadius={'25px'}
                                    />
                                  )}

                                  {!userData?.profile_image_url && <UserChatIcon />}
                                </Flex>
                              </Flex>
                            ) : (
                              <Flex ms={3} align={'center'}>
                                <Flex
                                  align={'end'}
                                  height={'35px'}
                                  width={'35px'}
                                  minWidth={'35px'}
                                  me={3}
                                >
                                  <Image
                                    src={blockImage}
                                    height={'35px'}
                                    width={'35px'}
                                    bg={'#F2F0FD'}
                                    borderRadius={'25px'}
                                  />
                                </Flex>

                                <Box
                                  dangerouslySetInnerHTML={{ __html: innerhtmlData }}
                                  fontFamily='Roboto regular'
                                  p={'0.5rem'}
                                  bg={'#fff'}
                                  borderRadius={'10px 10px 10px 0'}
                                  overflow={'hidden'}
                                  textOverflow={'ellipsis'}
                                  fontSize={'14px'}
                                  alignSelf={'center'}
                                  color={'black'}
                                  border={'1px solid #DDDBEC'}
                                ></Box>
                              </Flex>
                            )}
                          </Flex>
                        )}
                        {item?.type === 'choice' && item?.payload?.buttons && (
                          <Flex flexDirection={'column'} ps={14}>
                            {item?.payload?.buttons?.map((button: any, index_: number) => (
                              <Button
                                color={'black'}
                                mb={2}
                                fontFamily='Roboto regular'
                                key={index_}
                                onClick={() => onChoiceType(button?.request)}
                                bg={'#fff'}
                                padding={'.5rem 1rem'}
                                fontSize={'sm'}
                                fontWeight={'400'}
                                border={'1px solid #DDDBEC'}
                              >
                                {button?.name}
                              </Button>
                            ))}
                          </Flex>
                        )}
                        {item?.type === 'end' && (
                          <Flex width={'100%'} justify={'center'} align={'center'}>
                            <Text
                              mt={5}
                              textAlign={'center'}
                              fontSize={'xs'}
                              color={'grey'}
                              fontFamily='Roboto regular'
                            >
                              You have ended chat
                            </Text>
                          </Flex>
                        )}
                      </Flex>
                    );
                  })}
                {chatLoading && (
                  <Flex maxWidth={'70%'} gap={1} align={'end'} mt={5}>
                    <Flex align={'end'} height={'35px'} width={'35px'}>
                      <Image src={blockImage} height={'35px'} width={'35px'} />
                    </Flex>
                    <BeatLoader />
                  </Flex>
                )}
              </Flex>
            )}
          </ModalBody>

          <ModalFooter bg={'white'} mb={3} p={0} borderRadius={'inherit'} mx={3}>
            {lastIndexData && lastIndexData?.type === 'end' ? (
              <Button
                bg={'#F2F0FD'}
                outline={'grey !important'}
                boxShadow={'none !important'}
                width={'100%'}
                fontWeight={400}
                color={'#7A73AF'}
                onClick={startInteractWithVoiceFlow}
                fontSize={'sm'}
                fontFamily='Roboto regular'
                padding={'2rem'}
                mb='2'
              >
                Start New chat
              </Button>
            ) : lastIndexData && lastIndexData?.type === 'choice' ? (
              <></>
            ) : (
              <>
                <Textarea
                  ms={2}
                  whiteSpace={'pre-wrap'}
                  overflow={'auto'}
                  className='scroll'
                  value={inputMessage}
                  alignContent='center'
                  fontSize='sm'
                  width={'100%'}
                  border='none'
                  boxShadow={'none !important'}
                  py={'1rem'}
                  px={'2rem'}
                  height='3rem'
                  maxH='3rem'
                  fontFamily='Roboto regular'
                  placeholder={'Type a message...'}
                  _placeholder={{ color: '#4b3b86' }}
                  autoFocus
                  color='#7A73AF'
                  bg={'#F2F0FD'}
                  resize='none'
                  onKeyDown={(event: any) => {
                    if (event?.keyCode === 13 && event.target.value.trim().length > 0)
                      inputMessageData();
                  }}
                  onChange={(event: any) => {
                    setInputMesssage(event.target.value);
                  }}
                />
                <Flex
                  width={'35px'}
                  ms={'1rem'}
                  me={2}
                  cursor={'pointer'}
                  onClick={inputMessageData}
                >
                  <BiSend color='#D7D5E6' cursor={'pointer'} fontSize={100} />
                </Flex>
              </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
export default withLDConsumer()(DimensionBuilderModeHeader);
