/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Box, Button, Checkbox, Flex, Input, Stack, Text, VStack } from '@chakra-ui/react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DimensionTabIcon from 'components/Icons/ModelOverviewTabs/DimensionTabIcon';
import {
  getBlockDimensions,
  getIndicatorTableData,
  getmultiDimFiltersItems,
  isResetFilter,
  mdFilterItems,
  setFilterItems,
  setMultiDimFilter,
  setResetFilter,
} from 'redux/PlanPageSlice';
import {
  fetchIndicatorDetail,
  fetchIndicatorDetailType,
  getIndicatorDetail,
  setChartFilterItems,
} from 'redux/IndicatorPageSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';

const MultiSelectTableFilters = ({ dimensionId, setIsMenuOpen }: any) => {
  const multiDimFiltersItems = useSelector(getmultiDimFiltersItems);
  const blockDimensions = useSelector(getBlockDimensions);
  const filterItemApplied = useSelector(mdFilterItems);
  const indicator = useSelector(getIndicatorDetail);
  const selectedScenario = useSelector(getBaseScenario);
  const indicatorTableData = useSelector(getIndicatorTableData);
  const scenarioId = selectedScenario?.id;
  const itemsData =
    blockDimensions && blockDimensions?.dimensions?.find((item: any) => item?.id === dimensionId);
  const dispatch: any = useDispatch();
  const [searchValue, setSearchValue]: any = useState();
  const [isChartFilter, setIsChartFilter] = useState<any>(false);

  const resetFilter = useSelector(isResetFilter);

  const chartFilterDimId = indicatorTableData?.column_headers[0].is_dimension
    ? indicatorTableData?.column_headers[0].id
    : null;
  const [ischeckedItemsChecked, setCheckedItemsChecked] = useState(
    itemsData?.items?.map((item: any) => {
      return { name: item, status: false, search: true };
    }),
  );
  const [newDataItem, setNewDataItems] = useState<any>();

  useEffect(() => {
    const findDataItems = filterItemApplied.find((item: any) => item?.id === dimensionId);

    if (findDataItems) {
      setNewDataItems(findDataItems);
      setCheckedItemsChecked(findDataItems.resultedItems);
    } else {
      setCheckedItemsChecked(
        itemsData?.items?.map((item: any) => {
          return { name: item, status: false, search: true };
        }),
      );
    }
  }, [filterItemApplied, dimensionId, itemsData]);

  useEffect(() => {
    if (newDataItem) {
      setCheckedItemsChecked(newDataItem?.resultedItems);
    }
  }, [newDataItem]);
  useEffect(() => {
    if (resetFilter) {
      setCheckedItemsChecked(
        itemsData?.items?.map((item: any) => {
          return { name: item, status: false, search: true };
        }),
      );
      dispatch(setResetFilter(false));
    }
  }, [resetFilter]);

  const allChecked =
    ischeckedItemsChecked && ischeckedItemsChecked?.every((item: any) => item.status);
  const isIndeterminate =
    ischeckedItemsChecked && ischeckedItemsChecked?.some((item: any) => item.status) && !allChecked;

  useEffect(() => {
    if (searchValue) {
      const searchTerm = searchValue.toLowerCase(); // Convert search term to lowercase for case-insensitive search
      const updatedItems = ischeckedItemsChecked?.map((item: any) => ({
        ...item,
        search: item.name.toLowerCase().includes(searchTerm),
      }));
      setCheckedItemsChecked(updatedItems);
    } else {
      const updatedItems = ischeckedItemsChecked?.map((item: any) => ({
        ...item,
        search: true,
      }));
      setCheckedItemsChecked(updatedItems);
    }
  }, [searchValue]);

  const handleCheckBoxChange = (user: any, event: any) => {
    setCheckedItemsChecked((previousState: any) => {
      const newState = previousState?.map((item: any) => {
        if (item.name === user) {
          return {
            ...item,
            status: event?.target?.checked,
          };
        }
        return item;
      });
      return newState;
    });
  };

  const handleAllCheckBoxChange = (event: any) => {
    const allDimension = ischeckedItemsChecked?.map((item: any) => {
      return {
        name: item?.name,
        status: event.target.checked,
        search: true,
      };
    });
    setCheckedItemsChecked(allDimension);
  };

  const getFilteredPayload = (filteredItems: any) => {
    const payload: any = [];
    if (blockDimensions && blockDimensions.dimensions.length > 0) {
      blockDimensions.dimensions.forEach((dimension: any) => {
        const filter = filteredItems.find((item: any) => item.dimId === dimension.id);
        const itemName = filter
          ? filter.filterItems.map((item: any) => item.name)
          : dimension.items;

        payload.push({
          dimId: dimension.id,
          name: dimension.name,
          items: itemName,
        });
      });
    }

    const filteredPayload = payload.map((object: any) => {
      const payloadObject: { [key: string]: any } = {};
      payloadObject[object.name] = object.items;
      return payloadObject;
    });
    dispatch(setChartFilterItems(filteredPayload));
    return filteredPayload;
  };

  useEffect(() => {
    if (multiDimFiltersItems && multiDimFiltersItems.length > 0 && isChartFilter) {
      const payload = getFilteredPayload(multiDimFiltersItems);
      dispatch(
        fetchIndicatorDetailType({
          currentDriver: indicator,
          parameters: {
            dim_id: chartFilterDimId,
            scenario_id: scenarioId,
            is_chart_show: true,
            filter_dim_items: JSON.stringify(payload),
          },
        }),
      ).then(() => {
        dispatch(
          fetchIndicatorDetail({
            currentDriver: indicator,
            parameters: {
              dim_id: chartFilterDimId,
              scenario_id: scenarioId,
              is_chart_show: true,
              filter_dim_items: JSON.stringify(payload),
            },
          }),
        );
      });
      setIsChartFilter(false);
    }
  }, [isChartFilter]);

  const handleApplyFilter = async () => {
    const result = ischeckedItemsChecked?.filter((item: any) => {
      return item?.status === true;
    });
    const newResult = result.map((item: any) => {
      return { ...item, search: true, status: true };
    });

    const dataItems = {
      id: itemsData?.id,
      resultedItems: ischeckedItemsChecked,
    };
    const filteredData = { dimId: dimensionId, filterItems: newResult };
    await dispatch(setFilterItems(dataItems));
    await dispatch(setMultiDimFilter(filteredData));

    setIsChartFilter(true);
    setIsMenuOpen(false);
    setSearchValue();
  };

  const ClearAllFilter = () => {
    setIsMenuOpen(false);
    setSearchValue('');
  };

  return (
    <Stack minW={'250px'} maxW={'250px'} position={'relative'}>
      <Box position={'sticky'} top={'0'} backgroundColor={'white'}>
        <Flex px={5}>
          <DimensionTabIcon width={7} height={6} color={'#4BB6B9'} />
          <Text variant={'primary_sub_heading'}>{itemsData?.name}</Text>
        </Flex>
        <VStack px={5} align={'start'}>
          <Input
            _active={{ bg: '#fff' }}
            mt={3}
            mb={2}
            placeholder='Search'
            height={7}
            fontSize={'14px'}
            value={searchValue}
            onChange={(event: any) => setSearchValue(event.target.value)}
          />
          <Flex ms={2}>
            {!searchValue && (
              <Checkbox
                variant={'filters'}
                className='filters-all-checkbox'
                isChecked={allChecked}
                isIndeterminate={isIndeterminate}
                onChange={handleAllCheckBoxChange}
              >
                Select all
              </Checkbox>
            )}
          </Flex>
        </VStack>
      </Box>
      <Stack
        maxH={'150px'}
        overflowX={'auto'}
        className={'filters_scroll'}
        w={'200px'}
        alignSelf={'center'}
      >
        {ischeckedItemsChecked?.map((user: any, index: number) => (
          <React.Fragment key={index}>
            {user?.search && (
              <Checkbox
                pl={6}
                variant={'filters'}
                isChecked={ischeckedItemsChecked[index]?.status}
                onChange={(event) => {
                  handleCheckBoxChange(user?.name, event);
                }}
              >
                <Text maxW={'100px'} className='text-overflow' size={'Roboto-14'}>
                  {user?.name}
                </Text>
              </Checkbox>
            )}
          </React.Fragment>
        ))}
      </Stack>
      <Flex justifyContent={'flex-end'} pt={2} pr={3}>
        <Button variant={'cancel_button'} mr={3} onClick={ClearAllFilter}>
          Cancel
        </Button>
        <Button
          onClick={handleApplyFilter}
          variant={'save_button'}
          disabled={!isIndeterminate && !allChecked}
        >
          Apply
        </Button>
      </Flex>
    </Stack>
  );
};

export default MultiSelectTableFilters;
