/* eslint-disable class-methods-use-this */
import * as React from 'react';
import {
  CellTemplate,
  Cell,
  Compatible,
  Uncertain,
  UncertainCompatible,
  Id,
  CellStyle,
  keyCodes,
  getCellProperty,
  getCharFromKeyCode,
  isNavigationKey,
  isAlphaNumericKey,
} from '@silevis/reactgrid';
import { Box, Flex, Tooltip } from '@chakra-ui/react';
import { InfoOutlineIcon } from '@chakra-ui/icons';

import ChevronDownIconComponent from 'components/Icons/ChevronDownIcon';
import CalculationIconComponent from 'components/Icons/CalculationIcon';
import InputIconComponent from 'components/Icons/InputIcon';
import { FORMULA_ERROR } from 'projectConstants';

export interface ChevronCell extends Cell {
  type: 'chevron';
  text: string;
  isExpanded?: boolean;
  hasChildren?: boolean;
  parentId?: Id;
  indent?: number;
  indicatorType?: string;
  hasFormulaError?: boolean;
}

export class ChevronCellTemplate implements CellTemplate<ChevronCell> {
  getCompatibleCell(uncertainCell: Uncertain<ChevronCell>): Compatible<ChevronCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    let isExpanded = false;
    try {
      isExpanded = getCellProperty(uncertainCell, 'isExpanded', 'boolean');
    } catch {
      isExpanded = true;
    }
    let indent = -1;
    try {
      indent = getCellProperty(uncertainCell, 'indent', 'number');
    } catch {
      indent = 0;
    }
    let hasChildren = false;
    try {
      hasChildren = getCellProperty(uncertainCell, 'hasChildren', 'boolean');
    } catch {
      hasChildren = false;
    }
    const value = Number.parseFloat(text);
    return { ...uncertainCell, text, value, isExpanded, hasChildren, indent };
  }

  update(
    cell: Compatible<ChevronCell>,
    cellToMerge: UncertainCompatible<ChevronCell>,
  ): Compatible<ChevronCell> {
    return this.getCompatibleCell({
      ...cell,
      isExpanded: cellToMerge.isExpanded,
      text: cellToMerge.text,
    });
  }

  handleKeyDown(
    cell: Compatible<ChevronCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
  ): { cell: Compatible<ChevronCell>; enableEditMode: boolean } {
    let enableEditMode = keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER;
    const cellCopy = { ...cell };
    const char = getCharFromKeyCode(keyCode, shift);
    if (keyCode === keyCodes.SPACE && cellCopy.isExpanded !== undefined && !shift) {
      cellCopy.isExpanded = !cellCopy.isExpanded;
    } else if (
      !ctrl &&
      !alt &&
      isAlphaNumericKey(keyCode) &&
      !(shift && keyCode === keyCodes.SPACE)
    ) {
      cellCopy.text = !shift ? char.toLowerCase() : char;
      enableEditMode = true;
    }
    return { cell: cellCopy, enableEditMode };
  }

  getClassName(cell: Compatible<ChevronCell>) {
    const isExpanded = cell.hasChildren ? (cell.isExpanded ? 'expanded' : 'collapsed') : '';
    const className = cell.className ?? '';
    return `${isExpanded} ${className}`;
  }

  getStyle(cell: Compatible<ChevronCell>): CellStyle {
    const indent = cell.indent ?? 0;
    const elementMarginMultiplier = indent * 1.4;
    return { paddingLeft: `calc(${elementMarginMultiplier}em + 2px)` };
  }

  render(
    cell: Compatible<ChevronCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<ChevronCell>, commit: boolean) => void,
  ): React.ReactNode {
    return !isInEditMode ? (
      <Flex alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
        <Flex
          alignItems={'center'}
          width={cell?.hasFormulaError ? 'calc(100% - 45px)' : 'calc(100% - 20px)'}
        >
          {!cell.parentId && (
            <Box mx={2}>
              {(cell?.indicatorType === 'calculation' || cell?.indicatorType === 'Calculation') && (
                <CalculationIconComponent color='#4BB6B9' />
              )}
              {(cell?.indicatorType === 'Input' || cell?.indicatorType === 'input') && (
                <InputIconComponent color='#9C89DD' />
              )}
            </Box>
          )}
          <Tooltip label={cell.text}>
            <p style={{ paddingLeft: '.5rem', paddingRight: '.5rem' }}>{cell.text}</p>
          </Tooltip>
        </Flex>
        {cell.hasChildren ? (
          <>
            {cell?.hasFormulaError && (
              <Box mr={2} maxW={'max-content'} color={'red'} fontSize={'2xs'} position={'relative'}>
                <Tooltip label={FORMULA_ERROR} placement={'top'} variant={'primary'}>
                  <InfoOutlineIcon height={5} width={5} />
                </Tooltip>
              </Box>
            )}
            <div
              className='chevron'
              onPointerDown={(event_) => {
                event_.stopPropagation();
                onCellChanged(
                  this.getCompatibleCell({ ...cell, isExpanded: !cell.isExpanded }),
                  true,
                );
              }}
            >
              <ChevronDownIconComponent height={22} width={22} direction={true} />
            </div>
          </>
        ) : (
          <div className='no-child' />
        )}
      </Flex>
    ) : (
      <input
        ref={(input) => {
          if (input) {
            input.focus();
            input.setSelectionRange(input.value.length, input.value.length);
          }
        }}
        defaultValue={cell.text}
        onChange={(event) =>
          onCellChanged(this.getCompatibleCell({ ...cell, text: event.currentTarget.value }), false)
        }
        onBlur={(event) =>
          onCellChanged(this.getCompatibleCell({ ...cell, text: event.currentTarget.value }), true)
        }
        onCopy={(event) => event.stopPropagation()}
        onCut={(event) => event.stopPropagation()}
        onPaste={(event) => event.stopPropagation()}
        onPointerDown={(event) => event.stopPropagation()}
        onKeyDown={(event) => {
          if (isAlphaNumericKey(event.keyCode) || isNavigationKey(event.keyCode))
            event.stopPropagation();
        }}
      />
    );
  }
}
