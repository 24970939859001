import { Box, Button, Flex, Image, Text, useMediaQuery, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import FormField from 'components/FormField';
import OrganizationSchema from 'validation/schemas/OrganisationSchema';
import UserBorderIcon from 'components/Icons/UserBorderIcon';
import {
  FetchUserOrganizationDetails,
  getUserData,
  getUserOrganizationData,
  SetTabs,
  UpdateUserOrganizationDetails,
} from 'redux/UserSlice';
import { GetOrgDataResponse, UpdateUserOrgDetailRequestBody } from 'models/UsersSlice.interface';
import { hasUserPermission } from 'utils/UserPermissions';
import Pencil from 'components/Icons/Pencil';

import UserImage from './UserImage';
import UserHeading from './UserHeading';
import UserFooter from './UserFooter';
import UserShimmer from './UserManagementShimmer';

const OrganizationForm = () => {
  const dispatch: any = useDispatch();

  const userData = useSelector(getUserData);
  const userOrganizationData: GetOrgDataResponse = useSelector(getUserOrganizationData);

  const [isLaptop] = useMediaQuery('(max-width: 1024px)');
  const [isSmallLaptop] = useMediaQuery('(max-width: 768px)');
  const [loader, setLoader] = useState(true);
  const [imageLoading, setImageLoading] = useState(false);
  const [editUniqueName, setEditUniqueName] = useState(false);
  const userPermission = hasUserPermission(userData?.role, ['edit']);

  const fieldStyle = {
    labelStyle: {
      fontSize: '15px',
      color: '#212121',
    },

    inputStyle: {
      maxWidth: '100%',
      boxShadow: '0px 0px 0px 1.5px #8B8AAE',
      height: '35px',
      paddingInlineStart: '8px',
    },
  };

  useEffect(() => {
    const getUserOrgDetails = async () => {
      await dispatch(FetchUserOrganizationDetails());
      setLoader(false);
    };
    getUserOrgDetails();
  }, []);

  const getChangedValue = (formValue: any) => {
    const requestBody: UpdateUserOrgDetailRequestBody = {};
    if (formValue.companyName !== userOrganizationData.company) {
      requestBody.company = formValue.companyName;
    }
    if (formValue.industry !== userOrganizationData.industry) {
      requestBody.industry = formValue.industry;
    }
    if (formValue.imageUrl !== userOrganizationData.logo_url) {
      requestBody.logo_url = formValue.imageUrl;
    }
    if (formValue.website !== userOrganizationData.website) {
      requestBody.website = formValue.website;
    }
    if (formValue.uniqueName !== userOrganizationData.unique_name) {
      requestBody.unique_name = formValue.uniqueName;
    }
    return {
      request_body: requestBody,
      toaster: true,
    };
  };

  const handleSubmit = async (formValue: any, setSubmitting: (data: boolean) => void) => {
    const payload = getChangedValue(formValue);
    if (Object.keys(payload.request_body).length > 0) {
      dispatch(UpdateUserOrganizationDetails(payload));
    }
    setSubmitting(false);
  };

  const handleImageUpload = async (data: any) => {
    dispatch(
      UpdateUserOrganizationDetails({
        request_body: {
          logo_url: data,
        },
        toaster: {
          successMessage: 'Image Uploaded Successfully',
        },
      }),
    );
  };

  const handleImageDelete = async () => {
    dispatch(
      UpdateUserOrganizationDetails({
        request_body: {
          logo_url: '',
        },
        toaster: {
          successMessage: 'Image Deleted Successfully',
        },
      }),
    );
  };

  if (loader) return <UserShimmer />;

  return (
    <Flex width={'100%'} height={'100%'} flexDir='column'>
      <Formik
        initialValues={{
          orgId: userOrganizationData.id || 0,
          companyName: userOrganizationData.company || '',
          industry: userOrganizationData.industry || '',
          website: userOrganizationData.website || '',
          imageUrl: userOrganizationData.logo_url || '',
          uniqueName: userOrganizationData.unique_name || '',
          orgOwner: userOrganizationData.owner || { name: '', date_created: '' },
        }}
        onSubmit={(form, { setSubmitting }) => {
          handleSubmit(form, setSubmitting);
        }}
        validationSchema={OrganizationSchema}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => {
          return (
            <Form>
              <Box width={'100%'} height={'100%'}>
                <UserHeading heading='Organisation' />
                <Flex height={`calc(100% - 63px)`} width={'100%'} flexDir={'column'}>
                  <Box
                    width={'100%'}
                    height={'100%'}
                    position='relative'
                    overflow={'hidden'}
                    overflowY={'auto'}
                  >
                    <Flex width={'100%'} flexDir={isLaptop ? 'column-reverse' : 'row'} p='2'>
                      <Box width={isLaptop ? '100%' : '70%'}>
                        <VStack
                          spacing='5'
                          alignItems={'flex-start'}
                          width={'100%'}
                          fontFamily='regular_roboto'
                        >
                          <Flex
                            alignItems={'flex-start'}
                            gap='5'
                            width={'100%'}
                            flexDir={isSmallLaptop ? 'column' : 'row'}
                          >
                            <Box width='100%'>
                              {editUniqueName && userData?.role === 'owner' ? (
                                <Flex alignItems={'end'}>
                                  <FormField
                                    label='Organization ID'
                                    name='uniqueName'
                                    type='text'
                                    labelStyles={fieldStyle.labelStyle}
                                    inputStyles={fieldStyle.inputStyle}
                                    placeholder='Enter company name'
                                    error={errors.uniqueName}
                                    touched={touched.uniqueName}
                                    disabled={!userPermission || isSubmitting}
                                  />
                                  <Pencil
                                    style={{ margin: '0 0 .7rem .5rem', cursor: 'pointer' }}
                                    onClick={() => setEditUniqueName(!editUniqueName)}
                                    color='grey.100'
                                  />
                                </Flex>
                              ) : (
                                <>
                                  <Text mb='2' fontSize='15px'>
                                    Organisation ID
                                  </Text>
                                  <Flex alignItems={'center'}>
                                    <Text
                                      width={'fit-content'}
                                      color={'grey.100'}
                                      fontSize='15px'
                                      bgColor={'white.200'}
                                      padding={'.2rem 1rem'}
                                      borderRadius={'10px'}
                                    >
                                      {userOrganizationData.unique_name}
                                    </Text>
                                    {userData?.role === 'owner' && (
                                      <Pencil
                                        style={{ margin: '0 0 0rem .5rem', cursor: 'pointer' }}
                                        onClick={() => setEditUniqueName(!editUniqueName)}
                                        color='grey.100'
                                      />
                                    )}
                                  </Flex>
                                </>
                              )}
                            </Box>
                            <FormField
                              label='Company name'
                              name='companyName'
                              type='text'
                              labelStyles={fieldStyle.labelStyle}
                              inputStyles={fieldStyle.inputStyle}
                              placeholder='Enter company name'
                              error={errors.companyName}
                              touched={touched.companyName}
                              disabled={!userPermission || isSubmitting}
                            />
                          </Flex>
                          <Flex
                            alignItems={'flex-start'}
                            gap='5'
                            width={'100%'}
                            flexDir={isSmallLaptop ? 'column' : 'row'}
                          >
                            <FormField
                              label='Industry'
                              name='industry'
                              type='text'
                              labelStyles={fieldStyle.labelStyle}
                              inputStyles={fieldStyle.inputStyle}
                              placeholder='Enter industry name'
                              error={errors.industry}
                              touched={touched.industry}
                              disabled={!userPermission || isSubmitting}
                            />
                            <FormField
                              label='Website'
                              name='website'
                              type='text'
                              labelStyles={fieldStyle.labelStyle}
                              inputStyles={fieldStyle.inputStyle}
                              placeholder='Enter website name'
                              error={errors.website}
                              touched={touched.website}
                              disabled={!userPermission || isSubmitting}
                            />
                          </Flex>
                          <Box mt='5'>
                            <Text color='#212121' mb='2' fontSize='15px'>
                              Org Owner
                            </Text>
                            <Flex
                              alignItems={'baseline'}
                              flexDir={isSmallLaptop ? 'column' : 'row'}
                            >
                              <Flex
                                minW='250px'
                                padding='.7rem'
                                borderRadius='10px'
                                boxShadow='0px 0px 0px 1.5px #8B8AAE'
                                alignItems='center'
                              >
                                {userOrganizationData.owner.profile_image_url ? (
                                  <Image
                                    boxShadow={'1px 1px 10px #d9d9d9'}
                                    src={userOrganizationData.owner.profile_image_url}
                                    borderRadius={'50%'}
                                    height={12}
                                    width={12}
                                  />
                                ) : (
                                  <UserBorderIcon height={12} width={12} />
                                )}
                                <Box ml='4'>
                                  <Text color='black' fontWeight='600'>
                                    {values.orgOwner.name}
                                  </Text>
                                  <Text fontSize='xs' color='grey.100'>
                                    Created : {values.orgOwner.date_created}
                                  </Text>
                                </Box>
                              </Flex>
                              {userData?.role === 'owner' && (
                                <Button
                                  cursor='pointer'
                                  onClick={() => {
                                    dispatch(SetTabs(9));
                                  }}
                                  width='fit-content'
                                  mt={isSmallLaptop ? '2' : ''}
                                  ml={isSmallLaptop ? '0' : '4'}
                                  p='0'
                                  color='green.700'
                                  disabled={!userPermission}
                                >
                                  Modify owner
                                </Button>
                              )}
                            </Flex>
                          </Box>
                        </VStack>
                      </Box>
                      <UserImage
                        type='organisation'
                        disabled={!userPermission || isSubmitting}
                        bucketName='blox-org-profile'
                        imageUrl={values.imageUrl}
                        setFormValue={(value: string) => {
                          setFieldValue('imageUrl', value);
                        }}
                        imageLoading={imageLoading}
                        handleImageLoading={(value: boolean, data?: any) => {
                          setImageLoading(value);
                          if (data) {
                            handleImageUpload(data);
                          }
                        }}
                        handleImageDelete={() => {
                          setFieldValue('imageUrl', '');
                          handleImageDelete();
                        }}
                      />
                    </Flex>
                  </Box>
                  <UserFooter
                    isSubmitting={isSubmitting}
                    disabled={
                      imageLoading ||
                      Object.keys(errors).length > 0 ||
                      !userPermission ||
                      isSubmitting
                    }
                  />
                </Flex>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
};

export default OrganizationForm;
