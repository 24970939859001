import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const Adjustment = ({ width = 5, height = 5, color = 'green.900' }: Properties) => {
  return (
    <Icon viewBox='0 0 39 39' width={width} height={height} color={color}>
      <path
        d='M0 30.3333V34.6667H13V30.3333H0ZM0 4.33333V8.66667H21.6667V4.33333H0ZM21.6667 39V34.6667H39V30.3333H21.6667V26H17.3333V39H21.6667ZM8.66667 13V17.3333H0V21.6667H8.66667V26H13V13H8.66667ZM39 21.6667V17.3333H17.3333V21.6667H39ZM26 13H30.3333V8.66667H39V4.33333H30.3333V0H26V13Z'
        fill='currentColor'
      />
    </Icon>
  );
};

export default Adjustment;
