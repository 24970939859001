import { CheckIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
  Box,
  Divider,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
  useBoolean,
  useMediaQuery,
  useOutsideClick,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import BranchIcon from 'components/Icons/BranchIcon';
import { Scenario } from 'models/response/scenario.interface';
import {
  DeleteScenarios,
  DuplicateScenarios,
  FetchScenarios,
  RenameScenario,
  getAllScenarios,
  getBaseScenario,
  selectBaseScenario,
} from 'redux/BuilderModeSlice';
import ScenarioPermission from 'pages/PermissionComponent/ScenarioPermission';
import { getUserSubscriptionLimits } from 'redux/UserSlice';
import PlanPermission from 'container/PlanPermission';
import InfoIconComponent from 'components/Icons/InfoIcon';
import DeleteModal from 'components/Modals/DeleteModal';
import { selectBaseScenario as selectBuilderModeScenario } from 'redux/PlannerModeSlice';
import { selectBaseScenario as selectPlannerModeScenario } from 'redux/ScenarioSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

import ScenarioFormForecast from './ScenarioFormForecast';

interface BaseScenarioState {
  planPermission: boolean;
  renameScenarioApi: any;
  getAllscenarios: any;
  deleteScenario: any;
  selectedDropdown?: number | any;
  duplicateScenario: any;
  seletctBaseScenario: (scenario: Scenario) => void;
}
const ScenarioForecast = ({
  planPermission,
  getAllscenarios,
  deleteScenario,
  duplicateScenario,
  seletctBaseScenario,
}: BaseScenarioState) => {
  const dispatch: AppDispatch = useDispatch();

  const { modelId } = useParams();
  const scenarios = useSelector(getAllScenarios);
  const baseScenario = useSelector(getBaseScenario);
  const [isMobile] = useMediaQuery('(max-width: 681px)');
  const [scenariosPopover, setScenariosPopover] = useBoolean();
  const [addScenarioPopover, setAddScenarioPopover] = useBoolean();
  const [isPermissionComponent, setIsPermissionComponent] = useState(false);
  const [displayInfo, setDisplayInfo]: any = useState(null);
  const [renameItemId, setRenameItemId] = useState<number | null>(null);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteIdModal, setDeleteIdModal]: any = useState(null);
  const [DeleteNameModal, setDeleteNameModal] = useState('');
  const UserSubscriptionLimitData = useSelector(getUserSubscriptionLimits);
  const [editScenarioData, setEditScenarioData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (baseScenario) {
      const base = scenarios.find((scenario: Scenario) => {
        return scenario.id === baseScenario.id;
      });

      if (base) {
        dispatch(selectBaseScenario(base));
      } else if (scenarios.length > 0) {
        const baseSec = scenarios.find((scenario: Scenario) => {
          return scenario.is_base;
        });

        dispatch(selectBaseScenario(baseSec));
      }
    }
  }, [scenarios]);

  const reference = useRef() as React.MutableRefObject<HTMLInputElement>;

  const toggleInfoPopup = (event: any, id: number | null) => {
    event.stopPropagation();
    if (displayInfo === id) {
      return setDisplayInfo(null);
    }
    return setDisplayInfo(id);
  };

  useOutsideClick({
    ref: reference,
    handler: () => {
      setDisplayInfo(null);
      setRenameItemId(0);
      setAddScenarioPopover.off();
      setScenariosPopover.off();
      setIsPermissionComponent(false);
      setEditScenarioData(null);
      setIsEdit(false);
    },
  });

  const renameItem = (event: any, scenario: any) => {
    event.stopPropagation();
    setDisplayInfo(null);
    if (renameItemId === scenario?.id) {
      setRenameItemId(0);
      setEditScenarioData(null);
      setIsEdit(false);
    }
    setRenameItemId(scenario?.id);
    setAddScenarioPopover.on();
    setEditScenarioData(scenario);
    setIsEdit(true);
  };

  const copyScenario = async (event: any, item: any) => {
    event.stopPropagation();
    const requestBody = {
      scenarioId: item?.id,
      data: {
        name: `${item?.name} - Copy`,
      },
      toaster: {
        successMessage: 'Successfully duplicated scenario',
        errorMessage: 'Failed to duplicate',
      },
    };

    await duplicateScenario(requestBody);
    await getAllscenarios(modelId);
  };

  const deleteScenarioModal = async (event: any, id: number, name: any) => {
    event.stopPropagation();
    setIsDeleteModal(true);
    setDeleteIdModal(id);
    setDeleteNameModal(name);
  };

  const onDeleteModel = async () => {
    await deleteScenario(deleteIdModal);
    await getAllscenarios(modelId);
    await setIsDeleteModal(false);
    await setDeleteIdModal(null);
    await setDeleteNameModal('');
    if (baseScenario?.id === deleteIdModal) {
      const randomScenario = scenarios.find((scenario: Scenario) => {
        return scenario?.id !== deleteIdModal;
      });
      if (randomScenario) {
        dispatch(selectBaseScenario(randomScenario));
      }
    }
  };

  const selectScenario = async (scenario: Scenario) => {
    seletctBaseScenario(scenario);
    dispatch(selectBuilderModeScenario(scenario));
    dispatch(selectPlannerModeScenario(scenario));
  };

  const handleCancel = () => {
    setAddScenarioPopover.off();
    setEditScenarioData(null);
    setIsEdit(false);
  };

  const scenariosLength = scenarios?.length ?? 0;

  const OpenPopover = () => {
    if (
      scenarios?.length ===
        UserSubscriptionLimitData?.subscription_features_limit?.scenario_per_model ||
      scenarios?.length >=
        UserSubscriptionLimitData?.subscription_features_limit?.scenario_per_model
    ) {
      setAddScenarioPopover.off();
      setIsPermissionComponent(true);
    } else if (planPermission === true) {
      setAddScenarioPopover.off();
    } else {
      setIsPermissionComponent(false);
      setAddScenarioPopover.on();
      setDisplayInfo(null);
    }

    setEditScenarioData(null);
    setIsEdit(false);
  };

  useEffect(() => {
    setIsPermissionComponent(false);
  }, [UserSubscriptionLimitData?.subscription_features_limit?.scenario_per_model]);

  return (
    <>
      <Stack width={'300px'}>
        {scenarios?.length > 0 && (
          <Popover
            placement='bottom-start'
            closeOnBlur={false}
            isOpen={scenariosPopover}
            onOpen={setScenariosPopover.on}
            onClose={setScenariosPopover.off}
            isLazy
            lazyBehavior='keepMounted'
          >
            <Box ref={reference}>
              <PopoverTrigger>
                <Flex
                  textAlign='left'
                  alignSelf='left'
                  w='100%'
                  minH={'35px'}
                  bgColor='#7163D0'
                  p='0 14px'
                  borderRadius={'14px'}
                  filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))'
                  transition='all 0.3s ease-out'
                  justifyContent='space-between'
                  alignItems='center'
                  cursor='pointer'
                >
                  <Flex width='80%' alignItems='center'>
                    <BranchIcon height={7} width={7} />
                    <Tooltip label={baseScenario?.name}>
                      <Text
                        alignContent='center'
                        color='white'
                        fontSize='xs'
                        whiteSpace='nowrap'
                        overflow='hidden'
                        textOverflow={'ellipsis'}
                        ml='2'
                      >
                        {baseScenario?.name}
                      </Text>
                    </Tooltip>
                  </Flex>
                  <ChevronRightIcon boxSize={{ base: 6 }} color='white' />
                </Flex>
              </PopoverTrigger>

              <PopoverContent
                ml={{ base: '5', md: '3' }}
                filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))'
                bg='white'
                border='none'
                maxWidth='80vw'
                borderRadius='14px'
              >
                <Box pt={4} pb={1} px={5}>
                  <Text color='#6562CF' fontSize={'xs'} fontWeight={'600'}>
                    Select Scenario
                  </Text>
                </Box>
                <PopoverBody>
                  <Flex direction={'column'}>
                    {scenarios?.length > 0 &&
                      scenarios.map((scenario: Scenario, index: number) => {
                        return (
                          <Flex
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            key={scenario.id}
                            cursor='pointer'
                            borderRadius='14px'
                            px='1'
                            py='2'
                            onClick={(event) => {
                              event.stopPropagation();
                              selectScenario(scenario);
                              setScenariosPopover.off();
                            }}
                            bg={baseScenario?.id === scenario?.id ? '#EFEEFE' : 'transparent'}
                            onDoubleClick={(event) => {
                              event.stopPropagation();
                              setRenameItemId(scenario?.id);
                            }}
                          >
                            <Box
                              display={'flex'}
                              color={'#8B89B1'}
                              alignItems={'center'}
                              w={'70%'}
                              onClick={() => setDisplayInfo(null)}
                            >
                              {baseScenario?.id === scenario?.id && <CheckIcon />}
                              <Text
                                px='2'
                                py='0'
                                color='black'
                                fontSize={'xs'}
                                whiteSpace='nowrap'
                                overflow='hidden'
                                textOverflow={'ellipsis'}
                              >
                                {scenario?.name}
                              </Text>
                            </Box>
                            <Flex alignItems='center' position='relative'>
                              <Flex
                                w='25px'
                                height='25px'
                                mr='15px'
                                alignItems='center'
                                cursor={planPermission ? 'not-allowed' : 'pointer'}
                                onClick={
                                  planPermission
                                    ? undefined
                                    : (event) => toggleInfoPopup(event, scenario.id)
                                }
                              >
                                <InfoIconComponent width='20px' height='8px' />
                              </Flex>
                              <Flex
                                bgColor='white'
                                width='fit-content'
                                position='absolute'
                                display={displayInfo === scenario.id ? 'block' : 'none'}
                                zIndex='dropdown'
                                top={
                                  scenariosLength > 3 && index >= scenariosLength - 3
                                    ? '-100px'
                                    : '28px'
                                }
                                right='0'
                                p='5px 0'
                                filter='drop-shadow(0px 0px 15.998px rgba(0, 0, 0, 0.25))'
                                borderRadius='4px'
                              >
                                <Box
                                  p='5px 15px'
                                  _hover={{
                                    background: '#EEF2F6',
                                    color: 'black',
                                  }}
                                >
                                  <Text
                                    color='black'
                                    fontSize={'xs'}
                                    onClick={
                                      planPermission
                                        ? undefined
                                        : (event) => renameItem(event, scenario)
                                    }
                                  >
                                    Edit
                                  </Text>
                                </Box>
                                <Box
                                  p='5px 15px'
                                  _hover={{
                                    background: '#EEF2F6',
                                    color: 'black',
                                  }}
                                >
                                  <Text
                                    color='black'
                                    fontSize={'xs'}
                                    onClick={
                                      planPermission
                                        ? undefined
                                        : (event) => copyScenario(event, scenario)
                                    }
                                  >
                                    Duplicate
                                  </Text>
                                </Box>
                                {Number(scenarios?.length) !== 1 && (
                                  <Box
                                    p='5px 15px'
                                    _hover={{
                                      background: '#EEF2F6',
                                      color: 'black',
                                    }}
                                  >
                                    <Text
                                      color='black'
                                      fontSize={'xs'}
                                      onClick={
                                        planPermission
                                          ? undefined
                                          : (event) => {
                                              deleteScenarioModal(
                                                event,
                                                scenario?.id,
                                                scenario?.name,
                                              );
                                            }
                                      }
                                    >
                                      Delete
                                    </Text>
                                  </Box>
                                )}
                              </Flex>
                            </Flex>
                          </Flex>
                        );
                      })}
                  </Flex>
                  <Divider orientation='horizontal' mt={'2'} />
                  <Popover
                    placement={isMobile ? 'bottom' : 'right-start'}
                    closeOnBlur={false}
                    isOpen={addScenarioPopover}
                    onOpen={planPermission ? undefined : OpenPopover}
                    onClose={setAddScenarioPopover.off}
                    isLazy
                    lazyBehavior='keepMounted'
                  >
                    <PopoverTrigger>
                      <Text
                        mt={scenarios?.length > 0 ? '2' : '0'}
                        color='#6562CF'
                        fontSize={'xs'}
                        fontWeight={'600'}
                        px={2}
                        cursor={planPermission ? 'not-allowed' : 'pointer'}
                      >
                        + New Scenario
                      </Text>
                    </PopoverTrigger>
                    <PopoverContent
                      p={5}
                      filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))'
                      bg='white'
                      borderRadius='14px'
                    >
                      <ScenarioFormForecast
                        onCancel={handleCancel}
                        editScenarioData={editScenarioData}
                        isEdit={isEdit}
                      />
                    </PopoverContent>
                  </Popover>
                  {isPermissionComponent && (
                    <ScenarioPermission
                      ScenarioLimit={
                        UserSubscriptionLimitData?.subscription_features_limit?.scenario_per_model
                      }
                      CurrentScenarioLength={scenarios?.length}
                    />
                  )}
                </PopoverBody>
              </PopoverContent>
            </Box>
          </Popover>
        )}
      </Stack>
      <DeleteModal
        isOpen={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
        itemName={DeleteNameModal}
        onDelete={onDeleteModel}
      />
    </>
  );
};
const mapDispatchToProperties = (dispatch: any) => {
  return {
    getAllscenarios: (modelId: number) => dispatch(FetchScenarios(modelId)),
    renameScenarioApi: (data: any) => dispatch(RenameScenario(data)),
    duplicateScenario: (data: any) => dispatch(DuplicateScenarios(data)),
    deleteScenario: (scenarioId: number) => dispatch(DeleteScenarios(scenarioId)),
    seletctBaseScenario: (scenario: Scenario) => dispatch(selectBaseScenario(scenario)),
  };
};

export default connect(null, mapDispatchToProperties)(PlanPermission(ScenarioForecast));
