import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';

import whatNext from 'assets/what_next_icon.png';
import BackButton from 'components/Icons/BackIcon';

const ActionSection = () => {
  const navigate = useNavigate();

  const { modelId }: any = useParams();
  const onBackClick = () => {
    navigate(`/model/${modelId}/home`);
  };

  return (
    <Flex direction='column' align='center'>
      <Text fontSize={'20px'}>What next?</Text>
      <Box w={150} h={116} mb='8'>
        <Image src={whatNext} />
      </Box>

      <Text fontFamily='regular' fontSize='sm' lineHeight='5' align='center' mb='4.5'>
        Edit another block or <br /> View a summary block
      </Text>

      <Button
        width={'fit-content'}
        height={'fit-content'}
        padding={'.3rem 1rem'}
        leftIcon={<BackButton height={7} width={7} />}
        variant='plan_action_lg'
        onClick={onBackClick}
        mb='2.5'
        bgColor='green.900'
      >
        Back to overview
      </Button>
    </Flex>
  );
};

export default ActionSection;
