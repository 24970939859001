import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useMediaQuery,
  Flex,
  Box,
} from '@chakra-ui/react';
import { connect } from 'react-redux';

import Cross from 'components/Icons/Cross';
import { SetTabs, SetUserModalVisible } from 'redux/UserSlice';

import UserSidebar from './UserSidebar';
import ProfileForm from './ProfileForm';
import OrganisationForm from './OrganisationForm';
import UserTeamsComponent from './UserTeams';
import UserBillingComponent from './UserBilling';
import AddTeamMemberComponent from './AddTeamMember';
import UserPlansComponent from './UserPlans';

interface Properties {
  userTabs: number;
  isUserModalVisible: boolean;
  setUserModalVisible: (status: boolean) => void;
  setUserTabs: (value: number) => void;
}

const UserModal = ({
  userTabs,
  isUserModalVisible,
  setUserTabs,
  setUserModalVisible,
}: Properties) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isLaptop] = useMediaQuery('(max-width: 1024px)');

  return (
    <Modal
      isOpen={isUserModalVisible}
      onClose={() => {
        setUserTabs(1);
        setUserModalVisible(false);
      }}
    >
      <ModalOverlay bg='none' backdropFilter='auto' backdropInvert='3%' backdropBlur='4px' />
      <ModalContent
        color='black.900'
        padding={isLaptop ? '2rem 2rem 2rem 1rem' : '2rem 1rem 2rem 1rem'}
        width={isMobile ? '90%' : { base: '700px', sm: '800px', '4xl': '1500px' }}
        maxWidth={'90%'}
        height='80%'
      >
        <ModalBody padding='0' height={'100%'}>
          <Flex width={'100%'} height={'100%'}>
            {!isMobile && (
              <Box width={'20%'} borderRight='1px #E8E8ED solid'>
                <UserSidebar />
              </Box>
            )}
            <Box width={!isMobile ? '80%' : '100%'} height={'100%'}>
              <Box width={'100%'} height={'100%'}>
                <Box position={'absolute'} top={'10px'} right='10px'>
                  <Cross
                    width={8}
                    height={8}
                    bgColor='black.900'
                    onClick={() => {
                      setUserTabs(1);
                      setUserModalVisible(false);
                    }}
                  />
                </Box>
                <Box paddingLeft={'1rem'} marginTop={'1rem'} height={'100%'}>
                  {userTabs === 1 ? (
                    <ProfileForm />
                  ) : userTabs === 2 ? (
                    <OrganisationForm />
                  ) : userTabs === 3 ? (
                    <UserTeamsComponent type={1} />
                  ) : userTabs === 4 ? (
                    <UserBillingComponent />
                  ) : userTabs === 5 ? (
                    <UserPlansComponent />
                  ) : userTabs === 8 ? (
                    <AddTeamMemberComponent />
                  ) : userTabs === 9 ? (
                    <UserTeamsComponent type={2} />
                  ) : (
                    ''
                  )}
                </Box>
              </Box>
            </Box>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const stateToProperty = (state: any) => {
  return {
    userTabs: state.userSlice.userTabs,
    isUserModalVisible: state.userSlice.isUserModalVisible,
  };
};

const mapDispatchToProperties = (dispatch: any) => {
  return {
    setUserTabs: (data: number) => dispatch(SetTabs(data)),
    setUserModalVisible: (data: any) => dispatch(SetUserModalVisible(data)),
  };
};
export default connect(stateToProperty, mapDispatchToProperties)(UserModal);
