import { Flex, Text } from '@chakra-ui/react';
import { useEffect, useLayoutEffect } from 'react';
import { useJwt } from 'react-jwt';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Container from 'components/Container';
import Logo from 'components/Icons/Logo';
import Spinner from 'components/Spinner';
import {
  fetchCreateGuestUser,
  fetchUserAcess,
  getExpiry,
  getModelId,
  getUserToken,
  setUserToken,
  setUserExpiry,
} from 'redux/shareModalSlice';
import { useAuthContext } from 'context/authContext';
import { AppDispatch } from 'utils/GlobalHelpers';

const RedirectionPage = () => {
  const navigate = useNavigate();
  const { onAuthSuccess } = useAuthContext();
  const { token, emailToken } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const { isExpired: isModelLinkExpired } = useJwt<{
    model_id: number;
    exp: number;
  }>(token ?? '');

  useEffect(() => {
    if (emailToken) {
      dispatch(setUserToken(emailToken));

      const expiryDate = new Date(new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).getTime());

      const year = expiryDate.getFullYear();
      const month = String(expiryDate.getMonth() + 1).padStart(2, '0');
      const day = String(expiryDate.getDate()).padStart(2, '0');
      const hours = String(expiryDate.getHours()).padStart(2, '0');
      const minutes = String(expiryDate.getMinutes()).padStart(2, '0');
      const expiryTime = `${year}-${month}-${day} ${hours}:${minutes}`;

      onAuthSuccess(emailToken, expiryTime);
      dispatch(setUserExpiry(expiryTime));
    }
  }, [emailToken]);

  const authToken = localStorage.getItem('BLOX_USER_TOKEN');
  const userToken = useSelector(getUserToken);
  const ModelId = useSelector(getModelId);
  const getExpiryTime = useSelector(getExpiry);

  useLayoutEffect(() => {
    document.title = 'Blox';
  }, []);

  useEffect(() => {
    if (userToken && token) {
      localStorage.setItem('BLOX_USER_TOKEN', userToken);
      localStorage.setItem('BLOX_USER_TOKEN_EXPIRY', `${new Date(getExpiryTime).getTime()}`);

      dispatch(fetchUserAcess({ request_body: { token } }));
    }
  }, [userToken, token]);
  useEffect(() => {
    if (authToken && token) {
      dispatch(fetchUserAcess({ request_body: { token } }));
    }
  }, [authToken, token]);

  useEffect(() => {
    if (ModelId) {
      navigate(`/model/${ModelId}/home`);
    }
  }, [ModelId]);

  useEffect(() => {
    if (!userToken && !authToken) {
      const { navigator } = window;
      dispatch(
        fetchCreateGuestUser({
          request_body: { ip_address: '', browser_version: navigator.userAgent },
        }),
      );
    }
  }, [userToken, authToken]);

  return (
    <Container>
      <Flex
        h='full'
        direction='column'
        alignItems='center'
        justifyContent='center'
        justify='center'
        align='center'
      >
        <Logo color='white.900' width='34vw' height='auto' />
        {isModelLinkExpired ? (
          <Text fontFamily='regular' fontSize='calc(.5rem + 1vw)' letterSpacing='-0.015em' mt={4}>
            Invalid Link or Link expired. Please try again...
          </Text>
        ) : (
          <>
            <Spinner styleProperties={{ my: '4vh' }} />
            <Text fontFamily='regular' fontSize='calc(.5rem + 1vw)' letterSpacing='-0.015em'>
              Wait we are redirecting you to your page...
            </Text>
          </>
        )}
      </Flex>
    </Container>
  );
};

export default RedirectionPage;
