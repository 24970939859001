interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const InputPanelIconComponent = ({ width = 30, height = 22, color }: Properties) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 19 19'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.09692 18.3555C1.54692 18.3555 1.07609 18.1596 0.684424 17.768C0.292757 17.3763 0.0969238 16.9055 0.0969238 16.3555V2.35547C0.0969238 1.80547 0.292757 1.33464 0.684424 0.942969C1.07609 0.551302 1.54692 0.355469 2.09692 0.355469H16.0969C16.6469 0.355469 17.1178 0.551302 17.5094 0.942969C17.9011 1.33464 18.0969 1.80547 18.0969 2.35547V16.3555C18.0969 16.9055 17.9011 17.3763 17.5094 17.768C17.1178 18.1596 16.6469 18.3555 16.0969 18.3555H2.09692ZM11.0969 16.3555V2.35547H2.09692V16.3555H11.0969Z'
        fill={color}
      />
    </svg>
  );
};

export default InputPanelIconComponent;
