/* eslint-disable no-underscore-dangle */
import { useCallback, useEffect, useState } from 'react';
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  MiniMap,
  useNodesState,
  useEdgesState,
  Node,
  Edge,
  Connection,
  Position,
} from 'react-flow-renderer';
import { Box } from '@chakra-ui/react';
import dagre from 'dagre'; // Assuming you are using React Flow types

import { CHART_COLORS } from 'projectConstants';

import MapIndicatorNode from '../MapIndicatorNode';
import MapControls from '../MapControls';

import palette from './palette';
import styles from './styles'; // Adjust according to your theme setup

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 100;
const nodeHeight = 35;

// Define types for nodes and edges, adjust according to your data model
interface LayoutedElements {
  nodes: Node[];
  edges: Edge[];
  layoutDirection: string;
}

const getLayoutedElements = (
  nodes: Node[],
  edges: Edge[],
  direction: string = 'LR',
): LayoutedElements => {
  const isHorizontal = direction === 'LR';
  dagreGraph.setGraph({ rankdir: direction });

  const layoutDirection = dagreGraph?.graph()?.rankdir ?? 'LR';

  nodes.forEach((node: Node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  edges.forEach((edge: Edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  nodes.forEach((node: Node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    node.targetPosition = isHorizontal ? Position.Left : Position.Top;
    node.sourcePosition = isHorizontal ? Position.Right : Position.Bottom;

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: nodeWithPosition.x + nodeWidth / 2,
      y: nodeWithPosition.y + nodeHeight,
    };

    return node;
  });

  return { nodes, edges, layoutDirection };
};

// Define a custom node type for your React Flow nodes
const nodeTypes = {
  indicator: MapIndicatorNode, // Assuming MapIndicatorNode is a valid React component
};

// Function to determine node stroke color based on type
const nodeStrokeColor = (node: Node): string => {
  switch (node.type) {
    case 'indicator':
      return palette.primary.main;
    default:
      return palette.secondary.main;
  }
};

export interface Properties {
  initialNodes: Node[];
  initialEdges: Edge[];
}

const Map = ({ initialNodes, initialEdges }: Properties) => {
  const [isDraggable, setIsDraggable] = useState(true);
  const [layoutDirection, setLayoutDirection] = useState<string>('LR');
  const [nodes, setNodes, onNodesChange] = useNodesState<Node[]>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge[]>([]);

  useEffect(() => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
      initialNodes,
      initialEdges,
    );
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, [initialEdges, initialNodes, setEdges, setNodes]);

  const onConnect = useCallback(
    (parameters: Connection) =>
      setEdges((eds) => addEdge({ ...parameters, type: 'smoothstep', animated: false }, eds)),
    [setEdges],
  );

  const onLayout = useCallback(
    (direction: string) => {
      const {
        nodes: layoutedNodes,
        edges: layoutedEdges,
        layoutDirection: layoutdirection,
      } = getLayoutedElements(nodes, edges, direction);

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
      setLayoutDirection(layoutdirection);
    },
    [nodes, edges, setNodes, setEdges],
  );

  return (
    <ReactFlowProvider>
      <Box sx={styles.mapContainer}>
        <ReactFlow
          style={{ position: 'relative', height: '100%' }}
          nodes={nodes.map((node, index) => ({
            ...node,
            data: { ...node.data, index, color: CHART_COLORS[index] },
            style: { border: CHART_COLORS[index] }, // Use the color from node's data
          }))}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodesDraggable={isDraggable}
          nodeTypes={nodeTypes}
          defaultZoom={1.5}
        />
        <MiniMap
          nodeStrokeColor={nodeStrokeColor}
          nodeStrokeWidth={4}
          maskColor={'#4b4d5057'}
          nodeBorderRadius={10}
          nodeColor={'white'}
        />
        <MapControls
          layoutDirection={layoutDirection}
          onLayout={onLayout}
          isDraggable={isDraggable}
          setIsDraggable={setIsDraggable}
        />
      </Box>
    </ReactFlowProvider>
  );
};

export default Map;
