import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiService from 'services';

export const fetchUserAcess = createAsyncThunk(
  'scenarios/fetch',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.create_model_access({ request_body });
      return response.data?.model_id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchCreateGuestUser = createAsyncThunk(
  'scenarios/guest',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.create_guest_user({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
const initialState: any = {
  userToken: null,
  modelId: null,
  expiry: null,
};

const shareModalSlice = createSlice({
  initialState,
  name: 'shareModalSlice',
  reducers: {
    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
    setUserExpiry: (state, action) => {
      state.expiry = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCreateGuestUser.fulfilled, (state, action) => {
      state.userToken = action.payload.token;
      state.expiry = action.payload.expiry;
    });
    builder.addCase(fetchUserAcess.fulfilled, (state, action) => {
      state.modelId = action.payload;
    });
  },
});
export const { setUserToken, setUserExpiry } = shareModalSlice.actions;

export const getUserToken = (state: any) => state.shareModalSlice.userToken;
export const getModelId = (state: any) => state.shareModalSlice.modelId;
export const getExpiry = (state: any) => state.shareModalSlice.expiry;

export default shareModalSlice.reducer;
