/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable class-methods-use-this */
import {
  Cell,
  CellTemplate,
  Compatible,
  getCellProperty,
  Uncertain,
  isAlphaNumericKey,
  keyCodes,
  UncertainCompatible,
  isNavigationKey,
} from '@silevis/reactgrid';
import { Flex, Menu, Text, MenuList, MenuButton, Box } from '@chakra-ui/react';
import { BiFilter } from 'react-icons/bi';
import { useState } from 'react';

import FiltersCheckBox from './Filters';

export interface SelectCell extends Cell {
  type: 'select';
  text: string;
  placeholder?: string;
  hasEdit: boolean;
  hasDelete: boolean;
}

const FiltersMenu = ({ cell, filterChanges }: any) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleFilterChanges = (data: any) => {
    filterChanges(data);
  };

  const handleCancelFilter = () => {
    setIsMenuOpen(false);
  };

  return (
    <Flex width={'100%'} height={'100%'} alignItems={'center'} justifyContent={'space-between'}>
      <Text>{cell.text}</Text>

      <Menu
        variant={'filter'}
        placement='bottom-end'
        strategy='fixed'
        isOpen={isMenuOpen}
        onOpen={() => {
          setIsMenuOpen(true);
        }}
        onClose={() => setIsMenuOpen(false)}
      >
        <>
          <MenuButton onClick={(event: any) => event?.stopPropagation()}>
            <Box>
              <BiFilter size={30} color={'rgb(190 218 218)'} />
            </Box>
          </MenuButton>

          <MenuList
            onCopy={(event) => event.stopPropagation()}
            onCut={(event) => event.stopPropagation()}
            onPaste={(event) => event.stopPropagation()}
            onPointerDown={(event) => event.stopPropagation()}
            onKeyDown={(event) => {
              if (isAlphaNumericKey(event.keyCode) || isNavigationKey(event.keyCode))
                event.stopPropagation();
            }}
          >
            <FiltersCheckBox
              checkboxData={cell}
              handleFilterChanges={handleFilterChanges}
              cancelFilter={handleCancelFilter}
            />
          </MenuList>
        </>
      </Menu>
    </Flex>
  );
};

export class FiltersCellTemplate implements CellTemplate<Cell | SelectCell> {
  onFilterChange: (data: any, cell: any) => void;

  constructor(onFilterChange: (data: any, cell: any) => void) {
    this.onFilterChange = onFilterChange;
  }

  getCompatibleCell(uncertainCell: Uncertain<SelectCell>): Compatible<SelectCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const hasEdit = getCellProperty(uncertainCell, 'hasEdit', 'boolean');
    const hasDelete = getCellProperty(uncertainCell, 'hasDelete', 'boolean');
    const value = Number.parseFloat(text);
    return { ...uncertainCell, text, value, hasEdit, hasDelete };
  }

  render(
    cell: Compatible<SelectCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<SelectCell>, commit: boolean) => void,
  ): JSX.Element {
    return (
      <>
        {isInEditMode && cell.hasEdit ? (
          <input
            disabled={cell.nonEditable}
            style={{
              backgroundColor: '#423c82',
              color: 'white',
            }}
            ref={(input) => {
              if (input) {
                input.focus();
                input.setSelectionRange(input?.value?.length, input?.value?.length);
              }
            }}
            defaultValue={cell.text}
            onChange={(event) =>
              onCellChanged(
                this.getCompatibleCell({ ...cell, text: event.currentTarget.value }),
                false,
              )
            }
            onBlur={(event) =>
              onCellChanged(
                this.getCompatibleCell({ ...cell, text: event.currentTarget.value }),
                (event as any).view?.event?.keyCode !== keyCodes.ESCAPE,
              )
            }
            onCopy={(event) => event.stopPropagation()}
            onCut={(event) => event.stopPropagation()}
            onPaste={(event) => event.stopPropagation()}
            onPointerDown={(event) => event.stopPropagation()}
            placeholder={cell.placeholder}
            onKeyDown={(event) => {
              if (isAlphaNumericKey(event.keyCode) || isNavigationKey(event.keyCode))
                event.stopPropagation();
            }}
          />
        ) : (
          <FiltersMenu cell={cell} filterChanges={(data: any) => this.onFilterChange(cell, data)} />
        )}
      </>
    );
  }

  handleKeyDown(
    cell: Compatible<SelectCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
  ): { cell: Compatible<SelectCell>; enableEditMode: boolean } {
    if (!ctrl && !alt && isAlphaNumericKey(keyCode)) return { cell, enableEditMode: true };
    return {
      cell,
      enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }

  update(
    cell: Compatible<SelectCell>,
    cellToMerge: UncertainCompatible<SelectCell>,
  ): Compatible<SelectCell> {
    return this.getCompatibleCell({ ...cell, text: cellToMerge.text });
  }
}
