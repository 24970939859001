import { Flex, Text, Image } from '@chakra-ui/react';
import { useLayoutEffect } from 'react';

import Container from 'components/Container';
import emptyData from 'assets/empty_data_icon.png';

const EmptyData = () => {
  useLayoutEffect(() => {
    document.title = 'Blox';
  }, []);

  return (
    <Container bgColor='transparent'>
      <Flex h='100%' direction='column' align='center' justify='start' paddingTop={20}>
        <Image src={emptyData} w={'15rem'} h={'15rem'} />

        <Flex direction='column' justify='center' align='center'>
          <Text
            mb='2.5'
            width='200px'
            fontFamily='regular_roboto'
            fontSize={{ base: 'xs', sm: 'sm' }}
            lineHeight='5'
            textAlign={'center'}
          >
            Select an ‘Input’ indicator
          </Text>
          <Text
            width='200px'
            fontFamily='regular_roboto'
            fontSize={{ base: 'xs', sm: 'sm' }}
            lineHeight='5'
            textAlign={'center'}
          >
            to update the input values
          </Text>
        </Flex>
      </Flex>
    </Container>
  );
};

export default EmptyData;
