interface Properties {
  width?: number;
  height?: number;
  color?: string;
  marginRight?: string;
}

const BarChartIcon = ({
  width = 18,
  height = 18,
  color = 'white.900',
  marginRight,
}: Properties) => {
  return (
    <svg
      style={{ marginRight }}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      color={color}
      viewBox='0 0 18 18'
      fill='none'
    >
      <path d='M4.5 5.625H0V18H4.5V5.625Z' fill='#787878' />
      <path d='M4.5 0H0V4.5H4.5V0Z' fill='#787878' />
      <path d='M11.25 3.375H6.75V7.875H11.25V3.375Z' fill='#787878' />
      <path d='M18 6.75H13.5V11.25H18V6.75Z' fill='#787878' />
      <path d='M18 12.375H13.5V18H18V12.375Z' fill='#787878' />
      <path d='M11.25 9H6.75V18H11.25V9Z' fill='#787878' />
    </svg>
  );
};

export default BarChartIcon;
