import { Image, StyleProps } from '@chakra-ui/react';

import setup from 'assets/setup_icon.png';

interface Properties {
  width?: number;
  height?: number;
  styles?: StyleProps;
}

const Setup = ({ width = 34, height = 27, styles }: Properties) => {
  return <Image src={setup} w={width} h={height} {...styles} />;
};

export default Setup;
