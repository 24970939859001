import { Align } from 'chart.js';

import { FormatProperties } from 'models/response/modelOutput.interface';

import formatValue from './FormatValues';

interface Properties {
  chartType?: string;
  isLegend?: boolean;
  pageWidth?: number;
  formatProperties?: FormatProperties;
  pageName?: string;
  aspectRatio?: number;
}

const getChartOptions = ({
  chartType = 'line',
  isLegend = false,
  pageWidth = 0,
  formatProperties,
  pageName = '',
  aspectRatio,
}: Properties) => {
  return {
    aspectRatio,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: { display: false },
      tooltip: {
        callbacks: {
          label(context: any) {
            const label = context.dataset.label || '';
            const value = context.formattedValue || '';

            if (formatProperties?.measure === '%') {
              return `${label}: ${value * 100}`;
            }
            return `${label}: ${value}`;
          },
        },
      },
      legend: {
        display: isLegend,
        position: 'top' as const,
        align: 'start' as Align,
        labels: {
          boxWidth: pageWidth < 576 ? 10 : 15,
          boxHeight: pageWidth < 576 ? 10 : 15,
          color: '#ffffff',
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: chartType === 'stacked bar',
        grid: {
          color: 'transparent',
          borderColor: '#ffffff66',
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
          color: 'white',
          padding: 10,
        },
      },
      y: {
        beginAtZero: true,
        stacked: chartType === 'stacked bar',
        grid: {
          color: '#ffffff33',
          borderColor: 'transparent',
          tickColor: 'transparent',
        },
        ticks: {
          color: '#cdcdcd66',
          padding: 0,
          callback(value: any) {
            if (pageName === 'drivers') {
              if (formatProperties?.measure === '%') {
                return `${(value * 100).toFixed(2)}%`;
              }
              return `${formatValue(value, formatProperties as FormatProperties)}`;
            }

            return value.toLocaleString();
          },
        },
      },
    },
  };
};

export default getChartOptions;
