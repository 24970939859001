import { Flex, Text } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FullScreenLoader from 'components/FullScreenLoader';
import Cross from 'components/Icons/Cross';
import { HEADER_HEIGHT_WITH_NAVIGATION, STATUS } from 'projectConstants';
import { fetchPropertyValue, getPropertyValueStatus } from 'redux/DimensionPageSlice';
import { closeInputSection, getSelectedDriver } from 'redux/PlanPageSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

import PropertyTable from './PropertyTable';

interface Properties {
  refetchRequest: () => void;
}
const DimesionPage = ({ refetchRequest }: Properties) => {
  const dispatch: AppDispatch = useDispatch();
  const currentDriver = useSelector(getSelectedDriver);
  const baseScenario = useSelector(getBaseScenario);
  useEffect(() => {
    dispatch(
      fetchPropertyValue({
        propertyId: currentDriver?.property_id,
        parameters: { scenario_id: baseScenario?.id },
      }),
    );
  }, [currentDriver, baseScenario?.id]);

  const isLoading = useSelector(getPropertyValueStatus);

  const closeSection = () => {
    dispatch(closeInputSection());
  };

  if (isLoading !== STATUS.SUCCESS) {
    return <FullScreenLoader />;
  }

  return (
    <Flex
      display='flex'
      flexDirection='column'
      overflowY='auto'
      className='scroll'
      h={`calc(100vh - ${HEADER_HEIGHT_WITH_NAVIGATION})`}
      width='100%'
      p={4}
    >
      <Flex justifyContent={'space-between'} mb='3'>
        <Text fontFamily={'regular_roboto'} size='assistant_md'>
          {currentDriver.property_name}
        </Text>
        <Cross height={'25px'} width={'25px'} onClick={closeSection} />
      </Flex>
      <Flex direction='column' pt='4' px='2' borderTopLeftRadius='base' borderTopRightRadius='base'>
        <PropertyTable
          propertyId={currentDriver.property_id}
          dimensionName={currentDriver.dim_name}
          dimensionId={currentDriver.dimension_id}
          propertyName={currentDriver.property_name}
          refetchRequest={refetchRequest}
        />
      </Flex>
    </Flex>
  );
};

export default DimesionPage;
