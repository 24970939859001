interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const OrganizationIcon = ({ width = 4.75, height = 4.75, color = 'white.900' }: Properties) => {
  return (
    <svg width={width} height={height} viewBox='0 0 25 26' xmlns='http://www.w3.org/2000/svg'>
      <mask id='mask0_21_250' maskUnits='userSpaceOnUse' x='0' y='0' width='25' height='26'>
        <rect x='0.454834' y='0.74128' width='24.4772' height='24.4772' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_21_250)'>
        <path
          d='M2.49463 22.1588V3.80092H12.6934V7.88045H22.8923V22.1588H2.49463ZM4.53439 20.119H10.6537V18.0793H4.53439V20.119ZM4.53439 16.0395H10.6537V13.9997H4.53439V16.0395ZM4.53439 11.96H10.6537V9.92021H4.53439V11.96ZM4.53439 7.88045H10.6537V5.84068H4.53439V7.88045ZM12.6934 20.119H20.8525V9.92021H12.6934V20.119ZM14.7332 13.9997V11.96H18.8127V13.9997H14.7332ZM14.7332 18.0793V16.0395H18.8127V18.0793H14.7332Z'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default OrganizationIcon;
