import { CSSObject } from '@emotion/react';

import colors from 'styles/theme/colors';
import sizes from 'styles/theme/sizes';

const datePickerStyle: CSSObject = {
  '& .react-datepicker-popper': {
    '& .react-datepicker': {
      width: sizes.full,
      boxShadow: '1px 1px 10px #6e6e6e',
      minWidth: '137px',
      borderRadius: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '& .react-datepicker__navigation': {
        top: sizes[2],

        '& .react-datepicker__navigation-icon:before': {
          borderColor: colors.black[900],
        },
      },
    },
    '.react-datepicker__triangle': {
      transform: 'none !important',
    },
    '& .react-datepicker__triangle::after': {
      left: '30px !important',
    },
    '& .react-datepicker__triangle::before': {
      left: '30px !important',
    },
  },

  '& .react-datepicker__header': {
    backgroundColor: colors.cyan[500],
    color: colors.white[900],
  },

  '& .react-datepicker__month-container': {
    width: sizes.full,

    '& .react-datepicker__month-wrapper': {
      display: 'flex',
      justifyContent: 'space-around',

      div: {
        padding: `${sizes[2]} ${sizes[4]}`,
        borderRadius: sizes[2],
        color: colors.black[900],
        fontWeight: 'normal',
        backgroundColor: 'transparent',
      },
    },

    '& .react-datepicker__month-wrapper div[aria-selected="true"]': {
      background: colors.cyan[100],
      color: colors.black[900],
    },
  },

  '& .react-datepicker__year': {
    '& .react-datepicker__year-wrapper': {
      div: {
        width: '100%',
      },
    },
  },
};

export default datePickerStyle;
