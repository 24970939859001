import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  DeleteUser,
  FetchSubscriptionFeatureLimits,
  getUserData,
  getUserTeams,
  UpdateUserTeamPermissions,
} from 'redux/UserSlice';
import { GetUserTeamListResponseData } from 'models/UsersSlice.interface';
import { hasUserPermission } from 'utils/UserPermissions';
import Trash from 'components/Icons/Trash';
import LightUserAvatar from 'components/Icons/LightUserAvatar';
import Lock from 'components/Icons/Lock';

import SuccessModal from './SuccessModal';

type DeleteModalState = {
  modalStatus: boolean;
  userId: number | null;
  userName: string | null;
};

const TeamTableComponent = () => {
  const dispatch: any = useDispatch();

  const [hoveredRow, setHoveredRow] = useState<number | null>(null);
  const [ownerModal, setOwnerModal] = useState({
    modalStatus: false,
    id: 0,
    role: '',
    userName: '',
  });
  const [deleteModal, setDeleteModal] = useState<DeleteModalState>({
    modalStatus: false,
    userName: '',
    userId: null,
  });

  const userData = useSelector(getUserData);
  const userTeamsData = useSelector(getUserTeams);

  const userPermission = hasUserPermission(userData?.role, ['edit']);

  const handleModal = () => {
    setOwnerModal({ modalStatus: false, userName: '', id: 0, role: '' });
  };

  const confirmModal = async () => {
    setOwnerModal({ modalStatus: false, userName: '', id: 0, role: '' });
    dispatch(
      UpdateUserTeamPermissions({
        request_body: {
          role: ownerModal.role,
          user_id: ownerModal.id,
        },
        toaster: {
          successMessage: 'Role Updated Successfully',
        },
      }),
    );
    dispatch(FetchSubscriptionFeatureLimits());
  };

  const deleteOrgUser = async () => {
    setDeleteModal({ modalStatus: false, userId: 0, userName: '' });
    dispatch(
      DeleteUser({
        request_body: {
          user_id: deleteModal.userId,
        },
        toaster: {
          successMessage: 'User Deleted Successfully',
        },
      }),
    );
    dispatch(FetchSubscriptionFeatureLimits());
  };

  const checkRolePermission = (role: string) => {
    const currentRole = userData?.role;
    if (userPermission && role !== 'owner' && currentRole !== role) return true;
    return false;
  };

  const handleTeamPermission = async (id: number, name: string, role: string) => {
    if (role === 'owner') {
      setOwnerModal({ modalStatus: true, userName: name, id, role });
    } else {
      await dispatch(
        UpdateUserTeamPermissions({
          request_body: {
            role,
            user_id: id,
          },
          toaster: {
            successMessage: 'Role Updated Successfully',
          },
        }),
      );
      dispatch(FetchSubscriptionFeatureLimits());
    }
  };

  return (
    <>
      <Box height={'100%'} maxH={'100%'} overflowX='auto' overflowY='auto' mt='10'>
        <Table
          variant={'base'}
          borderRadius='10px'
          backgroundColor='white'
          color='black'
          fontFamily='Roboto Regular'
        >
          <Thead backgroundColor='white' width='100%' fontFamily='Roboto Regular'>
            <Tr borderBottomWidth='1' borderBottomColor='white.200'>
              <Th
                _first={{
                  backgroundColor: 'white',
                  position: 'sticky',
                  left: '0px',
                  zIndex: '10',
                }}
                color='black'
                fontWeight='600'
                textAlign='left'
                padding='1rem 0'
                width={userPermission ? '30%' : '33%'}
                fontFamily='Roboto Regular'
                fontSize='14px'
              >
                Name
              </Th>
              <Th
                color='black'
                fontWeight='600'
                textAlign='left'
                padding='1rem 0'
                width={userPermission ? '30%' : '33%'}
                fontFamily='Roboto Regular'
                fontSize='14px'
              >
                Email
              </Th>
              <Th
                color='black'
                fontWeight='600'
                textAlign='left'
                padding='1rem 0'
                width={userPermission ? '30%' : '33%'}
                fontFamily='Roboto Regular'
                fontSize='14px'
              >
                Role
              </Th>
              {userPermission && (
                <Th
                  color='black'
                  fontWeight='600'
                  textAlign='left'
                  padding='1rem 0'
                  width='10%'
                  fontFamily='Roboto Regular'
                  fontSize='14px'
                ></Th>
              )}
            </Tr>
          </Thead>
          <Tbody>
            {userTeamsData?.map((data: GetUserTeamListResponseData, index: number) => (
              <Tr
                borderBottomWidth='1'
                borderBottomColor='white.200'
                key={index}
                height='50px !important'
                onMouseEnter={() => setHoveredRow(index)}
                onMouseLeave={() => setHoveredRow(null)}
              >
                <Td padding='1rem 0' textAlign='left' fontSize={'xs'} lineHeight='18px'>
                  <Flex alignItems={'center'}>
                    {data.profile_image_url ? (
                      <Image
                        boxShadow={'1px 1px 10px #d9d9d9'}
                        src={data.profile_image_url}
                        borderRadius={'50%'}
                        width={'30px'}
                        height={'30px'}
                        mr='.5rem'
                      />
                    ) : (
                      <LightUserAvatar
                        height={'30px'}
                        width={'30px'}
                        styles={{ marginRight: '.5rem' }}
                      />
                    )}
                    {data.firstname}
                  </Flex>
                </Td>
                <Td
                  padding='1rem .5rem'
                  textAlign='left'
                  fontSize={'xs'}
                  verticalAlign='middle !important'
                  lineHeight='18px'
                >
                  {data.username}
                </Td>
                <Td
                  textAlign={'left'}
                  fontSize={'xs'}
                  verticalAlign='middle !important'
                  padding={userData?.role === 'planner' ? '1rem .2rem 1rem 0' : '0'}
                >
                  {data.is_active ? (
                    <Menu closeOnSelect={true} isLazy>
                      <MenuButton
                        as={Button}
                        backgroundColor='white.200'
                        maxHeight={'40px'}
                        borderRadius='5px'
                        minWidth={'150px'}
                        textAlign='left'
                        padding='0 .8rem'
                        rightIcon={<ChevronDownIcon fontSize='md' />}
                        fontWeight='300'
                        fontSize='xs'
                        color='black.900'
                        w={'fit-content'}
                        disabled={
                          data.role === 'owner' ||
                          !userPermission ||
                          data.username === userData.username ||
                          userData?.role === data.role
                        }
                      >
                        {data.role.charAt(0).toUpperCase() + data.role.slice(1)}
                      </MenuButton>
                      <MenuList>
                        <MenuOptionGroup
                          value={data.role}
                          onChange={(role: any) => {
                            handleTeamPermission(data.id, data.firstname, role);
                          }}
                        >
                          {userData?.role === 'owner' && (
                            <MenuItemOption value={'owner'}>Owner</MenuItemOption>
                          )}
                          <MenuItemOption value={'admin'}>Admin</MenuItemOption>
                          <MenuItemOption value={'planner'}>Planner</MenuItemOption>
                        </MenuOptionGroup>
                      </MenuList>
                    </Menu>
                  ) : (
                    <Button
                      backgroundColor='white.200'
                      color='black.900'
                      borderRadius={'10px'}
                      width={'150px'}
                      fontSize={'xs'}
                      fontWeight={'400'}
                      alignItems={'center'}
                      paddingInlineStart={'4'}
                      paddingInlineEnd={'4'}
                      cursor={'auto'}
                      justifyContent={'space-between'}
                      rightIcon={<Lock color='black' />}
                    >
                      <Text>View</Text>
                    </Button>
                  )}
                </Td>
                {userPermission && hoveredRow === index && (
                  <Td textAlign='left' verticalAlign={'middle !important'}>
                    <IconButton
                      disabled={!checkRolePermission(data.role)}
                      aria-label='delete model'
                      size='ghost'
                      variant='ghost'
                      onClick={() =>
                        setDeleteModal({
                          modalStatus: true,
                          userId: data.id,
                          userName: data.firstname,
                        })
                      }
                      icon={<Trash />}
                    />
                  </Td>
                )}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
      <SuccessModal
        type='role'
        userName={ownerModal.userName}
        isOpen={ownerModal.modalStatus}
        onClose={() => handleModal()}
        handleSubmit={() => confirmModal()}
      />
      <SuccessModal
        type='delete'
        isOpen={deleteModal.modalStatus}
        userName={deleteModal.userName}
        onClose={() => setDeleteModal({ modalStatus: false, userId: null, userName: null })}
        handleSubmit={() => deleteOrgUser()}
      />
    </>
  );
};

export default TeamTableComponent;
