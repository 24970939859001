import { useMediaQuery } from '@chakra-ui/react';

const Update = ({ color }: any) => {
  const [isSmallDevice] = useMediaQuery('(max-width: 900px)');
  return (
    <>
      <svg
        width={isSmallDevice ? '19' : '26'}
        height={isSmallDevice ? '12' : '19'}
        viewBox='0 0 26 19'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        cursor='pointer'
      >
        <path
          d='M13.2023 18.8587C10.6729 18.8587 8.52102 17.9809 6.74668 16.2255C4.97234 14.47 4.08517 12.3276 4.08517 9.79819V9.59999L2.27308 11.4121L0.6875 9.82651L5.21773 5.29628L9.74796 9.82651L8.16238 11.4121L6.35029 9.59999V9.79819C6.35029 11.6858 7.01567 13.2902 8.34642 14.6116C9.67718 15.9329 11.2958 16.5935 13.2023 16.5935C13.693 16.5935 14.1744 16.5369 14.6463 16.4237C15.1182 16.3104 15.5806 16.1405 16.0337 15.914L17.7325 17.6128C17.0152 18.0281 16.279 18.3396 15.524 18.5472C14.769 18.7548 13.9951 18.8587 13.2023 18.8587ZM21.0735 14.3001L16.5433 9.76988L18.1289 8.1843L19.941 9.99639V9.79819C19.941 7.9106 19.2756 6.30614 17.9448 4.98482C16.6141 3.66351 14.9955 3.00285 13.089 3.00285C12.5982 3.00285 12.1169 3.05947 11.645 3.17273C11.1731 3.28599 10.7106 3.45587 10.2576 3.68238L8.55877 1.98355C9.27606 1.56827 10.0122 1.25682 10.7673 1.04919C11.5223 0.84155 12.2962 0.737732 13.089 0.737732C15.6184 0.737732 17.7702 1.61546 19.5446 3.37093C21.3189 5.12639 22.2061 7.26881 22.2061 9.79819V9.99639L24.0182 8.1843L25.6038 9.76988L21.0735 14.3001Z'
          fill={color}
        />
      </svg>
    </>
  );
};

export default Update;
