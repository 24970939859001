import { Box, Flex, Image, Input, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  AddDimnesionGroupItem,
  fetchDimensionItemsGroup,
  getGroupItem,
  setGroupItem,
} from 'redux/ItemPageSlice';
import { FetchBlockItemDimensions, getSelectedDriver, handleFilters } from 'redux/PlanPageSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

import arrowDown from '../Icons/arrowDown.svg';

interface AddNewItemProperties {
  dataKey: any;
  styles: any;
  planPermission?: boolean;
  refetchRequest?: any;
}

const AddNewItem = ({ dataKey, styles, planPermission }: AddNewItemProperties) => {
  const [itemName, setItemName] = useState('');
  const { blockId } = useParams();
  const dispatch: AppDispatch = useDispatch();

  const groupItem = useSelector(getGroupItem);
  const currentDriver = useSelector(getSelectedDriver);
  const baseScenario = useSelector(getBaseScenario);

  const dimensionId = currentDriver?.dim_id;
  const requestBody = {
    name: itemName,
    properties: [
      {
        property_id: currentDriver?.group_property_id,
        value: groupItem,
      },
    ],
    scenario_id: baseScenario?.id,
  };

  const AddItem = async () => {
    const addDimGroupItem: any = await dispatch(
      AddDimnesionGroupItem({
        dimension_id: dimensionId,
        request_body: requestBody,
        toaster: {
          successMessage: 'Successfully added',
          errorMessage: 'An item with this name already exists',
        },
      }),
    );
    if (!addDimGroupItem?.error) {
      await dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));
      await setItemName('');
      await dispatch(
        FetchBlockItemDimensions({
          blockId,
        }),
      );
      await dispatch(handleFilters());
    }
  };

  const addNewItem = () => {
    const addItem = itemName.trim().length > 0;
    if (addItem) {
      AddItem();
    }
  };

  return (
    <>
      {groupItem === dataKey?.name ? (
        <Flex
          textAlign='left'
          alignSelf='left'
          w='100%'
          minH='50px'
          bgColor='#7163D0'
          mb='10px'
          p='0'
          flexDirection='column'
          borderRadius='14px'
          filter='drop-shadow(0px 0px 15.998px rgba(0, 0, 0, 0.25))'
          transition='all 0.3s ease-out'
          css={styles.container(true)}
        >
          <Box position='relative' p='16px'>
            <Flex justifyContent='space-between'>
              <Text fontFamily='Roboto Regular' fontSize='1xl' lineHeight='9' color='#FFFFFF'>
                <Input
                  fontSize='16px'
                  placeholder='New Item'
                  autoFocus
                  onChange={(event: any) => {
                    setItemName(event.target.value);
                  }}
                  isDisabled={!!planPermission}
                  onKeyDown={
                    planPermission
                      ? undefined
                      : (event) =>
                          event.code === 'Enter' && event.target.value.trim().length > 0
                            ? AddItem()
                            : null
                  }
                  onBlur={
                    planPermission
                      ? undefined
                      : (event: any) =>
                          event.target.value ? AddItem() : dispatch(setGroupItem(null))
                  }
                  color='#FFFFFF'
                  _placeholder={{ color: '#ffffff66', fontSize: '16px' }}
                />
              </Text>
              <Image src={arrowDown} w='12px' onClick={addNewItem} />
            </Flex>
          </Box>
        </Flex>
      ) : null}
    </>
  );
};

export default AddNewItem;
