const ExportIcon = () => {
  return (
    <svg
      height='20'
      width='20'
      version='1.1'
      id='Layer_1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 48 48'
      fill='white'
    >
      <g>
        <path
          className='st0'
          d='M25.3,20.8l0,8.9l4.8-4.8l1.7,1.7l-7.7,7.7l-7.7-7.7l1.7-1.7l4.8,4.8l0-8.9H25.3z'
        />
        <path
          className='st0'
          d='M28,8.1H13.6c-0.6,0-1.2,0.2-1.7,0.7c-0.5,0.5-0.7,1-0.7,1.7v27.1c0,0.6,0.2,1.2,0.7,1.7
		c0.5,0.5,1,0.7,1.7,0.7h20.7c0.6,0,1.2-0.2,1.7-0.7c0.5-0.5,0.7-1,0.7-1.7V16.8L28,8.1z M34.4,37.6H13.6V17.9v-7.4h13.2v7.4h7.5
		V37.6z'
        />
      </g>
    </svg>
  );
};

export default ExportIcon;
