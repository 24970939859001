/* eslint-disable no-restricted-syntax */
import './Table.scss';

import { Box, Flex } from '@chakra-ui/react';
import { ReactGrid } from '@silevis/reactgrid';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AddRowCellTemplate } from 'components/AddRowCell';
import { ChevronCellTemplate } from 'components/ChevronCell';
import { DateCellTemplate } from 'components/DateCell';
import DeleteGroupModal from 'components/DeleteGroupModal';
import { DeleteRowCellTemplate } from 'components/DeleteRowCell';
import { DropdownCellTemplate } from 'components/DropdownCell';
import FullScreenLoader from 'components/FullScreenLoader';
import { HeaderRowTemplate } from 'components/HeaderRow';
import { TextCellTemplate } from 'components/TextCell';
import PlanPermission from 'container/PlanPermission';
import { HEADER_HEIGHT_WITH_NAVIGATION } from 'projectConstants';
import { FetchPlangPageIndicatorTableData } from 'redux/PlanPageSlice';
import {
  AddIndicator,
  DeleteIndicator,
  FetchBlockOutputs,
  FetchFormulaList,
  ReorderIndicator,
  UpdateIndicator,
  getAddIndicator,
  getBaseScenario,
  getDimensionalIndicator,
  getIndicatorHeaders,
  getPlannerBlock,
  getPlannerModel,
  getSelectedCell,
  handleAddIndicator,
  handleSelectedCell,
} from 'redux/PlannerModeSlice';
import { getExandSideInputStatus } from 'redux/BuilderModeSlice';

import {
  buildTree,
  getDimensionalColumns,
  getDimensionalRows,
  getExpandedRows,
  handleCanReorderRows,
  handleCellChange,
  handleColumnResize,
  reorderArray,
} from './TableHelpers';

const BuilderModeDataTable = ({ planPermission }: any) => {
  const { modelId, blockId } = useParams();
  const dispatch: any = useDispatch();
  const tableReference: any = useRef(null);
  const selectedCell = useSelector(getSelectedCell);
  const plannerBlock = useSelector(getPlannerBlock);
  const baseScenario = useSelector(getBaseScenario);
  const addIndicator = useSelector(getAddIndicator);
  const modelDetails = useSelector(getPlannerModel);
  const indicatorHeaders = useSelector(getIndicatorHeaders);
  const indicatorValues = useSelector(getDimensionalIndicator);
  const isExpand = useSelector(getExandSideInputStatus);
  const [tableData, setTableData]: any = useState([]);
  const [deleteModal, setDeleteModal] = useState({
    indicatorId: '',
    indicatorName: '',
    status: false,
  });
  const [gridProperties, setGridProperties] = useState({});
  const memorizedRows: any = useMemo(() => {
    return (
      indicatorHeaders?.length >= 0 &&
      tableData?.length >= 0 &&
      buildTree(getDimensionalRows(tableData, indicatorHeaders, modelDetails?.reporting_currency))
    );
  }, [tableData, indicatorHeaders]);

  const memorizedColumns: any = useMemo(() => {
    return (
      indicatorHeaders?.length >= 0 &&
      getDimensionalColumns(indicatorHeaders, tableReference?.current?.offsetWidth)
    );
  }, [indicatorHeaders, isExpand]);

  const [columns, setColumns]: any = useState(memorizedColumns);
  const [rows, setRows]: any = useState(memorizedRows);
  const [rowsToRender, setRowsToRender]: any = useState(getExpandedRows(rows));

  useEffect(() => {
    if (indicatorValues?.length >= 0) setTableData(indicatorValues || []);
  }, [indicatorValues]);

  useEffect(() => {
    if (memorizedColumns?.length >= 0) setColumns(memorizedColumns);
  }, [memorizedColumns]);

  useEffect(() => {
    if (tableReference?.current && indicatorHeaders?.length >= 0) {
      setColumns(getDimensionalColumns(indicatorHeaders, tableReference?.current?.offsetWidth));
    }
  }, [tableReference?.current]);

  useEffect(() => {
    if (memorizedRows?.length >= 0) setRows(memorizedRows);
  }, [memorizedRows]);

  useEffect(() => {
    setRowsToRender(getExpandedRows(rows));
  }, [rows]);

  const handleAddRow = () => {
    let object: any = {};
    for (const number of indicatorHeaders) {
      object[number?.name] = 0;
    }
    const rowId = rows?.length ? rows.length - 2 : 0;
    object = {
      ...object,
      name: '',
      text: '',
      id: rowId,
      position: '',
      isNewCell: true,
      type: 'Number',
    };
    setTableData([...tableData, object]);
    setGridProperties({
      focusLocation: { rowId, columnId: 'name' },
    });
    if (addIndicator) dispatch(handleAddIndicator(false));
  };

  useEffect(() => {
    if (addIndicator) handleAddRow();
  }, [addIndicator]);

  const handleDeleteRow = (data: any) =>
    setDeleteModal({ indicatorId: data?.id, indicatorName: data?.itemName, status: true });

  const handleFocusLocationChanged = async (data: any) => {
    if (selectedCell?.rowId !== data?.rowId) {
      await dispatch(handleSelectedCell(data));
      if (typeof data?.rowId !== 'string' || typeof data?.rowId !== 'string') {
        dispatch(
          FetchPlangPageIndicatorTableData({
            indicator_id: data?.rowId,
            parameters: {
              scenario_id: baseScenario?.id,
              format_excel_data: true,
            },
          }),
        );
      }
    }
  };

  const fetchIndicatorsData = () => {
    const idArray = plannerBlock?.dimensions
      .filter((item: any) => item.name !== 'Time')
      .map((item: any) => item.id);
    const dimensionIds = idArray.join(', ');
    const payload = {
      blockId,
      params: {
        dim_id: dimensionIds,
        indicator_filter: 'all',
        scenario_id: baseScenario?.id,
      },
    };
    dispatch(FetchBlockOutputs(payload));
  };

  const handleDeleteModal = () => {
    setDeleteModal({ indicatorId: '', indicatorName: '', status: false });
    dispatch(
      DeleteIndicator({
        indicatorId: deleteModal.indicatorId,
        toaster: {
          successMessage: 'Indicator deleted',
        },
      }),
    ).then((response: any) => {
      if (!response?.error) {
        setGridProperties({
          focusLocation: { rowId: 'header', columnId: 'name' },
        });
        fetchIndicatorsData();
      }
    });
  };

  const handleAddDimIndicator = (indicatorName: string) => {
    dispatch(
      AddIndicator({
        blockId,
        data: {
          name: indicatorName,
          type: 'Input',
        },
        toaster: true,
      }),
    ).then((response: any) => {
      setGridProperties({
        focusLocation: { rowId: 'header', columnId: 'name' },
      });
      dispatch(FetchFormulaList(modelId));
      if (!response?.error) fetchIndicatorsData();
    });
  };

  const handleRowsReorder = async (targetRowId: any, rowIds: any[]) => {
    const to = tableData?.findIndex((person: any) => person.id === targetRowId);
    const rowsIds = rowIds.map((id) => tableData?.findIndex((person: any) => person.id === id));
    const reorededArray = reorderArray(tableData, rowsIds, to);
    dispatch(
      ReorderIndicator({
        blockId,
        data: { indicators: reorededArray },
      }),
    ).then((response: any) => {
      if (!response?.error) fetchIndicatorsData();
    });
  };

  const updateIndicator = (changes: any, change: any, columnId: any) => {
    dispatch(
      UpdateIndicator({
        indicatorId: changes[0]?.rowId,
        data: {
          name: change?.extraKey
            ? change?.checked
              ? 'subtotal'
              : 'default'
            : change?.checked !== undefined
            ? `${change?.checked}`
            : change?.text,
        },
        toaster: {
          errorMessage: '"An indicator with this already exists',
        },
      }),
    ).then(() => {
      dispatch(handleSelectedCell(selectedCell));
      dispatch(FetchFormulaList(modelId));
      if (columnId === 'name') fetchIndicatorsData();
    });
  };

  const onCellChange = (changes: any, columns_: any, setRows_: any) => {
    const change = changes[0];

    if (change?.newCell?.isNewCell) {
      handleAddDimIndicator(change?.newCell?.text);
    } else if (change?.newCell?.text !== change?.previousCell?.text) {
      updateIndicator(changes, change?.newCell, change?.columnId);
    } else if (change?.type === 'chevron') {
      handleCellChange(changes, columns_, setRows_);
    }
  };

  const handleContextMenu = (
    selectedRowIds: any,
    selectedColIds: any,
    selectionMode: any,
    menuOptions: any,
    selectedRanges: any,
  ) => {
    if (
      selectedRanges[0] &&
      selectedRanges[0][0]?.rowId !== 'header' &&
      selectedRanges[0][0]?.rowId !== 'footer'
    ) {
      const deleteRow = {
        id: 'deleteRow',
        label: 'Delete row',
        handler: () => {
          const myRow = rows?.filter((data2: any) => {
            return data2?.rowId === selectedRanges[0][0]?.rowId && data2;
          });
          handleDeleteRow({ id: myRow[0]?.itemId, itemName: myRow[0]?.itemName });
        },
      };
      let selectedRows = false;
      for (let index = 0; index < selectedRanges[0]?.length; index += 1) {
        selectedRows = selectedRanges[0][0]?.rowId === selectedRanges[0][index]?.rowId;
      }
      if (selectedRanges[0][0]?.columnId !== 'delete' && selectedRows) {
        menuOptions = menuOptions.filter((object: any) => object.id !== 'cut');
        return [...menuOptions, deleteRow];
      }
      return selectedRanges[0][0]?.columnId === 'delete' && selectedRanges[0]?.length === 1
        ? [deleteRow]
        : menuOptions.filter((object: any) => object.id !== 'cut');
    }
    return [];
  };

  const handleLocationChanged = () => {
    if (Object.keys(gridProperties).length > 0) {
      setGridProperties({});
    }
    return true;
  };

  if (!columns?.length || !rowsToRender?.length)
    return (
      <FullScreenLoader
        width={'100%'}
        height={`calc(100vh - ${HEADER_HEIGHT_WITH_NAVIGATION}) - 45px`}
        bgColor={'transparent'}
      />
    );

  return (
    <Flex
      height={'100%'}
      width={'100%'}
      flexDirection={'column'}
      ref={tableReference}
      flex={1}
      overflow={'hidden'}
    >
      {columns?.length && rows?.length && rowsToRender?.length && (
        <Box
          className='block-table-container sm-scroll'
          width={'100%'}
          margin={'initial'}
          maxH={'initial'}
          overflowY={rows.length > 6 ? 'auto' : 'initial'}
        >
          <ReactGrid
            rows={rowsToRender}
            columns={columns}
            enableRowSelection={rows?.length > 3}
            stickyTopRows={rows?.length <= 6 ? 0 : 1}
            stickyRightColumns={columns?.length <= 2 ? 0 : 1}
            stickyLeftColumns={columns?.length <= 2 ? 0 : 1}
            stickyBottomRows={rows?.length <= 3 ? 0 : 1}
            verticalStickyBreakpoint={100}
            horizontalStickyBreakpoint={100}
            onRowsReordered={handleRowsReorder}
            canReorderRows={handleCanReorderRows}
            {...gridProperties}
            onFocusLocationChanging={handleLocationChanged}
            onFocusLocationChanged={handleFocusLocationChanged}
            onCellsChanged={
              planPermission ? undefined : (changes: any) => onCellChange(changes, columns, setRows)
            }
            onContextMenu={planPermission ? undefined : handleContextMenu}
            onColumnResized={
              planPermission
                ? undefined
                : (ci: any, width: any) => handleColumnResize(ci, width, setColumns)
            }
            customCellTemplates={{
              header: new HeaderRowTemplate(),
              delete: new DeleteRowCellTemplate(),
              text: new TextCellTemplate(),
              date: new DateCellTemplate(),
              addRow: new AddRowCellTemplate(handleAddRow, planPermission),
              chevron: new ChevronCellTemplate(),
              dropdown: new DropdownCellTemplate(handleDeleteRow, planPermission),
            }}
          />
        </Box>
      )}
      <DeleteGroupModal
        isOpen={deleteModal.status}
        onClose={() => setDeleteModal({ indicatorId: '', indicatorName: '', status: false })}
        itemName={deleteModal.indicatorName || 'some name'}
        onDelete={() => handleDeleteModal()}
        orgChart={true}
      />
    </Flex>
  );
};

export default PlanPermission(BuilderModeDataTable);
