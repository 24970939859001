import { ChevronDownIcon, InfoIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  VStack,
  Tooltip,
  useBoolean,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, Suspense, lazy } from 'react';
import { BsArrowRight } from 'react-icons/bs';

import {
  createAction,
  getEditActionForm,
  getFormValues,
  getSelectedDataFileDetails,
  getTableHeader,
  putActionData,
  setClearSelectedAreasTable,
  setSelectedAreasProperties,
  getNoheadersStatus,
  setActionCreateForm,
  clearStepperFormData,
} from 'redux/DataImportSlice';
import { GetTableProperties, getDimensionProperties } from 'redux/ItemPageSlice';
import Spinner from 'components/Spinner';

import { textStyles } from '../Helper';

import ActionStepFooter from './ActionStepFooter';

const LazyMappingDimensionModal = lazy(() => import('../MappingDimensionModal'));

interface Properties {
  setHelpModalSeleceted: any;
}

const ActionStepThree = ({ setHelpModalSeleceted }: Properties) => {
  const dispatch: any = useDispatch();
  const [dimensionMappingModal, setDimensionMappingModal] = useBoolean(false);
  const [selectedActionId, setSelectedActionId] = useState<number | null>(null);
  const [selectedMapType, setSelectedMapType] = useState('');
  const [scenarioId, setScenarioId] = useState<number | null>(null);
  const getFormValue = useSelector(getFormValues);
  const properties = useSelector(getDimensionProperties);
  const headerNames = useSelector(getTableHeader);
  const sourceFileId = useSelector(getSelectedDataFileDetails);
  const stepperFormEditable = useSelector(getEditActionForm);
  const [loader, setLoader] = useState(false);
  const [formValue, setFormValue] = useState(getFormValue?.propertdata || {});
  const isHeader = useSelector(getNoheadersStatus);
  const [customHeaders, setCustomHeaders] = useState<any[]>(headerNames ?? []);
  useEffect(() => {
    if (isHeader) {
      const array: string[] = new Array(26)
        .fill(1)
        .map((_: string, index: number) => String.fromCodePoint(65 + index));
      const newHeaders = array
        .slice(0, headerNames.length)
        .map((item: string, headerIndex: number) => {
          return { current: `${headerNames[headerIndex]}`, modified: `Column ${item}` };
        });
      setCustomHeaders(newHeaders);
    } else {
      setCustomHeaders(
        headerNames.map((item: string) => {
          return { current: `${item}`, modified: `${item}` };
        }),
      );
    }
  }, [isHeader, headerNames]);

  const getTableProperties = async () => {
    setLoader(true);
    await dispatch(GetTableProperties({ selectedId: getFormValue?.dimension }));
    setLoader(false);
  };
  useEffect(() => {
    if (Object.keys(getFormValue?.propertdata).length === 0) {
      getTableProperties();
    }
  }, []);

  const modifyFormValue = (key: any, value: any) => {
    const object: any = JSON.parse(JSON.stringify(formValue));
    object[`${key}`] = value;
    setFormValue(object);
  };
  const getSelectedColumnOption = (value: string) => {
    const data = customHeaders?.find((item) => item?.current === value);
    return data?.modified;
  };

  useEffect(() => {
    if (Object.keys(getFormValue?.propertdata).length === 0) {
      setFormValue((previousFormValue: any) => {
        const updatedFormValue = { ...previousFormValue };

        properties?.forEach((field: any) => {
          const matchingProperty = customHeaders.find(
            (item) => item?.current?.toLowerCase() === field?.name?.toLowerCase(),
          );

          const valueToSet = matchingProperty ? matchingProperty.current : null;
          dispatch(setSelectedAreasProperties({ [field.id]: valueToSet }));
          updatedFormValue[field.id] = valueToSet;
        });

        return updatedFormValue;
      });
    }
  }, [getFormValue?.propertdata, properties]);

  const setModalOpen = () => {
    setDimensionMappingModal.on();
  };

  const handleSubmit = async (form: any, setSubmitting: (data: boolean) => void) => {
    const dimId = getFormValue?.dimension;
    const requestBody = {
      name: getFormValue?.name,
      description: getFormValue?.description,
      dimension_id: dimId,
      mappings: {
        [dimId]: getFormValue?.column,
        property_map: form,
      },
      action_type: 'dimension',
      mapping_type: 'row',
      time_column: 'time',
      period_type: 'single',
      single_period_date: '7 Aug 23',
      missing_header: isHeader,
    };
    if (stepperFormEditable?.isEditForm) {
      await dispatch(
        putActionData({
          dataSourceId: sourceFileId?.id,
          actionId: stepperFormEditable?.actionId,
          request_body: requestBody,
        }),
      );
      await Promise.all([
        setSelectedActionId(stepperFormEditable?.actionId),
        setSelectedMapType(stepperFormEditable?.actionType),
        setScenarioId(stepperFormEditable?.scenarioId),
      ]);
    } else {
      const response: any = await dispatch(
        createAction({ dataSourceId: sourceFileId?.id, request_body: requestBody }),
      );

      await Promise.all([
        setSelectedActionId(response?.payload?.id),
        setSelectedMapType(response?.payload?.action_type),
        setScenarioId(response?.payload?.scenario_id),
      ]);
    }
    await setSubmitting(false);
    await dispatch(setClearSelectedAreasTable());
    setModalOpen();
  };
  return (
    <>
      <Flex justifyContent={'space-between'} w={'full'} align={'center'}>
        <Text
          color={'#6562CF'}
          fontSize='lg'
          fontFamily={'regular_roboto'}
          fontWeight={'400'}
          textAlign={'start'}
        >
          Select properties
        </Text>
        <CloseIcon
          color={'black'}
          width={3}
          height={3}
          cursor={'pointer'}
          onClick={async () => {
            await dispatch(setActionCreateForm(false));
            await dispatch(clearStepperFormData());
            await dispatch(setClearSelectedAreasTable());
          }}
        />
      </Flex>
      {loader ? (
        <Flex
          h='full'
          direction='column'
          alignItems='center'
          justifyContent='center'
          justify='center'
          align='center'
        >
          <Spinner styleProperties={{ my: '4vh' }} />
        </Flex>
      ) : (
        <VStack w={'100%'} pt={3}>
          <Formik
            initialValues={formValue}
            onSubmit={(form, { setSubmitting }) => handleSubmit(form, setSubmitting)}
            enableReinitialize={true}
          >
            {({ values, setFieldValue, isSubmitting }) => {
              return (
                <Form style={{ width: '100%' }}>
                  <VStack display='flex' justify={'center'} w={'100%'}>
                    <VStack display='flex' justify={'center'} w={'90%'}>
                      {Object.keys(formValue).length > 0 && (
                        <Flex justifyContent={'space-between'} align={'center'} w='full'>
                          <Tooltip label={'Property in Blox'}>
                            <Text
                              fontSize='1rem'
                              color='black'
                              fontFamily={'regular_roboto'}
                              fontWeight={'600'}
                            >
                              Property in Blox
                            </Text>
                          </Tooltip>
                          <Flex w={'41%'}>
                            <Tooltip label={'Column name in file'}>
                              <Text
                                fontSize='1rem'
                                color='black'
                                fontFamily={'regular_roboto'}
                                fontWeight={'600'}
                                noOfLines={1}
                              >
                                Column name in file
                              </Text>
                            </Tooltip>
                          </Flex>
                        </Flex>
                      )}
                      {Object.keys(formValue).length > 0 ? (
                        properties.map((item: any) => (
                          <FormControl mt={4} key={item?.id}>
                            <Flex justifyContent={'space-between'} align={'center'} pt={3}>
                              <Tooltip label={item.name}>
                                <FormLabel
                                  fontSize='1rem'
                                  color='black'
                                  htmlFor='selecetDimension'
                                  fontFamily={'Roboto Regular'}
                                  m={0}
                                  maxW={'fit-content'}
                                  noOfLines={1}
                                  h={'fit-content'}
                                  fontWeight={500}
                                >
                                  {item.name}
                                </FormLabel>
                              </Tooltip>
                              <Flex align={'center'} gap={2} justify={'end'} w={'50%'}>
                                <BsArrowRight
                                  color={'black'}
                                  style={{ width: '20px', height: '20px' }}
                                />
                                <Menu closeOnSelect={true} isLazy>
                                  <MenuButton
                                    placeholder={item.name}
                                    as={Button}
                                    backgroundColor='white'
                                    maxHeight={'40px'}
                                    borderRadius='5px'
                                    minWidth={'150px'}
                                    textAlign='left'
                                    padding='0 .8rem'
                                    boxShadow={'rgb(139, 137, 177) 0px 0px 0px 0.6px'}
                                    rightIcon={<ChevronDownIcon fontSize='md' />}
                                    fontWeight='300'
                                    fontSize='xs'
                                    color='black.900'
                                    w={'70%'}
                                    border={'0.6px solid #8B89B1'}
                                  >
                                    <Text me={3} noOfLines={1} height={'fit-content'}>
                                      {getSelectedColumnOption(formValue[item.id]) ?? (
                                        <Text opacity={0.5}>{item?.name}</Text>
                                      )}
                                    </Text>
                                  </MenuButton>
                                  <MenuList
                                    height={'fit-content'}
                                    maxHeight={'20vh'}
                                    overflowY={'auto'}
                                    className='scroll'
                                  >
                                    <MenuOptionGroup
                                      defaultValue={values[item.id]}
                                      onChange={(role: any) => {
                                        modifyFormValue(item.id, role);
                                        setFieldValue(item.id, role);
                                        dispatch(setSelectedAreasProperties({ [item.id]: role }));
                                      }}
                                    >
                                      <MenuItemOption>Select...</MenuItemOption>
                                      {customHeaders
                                        .filter(
                                          (header: any) =>
                                            !Object.values(formValue).includes(header?.current),
                                        )
                                        .map((header: any) => (
                                          <MenuItemOption key={header} value={header.current}>
                                            {header.modified}
                                          </MenuItemOption>
                                        ))}
                                    </MenuOptionGroup>
                                  </MenuList>
                                </Menu>
                              </Flex>
                            </Flex>
                          </FormControl>
                        ))
                      ) : (
                        <Text {...textStyles} color={'black'}>
                          No properties found
                        </Text>
                      )}
                    </VStack>
                    <Flex
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      pb={8}
                      pt='5'
                      w={'100%'}
                    >
                      <Flex>
                        <Text
                          {...textStyles}
                          color={'black'}
                          textDecoration='underline'
                          onClick={() => {
                            setHelpModalSeleceted.on();
                          }}
                          display={'none'}
                        >
                          Help <InfoIcon color={'#8B89B1'} ms={3} />
                        </Text>
                      </Flex>
                      <ActionStepFooter
                        nextButtonName='Continue'
                        prevButtonName='Previous'
                        isSubmitting={isSubmitting}
                      />
                    </Flex>
                  </VStack>
                </Form>
              );
            }}
          </Formik>
        </VStack>
      )}
      <Suspense fallback={<div>Loading...</div>}>
        <LazyMappingDimensionModal
          mappingModal={dimensionMappingModal}
          setMappingModal={setDimensionMappingModal}
          selectedActionId={selectedActionId}
          dataSourceId={sourceFileId?.id}
          selectedMapType={selectedMapType}
          scenarioId={scenarioId}
        />
      </Suspense>
    </>
  );
};
export default ActionStepThree;
