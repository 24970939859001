const styles = {
  node: {
    width: 100,
    padding: 5,
    border: '1px solid #979797',
    borderRadius: 5,
    'background-color': 'white.main',
    color: 'black.main',
  },
  label: { mb: 0.5, fontSize: 11, fontFamily: 'Roboto', textAlign: 'center' },
  chip: {
    py: 0.8,
    px: 0.5,
    borderRadius: 5,
    fontSize: 9,
    textAlign: 'center',
    'background-color': 'primary.main',
    color: 'white.main',
    zIndex: 2,
  },
};

export default styles;
