import { Icon, StyleProps } from '@chakra-ui/react';

interface Properties {
  width?: number | string;
  height?: number | string;
  styles?: StyleProps;
}

const Avatar = ({ width = 10, height = 10, styles }: Properties) => {
  return (
    <Icon viewBox='0 0 100 99' width={width} height={height} {...styles}>
      <mask id='mask0_2322_4370' maskUnits='userSpaceOnUse' x='1' y='0' width='98' height='97'>
        <circle cx='50.1073' cy='48.4069' r='48.4069' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_2322_4370)'>
        <g opacity='0.3'>
          <path d='M199.544 -26.5898H-20.2563V133.197H199.544V-26.5898Z' fill='#D9D9D9' />
        </g>
        <path
          d='M77.9764 61.8971L27.7878 53.1714C21.9992 52.1622 16.0427 53.3655 11.0999 56.5427C6.15944 59.7208 2.59274 64.6384 1.10616 70.3215C-2.54622 84.2736 -8.41908 102.717 -19.0374 102.717C-34.9648 102.717 -55.9907 68.3103 -55.9907 68.3103L-80.7562 84.5032C-80.7562 84.5032 -53.6749 143.45 -20.4147 144.778C8.06578 145.913 14.5727 123.553 14.5727 123.553V136.514L94.3661 152.242C94.3661 152.242 103.008 185.658 114.027 196.276L143.309 184.676C132.993 168.335 113.94 115.277 104.914 85.7024C103.065 79.6323 99.5672 74.1943 94.8111 69.9936C90.056 65.7902 84.2286 62.9876 77.9764 61.8971Z'
          fill='white'
        />
        <path
          d='M113.899 196.923L113.639 196.673C109.008 192.212 104.27 183.4 99.539 170.464C96.4316 161.959 94.3611 154.381 93.9176 152.718L14.0071 136.961V126.463C12.5229 129.593 10.5477 132.466 8.15617 134.973C5.08855 138.194 1.38351 140.742 -2.72289 142.452C-7.90243 144.626 -13.8659 145.594 -20.4445 145.332C-28.1506 145.02 -36.1535 141.63 -44.228 135.237C-50.6521 130.152 -57.1417 123.166 -63.5158 114.469C-74.3511 99.6904 -81.2031 84.8795 -81.2702 84.7327L-81.4717 84.2954L-55.8206 67.5295L-55.5208 68.0213C-55.4677 68.1057 -50.1962 76.694 -43.0569 85.1652C-36.522 92.9259 -27.0529 102.159 -19.0439 102.159C-14.5795 102.159 -10.4806 98.5677 -6.8641 91.4987C-3.52558 84.9841 -1.1427 76.6862 0.565598 70.1809C2.08231 64.3598 5.73566 59.3233 10.7982 56.0742C15.8564 52.816 21.9562 51.5828 27.8828 52.6201L78.0713 61.3474C84.4252 62.4513 90.3482 65.2968 95.1808 69.5672C100.014 73.8359 103.568 79.362 105.448 85.5306C114.651 115.688 133.597 168.239 143.78 184.374L144.141 184.944L113.899 196.923ZM15.1237 136.049L94.8185 151.754L94.9076 152.095C94.9919 152.424 103.496 184.958 114.159 195.614L142.481 184.395C132.175 167.549 113.494 115.727 104.38 85.8632C102.561 79.89 99.12 74.5385 94.4406 70.4042C89.7641 66.2682 84.0314 63.5113 77.8808 62.4405L27.6922 53.7132C22.0408 52.7253 16.2247 53.9028 11.4026 57.0111C6.57314 60.1073 3.08738 64.9093 1.6399 70.4604C-2.35914 85.743 -8.32568 103.277 -19.0439 103.277C-22.8009 103.277 -27.1185 101.43 -31.8749 97.7854C-35.6334 94.9075 -39.6824 90.9007 -43.9188 85.8757C-50.1336 78.5007 -54.9416 71.0413 -56.1689 69.091L-80.0506 84.7061C-78.8389 87.2545 -72.3384 100.555 -62.6039 113.828C-56.2891 122.439 -49.8713 129.347 -43.5284 134.367C-35.6443 140.605 -27.8633 143.919 -20.3993 144.217C-13.997 144.472 -8.20856 143.536 -3.18829 141.436C0.772766 139.792 4.34843 137.341 7.31137 134.24C12.3629 128.953 14.0134 123.449 14.0259 123.394L15.1189 119.647L15.1237 136.049Z'
          fill='#EA5738'
        />
        <path d='M15.1221 94.0303H14.0056V123.535H15.1221V94.0303Z' fill='#EA5738' />
        <path
          d='M38.2622 32.2812C38.2622 39.4642 45.0782 44.5095 52.2986 44.5095C55.1772 44.5135 57.9766 43.5672 60.2623 41.8174C62.5466 40.0689 64.1901 37.6153 64.9376 34.8374C65.7045 36.8381 66.9904 38.5985 68.6632 39.9374C70.3356 41.2771 72.3348 42.1471 74.455 42.4576C76.5751 42.7687 78.7397 42.5089 80.7259 41.705C82.7129 40.9021 84.4502 39.5842 85.7588 37.8871C86.3063 42.9554 88.671 47.6539 92.4155 51.1131C96.1603 54.5698 101.03 56.5539 106.124 56.6986C111.22 56.8454 116.197 55.1419 120.134 51.9032C124.071 48.6642 126.702 44.1099 127.542 39.0816C128.382 34.0553 127.375 28.8947 124.706 24.5533C122.031 20.2125 117.874 16.9874 113.004 15.4747C108.137 13.9599 102.886 14.2583 98.2211 16.3148C93.5563 18.372 89.7939 22.0484 87.6294 26.6645C86.8075 24.2326 85.231 22.1265 83.1292 20.6527C81.0283 19.1786 78.5121 18.4128 75.9462 18.4665C73.3794 18.5207 70.8969 19.3927 68.8601 20.9556C66.8232 22.5184 65.3375 24.69 64.619 27.1548C63.7129 24.611 62.0408 22.4104 59.8329 20.8556C57.6245 19.302 54.9895 18.4696 52.2893 18.4728C45.0782 18.4743 38.2622 25.092 38.2622 32.2812Z'
          fill='#FFDB06'
        />
        <path
          d='M64.875 36.362C65.0509 36.3605 65.2192 36.2898 65.3435 36.1652C65.468 36.041 65.5387 35.8727 65.5402 35.6968V26.3136C65.5398 26.2265 65.5222 26.1402 65.4885 26.0598C65.4547 25.9795 65.4054 25.9065 65.3435 25.8452C65.2821 25.7832 65.2093 25.734 65.1289 25.7002C65.0485 25.6664 64.9622 25.6489 64.875 25.6484C64.7878 25.6489 64.7016 25.6664 64.6213 25.7002C64.5409 25.734 64.4679 25.7832 64.4066 25.8452C64.3446 25.9065 64.2953 25.9795 64.2615 26.0598C64.2278 26.1402 64.2103 26.2265 64.2098 26.3136V35.6968C64.2114 35.8727 64.282 36.041 64.4066 36.1652C64.5308 36.2898 64.6991 36.3605 64.875 36.362Z'
          fill='#433D53'
        />
        <path
          d='M58.3246 43.2698L64.0617 59.3534C64.0617 59.3534 62.0505 66.2569 50.2595 65.6447C38.4685 65.0326 39.7739 55.2466 39.7739 55.2466V49.2145L54.1508 43.084L58.3246 43.2698Z'
          fill='#EC7603'
        />
        <path
          d='M39.7737 51.3206L63.4947 60.5585C63.7334 60.1837 63.9263 59.7816 64.0693 59.3608L58.3292 43.2772L54.1584 43.0898L39.7925 49.1548V49.2313L39.7737 51.3206Z'
          fill='#433D53'
        />
        <path
          d='M44.1319 51.4129C48.5042 51.605 52.7515 48.1884 54.4379 43.2774H58.4979C59.0467 43.2797 59.5906 43.1735 60.0984 42.9651C60.6059 42.7555 61.0671 42.4477 61.4554 42.0594C61.8439 41.6709 62.152 41.2099 62.3626 40.7025C62.5703 40.1945 62.6764 39.6507 62.6749 39.1019C62.6764 38.5531 62.5703 38.0093 62.3626 37.5014C62.152 36.994 61.8439 36.5329 61.4554 36.1444C61.0672 35.7557 60.606 35.4474 60.0984 35.2372C59.5904 35.0295 59.0467 34.9234 58.4979 34.9249H54.7002V34.9124C54.58 34.9217 54.452 34.9249 54.3178 34.9249C49.032 34.9249 43.192 29.2597 43.1857 26.6582C38.8416 27.8168 37.0114 32.4951 36.749 38.4695C36.4492 45.3667 38.6666 51.1709 44.1319 51.4129Z'
          fill='#EC7603'
        />
        <path
          d='M55.9072 40.1798C55.759 40.1798 55.6168 40.1209 55.512 40.0161C55.4071 39.9112 55.3482 39.769 55.3482 39.6208C55.3476 39.1886 55.4323 38.7606 55.5977 38.3613C55.763 37.962 56.0057 37.5993 56.3117 37.2941C56.6165 36.988 56.9789 36.7452 57.3779 36.5796C57.7769 36.414 58.2047 36.3289 58.6367 36.3291C58.785 36.3291 58.9273 36.388 59.0321 36.4928C59.1369 36.5977 59.1957 36.7399 59.1957 36.8881C59.1957 37.0364 59.1369 37.1786 59.0321 37.2834C58.9273 37.3883 58.785 37.4471 58.6367 37.4471C58.3514 37.4465 58.0689 37.5025 57.8055 37.6119C57.542 37.7212 57.3027 37.8818 57.1017 38.0842C56.8993 38.2856 56.7388 38.525 56.6294 38.7888C56.5201 39.0525 56.4642 39.3353 56.4648 39.6208C56.465 39.6941 56.4506 39.7668 56.4227 39.8346C56.3947 39.9024 56.3537 39.964 56.3019 40.016C56.2501 40.0679 56.1886 40.1091 56.1209 40.1372C56.0531 40.1653 55.9806 40.1798 55.9072 40.1798Z'
          fill='#433D53'
        />
        <path
          d='M47.4612 41.4977C47.0255 41.4977 46.671 40.9668 46.671 40.3141C46.671 39.6614 47.0255 39.1289 47.4612 39.1289C47.8968 39.1289 48.2528 39.6598 48.2528 40.3141C48.2528 40.9684 47.8984 41.4977 47.4612 41.4977Z'
          fill='#433D53'
        />
        <path
          d='M38.9698 41.3961C38.5701 41.3961 38.2467 40.9105 38.2467 40.3108C38.2467 39.7112 38.5701 39.2178 38.9698 39.2178C39.3696 39.2178 39.6943 39.705 39.6943 40.3108C39.6943 40.9167 39.3696 41.3961 38.9698 41.3961Z'
          fill='#433D53'
        />
        <path
          d='M50.7824 37.6189C50.7078 37.6191 50.634 37.6042 50.5654 37.5752C50.4967 37.5463 50.4346 37.5038 50.3827 37.4503C50.3124 37.38 48.7478 35.8544 45.4889 36.2589C45.416 36.268 45.3419 36.2628 45.271 36.2433C45.2001 36.2239 45.1338 36.1907 45.0758 36.1456C45.0178 36.1004 44.9692 36.0443 44.9329 35.9805C44.8966 35.9166 44.8734 35.8462 44.8643 35.7732C44.8549 35.7003 44.86 35.6262 44.8794 35.5553C44.8988 35.4843 44.9319 35.4179 44.977 35.3598C45.0221 35.3018 45.0783 35.2532 45.1423 35.217C45.2063 35.1807 45.2768 35.1575 45.3498 35.1486C49.1959 34.6708 51.1026 36.5883 51.1822 36.6711C51.285 36.7778 51.3413 36.9209 51.3386 37.0691C51.336 37.2172 51.2746 37.3583 51.168 37.4612C51.0648 37.562 50.9265 37.6185 50.7824 37.6189Z'
          fill='#433D53'
        />
        <path
          d='M41.8944 44.6748H41.8834C41.81 44.6735 41.7378 44.6579 41.6705 44.6286C41.6032 44.5994 41.5424 44.5571 41.4915 44.5043C41.4406 44.4515 41.4005 44.3892 41.3738 44.3209C41.347 44.2526 41.3339 44.1797 41.3353 44.1064C41.3994 40.8818 40.7107 38.4428 39.3507 37.053C38.1499 35.8288 36.8523 35.8147 36.8367 35.8147C36.6884 35.8147 36.5463 35.7558 36.4414 35.651C36.3366 35.5462 36.2776 35.404 36.2776 35.2557C36.2776 35.1824 36.2921 35.1097 36.3202 35.042C36.3483 34.9743 36.3895 34.9128 36.4414 34.861C36.4934 34.8092 36.5551 34.7682 36.6229 34.7402C36.6907 34.7123 36.7633 34.698 36.8367 34.6982C36.9085 34.6982 38.623 34.7154 40.1486 36.2723C41.7476 37.9025 42.5237 40.5461 42.4534 44.1282C42.4501 44.2743 42.3898 44.4133 42.2854 44.5155C42.1809 44.6176 42.0405 44.6748 41.8944 44.6748Z'
          fill='#433D53'
        />
      </g>
      <path
        d='M50.1862 99.0001C43.7138 99.0176 37.3022 97.7514 31.3224 95.2746C25.3426 92.7979 19.9133 89.1598 15.3488 84.571C10.7598 80.0066 7.12172 74.5774 4.64496 68.5976C2.1682 62.6178 0.902056 56.2061 0.919738 49.7337C0.902271 43.2616 2.16853 36.8502 4.64528 30.8707C7.12203 24.8912 10.76 19.4623 15.3488 14.8981C19.9133 10.3092 25.3426 6.67118 31.3224 4.19443C37.3022 1.71768 43.7138 0.451426 50.1862 0.468929C56.6583 0.451462 63.0697 1.71775 69.0492 4.1945C75.0287 6.67125 80.4576 10.3093 85.0219 14.8981C89.6106 19.4623 93.2486 24.8912 95.7254 30.8707C98.2021 36.8502 99.4684 43.2616 99.4509 49.7337C99.4686 56.2061 98.2024 62.6178 95.7257 68.5976C93.2489 74.5774 89.6108 80.0066 85.0219 84.571C80.4576 89.1598 75.0287 92.7978 69.0492 95.2746C63.0697 97.7513 56.6583 99.0176 50.1862 99.0001ZM50.1862 3.077C44.0564 3.06023 37.984 4.25931 32.3207 6.60483C26.6573 8.95036 21.5155 12.3958 17.1925 16.7417C12.8467 21.0644 9.40133 26.206 7.0558 31.8691C4.71028 37.5322 3.51116 43.6042 3.52781 49.7337C3.51104 55.8635 4.71012 61.9358 7.05564 67.5992C9.40117 73.2625 12.8466 78.4044 17.1925 82.7274C21.5155 87.0733 26.6573 90.5187 32.3207 92.8643C37.984 95.2098 44.0564 96.4088 50.1862 96.3921C56.3158 96.4087 62.3877 95.2096 68.0508 92.8641C73.7138 90.5185 78.8555 87.0732 83.1782 82.7274C87.5241 78.4044 90.9695 73.2625 93.315 67.5992C95.6605 61.9358 96.8596 55.8635 96.8428 49.7337C96.8595 43.6042 95.6604 37.5322 93.3148 31.8691C90.9693 26.206 87.524 21.0644 83.1782 16.7417C78.8555 12.3959 73.7138 8.95055 68.0508 6.60502C62.3877 4.2595 56.3158 3.06035 50.1862 3.077Z'
        fill='#5F93CE'
      />
    </Icon>
  );
};

export default Avatar;
