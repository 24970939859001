/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';

import { FetchDimensionItemsGroup } from 'models/requestBody/updatePropertyValues.interface';
import apiService from 'services';
import { STATUS } from 'projectConstants';

export const fetchDimensionItemsGroup = createAsyncThunk(
  'dimmension/fetch',
  async ({ currentDriver, scenario_id }: FetchDimensionItemsGroup, thunkAPI) => {
    try {
      const response = await apiService.get_dimension_items_group(
        currentDriver?.dim_id,
        currentDriver?.group_property,
        scenario_id,
      );
      return response.data.items;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchDimensionPropeties = createAsyncThunk(
  'dimmensionporperties/fetch',
  async (currentDriver: any, thunkAPI) => {
    try {
      const response = await apiService.get_dimension_properties(currentDriver.dim_id);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchConnectedDimensionItems = createAsyncThunk(
  'connectedDimensionItem/fetch',
  async ({ currentDriver, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.get_dimension_items(currentDriver?.connected_dimension, {
        params: parameters,
      });
      return response.data.items;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const PostDeleteDimensionItem = createAsyncThunk(
  'delete/post',
  async ({ ItemId, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.delete_dimension_item(ItemId, { params: parameters });
      return response.data.items;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const AddDimnesionGroupItem = createAsyncThunk(
  'Add/post',
  async ({ dimension_id, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.add_dimension_item({ dimension_id, request_body });

      return response.data.id;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const UpdateItemName = createAsyncThunk(
  'UpdateItemName/post',
  async ({ item_id, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.update_dimension_item({ item_id, request_body });

      return response.data.items;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const UpdateGroupItemName = createAsyncThunk(
  'UpdateGroupItemName/post',
  async ({ item_id, requestBody }: any, thunkAPI) => {
    try {
      const response = await apiService.update_group_item_name({
        item_id,
        request_body: requestBody,
      });
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const UpdateGroupDimName = createAsyncThunk(
  'UpdateGroupDimName/post',
  async ({ item_id, requestBody }: any, thunkAPI) => {
    try {
      const response = await apiService.update_group_dim_name({
        item_id,
        request_body: requestBody,
      });
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const DeleteGroupDimName = createAsyncThunk(
  'DeleteGroupDimName/post',
  async (ItemId: any, thunkAPI) => {
    try {
      const response = await apiService.delete_group_dim_name(ItemId);
      return response.data.items;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const DeleteGroupItemName = createAsyncThunk(
  'DeleteGroupItemName/post',
  async ({ ItemId, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.delete_group_item_name(ItemId, { params: parameters });
      return response.data.items;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const GetTableProperties = createAsyncThunk(
  'fetchTableProperties/fetch',
  async ({ selectedId, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.getDimensionProperties(selectedId, { params: parameters });
      return response.data.properties;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const GetTableItemsProperties = createAsyncThunk(
  'fetchTableItemsProperties/fetch',
  async ({ selectedId, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.getDimensionItemsWithProperties(selectedId, {
        params: parameters,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const UpdateProperty = createAsyncThunk(
  'UpdateProperty/post',
  async ({ item_id, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.update_property({ item_id, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const AddItemProperty = createAsyncThunk(
  'AddItemProperty/post',
  async ({ dimension_id, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.additem_property({ dimension_id, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const UpdatePropertyName = createAsyncThunk(
  'UpdatePropertyName/post',
  async ({ item_id, name }: any, thunkAPI) => {
    try {
      const response = await apiService.update_property_name({ item_id, name });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const UpdatePropertyBulk = createAsyncThunk(
  'UpdatePropertyBulk/post',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.update_property_bulk({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const DeleteProperty = createAsyncThunk(
  'DeletePropertyBulk/post',
  async ({ item_id, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.delete_property(item_id, { params: parameters });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const GetDimensionGroup = createAsyncThunk(
  'getDimensionGroup/get',
  async ({ data, parameters }: any, thunkAPI) => {
    try {
      const dimId = JSON.parse(data?.data_format);
      const response = await apiService.get_dimension_group(dimId?.dimension, {
        params: parameters,
      });
      return { id: data?.id, arr: response?.data?.items };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const UpdateDimensionProperty = createAsyncThunk(
  'updateDimensionProperty/get',
  async ({ item_id, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.update_property_values_group({ item_id, request_body });
      return response?.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const ReorderDimensionProperty = createAsyncThunk(
  'reorderDimensionProperty/post',
  async ({ selectedDimension, reorededArr }: any, thunkAPI) => {
    try {
      const response = await apiService.reorder_property(selectedDimension, { items: reorededArr });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

const initialState: any = {
  dimensionDataGroupStatus: STATUS.IDLE,
  dataIndicators: null,
  tableProperties: [],
  updatedTableProperties: [],
  tableItemsProperties: null,
  filteredDimItemProperties: null,
  dimensionFilters: [],
  dataIndicatorsKeys: null,
  dataIndicatorStatus: STATUS.IDLE,
  connectedItems: null,
  groupItem: null,
  showNewGroupInput: false,
  displayInfo: null,
  itemId: null,
  submitValue: null,
  addItemName: null,
  renameItemName: null,
  newItemName: null,
  isItemRenamed: false,
  addItemSuccessToast: false,
  stepNumber: null,
  dataKey: null,
  loadNewRole: STATUS.IDLE,
  openItem: null,
  orgChartType: 2,
  orgTableViewStatus: STATUS.IDLE,
  orgTableGroup: null,
  selectedElement: null,
};

const itemPageSlice = createSlice({
  initialState,
  name: 'itemPageSlice',
  reducers: {
    setopenItem: (state, action) => {
      state.openItem = action.payload;
    },
    setDisplayInfo: (state, action) => {
      state.displayInfo = action.payload;
    },
    setGroupItem: (state, action) => {
      state.groupItem = action.payload;
    },
    setShowNewGroupInput: (state, action) => {
      state.showNewGroupInput = action.payload;
    },
    onSubmitValue: (state, action) => {
      state.submitValue = action.payload;
    },
    setAddItemName: (state, action) => {
      state.addItemName = action.payload;
    },
    setRenameItemName: (state, action) => {
      state.renameItemName = action.payload;
    },
    setNewItemName: (state, action) => {
      state.newItemName = action.payload;
    },
    setIsItemRenamed: (state, action) => {
      state.newItemName = action.payload;
    },
    setStepNumber: (state, action) => {
      state.stepNumber = action.payload;
    },
    setDataKey: (state, action) => {
      state.dataKey = action.payload;
    },
    updateOrgType: (state, action) => {
      state.orgChartType = action.payload;
    },
    setSelectedElement: (state, action) => {
      state.selectedElement = action.payload;
    },
    handleDimItemsChange: (state) => {
      const dimensionItems = current(state.tableItemsProperties) || [];
      const dimensionFilter = current(state.dimensionFilters) || [];
      const filteredDimension = dimensionItems.filter((item: any) => {
        return dimensionFilter.every((filter: any) => {
          return filter.data.some(
            (filtersItem: any) => filtersItem.name === item[filter?.id] && filtersItem?.status,
          );
        });
      });
      state.filteredDimItemProperties = filteredDimension;
    },
    handleDimFiltersChange: (state, action) => {
      const dimensionFilters = current(state.dimensionFilters);
      const index = dimensionFilters?.findIndex(
        (item: any) => item?.id === String(action.payload?.id),
      );
      if (index >= 0) {
        const modifiedData = dimensionFilters[index]?.data.map((item: any) => {
          const correspondingItem = action.payload?.ischeckedItemsChecked?.find(
            (secondItem: any) => secondItem.name === item.name,
          );
          if (correspondingItem) {
            return { ...item, status: correspondingItem.status };
          }
          return item;
        });

        const isAllChecked = modifiedData.every((item: any) => item.status === true);
        const modifiedFilter = {
          ...dimensionFilters[index],
          data: modifiedData,
          isFiltersApplied: !isAllChecked,
        };
        state.dimensionFilters[index] = modifiedFilter;
      }
    },
    resetTableOrgChart: (state) => {
      state.orgTableGroup = null;
      state.tableProperties = null;
      state.updatedTableProperties = null;
      state.tableItemsProperties = null;
      state.filteredDimItemProperties = null;
      state.dimensionFilters = [];
      state.orgTableViewStatus = STATUS.IDLE;
    },
    resetItem: (state) => {
      state.dimensionDataGroupStatus = STATUS.IDLE;
      state.dataIndicators = null;
      state.tableItemsProperties = null;
      state.filteredDimItemProperties = null;
      state.dimensionFilters = [];
      state.dataIndicatorsKeys = null;
      state.dataIndicatorStatus = STATUS.IDLE;
      state.groupItem = null;
      state.showNewGroupInput = false;
      state.displayInfo = null;
      state.itemId = null;
      state.submitValue = null;
      state.addItemName = null;
      state.renameItemName = null;
      state.newItemName = null;
      state.isItemRenamed = false;
      state.addItemSuccessToast = false;
      state.stepNumber = null;
      state.dataKey = null;
      state.loadNewRole = STATUS.IDLE;
      state.connectedItems = null;
      state.orgTableViewStatus = STATUS.IDLE;
      state.selectedElement = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchConnectedDimensionItems.fulfilled, (state, action) => {
        state.connectedItems = action.payload;
      })
      .addCase(fetchDimensionItemsGroup.fulfilled, (state, action) => {
        state.dataIndicators = action.payload;
        const data = action.payload;
        const dataArray = Object.keys(data).map((key) => {
          return {
            name: key,
            dim_id: data[key].ref_dim_item_id || data[key].dim_id,
            type: data[key].type,
          };
        });
        state.dataIndicatorsKeys = dataArray;
        state.dataIndicatorStatus = STATUS.SUCCESS;
        state.showNewGroupInput = false;
        state.groupItem = null;
        state.displayInfo = null;

        state.dimensionDataGroupStatus = STATUS.SUCCESS;
      })
      .addCase(fetchDimensionItemsGroup.rejected, (state) => {
        state.dataIndicators = [];
        state.dataIndicatorsKeys = [];
        state.dataIndicatorStatus = STATUS.FAILED;
        state.groupItem = null;
        state.displayInfo = null;
        state.dimensionDataGroupStatus = STATUS.FAILED;
      })
      .addCase(fetchDimensionPropeties.fulfilled, (state, action) => {
        state.itemId = action.payload;
      })
      .addCase(AddDimnesionGroupItem.fulfilled, (state, action) => {
        state.openItem = action.payload;
        state.addItemSuccessToast = true;
        state.loadNewRole = STATUS.SUCCESS;
      })
      .addCase(UpdateItemName.fulfilled, (state) => {
        state.renameItemName = null;
      })
      .addCase(GetTableProperties.fulfilled, (state, action) => {
        state.tableProperties = action?.payload;
        state.updatedTableProperties = action?.payload;
      })
      .addCase(GetTableProperties.rejected, (state) => {
        state.tableProperties = [];
        state.updatedTableProperties = [];
      })
      .addCase(GetTableItemsProperties.fulfilled, (state, action) => {
        state.tableItemsProperties = action?.payload;
        state.filteredDimItemProperties = action?.payload;
        const transformedArray = action.payload.reduce((accumulator: any, item: any) => {
          Object.keys(item).forEach((key) => {
            if (key !== 'id' && key !== 'position') {
              const existingItem = accumulator.find((element: any) => element.id === key);
              if (existingItem) {
                const existingValue = existingItem.data.find(
                  (object: any) => object.name === item[key],
                );
                if (!existingValue) {
                  existingItem.data.push({ name: item[key], status: true });
                }
              } else {
                accumulator.push({
                  id: key,
                  data: [{ name: item[key], status: true }],
                  isFiltersApplied: false,
                  totalLength: action.payload?.length,
                });
              }
            }
          });
          return accumulator;
        }, []);

        state.dimensionFilters = transformedArray;
        state.orgTableViewStatus = STATUS.SUCCESS;
      })
      .addCase(GetTableItemsProperties.rejected, (state) => {
        state.orgTableViewStatus = STATUS.FAILED;
      })
      .addCase(UpdatePropertyBulk.fulfilled, (state, action) => {
        state.tableItemsProperties = action?.payload;
        state.filteredDimItemProperties = action?.payload;
      })
      .addCase(UpdateProperty.fulfilled, (state, action) => {
        state.tableItemsProperties = action?.payload;
        const dimensionItems = action?.payload || [];
        const dimensionFilters = current(state.dimensionFilters) || [];

        const transformedArray = action.payload.reduce((accumulator: any, item: any) => {
          Object.keys(item).forEach((key) => {
            if (key !== 'id' && key !== 'position' && key !== 'scenario_id') {
              const existingItem = accumulator.find((element: any) => element.id === key);
              if (existingItem) {
                const existingValue = existingItem.data.find(
                  (object: any) => object.name === item[key],
                );
                if (!existingValue) {
                  existingItem.data.push({ name: item[key], status: true });
                }
              } else {
                accumulator.push({
                  id: key,
                  data: [{ name: item[key], status: true }],
                  isFiltersApplied: false,
                  totalLength: action.payload?.length,
                });
              }
            }
          });
          return accumulator;
        }, []);

        transformedArray.forEach((element: any) => {
          const dimensionFilterIndex = dimensionFilters.findIndex(
            (filter: any) => filter?.id === element?.id,
          );
          element.data.forEach((item: any) => {
            const filterIndex = dimensionFilters[dimensionFilterIndex]?.data.findIndex(
              (filterItem: any) => filterItem?.name === item?.name,
            );
            if (filterIndex === -1 && item?.name) {
              const currentDimensionFilter = dimensionFilters[dimensionFilterIndex];
              state.dimensionFilters[dimensionFilterIndex] = {
                ...currentDimensionFilter,
                data: [...currentDimensionFilter.data, item],
              };
            }
          });
        });

        const dimensionFilter = current(state.dimensionFilters) || [];
        const filteredDimension = dimensionItems.filter((item: any) => {
          return dimensionFilter.every((filter: any) => {
            return filter.data.some(
              (filtersItem: any) => filtersItem.name === item[filter?.id] && filtersItem?.status,
            );
          });
        });
        state.filteredDimItemProperties = filteredDimension;
      })
      .addCase(GetDimensionGroup.fulfilled, (state, action) => {
        const tableProperties = current(state.tableProperties).map((data: any) => {
          if (data?.id === action?.payload?.id) {
            return { ...data, items: action?.payload?.arr };
          }
          return data;
        });
        state.updatedTableProperties = tableProperties;
        state.orgTableGroup = tableProperties;
      });
  },
});

export const getItemId = (state: any) => state.itemPageSlice.itemId;
export const getDataKey = (state: any) => state.itemPageSlice.dataKey;
export const getOpenItem = (state: any) => state.itemPageSlice.openItem;
export const getGroupItem = (state: any) => state.itemPageSlice.groupItem;
export const getStepNumber = (state: any) => state.itemPageSlice.stepNumber;
export const getLoadNewRole = (state: any) => state.itemPageSlice.loadNewRole;
export const getDisplayInfo = (state: any) => state.itemPageSlice.displayInfo;
export const getAddItemName = (state: any) => state.itemPageSlice.addItemName;
export const getNewItemName = (state: any) => state.itemPageSlice.newItemName;
export const getOrgTableGroup = (state: any) => state.itemPageSlice.orgTableGroup;
export const getIsItemRenamed = (state: any) => state.itemPageSlice.isItemRenamed;
export const getDataIndicators = (state: any) => state.itemPageSlice.dataIndicators;
export const getRenameItemName = (state: any) => state.itemPageSlice.renameItemName;
export const getSelectedElement = (state: any) => state.itemPageSlice.selectedElement;
export const getTableViewStatus = (state: any) => state.itemPageSlice.orgTableViewStatus;
export const getDimensionProperties = (state: any) => state.itemPageSlice.tableProperties;
export const getUpdatedDimensionProperties = (state: any) =>
  state.itemPageSlice.updatedTableProperties;
export const getShowNewGroupInput = (state: any) => state.itemPageSlice.showNewGroupInput;
export const getAddItemSuccessToast = (state: any) => state.itemPageSlice.addItemSuccessToast;
export const getDimensionItemProperties = (state: any) => state.itemPageSlice.tableItemsProperties;
export const getFilteredProperties = (state: any) => state.itemPageSlice.filteredDimItemProperties;
export const getDimensionFilters = (state: any) => state.itemPageSlice.dimensionFilters;

export const getDataIndicatorsKeys = (state: any) => {
  return state.itemPageSlice.dataIndicatorsKeys || null;
};
export const getDataIndicatorStatus = (state: any) => {
  return state.itemPageSlice.dataIndicatorStatus || null;
};
export const getDimensionDataGroupStatus = (state: any) =>
  state.itemPageSlice.dimensionDataGroupStatus;

export const {
  resetItem,
  setDataKey,
  setopenItem,
  setGroupItem,
  onSubmitValue,
  setStepNumber,
  updateOrgType,
  setDisplayInfo,
  setAddItemName,
  setNewItemName,
  setIsItemRenamed,
  setRenameItemName,
  setSelectedElement,
  resetTableOrgChart,
  setShowNewGroupInput,
  handleDimItemsChange,
  handleDimFiltersChange,
} = itemPageSlice.actions;

export default itemPageSlice.reducer;
