import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
  innerWidth: 0,
  currentDimensionIndex: 0,
};

const inputPageSlice = createSlice({
  initialState,
  name: 'inputPageSlice',
  reducers: {
    setInnerWidth: (state, action) => {
      state.innerWidth = action.payload;
    },
    setCurrentDimensionIndex: (state, action) => {
      state.currentDimensionIndex = action.payload;
    },
  },
});
export const { setInnerWidth, setCurrentDimensionIndex } = inputPageSlice.actions;

export const getInnerWidth = (state: any) => state.inputPageSlice.innerWidth;
export const getCurrentDimensionIndex = (state: any) => state.inputPageSlice?.currentDimensionIndex;
export default inputPageSlice.reducer;
