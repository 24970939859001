export default {
  variants: {
    unstyled: {
      root: { w: 'full' },
      tablist: {
        borderWidth: 1,
        borderRadius: 'sm',
        borderStyle: 'solid',
        borderColor: 'violet.900',
        overflow: 'hidden',
      },
      tab: {
        borderLeftWidth: 1,
        borderRightWidth: 1,
        borderStyle: 'solid',
        borderColor: 'violet.900',
        _last: { border: 'none' },
        _first: { border: 'none' },
        _selected: {
          color: 'white',
          bgColor: 'violet.900',
        },
      },
      tabpanel: { px: 'none' },
    },
    file: {
      root: { w: 'full' },
      tab: {
        px: 1,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderColor: 'white.400',
        bgColor: 'transparent',
        color: '#BABABA',
        fontFamily: 'regular',
        _selected: { fontWeight: 'bold', borderBottomColor: 'transparent', color: 'white.900' },
      },
      tabpanel: {
        px: 'none',
        pb: 'none',
      },
    },
    primary: {
      tablist: {
        borderBottom: '1px solid #d9d9d9',
      },
      tab: {
        color: 'black.900',
        fontFamily: 'regular_roboto',
        padding: 2,
      },
    },
    secondary: {},
    dashboarding: {
      tablist: {
        bg: 'white',
        width: '100%',
        borderBottom: '1px solid #d9d9d9',
      },
      tab: {
        maxWidth: '100px',
        _selected: {
          borderBottom: '1px solid #6562CF',
        },
      },
      tabpanel: {
        overflowY: 'auto',
        padding: '1rem 0',
      },
      tabindicator: {
        marginTop: '-1.5px',
      },
    },
  },
  sizes: {
    main: {
      tab: {
        flex: 1,
        h: 10,
        fontSize: '2xs',
      },
    },
    file: {
      tab: {
        h: { base: 15, md: 20 },
        w: 23,
        fontSize: '3xs',
      },
    },
  },
  defaultProps: {
    size: 'main',
  },
};
