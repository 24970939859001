import { Flex, Button, Spinner, useMediaQuery } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { SetTabs, SetUserModalVisible } from 'redux/UserSlice';

interface Properties {
  redirectTo?: number;
  buttonName?: string;
  disabled?: boolean;
  isSubmitting?: boolean;
}

const UserFooter = ({ redirectTo, disabled, buttonName, isSubmitting }: Properties) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isLaptop] = useMediaQuery('(max-height: 650px)');
  const dispatch: any = useDispatch();
  return (
    <Flex padding={isMobile ? '2rem 0 0 2rem' : '0 2rem 1rem 2rem'} justifyContent={'end'}>
      <Button
        w='fit-content'
        h={isLaptop ? '10' : '12'}
        borderRadius='10px'
        fontSize='xs'
        color='violet.800'
        bgColor='grey.500'
        mr='4'
        onClick={() => {
          if (redirectTo) {
            dispatch(SetTabs(redirectTo));
          } else if (isMobile) {
            dispatch(SetTabs(0));
          } else {
            dispatch(SetUserModalVisible(false));
          }
        }}
      >
        Cancel
      </Button>
      <Button
        _hover={{ _disabled: {} }}
        disabled={disabled}
        type='submit'
        w='fit-content'
        h={isLaptop ? '10' : '12'}
        borderRadius='10px'
        fontSize='xs'
        bgColor='violet.800'
      >
        {buttonName || 'Save Changes'}
        {isSubmitting && <Spinner ml={'4'} />}
      </Button>
    </Flex>
  );
};

export default UserFooter;
