import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  FetchBlockOutputs,
  FetchPlannerModeItems,
  ResetTable,
  getBaseScenario,
  getPlannerBlock,
  getPlannerModel,
  getToolbarType,
} from 'redux/PlannerModeSlice';

import BuilderModeDataTable from './DataTable';
import BuilderModePropertyTable from './PropertyTable';
import MapTab from './MapTab';

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const BuilderModeTable = () => {
  const { blockId } = useParams();
  const toolbarType = useSelector(getToolbarType);
  const plannerBlock: any = useSelector(getPlannerBlock);
  const modelDetails = useSelector(getPlannerModel);
  const baseScenario = useSelector(getBaseScenario);
  const dispatch: any = useDispatch();

  useEffect(() => {
    const getIntialData = async () => {
      const startDateObject = new Date(baseScenario?.start_date);
      startDateObject.setMonth(startDateObject.getMonth() - 1);
      const yearFormat = `FY${startDateObject.getFullYear().toString().slice(-2)}`;
      const monthFormat = `${monthNames[startDateObject.getMonth()]}-${String(yearFormat).slice(
        -2,
      )}`;

      if (plannerBlock?.indicators?.length) {
        const idArray = plannerBlock?.dimensions
          .filter((item: any) => item.name !== 'Time')
          .map((item: any) => item.id);
        const dimensionIds = idArray.join(', ');
        const payload = {
          blockId,
          params: {
            dim_id: dimensionIds,
            indicator_filter: 'all',
            scenario_id: baseScenario?.id,
          },
        };
        dispatch(FetchBlockOutputs(payload));
      }

      const dimId = plannerBlock?.dimensions?.find((data: any) => {
        return data?.name === 'Time';
      });

      dispatch(
        FetchPlannerModeItems({
          dimensionId: dimId?.id,
          params: { scenario_id: baseScenario?.id },
          actuals:
            modelDetails?.time_properties?.time_granularity.toLowerCase() === 'm'
              ? monthFormat
              : yearFormat,
        }),
      );
    };
    getIntialData();
  }, [baseScenario]);

  useEffect(() => {
    return () => {
      dispatch(ResetTable());
    };
  }, []);

  if (toolbarType === 'Data') {
    return <BuilderModeDataTable />;
  }

  if (toolbarType === 'Property') {
    return <BuilderModePropertyTable />;
  }

  return <MapTab />;
};

export default BuilderModeTable;
