import { Image, StyleProps } from '@chakra-ui/react';

import loginImage from 'assets/login_scooter_icon.png';

interface Properties {
  width?: any;
  height?: any;
  styles?: StyleProps;
}

const LoginImage = ({ width, height, styles }: Properties) => {
  return <Image src={loginImage} w={width} h={height} {...styles} />;
};

export default LoginImage;
