import {
  Box,
  Button,
  Flex,
  FormControl,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useBoolean,
  useMediaQuery,
  VStack,
  useOutsideClick,
  FormLabel,
} from '@chakra-ui/react';
import { ErrorMessage, Form, Formik } from 'formik';
import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';

import FormField from 'components/FormField';
import Reset from 'components/Icons/Reset';
import { InputAdjutment } from 'models/requestBody/getModelOutputs.interface';
import { IndicatorDriver } from 'models/response/driver.interface';
import { adjustInput, editDriverDataInput } from 'redux/driverAdjustmentSlice';
import { getModelDetails, setEmptyData } from 'redux/PlanPageSlice';
import { fetchAllScenarios, getAllScenarios, getBaseScenario } from 'redux/ScenarioSlice';
import PlanPermission from 'container/PlanPermission';
import { getUserSubscriptionLimits } from 'redux/UserSlice';
import ScenarioPermission from 'pages/PermissionComponent/ScenarioPermission';
import { getBlocks } from 'redux/ModelsSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

import SliderComponent from './SliderComponent';
import ActionSection from './ActionSection';
import ScenarioDatePicker from './Filters/ScenarioDatePicker';

interface Properties {
  planPermission: boolean;
  drivers: IndicatorDriver[];
  onToggleAuthModal?: () => void;
}
const validationSchema = Yup.object({
  scenarioName: Yup.string().required('Scenario name is required'),
  scenarioDescription: Yup.string(),
  // startDate: Yup.string().required('StartDate is required'),
});

const EditDriversSection = ({ drivers, planPermission }: Properties) => {
  const { modelId, blockId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const reference = useRef() as React.MutableRefObject<HTMLInputElement>;
  const [smallLaptop] = useMediaQuery('(max-height: 768px)');
  const [isResetClicked, setIsResetClicked] = useState(false);
  const [isPermissionComponent, setIsPermissionComponent] = useState(false);
  const [startIn, setStartIn] = useState<string>('');
  const [isSelected, setIsSelected] = useBoolean();
  const [popoverSeleceted, setPopoverSeleceted] = useBoolean();
  const [isEditDriverInputsDataChanged, setIsEditDriverInputsDataChanged] = useState(false);
  const [editDriverInputsData, setEditDriverInputsData]: any = useState({});
  const baseScenario = useSelector(getBaseScenario);
  const UserSubscriptionLimitData = useSelector(getUserSubscriptionLimits);
  const scenarios = useSelector(getAllScenarios);
  const modelDetails = useSelector(getModelDetails);
  const blockDetails = useSelector(getBlocks);
  const blockData = Object.keys(blockDetails).length > 0 ? blockDetails : modelDetails;

  const handleClearSelection = () => {
    setStartIn('');
  };
  useEffect(() => {
    setIsEditDriverInputsDataChanged(true);
  }, [editDriverInputsData]);
  const onAdjustInput = (parameters: InputAdjutment) => {
    dispatch(setEmptyData(true));
    setEditDriverInputsData(parameters);
    dispatch(adjustInput(parameters));
  };

  const onResetClick = () => {
    onAdjustInput({});
    setIsResetClicked(true);
  };

  const inputsDriverData = async () => {
    const requestBody = { input_adjustments: editDriverInputsData, scenario_id: baseScenario?.id };
    const data = {
      blockId,
      requestBody,
      toaster: {
        successMessage: `Input is saved to scenario ${baseScenario?.name}`,
        errorMessage: 'Failed to saved data',
      },
    };
    if (isEditDriverInputsDataChanged === true && Object.keys(editDriverInputsData).length > 0) {
      await dispatch(editDriverDataInput(data));
      setIsEditDriverInputsDataChanged(false);
      setIsResetClicked(true);

      setPopoverSeleceted.off();
    }
  };

  const handleSubmit = async (values: any, { resetForm }: any) => {
    const requestBody = {
      input_adjustments: editDriverInputsData,
      name: values?.scenarioName,
      description: values?.scenarioDescription,
      scenario_id: baseScenario?.id,
      start_date: values?.startDate,
    };
    const data = {
      blockId,
      requestBody,
      toaster: {
        successMessage: `Input is saved to scenario ${values?.scenarioName}`,
        errorMessage: 'A scenario with this name already exists',
      },
    };
    await dispatch(editDriverDataInput(data));
    setIsEditDriverInputsDataChanged(false);
    setIsResetClicked(true);

    setIsSelected.off();
    setPopoverSeleceted.off();
    await dispatch(fetchAllScenarios(modelId));
    resetForm();
    setStartIn('');
  };

  const handleCancel = (resetForm: () => void) => {
    resetForm();
    setIsSelected.off();
    setPopoverSeleceted.off();
    setStartIn('');
  };

  const openPopover = () => {
    if (UserSubscriptionLimitData?.subscription_features_limit?.scenario_per_model === null) {
      setIsPermissionComponent(false);
      setIsSelected.on();
    } else if (
      scenarios?.length ===
        UserSubscriptionLimitData?.subscription_features_limit?.scenario_per_model ||
      scenarios?.length >=
        UserSubscriptionLimitData?.subscription_features_limit?.scenario_per_model
    ) {
      setIsSelected.off();
      setIsPermissionComponent(true);
    } else if (planPermission === true) {
      setIsSelected.off();
    } else {
      setIsPermissionComponent(false);
      setIsSelected.on();
    }
  };
  useOutsideClick({
    ref: reference,
    handler: () => {
      setIsSelected.off();
      setIsPermissionComponent(false);
    },
  });

  return (
    <>
      {drivers?.length > 0 && (
        <Box mb={5} pl={4}>
          <Flex alignItems={'center'} justifyContent='space-between' mt='3.5'>
            <Text fontFamily='bold' fontSize='lg' lineHeight='9'>
              Edit inputs
            </Text>
          </Flex>

          <VStack
            pt={4}
            spacing='4'
            maxH={{ md: !smallLaptop ? 100 : 40 }}
            pb='5'
            mb='5'
            overflowY={{ md: 'auto' }}
            overflowX='hidden'
            className='scroll'
          >
            {drivers.map((driver) => (
              <SliderComponent
                key={`${driver.id} + ${driver.type}`}
                driver={driver}
                onAdjustInput={onAdjustInput}
                isResetClicked={isResetClicked}
                setIsResetClicked={setIsResetClicked}
              />
            ))}
          </VStack>
          <Box display='flex' justifyContent='space-between'>
            <Button
              leftIcon={<Reset />}
              variant='ghost'
              w='auto'
              h='auto'
              p='0'
              fontWeight='400'
              fontSize='2xs'
              onClick={onResetClick}
            >
              Reset
            </Button>
            <Popover
              placement='bottom-start'
              isOpen={popoverSeleceted}
              onOpen={planPermission ? undefined : setPopoverSeleceted.on}
              onClose={setPopoverSeleceted.off}
              closeOnBlur={true}
              isLazy
              lazyBehavior='keepMounted'
            >
              <Box ref={reference}>
                <PopoverTrigger>
                  <Button
                    colorScheme='white'
                    p='0'
                    my='0'
                    ms='0'
                    me='2'
                    height='2rem'
                    fontWeight='400'
                    fontSize='xs'
                    textTransform='capitalize'
                    borderRadius='8px'
                    width={'65px'}
                    boxShadow={'none !important'}
                    cursor={planPermission ? 'not-allowed' : 'pointer'}
                  >
                    Save
                  </Button>
                </PopoverTrigger>
                <PopoverContent
                  color='black'
                  bg='white'
                  borderColor='#7163D0'
                  borderRadius='11px'
                  width={'fit-content'}
                  p={0}
                  m={0}
                >
                  <Button
                    borderBottom={0}
                    color='#212121'
                    bg='white'
                    height={'2.5rem'}
                    _hover={{ bg: '#EFEEFE' }}
                    borderRadius='5px'
                    width={'100%'}
                    onClick={inputsDriverData}
                    textDecoration={'none'}
                    fontFamily={'regular_roboto'}
                    fontSize={'14px'}
                    fontWeight={'500'}
                    textTransform='none'
                  >
                    Save to this scenario
                  </Button>
                  <PopoverBody m={0} p={0}>
                    <Popover
                      placement='left-start'
                      isOpen={isSelected}
                      onOpen={openPopover}
                      onClose={setIsSelected.off}
                      closeOnBlur={true}
                      isLazy
                      lazyBehavior='keepMounted'
                    >
                      <PopoverTrigger>
                        <Button
                          color='#212121'
                          cursor='pointer'
                          alignSelf={'center'}
                          height={'2.5rem'}
                          _hover={{ bg: '#EFEEFE' }}
                          borderRadius='5px'
                          width={'100%'}
                          textDecoration={'none'}
                          fontFamily={'regular_roboto'}
                          fontSize={'14px'}
                          fontWeight={'500'}
                          bg={isSelected ? '#EFEEFE' : 'white'}
                          textTransform='none'
                        >
                          Save to new scenario
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent bg='white' color='#212121' p={'4'} width={'260px'}>
                        <Box>
                          <Text
                            color='#6562CF'
                            fontSize={{ base: 'xs', md: 'sm' }}
                            fontWeight={'600'}
                            mb={2}
                          >
                            Enter Details
                          </Text>
                          <Formik
                            initialValues={{
                              scenarioName: '',
                              scenarioDescription: '',
                              startDate: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                          >
                            {({ errors, touched, resetForm, setFieldValue }) => (
                              <Form>
                                <FormControl>
                                  <FormField
                                    label='Name'
                                    name='scenarioName'
                                    type='text'
                                    labelStyles={{
                                      fontSize: '0.8rem',
                                      color: '#212121',
                                      fontWeight: '600',
                                      opacity: 0.8,
                                    }}
                                    inputStyles={{
                                      maxWidth: '300px',
                                      height: '2.3rem',
                                      boxShadow: '0px 0px 0px 0.6px #8B89B1',
                                      fontSize: '0.8rem',
                                      color: '#212121',
                                    }}
                                    placeholder='Name'
                                    error={errors.scenarioName}
                                    touched={touched.scenarioName}
                                  />
                                </FormControl>

                                <FormControl mt={4}>
                                  <FormField
                                    label='Description'
                                    name='scenarioDescription'
                                    type='text'
                                    labelStyles={{
                                      fontSize: '0.8rem',
                                      color: '#212121',
                                      fontWeight: '600',
                                      opacity: 0.8,
                                    }}
                                    inputStyles={{
                                      maxWidth: '300px',
                                      fontSize: '0.8rem',
                                      color: '#212121',
                                      height: '2.3rem',
                                      boxShadow: '0px 0px 0px 0.6px #8B89B1',
                                    }}
                                    placeholder='Description'
                                  />
                                </FormControl>
                                <FormControl mt={4}>
                                  <FormLabel
                                    fontSize='0.9rem'
                                    color='black'
                                    htmlFor='selecetDimension'
                                    fontFamily={'Roboto Regular'}
                                  >
                                    Forecast start date
                                  </FormLabel>
                                  <Flex bgColor={'white'}>
                                    <ScenarioDatePicker
                                      clearSection={false}
                                      clearDateInput={handleClearSelection}
                                      selected={startIn ? new Date(startIn) : null}
                                      onChange={(date: Date) => {
                                        setStartIn(date.toDateString());
                                        setFieldValue('startDate', date.toDateString());
                                      }}
                                      timescale={
                                        blockData?.time_properties?.time_granularity === 'Y'
                                          ? 'Y'
                                          : 'M'
                                      }
                                    />
                                  </Flex>
                                  <ErrorMessage
                                    name='startDate'
                                    component='div'
                                    className='error'
                                  />
                                </FormControl>

                                <Flex
                                  mt={4}
                                  color={'black.900'}
                                  fontSize='xs'
                                  display={'flex'}
                                  justifyContent={'flex-end'}
                                  alignContent={'center'}
                                >
                                  <Button
                                    type='reset'
                                    onClick={() => handleCancel(resetForm)}
                                    width={'80px'}
                                    height={'30px'}
                                    borderRadius='xs'
                                    fontSize='xs'
                                    bgColor='#EFEEFE'
                                    color='#6562CF'
                                    fontWeight='400'
                                    me={'1rem'}
                                    fontFamily={'Roboto Regular'}
                                    px='10'
                                    py='4'
                                    textTransform='capitalize'
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    type='submit'
                                    width={'80px'}
                                    height={'30px'}
                                    borderRadius='xs'
                                    fontSize='xs'
                                    bgColor='#6562CF'
                                    fontWeight='400'
                                    fontFamily={'Roboto Regular'}
                                    px='10'
                                    py='4'
                                    textTransform='capitalize'
                                  >
                                    Save
                                  </Button>
                                </Flex>
                              </Form>
                            )}
                          </Formik>
                        </Box>
                      </PopoverContent>
                    </Popover>
                    {isPermissionComponent && (
                      <Flex px={1}>
                        <ScenarioPermission
                          ScenarioLimit={
                            UserSubscriptionLimitData?.subscription_features_limit
                              ?.scenario_per_model
                          }
                          CurrentScenarioLength={scenarios?.length}
                        />
                      </Flex>
                    )}
                  </PopoverBody>
                </PopoverContent>
              </Box>
            </Popover>
          </Box>
          <Box display={{ base: 'none', md: 'block' }}>
            <ActionSection />
          </Box>
        </Box>
      )}
    </>
  );
};

export default PlanPermission(EditDriversSection);
