import { Box, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Slide } from '@chakra-ui/react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLongPress } from 'use-long-press';

import PlanIconGreen from 'components/Icons/PlanIconGreen';
import EditPencilGreen from 'components/Icons/EditPencilGreen';
import EditPencilIcon from 'components/Icons/EditPencilIcon';
import PlanIcon from 'components/Icons/PlanIcon';
import { getSelectedDriver } from 'redux/PlanPageSlice';
import { HEADER_HEIGHT_WITH_NAVIGATION } from 'projectConstants';

import DriversSection from './driversSection';
import PlanSection from './Plan/planSection';
import MobilePanel from './ItemPage/MobilePanel';
// import IndicatorPage from './Inputs/IndicatorPage';
import DimesionPage from './Inputs/DimensionPage';
import IndicatorPageMultiDim from './Inputs/IndicatorPageMultiDim';

export interface Properties {
  refetchRequest: () => void;
}

const MobileLayout = ({ refetchRequest }: Properties) => {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const currentDriver = useSelector(getSelectedDriver);
  const handleTabIndexChange = () => {
    setSelectedTabIndex(!selectedTabIndex ? 1 : 0);
  };
  const [isLongClicked, setIsLongClicked] = useState<boolean>(false);

  const longClickCheck = useLongPress(() => {
    setIsLongClicked((previousState) => !previousState);
  });

  const showCurrentPage = () => {
    if (currentDriver?.type === 'dimension') {
      return (
        <MobilePanel
          longClickCheck={longClickCheck}
          isLongClicked={isLongClicked}
          refetchRequest={refetchRequest}
        />
      );
    }
    if (currentDriver?.type === 'indicator') {
      return <IndicatorPageMultiDim refetchRequest={refetchRequest} />;
    }
    return <DimesionPage refetchRequest={refetchRequest} />;
  };
  if (currentDriver) {
    return (
      <Slide
        unmountOnExit
        direction='right'
        in={currentDriver}
        style={{
          position: 'relative',
          height: '100%',
          transform: 'translateX(-100%)',
          zIndex: '1',
        }}
      >
        <Flex
          display='flex'
          flexDirection='column'
          bg={{ md: 'gradient.body_dark' }}
          overflowY='auto'
          className='scroll'
          h={`calc(100vh - ${HEADER_HEIGHT_WITH_NAVIGATION} - 10px)`}
          width='100%'
          justifyContent='space-between'
        >
          {showCurrentPage()}
        </Flex>
      </Slide>
    );
  }
  return (
    <>
      <Box my='4'>
        <Text fontFamily='regular' fontSize={'lg'} textAlign={'start'} pb='2'>
          Welcome to your Blox plan!
        </Text>
        <Text fontFamily='regular' fontSize={'2xs'} textAlign={'start'}>
          Enter and adjust your inputs here first then view your plan
        </Text>
      </Box>
      <Tabs isFitted variant='file' onChange={handleTabIndexChange}>
        <TabList>
          <Tab
            color='#6562CF'
            _selected={{
              color: 'white',
              borderBottom: '3px solid #4BB6B9',
              borderTopRadius: '10px',
            }}
          >
            <Flex alignItems={'center'} align={'start'} fontFamily='regular_roboto'>
              {selectedTabIndex ? <EditPencilIcon /> : <EditPencilGreen />}
              <Text fontSize={'md'} ml={2} fontFamily='regular_roboto'>
                Input
              </Text>
            </Flex>
          </Tab>
          <Tab
            color='#6562CF'
            _selected={{
              color: 'white',
              borderBottom: '3px solid #4BB6B9',
              borderTopRadius: '10px',
            }}
          >
            <Flex alignItems={'center'} align={'start'} fontFamily='regular_roboto'>
              {!selectedTabIndex ? <PlanIcon /> : <PlanIconGreen />}
              <Text fontSize={'md'} ml={2}>
                Plan
              </Text>
            </Flex>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            {selectedTabIndex === 0 && <DriversSection refetchRequest={refetchRequest} />}
          </TabPanel>
          <TabPanel>
            {selectedTabIndex === 1 && <PlanSection refetchRequest={refetchRequest} />}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};
export default MobileLayout;
