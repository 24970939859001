/* eslint-disable class-methods-use-this */
import { Box, Flex } from '@chakra-ui/react';
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  UncertainCompatible,
  getCellProperty,
  getCharFromKeyCode,
  isAlphaNumericKey,
  isNavigationKey,
  keyCodes,
} from '@silevis/reactgrid';

export interface TextCell extends Cell {
  type: 'text';
  text: string;
  placeholder?: string;
  validator?: (text: string) => boolean;
  renderer?: (text: string) => React.ReactNode;
  errorMessage?: string;
  property?: string;
  rows?: number;
  colType?: string;
  format?: string;
  indicatorType?: string;
  isDraggable?: boolean;
  tableName?: string;
  hasFormulaError?: boolean;
}

export class TextCellTemplate implements CellTemplate<TextCell> {
  onDeleteHeader?: (rowId: any) => void;

  constructor(onDeleteHeader?: (rowId: any) => void) {
    this.onDeleteHeader = onDeleteHeader;
  }

  getCompatibleCell(uncertainCell: Uncertain<TextCell>): Compatible<TextCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    let placeholder: string | undefined;
    try {
      placeholder = getCellProperty(uncertainCell, 'placeholder', 'string');
    } catch {
      placeholder = '';
    }
    const value = Number.parseFloat(text); // TODO more advanced parsing for all text based cells
    return { ...uncertainCell, text, value, placeholder };
  }

  isFocusable?(cell: Compatible<any>): boolean {
    return cell?.property !== 'dimension';
  }

  update(
    cell: Compatible<TextCell>,
    cellToMerge: UncertainCompatible<TextCell>,
  ): Compatible<TextCell> {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text,
      placeholder: cellToMerge.placeholder,
    });
  }

  handleKeyDown(
    cell: Compatible<TextCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
  ): { cell: Compatible<TextCell>; enableEditMode: boolean } {
    const char = getCharFromKeyCode(keyCode, shift);
    if (!ctrl && !alt && isAlphaNumericKey(keyCode) && !(shift && keyCode === keyCodes.SPACE))
      return {
        cell: this.getCompatibleCell({
          ...cell,
          text: shift ? char : char.toLowerCase(),
        }),
        enableEditMode: true,
      };
    return {
      cell,
      enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }

  getClassName(cell: Compatible<TextCell>): string {
    const isValid = cell.validator ? cell.validator(cell.text) : true;
    const className = cell.className || '';
    return `${className} ${isValid ? 'valid' : 'invalid'} ${
      cell.placeholder && cell.text === '' ? 'placeholder' : ''
    }`;
  }

  numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  render(
    cell: any,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<TextCell>, commit: boolean) => void,
  ): React.ReactNode {
    const format = cell.format?.toLocaleLowerCase();
    const cellText: any = cell?.format ? Number(cell.text) : cell.text;
    const text =
      format === 'number'
        ? `${this.numberWithCommas(cellText?.toFixed(2))}`
        : format === 'integer'
        ? `${this.numberWithCommas(cellText?.toFixed(0))}`
        : format === 'percentage'
        ? `${cellText?.toFixed(1)}%`
        : format === 'currency'
        ? cellText < 0
          ? `(${cell?.currencyType}${this.numberWithCommas(Math.abs(cellText?.toFixed(2)))})`
          : `${cell?.currencyType}${this.numberWithCommas(cellText?.toFixed(2))}`
        : cellText;
    const show = cell?.show;
    if (!isInEditMode) {
      return (
        <div
          style={{
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontSize: '15px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          {cell?.property === 'dimension' ? (
            <Flex width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
              <Flex width={'100%'} pointerEvents={show ? 'auto' : 'none'}>
                <Box
                  border={`1px solid ${cell.color}`}
                  bg={cell.color}
                  h={!cell.color ? '0px' : '20px'}
                  w={!cell.color ? '0px' : '20px'}
                  mr='2'
                ></Box>
                <p
                  style={{
                    paddingLeft: cell?.property === 'name' ? '.2rem' : '0',
                  }}
                >
                  {`${cell.text}` || cell.placeholder || ''}
                </p>
              </Flex>
            </Flex>
          ) : (
            <Flex w={'100%'} align={'center'} justify={'center'}>
              <p
                style={{
                  paddingLeft: cell?.property === 'name' ? '.2rem' : '0',
                  textAlign: 'center',
                }}
              >
                {cell.rowId === 'growth_rate'
                  ? `${cell.text}%`
                  : cell?.nonEditable
                  ? ''
                  : `${text}` || cell.placeholder || ''}
              </p>
            </Flex>
          )}
          {cell?.colType === 'header' && 'del'}
        </div>
      );
    }

    return (
      <Flex w={'100%'}>
        <input
          disabled={cell.nonEditable}
          style={{
            backgroundColor: '#423c82',
            color: 'white',
            width: '100%',
          }}
          ref={(input) => {
            if (input) {
              input.focus();
              input.setSelectionRange(input.value.length, input.value.length);
            }
          }}
          defaultValue={cell.text}
          onChange={(event) =>
            onCellChanged(
              this.getCompatibleCell({
                ...cell,
                text: event.currentTarget.value,
              }),
              false,
            )
          }
          onBlur={(event) =>
            onCellChanged(
              this.getCompatibleCell({
                ...cell,
                text: event.currentTarget.value,
              }),
              (event as any).view?.event?.keyCode !== keyCodes.ESCAPE,
            )
          }
          onCopy={(event) => event.stopPropagation()}
          onCut={(event) => event.stopPropagation()}
          onPaste={(event) => event.stopPropagation()}
          onPointerDown={(event) => event.stopPropagation()}
          placeholder={cell.placeholder}
          onKeyDown={(event) => {
            if (isAlphaNumericKey(event.keyCode) || isNavigationKey(event.keyCode))
              event.stopPropagation();
          }}
        />
      </Flex>
    );
  }
}
