const VoiceFlowGenerateIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='27' height='27' viewBox='0 1 14 16' fill='none'>
      <g clipPath='url(#clip0_24_100)'>
        <path
          d='M11.4241 10.4158V12.7996C11.4241 14.0737 10.3884 15.1094 9.11429 15.1094H3.36848C2.09438 15.1094 1.05865 14.0737 1.05865 12.7996V7.05379C1.05865 5.77968 2.09438 4.74396 3.36848 4.74396H4.99605'
          stroke='#77B3B9'
          strokeWidth='1.0275'
          strokeMiterlimit='10'
          strokeLinecap='round'
        />
        <path
          d='M5.72759 6.30981L7.07979 8.63608L9.70609 9.24026L7.37571 10.5925L6.77565 13.2146L5.42345 10.8884L2.79715 10.2842L5.12753 8.93201L5.72759 6.30981Z'
          fill='#8DB684'
        />
        <path
          d='M9.91984 0.370911L9.58693 2.39304L10.8405 4.02061L8.81835 3.68769L7.1949 4.94125L7.5237 2.91501L6.27014 1.29155L8.29638 1.62447L9.91984 0.370911Z'
          fill='#7491C5'
        />
        <path
          d='M12.1845 4.42334L12.5215 5.99748L13.9025 6.8277L12.3283 7.16472L11.5022 8.54569L11.1652 6.97155L9.78424 6.14133L11.3584 5.80431L12.1845 4.42334Z'
          fill='#9190C6'
        />
      </g>
      <defs>
        <clipPath id='clip0_24_100'>
          <rect
            width='13.3576'
            height='15.2523'
            fill='white'
            transform='translate(0.544861 0.370911)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VoiceFlowGenerateIcon;
