export default {
  variants: {
    primary: {
      button: {},
      list: {
        minWidth: '200px',
        padding: '.5rem .8rem',
      },
      item: {
        borderRadius: '10px',
        _hover: { backgroundColor: '#EFEEFE' },
        _active: { backgroundColor: '#EFEEFE' },
        _focus: { backgroundColor: '#EFEEFE' },
      },
    },
    secondary: {
      button: {
        border: 'none',
        padding: '.5rem .8rem',
        fontWeight: '400',
        fontSize: 'xs',
        width: 'fit-content',
      },
      list: {
        minWidth: 'fit-content',
      },
      item: {
        _hover: { background: 'transparent' },
        _active: { backgroundColor: 'transparent' },
        _focus: { backgroundColor: 'transparent' },

        borderRadius: '10px',
      },
    },
    breadCrumb: {
      button: {
        ml: 1,
      },
      list: {
        borderRadius: '10px',
      },
      item: {
        minH: '30px',
        color: 'black',
      },
    },
    filter: {
      button: {},
      list: {
        border: '4px #4F71B5 solid',
        p: 5,
        minWidth: 'unset',
      },
      item: {
        border: '1px black solid',
        width: '300px',
      },
    },
    dashboarding: {
      button: {},
      list: {
        borderRadius: '10px',
        padding: '.4rem',
        margin: '0 .3rem',
      },
      item: {
        borderRadius: '10px',
      },
    },
    dashboardingModal: {
      button: {},
      list: {
        borderRadius: '10px',
        padding: '.4rem',
        margin: '0 .3rem',
      },
      item: {
        borderRadius: '10px',
      },
    },
  },
  baseStyle: {
    button: {
      fontWeight: 'medium',
      color: '#1A202C',
      width: 'fit-content',
      borderRadius: '10px',
      minWidth: 'fit-content',
      border: 'none',
    },
    list: {
      zIndex: '3',
      py: '3',
      borderRadius: '10px',
      border: 'none',
      width: '100%',
      minWidth: 'fit-content',
      boxShadow: '1px 1px 10px #6e6e6e',
      color: 'black',
    },
    item: {
      padding: '.5rem 2rem .5rem 1rem',
      color: '#1A202C',
      _hover: {
        bg: '#EEF2F6',
      },
      _focus: {
        bg: '#EEF2F6',
      },
    },
  },
};
