import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import 'matchmedia-polyfill';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from 'components/App';
import Fonts from 'components/Fonts';
import store from 'redux/store/store';
import theme from 'styles/theme';

async function run() {
  const { REACT_APP_LAUNCH_DARKLY_CLIENT_KEY } = process.env;

  const container = document.querySelector('#root');
  if (!container) throw new Error('Failed to find the root element');

  const root = ReactDOM.createRoot(container);

  const LDProvider = await asyncWithLDProvider({
    clientSideID: REACT_APP_LAUNCH_DARKLY_CLIENT_KEY || '63ce906386fd1a137aa04931',
  });

  const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
  });

  root.render(
    <Provider store={store}>
      <LDProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ColorModeScript />
            <ChakraProvider theme={theme}>
              <Fonts />
              <App />
            </ChakraProvider>
          </BrowserRouter>
        </QueryClientProvider>
      </LDProvider>
    </Provider>,
  );
}
function main() {
  try {
    run();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}

main();
