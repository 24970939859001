import { HStack, Td, Text, Tooltip, Tr, useMediaQuery } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { MdEdit } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { PropertyValue } from 'models/response/propertyValue.interface';
import { UpdatePropertyValues } from 'models/requestBody/updatePropertyValues.interface';
import { UpdateDimensionItem } from 'models/requestBody/updateDimensionItem.interface';
import {
  deleteDimensionItem,
  fetchPropertyValue,
  getEditMode,
  setEditNameMode,
  updateDimensionItem,
  updatePropertyValue,
} from 'redux/DimensionPageSlice';
import { getSelectedDriver } from 'redux/PlanPageSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';
import DeleteModal from 'components/Modals/DeleteModal';
import { AppDispatch } from 'utils/GlobalHelpers';

import EditableField from './EditableField';

interface Properties {
  property: PropertyValue;
  propertyId: number;
  refetchRequest: () => void;
  planPermission?: boolean;
}

const PropertyTableRaw = ({ property, propertyId, refetchRequest, planPermission }: Properties) => {
  const editNameMode = useSelector(getEditMode);
  const [isMobile] = useMediaQuery('(max-width: 575px)');
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const currentDriver = useSelector(getSelectedDriver);
  const baseScenario = useSelector(getBaseScenario);
  const [defaultProperty, setDefaultProperty] = useState(property);

  useEffect(() => {
    setDefaultProperty(property);
  }, [property]);

  const removeItem = (itemId: number) => {
    dispatch(deleteDimensionItem({ itemId, parameters: { scenario_id: baseScenario?.id } })).then(
      () => {
        dispatch(
          fetchPropertyValue({
            propertyId: currentDriver.property_id,
            parameters: { scenario_id: baseScenario?.id },
          }),
        );
        refetchRequest();
      },
    );
  };

  const updateValue = async ({ item_id, request_body }: UpdatePropertyValues) => {
    await dispatch(updatePropertyValue({ item_id, request_body }));
    await dispatch(
      fetchPropertyValue({
        propertyId: currentDriver.property_id,
        parameters: { scenario_id: baseScenario?.id },
      }),
    );
    refetchRequest();
  };

  const updateName = async ({ item_id, request_body }: UpdateDimensionItem) => {
    await dispatch(updateDimensionItem({ item_id, request_body }));
    await dispatch(
      fetchPropertyValue({
        propertyId: currentDriver.property_id,
        parameters: { scenario_id: baseScenario?.id },
      }),
    );
    refetchRequest();
  };

  const onToggleDeleteModal = () => {
    setIsDeleteModal((previousState) => !previousState);
  };

  const onOpenEditMode = () => {
    dispatch(
      setEditNameMode({
        state: true,
        itemId: defaultProperty.item_id,
      }),
    );
  };

  const onCloseEditMode = () => {
    dispatch(
      setEditNameMode({
        state: false,
        itemId: defaultProperty.item_id,
      }),
    );
  };

  const onDeleteItem = async () => {
    removeItem(defaultProperty.item_id);
    setIsDeleteModal(false);
  };

  const onSubmitValue = (value: string) => {
    updateValue({
      item_id: property.item_id,
      request_body: {
        property_id: propertyId,
        value,
        scenario_id: baseScenario?.id,
      },
    });
  };

  const onSubmitName = async (value: string) => {
    await updateName({
      item_id: property.item_id,
      request_body: { name: value, scenario_id: baseScenario?.id },
    });
    onCloseEditMode();
  };

  return (
    <>
      <Tr>
        <Td>
          <HStack spacing='3' alignItems='center'>
            {!planPermission &&
            editNameMode.state &&
            editNameMode.itemId === defaultProperty.item_id ? (
              <EditableField
                defaultValue={defaultProperty.name}
                onSubmit={onSubmitName}
                isEditNameMode={editNameMode.state}
                closeEditMode={onCloseEditMode}
              />
            ) : (
              <>
                <Tooltip label={defaultProperty.name}>
                  <Text
                    onDoubleClick={planPermission ? undefined : onOpenEditMode}
                    noOfLines={1}
                    display='block'
                    style={{ maxWidth: '100%', width: '100%' }}
                  >
                    {defaultProperty.name}
                  </Text>
                </Tooltip>
                <MdEdit
                  style={{ cursor: planPermission ? 'not-allowed' : 'pointer' }}
                  fontSize='16'
                  onClick={planPermission ? undefined : onOpenEditMode}
                  className={isMobile ? 'mobile_icon' : 'pc_icon'}
                />
                <FaTrash
                  style={{ cursor: planPermission ? 'not-allowed' : 'pointer' }}
                  fontSize='14'
                  onClick={planPermission ? undefined : onToggleDeleteModal}
                  className={isMobile ? 'mobile_icon' : 'pc_icon'}
                />
              </>
            )}
          </HStack>
        </Td>
        <Td>
          <EditableField defaultValue={defaultProperty?.value} onSubmit={onSubmitValue} />
        </Td>
      </Tr>

      <DeleteModal
        isOpen={isDeleteModal}
        onClose={onToggleDeleteModal}
        itemName={defaultProperty.name}
        onDelete={onDeleteItem}
      />
    </>
  );
};

export default PropertyTableRaw;
