import { Flex, Box, Image, Text } from '@chakra-ui/react';

import addAction from 'assets/add_action_icon.png';
import dataIcon from 'assets/data_icon.png';

const commonStyles = {
  display: 'flex',
  alignItems: 'center',
};
const AddAction = () => {
  return (
    <Flex flexDirection={'column'} mt={'1rem'}>
      <Box
        minH='45px'
        {...commonStyles}
        px={3}
        gap={3}
        borderRadius={'9px'}
        width='full'
        border={'1px dashed rgba(255, 255, 255, 0.40)'}
        cursor={'pointer'}
        boxShadow='2px 3px 5px rgba(0, 0, 0, 0.25)'
      >
        <Image src={dataIcon} />
      </Box>
      <Box width='full' display={'flex'} justifyContent={'end'} alignItems={'center'} pt={3}>
        <Box width={'50%'}>
          <Text textAlign={'start'}>Select a source to view your actions and data</Text>
        </Box>
        <Box width={'40%'}>
          <Image h={30} w={30} src={addAction} />
        </Box>
      </Box>
    </Flex>
  );
};

export default AddAction;
