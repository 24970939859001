/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/lines-between-class-members */
/* eslint-disable class-methods-use-this */

import { Flex, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import {
  Cell,
  CellTemplate,
  Compatible,
  Uncertain,
  getCellProperty,
  isAlphaNumericKey,
  isNavigationKey,
  keyCodes,
} from '@silevis/reactgrid';
import React, { useState } from 'react';
import { BiFilter } from 'react-icons/bi';

import { capitalizeFirstLetter } from 'utils/GlobalHelpers';

import MultiSelectTableFilters from './MultiSelectTableFilters';

export interface SelectCell extends Cell {
  type: 'select';
  text: string;
  rowId: string;
  property: string;
}

const CustomFilter = ({ cell }: any) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const [isFilterHover, setIsFilterHover] = useState<any>(true);
  const reference = React.useRef() as React.MutableRefObject<HTMLInputElement>;
  // useOutsideClick({
  //   ref: reference,
  //   handler: () => {
  //     setIsFilterHover(false);
  //   },
  // });

  return (
    <>
      <Flex
        ref={reference}
        // onMouseEnter={() => setIsFilterHover(true)}
        // onMouseLeave={() => setIsFilterHover(false)}
        // border={'1px solid red'}
        // w={'20px'}
        // h={'20px'}
        align={'center'}
        justify={'space-between'}
        w={'100%'}
      >
        <p
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '100%',
            textOverflow: 'ellipsis',
            paddingLeft: '3px',
          }}
        >
          {capitalizeFirstLetter(cell?.text)}
        </p>
        {/* {isFilterHover ? ( */}
        <Menu
          variant={'filter'}
          strategy='fixed'
          isOpen={isMenuOpen}
          onClose={() => setIsMenuOpen(false)}
          onOpen={() => setIsMenuOpen(true)}
        >
          <MenuButton
            onCopy={(event: any) => event.stopPropagation()}
            onCut={(event: any) => event.stopPropagation()}
            onPaste={(event: any) => event.stopPropagation()}
            onPointerDown={(event: any) => event.stopPropagation()}
            onKeyDown={(event: any) => {
              if (isAlphaNumericKey(event.keyCode) || isNavigationKey(event.keyCode))
                event.stopPropagation();
            }}
          >
            <BiFilter size={30} color={'rgb(190 218 218)'} />
          </MenuButton>
          <MenuList
            px='2.5'
            ref={(input) => {
              input && input.focus();
            }}
            onCopy={(event) => event.stopPropagation()}
            onCut={(event) => event.stopPropagation()}
            onPaste={(event) => event.stopPropagation()}
            onPointerDown={(event) => event.stopPropagation()}
            onKeyDown={(event) => {
              if (isAlphaNumericKey(event.keyCode) || isNavigationKey(event.keyCode))
                event.stopPropagation();
            }}
          >
            <Flex gap={8} maxW={'600px'} overflowX={'auto'} className='sm-scroll' pb={0}>
              <MultiSelectTableFilters dimensionId={cell?.id} setIsMenuOpen={setIsMenuOpen} />
            </Flex>
          </MenuList>
        </Menu>
        {/* ) : (
          ''
        )} */}
      </Flex>
    </>
  );
};

export class HeaderRow implements CellTemplate<Cell | SelectCell> {
  getCompatibleCell(uncertainCell: Uncertain<SelectCell>): Compatible<SelectCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const value = Number.parseFloat(text);
    return { ...uncertainCell, text, value } as Compatible<SelectCell>;
  }

  handleKeyDown(
    cell: Compatible<SelectCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
  ): { cell: Compatible<SelectCell>; enableEditMode: boolean } {
    if (!ctrl && !alt && isAlphaNumericKey(keyCode)) return { cell, enableEditMode: true };
    return {
      cell,
      enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }
  getClassName(cell: any) {
    const isValid = cell.validator ? cell.validator(cell.text) : true;
    const className = cell.className ?? '';
    return `${isValid ? 'valid' : 'invalid'} ${
      cell.placeholder && cell.text === '' ? 'placeholder' : ''
    } ${className}`;
  }
  render(cell: Compatible<SelectCell>): JSX.Element {
    return (
      <>
        {cell?.property === 'dimension' ? (
          <CustomFilter cell={cell} />
        ) : (
          <p
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              maxWidth: '100%',
              textOverflow: 'ellipsis',
              paddingLeft: '3px',
            }}
          >
            {cell?.text === 'start_value'
              ? 'Start Value'
              : cell?.text === 'growth_rate'
              ? 'Growth Rate'
              : capitalizeFirstLetter(cell?.text)}
          </p>
        )}
      </>
    );
  }
}
