import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { STATUS } from 'projectConstants';
import apiService from 'services';

const initialState: any = {
  sharedModelUsers: [],
  sharedModelUsersStatus: STATUS.IDLE,
};

export const getModelUsers = createAsyncThunk(
  'modelUsers/get',
  async (modelId: number, thunkAPI) => {
    try {
      const response = await apiService.get_share_model_users(modelId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const putModelUsers = createAsyncThunk(
  'modelUsers/post',
  async ({ modelId, request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.put_share_model_user_permission({ modelId, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

const headerSlice = createSlice({
  initialState,
  name: 'headerSlice',
  reducers: {
    resetHeaderSlice: (state) => {
      state.sharedModelUsers = [];
      state.sharedModelUsersStatus = STATUS.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getModelUsers.pending, (state) => {
        state.sharedModelUsersStatus = STATUS.LOADING;
      })
      .addCase(getModelUsers.fulfilled, (state, action) => {
        state.sharedModelUsers = action.payload;
        state.sharedModelUsersStatus = STATUS.SUCCESS;
      })
      .addCase(putModelUsers.fulfilled, (state, action) => {
        state.sharedModelUsersStatus = STATUS.LOADING;
        const newUserPermission = action.payload;
        const newPermissions = state.sharedModelUsers.map((user: any) => {
          return user.user_id === newUserPermission.user_id ? newUserPermission : user;
        });
        state.sharedModelUsers = newPermissions;
      });
  },
});
export const { resetHeaderSlice } = headerSlice.actions;
export const getSharedModelUsers = (state: any) => state.headerSlice?.sharedModelUsers;
export const getSharedModelUsersStatus = (state: any) => state.headerSlice.sharedModelUsersStatus;
export default headerSlice.reducer;
