import { InfoIcon } from '@chakra-ui/icons';
import {
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Divider,
  VStack,
} from '@chakra-ui/react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';

import {
  addDataStepOneFormMultipleDataPeriod,
  handleActionStepNext,
  resetDataPeriodValuesForMultipleType,
} from 'redux/DataImportSlice';
import { ActionDataStepTwoSchemaTimePeriod } from 'validation/schemas/ActionStepSchema';
import TableRowIcon from 'components/Icons/TableRowIcon';
import TableColumnIcon from 'components/Icons/TableColumnIcon';
import { checkedRadioStyles, textStyles } from 'pages/ModelOverviewPage/Import/Helper';

import ActionStepFooter from '../../ActionStepFooter';

interface Properties {
  setHelpModalSeleceted: any;
  getFormValue: any;
}

const Multiple = ({ setHelpModalSeleceted, getFormValue }: Properties) => {
  const dispatch: any = useDispatch();
  const handleSubmit = async (form: any, setSubmitting: (data: boolean) => void) => {
    await dispatch(addDataStepOneFormMultipleDataPeriod(form)).then(() => {
      if (getFormValue?.dataPeriod === '2') {
        dispatch(resetDataPeriodValuesForMultipleType());
      }
      setSubmitting(false);
      dispatch(handleActionStepNext());
    });
  };
  return (
    <Formik
      initialValues={{
        selectedTimePeriod: '' || `${getFormValue.selectedTimePeriod}`,
      }}
      validationSchema={ActionDataStepTwoSchemaTimePeriod}
      onSubmit={(form, { setSubmitting }) => handleSubmit(form, setSubmitting)}
      enableReinitialize={true}
    >
      {({ values, isSubmitting, setFieldValue }: any) => {
        return (
          <Form style={{ width: '100%' }}>
            <VStack display='flex' justify={'center'} w={'100%'}>
              <VStack display='flex' justify={'center'} w={'100%'}>
                <VStack w={'100%'} justify={'center'}>
                  <FormControl mt={5} pt={2}>
                    <FormLabel fontSize='0.9rem' color='black' fontFamily={'bold_roboto'}>
                      Are the time periods in rows or columns?
                    </FormLabel>
                    <RadioGroup
                      width={'100%'}
                      pt={3}
                      defaultValue={getFormValue.selectedTimePeriod}
                      onChange={(value) => {
                        setFieldValue('selectedTimePeriod', value);
                      }}
                      value={values.selectedDataType}
                    >
                      <Stack spacing={5} direction='column'>
                        <Field name='selectedTimePeriod'>
                          {() => (
                            <>
                              <TableRowIcon />
                              <Radio _checked={checkedRadioStyles} value='row'>
                                <Text color='black' {...textStyles}>
                                  Select rows
                                </Text>
                              </Radio>
                              <Divider my='5' border={2} />
                              <TableColumnIcon />
                              <Radio _checked={checkedRadioStyles} value='column'>
                                <Text color='black' {...textStyles}>
                                  Select column
                                </Text>
                              </Radio>
                            </>
                          )}
                        </Field>
                      </Stack>
                    </RadioGroup>
                    <ErrorMessage name='selectedTimePeriod' component='div' className='error' />
                  </FormControl>
                </VStack>
              </VStack>
              <Flex alignItems={'center'} justifyContent={'space-between'} pb={8} pt='5' w={'100%'}>
                <Flex>
                  <Text
                    {...textStyles}
                    color={'black'}
                    textDecoration='underline'
                    onClick={() => {
                      setHelpModalSeleceted.on();
                    }}
                    display={'none'}
                  >
                    Help <InfoIcon color={'#8B89B1'} ms={3} />
                  </Text>
                </Flex>
                <ActionStepFooter
                  nextButtonName='Continue'
                  prevButtonName='Previous'
                  isSubmitting={isSubmitting}
                />
              </Flex>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Multiple;
