const ZoomOutMapIcon = () => {
  return (
    <svg width='25px' height='25px' viewBox='0 0 48 48' xmlns='http://www.w3.org/2000/svg'>
      <path d='M0 0h48v48H0z' fill='none' />
      <g id='Shopicon'>
        <g>
          <polygon points='41,31 41,27 27,27 27,41 31,41 31,33.828 40.585,43.413 43.413,40.585 33.828,31 		' />
          <polygon points='31,14.172 31,7 27,7 27,21 41,21 41,17 33.828,17 43.414,7.414 40.586,4.586 		' />
          <polygon points='7,17 7,21 21,21 21,7 17,7 17,14.172 7.413,4.585 4.585,7.413 14.172,17 		' />
          <polygon points='7.416,43.416 17,33.832 17,41 21,41 21,27 7,27 7,31 14.175,31 4.588,40.588 		' />
        </g>
      </g>
    </svg>
  );
};

export default ZoomOutMapIcon;
