/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Text, Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useEffect, useState, useRef } from 'react';

import PriviewFilterByComponent from 'pages/PlanPageForecast/Plan/Filters/PreviewFilterBy';
import { fetchDashboardChart, getChartType } from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

import TimeAggregationFilter from '../table/TimeAggregationFilter';
import TimeRangeFilter from '../TimeRangeFilter';

import PieChart from './PieChart';
import BarChart from './BarChart';
import LineChart from './LineChart';
import ComboChart from './ComboChart';

const ChartCard = ({ chartData, isDashboardLayout }: any) => {
  const dispatch: AppDispatch = useDispatch();
  const chartType = useSelector(getChartType);
  const [pieChartData, setPieChartData] = useState<any>();
  const [pieGraphData, setPieGraphData] = useState<any>();
  const [timeGranularity, setTimeGranularity] = useState('M');
  useEffect(() => {
    if (chartData?.data) {
      const chartMatrixData = chartData?.data[0];
      setPieGraphData(chartMatrixData);
      dispatch(fetchDashboardChart(chartData?.id)).then((data: any) => {
        setPieChartData(data.payload);
      });
    }
  }, [chartData]);

  const isDimensionFilter = useMemo(() => {
    const filterData = pieChartData?.chart_filters?.some(
      (item: any) => item?.filter_type === 'dimension',
    );
    return filterData || false;
  }, [pieChartData?.chart_filters]);

  const handleTimeGranulirity = (value: any) => {
    setTimeGranularity(value);
  };

  const blockId = useMemo(() => {
    if (pieChartData?.chart_metrics) {
      const firstMetric = pieChartData.chart_metrics[0];
      return firstMetric ? firstMetric.block_id : null;
    }
    return null;
  }, [pieChartData?.chart_metrics]);

  const displayChart = () => {
    switch (
      isDashboardLayout
        ? chartData?.chart_type
        : chartType === 3
        ? 'bar'
        : chartType === 2
        ? 'line'
        : 'bar'
    ) {
      case 'pie':
        return (
          <PieChart chartFormatting={pieChartData?.formatting} graphData={pieGraphData} />
          // <PieChart chartFormatting={chartData?.formatting} />
        );
      case 'line':
        return (
          <LineChart
            timeGranularityFilter={timeGranularity}
            chartFormatting={pieChartData?.formatting}
            graphData={chartData}
            isDashboardLayout={isDashboardLayout}
          />
        );
      case 'bar':
        return (
          <BarChart
            timeGranularityFilter={timeGranularity}
            chartFormatting={pieChartData?.formatting}
            graphData={chartData}
            isDashboardLayout={isDashboardLayout}
          />
        );
      case 'combi':
        return <ComboChart />;
      default:
        return <PieChart />;
    }
  };

  return (
    <>
      <Flex
        borderRadius='15px'
        flexDirection={'column'}
        border={isDashboardLayout ? '' : '1px solid #787878'}
        boxShadow={isDashboardLayout ? '' : '0px 4px 4px rgba(0, 0, 0, 0.25)'}
        height={'100%'}
      >
        <Text
          m={2}
          fontFamily={'regular'}
          fontSize={'2xl'}
          fontWeight={'extrabold'}
          color={'blackAlpha.900'}
          textAlign={'left'}
        >
          {pieChartData?.chart_metrics && (
            <>{pieChartData?.chart_metrics[0]?.indicator_name || 'Indicator'}</>
          )}
        </Text>
        <Flex flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} m={3}>
          <Flex>
            {/* <TimeRangeFilter
              chartFilters={pieChartData?.chart_filters}
              chartId={pieChartData?.id}
            /> */}
            {isDimensionFilter && (
              <PriviewFilterByComponent
                filterChartData={pieChartData}
                graphData={pieGraphData}
                block_id={blockId}
              />
            )}
          </Flex>
          <Flex>
            {pieChartData?.chart_type !== 'pie' && (
              <TimeAggregationFilter
                setTimeGranularityFilter={handleTimeGranulirity}
                chartData={pieChartData}
              />
            )}
          </Flex>
        </Flex>
        <Flex justifyContent={'center'} alignItems={'center'} m={3} height={'100%'}>
          <Box
            width={
              pieChartData?.chart_type === 'bar' || pieChartData?.chart_type === 'line' ? '95%' : ''
            }
            height={
              pieChartData?.chart_type === 'bar' || pieChartData?.chart_type === 'line'
                ? '100%'
                : ''
            }
          >
            {displayChart()}
          </Box>
        </Flex>
      </Flex>
    </>
  );
};

export default ChartCard;
