import {
  Popover,
  PopoverTrigger,
  Button,
  Portal,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  IconButton,
  Box,
  Flex,
  Text,
  useMediaQuery,
  Image,
  Tooltip,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Person from 'components/Icons/Person';
import TextComponent from 'components/Text';
import {
  getUserData,
  getUserSubscriptionLimits,
  SetTabs,
  SetUserModalVisible,
} from 'redux/UserSlice';
import LogoutModal from 'components/LogoutModal';
import LightFilledUserAvatar from 'components/Icons/FilledLightUserAvatar';

import UserModal from './UserModal';
import UserMobileLayoutModal from './UserResponsiveModal';

const UserManagement = () => {
  const dispatch: any = useDispatch();

  const userData = useSelector(getUserData);
  const userSubscriptionLimits = useSelector(getUserSubscriptionLimits);

  const [logoutModal, setLogoutModal] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (isMobile) {
      dispatch(SetTabs(0));
    } else {
      dispatch(SetTabs(1));
    }
  }, [isMobile]);

  return (
    <>
      <Popover isLazy gutter={16} placement='bottom-end'>
        {({ isOpen }) => (
          <>
            <PopoverTrigger>
              <IconButton
                size='ghost'
                color='black.900'
                icon={<Person />}
                aria-label='Open user menu'
              />
            </PopoverTrigger>
            {isOpen && (
              <Portal>
                <PopoverContent
                  position={'absolute'}
                  right='0'
                  p='1rem .5rem 0 .5rem'
                  w='fit-content'
                  minWidth='250px'
                  color='black.900'
                  borderWidth='1'
                  borderRadius='xl'
                  borderColor='grey.800'
                  bgColor='white.900'
                  boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
                  mr='2'
                >
                  <PopoverHeader padding='0 0 1rem 0' borderBottom={'none'}>
                    <Flex alignItems={'center'}>
                      {userData?.profile_image_url ? (
                        <Image
                          boxShadow={'1px 1px 10px #d9d9d9'}
                          src={userData?.profile_image_url}
                          borderRadius={'50%'}
                          width={'70px'}
                          height={'70px'}
                          ml='2'
                        />
                      ) : (
                        <LightFilledUserAvatar styles={{ marginLeft: '2' }} />
                      )}
                      <Box ml='4' overflow='hidden'>
                        <Tooltip label={`${userData?.firstname} ${userData?.lastname}`}>
                          <Box>
                            <TextComponent
                              ellipsis={true}
                              as='h2'
                              fontWeight={'600'}
                              fontSize={'xl'}
                            >
                              {`${userData?.firstname} ${userData?.lastname}`}
                            </TextComponent>
                          </Box>
                        </Tooltip>
                        <Text fontSize={'xs'} color='green.900'>
                          {userSubscriptionLimits?.current_subscription_name}
                        </Text>
                      </Box>
                    </Flex>
                  </PopoverHeader>
                  <PopoverBody color='black.900' padding='.7rem '>
                    <Flex flexDir={'column'}>
                      <Button
                        p='0'
                        justifyContent='flex-start'
                        w='fit-content'
                        h='5'
                        m='0 0 .5rem 0'
                        color='black.900'
                        onClick={() => dispatch(SetUserModalVisible(true))}
                      >
                        Profile
                      </Button>
                      <Button
                        p='0'
                        w='fit-content'
                        h='5'
                        color='black.900'
                        onClick={() => dispatch(SetUserModalVisible(true))}
                      >
                        Settings
                      </Button>
                    </Flex>
                  </PopoverBody>
                  <PopoverFooter padding='.7rem '>
                    <Button
                      p='0'
                      w='fit-content'
                      h='5'
                      color='red.900'
                      onClick={() => setLogoutModal(true)}
                    >
                      Log out
                    </Button>
                  </PopoverFooter>
                </PopoverContent>
              </Portal>
            )}
          </>
        )}
      </Popover>
      {isMobile ? <UserMobileLayoutModal /> : <UserModal />}
      {logoutModal && (
        <LogoutModal
          isOpen={logoutModal}
          onClose={() => setLogoutModal(false)}
          onOpenAuthModal={() => {}}
        />
      )}
    </>
  );
};

export default UserManagement;
