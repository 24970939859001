import { Text } from '@chakra-ui/react';

import { BLOCK_NOT_FOUND } from 'projectConstants';

const BlockNotFound = () => (
  <Text size='home_page_subtitle' variant='grey'>
    {BLOCK_NOT_FOUND}
  </Text>
);

export default BlockNotFound;
