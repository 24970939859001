interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const BillingIcon = ({ width = 4.75, height = 4.75, color = 'white.900' }: Properties) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 25' xmlns='http://www.w3.org/2000/svg'>
      <mask id='mask0_21_256' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='25'>
        <rect x='0.0996094' y='0.416946' width='23.8327' height='23.8327' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_21_256)'>
        <path
          d='M21.9463 6.37512V18.2915C21.9463 18.8376 21.7518 19.3052 21.3629 19.6941C20.9739 20.0831 20.5064 20.2775 19.9602 20.2775H4.07175C3.52559 20.2775 3.05803 20.0831 2.6691 19.6941C2.28016 19.3052 2.08569 18.8376 2.08569 18.2915V6.37512C2.08569 5.82895 2.28016 5.3614 2.6691 4.97247C3.05803 4.58353 3.52559 4.38906 4.07175 4.38906H19.9602C20.5064 4.38906 20.9739 4.58353 21.3629 4.97247C21.7518 5.3614 21.9463 5.82895 21.9463 6.37512ZM4.07175 8.36118H19.9602V6.37512H4.07175V8.36118ZM4.07175 12.3333V18.2915H19.9602V12.3333H4.07175Z'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default BillingIcon;
