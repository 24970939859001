/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable class-methods-use-this */

import * as React from 'react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Cell,
  CellTemplate,
  Uncertain,
  Compatible,
  getCellProperty,
  UncertainCompatible,
  keyCodes,
  getCharFromKeyCode,
  isAlphaNumericKey,
  isNavigationKey,
} from '@silevis/reactgrid';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuOptionGroup,
  Button,
  Text,
} from '@chakra-ui/react';

import HorizontalMenu from 'components/Icons/horizontalMenu';
import { capitalizeFirstLetter } from 'utils/GlobalHelpers';

export type OptionType = {
  label: string;
  value: string;
};

export interface DropdownCell extends Cell {
  type: 'dropdown';
  selectedValue?: string;
  values: OptionType[];
  isDisabled?: boolean;
  isOpen?: boolean;
  inputValue?: string;
  buttonType?: number;
  planPermission?: boolean;
  selectOption?: boolean;
  placeholder?: string;
  validator?: (text: string) => boolean;
}

export class DropdownCellTemplate implements CellTemplate<DropdownCell> {
  onDeleteRow: (data: any) => void;

  planPermission?: boolean;

  constructor(onDeleteRow: (data: string) => void, planPermission: boolean) {
    this.onDeleteRow = onDeleteRow;
    this.planPermission = planPermission;
  }

  getCompatibleCell(uncertainCell: Uncertain<DropdownCell>): Compatible<DropdownCell> {
    let selectedValue: string | undefined;
    try {
      selectedValue = getCellProperty(uncertainCell, 'selectedValue', 'string');
    } catch {
      selectedValue = undefined;
    }
    const values = getCellProperty(uncertainCell, 'values', 'object');
    const value = selectedValue ? Number.parseFloat(selectedValue) : Number.NaN;
    let isDisabled = true;
    try {
      isDisabled = getCellProperty(uncertainCell, 'isDisabled', 'boolean');
    } catch {
      isDisabled = false;
    }
    let inputValue: string | undefined;
    try {
      inputValue = getCellProperty(uncertainCell, 'inputValue', 'string');
    } catch {
      inputValue = undefined;
    }
    let isOpen: boolean;
    try {
      isOpen = getCellProperty(uncertainCell, 'isOpen', 'boolean');
    } catch {
      isOpen = false;
    }
    const text = selectedValue || '';
    return { ...uncertainCell, selectedValue, text, value, values, isDisabled, isOpen, inputValue };
  }

  getClassName(cell: Compatible<DropdownCell>): string {
    const isValid = cell.validator ? cell.validator(cell.text) : true;
    const isOpen = cell.isOpen ? 'open' : 'closed';
    const className = cell.className || '';
    return `${className} ${isValid ? 'valid' : 'invalid'} ${
      cell.placeholder && cell.text === '' && 'placeholder'
    } ${isOpen}`;
  }

  update(
    cell: Compatible<DropdownCell>,
    cellToMerge: UncertainCompatible<DropdownCell>,
  ): Compatible<DropdownCell> {
    return this.getCompatibleCell({
      ...cell,
      selectedValue: cellToMerge.selectedValue,
      isOpen: cellToMerge.isOpen,
      inputValue: cellToMerge.inputValue,
    });
  }

  handleKeyDown(
    cell: Compatible<DropdownCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
  ): { cell: Compatible<DropdownCell>; enableEditMode: boolean } {
    if ((keyCode === keyCodes.SPACE || keyCode === keyCodes.ENTER) && !shift) {
      return {
        cell: this.getCompatibleCell({ ...cell, isOpen: !cell.isOpen }),
        enableEditMode: true,
      };
    }
    const char = getCharFromKeyCode(keyCode, shift);
    if (!ctrl && !alt && isAlphaNumericKey(keyCode))
      return {
        cell: this.getCompatibleCell({
          ...cell,
          inputValue: shift ? char : char.toLowerCase(),
          isOpen: !cell.isOpen,
        }),
        enableEditMode: false,
      };
    return { cell, enableEditMode: false };
  }

  render(
    cell: any,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<DropdownCell>, commit: boolean) => void,
  ): React.ReactNode {
    return (
      <Menu closeOnSelect={true} placement={cell?.buttonType && 'left'} strategy='fixed'>
        {({ isOpen }) => {
          return (
            <>
              {cell?.buttonType ? (
                <>
                  <MenuButton
                    border='none'
                    fontWeight={'500'}
                    textDecoration='none'
                    as={Button}
                    padding={'15px'}
                    h={'100%'}
                    w={'100%'}
                    textAlign='start'
                    textTransform='none'
                  >
                    <HorizontalMenu
                      width={'25px'}
                      height={'25px'}
                      styles={{ filter: 'invert(1)' }}
                    />
                  </MenuButton>
                  {isOpen && (
                    <MenuList
                      width={'100%'}
                      padding={2}
                      ref={(input) => {
                        input && input.focus();
                      }}
                      onCopy={(event) => event.stopPropagation()}
                      onCut={(event) => event.stopPropagation()}
                      onPaste={(event) => event.stopPropagation()}
                      onPointerDown={(event) => event.stopPropagation()}
                      onKeyDown={(event) => {
                        if (isAlphaNumericKey(event.keyCode) || isNavigationKey(event.keyCode))
                          event.stopPropagation();
                      }}
                    >
                      {cell?.values?.map((option: any, index: number) => {
                        return (
                          <React.Fragment key={index}>
                            <MenuItem
                              height={'25px'}
                              cursor={this.planPermission ? 'not-allowed' : 'pointer'}
                              onClick={
                                this.planPermission
                                  ? undefined
                                  : () =>
                                      this.onDeleteRow({ id: cell?.id, itemName: cell?.itemName })
                              }
                            >
                              {option?.label}
                            </MenuItem>
                          </React.Fragment>
                        );
                      })}
                    </MenuList>
                  )}
                </>
              ) : (
                <>
                  <MenuButton
                    as={Button}
                    className={` menu-cell ${cell.className}`}
                    border='none'
                    fontWeight={'500'}
                    textDecoration='none'
                    rightIcon={<ChevronDownIcon fontSize='md' />}
                    h={'100%'}
                    padding={'15px'}
                    fontSize={'15px'}
                    color={'white'}
                    textAlign='start'
                    textTransform='none'
                  >
                    {cell?.selectedValue ? (
                      <Text>{capitalizeFirstLetter(cell?.selectedValue)}</Text>
                    ) : (
                      <Text opacity={'.2'}>Select...</Text>
                    )}
                  </MenuButton>
                  {isOpen && (
                    <MenuList
                      p={'0'}
                      width={'100%'}
                      onPointerDown={(event) => {
                        event.stopPropagation();
                      }}
                      className={` scroll menu-cell ${cell.className}`}
                      maxH={40}
                      minH={'fit-content'}
                      sx={{ overflow: 'auto' }}
                    >
                      <MenuOptionGroup
                        as='ul'
                        value={cell?.selectedValue}
                        onChange={(event: any) =>
                          onCellChanged(
                            {
                              ...cell,
                              selectedValue: event,
                              text: event,
                            },
                            true,
                          )
                        }
                      >
                        {cell.selectOption && (
                          <MenuItemOption value={''} key={''}>
                            Select...
                          </MenuItemOption>
                        )}
                        {cell?.values?.map((option: any) => {
                          return (
                            <MenuItemOption
                              as='li'
                              value={option?.value}
                              key={option.value}
                              cursor={this.planPermission ? 'not-allowed' : 'pointer'}
                            >
                              {option?.label}
                            </MenuItemOption>
                          );
                        })}
                        ;
                      </MenuOptionGroup>
                    </MenuList>
                  )}
                </>
              )}
            </>
          );
        }}
      </Menu>
    );
  }
}
