import { Global } from '@emotion/react';

const Fonts = () => {
  return (
    <Global
      styles={`
        @font-face {
            font-weight: 400;
            font-family: Comfortaa Regular;
            font-style: normal;
            src: url('/fonts/comfortaa-v40-latin-regular.woff2') format('woff2'),
            url('/fonts/comfortaa-v40-latin-regular.woff') format('woff');
            font-display: swap;
        }
        @font-face {
          font-weight: 700;
          font-family: Comfortaa Bold;
          font-style: normal;
          src: url('/fonts/comfortaa-v40-latin-700.woff2') format('woff2'),
          url('/fonts/comfortaa-v40-latin-700.woff') format('woff');
          font-display: swap;
        }
        @font-face {
          font-weight: 400;
          font-family: Roboto Regular;
          font-style: normal;
          src: url('/fonts/roboto-v30-latin-regular.woff2') format('woff2'),
          url('/fonts/roboto-v30-latin-regular.woff') format('woff');
          font-display: swap;
        }
        @font-face {
        font-weight: 700;
        font-family: Roboto Bold;
        font-style: normal;
        src: url('/fonts/roboto-v30-latin-700.woff2') format('woff2'),
        url('/fonts/roboto-v30-latin-700.woff') format('woff');
        font-display: swap;
        }
      `}
    />
  );
};

export default Fonts;
