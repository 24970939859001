import { Container as ChakraContainer, StyleProps } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { CSSProperties } from 'react';

import { HEADER_HEIGHT } from 'projectConstants';

interface Properties {
  children: JSX.Element;
  wrapperProperties?: StyleProps;
  bgColor?: string;
  padding?: string;
  style?: CSSProperties;
}

const Container = ({ children, wrapperProperties, bgColor, padding, style }: Properties) => {
  const { pathname } = useLocation();

  const isLoginPage = pathname === '/';
  const isSignupPage = pathname === '/signup';
  const isSetNewPassword = pathname === '/set-new-password';
  const isSplashPage = pathname.includes('/create');

  return (
    <ChakraContainer
      style={style && style}
      maxW='100vw'
      color='white.900'
      padding={padding}
      bg={bgColor || 'gradient.body'}
      overflow='auto'
      h={
        isLoginPage || isSplashPage || isSetNewPassword || isSignupPage
          ? '100vh'
          : `calc(100vh - ${HEADER_HEIGHT})`
      }
      {...wrapperProperties}
    >
      {children}
    </ChakraContainer>
  );
};

export default Container;
