import { Box, Table, TableContainer, Tbody, Text, Th, Thead, Tooltip, Tr } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import AddButton from 'components/AddButton';
import { PropertyValue } from 'models/response/propertyValue.interface';
import { addDimesionItem, fetchPropertyValue, getPropertyValues } from 'redux/DimensionPageSlice';
import { getSelectedDriver } from 'redux/PlanPageSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';
import addNewDimensionItem from 'utils/AddNewDimItem';
import PlanPermission from 'container/PlanPermission';
import { AppDispatch } from 'utils/GlobalHelpers';

import PropertyTableRaw from './PropertyTableRaw';

interface Properties {
  propertyId: number;
  dimensionName: string;
  dimensionId: number;
  propertyName: string;
  planPermission: boolean;
  refetchRequest: () => void;
}

const PropertyTable = ({
  dimensionName,
  dimensionId,
  propertyName,
  planPermission,
  propertyId,
  refetchRequest,
}: Properties) => {
  const propertyValues: PropertyValue[] = useSelector(getPropertyValues);
  const dispatch: AppDispatch = useDispatch();
  const currentDriver = useSelector(getSelectedDriver);
  const baseScenario = useSelector(getBaseScenario);
  const scenarioId = baseScenario?.id;

  const onAddItem = async () => {
    await dispatch(
      addDimesionItem({
        dimension_id: dimensionId,
        request_body: addNewDimensionItem(propertyValues, scenarioId),
      }),
    );

    await dispatch(
      fetchPropertyValue({
        propertyId: currentDriver.property_id,
        parameters: { scenario_id: baseScenario?.id },
      }),
    );
  };

  return (
    <TableContainer mb='8' pr='2' h='100%' overflowY='auto' overflowX='hidden' className='scroll'>
      <Table variant='dimension' mb='15px' style={{ tableLayout: 'fixed', width: '100%' }}>
        <Thead bgColor='inherit' position='static'>
          <Tr>
            <Th>
              <Tooltip label={dimensionName} style={{ maxWidth: '60%', width: '60%' }}>
                <Text noOfLines={1} display={'block'}>
                  {dimensionName}
                </Text>
              </Tooltip>
            </Th>
            <Th paddingLeft={'5%'}>
              <Tooltip label={propertyName} style={{ maxWidth: '40%', width: '40%' }}>
                <Text noOfLines={1} display={'block'}>
                  Enter {propertyName}
                </Text>
              </Tooltip>
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {propertyValues?.map((property: PropertyValue) => (
            <PropertyTableRaw
              key={`${property?.name}+${baseScenario?.name}`}
              property={property}
              propertyId={propertyId}
              refetchRequest={refetchRequest}
              planPermission={planPermission}
            />
          ))}
        </Tbody>
      </Table>
      <Box pb='10px' mr='8px' cursor={planPermission ? 'not-allowed' : 'pointer'}>
        <AddButton
          label={`Add ${dimensionName}`}
          onClick={planPermission ? undefined : onAddItem}
        />
      </Box>
    </TableContainer>
  );
};

export default PlanPermission(PropertyTable);
