import {
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Text,
  Tooltip,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
} from '@chakra-ui/react';
import React, { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// import { Model } from 'models/response/model.interface';
import {
  GetUserModels,
  DuplicateUserModels,
  RenameUserModels,
  DeleteUserModels,
  StartDuplicateModel,
  StopDuplicateModel,
  getModels,
} from 'redux/ModelsSlice';
import BoxShimmer from 'components/FullScreenLoader/BoxShimmer';
import { getUserSubscriptionLimits, SetModelLimit } from 'redux/UserSlice';
import DeleteModal from 'components/Modals/DeleteModal';
import InfoIconComponent from 'components/Icons/InfoIcon';
import PlanPermission from 'container/PlanPermission';
import { AppDispatch } from 'utils/GlobalHelpers';

interface Properties {
  // model: Model;
  model: any;
  planPermission: boolean;
  handleProperties?: (data: any) => void;
}

const MobileModels = ({ model, planPermission, handleProperties }: Properties) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const [menu, setMenu]: any = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false);

  const models = useSelector(getModels);
  const permissionData = useSelector(getUserSubscriptionLimits);

  const onCardClick = () => {
    if (!isEditMode) {
      if (model?.blocks[0]) {
        navigate(`/model/${model.id}/block/${model.blocks[0].id}/plan`);
      } else {
        navigate(`/model/${model.id}/home`);
      }
    }
  };

  const onOpenEditMode = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsEditMode((previousState) => !previousState);
  };

  const onBlur = () => setIsEditMode(false);

  const handleMenuClick = (event: any) => event.stopPropagation();

  const onOpenDeleteModal = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsDeleteModal(true);
  };

  const onDeleteModel = async () => {
    setIsDeleteModal(false);
    await dispatch(DeleteUserModels({ modelId: model.id }));
    dispatch(GetUserModels({}));
  };

  const onRenameModel = async (value: string) => {
    await dispatch(
      RenameUserModels({
        model_id: model.id,
        request_body: { name: value },
        toaster: true,
      }),
    );
  };

  const copyModel = async (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (
      permissionData?.subscription_features_limit?.No_of_models &&
      !(permissionData?.subscription_features_limit?.No_of_models > models?.length)
    ) {
      dispatch(SetModelLimit(true));
    } else {
      dispatch(
        StartDuplicateModel({
          id: '',
          name: '',
        }),
      );
      dispatch(
        DuplicateUserModels({
          modelId: model?.id,
          modelName: { name: `${model?.name} - Copy`, all_models_data: true },
          toaster: true,
        }),
      ).then(() => {
        dispatch(StopDuplicateModel());
      });
    }
  };

  return (
    <React.Fragment>
      {model?.id ? (
        <Flex
          w='full'
          h='10'
          justify='space-between'
          align='center'
          p='2'
          borderWidth='1'
          borderRadius='sm'
          borderColor='grey.900'
          zIndex={model?.id === menu ? 4 : 3}
          onClick={onCardClick}
        >
          {isEditMode ? (
            <Editable
              defaultValue={model.name}
              onSubmit={(event) => {
                if (event.trim() !== '') onRenameModel(event);
              }}
              startWithEditView={isEditMode}
              onBlur={onBlur}
              w='full'
              mr='2'
              fontFamily='bold_roboto'
              fontSize='3xs'
              color='black.900'
            >
              <EditablePreview w='full' />
              <EditableInput
                _focusVisible={{
                  h: 'full',
                  px: '1',
                  borderWidth: '3',
                  borderRadius: 'xs',
                  borderColor: 'blue.500',
                }}
                required
              />
            </Editable>
          ) : (
            <Tooltip label={model.name} borderRadius='4' fontSize='3xs'>
              <Text
                variant='secondary'
                mr='2'
                fontFamily='bold_roboto'
                fontSize='3xs'
                wordBreak='break-all'
                noOfLines={2}
              >
                {model.name}
              </Text>
            </Tooltip>
          )}
          <Menu
            isLazy
            closeOnSelect={true}
            onOpen={() => setMenu(model?.id)}
            onClose={() => setMenu('')}
            variant='primary'
            placement='bottom-end'
          >
            <MenuButton onClick={(event: any) => handleMenuClick(event)}>
              <InfoIconComponent color='#BDBCD0' />
            </MenuButton>
            <MenuList minWidth={'160px !important'} zIndex={5}>
              <MenuItem onClick={onOpenEditMode} isDisabled={planPermission}>
                Rename
              </MenuItem>
              <MenuItem onClick={copyModel} isDisabled={planPermission}>
                Duplicate
              </MenuItem>
              <MenuItem onClick={onOpenDeleteModal} isDisabled={planPermission}>
                Delete
              </MenuItem>
              <MenuItem
                onClick={(event: any) => {
                  event.stopPropagation();
                  if (handleProperties)
                    handleProperties({
                      id: model?.id,
                      name: model?.name,
                      description: model?.description,
                      icon: model?.icon,
                      createdAt: model?.date_created,
                      ownerImage: model?.owner?.profile_image_url,
                      ownerName: `${model?.owner?.firstname} ${model?.owner?.lastname}`,
                    });
                }}
                isDisabled={planPermission}
              >
                Properties
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      ) : (
        <BoxShimmer width='full' height={'37.50px'} isList={true} isMobile={true} />
      )}

      <DeleteModal
        isOpen={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
        itemName={model.name}
        onDelete={onDeleteModel}
      />
    </React.Fragment>
  );
};

export default PlanPermission(MobileModels);
