import { useMediaQuery, useToast } from '@chakra-ui/react';
import { useLayoutEffect, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CustomToast from 'components/CustomToast';
import { showModelLimitPopup, SetModelLimit } from 'redux/UserSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

import ModelPermissionPopup from '../PermissionComponent/ModelPermissionPopup';

import DesktopModelsLayout from './DesktopModelsLayout';
import NewMobileLayout from './MobileModelsLayout';

const HomeComponent = () => {
  const toast = useToast();
  const dispatch: AppDispatch = useDispatch();

  const [queryParameters] = useSearchParams();

  const [isTablet] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    if (queryParameters.get('model')) {
      toast({
        duration: 2500,
        render: () => <CustomToast title='Payment Successfull' status='success' />,
      });

      const newUrl = window.location.href.replace('?model=true', '');
      window.history.pushState({}, '', newUrl);
    } else if (queryParameters.get('cancel')) {
      toast({
        duration: 2500,
        render: () => <CustomToast title='Payment Failed' status='error' />,
      });

      const newUrl = window.location.href.replace('?cancel=true', '');
      window.history.pushState({}, '', newUrl);
    }
  }, []);

  useLayoutEffect(() => {
    document.title = 'Blox';
  }, []);

  const showModelLimit = useSelector(showModelLimitPopup);

  const onCloseModelPermissionModel = () => dispatch(SetModelLimit(false));

  return (
    <>
      {isTablet ? <NewMobileLayout /> : <DesktopModelsLayout />}
      {showModelLimit && (
        <ModelPermissionPopup isOpen={showModelLimit} onClose={onCloseModelPermissionModel} />
      )}
    </>
  );
};

export default HomeComponent;
