import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  VStack,
  FormControl,
  Button,
  Flex,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useState, useEffect } from 'react';

import { textStyles } from 'pages/ModelOverviewPage/Import/Helper';
import {
  getNoheadersStatus,
  getTableHeader,
  handleActionStepNext,
  resetMultipleIndicatprType,
  setSelectedAreas,
  setSingleIndicatorValue,
} from 'redux/DataImportSlice';
import { getBlockInputIndicator } from 'redux/PlanPageSlice';

import ActionStepFooter from '../../ActionStepFooter';

const validationSchema = Yup.object().shape({
  singleIndicatorValue: Yup.string().required('Indicator value is required'),
  columnNumericValueoofRowsIndicatorType: Yup.string().required('Column value is required'),
});

interface Properties {
  getFormValue: any;
}

const SingleIndicators = ({ getFormValue }: Properties) => {
  const dispatch: any = useDispatch();
  const headerNames = useSelector(getTableHeader);
  const inputIndicator = useSelector(getBlockInputIndicator);
  const isHeader = useSelector(getNoheadersStatus);

  const [customHeaders, setCustomHeaders] = useState<any[]>(headerNames ?? []);

  useEffect(() => {
    if (isHeader) {
      const array: string[] = new Array(26)
        .fill(1)
        .map((_: string, index: number) => String.fromCodePoint(65 + index));
      const newHeaders = array
        .slice(0, headerNames.length)
        .map((item: string, headerIndex: number) => {
          return { current: `${headerNames[headerIndex]}`, modified: `Column ${item}` };
        });
      setCustomHeaders(newHeaders);
    } else {
      setCustomHeaders(
        headerNames.map((item: string) => {
          return { current: `${item}`, modified: `${item}` };
        }),
      );
    }
  }, [isHeader, headerNames]);

  const selectedIndicatorDetails = (id: number) => {
    const data = inputIndicator.find((item: any) => item.id === id);
    return data?.name;
  };
  const getSelectedColumnOption = (value: string) => {
    const data = customHeaders.find((item: any) => item.current === value);
    return data?.modified;
  };
  const handleSubmit = (form: any, setSubmitting: (data: boolean) => void) => {
    dispatch(setSingleIndicatorValue(form)).then(() => {
      dispatch(resetMultipleIndicatprType());
      setSubmitting(false);
      dispatch(handleActionStepNext());
    });
  };

  return (
    <VStack display='flex' justify={'center'} w={'100%'} pt={2}>
      <Text
        fontSize='0.9rem'
        color='black'
        fontFamily={'bold_roboto'}
        textAlign={'start'}
        width={'100%'}
      >
        What indicator are you importing into the Block?{' '}
      </Text>
      <Formik
        initialValues={{
          singleIndicatorValue: getFormValue?.singleIndicatorValue || '',
          columnNumericValueoofRowsIndicatorType:
            getFormValue?.columnNumericValueoofRowsIndicatorType || '',
        }}
        validationSchema={validationSchema}
        onSubmit={(form, { setSubmitting }) => handleSubmit(form, setSubmitting)}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue, isSubmitting }: any) => {
          return (
            <Form
              style={{
                width: '100%',
              }}
            >
              {inputIndicator?.length > 0 && (
                <>
                  <FormControl mt={5}>
                    <Menu closeOnSelect={true} isLazy>
                      <MenuButton
                        as={Button}
                        backgroundColor='white'
                        maxHeight={'40px'}
                        borderRadius='5px'
                        minWidth='140px'
                        textAlign='left'
                        padding='0 .8rem'
                        boxShadow='rgb(139, 137, 177) 0px 0px 0px 0.6px'
                        rightIcon={<ChevronDownIcon fontSize='md' />}
                        fontWeight='300'
                        fontSize='xs'
                        color='black.900'
                        w='100%'
                        border={
                          errors.singleIndicatorValue ? '2px solid #fe6e6e' : '0.6px solid #8B89B1'
                        }
                      >
                        <Text me={3} noOfLines={1} height='fit-content'>
                          {selectedIndicatorDetails(values.singleIndicatorValue) || (
                            <Text opacity={0.5}>Select indicator</Text>
                          )}
                        </Text>
                      </MenuButton>
                      <MenuList
                        height='fit-content'
                        maxHeight='20vh'
                        overflowY='auto'
                        className='scroll'
                      >
                        <MenuOptionGroup defaultValue={values.singleIndicatorValue || ''}>
                          {inputIndicator &&
                            inputIndicator?.map((item: any) => (
                              <MenuItemOption
                                key={item?.id}
                                value={item?.name}
                                onClick={() => setFieldValue('singleIndicatorValue', item?.id)}
                              >
                                {item?.name}
                              </MenuItemOption>
                            ))}
                        </MenuOptionGroup>
                      </MenuList>
                    </Menu>
                    <ErrorMessage name='singleIndicatorValue' component='div' className='error' />
                  </FormControl>
                  <Text color={'black'} {...textStyles} textAlign={'start'} width={'100%'} pt={3}>
                    Now select the column in your table that contains the numerical values you want
                    to import
                  </Text>
                  <FormControl pt={5}>
                    <Menu closeOnSelect={true} isLazy>
                      <MenuButton
                        as={Button}
                        backgroundColor='white'
                        maxHeight={'40px'}
                        borderRadius='5px'
                        minWidth='140px'
                        textAlign='left'
                        padding='0 .8rem'
                        boxShadow='rgb(139, 137, 177) 0px 0px 0px 0.6px'
                        rightIcon={<ChevronDownIcon fontSize='md' />}
                        fontWeight='300'
                        fontSize='xs'
                        color='black.900'
                        w='100%'
                        border={
                          errors.columnNumericValueoofRowsIndicatorType
                            ? '2px solid #fe6e6e'
                            : '0.6px solid #8B89B1'
                        }
                      >
                        <Text me={3} noOfLines={1} height={'fit-content'}>
                          {getSelectedColumnOption(
                            values?.columnNumericValueoofRowsIndicatorType,
                          ) ?? <Text opacity={0.5}>Select...</Text>}
                        </Text>
                      </MenuButton>
                      <MenuList
                        height='fit-content'
                        maxHeight='20vh'
                        overflowY='auto'
                        className='scroll'
                      >
                        <MenuOptionGroup
                          defaultValue={values?.columnNumericValueoofRowsIndicatorType}
                          onChange={async (role: any) => {
                            await setFieldValue('columnNumericValueoofRowsIndicatorType', role);
                            await dispatch(setSelectedAreas({ selectedColumnOption: role }));
                          }}
                        >
                          <MenuItemOption value=''>Select...</MenuItemOption>
                          {customHeaders.map((header: any) => (
                            <MenuItemOption key={header} value={header.current}>
                              {header.modified}
                            </MenuItemOption>
                          ))}
                        </MenuOptionGroup>
                      </MenuList>
                    </Menu>
                    <ErrorMessage
                      name='columnNumericValueoofRowsIndicatorType'
                      component='div'
                      className='error'
                    />
                  </FormControl>
                </>
              )}
              {inputIndicator?.length === 0 && (
                <Text color={'red'} my='3' {...textStyles} textAlign={'start'} width={'100%'}>
                  No input indicators
                </Text>
              )}

              <Flex alignItems={'center'} justifyContent={'flex-end'} pb={8} pt='5' w={'100%'}>
                <ActionStepFooter
                  nextButtonName='Continue'
                  prevButtonName='Previous'
                  isSubmitting={isSubmitting}
                  disableNext={inputIndicator?.length === 0}
                />
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </VStack>
  );
};

export default SingleIndicators;
