import {
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  Button,
  Text,
  Box,
  Flex,
  useMediaQuery,
  Image,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { useMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AiOutlineLock } from 'react-icons/ai';

import { getModelUsers, putModelUsers } from 'redux/HeaderSlice';
import Person from 'components/Icons/Person';
import { getUserData } from 'redux/UserSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

interface Properties {
  shareUser: any;
  modelOwner: any;
  userPermission?: any;
}

const UserDetails = ({ shareUser, modelOwner, userPermission }: Properties) => {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector(getUserData);
  const [isMobile] = useMediaQuery('(max-width: 575px)');
  const match = useMatch({ path: 'model/:modelId/', end: false });
  const modelId = match?.params?.modelId;

  const textStyle = {
    fontFamily: 'regular_roboto',
    fontWeight: 400,
  };

  const onChangePermissions = (editUser: any, permission: string) => {
    dispatch(
      putModelUsers({
        modelId,
        request_body: {
          user_id: editUser.user_id,
          role: permission,
        },
      }),
    ).then(() => {
      dispatch(getModelUsers(Number(modelId)));
    });
  };

  return (
    <Flex justify={'space-between'} align={'center'} w={'full'} mt={1}>
      <Flex align={'center'}>
        <Box
          px={shareUser?.profile_image_url ? 0 : 1.5}
          py={shareUser?.profile_image_url ? 0 : 2}
          border={shareUser?.profile_image_url ? 0 : '1px solid #A9A8A8'}
          borderRadius={shareUser?.profile_image_url ? 0 : 'full'}
          me={2}
          display={'flex'}
          alignItems={'center'}
        >
          {shareUser?.profile_image_url ? (
            <Image
              border={'1px solid #A9A8A8'}
              borderRadius={'full'}
              src={shareUser?.profile_image_url}
              width={'35px'}
              height={'36px'}
            />
          ) : (
            <Person color={'#6561A9'} width={6} height={5} />
          )}
        </Box>
        <Box>
          <Text {...textStyle} color={'#000'} fontSize='12.972px'>
            {shareUser?.orgnization_name === 'anonymous' ? 'Guest' : shareUser?.firstname}{' '}
            {shareUser?.user_id === user?.id ? '(You)' : ''}
            {shareUser?.is_in_same_org ? '' : '(External)'}
          </Text>
          <Text {...textStyle} color={'#A9A8A8'} fontSize='9.92px'>
            {shareUser?.username}
          </Text>
        </Box>
      </Flex>
      <Menu>
        <MenuButton
          isDisabled={
            shareUser?.role === 'owner' ||
            shareUser?.orgnization_name === 'anonymous' ||
            !shareUser?.is_in_same_org ||
            modelOwner?.user_id !== user?.id ||
            !(userPermission === 'execute')
          }
          border={'1px solid #D9D9D9'}
          borderRadius={'8px'}
          as={Button}
          rightIcon={shareUser?.role === 'view' ? <AiOutlineLock /> : <ChevronDownIcon />}
          w={isMobile ? '60px' : '90px'}
          h={'35px'}
          color={'#000'}
          textTransform={'capitalize'}
          fontSize={'xs'}
          {...textStyle}
          alignSelf={'left'}
          textAlign={'left'}
          css={`
            &:disabled {
              opacity: 1;
              cursor: not-allowed;
            }
          `}
          ps={'2'}
          pe={'2'}
        >
          {shareUser?.role}
        </MenuButton>
        <MenuList>
          {modelOwner?.user_id !== user?.id && (
            <MenuItem onClick={() => onChangePermissions(shareUser, 'owner')}>Owner</MenuItem>
          )}
          <MenuItem onClick={() => onChangePermissions(shareUser, 'edit')}>Edit</MenuItem>
          <MenuItem onClick={() => onChangePermissions(shareUser, 'view')}>View</MenuItem>
          <MenuItem onClick={() => onChangePermissions(shareUser, 'owner')}>Owner</MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default UserDetails;
