import { Box, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { useEffect, useLayoutEffect, useRef, useState, useMemo } from 'react';
import { Chart as ReactChart } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';

import getChartOptionsNew from 'utils/getChartOptionsNew';
import { CHART_COLORS } from 'projectConstants';
import {
  getPlanPageChartinnerWidth,
  getSelectedDriver,
  inputsPageShow,
  planPageShow,
  setPlanPageInnerWidth,
} from 'redux/PlanPageSlice';
import { NewDimItemData, NewTotalValues } from 'models/response/blockOutput.interface';
import {
  getBaseScenario,
  getCompareChartType,
  getCompareScenario,
  getValuesForSlider,
  setCompareChartType,
} from 'redux/ScenarioSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

import ChartTypeSwitcher from '../ChartTypeSwitcher';

import CompareCustomRangeSlider from './CompareCustomRangeSlider';

ChartJS.register(...registerables);
interface Properties {
  chartData: NewDimItemData[];
  chartLabels: string[];
  tabData: any;
  activeTabIndex: any;
}

const CompareChart = ({ chartData, chartLabels }: Properties) => {
  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isLegend, setIsLegend] = useState(true);
  const showInputPage = useSelector(inputsPageShow);
  const [chartKey, setChartKey] = useState(0);
  const dispatch: AppDispatch = useDispatch();
  const showPlanPage = useSelector(planPageShow);
  const currentDriver = useSelector(getSelectedDriver);
  const sliderLables = useSelector(getValuesForSlider);
  const containerReference = useRef<HTMLDivElement>(null);
  const innerWidth = useSelector(getPlanPageChartinnerWidth);
  const [chartNewData, setChartNewData] = useState<any>([]);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setPageWidth(newWidth);
    };
    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);
  useEffect(() => {
    setChartKey((previousKey) => previousKey + 1);
  }, []);

  const chartTypeState = useSelector(getCompareChartType);

  const toggleChartType = (newType: string) => {
    dispatch(setCompareChartType(newType));
  };

  const legendMargin = {
    id: 'legendMargin',
    beforeInit(chart: any) {
      const originalFit = chart.legend.fit;

      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 25;
      };
    },
  };

  const baseScenario = useSelector(getBaseScenario);
  const compareScenario = useSelector(getCompareScenario);

  const datasets1 = useMemo(() => {
    return chartData.map((entry: NewDimItemData) => {
      const data = entry?.value?.map((value: NewTotalValues) => value.value) || [];
      if (data.some((value) => value !== 0)) {
        while (data.length > 0 && data[data.length - 1] === 0) {
          data.pop();
        }
      }
      return {
        label: baseScenario?.name,
        data,
        backgroundColor: CHART_COLORS[0],
        borderColor: CHART_COLORS[0],
        pointRadius: 3,
      };
    });
  }, [chartData, baseScenario]);

  const datasets2 = useMemo(() => {
    return chartData.map((entry: NewDimItemData) => {
      const data = entry?.value?.map((value: NewTotalValues) => value.value2) || [];
      if (data.some((value) => value !== 0)) {
        while (data.length > 0 && data[data.length - 1] === 0) {
          data.pop();
        }
      }
      return {
        label: compareScenario?.name,
        data,
        backgroundColor: chartTypeState === 'line' ? CHART_COLORS[0] : CHART_COLORS[1],
        borderColor: chartTypeState === 'line' ? CHART_COLORS[0] : CHART_COLORS[1],
        borderDash: [isMobile ? 0.5 : 2, isMobile ? 2 : 2],
        pointRadius: 3,
      };
    });
  }, [chartData, compareScenario, chartTypeState]);

  useEffect(() => {
    setChartNewData([...datasets1, ...datasets2]);
    return () => {
      setChartNewData([]);
    };
  }, [compareScenario]);

  const data = {
    labels: chartLabels,
    datasets: chartNewData,
  };
  useLayoutEffect(() => {
    const updateContainerWidth = () => {
      setTimeout(() => {
        if (containerReference.current) {
          const containerWidth = containerReference.current.offsetWidth;
          const containerPadding =
            Number.parseInt(window.getComputedStyle(containerReference.current).paddingLeft, 10) +
            Number.parseInt(window.getComputedStyle(containerReference.current).paddingRight, 10);
          const widthWithoutPadding = containerWidth - containerPadding;
          if (widthWithoutPadding) dispatch(setPlanPageInnerWidth(widthWithoutPadding));
        }
      }, 200);
    };
    updateContainerWidth();
    window.addEventListener('resize', updateContainerWidth);
    return () => {
      window.removeEventListener('resize', updateContainerWidth);
    };
  }, [containerReference?.current, data, currentDriver, showInputPage, showPlanPage]);

  return (
    <Flex flexDirection={'column'} padding={'0'}>
      <Flex
        style={{
          height: isMobile
            ? innerWidth
              ? `${innerWidth / 1.5}px`
              : '35vh'
            : innerWidth
            ? `${innerWidth / 3}px`
            : '35vh',
          position: 'relative',
          marginBottom: '1%',
          padding: '1%',
        }}
      >
        <ReactChart
          key={chartKey}
          type={chartTypeState === 'line' ? 'line' : 'bar'}
          data={data}
          options={getChartOptionsNew({
            chartType: chartTypeState === 'line' ? 'line' : 'bar',
            isLegend: false,
            pageWidth,
            aspectRatio: isMobile ? 1.5 : 3,
          })}
          plugins={[legendMargin]}
        />
      </Flex>

      <Flex
        ref={containerReference}
        display={'flex'}
        justifyContent={isMobile ? 'center' : 'space-between'}
        alignItems='self-start'
        flexDirection='row'
        pt={isMobile ? '0.7rem' : 0}
      >
        <Flex maxW={isMobile ? '100&' : '70%'} width={isMobile ? '100&' : '70%'} flexWrap={'wrap'}>
          {isLegend &&
            data?.datasets?.map((_data: any, index: number) => {
              return (
                <Flex alignItems={'center'} p={'2px'} ml={1}>
                  <Box
                    borderColor={chartTypeState === 'line' ? CHART_COLORS[0] : CHART_COLORS[index]}
                    borderWidth={'1px'}
                    bg={chartTypeState === 'line' ? 'none' : CHART_COLORS[index]}
                    h={chartTypeState === 'line' ? '1px' : '10px'}
                    w={chartTypeState === 'line' ? '15px' : '10px'}
                    mr={chartTypeState === 'line' ? '0.5rem' : '0.1rem'}
                    borderStyle={index === 0 ? 'solid' : 'dashed dashed'}
                  ></Box>
                  <Text
                    color='black'
                    fontSize={'2xs'}
                    fontFamily={'regular_roboto'}
                    textTransform={'capitalize'}
                    fontWeight={'500'}
                  >
                    {_data?.label}
                  </Text>
                </Flex>
              );
            })}
        </Flex>
        {!isMobile && (
          <Flex>
            <ChartTypeSwitcher
              viewMode={chartTypeState}
              onChange={toggleChartType}
              isLegend={isLegend}
              setIsLegend={setIsLegend}
            />
          </Flex>
        )}
      </Flex>
      {data?.labels && (
        <Box>
          <CompareCustomRangeSlider labels={sliderLables} />
        </Box>
      )}
      {isMobile && (
        <Flex
          ps='0.6rem'
          py='0.8rem'
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <ChartTypeSwitcher
            viewMode={chartTypeState}
            onChange={toggleChartType}
            isLegend={isLegend}
            setIsLegend={setIsLegend}
          />
        </Flex>
      )}
    </Flex>
  );
};

export default CompareChart;
