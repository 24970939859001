import { Button, HStack } from '@chakra-ui/react';
import debounce from 'lodash.debounce';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAuthContext } from 'context/authContext';
import { getSharedModelUsers } from 'redux/HeaderSlice';
import { getModelTime, setTabShimmer } from 'redux/IndicatorPageSlice';
import { UpdatePlangPageIndicatorTableData, updateSetDriver } from 'redux/PlanPageSlice';
import { getExpandValue, getPlannerBlock, getPlannerModel } from 'redux/PlannerModeSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

interface Properties {
  inputDimensionsData?: { id: string }[];
  inputType: string;
  indicatorId: number;
  blockId: number;
  refetchDrivers: () => void;
  refetchRequest: () => void;
  inputDataValues: Record<string, string>[];
  timeDimension: number;
  timeRange: string[];
  timeRangeYears: string[];
}

const InputTypeGroupedButton = ({
  inputDimensionsData,
  inputType,
  indicatorId,
  refetchRequest,
}: Properties) => {
  const dispatch: AppDispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('');
  const modelTime = useSelector(getModelTime);
  const timeGranularity = modelTime?.properties?.time_granularity.toLowerCase();
  const baseScenario = useSelector(getBaseScenario);
  const [isAPiLoading, setApiLoading] = useState(false);
  const isExpand = useSelector(getExpandValue);
  const plannerModel = useSelector(getPlannerModel);
  const plannerBlock = useSelector(getPlannerBlock);
  const commonObjects =
    plannerBlock?.dimensions?.length > 1
      ? plannerModel?.dimensions?.filter((object1: any) =>
          plannerBlock?.dimensions?.some(
            (object2: any) => object1.id === object2.id && object1.name !== 'Time',
          ),
        )
      : plannerModel?.dimensions?.filter((object1: any) =>
          plannerBlock?.dimensions?.some((object2: any) => object1.id === object2.id),
        );

  const inputDimensions = [{ id: `${commonObjects[0].id}` }] || inputDimensionsData;
  const tabchange = () => {
    if (inputType !== activeTab) {
      setActiveTab(inputType);
    }
  };

  useEffect(() => {
    setActiveTab(inputType);
  }, [inputType]);
  useEffect(() => {
    tabchange();
  }, [activeTab]);

  const { user } = useAuthContext();
  const shareModelUsers = useSelector(getSharedModelUsers);

  const currentUserPermissions = shareModelUsers.find((sharedUser: any) => {
    return user && sharedUser?.user_id === user?.id;
  });

  const disableChange = isAPiLoading || currentUserPermissions?.role === 'view';

  const onChangeDataInput = debounce(async (newType: string) => {
    if (activeTab === newType) return;
    if (isExpand && inputDimensions.length > 0) {
      setApiLoading(true);
      const payloadNew: any = {
        indicator_id: indicatorId,
        request_body: {
          type: newType,
          scenario_id: baseScenario?.id,
          bulk_input: true,
          format_excel_data: true,
        },
      };
      setActiveTab(newType);
      dispatch(setTabShimmer(true));
      await dispatch(UpdatePlangPageIndicatorTableData(payloadNew));
      await refetchRequest();
      dispatch(updateSetDriver(newType));
      setTimeout(() => {
        setApiLoading(false);
      }, 600);
    } else {
      setApiLoading(true);
      const payloadNew: any = {
        indicator_id: indicatorId,
        request_body: {
          type: newType,
          scenario_id: baseScenario?.id,
          bulk_input: true,
          format_excel_data: true,
        },
      };
      setActiveTab(newType);
      dispatch(setTabShimmer(true));
      await dispatch(UpdatePlangPageIndicatorTableData(payloadNew));
      await refetchRequest();
      dispatch(updateSetDriver(newType));
      setTimeout(() => {
        setApiLoading(false);
      }, 600);
    }
  }, 1000);
  useEffect(() => {
    if (
      plannerModel?.time_properties?.time_granularity.toLowerCase() === 'y' &&
      inputType === 'raw'
    ) {
      onChangeDataInput('constant_by_year');
    } else {
      setActiveTab(inputType);
    }
  }, [inputType]);
  return (
    <HStack
      spacing={0}
      align='center'
      w='full'
      mb={'0'}
      borderRadius='20px'
      borderWidth={1}
      borderColor='transparent'
      bg={'#8885DD'}
    >
      <Button
        isDisabled={disableChange}
        height={10}
        variant='tab'
        size='tab'
        fontSize={{ base: '9px', md: '12px' }}
        className={inputType === 'constant' ? '#4BB6B9' : 'transparent'}
        onClick={() => onChangeDataInput('constant')}
        bgColor={activeTab === 'constant' ? '#4BB6B9' : 'transparent'}
        borderRadius={activeTab === 'constant' ? '20px' : '2px'}
        borderColor='transparent'
      >
        Constant
      </Button>
      <>
        <Button
          height={10}
          variant='tab'
          size='tab'
          fontSize={{ base: '9px', md: '12px' }}
          bgColor={activeTab === 'constant_by_year' ? '#4BB6B9' : 'transparent'}
          borderRightWidth={1}
          borderLeftWidth={1}
          onClick={() => {
            onChangeDataInput('constant_by_year');
          }}
          borderRadius={activeTab === 'constant_by_year' ? '20px' : '2px'}
          borderColor='transparent'
          isDisabled={disableChange}
        >
          Yearly
        </Button>
        {timeGranularity && timeGranularity === 'm' && (
          <Button
            height={10}
            variant='tab'
            size='tab'
            fontSize={{ base: '9px', md: '12px' }}
            className={activeTab === 'raw' ? '#4BB6B9' : 'transparent'}
            onClick={() => {
              onChangeDataInput('raw');
            }}
            bgColor={activeTab === 'raw' ? '#4BB6B9' : 'transparent'}
            borderRadius={activeTab === 'raw' ? '20px' : '2px'}
            borderColor='transparent'
            isDisabled={disableChange}
          >
            Monthly
          </Button>
        )}
      </>
      <Button
        fontSize={{ base: '9px', md: '12px' }}
        height={10}
        variant='tab'
        size='tab'
        borderColor='transparent'
        bgColor={activeTab === 'growth' ? '#4BB6B9' : 'transparent'}
        onClick={() => {
          onChangeDataInput('growth');
        }}
        borderRadius={activeTab === 'growth' ? '20px' : '2px'}
        isDisabled={disableChange}
      >
        Growth rate
      </Button>
    </HStack>
  );
};

export default InputTypeGroupedButton;
