import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { useAuthContext } from 'context/authContext';

import AnonymousModalBody from './AnonymousModalBody';
import AnonymousModalTitle from './AnonymousModalTitle';

interface Properties {
  isOpen: boolean;
  onClose: () => void;
  onOpenAuthModal: () => void;
  isLogoTriggered?: boolean;
  setIsLogoTriggered?: (value: boolean) => void;
}

const LogoutModal = ({
  isOpen,
  onClose,
  onOpenAuthModal,
  isLogoTriggered,
  setIsLogoTriggered,
}: Properties) => {
  const { user, onLogout } = useAuthContext();

  const isAnonymous = user?.is_anonymous;

  const onCloseLogoutModal = () => {
    onClose();
    setIsLogoTriggered?.(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseLogoutModal}
      size={isAnonymous ? 'sm' : 'xs'}
      blockScrollOnMount={false}
    >
      <ModalOverlay bg='none' backdropFilter='auto' backdropInvert='10%' backdropBlur='5px' />
      <ModalContent pb={3} w='90%'>
        <ModalHeader position='relative'>
          {isAnonymous ? (
            <AnonymousModalTitle />
          ) : (
            <Text variant='modal' size='subTitle'>
              Confirm your action
            </Text>
          )}
        </ModalHeader>

        <ModalBody>
          {isAnonymous ? (
            <AnonymousModalBody
              onLogout={onLogout}
              onOpenAuthModal={onOpenAuthModal}
              isLogoTriggered={isLogoTriggered}
            />
          ) : (
            <Text variant='modal' size='modalContent'>
              Are you sure you want to log out?
            </Text>
          )}
        </ModalBody>

        {!isAnonymous && (
          <ModalFooter pb='0'>
            <HStack spacing='5'>
              <Button variant='clear' size='auto' fontSize='xs' color='red.500' onClick={onClose}>
                Cancel
              </Button>
              <Button
                variant='clear'
                size='auto'
                fontSize='xs'
                color='violet.900'
                onClick={onLogout}
              >
                Confirm
              </Button>
            </HStack>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};

export default LogoutModal;
