import { AddIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Flex,
  Radio,
  RadioGroup,
  Text,
  useBoolean,
  VStack,
  Image,
  useMediaQuery,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  deleteVersionIFile,
  editVersionFile,
  getVersionList,
  selectedFileId,
  setSelectedVersionDetails,
  versionData,
} from 'redux/DataImportSlice';
import CustomInputField from 'components/CustomInput';
import DeleteModal from 'components/Modals/DeleteModal';
import infoIcon from 'assets/info_icon.svg';

import { versionCheckedRadioStyles } from './Helper';
import AddVersionModal from './AddVersionModal';

const VersionList = ({ selectedFileDetails }: any) => {
  const dispatch: any = useDispatch();
  const [isMobile] = useMediaQuery('(max-width: 900px)');

  const [versionModal, setVersionModal] = useBoolean();

  const VersionListData = useSelector(getVersionList);
  const [selectedVersion, setSelectedVersion] = useState<any>();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteIdModal, setDeleteIdModal]: any = useState(null);
  const [DeleteNameModal, setDeleteNameModal] = useState('');
  const [renameItemId, setRenameItemId] = useState<number | null>(null);
  const [renameItemValue, setRenameItemValue] = useState<string | null>(null);
  const [showVersionList, setShowVersionList] = useState(true);

  const dataSourceId = selectedFileDetails?.id;

  useEffect(() => {
    if (VersionListData && VersionListData.versions && VersionListData.versions.length > 0) {
      setSelectedVersion(VersionListData.versions[0]);
    }
  }, [VersionListData]);

  const handleRadioChange = async (id: any) => {
    const selected = await VersionListData?.versions?.find((item: any) => {
      return item.id === Number(id);
    });
    setSelectedVersion(selected);
    dispatch(setSelectedVersionDetails(selected));
  };
  const deleteScenarioModal = async (event: any, id: number, name: any) => {
    event.stopPropagation();
    setIsDeleteModal(true);
    setDeleteIdModal(id);
    setDeleteNameModal(name);
  };
  const onDeleteModel = async () => {
    await dispatch(deleteVersionIFile({ dataSourceId, versionId: deleteIdModal }));
    if (deleteIdModal === selectedFileDetails?.id) {
      dispatch(
        selectedFileId({
          id: null,
          name: null,
          deatsilsPage: false,
        }),
      );
    }
    await dispatch(versionData(dataSourceId));
    setIsDeleteModal(false);
    setDeleteIdModal(null);
    setDeleteNameModal('');
  };
  const renameItem = (event: any, id: number) => {
    event.stopPropagation();
    if (renameItemId === id) {
      setRenameItemId(0);
    }
    setRenameItemId(id);
  };
  const UpdateItemApi = async (item_id: Number) => {
    const requestBody = {
      name: renameItemValue,
    };
    await dispatch(
      editVersionFile({
        dataSourceId,
        versionId: item_id,
        request_body: requestBody,
        toaster: {
          successMessage: 'Successfully updated',
          errorMessage: 'An Source with this name already exists',
        },
      }),
    );
    await setRenameItemId(0);
    await dispatch(versionData(dataSourceId));
  };

  const onKeyPressAction = async (event: any, item_id: number) => {
    event.stopPropagation();
    if (event.keyCode === 13) {
      await UpdateItemApi(item_id);
      setRenameItemValue(null);
    }
    if (event.key === 'Escape') setRenameItemValue(null);
  };
  return (
    <VStack alignItems={'start'}>
      <Flex
        justifyContent={'center'}
        align={'center'}
        onClick={() => setShowVersionList(!showVersionList)}
      >
        <ChevronDownIcon
          width={7}
          height={7}
          style={{
            transition: 'transform 0.3s ease',
            transform: showVersionList ? 'rotate(0deg)' : 'rotate(180deg)',
          }}
        />
        <Text>Version</Text>
      </Flex>
      {showVersionList && (
        <>
          <Flex
            flexDirection={'column'}
            justifyContent={'start'}
            gap={3}
            mt={0}
            m={0}
            ps={3}
            pe={4}
            w={'100%'}
            maxH={`15vh`}
            overflowX={'hidden'}
            overflowY={'auto'}
            className='scroll'
            pt={'0.5rem'}
            pb={2}
          >
            <RadioGroup
              display={'flex'}
              justifyContent={'center'}
              onChange={handleRadioChange} // Step 4: Handle change event
              value={selectedVersion?.id}
              flexDirection={'column'}
            >
              {VersionListData &&
                VersionListData?.versions?.map((item: any) => (
                  <Flex
                    gap={3}
                    key={item?.id}
                    justify={'space-between'}
                    boxShadow='2px 3px 5px rgba(0, 0, 0, 0.25)'
                    borderRadius={'9px'}
                    ps={2}
                  >
                    <Radio size='md' value={item.id} _checked={versionCheckedRadioStyles}>
                      {renameItemId === item?.id ? (
                        <CustomInputField
                          alignContent='center'
                          fontSize={isMobile ? '2xs' : 'xs'}
                          whiteSpace='nowrap'
                          overflow='hidden'
                          width={'100%'}
                          outline={'none !important'}
                          boxShadow={'none !important'}
                          border={'1px #fff solid !important'}
                          padding={'13px 5px 13px 10px'}
                          defaultValue={item?.name}
                          height='0.9rem'
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setRenameItemValue(event.target.value);
                          }}
                          onKeyDown={(event: React.ChangeEvent<HTMLInputElement>) => {
                            if (event.target.value.trim().length > 0)
                              onKeyPressAction(event, item?.id);
                          }}
                          _placeholder={{ color: 'white' }}
                          autoFocus
                          color='white'
                          onBlur={() => {
                            setRenameItemId(0);
                          }}
                        />
                      ) : (
                        <Text fontSize={isMobile ? '2xs' : 'sm'}>{item?.name}</Text>
                      )}
                    </Radio>
                    <Flex gap={3} align={'center'}>
                      <Menu closeOnSelect={true} variant='primary'>
                        <MenuButton
                          as={Button}
                          border={'none'}
                          width={'fit-content'}
                          padding={0}
                          minW={'fit-content'}
                          mr={2}
                        >
                          <Image
                            src={infoIcon}
                            w='18px'
                            h='18px'
                            className='infoButton'
                            cursor={'pointer'}
                          />
                        </MenuButton>
                        <MenuList>
                          <MenuItem
                            onClick={(event) => renameItem(event, item.id)}
                            fontSize={isMobile ? '11px' : '14px'}
                            fontFamily='regular_roboto'
                          >
                            Rename
                          </MenuItem>
                          {VersionListData && VersionListData?.versions?.length > 1 && (
                            <MenuItem
                              onClick={(event) => {
                                deleteScenarioModal(event, item?.id, item?.name);
                              }}
                              // onClick={(event) => {
                              //   actionDelete(event, item?.id);
                              // }}
                              fontSize={isMobile ? '11px' : '14px'}
                              fontFamily='regular_roboto'
                            >
                              Delete
                            </MenuItem>
                          )}
                        </MenuList>
                      </Menu>
                    </Flex>
                  </Flex>
                ))}
            </RadioGroup>
          </Flex>
          <Flex ps={'16px'} pt={'0.5rem'} cursor={'pointer'} onClick={() => setVersionModal.on()}>
            <AddIcon />
          </Flex>
        </>
      )}

      <AddVersionModal setVersionModal={setVersionModal} versionModal={versionModal} />
      <DeleteModal
        isOpen={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
        itemName={DeleteNameModal}
        onDelete={onDeleteModel}
      />
    </VStack>
  );
};

export default VersionList;
