/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable array-callback-return */
import 'react-grid-layout/css/styles.css';
import './index.css';

import React, { useEffect, useMemo, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useSelector, useDispatch } from 'react-redux';
import { Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';

import {
  fetchAllCharts,
  // fetchDashboardGraph,
  getAllChartIds,
  getAllChartData,
  getDashboardWidgetType,
  handleAddAllChartData,
  resetDashboard,
  fetchDashboardChart,
  FetchModelBlocks,
  fetchDashboardGraph,
  addAllDashboardTableData,
  getDashboardSelectedScenario,
  selectDashboardScenario,
  fetchDashboardID,
  createDashboardID,
  getModelDashboardId,
} from 'redux/DashboardSlice';
import { AppDispatch } from 'utils/GlobalHelpers';
import FullScreenLoader from 'components/FullScreenLoader';
import formatIndicatorTableData from 'utils/FormatTable';
import { fetchAllScenarios, getAllScenarios } from 'redux/ScenarioSlice';
import { getBlocks } from 'redux/ModelsSlice';

import EmptyDashboard from './EmptyDashboard';
import DashboardModal from './dashboardModal';
import DashboardingHeader from './Header';
import LayoutComponent from './LayoutComponent';

const Dashboarding = () => {
  const dispatch: AppDispatch = useDispatch();
  const allChartData: any = useSelector(getAllChartData);
  const allChartIds = useSelector(getAllChartIds);
  const { modelId } = useParams();
  const scenarios = useSelector(getAllScenarios);
  const dashboardWidgetType = useSelector(getDashboardWidgetType);
  const modelDashboardId = useSelector(getModelDashboardId);
  const selectedScenario = useSelector(getDashboardSelectedScenario);
  const model = useSelector(getBlocks);

  const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);
  const layoutsData: any = Object.values(allChartData);
  const scenarioId = selectedScenario?.id;

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (modelId) {
      dispatch(fetchAllScenarios(modelId));
      axios
        .post('https://dev.api.blox.so/worker/send-message', {
          model_id: modelId,
        })
        .then(() => {
          dispatch(fetchDashboardID({ model_id: modelId })).then((response) => {
            if (!response.payload?.id) {
              dispatch(
                createDashboardID({
                  request_body: {
                    name: 'string',
                    model_id: Number(modelId),
                    fiscal_start_month:
                      model?.time_properties?.time_granularity === 'M'
                        ? moment(model?.time_properties?.starting_period).format('MMM-YY')
                        : 'Jan-24',
                    description: 'string',
                  },
                }),
              );
            }
          });
        });

      dispatch(FetchModelBlocks({ modelId: Number(modelId) }));
    }
  }, [modelId]);

  useEffect(() => {
    if (modelDashboardId) {
      dispatch(fetchAllCharts(modelDashboardId)).then((data: any) => {
        if (data?.payload?.length === 0) {
          setLoader(false);
        }
      });
    }
  }, [modelDashboardId]);

  useEffect(() => {
    if (!selectedScenario) {
      const baseScenario = scenarios.find((item: any) => item.name === 'Baseline');

      dispatch(selectDashboardScenario(baseScenario));
    }
  }, [scenarios]);

  useEffect(() => {
    if (allChartIds?.length > 0 && scenarioId) {
      allChartIds.map((item: any) => {
        // eslint-disable-next-line default-case
        switch (item.chart_type) {
          case 'kpi': {
            dispatch(fetchDashboardChart(item.id)).then((data: any) => {
              setLoader(false);
              if (!data.error) {
                const updatedPayload = JSON.parse(JSON.stringify(data.payload));
                updatedPayload.x = Number(item.x_axis);
                updatedPayload.y = Number(item.y_axis);
                updatedPayload.h = item.height || 2;
                updatedPayload.w = item.width || 2;

                dispatch(handleAddAllChartData(updatedPayload));
              }
            });
            break;
          }
          case 'table': {
            dispatch(fetchDashboardGraph({ chartId: item.id, scenarioId, requestBody: [] })).then(
              (data: any) => {
                setLoader(false);

                if (!data.error) {
                  const layoutData = {
                    id: item.id,
                    x: Number(item.x_axis),
                    y: Number(item.y_axis),
                    h: item.height || 2,
                    w: item.width || 2,

                    chart_type: 'table',
                  };
                  dispatch(addAllDashboardTableData({ ...data.payload, ...layoutData }));
                  const formattedTableData = formatIndicatorTableData({
                    indicatorTableData: data?.payload,
                    expandKey: '',
                    tableFilter: 'M',
                  });

                  dispatch(handleAddAllChartData({ ...formattedTableData, ...layoutData }));
                }
              },
            );
            break;
          }
          case 'pie': {
            dispatch(fetchDashboardGraph({ chartId: item.id, scenarioId, requestBody: [] })).then(
              (response: any) => {
                setLoader(false);
                if (!response.error) {
                  const layoutData = {
                    id: item.id,
                    x: Number(item.x_axis),
                    y: Number(item.y_axis),
                    h: item.height || 3,
                    w: item.width || 2,
                    chart_type: 'pie',
                  };
                  dispatch(handleAddAllChartData({ ...response.payload, ...layoutData }));
                }
              },
            );
            break;
          }
          case 'bar': {
            dispatch(fetchDashboardGraph({ chartId: item.id, scenarioId, requestBody: [] })).then(
              (response: any) => {
                setLoader(false);
                const layoutData = {
                  id: item.id,
                  x: Number(item.x_axis),
                  y: Number(item.y_axis),
                  h: item.height || 4,
                  w: item.width || 2,
                  chart_type: 'bar',
                };
                dispatch(handleAddAllChartData({ ...response.payload, ...layoutData }));
              },
            );
            break;
          }
          case 'line': {
            dispatch(fetchDashboardGraph({ chartId: item.id, scenarioId, requestBody: [] })).then(
              (response: any) => {
                setLoader(false);
                const layoutData = {
                  id: item.id,
                  x: Number(item.x_axis),
                  y: Number(item.y_axis),
                  h: item.height || 4,
                  w: item.width || 2,
                  chart_type: 'line',
                };
                dispatch(handleAddAllChartData({ ...response.payload, ...layoutData }));
              },
            );
            break;
          }
        }
      });
    }
  }, [allChartIds, scenarioId]);

  useEffect(() => {
    return () => {
      dispatch(resetDashboard());
    };
  }, []);

  const handleModify = (data: any) => {
    console.log('handleModify:', data);
    // Call API
  };

  if (loader) {
    return <FullScreenLoader style={{ position: 'absolute' }} height='100%' width='100%' />;
  }
  return (
    <>
      <Box height={'100%'}>
        <DashboardingHeader />
        <Box className='scroll' height={'calc(100% - 53px)'} mt={2}>
          {Object.keys(allChartData).length > 0 ? (
            <ResponsiveReactGridLayout
              useCSSTransforms={true}
              className='dashboard-grid-container'
              draggableHandle='.dashboard-drag-header'
              allowOverlap={false}
              onLayoutChange={handleModify}
              verticalCompact={true}
              layouts={layoutsData}
              breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
              preventCollision={false}
              cols={{ lg: 8, md: 8, sm: 4, xs: 2, xxs: 2 }}
              autoSize={true}
              margin={{
                lg: [20, 20],
                md: [20, 20],
                sm: [20, 20],
                xs: [20, 20],
                xxs: [20, 20],
              }}
            >
              {Object.keys(allChartData)
                .filter((key: any) => allChartData[key]?.chart_type !== 'pie')
                ?.map((key: any, index: any) => {
                  const widget = allChartData[key];
                  const minH = widget.chart_type === 'kpi' || widget.chart_type === 'table' ? 2 : 3;
                  return (
                    <Box
                      className='dashboard-item'
                      key={index}
                      data-grid={{
                        ...widget,
                        minH, // Set the minimum height here
                        minW: 4,
                        h: widget.h < minH ? minH : widget.h,
                        w: widget.w < 4 ? 4 : widget.w,
                      }}
                    >
                      <LayoutComponent widget={widget} />
                    </Box>
                  );
                })}
            </ResponsiveReactGridLayout>
          ) : (
            <>
              <EmptyDashboard />
            </>
          )}
        </Box>
      </Box>
      {dashboardWidgetType !== 0 && <DashboardModal />}
    </>
  );
};

export default Dashboarding;
