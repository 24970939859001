import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
  style?: any;
  onClick?: () => void;
}

const ArrowLeftBlocks = ({
  width = 4.75,
  height = 4.75,
  color = 'white.900',
  style,
  onClick,
}: Properties) => {
  return (
    <Icon
      viewBox='0 0 15 25'
      fill='white'
      width={width}
      height={height}
      color={color}
      {...style}
      onClick={onClick}
    >
      <path
        d='M12.8611 24.7437L0.984373 12.867L12.8611 0.990236L14.9693 3.09836L5.20062 12.867L14.9693 22.6356L12.8611 24.7437Z'
        fill={color}
      />
    </Icon>
  );
};

export default ArrowLeftBlocks;
