import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const Info = ({ width = 3.75, height = 3.75, color = 'white' }: Properties) => {
  return (
    <Icon viewBox='0 0 15 15' width={width} height={height}>
      <path
        d='M6.875 4.375H8.125V5.625H6.875V4.375ZM6.875 6.875H8.125V10.625H6.875V6.875ZM7.5 1.25C4.05 1.25 1.25 4.05 1.25 7.5C1.25 10.95 4.05 13.75 7.5 13.75C10.95 13.75 13.75 10.95 13.75 7.5C13.75 4.05 10.95 1.25 7.5 1.25ZM7.5 12.5C4.74375 12.5 2.5 10.2563 2.5 7.5C2.5 4.74375 4.74375 2.5 7.5 2.5C10.2563 2.5 12.5 4.74375 12.5 7.5C12.5 10.2563 10.2563 12.5 7.5 12.5Z'
        fill={color}
      />
    </Icon>
  );
};

export default Info;
