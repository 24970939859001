import AWS from 'aws-sdk';

AWS.config.region = 'us-east-1';
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-east-1:a327c876-9da3-4697-a766-64f19b7ec8db',
});

async function handleFileUpload(file: File, bucketName: string): Promise<string> {
  const s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: { Bucket: bucketName },
  });
  const promise = new Promise<string>((resolve, reject) => {
    const parameters: any = {
      Key: `${Date.now()}_${file.name}`,
      Body: file,
      ContentType: file.type,
      ACL: 'public-read',
    };
    s3.upload(parameters, (error: any, data: any) => {
      if (error) {
        reject(error);
      } else {
        resolve(data.Location);
      }
    });
  });
  return promise;
}
async function readDataFile(key: string, bucketName: string): Promise<string> {
  const s3 = new AWS.S3({
    apiVersion: '2006-03-01',

    params: { Bucket: bucketName },
  });

  return s3.getSignedUrlPromise('getObject', { Bucket: bucketName, Key: key });
}
function replaceSpecialChars(inputString: string) {
  // Define a map of special characters and their replacements
  const specialCharsMap: any = {
    '!': 'exclamation_mark',
    '@': 'at_sign',
    '#': 'hash',
    '%': 'percent',
    '^': 'caret',
    '&': 'ampersand',
    '*': 'asterisk',
    '-': '',
    '(': '',
    ')': '',
    '/': '',
  };

  // Use a regular expression to replace special characters
  return inputString.replace(/[!#$%&()*/@[^{}]/g, (match) => specialCharsMap[match]);
}

async function handleDataFileUpload(
  file: File,
  bucketName: string,
  progressCallback: (percentage: number) => void,
): Promise<string> {
  const s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: { Bucket: bucketName },
  });
  let fileName = '';
  const name = file.name.split(' ').map((subString: string) => {
    // return subString.replace('[-+.^:,()=+%/]', '_');
    return replaceSpecialChars(subString);
  });
  fileName = name.join('');

  const myNewFile = new File([file], fileName, { type: file.type });
  const parameters: any = {
    Key: `${Date.now()}_${fileName}`,
    Body: myNewFile,
    ContentType: file.type,
  };
  const upload = s3.upload(parameters);
  upload.on('httpUploadProgress', (progress) => {
    const { loaded, total } = progress;
    const percentage = Math.round((loaded / total) * 100);
    progressCallback(percentage);
  });

  const data = await upload.promise();
  return data.Location;
}

function handleFileDelete(fileKey: string, bucketName: string): Promise<void> {
  const s3 = new AWS.S3({
    apiVersion: '2006-03-01',
    params: { Bucket: bucketName },
  });
  const promise = new Promise<void>((resolve, reject) => {
    const parameters: any = {
      Key: fileKey,
    };
    s3.deleteObject(parameters, (error: any) => {
      if (error) {
        reject(error);
      } else {
        resolve();
      }
    });
  });
  return promise;
}

async function replaceImage(
  existingKey: string,
  newFile: File,
  bucketName: string,
): Promise<string> {
  await handleFileDelete(existingKey, bucketName);
  const newUrl = await handleFileUpload(newFile, bucketName);
  return newUrl;
}

export { handleDataFileUpload, handleFileDelete, handleFileUpload, readDataFile, replaceImage };
