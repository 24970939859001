import React, { useState, useEffect } from 'react';
import { Box } from '@chakra-ui/react';

interface TypewriterProperties {
  text: string;
  delay: number;
  infinite?: boolean;
}

const Typewriter: React.FC<TypewriterProperties> = ({ text, delay, infinite }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (currentIndex <= text.length && text[currentIndex]) {
      timeout = setTimeout(() => {
        setCurrentText((previousText) => previousText + text[currentIndex]);
        setCurrentIndex((previousIndex) => previousIndex + 1);
      }, delay);
    } else if (infinite) {
      setCurrentIndex(0);
      setCurrentText('');
    }

    return () => clearTimeout(timeout);
  }, [currentIndex, delay, infinite, text]);

  return (
    <Box
      fontFamily='Roboto regular'
      p={'0.5rem'}
      bg={'#fff'}
      borderRadius={'10px 10px 10px 0'}
      overflow={'hidden'}
      textOverflow={'ellipsis'}
      fontSize={'14px'}
      alignSelf={'center'}
      color={'black'}
      border={'1px solid #DDDBEC'}
      dangerouslySetInnerHTML={{
        __html: currentText,
      }}
    ></Box>
  );
};

export default Typewriter;
