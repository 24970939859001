import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const PlanIcon = ({ width = 6, height = 6, color = 'green.900' }: Properties) => {
  return (
    <Icon viewBox='0 0 31 31' width={width} height={height} color={color}>
      <g mask='url(#mask0_25_3891)'>
        <path
          d='M4.55444 27.072V24.5853L7.04118 22.0986V27.072H4.55444ZM9.52791 27.072V19.6118L12.0146 17.1251V27.072H9.52791ZM14.5014 27.072V17.1251L16.9881 19.6429V27.072H14.5014ZM19.4749 27.072V19.6429L21.9616 17.1562V27.072H19.4749ZM24.4483 27.072V14.6383L26.9351 12.1516V27.072H24.4483ZM4.55444 20.6376V17.1251L13.258 8.42151L18.2315 13.395L26.9351 4.69141V8.20392L18.2315 16.9075L13.258 11.934L4.55444 20.6376Z'
          fill='white'
          fillOpacity='0.2'
        />
      </g>
    </Icon>
  );
};

export default PlanIcon;
