import {
  Box,
  Flex,
  Skeleton,
  // Table, Td, Text, Th, Thead, Tooltip, Tr
} from '@chakra-ui/react';
// import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

// import transformIndicatorDisplay from 'connectors/transformIndicatorDisplay';
// import { ModifyIndicatorDataInput } from 'models/requestBody/modifyIndicatorDataInput.interface';
// import { InputDataValue } from 'models/response/indicator.interface';
// import { CHART_COLORS } from 'projectConstants';
import { getIndicatorDetailTableData } from 'redux/IndicatorPageSlice';
// import { getCurrentDimensionIndex } from 'redux/InputPageSlice';
// import { getSelectedDriver } from 'redux/PlanPageSlice';
// import { getBaseScenario } from 'redux/ScenarioSlice';
// import apiService from 'services';

// import EditBox from './EditBox';
// import ItemActions from './ItemActions';
// import MonthlyView from './MonthlyView';
// import MonthlyViewTable from './MonthlyViewTable';
import MultiDimensionTable from './MultiDimensionTable';
// import ExcelTable from './ExcelTable';

interface Properties {
  refetchRequest: () => void;
  isPercentage: any;
}

const IndicatorTable = ({ refetchRequest, isPercentage }: Properties) => {
  // const currentDriver = useSelector(getSelectedDriver);
  const indicator: any = useSelector(getIndicatorDetailTableData);
  // const dimensionItems = useSelector(getDimensionItems);
  // const isPercentage = indicator?.data_format.toLowerCase() === 'percentage';
  // const inputType = indicator?.input[0].type;
  // const timeDimensionId = currentDriver?.time_dim;
  // const currentDimensionIndex = useSelector(getCurrentDimensionIndex);
  // const baseScenario = useSelector(getBaseScenario);
  // const currentDimensionId = currentDriver?.dimensions[currentDimensionIndex];
  // const indicatorId = currentDriver?.id;
  // const inputDisplay = transformIndicatorDisplay({
  //   data: indicator?.input_display,
  //   driverId: currentDriver?.id,
  // });

  // const getRowName = (indicatorName: string) => {
  //   if (indicatorName === 'value') {
  //     return 'Value';
  //   }

  //   if (indicatorName === 'start_value') {
  //     return 'Start Value';
  //   }

  //   if (indicatorName === 'growth_rate') {
  //     return 'Growth Rate';
  //   }

  //   return indicatorName;
  // };

  // const { mutate } = useMutation(
  //   ({ indicator_id, request_body }: ModifyIndicatorDataInput) =>
  //     apiService.patch_indicator_data_input({ indicator_id, request_body }),
  //   {
  //     onSuccess: () => {
  //       refetchRequest();
  //     },
  //   },
  // );
  // const onSubmitIndicatorValue = (
  //   fieldValue: number | string,
  //   fieldName: string,
  //   callback: (value: boolean) => void,
  //   indicatorIndex: number,
  // ) => {
  //   const requestBody = (inputDisplay as any)[indicatorIndex].reduce(
  //     (accumulator: any, item: InputDataValue, index: number, array: any) => {
  //       if (inputType === 'constant') {
  //         accumulator.data_values = {
  //           ...(currentDimensionId && { [currentDimensionId]: item.dimensionItem }),
  //           [fieldName]: isPercentage ? (+fieldValue / 100).toString() : fieldValue,
  //         };
  //       }

  //       if (inputType === 'constant_by_year' || inputType === 'raw') {
  //         accumulator.data_values = {
  //           ...(currentDimensionId && { [currentDimensionId]: item.dimensionItem }),
  //           [timeDimensionId]: fieldName,
  //           value: isPercentage ? (+fieldValue / 100).toString() : fieldValue,
  //         };
  //       }

  //       if (inputType === 'growth') {
  //         accumulator.data_values = {
  //           ...(currentDimensionId && { [currentDimensionId]: item.dimensionItem }),
  //           start_value: array[0].value.toString(),
  //           growth_rate: array[1].value.toString(),
  //         };

  //         Object.assign(accumulator.data_values, {
  //           [fieldName]:
  //             isPercentage && fieldName !== 'growth_rate'
  //               ? (+fieldValue / 100).toString()
  //               : fieldValue,
  //         });
  //       }
  //       return accumulator;
  //     },
  //     { scenario_id: baseScenario?.id },
  //     {},
  //   );

  //   mutate({
  //     indicator_id: indicatorId,
  //     request_body: requestBody,
  //   });

  //   callback(false);
  // };

  if (!indicator) {
    return (
      <Flex direction='column' pb='1'>
        {[...new Array(6)].map((_, index) => (
          <Flex key={index} p={2} justifyContent={'space-between'}>
            {[...new Array(4)].map((__, subIndex: number) => (
              <Box key={subIndex} flex={1} mr={2}>
                <Skeleton startColor='white' endColor='#CCD4E8' height='35px' width={'100%'} />
              </Box>
            ))}
          </Flex>
        ))}
      </Flex>
    );
  }

  // if (inputType === 'raw' && dimensionItems?.items) {
  //   return <MonthlyView refetchRequest={refetchRequest} />;
  // }

  // if (inputType === 'raw') {
  //   return <MonthlyViewTable refetchRequest={refetchRequest} />;
  // }

  return (
    <>
      {/* <Table
        variant='dimension'
        layout={indicator?.input_display?.columns?.length > 4 ? 'auto' : 'fixed'}
      >
        <Thead style={{ backgroundColor: 'transparent' }}>
          <Tr>
            {dimensionItems?.items && <Th px='1' py='1' width={'150px'}></Th>}

            {indicator?.input_display?.columns.map((input: string, index: string) => {
              return (
                <Th px='1' py='1' key={index}>
                  <Text
                    size='secondary'
                    lineHeight='4'
                    whiteSpace='nowrap'
                    fontWeight={'normal'}
                    textAlign='center'
                  >
                    {getRowName(input)}
                  </Text>
                </Th>
              );
            })}
          </Tr>
          {indicator?.input_display?.index.map((row: any, index: number) => {
            return (
              <Tr key={index}>
                {dimensionItems?.items && (
                  <Td px='1' py='1'>
                    <Tooltip label={row}>
                      <Flex alignItems={'center'}>
                        <Box bg={CHART_COLORS[index]} h='15px' w='15px' mr='2'></Box>
                        <ItemActions
                          row={row}
                          currentDimensionItemIndex={index}
                          refetchRequest={refetchRequest}
                        />
                      </Flex>
                    </Tooltip>
                  </Td>
                )}

                {indicator?.input_display?.data[index]?.map((value: any, valueIndex: number) => (
                  <Td
                    px='1'
                    py='1'
                    key={`${valueIndex} + ${indicator?.input_display?.columns[valueIndex]}`}
                  >
                    <EditBox
                      isGrowthValue={
                        indicator?.input_display?.columns[valueIndex] === 'growth_rate'
                      }
                      fieldValue={value}
                      onSubmit={onSubmitIndicatorValue}
                      formatProperties={indicator?.data_format_properties}
                      indicatorName={indicator?.input_display?.columns[valueIndex]}
                      index={index}
                      isPercentage={isPercentage}
                    />
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Thead>
      </Table> */}
      <Flex ms={2} mt={2}>
        <MultiDimensionTable refetchRequest={refetchRequest} isPercentage={isPercentage} />
      </Flex>
    </>
  );
};
export default IndicatorTable;
