import { Flex } from '@chakra-ui/react';
import { connect } from 'react-redux';

import Spinner from 'components/Spinner';
import { HEADER_HEIGHT } from 'projectConstants';

interface Properties {
  isLoading: boolean;
}

const SharedLoader = ({ isLoading }: Properties) => {
  if (!isLoading) return <></>;
  return (
    <Flex
      zIndex='1000'
      bg={'gradient.body'}
      position='fixed'
      top={HEADER_HEIGHT}
      width='100%'
      height={`calc(100vh - ${HEADER_HEIGHT})`}
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <Spinner />
    </Flex>
  );
};

const stateToProperty = (state: any) => {
  return { isLoading: state?.sharedSlice?.loading };
};

export default connect(stateToProperty)(SharedLoader);
