/* eslint-disable consistent-return */
import { useSelector } from 'react-redux';
import React, { useMemo } from 'react';

import { getDashboardWidgetType, getTableIndicator, getChartType } from 'redux/DashboardSlice';

import ChartType from '../../chart/ChartType';

import DashboardModalBlock from './Block';
import DashboardModalComparisons from './Comparison';
import DashboardModalFilters from './Filters';
import DashboardModalIndicator from './Indicator';
import DashboardModalTime from './Time';
import DashboardTableIndicator from './SortableIndicator';
import Dimension from './Dimension';

interface Properties {
  isEditableModal?: boolean;
  updateChart?: any;
}

const DashboardWidgetSetup = ({ isEditableModal, updateChart }: Properties) => {
  const widetType = useSelector(getDashboardWidgetType);
  const graphData = useSelector(getTableIndicator);

  const chartType = useSelector(getChartType);

  const timeFilter = useMemo(() => {
    const timeData = updateChart?.chart_filters?.find(
      (item: any) => item.name === 'Time' && item.filter_type === 'primary',
    );
    return timeData;
  }, [updateChart]);

  const dimFilter = useMemo(() => {
    const dimData = updateChart?.chart_filters?.filter(
      (item: any) => item.filter_type === 'dimension',
    );
    return dimData;
  }, [updateChart]);

  return (
    <>
      {widetType === 2 && <ChartType />}
      {/* Different component For table and chart instruments */}
      {widetType === 3 || (widetType === 2 && chartType !== 1) ? (
        <DashboardTableIndicator />
      ) : (
        <React.Fragment>
          <DashboardModalBlock
            chartMatrix={updateChart?.chart_metrics}
            isEditKpi={isEditableModal}
          />
          <DashboardModalIndicator
            chartMatrix={updateChart?.chart_metrics}
            isEditKpi={isEditableModal}
          />
        </React.Fragment>
      )}
      {chartType === 1 && (
        <Dimension chartData={updateChart} dimensionData={graphData} isEditMode={isEditableModal} />
      )}
      <DashboardModalTime chartFilter={timeFilter} isEditKpi={isEditableModal} />
      <DashboardModalFilters chartFilter={dimFilter} isEditKpi={isEditableModal} />
      {widetType !== 2 && (
        <DashboardModalComparisons chartFilter={updateChart} isEditKpi={isEditableModal} />
      )}
    </>
  );
};

export default DashboardWidgetSetup;
