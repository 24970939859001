import { Box } from '@chakra-ui/react';
import './BoxShimmer.scss';

interface Properties {
  isList?: boolean;
  isMobile?: boolean;
  width?: string;
  minH?: string;
  height?: string;
}

const BoxShimmer = ({ width, minH, height, isList, isMobile }: Properties) => {
  return (
    <>
      {isMobile ? (
        <Box w={width} minH={minH} height={height}>
          <div className='card'>
            <div className='p-20'>
              <div className='shimmerBG mobile-content-line border-line'></div>
            </div>
          </div>
        </Box>
      ) : (
        <>
          {isList ? (
            <Box w={width} minH={minH} height={height}>
              <div className='card'>
                <div className='p-20'>
                  <div className='shimmerBG list-content-line m-t-12'></div>
                  <div className='shimmerBG list-content-line border-line'></div>
                  <div className='shimmerBG list-content-line end'></div>
                </div>
              </div>
            </Box>
          ) : (
            <Box w={width} minH={minH} height={height}>
              <div className='card'>
                <div className='shimmerBG media'></div>
                <div className='p-20'>
                  <div className='shimmerBG content-line m-t-16'></div>
                  <div className='shimmerBG content-line border-line'></div>
                  <div className='shimmerBG content-line end'></div>
                </div>
              </div>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default BoxShimmer;
