import {
  Box,
  Button,
  Flex,
  HStack,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text,
  useMediaQuery,
  useToast,
  Spinner,
} from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FormField from 'components/FormField';
import { SetAuthPageType, ResetPassword } from 'redux/AuthSlice';
import Logo from 'components/Icons/Logo';
import CustomToast from 'components/CustomToast';
import { ResetPasswordSchema } from 'validation/schemas/AuthenticationSchema';

const initialValues = {
  email: '',
};

const ResetPasswordPage = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const [successModal, setSuccessModal] = useState({ isVisible: false, type: 1, name: '' }); // type 1:forgot password, 2: set password;

  const [isMobile] = useMediaQuery('(max-width: 576px)');

  const handleSubmit = (form: any, setSubmitting: (data: boolean) => void) => {
    dispatch(
      ResetPassword({
        request_body: { username: form.email },
        toaster: true,
      }),
    ).then((data: any) => {
      setSubmitting(false);
      if (data?.payload.is_password_set === true || data?.payload.is_password_set === false) {
        setSuccessModal({
          isVisible: true,
          type: data?.payload.is_password_set === true ? 1 : 2,
          name: form.email,
        });
        localStorage.removeItem('BLOX_PASSWORD_TOKEN');
      } else {
        toast({
          duration: 2500,
          render: () => (
            <CustomToast
              title={data?.payload?.message || 'Something went wrong..'}
              status='error'
            />
          ),
        });
      }
    });
  };

  return (
    <>
      <Box>
        <Formik
          initialValues={initialValues}
          onSubmit={(form, { setSubmitting }) => {
            handleSubmit(form, setSubmitting);
          }}
          validationSchema={ResetPasswordSchema}
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <HStack spacing='7' alignItems={'center'} flexWrap={'wrap'}>
                <FormField
                  label='Enter your email address'
                  name='email'
                  type='email'
                  labelStyles={{
                    fontSize: '14px',
                    color: '#121826',
                  }}
                  inputStyles={{
                    maxWidth: '300px',
                    boxShadow: '0px 0px 0px 1px #7A849E',
                  }}
                  placeholder='Email'
                  error={errors.email}
                  touched={touched.email}
                  disabled={isSubmitting}
                >
                  <Box display={'flex'} flexDirection={'column'}>
                    <Button
                      type='submit'
                      w='150px'
                      h='9'
                      borderRadius='xs'
                      fontSize='xs'
                      bgColor='green.900'
                      disabled={isSubmitting}
                      _hover={{}}
                    >
                      Continue {isSubmitting && <Spinner ml={'4'} />}
                    </Button>
                    <small
                      style={{
                        width: '100%',
                        cursor: 'pointer',
                        color: '#939393',
                        textAlign: 'center',
                        marginTop: '.7rem',
                        textDecoration: 'underline',
                      }}
                      onClick={() => {
                        navigate('/');
                        dispatch(SetAuthPageType(1));
                      }}
                    >
                      Back to login
                    </small>
                  </Box>
                </FormField>
                {isMobile && (
                  <>
                    <Button
                      margin={'1rem 0 0 0 !important'}
                      type='submit'
                      w='100%'
                      h='12'
                      borderRadius='xs'
                      fontSize='xs'
                      bgColor='green.900'
                      disabled={isSubmitting}
                      _hover={{}}
                    >
                      Continue {isSubmitting && <Spinner ml={'4'} />}
                    </Button>
                    <small
                      style={{
                        width: '100%',
                        cursor: 'pointer',
                        color: '#939393',
                        textAlign: 'center',
                        margin: '.7rem 0 0 0',
                        textDecoration: 'underline',
                      }}
                      onClick={() => {
                        navigate('/');
                        dispatch(SetAuthPageType(1));
                      }}
                    >
                      Back to login
                    </small>
                  </>
                )}
              </HStack>
            </Form>
          )}
        </Formik>
      </Box>
      <Modal
        onClose={() => {
          setSuccessModal({ ...successModal, isVisible: !successModal.isVisible });
        }}
        isOpen={successModal.isVisible}
        isCentered
        size={isMobile ? 'xs' : 'lg'}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent minWidth={isMobile ? '90%' : 'fit-content'}>
          <ModalBody
            alignItems={'center'}
            p={isMobile ? 6 : 10}
            fontFamily={'regular_roboto'}
            boxShadow={'0 15px 15px rgba(0,0,0,15%)'}
          >
            <Flex direction='column' justify='center' align='center'>
              <Logo height={10} width={'fit-content'} />
              <Heading
                fontFamily='bold'
                color='black'
                as={isMobile ? 'h5' : 'h3'}
                size={'md'}
                textAlign='center'
                pt='6'
              >
                Email sent to
                <Text size={'md'} color='green.900' style={{ wordBreak: 'break-word' }}>
                  {successModal.name}
                </Text>
              </Heading>
              <Text
                wordBreak='break-word'
                color='#1A202C'
                pt='4'
                textAlign='center'
                fontSize={isMobile ? 'xs' : 'md'}
              >
                {successModal.type === 1
                  ? 'Please check your inbox and click on the link to reset your password.'
                  : 'Please check your inbox and click on the link to set your password.'}
              </Text>
            </Flex>
            <Flex width={'100%'} justifyContent={'center'}>
              <Button
                width={'fit-content'}
                bgColor={'green.900'}
                mt={6}
                onClick={() => {
                  setSuccessModal({ ...successModal, isVisible: !successModal.isVisible });
                  navigate('/');
                  dispatch(SetAuthPageType(1));
                }}
                borderColor={'1px solid #7163D0'}
                borderRadius='10px'
              >
                <Text fontSize={'sm'}>{successModal.type === 1 ? 'Back to login' : 'Ok'}</Text>
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ResetPasswordPage;
