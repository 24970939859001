import { ChevronDownIcon, InfoIcon } from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  VStack,
  FormControl,
  Button,
  Flex,
} from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';

import { textStyles } from 'pages/ModelOverviewPage/Import/Helper';
import {
  getNoheadersStatus,
  getTableHeader,
  handleActionStepNext,
  resetMultipleTimePeriodValue,
  setSelectedAreas,
  setSingleTimePeriodValue,
} from 'redux/DataImportSlice';

import ActionStepFooter from '../../ActionStepFooter';

const validationSchema = Yup.object().shape({
  singleTimePeriodValue: Yup.string().required('Time value is required'),
});

interface Properties {
  setHelpModalSeleceted?: any;
  getFormValue?: any;
}
const Rows = ({ setHelpModalSeleceted, getFormValue }: Properties) => {
  const dispatch: any = useDispatch();
  const headerNames = useSelector(getTableHeader);
  const isHeader = useSelector(getNoheadersStatus);
  const [customHeaders, setCustomHeaders] = useState<any[]>(headerNames ?? []);

  useEffect(() => {
    if (isHeader) {
      const array: string[] = new Array(26)
        .fill(1)
        .map((_: string, index: number) => String.fromCodePoint(65 + index));
      const newHeaders = array
        .slice(0, headerNames.length)
        .map((item: string, headerIndex: number) => {
          return { current: `${headerNames[headerIndex]}`, modified: `Column ${item}` };
        });
      setCustomHeaders(newHeaders);
    } else {
      setCustomHeaders(
        headerNames.map((item: string) => {
          return { current: `${item}`, modified: `${item}` };
        }),
      );
    }
  }, [isHeader, headerNames]);
  const getSelectedColumnOption = (value: string) => {
    const data = customHeaders.find((item: any) => item.current === value);
    return data?.modified;
  };

  const handleSubmit = (form: any, setSubmitting: (data: boolean) => void) => {
    dispatch(setSingleTimePeriodValue(form)).then(() => {
      setSubmitting(false);
      dispatch(resetMultipleTimePeriodValue());
      dispatch(handleActionStepNext());
    });
  };

  return (
    <VStack display='flex' justify={'center'} w={'100%'} pt={3}>
      <Text color={'black'} {...textStyles} textAlign={'start'} width={'100%'}>
        Select the column which has the time periods for each row
      </Text>
      <Formik
        initialValues={{
          singleTimePeriodValue: '' || getFormValue?.singleTimePeriodValue,
        }}
        validationSchema={validationSchema}
        onSubmit={(form, { setSubmitting }) => handleSubmit(form, setSubmitting)}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue, isSubmitting }: any) => {
          return (
            <Form
              style={{
                width: '100%',
              }}
            >
              <FormControl pt={2}>
                <Menu closeOnSelect={true} isLazy>
                  <MenuButton
                    as={Button}
                    backgroundColor='white'
                    maxHeight={'40px'}
                    borderRadius='5px'
                    minWidth='140px'
                    textAlign='left'
                    padding='0 .8rem'
                    boxShadow='rgb(139, 137, 177) 0px 0px 0px 0.6px'
                    rightIcon={<ChevronDownIcon fontSize='md' />}
                    fontWeight='300'
                    fontSize='xs'
                    color='black.900'
                    w='100%'
                    border={
                      errors.singleTimePeriodValue ? '2px solid #fe6e6e' : '0.6px solid #8B89B1'
                    }
                  >
                    <Text me={3} noOfLines={1} height={'fit-content'}>
                      {getSelectedColumnOption(values?.singleTimePeriodValue) ?? (
                        <Text opacity={0.5}>Select column</Text>
                      )}
                    </Text>
                  </MenuButton>
                  <MenuList
                    height='fit-content'
                    maxHeight='20vh'
                    overflowY='auto'
                    className='scroll'
                  >
                    <MenuOptionGroup
                      defaultValue={values?.singleTimePeriodValue}
                      onChange={async (role: any) => {
                        await setFieldValue('singleTimePeriodValue', role);
                        await dispatch(setSelectedAreas({ selectedColumnOption: role }));
                      }}
                    >
                      <MenuItemOption>Select...</MenuItemOption>
                      {customHeaders.map((header: any) => (
                        <MenuItemOption key={header} value={header.current}>
                          {header.modified}
                        </MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
                <ErrorMessage name='singleTimePeriodValue' component='div' className='error' />
              </FormControl>
              <Flex alignItems={'center'} justifyContent={'space-between'} pb={8} pt='5' w={'100%'}>
                <Flex>
                  <Text
                    {...textStyles}
                    color={'black'}
                    textDecoration='underline'
                    onClick={() => {
                      setHelpModalSeleceted.on();
                    }}
                    display={'none'}
                  >
                    Help <InfoIcon color={'#8B89B1'} ms={3} />
                  </Text>
                </Flex>
                <ActionStepFooter
                  nextButtonName='Continue'
                  prevButtonName='Previous'
                  isSubmitting={isSubmitting}
                />
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </VStack>
  );
};

export default Rows;
