import { HStack, IconButton, Text } from '@chakra-ui/react';

import Plus from 'components/Icons/Plus';
import PlanPermission from 'container/PlanPermission';

interface Properties {
  label?: string;
  planPermission: boolean;
  onClick: any;
}

const AddButton = ({ label, planPermission, onClick }: Properties) => {
  return (
    <>
      {label ? (
        <HStack onClick={planPermission ? undefined : onClick} cursor='pointer'>
          <IconButton
            size='ghost'
            variant='ghost'
            color='white.900'
            icon={<Plus />}
            aria-label='add dimension'
            cursor={planPermission ? 'not-allowed' : 'pointer'}
          />

          <Text size='property_help' cursor={planPermission ? 'not-allowed' : 'pointer'}>
            {label}
          </Text>
        </HStack>
      ) : (
        <IconButton
          size='ghost'
          variant='ghost'
          color='white.900'
          icon={<Plus />}
          aria-label='add dimension'
          onClick={planPermission ? undefined : onClick}
          cursor={planPermission ? 'not-allowed' : 'pointer'}
        />
      )}
    </>
  );
};

export default PlanPermission(AddButton);
