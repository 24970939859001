export default {
  body: {
    color: 'white.900',
    fontFamily: 'regular_roboto',
  },

  '.carousel': {
    position: 'relative',

    '& > button': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1000,
    },
    '& button[aria-label="next button"]': { right: -3 },
    '& button[aria-label="previuos button"]': { left: -3 },
    '& .slick-slide': {
      borderLeft: '1px',
      borderColor: 'white.400',
    },
    '& .slick-current': { borderColor: 'transparent' },
  },

  '.carousel_bg_white': {
    position: 'relative',
    '& > button': {
      position: 'absolute',
      top: '25%',
      transform: 'translateY(-50%)',
      zIndex: 1000,
      paddingRight: '0.4rem',
    },
    '& button[aria-label="next button"]': { right: -3, color: 'black' },
    '& button[aria-label="previuos button"]': { left: -3, color: 'black' },
    '& .slick-slide': {
      borderColor: 'white.400',
    },
    '& .slick-current': { borderColor: 'transparent' },
  },

  '.home_grid_carousel': {
    position: 'relative',
    '& > button': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      color: 'grey.900',
      zIndex: 1000,
      '& svg': {
        h: '40px',
        w: '40px',
      },
    },
    '& button[aria-label="next button"]': { right: '-20px' },
    '& button[aria-label="previuos button"]': { left: '-40px' },
  },

  '.home_list_carousel': {
    position: 'relative',
    '& > button': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      color: 'grey.900',
      zIndex: 1000,
      '& svg': {
        h: '40px',
        w: '40px',
      },
    },
    '& button[aria-label="next button"]': { right: '-20px' },
    '& button[aria-label="previuos button"]': { left: '-40px' },
    '& .slick-slide > div > div:not(:last-of-type)': { mb: '1' },
  },
  '.scenario-menu': {
    '& span:nth-of-type(2)': {
      width: '100%',
    },
  },
  '.css-1qq679y': {
    zIndex: '100 !important',
    width: { base: '100%', lg: '260px', md: '260px', sm: '100%' },
  },

  '.scroll': {
    '::-webkit-scrollbar': { width: 1, height: 1 },
    '::-webkit-scrollbar-track': { bgColor: 'transparent' },
    '::-webkit-scrollbar-thumb': { bgColor: 'violet.300', borderRadius: 1 },
    '::-webkit-scrollbar-thumb: hover': { bgColor: 'green.900' },
  },

  '.sm-scroll': {
    '::-webkit-scrollbar': { width: 1, height: 1.5 },
    '::-webkit-scrollbar-track': { bgColor: 'transparent' },
    '::-webkit-scrollbar-thumb': { bgColor: 'green.900', borderRadius: 1 },
    '::-webkit-scrollbar-thumb: hover': { bgColor: 'green.900' },
  },

  '.horizontal_scroll': {
    '::-webkit-scrollbar': { height: 2.5, width: 2.5 },
    '::-webkit-scrollbar-thumb': { bgColor: 'green.900', borderRadius: 1 },
  },

  '.horizontal_scroll_monthly': {
    '::-webkit-scrollbar': { height: 1.5, width: 2.5 },
    '::-webkit-scrollbar-thumb': { bgColor: 'green.900', borderRadius: 1 },
  },

  '.table_scroll': {
    '::-webkit-scrollbar': { height: 2, width: 2 },
    '::-webkit-scrollbar-track': {
      bgColor: '#f1f1f1',
    },
    '::-webkit-scrollbar-thumb': {
      bgColor: 'green.900',
      borderRadius: '5px',
    },
    '::-webkit-scrollbar-corner': {
      bgColor: '#f1f1f1',
    },
  },

  '.dataImport_scroll': {
    '::-webkit-scrollbar': { height: 2, width: 2 },
    '::-webkit-scrollbar-track': {
      bgColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      bgColor: 'green.900',
      borderRadius: '5px',
    },
    '::-webkit-scrollbar-corner': {
      bgColor: 'transparent',
    },
  },

  '.filters_scroll': {
    '::-webkit-scrollbar': { width: '3px', height: 1.5 },
    '::-webkit-scrollbar-track': { bgColor: '#D9D9D9' },
    '::-webkit-scrollbar-thumb': { bgColor: 'green.900', borderRadius: '1px' },
    '::-webkit-scrollbar-thumb: hover': { bgColor: 'green.900' },
  },

  '.indicator-table': {
    position: 'relative',
  },
  '.disabled': {
    opacity: '.5',
    cursor: 'no-drop',
  },
  '.error': {
    color: '#fe6e6e',
    fontSize: '3xs',
    marginTop: '2',
    width: '100%',
    textAlign: 'left',
  },
  '.form-control': {
    boxShadow: '0px 0px 0px 1.5px #8B8AAE',
    borderRadius: 'xs',
    padding: '.5rem',
    fontSize: '.75rem',
    outline: 'none',
  },
  '.form-control-error': {
    boxShadow: '0px 0px 0px 1.5px #8B8AAE',
    borderRadius: 'xs',
    padding: '.5rem',
    fontSize: '.75rem',
    outline: 'none',
  },
  Form: {
    height: '100%',
  },
  '.settings-container': {
    opacity: 0,
    transform: 'translateX(100%)',
    transition: 'all 300ms ease',
  },
  '.settings-container.visible': {
    opacity: 1,
    transform: 'translateX(0)',
  },
  '.settings-container.hidden': {
    opacity: 0,
    transform: 'translateX(100%)',
  },
  '.properties-container': {
    opacity: 0,
    transform: 'translateX(100%)',
    transition: 'all 300ms ease',
  },
  '.properties-container.visible': {
    opacity: 1,
    transform: 'translateX(0)',
  },
  '.properties-container.hidden': {
    opacity: 0,
    transform: 'translateX(100%)',
  },
  '.input-panel': {
    opacity: 0,
    transition: 'all 500ms ease',
  },
  '.input-panel.visible': {
    opacity: 1,
  },
  '.input-panel.hidden': {
    opacity: 0,
  },
  '.checkboxStyle span': {
    borderColor: 'transparent !important',
    width: '14px',
    height: '0px',
    borderWidth: '0px',
    borderRadius: '0px',
  },
  '.rg-cell.formula-error': {
    borderColor: '#E23F3F !important',
    borderTopWidth: '1px !important',
    borderBottomWidth: '1px !important',
    borderTopStyle: 'solid !important',
    borderBottomStyle: 'solid !important',
    borderRightColor: 'rgba(255, 255, 255, 0.3) !important',
    backgroundColor: 'rgb(105 83 161) !important',
    borderLeftWidth: 'none',
    borderLeftRadius: 'none',
  },
  '.rg-cell.rg-header-cell.actuals-cell': {
    backgroundColor: ' #39ABB5 !important',
  },
  '.rg-cell.actuals-cell': {
    backgroundColor: ' #453D85 !important',
  },
  '.text-overflow': {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  '.filters-all-checkbox .chakra-checkbox__control[data-indeterminate] svg': {
    background: '#8E8DB0',
  },
  '.delete-all-checkbox .chakra-checkbox__control[data-checked] ': {
    color: '#fff',
    background: 'transparent ',
    borderColor: '#fff !important',
  },
  '.formula-ul li:focus-visible': {
    backgroundColor: '#EFEEFE',
    outline: 'none',
  },
};
