import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';

import breakpoints from './breakpoints';

const { REACT_APP_DOMAIN_URL } = process.env;

class ApiServices {
  instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({ baseURL: REACT_APP_DOMAIN_URL });

    this.instance.interceptors.request.use(async (config) => {
      const token = localStorage.getItem('BLOX_PASSWORD_TOKEN');
      (config.headers as AxiosRequestHeaders).authorization = token as string;
      config.timeout = 60 * 4 * 1000;

      return config;
    });
  }

  fetch_user = () => this.instance.get(breakpoints.user());

  submitPassword = (data: any) => this.instance.put(breakpoints.set_password(), data);

  set_new_password = ({ request_body }: any) =>
    this.instance.post(breakpoints.set_new_password(), request_body);

  change_password = ({ request_body }: any) =>
    this.instance.post(breakpoints.change_password(), request_body);
}

export default new ApiServices();
