import { Box, Input, Flex, Text, List, ListItem } from '@chakra-ui/react';
import { useSelector, useDispatch } from 'react-redux';
import { useRef, useState, useEffect, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { CheckCircleIcon, InfoOutlineIcon } from '@chakra-ui/icons';

import {
  FetchBlockOutputs,
  getBaseScenario,
  getIndicators,
  getPlannerBlock,
  getSelectedIndicators,
  UpdateIndicatorFormula,
} from 'redux/PlannerModeSlice';
import FunctionIconComponent from 'components/Icons/FunctionIcon';

const ToolbarFormula = () => {
  const { blockId } = useParams();
  const inputReference: any = useRef(null);
  const dispatch: any = useDispatch();

  const indicators = useSelector(getIndicators);
  const baseScenario = useSelector(getBaseScenario);
  const plannerBlock = useSelector(getPlannerBlock);
  const selectedIndicators = useSelector(getSelectedIndicators);

  const [filterValue, setfilterValue]: any = useState(null);
  const [formula, setFormula] = useState(selectedIndicators?.formula);
  const [message, setMessage]: any = useState({ type: '', text: '', errorStatus: false });
  const [hover, setHover] = useState(false);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  useEffect(() => {
    setFormula(selectedIndicators?.formula);
  }, [selectedIndicators]);

  useEffect(() => {
    const shouldVisible = Boolean(filterValue);
    setIsOptionsVisible(shouldVisible);
  }, [filterValue, formula]);

  const fetchIndicatorsData = () => {
    const idArray = plannerBlock?.dimensions
      .filter((item: any) => item.name !== 'Time')
      .map((item: any) => item.id);
    const dimensionIds = idArray.join(', ');
    const payload = {
      blockId,
      params: {
        dim_id: dimensionIds,
        indicator_filter: 'all',
        scenario_id: baseScenario?.id,
      },
    };
    dispatch(FetchBlockOutputs(payload));
  };

  const handleFormulaChange = (event: any) => {
    if (message.type === 'success') {
      setMessage({ type: '', text: '', errorStatus: false });
    } else if (message.type === 'error') {
      setMessage({ type: 'error', text: message.text, errorStatus: false });
    }
    const value = event?.currentTarget?.value;
    setfilterValue(value ? value[0] : '');
    const lastTypedIndicator = value?.match(/\b(\w+)$/g);
    setFormula(value);
    setfilterValue(lastTypedIndicator ? lastTypedIndicator[0] : '');
  };

  const handleFormulaBlur = () => {
    if (message.type === 'success') {
      setMessage({ type: '', text: '', errorStatus: false });
    } else if (message.type === 'error') {
      setMessage({ type: 'error', text: message.text, errorStatus: false });
    }
  };

  const selectOption = useCallback(
    (option: string) => {
      if (inputReference?.current) {
        inputReference?.current?.focus();
      }
      const numberOfOptionWords = option.split(' ').length;
      const formulaArray = formula.split(' ');
      formulaArray.splice(-1, 1, option);
      if (numberOfOptionWords > 1) {
        const formulaString = formulaArray
          .map((element: any) => {
            if (element === option) {
              return `'${option}' `;
            }
            return element;
          })
          .join(' ');
        setFormula(formulaString);
      }
      if (numberOfOptionWords === 1) {
        const formulaString = formulaArray.join(' ');
        setFormula(formulaString);
      }
      setfilterValue('');
    },
    [formula],
  );

  const optionList = useMemo(() => {
    return indicators?.reduce((accumulator, option) => {
      if (option?.name?.toLowerCase()?.includes(filterValue?.toLowerCase())) {
        return [
          ...accumulator,
          <ListItem
            cursor={'pointer'}
            key={option?.id}
            color={'black'}
            onClick={() => selectOption(option?.name)}
          >
            <p>{option?.name}</p>
          </ListItem>,
        ];
      }
      return accumulator;
    }, []);
  }, [filterValue]);

  const handleFieldChanges = () => {
    dispatch(
      UpdateIndicatorFormula({
        indicatorId: selectedIndicators?.id,
        data: {
          formula,
        },
      }),
    ).then((response: any) => {
      if (response?.error) {
        fetchIndicatorsData();
        setMessage({ type: 'error', text: response?.payload, errorStatus: true });
      } else {
        fetchIndicatorsData();
        setMessage({ type: 'success', text: 'Formula parsed successfully', errorStatus: false });
      }
      setIsOptionsVisible(false);
    });
  };

  return (
    <Flex alignItems={'center'} width={'100%'}>
      <FunctionIconComponent
        color='white'
        style={{ margin: '0 10px 0 15px', height: 30, width: 30 }}
      />
      <Box
        alignItems={'center'}
        position={'relative'}
        width={'100%'}
        onBlur={handleFormulaBlur}
        mr={2}
      >
        <Input
          ref={inputReference}
          width={'100%'}
          value={formula || ''}
          onChange={handleFormulaChange}
          onKeyDown={(event) => {
            if (event?.keyCode === 13) handleFieldChanges();
          }}
          backgroundColor={'white'}
          color={'black'}
          borderRadius={'8px'}
          fontSize={'xs'}
          height={9}
          outline={
            message?.type === 'error'
              ? '2px red solid'
              : message?.type === 'success'
              ? '2px green solid'
              : 'none'
          }
        />
        {isOptionsVisible && (
          <List
            py={2}
            px={5}
            borderRadius={'8px'}
            position='absolute'
            width={'400px'}
            zIndex='3'
            boxShadow='md'
            mt={1}
            bg='white'
            spacing={1}
          >
            <Text color={'#796cd6'}>CURRENT BLOCK</Text>
            {optionList}
          </List>
        )}
      </Box>
      {(message?.type === 'error' || message?.type === 'success') && (
        <Box
          ml={4}
          maxW={'max-content'}
          color={message.type === 'success' ? '#4bb6b9' : 'red'}
          fontSize={'2xs'}
          position={'relative'}
        >
          {message?.type === 'error' && (
            <InfoOutlineIcon
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              height={6}
              width={6}
            />
          )}
          {message?.type === 'success' && (
            <CheckCircleIcon height={6} width={6} color={'#4bb6b9'} />
          )}
          {((message?.type === 'error' && message?.errorStatus) || hover) && (
            <Flex
              width={'200px'}
              maxWidth={'200px'}
              backgroundColor={'#ecd3d3'}
              color={'red'}
              position={'absolute'}
              bottom={'30px'}
              padding={'.2rem .5rem'}
              borderRadius={'2px'}
              left={'50%'}
              transform={'translateX(-50%)'}
            >
              {message.text}
            </Flex>
          )}
        </Box>
      )}
    </Flex>
  );
};

export default ToolbarFormula;
