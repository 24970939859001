import { Box, Checkbox as ChackraCheckbox, StyleProps } from '@chakra-ui/react';
import { ChangeEvent, ReactNode } from 'react';

interface Properties {
  isChecked?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  label: string | ReactNode;
  size?: 'sm' | 'md' | 'lg';
  wrapperProps?: StyleProps;
  isRound?: boolean;
  variant?: string;
  styles?: any;
}

const Checkbox = ({
  isChecked,
  onChange,
  label,
  size = 'md',
  wrapperProps,
  isRound = false,
  variant,
  styles,
}: Properties) => {
  return (
    <>
      {isRound ? (
        <ChackraCheckbox
          variant={variant}
          isChecked={isChecked}
          onChange={onChange}
          size={size}
          style={styles}
          colorScheme='transparent'
          sx={{
            '& .chakra-checkbox__control': {
              borderRadius: 'full',
              borderColor: 'grey.900',
              bgColor: 'transparent',
            },
          }}
          icon={<Box boxSize='1.5' borderRadius='full' bgColor='grey.900' />}
        >
          {label}
        </ChackraCheckbox>
      ) : (
        <ChackraCheckbox
          variant={variant}
          style={styles}
          isChecked={isChecked}
          onChange={onChange}
          size={size}
          {...wrapperProps}
        >
          {label}
        </ChackraCheckbox>
      )}
    </>
  );
};

export default Checkbox;
