import { useMediaQuery } from '@chakra-ui/react';

const DataSet = ({ color }: any) => {
  const [isSmallDevice] = useMediaQuery('(max-width: 900px)');
  return (
    <>
      <svg
        width={isSmallDevice ? '20' : '29'}
        height={isSmallDevice ? '20' : '29'}
        viewBox='0 0 29 29'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <mask
          id='mask0_1510_3377'
          style={{ maskType: 'alpha' }}
          maskUnits='userSpaceOnUse'
          x='0'
          y='0'
          width='29'
          height='29'
        >
          <rect x='0.761719' y='0.998543' width='27.3008' height='27.3008' fill='#D9D9D9' />
        </mask>
        <g mask='url(#mask0_1510_3377)'>
          <path
            d='M6.45085 23.7491C5.82521 23.7491 5.28962 23.5263 4.84408 23.0808C4.39855 22.6352 4.17578 22.0996 4.17578 21.474V5.54851C4.17578 4.92287 4.39855 4.38728 4.84408 3.94175C5.28962 3.49621 5.82521 3.27345 6.45085 3.27345H22.3763C23.002 3.27345 23.5376 3.49621 23.9831 3.94175C24.4286 4.38728 24.6514 4.92287 24.6514 5.54851V13.625C24.4618 13.5871 24.277 13.5587 24.0969 13.5397C23.9167 13.5207 23.7224 13.5113 23.5139 13.5113H22.3763V5.54851H6.45085V21.474H9.97721C10.053 21.8911 10.1526 22.2892 10.2758 22.6684C10.399 23.0476 10.5649 23.4078 10.7735 23.7491H6.45085ZM8.72592 19.1989H9.97721C10.1289 18.2699 10.4607 17.4121 10.9726 16.6253C11.4844 15.8385 12.1385 15.1796 12.9348 14.6488H8.72592V19.1989ZM8.72592 12.3737H13.2761V7.82358H8.72592V12.3737ZM16.6887 24.8866C15.4374 24.8866 14.3662 24.4411 13.4751 23.55C12.5841 22.6589 12.1385 21.5878 12.1385 20.3365C12.1385 19.0852 12.5841 18.014 13.4751 17.1229C14.3662 16.2319 15.4374 15.7863 16.6887 15.7863H18.9637V18.0614H16.6887C16.063 18.0614 15.5274 18.2842 15.0819 18.7297C14.6364 19.1752 14.4136 19.7108 14.4136 20.3365C14.4136 20.9621 14.6364 21.4977 15.0819 21.9432C15.5274 22.3888 16.063 22.6115 16.6887 22.6115H18.9637V24.8866H16.6887ZM15.5511 12.3737H20.1013V7.82358H15.5511V12.3737ZM16.6887 21.474V19.1989H23.5139V21.474H16.6887ZM21.2388 24.8866V22.6115H23.5139C24.1395 22.6115 24.6751 22.3888 25.1206 21.9432C25.5662 21.4977 25.7889 20.9621 25.7889 20.3365C25.7889 19.7108 25.5662 19.1752 25.1206 18.7297C24.6751 18.2842 24.1395 18.0614 23.5139 18.0614H21.2388V15.7863H23.5139C24.7652 15.7863 25.8363 16.2271 26.7274 17.1087C27.6185 17.9903 28.064 19.0662 28.064 20.3365C28.064 21.5878 27.6185 22.6589 26.7274 23.55C25.8363 24.4411 24.7652 24.8866 23.5139 24.8866H21.2388Z'
            fill={color}
          />
        </g>
      </svg>
    </>
  );
};

export default DataSet;
