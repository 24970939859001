interface Properties {
  width?: number;
  height?: number;
  color?: string;
  style?: React.CSSProperties;
}

const InputIconComponent = ({
  width = 20,
  height = 20,
  color = 'green.900',
  style,
}: Properties) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox='0 0 23 22'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.6989 0.816895C9.94884 0.816895 6.75818 2.06647 5.49209 3.81534L11.2658 9.94045C12.0688 10.0769 12.8825 10.1478 13.6963 10.1505C18.5572 10.1505 22.4946 8.06335 22.4946 5.48508C22.4946 2.9068 18.5599 0.816895 13.6989 0.816895ZM4.8122 5.57784L3.249 7.23394L5.99799 10.1505H0.5V12.4833H6.00067L3.25167 15.3998L4.81488 17.0559L10.2272 11.3155L4.81488 5.57511L4.8122 5.57784ZM22.5 7.8178C22.5 10.3961 18.5625 12.4833 13.7016 12.4833C12.9976 12.4833 12.2963 12.4314 11.6004 12.3305L8.8835 15.2116C10.4414 15.7327 12.0661 15.9919 13.7016 15.981C18.5625 15.981 22.5 13.8938 22.5 11.3155V7.81507V7.8178ZM22.5 13.651C22.5 16.2293 18.5625 18.3164 13.7016 18.3164C11.5013 18.3574 9.32248 17.8581 7.33368 16.8595L5.48674 18.8185C6.75283 20.5673 9.95152 21.8169 13.7043 21.8169C18.5652 21.8169 22.5027 19.7297 22.5027 17.1514'
        fill={color}
      />
    </svg>
  );
};

export default InputIconComponent;
