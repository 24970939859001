import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

import { InputAdjutment } from 'models/requestBody/getModelOutputs.interface';

interface Properties {
  children: ReactNode;
}

interface DriversAdjustmentContextProperties {
  inputAdjustments: InputAdjutment;
  onAdjustInput: (parameter: InputAdjutment | {}) => void;
}

export const DriversAdjustmentContext = createContext<DriversAdjustmentContextProperties>({
  inputAdjustments: {},
  onAdjustInput: () => {},
});

export const useDriversAdjustmentContext = () => {
  const context = useContext(DriversAdjustmentContext);
  if (!context) {
    throw new Error('useDriversAdjustmentContext must be used within a DriversAdjustmentContext');
  }
  return context;
};

export const DriversAdjustmentProvider = ({ children }: Properties) => {
  const [inputAdjustments, setInputAdjustments] = useState({});

  const onAdjustInput = useCallback((parameter: InputAdjutment | {}) => {
    if (Object.keys(parameter).length === 0) {
      setInputAdjustments({});
    }
    setInputAdjustments((previousState) => ({ ...previousState, ...parameter }));
  }, []);

  return (
    <DriversAdjustmentContext.Provider
      value={{
        inputAdjustments,
        onAdjustInput,
      }}
    >
      {children}
    </DriversAdjustmentContext.Provider>
  );
};
