import './beat.css';

const BeatLoader = () => {
  return (
    <div className='chat-bubble'>
      <div className='typing'>
        <div className='dot'></div>
        <div className='dot'></div>
        <div className='dot'></div>
      </div>
    </div>
  );
};

export default BeatLoader;
