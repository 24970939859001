import { useQuery } from '@tanstack/react-query';
import { createContext, ReactNode, useCallback, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import useOnLocationChange from 'hooks/useOnLocationChange';
import User from 'models/response/user.interface';
import apiService from 'services';
import VerifyToken from 'utils/VerifyToken';
import setTokenWithExpiry from 'utils/setTokenWithExpiry';
import {
  FetchSubscriptionFeatureLimits,
  FetchUserDetails,
  FetchUserInvitations,
} from 'redux/UserSlice';

interface Properties {
  children: ReactNode;
  getSubscriptionFeatureLimit: () => Promise<void>;
  getUserInvitation: () => Promise<void>;
  getUserDetail: () => Promise<void>;
}
declare global {
  interface Window {
    heap: any;
  }
}
interface AuthContextProperties {
  isAuth: boolean;
  onLogout: () => void;
  onAuthSuccess: (token: string, expiry: string) => void;
  user: User | null;
  userRoles: any;
}

export const AuthContext = createContext<AuthContextProperties>({
  isAuth: false,
  onLogout: () => {},
  onAuthSuccess: () => {},
  user: null,
  userRoles: {},
});

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return context;
};

const AuthProvider = ({
  children,
  getUserDetail,
  getUserInvitation,
  getSubscriptionFeatureLimit,
}: Properties) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useOnLocationChange(VerifyToken);
  const { data: userData } = useQuery(['user'], () => apiService.fetch_user(), {
    enabled:
      pathname !== '/' &&
      pathname !== '/signup' &&
      !pathname.includes('/invitation') &&
      pathname !== '/set-password' &&
      pathname !== '/set-new-password' &&
      pathname !== '/user/welcome' &&
      pathname !== '/change-password' &&
      !pathname.includes('/share-model'),
  });

  useEffect(() => {
    if (userData?.data.user) {
      getUserDetail();
      getSubscriptionFeatureLimit();
      getUserInvitation();
      window.heap.identify(userData?.data.user.id);
      window.heap.addUserProperties({
        name: `${userData?.data.user.firstname} ${userData?.data.user.lastname}`,
        email: userData?.data.user.username,
        job_role: userData?.data.user.job_role,
        industry: userData?.data.user.industry,
      });
    }
  }, [userData]);

  const onLogout = useCallback(() => {
    localStorage.removeItem('BLOX_USER_TOKEN');
    localStorage.removeItem('BLOX_PASSWORD_TOKEN');
    localStorage.removeItem('BLOX_USER_TOKEN_EXPIRY');
    localStorage.removeItem('model-token');
    localStorage.removeItem('BUILDER_MODE');
    navigate('/');
    window.location.reload();
  }, [navigate]);

  const onAuthSuccess = useCallback((token: string, tokenExpiryTime: string) => {
    setTokenWithExpiry(token, tokenExpiryTime);
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuth: isAuthenticated,
        onLogout,
        onAuthSuccess,
        user: userData?.data.user,
        userRoles: userData?.data?.user?.role,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const stateToProperty = (state: any) => {
  return {
    userData: state.userSlice.userData,
  };
};

const mapDispatchToProperties = (dispatch: any) => {
  return {
    getSubscriptionFeatureLimit: () => dispatch(FetchSubscriptionFeatureLimits()),
    getUserInvitation: () => dispatch(FetchUserInvitations()),
    getUserDetail: () => dispatch(FetchUserDetails({})),
  };
};

export default connect(stateToProperty, mapDispatchToProperties)(AuthProvider);
