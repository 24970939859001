interface Properties {
  width?: number;
  height?: number;
  color?: string;
  style?: React.CSSProperties;
}

const VisibleIconComponent = ({
  width = 20,
  height = 20,
  color = '#787878',
  style,
}: Properties) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      fill={color}
      viewBox='0 0 25 17'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.4667 0C6.8 0 1.96067 3.52467 0 8.5C1.96067 13.4753 6.8 17 12.4667 17C18.1333 17 22.9727 13.4753 24.9333 8.5C22.9727 3.52467 18.1333 0 12.4667 0ZM12.4667 14.1667C9.33867 14.1667 6.8 11.628 6.8 8.5C6.8 5.372 9.33867 2.83333 12.4667 2.83333C15.5947 2.83333 18.1333 5.372 18.1333 8.5C18.1333 11.628 15.5947 14.1667 12.4667 14.1667ZM12.4667 5.1C10.5853 5.1 9.06667 6.61867 9.06667 8.5C9.06667 10.3813 10.5853 11.9 12.4667 11.9C14.348 11.9 15.8667 10.3813 15.8667 8.5C15.8667 6.61867 14.348 5.1 12.4667 5.1Z'
        fill={color}
      />
    </svg>
  );
};

export default VisibleIconComponent;
