import { Box, Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ArrowLeftBlocks from 'components/Icons/ArrowLeftBlocks';
import Cross from 'components/Icons/Cross';
import InputField from 'components/InputField';
import {
  DeleteGroupDimName,
  DeleteGroupItemName,
  UpdateGroupDimName,
  UpdateGroupItemName,
  fetchDimensionItemsGroup,
  getDataIndicatorStatus,
  getDataIndicators,
  getDataIndicatorsKeys,
  setDataKey,
  setDisplayInfo,
  setStepNumber,
} from 'redux/ItemPageSlice';
import { closeInputSection, getSelectedDriver } from 'redux/PlanPageSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';
import { Scenario } from 'models/response/scenario.interface';
import FullScreenLoader from 'components/FullScreenLoader';
import { STATUS } from 'projectConstants';
import { AppDispatch } from 'utils/GlobalHelpers';

import DeleteGroupModal from '../../../../../components/DeleteGroupModal';
import ArrowRight from '../../../../../components/Icons/ArrowRight';
import Plus from '../../../../../components/Icons/Plus';
import infoIcon from '../../Icons/infoIcon.svg';

interface StepOneProperties {
  longClickCheck: any;
  isLongClicked: boolean;
  planPermission?: boolean;
}

const StepOne = ({ longClickCheck, isLongClicked, planPermission }: StepOneProperties) => {
  const dispatch: AppDispatch = useDispatch();
  const currentDriver = useSelector(getSelectedDriver);
  const dataIndicatorsKeys = useSelector(getDataIndicatorsKeys);
  const dataIndicatorStatus = useSelector(getDataIndicatorStatus);
  const dataIndicators = useSelector(getDataIndicators);
  const [renameGroup, setRenameGroup]: any = useState(null);
  const [renameGroupValue, setRenameGroupValue] = useState<string>('');
  const [displayGroupInfo, setDisplayGroupInfo]: any = useState(null);
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [DeleteIdModal, setDeleteIdModal]: any = useState(null);
  const [DeleteTypeModal, setDeleteTypeModal]: any = useState('');
  const baseScenario: Scenario = useSelector(getBaseScenario);
  const [DeleteNameModal, setDeleteNameModal] = useState('');

  const closeSection = () => {
    dispatch(closeInputSection());
  };

  const toggleInfoPopup = (event: any, id: number | null) => {
    event.stopPropagation();
    if (displayGroupInfo === id) {
      return setDisplayGroupInfo(null);
    }
    return setDisplayGroupInfo(id);
  };
  const renameItem = async (event: any, id: number) => {
    event.stopPropagation();
    await setDisplayGroupInfo(null);
    if (renameGroup === id) {
      return setRenameGroup(0);
    }
    return setRenameGroup(id);
  };
  const deleteDimItem = async (type: string, id: Number) => {
    if (type === 'dimension') {
      await dispatch(DeleteGroupDimName(id));
      await dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));
      await setIsDeleteModal(false);
      await setDeleteIdModal(null);
      await setDeleteTypeModal('');
      await setDeleteNameModal('');
    }
    if (type === 'item') {
      await dispatch(
        DeleteGroupItemName({ ItemId: id, parameters: { scenario_id: baseScenario?.id } }),
      );
      await dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));
      await setIsDeleteModal(false);
      await setDeleteIdModal(null);
      await setDeleteTypeModal('');
      await setDeleteNameModal('');
    }
  };

  const UpdateItemApi = async (item_id: Number, type: string) => {
    if (renameGroupValue && renameGroupValue?.trim()) {
      const requestBody = { name: renameGroupValue };
      if (type === 'item') {
        await dispatch(
          UpdateGroupItemName({
            item_id,
            requestBody,
            toaster: {
              successMessage: 'Successfully Updated',
              errorMessage: 'An item with this name already exists',
            },
          }),
        );
        await dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));

        await setRenameGroup(null);
      }
      if (type === 'dimension') {
        await dispatch(
          UpdateGroupDimName({
            item_id,
            requestBody,
            toaster: {
              successMessage: 'Successfully Updated',
              errorMessage: 'An item with this name already exists',
            },
          }),
        );
        await dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));

        setRenameGroup(null);
      }
    }
  };

  const onKeyPressAction = async (event: any, item_id: number, type: string) => {
    if (event.keyCode === 13) {
      await setRenameGroup(item_id);
      await UpdateItemApi(item_id, type);
    }
    if (event.code === 'Escape') await setRenameGroup(0);
  };

  const onOpenDeleteModal = (event: any, name: any, id: any, type: any) => {
    event.stopPropagation();
    setIsDeleteModal(true);
    setDeleteIdModal(id);
    setDeleteTypeModal(type);
    setDeleteNameModal(name);
  };
  const onDeleteModel = async () => {
    await deleteDimItem(DeleteTypeModal, DeleteIdModal);
  };

  if (dataIndicatorStatus === STATUS.IDLE) {
    return <FullScreenLoader bgColor='transparent' />;
  }
  return (
    <Flex
      width={'100%'}
      height={'100%'}
      alignItems={'flex-start'}
      flexDirection={'column'}
      onBlur={() => setDisplayGroupInfo(null)}
      paddingBottom={'3rem'}
    >
      <Flex justifyContent='space-between' alignItems='center' mb='40px' width={'100%'}>
        <Flex alignItems='center' cursor='pointer'>
          <Box onClick={closeSection} mr='12px' paddingBottom='6px'>
            <ArrowLeftBlocks />
          </Box>
          <Text fontSize='18px'>Groups</Text>
        </Flex>
        <Cross height={'25px'} width={'25px'} onClick={closeSection} />
      </Flex>
      {dataIndicatorsKeys &&
        dataIndicatorsKeys?.map((currentDataKey: any, index: number) => {
          return (
            <Flex
              key={index}
              textAlign='left'
              alignSelf='left'
              w='100%'
              minH='60px'
              bgColor='#7163D0'
              mb='6px'
              p='0 14px'
              flexDirection='column'
              borderRadius='14px'
              filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))'
              transition='all 0.3s ease-out'
              justifyContent='center'
              cursor='pointer'
              position='relative'
              zIndex={displayGroupInfo === currentDataKey?.dim_id ? '350' : '0'}
            >
              <Box position='relative'>
                <Flex
                  justifyContent='space-between'
                  minH='50px'
                  alignItems='center'
                  {...longClickCheck()}
                >
                  {!planPermission && renameGroup === currentDataKey?.dim_id ? (
                    <Box>
                      <InputField
                        width={'fit-content'}
                        outline={'none !important'}
                        boxShadow={'none !important'}
                        border={'1px white solid !important'}
                        padding={'15px 5px 15px 10px'}
                        height={'28px'}
                        fontSize={'1xl !important'}
                        fontFamily={'Roboto Bold'}
                        defaultValue={currentDataKey?.name}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          setRenameGroupValue(event.target.value);
                        }}
                        clickedOutside={() => {
                          setDisplayGroupInfo(null);
                          setRenameGroup(0);
                        }}
                        onKeyDown={(event: React.ChangeEvent<HTMLInputElement>) => {
                          onKeyPressAction(event, currentDataKey?.dim_id, currentDataKey?.type);
                        }}
                        _placeholder={{ color: '#ffffff66' }}
                        autoFocus
                      />
                    </Box>
                  ) : (
                    <Box
                      width={'100%'}
                      height={'60px'}
                      display={'flex'}
                      alignItems={'center'}
                      onDoubleClick={(event) => {
                        event.stopPropagation();
                        setRenameGroup(currentDataKey?.dim_id);
                      }}
                      onClick={() => {
                        setDisplayGroupInfo(null);
                        dispatch(setStepNumber(1));
                        dispatch(setDataKey(currentDataKey));
                        dispatch(setDisplayInfo(null));
                      }}
                    >
                      <Tooltip label={currentDataKey?.name}>
                        <Text
                          fontFamily='Roboto Regular'
                          fontSize='1xl'
                          lineHeight='9'
                          width={'100%'}
                          height={'fit-content'}
                          cursor='pointer'
                          noOfLines={isLongClicked ? 0 : 1}
                        >
                          {currentDataKey?.name}
                        </Text>
                      </Tooltip>
                    </Box>
                  )}

                  <Flex height='60px'>
                    <Flex alignItems='center' onBlur={() => setDisplayGroupInfo(null)}>
                      <Image
                        src={infoIcon}
                        w='28px'
                        height='8px'
                        mr='20px'
                        cursor={planPermission ? 'not-allowed' : 'pointer'}
                        onClick={
                          planPermission
                            ? undefined
                            : (event) => toggleInfoPopup(event, currentDataKey?.dim_id)
                        }
                      />
                      <Box
                        bgColor='white'
                        width='fit-content'
                        position='absolute'
                        display={displayGroupInfo === currentDataKey?.dim_id ? 'block' : 'none'}
                        zIndex={2000}
                        top='46px'
                        p='5px 0'
                        filter='drop-shadow(0px 0px 15.998px rgba(0, 0, 0, 0.25))'
                        borderRadius='4px'
                        right='1px !important'
                      >
                        <Box
                          p='5px 15px'
                          _hover={{
                            background: '#EEF2F6',
                            color: 'black',
                          }}
                        >
                          <Text
                            color='black'
                            onClick={
                              planPermission
                                ? undefined
                                : (event) => {
                                    renameItem(event, currentDataKey?.dim_id);
                                  }
                            }
                          >
                            Rename
                          </Text>
                        </Box>
                        <Box
                          p='5px 15px'
                          _hover={{
                            background: '#EEF2F6',
                            color: 'black',
                          }}
                        >
                          <Text
                            color='black'
                            onClick={
                              planPermission
                                ? (event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                  }
                                : (event) => {
                                    event.stopPropagation();
                                    event.preventDefault();
                                    onOpenDeleteModal(
                                      event,
                                      currentDataKey?.name,
                                      currentDataKey?.dim_id,
                                      currentDataKey?.type,
                                    );
                                  }
                            }
                          >
                            Delete
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        onClick={() => {
                          setDisplayGroupInfo(null);
                          dispatch(setStepNumber(1));
                          dispatch(setDataKey(currentDataKey));
                          dispatch(setDisplayInfo(null));
                        }}
                      >
                        <ArrowRight color='#FFFFFF' />
                      </Box>
                    </Flex>

                    <Flex marginLeft={1}>
                      {dataIndicators?.[currentDataKey] &&
                        (dataIndicators[currentDataKey]?.length === 0 ? (
                          <>
                            <Tooltip label={currentDriver?.dim_name}>
                              <Text mr='4px' noOfLines={1} maxWidth='100px'>
                                Add {currentDriver?.dim_name}
                              </Text>
                            </Tooltip>
                            <Plus height={5} width={5} />
                          </>
                        ) : (
                          <Box>
                            <ArrowRight height={5} width={5} color='#FFFFFF' />
                          </Box>
                        ))}
                    </Flex>
                  </Flex>
                </Flex>
              </Box>
            </Flex>
          );
        })}
      <DeleteGroupModal
        isOpen={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
        itemName={DeleteNameModal}
        onDelete={onDeleteModel}
      />
    </Flex>
  );
};

export default StepOne;
