import { Chart as ChartJS, registerables } from 'chart.js';
import { useEffect, useState } from 'react';
import { Chart as ReactChart } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { FormatProperties } from 'models/response/modelOutput.interface';
import { CHART_COLORS } from 'projectConstants';
import { planPageShow } from 'redux/PlanPageSlice';
import getChartOptions from 'utils/getChartOptions';

ChartJS.register(...registerables);

interface Properties {
  chartLabelData: { [x: string]: number };
  chartData: Record<string, Record<string, number>> | [];
  chartType: string;
  formatProperties: FormatProperties;
  showLabel?: boolean;
  dimId?: number | string;
}

const Chart = ({
  chartLabelData,
  chartData,
  chartType,
  formatProperties,
  showLabel,
}: Properties) => {
  const showPlanPage = useSelector(planPageShow);
  const [data, setData] = useState<any>();

  const legendMargin = {
    id: 'legendMargin',
    beforeInit(chart: any) {
      const originalFit = chart.legend.fit;

      chart.legend.fit = function fit() {
        originalFit.bind(chart.legend)();
        this.height += 25;
      };
    },
  };

  const chartComponentReadyData = () => {
    if (Object.values(chartData)[0] !== undefined) {
      const datasets = Object.entries(chartData).map((entry: any, index) => {
        const data1 = Object.values(entry[1]);
        const allZeros = data1.every((value) => value === 0);
        const trimmedData = allZeros ? [...data1] : [...data1];
        if (!allZeros) {
          while (trimmedData[trimmedData.length - 1] === 0 && trimmedData.length > 1) {
            trimmedData.pop();
          }
        }

        return {
          key: index,
          label: entry[0],
          data: trimmedData,
          backgroundColor: CHART_COLORS[index],
          borderColor: CHART_COLORS[index],
        };
      });

      const newdata = {
        labels: Object.keys(chartLabelData),
        datasets,
      };
      setData(newdata);
    }
  };

  useEffect(() => {
    chartComponentReadyData();
  }, [chartData]);

  return (
    <>
      {data && (
        <ReactChart
          type={chartType === 'line' ? 'line' : 'bar'}
          data={data}
          options={getChartOptions({
            chartType,
            isLegend: !showLabel,
            formatProperties,
            pageName: 'drivers',
            aspectRatio: showPlanPage ? 1.5 : 5,
          })}
          plugins={[legendMargin]}
        />
      )}
    </>
  );
};

export default Chart;
