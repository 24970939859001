import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  useMediaQuery,
  useToast,
  PopoverCloseButton,
  Flex,
  Divider,
  VStack,
  useOutsideClick,
  useBoolean,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import React, { useState, useEffect } from 'react';
import { AiOutlineLink } from 'react-icons/ai';
import { FaRegCheckCircle } from 'react-icons/fa';
import { MdClear, MdError } from 'react-icons/md';
import { useMatch, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { EmailIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';

import apiService from 'services';
import colors from 'styles/theme/colors';
import sizes from 'styles/theme/sizes';
import ShareImg from 'assets/share_icon.png';
import AuthModalContainer from 'components/AuthModal/AuthModalContainer';
import { getSharedModelUsers, getModelUsers } from 'redux/HeaderSlice';
import { getUserData, getUserSubscriptionLimits } from 'redux/UserSlice';
import ShareModalPermission from 'pages/PermissionComponent/ShareModalPermission';
import { AppDispatch } from 'utils/GlobalHelpers';

import blockImage from '../../assets/blox_logo.png';

import UserDetails from './UserDetails';

const emailSchema = yup.object().shape({
  email: yup.string().email().min(1),
});

const headingTextStyle = {
  color: '#000',
  fontFamily: 'regular',
  fontSize: '21.039px',
  fontWeight: 400,
};

const textStyle = {
  fontFamily: 'regular_roboto',
  fontWeight: 400,
};

const backdropStyle: React.CSSProperties = {
  backdropFilter: 'blur(5px)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 20,
  cursor: 'pointer',
};

const ShareButtonWithPopup = () => {
  const dispatch: AppDispatch = useDispatch();

  const [upgrade, setUpgrade] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isEmailAddressValid, setIsEmailAddressValid] = useState(true);

  const toast = useToast();
  const navigate = useNavigate();
  const user = useSelector(getUserData);
  // const { user } = useAuthContext();
  const [isMobile] = useMediaQuery('(max-width: 575px)');
  const [popoverSeleceted, setPopoverSeleceted] = useBoolean();
  const match = useMatch({ path: 'model/:modelId/', end: false });
  const reference = React.useRef() as React.MutableRefObject<HTMLInputElement>;

  const modelId = match?.params?.modelId;

  const shareModelUsers = useSelector(getSharedModelUsers);
  const userSubscriptionLimitData = useSelector(getUserSubscriptionLimits);

  const modelOwner = shareModelUsers.find((share: any) => share.role === 'owner');

  const isShareModelAccess = shareModelUsers.find((share: any) => {
    return share?.user_id === user?.id;
  });

  useEffect(() => {
    setShowBackdrop(popoverSeleceted);
  }, [popoverSeleceted]);

  useEffect(() => {
    if (modelId) {
      dispatch(getModelUsers(Number(modelId)));
    }
  }, [modelId]);

  useOutsideClick({
    ref: reference,
    handler: () => {
      if (upgrade === false) setPopoverSeleceted.off();
    },
  });

  const { refetch: copyModelLink } = useQuery(
    ['modelLink', modelId],
    () => apiService.get_model_link(Number(modelId)),
    {
      enabled: false,
      onError: () => navigate('/error'),
    },
  );

  const { refetch: sendInvite } = useQuery(
    ['modalLinkInvite', modelId],
    () =>
      apiService.create_model_share({
        model_id: Number(modelId),
        request_body: {
          email_id: emailAddress,
        },
      }),
    {
      enabled: false,
      retry: false,
      onError: () => navigate('/error'),
    },
  );

  const emailChangeHandler = (event_: React.ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(event_.target.value.trim());
    setIsEmailAddressValid(true);
  };

  const showToast = (type: 'SUCCESS' | 'ERROR', message: string, duration = 1250) => {
    toast({
      duration,
      isClosable: false,
      render: () => (
        <Box
          position='relative'
          bg={type === 'SUCCESS' ? colors.green[800] : colors.red[500]}
          p={sizes[2]}
          pl={sizes[3]}
          pr={sizes[3]}
          borderRadius={sizes['2']}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          columnGap={sizes[2]}
        >
          {type === 'SUCCESS' ? (
            <FaRegCheckCircle fontSize='x-large' />
          ) : (
            <MdError fontSize='x-large' />
          )}
          <Text fontSize='sm' mr={'auto'}>
            {message}
          </Text>
          <Box position='absolute' top={sizes[1]} right={sizes['1']}>
            <MdClear fontSize='large' />
          </Box>
        </Box>
      ),
    });
  };

  const sendInviteHandler = async () => {
    const isEmailValid = emailSchema.isValidSync({ email: emailAddress });

    setIsEmailAddressValid(isEmailValid);

    if (isEmailValid && emailAddress && modelId) {
      setPopoverSeleceted.off();
      sendInvite().then(() => {
        showToast('SUCCESS', 'Email Sent Successfully');
        setEmailAddress('');
      });
    }
  };

  const copyLinkHandler = async () => {
    copyModelLink().then(async ({ data }) => {
      const link = data?.data?.link;
      let status: 'COPYING' | 'COPIED' | 'FAILED' = 'COPYING';

      try {
        navigator.clipboard.writeText(data?.data?.link);
        status = 'COPIED';
      } catch {
        const textArea = document.createElement('textarea');
        textArea.value = link;

        document.body.append(textArea);

        textArea.select();
        textArea.focus();

        try {
          document.execCommand('copy');
          status = 'COPIED';
        } catch {
          status = 'FAILED';
        } finally {
          textArea.remove();
        }
      }

      showToast(
        status === 'COPIED' ? 'SUCCESS' : 'ERROR',
        status === 'COPIED' ? 'Link Copied' : 'Something Went Wrong',
      );

      setPopoverSeleceted.off();
    });
  };

  const onToggleAuthModal = () => {
    setIsAuthModalOpen((previousState) => !previousState);
  };

  const handleShareClick = (event: React.MouseEvent) => {
    if (user?.is_anonymous) {
      setIsAuthModalOpen(true);
      event.stopPropagation();
    } else if (modelId) {
      dispatch(getModelUsers(Number(modelId)));
    }
  };

  return (
    <>
      <Popover
        isLazy
        placement='bottom-end'
        gutter={16}
        isOpen={popoverSeleceted}
        onOpen={user?.is_anonymous ? undefined : setPopoverSeleceted.on}
        onClose={setPopoverSeleceted.off}
        closeOnBlur={false}
      >
        <PopoverTrigger>
          <Button
            variant='clear'
            size=''
            fontSize='2xs'
            textTransform='uppercase'
            color='black.900'
            padding={'0 .5rem'}
            _hover={{ bgColor: 'green.900', color: 'white' }}
            onClick={(event) => handleShareClick(event)}
          >
            Share
            <Image ml={2} h={6} w={6} src={ShareImg} />
          </Button>
        </PopoverTrigger>
        <Portal>
          {showBackdrop && (
            <div
              style={backdropStyle}
              onClick={(event: any) => {
                event.stopPropagation();
              }}
            ></div>
          )}
          <PopoverContent
            w={{ base: '80', sm: '30rem' }}
            borderWidth='1'
            borderRadius='sm'
            borderColor='grey.800'
            bgColor='white.900'
            boxShadow='0px 4px 4px rgba(0, 0, 0, 0.25)'
            position={{ base: 'absolute', sm: 'relative' }}
            marginLeft={{ base: 'auto' }}
            right={{ base: '2', sm: '2' }}
            p={{ base: sizes['4'] }}
            pt={{ base: sizes['6'] }}
            pb={{ base: sizes['6'] }}
            color={'#000'}
            ref={reference}
          >
            <PopoverCloseButton />
            <PopoverHeader
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              columnGap={sizes['2']}
              border='none'
              pb={{ base: 0 }}
              pt={0}
              px={0}
            >
              <Text {...headingTextStyle} fontSize={'xs'}>
                Share your model
              </Text>
            </PopoverHeader>
            <PopoverBody display='flex' flexDir='column' px='0' py='2' alignItems='flex-start'>
              <Flex
                align={'center'}
                bgColor={'rgba(101, 97, 169, 0.19)'}
                borderRadius={'7px'}
                w={'100%'}
                gap={3}
                py={2}
              >
                <EmailIcon color={'#6561A9'} h={isMobile ? 4 : 6} w={isMobile ? 4 : 6} ms={2} />
                <Text {...textStyle} color='#6561A9' fontSize={isMobile ? '3xs' : 'xs'}>
                  Invite via email:
                </Text>
                <InputGroup size='sm' w={isMobile ? '55%' : '65%'}>
                  <Input
                    mr={{ base: 0, sm: sizes['20'] }}
                    type='email'
                    borderRadius={sizes[2]}
                    color={colors.black[900]}
                    onChange={emailChangeHandler}
                    value={emailAddress}
                    required
                    pattern='\S(.*\S)?'
                    bg={'white'}
                    w={isMobile ? '70%' : 'fill-available'}
                    isDisabled={isShareModelAccess?.role === 'view'}
                  />
                  <InputRightElement width='fit-content'>
                    <Button
                      h='full'
                      size='sm'
                      w={{ base: 'fit-content', sm: sizes[18] }}
                      fontWeight='normal'
                      fontSize={isMobile ? '3xs' : 'xs'}
                      textTransform='none'
                      borderRadius={sizes[2]}
                      bg='#6561A9'
                      pl={{ base: sizes['2'], sm: sizes['4'] }}
                      pr={{ base: sizes['2'], sm: sizes['4'] }}
                      py={1}
                      onClick={isShareModelAccess?.role === 'view' ? undefined : sendInviteHandler}
                      borderColor={'none'}
                      cursor={isShareModelAccess?.role === 'view' ? 'not-allowed' : 'pointer'}
                    >
                      Invite
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Flex>
              {!isEmailAddressValid && (
                <Text color={'red'} fontSize={'2xs'}>
                  Please enter a valid email address to share your model
                </Text>
              )}
              <Button
                fontWeight='normal'
                fontSize={isMobile ? '3xs' : 'xs'}
                textTransform='none'
                borderRadius={sizes[2]}
                onClick={copyLinkHandler}
                color={'#6561A9'}
                py={1}
                border={'1px solid #6561A9'}
                w={'fit-content'}
                height={'35px'}
                mt={sizes[!isEmailAddressValid ? 2 : 4]}
                mb={sizes[!isEmailAddressValid ? 2 : 4]}
                px={2}
                isDisabled={isShareModelAccess?.role === 'view'}
              >
                <AiOutlineLink
                  color={'#6561A9'}
                  style={{ marginRight: '7px', height: '20px', width: '20px' }}
                />
                Invite via link
              </Button>
              <Divider orientation='horizontal' borderWidth='1px' color={'#D9D9D9'} />
              <VStack w={'full'} display={'flex'} align={'baseline'} py={3}>
                {userSubscriptionLimitData?.subscription_features_limit?.sharing === 'execute' ? (
                  <Flex h={'30px'} justify={'start'} align={'center'}>
                    <Box border={'1px solid #5F93CE'} borderRadius={'full'} me={2}>
                      <Image src={blockImage} height={'30px'} width={'30px'} />
                    </Box>
                    <Text {...textStyle} color={'#A9A8A8'} fontSize={isMobile ? '2xs' : '12.326px'}>
                      Only invited users can access this model <ChevronDownIcon />
                    </Text>{' '}
                  </Flex>
                ) : (
                  <ShareModalPermission
                    modelOwner={modelOwner}
                    user={user}
                    setUpgrade={setUpgrade}
                  />
                )}

                <Flex
                  flexDirection={'column'}
                  w={'full'}
                  gap={3}
                  opacity={
                    userSubscriptionLimitData?.subscription_features_limit?.sharing === 'view'
                      ? 0.4
                      : 1
                  }
                >
                  <Text {...textStyle} color={'#6561A9'} fontSize={isMobile ? 'xs' : 'sm'} pt={2}>
                    People with access
                  </Text>
                  {shareModelUsers && shareModelUsers?.length > 0 && (
                    <>
                      {shareModelUsers.map((shareUser: any) => (
                        <UserDetails
                          shareUser={shareUser}
                          key={shareUser.user_id}
                          modelOwner={modelOwner}
                          userPermission={
                            userSubscriptionLimitData?.subscription_features_limit?.sharing
                          }
                        />
                      ))}
                    </>
                  )}
                </Flex>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
      <AuthModalContainer isOpen={isAuthModalOpen} onClose={onToggleAuthModal} />
    </>
  );
};

export default ShareButtonWithPopup;
