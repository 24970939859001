import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const PlanIconGreen = ({ width = 6, height = 6, color = 'green.900' }: Properties) => {
  return (
    <Icon viewBox='0 0 20 20' width={width} height={height} color={color}>
      <g mask='url(#mask0_25_3891)'>
        <path
          d='M2.59668 17.4761V15.8436L4.22917 14.2111V17.4761H2.59668ZM5.86165 17.4761V12.5786L7.49414 10.9461V17.4761H5.86165ZM9.12663 17.4761V10.9461L10.7591 12.599V17.4761H9.12663ZM12.3916 17.4761V12.599L14.0241 10.9665V17.4761H12.3916ZM15.6566 17.4761V9.31364L17.2891 7.68115V17.4761H15.6566ZM2.59668 13.252V10.9461L8.31038 5.23242L11.5754 8.4974L17.2891 2.78369V5.08958L11.5754 10.8033L8.31038 7.53831L2.59668 13.252Z'
          fill='#4BB6B9'
        />
      </g>
    </Icon>
  );
};

export default PlanIconGreen;
