import { Box, Button, Heading, Spinner, Text, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import TagManager from 'react-gtm-module';
import { isExpired } from 'react-jwt';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from 'context/authContext';
import FormField from 'components/FormField';
import { SetAuthPageType, SignIn } from 'redux/AuthSlice';
import { SignInSchema } from 'validation/schemas/AuthenticationSchema';

const initialValues = {
  username: '',
  password: '',
};

const SignInPage = () => {
  const navigate = useNavigate();
  const dispatch: any = useDispatch();

  const { onAuthSuccess } = useAuthContext();

  const onSubmitForm = async (form: any, setSubmitting: (data: boolean) => void) => {
    dispatch(
      SignIn({
        request_body: {
          username: form.username,
          password: form.password,
        },
        toaster: { successMessage: false, errorMessage: true },
      }),
    ).then((response: any) => {
      setSubmitting(false);
      if (!response?.error) {
        onAuthSuccess(response?.payload?.token, response?.payload?.expiry);
        const tagManagerArguments = {
          gtmId: 'GTM-NP7FL5N',
          events: {
            event: 'login',
            userId: response?.payload?.user.id,
          },
        };
        TagManager.initialize(tagManagerArguments);
        const modelToken = window.localStorage.getItem('model-token');
        if (modelToken) {
          const isLinkExpired = isExpired(modelToken);
          if (isLinkExpired) {
            navigate('/home');
          } else {
            navigate(`/model/${modelToken}`);
          }

          localStorage.removeItem('model-token');
        } else {
          navigate('/home');
        }
      }
    });
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={(form, { setSubmitting }) => {
          onSubmitForm(form, setSubmitting);
        }}
        validationSchema={SignInSchema}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form>
            <VStack spacing='7' mb={'1rem'}>
              <FormField
                label='Email address'
                name='username'
                type='email'
                labelStyles={{
                  fontSize: '14px',
                  color: '#121826',
                }}
                inputStyles={{
                  maxWidth: '300px',
                  boxShadow: '0px 0px 0px 1px #7A849E',
                }}
                placeholder='name@email.com'
                error={errors.username}
                touched={touched.username}
                disabled={isSubmitting}
              />
              <FormField
                label='Password'
                name='password'
                type='password'
                placeholder='*********'
                labelStyles={{
                  fontSize: '14px',
                  color: '#121826',
                }}
                inputStyles={{
                  maxWidth: '300px',
                  boxShadow: '0px 0px 0px 1px #7A849E',
                }}
                styles={{
                  marginTop: '.7rem',
                }}
                error={errors.password}
                touched={touched.password}
                disabled={isSubmitting}
              />
              <Heading
                mt={'.6rem !important'}
                color={'black'}
                display={'flex'}
                width={'100%'}
                fontFamily='regular_roboto'
              >
                <Text opacity={'.3'} fontSize='2xs'>
                  Don't know your password?
                </Text>
                <Text
                  ml={'1'}
                  fontFamily='regular_roboto'
                  fontSize='2xs'
                  color='green.900'
                  cursor={'pointer'}
                  onClick={() => {
                    dispatch(SetAuthPageType(3));
                  }}
                >
                  Click here to reset
                </Text>
              </Heading>
            </VStack>
            <Button
              type='submit'
              w='100%'
              maxWidth={'300px'}
              h='12'
              borderRadius='xs'
              fontSize='sm'
              bgColor='green.900'
              disabled={isSubmitting}
              _hover={{
                bgColor: '',
              }}
            >
              SIGN IN {isSubmitting && <Spinner ml={'4'} />}
            </Button>
            <Heading
              mt={'.6rem !important'}
              color={'rgb(18, 24, 38) !important'}
              display={'flex'}
              justifyContent='center' // Center align items in flex container
              width={'100%'}
              fontFamily='regular_roboto'
            >
              <Text opacity={'.7'} fontSize='2xs'>
                New user?
              </Text>
              <Text
                ml={'1'}
                fontFamily='regular_roboto'
                fontSize='2xs'
                color='green.900'
                cursor={'pointer'}
                textDecoration={'underline'}
                onClick={() => {
                  window.location.href = 'https://www.blox.so/demo';
                }}
              >
                Click here to book a demo
              </Text>
            </Heading>
            <Box
              mt={2}
              color={'black.900'}
              fontSize='xs'
              display={'flex'}
              width={'100%'}
              justifyContent={'center'}
              alignContent={'center'}
            ></Box>
            {/* )} */}
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default SignInPage;
