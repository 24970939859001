const saveFile = (fileName: string, data: Array<BlobPart>, contentType: string) => {
  const file = new Blob(data, { type: contentType });

  const element = document.createElement('a');
  element.href = URL.createObjectURL(file);
  element.download = fileName;

  document.body?.appendChild(element);

  element.click();

  return file;
};

export default saveFile;
