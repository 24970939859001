import { Box, Flex, useMediaQuery } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import FullScreenLoader from 'components/FullScreenLoader';
import useDocumentTitle from 'hooks/useDocumentTitle';
import NavigationHeader from 'pages/NavigationHeader';
import { HEADER_HEIGHT } from 'projectConstants';
import { getExandSideInputStatus, handleExpandSideInput } from 'redux/BuilderModeSlice';
import {
  FetchFormulaList,
  FetchPlannerModeBlock,
  FetchPlannerModeModel,
  FetchScenarios,
  getPlannerBlock,
  getToggleInputPanel,
} from 'redux/PlannerModeSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

import PlannerModeHeader from './Header';
import IndicatorPage from './IndicatorPage';
import BuilderModeTable from './Table';
import PlannerModeToolbar from './Toolbar';

const BuilderMode: React.FC = () => {
  const { modelId, blockId } = useParams();
  const modelIdFromParameters = useParams().modelId;
  const [loader, setLoader] = useState(true);
  const toggleInputPanel = useSelector(getToggleInputPanel);
  const dispatch: AppDispatch = useDispatch();
  const blockDetails = useSelector(getPlannerBlock);
  const { pathname } = useLocation();
  const isExpand = useSelector(getExandSideInputStatus);

  const [isTablet] = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const initialData = async () => {
      setLoader(true);
      dispatch(FetchPlannerModeModel(Number(modelId)));
      dispatch(FetchFormulaList(Number(modelId)));
      dispatch(FetchPlannerModeBlock(Number(blockId))).then(async () => {
        if (modelIdFromParameters) await dispatch(FetchScenarios(modelIdFromParameters));
        setLoader(false);
      });
    };
    initialData();
  }, [pathname]);

  useDocumentTitle(blockDetails?.name);

  useEffect(() => {
    return () => {
      dispatch(handleExpandSideInput(false));
    };
  }, []);

  if (loader) {
    return <FullScreenLoader height={`calc(100vh - ${HEADER_HEIGHT})`} />;
  }

  return (
    <Box px={isTablet ? 4 : 7} py={4}>
      <NavigationHeader />
      <Flex
        width={'100%'}
        h={`calc(100vh - 76px)`}
        gap={4}
        flexDirection={isExpand ? 'row-reverse' : 'row'}
      >
        <Box
          width={toggleInputPanel ? (isExpand && toggleInputPanel ? '0%' : '75%') : '100%'}
          display={isExpand ? 'none' : 'block'}
        >
          <PlannerModeHeader />
          <PlannerModeToolbar />
          <Box>
            <BuilderModeTable />
          </Box>
        </Box>
        {toggleInputPanel && (
          <Box
            mt={4}
            className={`input-panel${toggleInputPanel ? ' visible' : ' hidden'}`}
            width={toggleInputPanel ? (isExpand && toggleInputPanel ? '100%' : '25%') : '0'}
            boxShadow={'1px 1px 10px #2b2727'}
            backgroundColor={'#776dcf'}
            borderRadius={'10px'}
          >
            <IndicatorPage />
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default BuilderMode;
