import {
  Box,
  Text,
  Image,
  useMediaQuery,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  deleteDataImportFile,
  duplicateSourceFile,
  editDataImportFile,
  getDataImportFiles,
} from 'redux/DataImportSlice';
import CustomInputField from 'components/CustomInput';
import { handleFileDelete } from 'services/s3Service';
import DeleteModal from 'components/Modals/DeleteModal';
import DataTabIcon from 'components/Icons/ModelOverviewTabs/DataTabIcon';
import infoIcon from 'assets/info_icon.svg';
import { AppDispatch } from 'utils/GlobalHelpers';

const commonStyles = {
  display: 'flex',
  alignItems: 'center',
};

export interface Properties {
  ImportDataFiles?: any;
  FileDetailsPage?: any;
}

const SourceItem = ({ ImportDataFiles, FileDetailsPage }: Properties) => {
  const [isSmallLaptop] = useMediaQuery('(max-width: 1415px)');
  const [isMobile] = useMediaQuery('(max-width: 900px)');

  const { modelId } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [deleteIdModal, setDeleteIdModal]: any = useState(null);
  const [DeleteNameModal, setDeleteNameModal] = useState('');
  const [renameItemId, setRenameItemId] = useState<number | null>(null);
  const [renameItemValue, setRenameItemValue] = useState<string | null>(null);

  const textStyles = {
    color: 'rgba(255, 255, 255, 1)',
    fontFamily: 'regular_roboto',
    fontWeight: '400',
    fontSize: isMobile ? '2xs' : isSmallLaptop ? 'sm' : 'xs',
  };

  const deleteScenarioModal = async (event: any, id: number, name: any) => {
    event.stopPropagation();
    setIsDeleteModal(true);
    setDeleteIdModal(id);
    setDeleteNameModal(name);
  };
  const onDeleteModel = async () => {
    await dispatch(deleteDataImportFile({ modelId, fileId: deleteIdModal }));
    await dispatch(getDataImportFiles(modelId));
    await setIsDeleteModal(false);
    await setDeleteIdModal(null);
    await setDeleteNameModal('');
  };

  const handleAWSFileDelete = async (file: any) => {
    await handleFileDelete(file, 'blox-data-import');
  };
  const renameItem = (event: any, id: number) => {
    event.stopPropagation();
    if (renameItemId === id) {
      setRenameItemId(0);
    }
    setRenameItemId(id);
  };
  const duplicateItem = async (event: any, dataSourceId: number) => {
    event.stopPropagation();
    await dispatch(duplicateSourceFile(dataSourceId));
    await dispatch(getDataImportFiles(modelId));
  };
  const UpdateItemApi = async (item_id: Number) => {
    const requestBody = {
      file_name: renameItemValue,
      file_type: 'csv',
    };
    await dispatch(
      editDataImportFile({
        modelId,
        fileId: item_id,
        request_body: requestBody,
        toaster: {
          successMessage: 'Successfully updated',
          errorMessage: 'An Source with this name already exists',
        },
      }),
    );
    await setRenameItemId(0);
    await dispatch(getDataImportFiles(modelId));
  };

  const onKeyPressAction = async (event: any, item_id: number) => {
    event.stopPropagation();
    if (event.keyCode === 13) {
      await UpdateItemApi(item_id);
      setRenameItemValue(null);
    }
    if (event.key === 'Escape') setRenameItemValue(null);
  };

  return (
    <Box
      minH='40px'
      {...commonStyles}
      justifyContent='space-between'
      px={3}
      gap={3}
      bg={'rgba(112, 98, 205, 1)'}
      borderRadius={'9px'}
      width='full'
      mb={3}
      boxShadow='2px 3px 5px rgba(0, 0, 0, 0.25)'
    >
      <Box {...commonStyles} gap={3} width={'80%'}>
        <DataTabIcon color={'#4BB6B9'} />
        {renameItemId === ImportDataFiles?.id ? (
          <CustomInputField
            alignContent='center'
            fontSize='xs'
            whiteSpace='nowrap'
            overflow='hidden'
            width={'fit-content'}
            outline={'none !important'}
            boxShadow={'none !important'}
            border={'1px #fff solid !important'}
            padding={'15px 5px 15px 10px'}
            defaultValue={ImportDataFiles?.file_name}
            height='0.9rem'
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setRenameItemValue(event.target.value);
            }}
            onKeyDown={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.value.trim().length > 0)
                onKeyPressAction(event, ImportDataFiles?.id);
            }}
            _placeholder={{ color: 'white' }}
            autoFocus
            color='white'
            onBlur={() => {
              setRenameItemId(0);
            }}
          />
        ) : (
          <Tooltip label={ImportDataFiles?.file_name}>
            <Text
              {...textStyles}
              onClick={() => FileDetailsPage(ImportDataFiles?.id, ImportDataFiles?.file_name)}
              whiteSpace={'nowrap'}
              overflow='hidden'
              textOverflow='ellipsis'
              // noOfLines={1}
              width={'100%'}
              textAlign={'start'}
              cursor={'pointer'}
            >
              {ImportDataFiles?.file_name}
            </Text>
          </Tooltip>
        )}
      </Box>
      <Box {...commonStyles} gap={5}>
        <Menu closeOnSelect={true} variant='primary'>
          <MenuButton
            as={Button}
            border={'none'}
            width={'fit-content'}
            padding={0}
            minW={'fit-content'}
            mr={2}
            // onClick={(event) => toggleInfoPopup(event, ImportDataFiles?.id)}
          >
            <Image src={infoIcon} w='18px' h='18px' className='infoButton' cursor={'pointer'} />
          </MenuButton>
          <MenuList>
            <MenuItem
              onClick={(event) => renameItem(event, ImportDataFiles.id)}
              fontSize={isMobile ? '11px' : '14px'}
              fontFamily='regular_roboto'
            >
              Rename
            </MenuItem>
            <MenuItem
              onClick={(event) => {
                handleAWSFileDelete(ImportDataFiles?.file_name);
                deleteScenarioModal(event, ImportDataFiles?.id, ImportDataFiles?.file_name);
              }}
              fontSize={isMobile ? '11px' : '14px'}
              fontFamily='regular_roboto'
            >
              Delete
            </MenuItem>
            <MenuItem
              fontSize={isMobile ? '11px' : '14px'}
              fontFamily='regular_roboto'
              onClick={(event) => duplicateItem(event, ImportDataFiles.id)}
            >
              Duplicate
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
      <DeleteModal
        isOpen={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
        itemName={DeleteNameModal}
        onDelete={onDeleteModel}
      />
    </Box>
  );
};

export default SourceItem;
