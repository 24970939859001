import { Flex, Radio, RadioGroup, Stack, Text, VStack } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseIcon } from '@chakra-ui/icons';

import {
  clearStepperFormData,
  getFormValues,
  setActionCreateForm,
  setClearSelectedAreasTable,
  setDataPeriodType,
} from 'redux/DataImportSlice';

import { checkedRadioStyles, textStyles } from '../../Helper';

import Single from './DataPeriod/Single';
import Multiple from './DataPeriod/Multiple';

interface Properties {
  setHelpModalSeleceted: any;
}

const DataStepsOne = ({ setHelpModalSeleceted }: Properties) => {
  const dispatch: any = useDispatch();
  const getFormValue = useSelector(getFormValues);
  return (
    <VStack w={'100%'} pt={3}>
      <Flex justifyContent={'space-between'} w={'full'} align={'center'}>
        <Text
          color={'#6562CF'}
          fontSize='lg'
          fontFamily={'regular_roboto'}
          fontWeight={'400'}
          textAlign={'start'}
        >
          Select time period
        </Text>
        <CloseIcon
          color={'black'}
          width={3}
          height={3}
          cursor={'pointer'}
          onClick={async () => {
            await dispatch(setActionCreateForm(false));
            await dispatch(clearStepperFormData());
            await dispatch(setClearSelectedAreasTable());
          }}
        />
      </Flex>
      <Text color={'black'} {...textStyles} textAlign={'start'} width={'100%'}>
        {getFormValue.dataPeriod === '2' ? (
          <Text fontSize='0.9rem' color='black' fontFamily={'bold_roboto'}>
            Are you importing multiple time periods?
          </Text>
        ) : (
          <Text fontSize='0.9rem' color='black' fontFamily={'bold_roboto'}>
            Is your data for a single period or multiple period?
          </Text>
        )}
      </Text>
      <RadioGroup
        width={'100%'}
        pt={3}
        onChange={async (value) => {
          await dispatch(setDataPeriodType(value));
        }}
        value={getFormValue.dataPeriod}
      >
        <Stack spacing={5} direction='row'>
          <>
            <Radio _checked={checkedRadioStyles} value='1'>
              <Text color='black' {...textStyles}>
                Single
              </Text>
            </Radio>
            <Radio _checked={checkedRadioStyles} value='2'>
              <Text color='black' {...textStyles}>
                Multiple
              </Text>
            </Radio>
          </>
        </Stack>
      </RadioGroup>
      {getFormValue.dataPeriod === '1' && (
        <Single setHelpModalSeleceted={setHelpModalSeleceted} getFormValue={getFormValue} />
      )}
      {getFormValue.dataPeriod === '2' && (
        <Multiple setHelpModalSeleceted={setHelpModalSeleceted} getFormValue={getFormValue} />
      )}
    </VStack>
  );
};

export default DataStepsOne;
