/* eslint-disable @typescript-eslint/no-shadow */
import {
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  Select,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DimItem } from 'models/dimensionItem.interface';
import { getItemId } from 'redux/ItemPageSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';
import apiService from 'services';

interface DesktopValues {
  keys: string;
  value: string | any;
  onSubmitValue: (value: string, id: number, propertyValue: string) => void;
  dataElementsId: any;
  planPermission?: boolean;
}

const styles = {
  border: css`
    &:last-child {
      border: none;
    }
  `,
};

const DesktopProperties = ({
  keys,
  value,
  onSubmitValue,
  dataElementsId,
  planPermission,
}: DesktopValues) => {
  const [inputValue, setInputValue] = useState(value);
  const [isEditMode, setIsEditMode] = useState(false);

  const [connectedDimensionItems, setItems] = useState<null | any[]>(null);
  const itemId = useSelector(getItemId);
  const baseScenario = useSelector(getBaseScenario);

  const findConnectedDimId = () => {
    const connectedProperty = itemId?.properties?.find((property: any) => {
      return property.name === keys && property.data_format && property.type === 'dimension';
    });

    if (connectedProperty) {
      const dataFormat = JSON.parse(connectedProperty?.data_format || '{}');
      return dataFormat?.dimension;
    }
    return null;
  };

  const connectedDimId = findConnectedDimId();

  useEffect(() => {
    if (connectedDimId) {
      apiService
        .get_dimension_items(connectedDimId, {
          params: {
            scenario_id: baseScenario?.id,
          },
        })
        .then((response: any) => {
          setItems(response.data.items);
        });
    }
  }, [connectedDimId, value]);

  if (keys === 'Department') {
    return null;
  }

  const submitDropdownData = (event: any, id: number, propertyValue: string) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    onSubmitValue(newValue, id, propertyValue);
  };

  if (
    connectedDimensionItems &&
    connectedDimensionItems.length > 0 &&
    itemId?.properties?.length &&
    connectedDimId
  ) {
    return (
      <Flex
        w='100%'
        justifyContent='space-between'
        p='15px 0px'
        alignItems='center'
        borderBottom='1px solid rgba(255, 255, 255, 0.3)'
        css={styles.border}
      >
        <>
          <Tooltip label={keys}>
            <Text noOfLines={1} maxWidth='150px'>
              {keys}
            </Text>
          </Tooltip>
          <Tooltip label={inputValue}>
            <Select
              maxWidth='100px'
              iconColor='white'
              iconSize='small'
              borderRadius={'10px'}
              placeholder='Select option'
              value={inputValue}
              color={'white'}
              bg='#796cd6'
              fontSize={'2xs'}
              p={'2px'}
              h='7.5'
              onChange={(event: any) => submitDropdownData(event, dataElementsId, keys)}
              isDisabled={!!planPermission}
              cursor={planPermission ? 'not-allowed' : 'pointer'}
            >
              {connectedDimensionItems.map((item: DimItem) => (
                <option
                  key={item.id}
                  value={item.name}
                  style={{ margin: '4px', borderRadius: '25px' }}
                >
                  {item.name}
                </option>
              ))}
            </Select>
          </Tooltip>
        </>
      </Flex>
    );
  }

  return (
    <Flex
      w='100%'
      justifyContent='space-between'
      p='15px 0px'
      alignItems='center'
      borderBottom='1px solid rgba(255, 255, 255, 0.3)'
      css={styles.border}
    >
      <>
        <Tooltip label={keys}>
          <Text noOfLines={1} maxWidth='150px'>
            {keys}
          </Text>
        </Tooltip>

        <Tooltip label={inputValue}>
          <Editable
            defaultValue={inputValue}
            h='7.5'
            flexGrow='1'
            borderWidth='1'
            borderRadius='xs'
            fontSize='xs'
            display='flex'
            alignItems='center'
            justifyContent='center'
            textAlign='center'
            onChange={(v) => setInputValue(v)}
            onSubmit={async () => {
              setIsEditMode(false);
              onSubmitValue(inputValue, dataElementsId, keys);
            }}
            maxWidth='80px'
            cursor={planPermission ? 'not-allowed' : 'pointer'}
            isDisabled={!!planPermission}
            onClick={() => setIsEditMode(true)}
          >
            <EditablePreview
              minW='50px'
              pl='0.25rem'
              pr='0.25rem'
              h='100%'
              display={isEditMode ? 'none' : 'inline'}
              overflow='hidden'
              whiteSpace='nowrap'
              textOverflow='ellipsis'
              cursor={planPermission ? 'not-allowed' : 'pointer'}
              onClick={() => setIsEditMode(true)}
            />
            <EditableInput
              pl='0.25rem'
              pr='0.25rem'
              h='100%'
              display={!isEditMode ? 'none' : 'inline !important'}
              cursor={planPermission ? 'not-allowed' : 'pointer'}
              _focusVisible={{
                borderColor: 'none',
              }}
              autoFocus
            />
          </Editable>
        </Tooltip>
      </>
    </Flex>
  );
};

export default DesktopProperties;
