import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { AddDimensionItem } from 'models/requestBody/addDimensionItem.interface';
import {
  GetDimensionItems,
  UpdateIndicatorDataInput,
} from 'models/requestBody/updateIndicatorDataInput.interface';
import { IndicatorDetails } from 'models/response/driver.interface';
import { STATUS } from 'projectConstants';
import apiService from 'services';

const initialState: any = {
  currentDimensionItemIndex: 0,
  dimensionItems: null,
  dimensionItemsStatus: STATUS.IDLE,

  modelTime: null,
  modelTimeStatus: STATUS.IDLE,
  tabShimmer: false,
  indicatorDetail: null,
  indicatorDetailTableData: null,
  indicatorDetailTableDataStatus: STATUS.IDLE,
  indicatorDetailStatus: STATUS.IDLE,
  tableView: false,
  chartFilterItems: null,
};

export const fetchModelTime = createAsyncThunk(
  'modelTime/fetch',
  async (modelId: number, thunkAPI) => {
    try {
      const response = await apiService.get_model_time(modelId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const fetchIndicatorDetailType = createAsyncThunk(
  'indicatorDetailType/fetch',
  async ({ currentDriver, parameters }: IndicatorDetails, thunkAPI) => {
    try {
      const response = await apiService.get_indicator_details(currentDriver.id, {
        params: parameters,
      });

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchIndicatorDetail = createAsyncThunk(
  'indicatorDetail/fetch',
  async ({ currentDriver, parameters }: IndicatorDetails, thunkAPI) => {
    try {
      const response = await apiService.get_indicator_details(currentDriver.id, {
        params: parameters,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const updateDataInput = createAsyncThunk(
  'updateDataInput/update',
  async ({ indicator_id, request_body }: UpdateIndicatorDataInput, thunkAPI) => {
    try {
      const response = await apiService.update_indicator_data_input({ indicator_id, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const addDimensionItem = createAsyncThunk(
  'addDimensionItem/add',
  async ({ dimension_id, request_body }: AddDimensionItem, thunkAPI) => {
    try {
      const response = await apiService.add_dimension_item({ dimension_id, request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchDimensionItems = createAsyncThunk(
  'dimensionItems/get',
  async ({ dimensionId, parameters }: GetDimensionItems, thunkAPI) => {
    try {
      const response = await apiService.get_dimension_items(dimensionId, {
        params: parameters,
      });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

const indicatorPageSlice = createSlice({
  initialState,
  name: 'indicatorPageSlice',
  reducers: {
    setCurrentDimensionItemIndex: (state, action) => {
      state.currentDimensionItemIndex = action.payload;
    },
    setChartFilterItems: (state, action) => {
      state.chartFilterItems = action.payload;
    },
    setTabShimmer: (state, action) => {
      state.tabShimmer = action.payload;
    },
    setTableBigView: (state, action) => {
      state.tableView = action.payload;
    },
    resetIndicator: (state) => {
      state.tableView = false;
      state.currentDimensionItemIndex = 0;
      state.dimensionItems = null;
      state.dimensionItemsStatus = STATUS.IDLE;

      state.modelTime = null;
      state.modelTimeStatus = STATUS.IDLE;
      state.indicatorDetailTableData = null;

      // state.indicatorDetail = null;
      state.indicatorDetailStatus = STATUS.IDLE;
      state.indicatorDetailTableDataStatus = STATUS.IDLE;
      state.tabShimmer = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchModelTime.pending, (state) => {
        state.modelTimeStatus = STATUS.LOADING;
      })
      .addCase(fetchModelTime.fulfilled, (state, action) => {
        state.modelTime = action.payload;
        state.modelTimeStatus = STATUS.SUCCESS;
      })
      .addCase(fetchModelTime.rejected, (state) => {
        state.modelTimeStatus = STATUS.FAILED;
      })
      .addCase(fetchIndicatorDetailType.pending, (state) => {
        state.indicatorDetailTableDataStatus = STATUS.LOADING;
      })
      .addCase(fetchIndicatorDetailType.fulfilled, (state, action) => {
        state.indicatorDetailTableData = action.payload;
        state.indicatorDetailTableDataStatus = STATUS.SUCCESS;
      })
      .addCase(fetchIndicatorDetail.pending, (state) => {
        state.indicatorDetailStatus = STATUS.LOADING;
      })
      .addCase(fetchIndicatorDetail.fulfilled, (state, action) => {
        state.indicatorDetail = action.payload;
        state.tabShimmer = false;
        state.indicatorDetailStatus = STATUS.SUCCESS;
      })
      .addCase(fetchIndicatorDetail.rejected, (state) => {
        state.indicatorDetailStatus = STATUS.FAILED;
      })
      .addCase(fetchDimensionItems.fulfilled, (state, action) => {
        state.dimensionItems = action.payload;
        state.dimensionItemsStatus = STATUS.SUCCESS;
      })
      .addCase(fetchDimensionItems.rejected, (state) => {
        state.dimensionItems = [];
        state.dimensionItemsStatus = STATUS.SUCCESS;
      });
  },
});

export const {
  setCurrentDimensionItemIndex,
  resetIndicator,
  setTabShimmer,
  setTableBigView,
  setChartFilterItems,
} = indicatorPageSlice.actions;

export const getModelTime = (state: any) => state.indicatorPageSlice.modelTime;
export const geModelTimeStatus = (state: any) => state.indicatorPageSlice.modelTimeStatus;

export const getIndicatorDetail = (state: any) => state.indicatorPageSlice.indicatorDetail;
export const getIndicatorDetailStatus = (state: any) =>
  state.indicatorPageSlice.indicatorDetailStatus;
export const getIndicatorDetailTableDataStatus = (state: any) =>
  state.indicatorPageSlice.indicatorDetailTableDataStatus;
export const getTableShimmer = (state: any) => state.indicatorPageSlice.tabShimmer;
export const getCurrentDimensionItemIndex = (state: any) =>
  state.indicatorPageSlice.currentDimensionItemIndex;
export const getDimensionItems = (state: any) => state.indicatorPageSlice.dimensionItems;
export const getTableView = (state: any) => state.indicatorPageSlice.tableView;
export const getChartFilterItems = (state: any) => state.indicatorPageSlice.chartFilterItems;
export const getIndicatorDetailTableData = (state: any) =>
  state.indicatorPageSlice.indicatorDetailTableData;

export default indicatorPageSlice.reducer;
