import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import { SetTabs, SetUserModalVisible } from 'redux/UserSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

export interface Properties {
  ScenarioLimit?: number;
  CurrentScenarioLength?: number;
}

const ScenarioPermission = ({ ScenarioLimit, CurrentScenarioLength }: Properties) => {
  const dispatch: AppDispatch = useDispatch();

  const fontStyle = {
    color: '#FFF',
    fontFamily: 'regular_roboto',
    fontSize: 'xs',
    fontWeight: 400,
  };
  return (
    <Flex
      bg={'#3BB8BC'}
      justify={'center'}
      align={'center'}
      gap={3}
      height={'30px'}
      borderRadius={'6px'}
      my={2}
    >
      <InfoOutlineIcon color={'white'} />
      <Flex justify={'center'} align={'center'} gap={1}>
        <Text {...fontStyle}>
          {CurrentScenarioLength}/{ScenarioLimit} Scenarios |
        </Text>
        <Text
          {...fontStyle}
          fontWeight={600}
          textDecoration={'underline'}
          cursor={'pointer'}
          onClick={async () => {
            await dispatch(SetUserModalVisible(true));
            await dispatch(SetTabs(5));
          }}
        >
          Upgrade
        </Text>
        <Text {...fontStyle}>to unlock more</Text>
      </Flex>
    </Flex>
  );
};

export default ScenarioPermission;
