const AlignHorizontalLeftIcon = () => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      width='25px'
      height='25px'
      viewBox='0 0 50 50'
    >
      <path
        d='M0 0 C4.29 0 8.58 0 13 0 C13 6.27 13 12.54 13 19 C8.71 19 4.42 19 0 19 C0 12.73 0 6.46 0 0 Z '
        fill='#1C274C'
        transform='translate(1,14)'
      />
      <path
        d='M0 0 C3.96 0 7.92 0 12 0 C12 0.99 12 1.98 12 3 C9.03 3 6.06 3 3 3 C3 7.95 3 12.9 3 18 C5.97 18 8.94 18 12 18 C12 18.99 12 19.98 12 21 C9.03 21 6.06 21 3 21 C3 25.95 3 30.9 3 36 C5.97 36 8.94 36 12 36 C12 36.99 12 37.98 12 39 C8.04 39 4.08 39 0 39 C0 33.06 0 27.12 0 21 C-2.64 21 -5.28 21 -8 21 C-8 20.01 -8 19.02 -8 18 C-5.36 18 -2.72 18 0 18 C0 12.06 0 6.12 0 0 Z '
        fill='#1C274C'
        transform='translate(23,4)'
      />
      <path
        d='M0 0 C4.29 0 8.58 0 13 0 C13 4.95 13 9.9 13 15 C8.71 15 4.42 15 0 15 C0 10.05 0 5.1 0 0 Z '
        fill='#1C274C'
        transform='translate(36,34)'
      />
      <path
        d='M0 0 C4.29 0 8.58 0 13 0 C13 4.95 13 9.9 13 15 C8.71 15 4.42 15 0 15 C0 10.05 0 5.1 0 0 Z '
        fill='#1C274C'
        transform='translate(36,16)'
      />
      <path
        d='M0 0 C4.29 0 8.58 0 13 0 C13 4.29 13 8.58 13 13 C8.71 13 4.42 13 0 13 C0 8.71 0 4.42 0 0 Z '
        fill='#1C274C'
        transform='translate(36,0)'
      />
    </svg>
  );
};

export default AlignHorizontalLeftIcon;
