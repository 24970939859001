interface Properties {
  width?: number;
  height?: number;
  color?: string;
  style?: React.CSSProperties;
}

const CalculationIconComponent = ({
  width = 20,
  height = 20,
  color = 'green.900',
  style,
}: Properties) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      viewBox='0 0 19 20'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_372_3422)'>
        <path
          d='M16.6161 0.295898H2.74079C1.73381 0.295898 0.851074 1.15404 0.851074 2.13295V18.0689C0.851074 19.0478 1.73381 19.906 2.74079 19.906H16.6096C17.6166 19.906 18.4993 19.0478 18.4993 18.0689V2.13295C18.4993 1.15404 17.6166 0.295898 16.6096 0.295898H16.6161ZM5.8925 16.9692C5.8925 17.2171 5.63748 17.4587 5.38901 17.4587H3.87854C3.62353 17.4587 3.37505 17.2108 3.37505 16.9692V15.5009C3.37505 15.2529 3.63007 15.0114 3.87854 15.0114H5.38901C5.64402 15.0114 5.8925 15.2593 5.8925 15.5009V16.9692ZM5.8925 12.0683C5.8925 12.3162 5.63748 12.5578 5.38901 12.5578H3.87854C3.62353 12.5578 3.37505 12.3098 3.37505 12.0683V10.5999C3.37505 10.352 3.63007 10.1105 3.87854 10.1105H5.38901C5.64402 10.1105 5.8925 10.3584 5.8925 10.5999V12.0683ZM10.9339 16.9692C10.9339 17.2171 10.6789 17.4587 10.4304 17.4587H8.91997C8.66495 17.4587 8.41648 17.2108 8.41648 16.9692V15.5009C8.41648 15.2529 8.67149 15.0114 8.91997 15.0114H10.4304C10.6854 15.0114 10.9339 15.2593 10.9339 15.5009V16.9692ZM10.9339 12.0683C10.9339 12.3162 10.6789 12.5578 10.4304 12.5578H8.91997C8.66495 12.5578 8.41648 12.3098 8.41648 12.0683V10.5999C8.41648 10.352 8.67149 10.1105 8.91997 10.1105H10.4304C10.6854 10.1105 10.9339 10.3584 10.9339 10.5999V12.0683ZM15.9753 16.9692C15.9753 17.2171 15.7203 17.4587 15.4719 17.4587H13.9614C13.7064 17.4587 13.4579 17.2108 13.4579 16.9692V10.5936C13.4579 10.3457 13.7129 10.1041 13.9614 10.1041H15.4719C15.7269 10.1041 15.9753 10.352 15.9753 10.5936V16.9692ZM15.9753 7.16101C15.9753 7.40892 15.7203 7.65047 15.4719 7.65047H3.87854C3.62353 7.65047 3.37505 7.40256 3.37505 7.16101V3.239C3.37505 2.99109 3.63007 2.74954 3.87854 2.74954H15.4784C15.7334 2.74954 15.9819 2.99745 15.9819 3.239V7.16101H15.9753Z'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default CalculationIconComponent;
