import './Switch.scss';
import { memo } from 'react';

interface Properties {
  isSelected?: boolean;
  handleOnChange?: (event: any) => void;
}

const Switch = memo(({ isSelected, handleOnChange }: Properties) => {
  return (
    <div className='nav-switch' style={{ height: '17px' }}>
      <label className='switch'>
        <input checked={isSelected} onChange={handleOnChange} type='checkbox' />
        <span className='slider round icon'></span>
      </label>
    </div>
  );
});
export default Switch;
