import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Container from 'components/Container';
import LoginImage from 'components/Icons/LoginImage';
import Logo from 'components/Icons/Logo';
import { getAuthenticationToken, getSetPasswordsPageType, GetUserDetail } from 'redux/AuthSlice';

import ChangePasswordForm from './ChangePasswordForm';

const ChangePasswordPage = () => {
  const navigate = useNavigate();
  const confirmReference: any = useRef();
  const dispatch: any = useDispatch();

  const [alert, setAlert] = useState(false);
  const [queryParameters] = useSearchParams();
  const [userDetail, setUserDetail]: any = useState();

  const authenticationToken = useSelector(getAuthenticationToken);
  const setPasswordsPageType = useSelector(getSetPasswordsPageType);

  useEffect(() => {
    if (queryParameters.get('token')) {
      localStorage.setItem('BLOX_PASSWORD_TOKEN', queryParameters.get('token') as string);
    } else {
      localStorage.removeItem('BLOX_PASSWORD_TOKEN');
    }
  }, [queryParameters]);

  useEffect(() => {
    const token = queryParameters.get('token') || authenticationToken;
    dispatch(GetUserDetail({ request_body: token })).then((response: any) => {
      if (response.type.endsWith('/rejected')) {
        setAlert(true);
      } else {
        setUserDetail(response.payload.user);
      }
    });
  }, []);

  return (
    <>
      <Container
        wrapperProperties={{
          px: 0,
          p: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
        bgColor={
          'linear-gradient(180deg, rgba(101, 98, 207, 0.6) 0%, rgba(75, 182, 185, 0.6) 100%);'
        }
      >
        <Flex
          boxShadow={'0px 15px 15px rgba(0, 0, 0, 0.15)'}
          justifyContent={'space-between'}
          borderRadius={'15px'}
          bgColor={'white'}
          width={'fit-content'}
          maxHeight={'100%'}
          minWidth={'300px'}
          position={'relative'}
        >
          <Flex maxWidth={'550px'} width={'100%'} padding={'2rem'} direction={'column'}>
            <Box>
              <Logo height={10} width={'auto'} />
            </Box>
            {setPasswordsPageType === 1 ? (
              <Box>
                <Heading
                  display={'flex'}
                  variant='secondary'
                  fontFamily='bold'
                  color={'#121826'}
                  fontWeight={'medium'}
                  fontSize={{ base: '20px', '1.5xl': '25px' }}
                  marginTop={'2rem'}
                >
                  Hi,
                  <Text color={'green.900'} ml={'1'}>
                    {userDetail?.firstname || 'User'}
                  </Text>
                </Heading>
                <Text
                  variant='secondary'
                  fontFamily='regular_roboto'
                  fontSize={{ base: '16px', '1.5xl': 'md' }}
                  color={'#1A202C'}
                  marginBottom={'1rem'}
                  mt={3}
                  mb={3}
                  width={{ start: '60%', sm: '100%' }}
                >
                  Set your password below to access Blox
                </Text>
              </Box>
            ) : (
              <Box>
                <Heading
                  display={'flex'}
                  variant='secondary'
                  fontFamily='regular_roboto'
                  color={'black'}
                  fontSize={{ base: '20px', '1.5xl': '25px' }}
                  marginTop={'2rem'}
                >
                  One more thing...
                </Heading>
                <Text
                  variant='secondary'
                  fontFamily='regular_roboto'
                  fontSize={{ base: '16px', '1.5xl': 'md' }}
                  opacity={'.5'}
                  marginBottom={'1rem'}
                  color={'#1A202C'}
                  mt={3}
                  mb={3}
                  width={{ start: '60%', sm: '100%' }}
                >
                  Please confirm your personal details below:
                </Text>
              </Box>
            )}
            <Box>
              <ChangePasswordForm />
            </Box>
          </Flex>
          <Box position={'absolute'} top={'0'} right={'0'}>
            <LoginImage
              width={{ start: '180px', sm: '200px' }}
              height={{ start: '180px', sm: '200px' }}
              styles={{ borderRadius: '15px' }}
            />
          </Box>
        </Flex>
      </Container>
      <AlertDialog
        closeOnOverlayClick={false}
        leastDestructiveRef={confirmReference}
        isOpen={alert}
        onClose={() => setAlert(!alert)}
        colorScheme='black'
      >
        <AlertDialogOverlay>
          <AlertDialogContent width={{ start: '90%' }}>
            <AlertDialogHeader fontSize='lg' fontWeight='bold' color={'black'}>
              Session expire
            </AlertDialogHeader>

            <AlertDialogBody color={'black'}>
              Your current session has been expired, Please reset your password again
            </AlertDialogBody>
            <AlertDialogFooter color={'black'} justifyContent={'center'}>
              <Button
                color={'black'}
                onClick={() => {
                  setAlert(!alert);
                  navigate('/');
                }}
                ml={3}
              >
                Ok
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export default ChangePasswordPage;
