import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const Pencil = ({ width = 5, height = 5, color = 'green.900', style, onClick }: Properties) => {
  return (
    <Icon
      style={style}
      viewBox='0 0 38 38'
      width={width}
      height={height}
      color={color}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <path
        d='M0 30.0844V38H7.91557L31.2612 14.6544L23.3456 6.73879L0 30.0844ZM37.3826 8.53298C38.2058 7.70976 38.2058 6.37995 37.3826 5.55673L32.4433 0.617414C31.6201 -0.205805 30.2902 -0.205805 29.467 0.617414L25.6042 4.48021L33.5198 12.3958L37.3826 8.53298Z'
        fill='currentColor'
      />
    </Icon>
  );
};

export default Pencil;
