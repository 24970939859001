import { useSelector } from 'react-redux';

import { getSharedModelUsers } from 'redux/HeaderSlice';
import { getUserData } from 'redux/UserSlice';

const PlanPermission = (WrappedComponent: any) => {
  const EnhancedComponent = (properties: any) => {
    const userData = useSelector(getUserData);
    const shareModelUsers = useSelector(getSharedModelUsers);

    if (!userData?.id && shareModelUsers) {
      return null;
    }

    const currentUserPermissions = !shareModelUsers?.some(
      (sharedUser: any) => sharedUser?.user_id === userData?.id && sharedUser?.role === 'view',
    );

    return (
      <WrappedComponent
        {...properties}
        planPermission={!(currentUserPermissions && userData?.is_active)}
      />
    );
  };

  return EnhancedComponent;
};

export default PlanPermission;
