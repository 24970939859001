import {
  Box,
  Flex,
  Text,
  Tab,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  TabIndicator,
  Select,
  Tooltip,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import {
  FetchDimensionGroup,
  FetchDimensionProperties,
  getBuilderDimensions,
  getDimensionProperty,
  getSelectedDimension,
  getBaseScenario,
  handlePropertySection,
  UpdateDimensionProperties,
  handleLoader,
} from 'redux/BuilderModeSlice';
import InputField from 'components/InputField';
import CloseIconComponent from 'components/Icons/CloseIcon';
import { HEADER_HEIGHT } from 'projectConstants';

const BuilderPropertiesComponent = () => {
  const dimensionProperty = useSelector(getDimensionProperty);
  const builderDimension = useSelector(getBuilderDimensions);
  const selectedDimension: any = useSelector(getSelectedDimension);
  const selectedScenario = useSelector(getBaseScenario);

  const dispatch: any = useDispatch();
  const handleClose = () => dispatch(handlePropertySection());

  const handlePropertiesChange = async (
    value: string,
    id: number,
    type: string,
    dataFormat: string,
  ) => {
    dispatch(handleLoader(true));
    // dispatch(handlePropertySection());
    await (type === 'type' && dataFormat
      ? dispatch(
          UpdateDimensionProperties({
            property_id: id,
            request_body: {
              [type]: typeof value === 'boolean' ? value : `${value}`,
            },
          }),
        ).then(() => {
          dispatch(
            UpdateDimensionProperties({
              property_id: id,
              request_body: {
                data_format: JSON.stringify({ dimension: null }),
              },
            }),
          );
        })
      : dispatch(
          UpdateDimensionProperties({
            property_id: id,
            request_body: {
              [type]: typeof value === 'boolean' ? value : `${value}`,
            },
          }),
        ).then(() => {
          if (type === 'data_format' || (type === 'type' && value === 'dimension')) {
            dispatch(
              FetchDimensionProperties({
                selectedId: selectedDimension?.id,
                parameters: { scenario_id: selectedScenario?.id },
              }),
            ).then((response_: any) => {
              if (response_?.payload?.properties) {
                response_?.payload?.properties?.map((data: any) => {
                  if (data?.data_format) {
                    dispatch(
                      FetchDimensionGroup({
                        data,
                        parameters: { scenario_id: selectedScenario?.id },
                      }),
                    );
                  }
                  return null;
                });
              }
            });
          }
        }));
    dispatch(handleLoader(false));
  };
  return (
    <Flex
      flexDir={'column'}
      backgroundColor={'white'}
      borderRadius={'md'}
      minW={'350px'}
      color={'black.900'}
      height={'100%'}
    >
      <Flex justifyContent={'end'} pt={4} pr={4}>
        <CloseIconComponent height={'15px'} width={'15px'} color={'black'} onClick={handleClose} />
      </Flex>
      <Flex px={8} flexDir={'column'} height={`calc(100% - ${HEADER_HEIGHT})`}>
        <Text mb={4} fontSize={'md'} fontWeight={'600'} color={'violet.750'}>
          Properties
        </Text>
        {dimensionProperty?.length ? (
          <Tabs isLazy variant={'primary'} height={`calc(100% - 40px)`} position={'relative'}>
            <Box
              className='scroll'
              width={'285px'}
              overflowX={'scroll'}
              pb={'1rem'}
              position={'relative'}
            >
              <TabList width={'100%'} position={'relative'}>
                {dimensionProperty?.map((data: any, index: number) => (
                  <Tooltip label={data?.name}>
                    <Tab
                      minWidth={'70px'}
                      key={index}
                      noOfLines={1}
                      whiteSpace={'nowrap'}
                      overflow={'hidden'}
                      textOverflow={'ellipsis'}
                    >
                      {data?.name}
                    </Tab>
                  </Tooltip>
                ))}
              </TabList>
              <TabIndicator
                style={{
                  position: 'absolute',
                  marginTop: '-1.5px',
                  height: '3px',
                  backgroundColor: '#6562CF',
                  borderRadius: '10px',
                }}
              />
            </Box>
            <TabPanels height={`calc(100% - 42px)`} overflowY={'auto'}>
              {dimensionProperty?.map((data: any, index: number) => {
                return (
                  <TabPanel key={index} padding={'1rem 0 0 0'}>
                    <Box>
                      <Box>
                        <Text fontSize={'xs'}>Help</Text>
                        <InputField
                          defaultValue={data?.help}
                          onKeyDown={(event: any) => {
                            if (event?.keyCode === 13 && event.target.value.trim().length > 0)
                              handlePropertiesChange(
                                event?.target?.value,
                                data?.id,
                                'help',
                                data?.data_format,
                              );
                          }}
                          placeholder='Enter description here'
                          borderColor='violet.750'
                          borderRadius='0'
                          fontSize={'14px'}
                          height={'40px'}
                          outline={'none'}
                        />
                      </Box>
                      <Box mt={4}>
                        <Text fontSize={'xs'}>Type</Text>
                        <Select
                          mt={2}
                          width='100%'
                          borderRadius={''}
                          borderColor={'violet.400'}
                          outline={'none'}
                          _focus={{ borderColor: 'violet.400', boxShadow: 'none' }}
                          fontSize={'14px'}
                          height={'40px'}
                          color='black.900'
                          value={data?.type}
                          onChange={(event: any) =>
                            handlePropertiesChange(
                              event?.target?.value,
                              data?.id,
                              'type',
                              data?.data_format,
                            )
                          }
                        >
                          <option value={'number'}>Number</option>
                          <option value={'dimension'}>Dimension</option>
                          <option value={'text'}>Text</option>
                        </Select>
                      </Box>
                      {(data?.type === 'dimension' || data?.type === 'Dimension') && (
                        <Box mt={4}>
                          <Text fontSize={'xs'}>Connected Dimension</Text>
                          <Select
                            mt={2}
                            width='100%'
                            borderRadius={''}
                            borderColor={'violet.400'}
                            outline={'none'}
                            _focus={{ borderColor: 'violet.400', boxShadow: 'none' }}
                            fontSize={'14px'}
                            height={'40px'}
                            color='black.900'
                            value={JSON.parse(data?.data_format)?.dimension}
                            onChange={(event: any) =>
                              handlePropertiesChange(
                                JSON.stringify({ dimension: event.target.value }),
                                data?.id,
                                'data_format',
                                data?.data_format,
                              )
                            }
                          >
                            <option value={''}>Select data format</option>
                            {builderDimension?.map((data_: any, index_: number) => (
                              <option key={index_} value={data_?.id}>
                                {data_?.name}
                              </option>
                            ))}
                          </Select>
                        </Box>
                      )}
                      <Box mt={4}>
                        <Text fontSize={'xs'}>Default Value</Text>
                        <InputField
                          mt={2}
                          defaultValue={data?.default_value}
                          onKeyDown={(event: any) => {
                            if (event?.keyCode === 13 && event.target.value.trim().length > 0)
                              handlePropertiesChange(
                                event?.target?.value,
                                data?.id,
                                'default_value',
                                data?.data_format,
                              );
                          }}
                          placeholder='Enter default value'
                          borderColor='violet.750'
                          borderRadius='0'
                          fontSize={'14px'}
                          height={'40px'}
                          outline={'none'}
                        />
                      </Box>
                    </Box>
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        ) : (
          <Box height={'100%'}>
            <p>NA</p>
          </Box>
        )}
      </Flex>
    </Flex>
  );
};
export default BuilderPropertiesComponent;
