import { Flex } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { getFormValues } from 'redux/DataImportSlice';
import { fetchBlock } from 'redux/PlanPageSlice';

import DataStepsFourth from './DataStepsFourth';
import DataStepsOne from './DataStepsOne';
import DataStepsThree from './DataStepsThree';
import DataStepsTwo from './DataStepsTwo';
import DataStepsFive from './DataStepsFive';
import SelectDimesnionColumn from './SelectDimesnionColumn';

interface Properties {
  setHelpModalSeleceted: any;
}
const DataStepsForm = ({ setHelpModalSeleceted }: Properties) => {
  const dispatch: any = useDispatch();
  const getFormValue = useSelector(getFormValues);
  const activeActionStep = useSelector((state: any) => state.dataImportSlice.actionSteps);
  const blockId = getFormValue?.blockId;

  useEffect(() => {
    if (blockId) {
      dispatch(fetchBlock(blockId));
    }
  }, [blockId]);

  return (
    <Flex w={'full'} align={'center'}>
      {getFormValue?.dataType === '2' && activeActionStep === 2 && (
        <DataStepsOne setHelpModalSeleceted={setHelpModalSeleceted} />
      )}

      {getFormValue?.dataType === '2' &&
        getFormValue?.dataPeriod === '1' &&
        activeActionStep === 3 && <DataStepsThree />}

      {getFormValue?.dataType === '2' &&
        getFormValue?.dataPeriod === '2' &&
        activeActionStep === 3 && <DataStepsTwo setHelpModalSeleceted={setHelpModalSeleceted} />}

      {getFormValue?.dataType === '2' &&
        getFormValue?.dataPeriod === '2' &&
        activeActionStep === 4 && <DataStepsThree />}

      {getFormValue?.dataType === '2' &&
        getFormValue?.dataPeriod === '1' &&
        activeActionStep === 4 &&
        getFormValue?.importindicatroType === '1' && (
          <SelectDimesnionColumn getFormValue={getFormValue} />
        )}
      {getFormValue?.dataType === '2' &&
        getFormValue?.dataPeriod === '2' &&
        activeActionStep === 5 &&
        getFormValue?.importindicatroType === '1' && (
          <SelectDimesnionColumn getFormValue={getFormValue} />
        )}

      {getFormValue?.dataType === '2' &&
        getFormValue?.dataPeriod === '1' &&
        getFormValue?.importindicatroType === '2' &&
        activeActionStep === 4 && <DataStepsFourth />}

      {getFormValue?.dataType === '2' &&
        getFormValue?.dataPeriod === '2' &&
        getFormValue?.importindicatroType === '2' &&
        activeActionStep === 5 && <DataStepsFourth />}

      {getFormValue?.dataType === '2' &&
        getFormValue?.importindicatroType === '2' &&
        getFormValue?.dataPeriod === '2' &&
        activeActionStep === 6 && <DataStepsFive />}

      {getFormValue?.dataType === '2' &&
        getFormValue?.dataPeriod === '1' &&
        getFormValue?.importindicatroType === '2' &&
        activeActionStep === 5 && <DataStepsFive />}
      {getFormValue?.dataType === '2' &&
        getFormValue?.dataPeriod === '1' &&
        getFormValue?.importindicatroType === '2' &&
        activeActionStep === 6 && <SelectDimesnionColumn getFormValue={getFormValue} />}

      {getFormValue?.multipleIndicatrosType === 'row' &&
        getFormValue?.importindicatroType === '2' &&
        activeActionStep === 7 && <SelectDimesnionColumn getFormValue={getFormValue} />}
    </Flex>
  );
};

export default DataStepsForm;
