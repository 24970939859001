/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Text,
  Button,
  Box,
  Flex,
  Menu,
  useToast,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { GoChevronDown } from 'react-icons/go';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';

import {
  handleComparisionTimeChange,
  getDashboardChartId,
  getKpiChartData,
  updateChartFilter,
  fetchDashboardChart,
  getWidgetData,
  addChartFilter,
} from 'redux/DashboardSlice';
import DatePickerInputButton from 'pages/ModelOverviewPage/DatePickerWithButton';
import { getBaseScenario } from 'redux/ScenarioSlice';
import CustomToast from 'components/CustomToast';
import {
  formateDate,
  getPreviousMonth,
  totalMonthsGap,
  priorYear,
  getDate,
  convertDate,
} from 'utils/ConvertDate';
import { AppDispatch } from 'utils/GlobalHelpers';
import RightArrowIconComponent from 'components/Icons/RightArrowIcon';
import { getTimescaleDate } from 'redux/ModelsSlice';

const DashboardModalComparisons = ({ chartFilter, isEditKpi }: any) => {
  const dispatch: AppDispatch = useDispatch();
  const baseScenario = useSelector(getBaseScenario);
  const getTimeDate = useSelector(getTimescaleDate);
  const kpiChartData = useSelector(getKpiChartData);
  const startDateObject = new Date(baseScenario?.start_date);
  const chartId = useSelector(getDashboardChartId);
  const widgetData = useSelector(getWidgetData);
  const toast = useToast();

  const [startIn, setStartIn] = useState<Date | any>(new Date());
  const [forcastStartDate, setForcastStartDate] = useState<Date | any>(new Date());
  const [endIn, setEndIn] = useState<Date | any>(new Date());
  const [timescale, setTimescale] = useState('M');

  const setTimeAndDispatch = (newComparisionTime: any) => {
    dispatch(handleComparisionTimeChange(newComparisionTime));
  };

  const conparisionType = useMemo(() => {
    if (isEditKpi && chartFilter) {
      let timeStartDate = '';
      let timeEndDate = '';
      let comparisionStartDate = '';
      let comparisionEndDate: any = '';
      const letestActuals = getPreviousMonth(new Date(getTimeDate?.startDate));
      chartFilter?.chart_filters?.forEach((item: any) => {
        if (item.name === 'Time' && item.filter_type === 'primary') {
          timeStartDate = item.value;
          timeEndDate = item.second_value;
        }
        if (item.name === 'Time' && item.filter_type === 'comparision') {
          comparisionStartDate = item.value;
          comparisionEndDate = item?.second_value;
        }
      });
      const timeDate: any = getDate(timeStartDate);
      const comparisionDate: any = getDate(comparisionStartDate);
      const conparisionEndValue: any = getDate(comparisionEndDate);
      setStartIn(new Date(comparisionDate));
      if (totalMonthsGap(timeDate, comparisionDate) === -12 && !conparisionEndValue) {
        setTimeAndDispatch({
          ...widgetData.time,
          startDate: formateDate(new Date(comparisionDate)),
          detail: 2,
        });
      }
      if (new Date(comparisionDate).getTime() === new Date(letestActuals).getTime()) {
        setTimeAndDispatch({
          ...widgetData.time,
          startDate: formateDate(new Date(comparisionDate)),
          detail: 3,
        });
      }
      if (conparisionEndValue) {
        setTimeAndDispatch({
          ...widgetData.time,
          startDate: formateDate(new Date(comparisionDate)),
          endDate: formateDate(new Date(conparisionEndValue)),
          detail: 1,
        });
        setEndIn(new Date(conparisionEndValue));
      } else if (
        new Date(comparisionDate).getTime() !== new Date(letestActuals).getTime() &&
        comparisionDate !== null
      ) {
        setTimeAndDispatch({
          ...widgetData.time,
          startDate: formateDate(new Date(comparisionDate)),
          detail: 2,
        });
      }
    }
  }, [chartFilter?.chart_filters]);

  const comparisionTimeFilterId = useMemo(() => {
    if (kpiChartData) {
      const chartDataId = kpiChartData?.chart_filters
        ?.map((item: any) => {
          if (item.name === 'Time' && item.filter_type === 'comparision') {
            return item.id;
          }
        })
        .filter((item: any) => item !== undefined);
      return chartDataId[0];
    }
    return null;
  }, [kpiChartData]);

  const updateCompareFilter = (payload: any) => {
    if (comparisionTimeFilterId) {
      dispatch(updateChartFilter({ ...payload, id: comparisionTimeFilterId })).then(() => {
        dispatch(fetchDashboardChart(chartId));
      });
    } else {
      dispatch(addChartFilter(payload)).then(() => {
        dispatch(fetchDashboardChart(chartId));
      });
    }
  };

  useEffect(() => {
    const formattedStartDate = formateDate(getTimeDate?.startDate);
    setForcastStartDate(new Date(getTimeDate?.startDate));
    if (!isEditKpi) {
      setStartIn(new Date(getTimeDate?.startDate));
    }
    setEndIn(new Date(getTimeDate?.endDate));
    // if (chartFilter && !isEditKpi) {
    //   setTimeAndDispatch({
    //     ...widgetData.comparisionTime,
    //     startDate: formattedStartDate,
    //     detail: 0,
    //   });
    // }
    if (
      widgetData.comparisionTime.detail !== 0 &&
      widgetData.comparisionTime.detail === 1 &&
      !isEditKpi
    ) {
      setTimeAndDispatch({ ...widgetData.time, startDate: formattedStartDate });
      setTimeAndDispatch({ ...widgetData.comparisionTime, startDate: formattedStartDate });
    } else if (widgetData.comparisionTime.detail === 0) {
      setTimeAndDispatch({ ...widgetData.time, startDate: formattedStartDate, detail: 0 });
    }
  }, [getTimeDate, widgetData.comparisionTime.detail]);

  const letestActuals = useMemo(() => {
    startDateObject.setMonth(startDateObject.getMonth() - 1);
    const formatLeteastActuals = formateDate(startDateObject);
    return formatLeteastActuals;
  }, [startDateObject]);

  const handleTime = (timeDetails: any) => {
    const timeStartDate = widgetData?.time?.startDate;
    const totalMonths = totalMonthsGap(forcastStartDate, new Date(timeStartDate));
    const oneYearPrior = priorYear(new Date(timeStartDate));

    // move to below condition when add the error of prior year
    setTimeAndDispatch({ ...widgetData.comparisionTime, detail: timeDetails });
    if (timeDetails === 1 && totalMonths !== null && totalMonths >= 12) {
      if (chartId) {
        const payload = {
          name: 'Time',
          operator: 'between',
          value: convertDate(formateDate(oneYearPrior)),
          second_value: convertDate(formateDate(getPreviousMonth(new Date(timeStartDate)))),
          chart_id: chartId,
          filter_type: 'comparision',
        };
        updateCompareFilter(payload);
      }
    } else if (timeDetails === 1 && totalMonths !== null && totalMonths < 12) {
      setTimeAndDispatch({ ...widgetData.comparisionTime, detail: 0 });
      toast({
        duration: 2500,
        render: () => <CustomToast title={'Please select the correct Time date'} status='error' />,
      });
    } else if (timeDetails === 3) {
      setTimeAndDispatch({
        ...widgetData.comparisionTime,
        startDate: letestActuals,
        detail: timeDetails,
      });
      if (chartId) {
        const payload = {
          name: 'Time',
          operator: 'eq',
          value: convertDate(letestActuals),
          chart_id: chartId,
          filter_type: 'comparision',
        };
        updateCompareFilter(payload);
      }
    } else if (timeDetails === 0) {
      setTimeAndDispatch({ ...widgetData.comparisionTime, detail: 0 });
    }
  };

  const onSelectStartDate = async (date: Date) => {
    await setStartIn(date);
    const formatStartDate = formateDate(date);
    await setTimeAndDispatch({ ...widgetData.comparisionTime, startDate: formatStartDate });
    if (chartId) {
      const payload = {
        name: 'Time',
        operator: 'eq',
        value: convertDate(formatStartDate),
        chart_id: chartId,
        filter_type: 'comparision',
      };
      await updateCompareFilter(payload);
    }
  };

  const onSelectEndDate = async (date: Date) => {
    const formatEndDate = formateDate(date);
    await setEndIn(date);
    await setTimeAndDispatch({ ...widgetData.comparisionTime, endDate: formatEndDate });
  };

  return (
    <>
      <Flex flexDir={'column'} w={'100%'}>
        <Text color={'black.900'} fontWeight={'600'} mb={2}>
          Comparisions
        </Text>
        <Menu variant={'primary'}>
          {({ isOpen }) => (
            <>
              <MenuButton width={'100%'}>
                <Flex
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  backgroundColor={'#FAFAFA'}
                  border={'1px #D9D9D9 solid'}
                  padding={'.5rem .8rem'}
                  borderRadius={'6px'}
                  color={'black.600'}
                  fontSize={'2xs'}
                >
                  <Text>
                    {widgetData.comparisionTime.detail === 1
                      ? 'vs Prior Year'
                      : widgetData.comparisionTime.detail === 2
                      ? 'vs Single month'
                      : widgetData.comparisionTime.detail === 3
                      ? 'Latest actuals'
                      : 'No comparision'}
                  </Text>
                  <GoChevronDown size={18} />
                </Flex>
              </MenuButton>
              {isOpen && (
                <MenuList>
                  <MenuItem onClick={() => handleTime(0)}>No comparision</MenuItem>
                  <MenuItem onClick={() => handleTime(1)}>vs Prior Year</MenuItem>
                  <MenuItem onClick={() => handleTime(2)}>vs Single month</MenuItem>
                  <MenuItem onClick={() => handleTime(3)}>vs Latest actuals</MenuItem>
                </MenuList>
              )}
            </>
          )}
        </Menu>
        {widgetData.comparisionTime.detail === 8 ? ( // 8 is for not to display custom date range
          <Flex mt={3} alignItems='center'>
            <DatePickerInputButton
              clearSection={false}
              selected={startIn}
              onChange={onSelectStartDate}
              timescale={timescale}
              styles={{ borderColor: '#D9D9D9', backgroundColor: '#FAFAFA', color: '#787878' }}
            />
            <RightArrowIconComponent color='#787878' style={{ margin: '0 1rem' }} />
            <DatePickerInputButton
              clearSection={false}
              selected={endIn}
              onChange={onSelectEndDate}
              timescale={timescale}
              styles={{ borderColor: '#D9D9D9', backgroundColor: '#FAFAFA', color: '#787878' }}
            />
          </Flex>
        ) : widgetData.comparisionTime.detail === 2 ? (
          <Box mt={3}>
            <DatePickerInputButton
              clearSection={false}
              selected={startIn}
              onChange={onSelectStartDate}
              timescale={timescale}
              styles={{ borderColor: '#D9D9D9', backgroundColor: '#FAFAFA', color: '#787878' }}
            />
          </Box>
        ) : (
          <></>
        )}
      </Flex>
    </>
  );
};

export default DashboardModalComparisons;
