import { useRef, useEffect } from 'react';

function useDocumentTitle(title: string, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = `Blox ${title ? '-' : ''} ${title || ''}`;
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        document.title = defaultTitle.current;
      }
    },
    [defaultTitle],
  );
}

export default useDocumentTitle;
