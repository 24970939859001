import * as yup from 'yup';

import * as MESSAGES from '../messages';

export const ActionSteponeSchema = yup.object().shape({
  name: yup.string().trim().required(MESSAGES.REQUIRE_MESSAGE),
});

export const ActionSteptwoSchema = yup.object().shape({
  selectedDataType: yup
    .string()
    .oneOf(['1', '2'], 'Please select atleast one')
    .required(MESSAGES.REQUIRE_MESSAGE),
  selecetedDimension: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
  selectedColumnOption: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
});
export const ActionSteptwoSchemaDataType = yup.object().shape({
  selectedDataType: yup
    .string()
    .oneOf(['1', '2'], 'Please select atleast one')
    .required(MESSAGES.REQUIRE_MESSAGE),
  selectedBlock: yup.string().required(MESSAGES.REQUIRE_MESSAGE),
});

export const ActionDataStepTwoSchema = yup.object().shape({
  selectedDataPeriod: yup
    .string()
    .oneOf(['1', '2'], 'Please select atleast one')
    .required(MESSAGES.REQUIRE_MESSAGE),
});
export const ActionDataStepTwoSchemaTimePeriod = yup.object().shape({
  selectedTimePeriod: yup
    .string()
    .oneOf(['row', 'column'], 'Please select atleast one')
    .required(MESSAGES.REQUIRE_MESSAGE),
});

export const ActionStepthreeSchema = yup.object().shape({
  name: yup.string().trim().required(MESSAGES.REQUIRE_MESSAGE),
});
