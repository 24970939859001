import { Box, Button, Text, useToast, VStack } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';

import Checkbox from 'components/Checkbox';
import CustomToast from 'components/CustomToast';
import FormField from 'components/FormField';
// import { useAuthContext } from 'context/authContext';
import apiService from 'services';
import { getUserData } from 'redux/UserSlice';
import { SingupSchema } from 'validation/schemas/AuthenticationSchema';

interface Properties {
  onClose: () => void;
  successModal: any;
}

const LatestNewAuthForm = ({ onClose, successModal }: Properties) => {
  const queryClient = useQueryClient();
  const toast = useToast();
  const user = useSelector(getUserData);
  // const { user } = useAuthContext();

  const initialValues = {
    firstName: '',
    lastName: '',
    organizationName: '',
    signupEmail: '',
    isChecked: false,
  };

  const { mutate } = useMutation(
    ({ request_body }: any) => apiService.update_user_details({ request_body }),
    {
      onSuccess: (response: any) => {
        queryClient.invalidateQueries(['user']);
        successModal({ status: true, name: response?.data?.username });
        toast({
          duration: 2500,
          render: () => <CustomToast title='Updated successfully' status='success' />,
        });
        onClose();
      },
      onError: (response: any) => {
        toast({
          duration: 2500,
          render: () => (
            <CustomToast
              title={response?.response?.data?.message || 'Something went wrong..'}
              status='error'
            />
          ),
        });
      },
    },
  );

  const onSubmitForm = (form: any) => {
    mutate({
      request_body: {
        firstname: form?.firstName,
        lastname: form?.lastName,
        username: form?.signupEmail,
        organization: form?.organizationName,
        set_new_password: !user?.is_anonymous,
      },
    });
  };

  return (
    <Box>
      <Formik initialValues={initialValues} onSubmit={onSubmitForm} validationSchema={SingupSchema}>
        {({ errors, touched, values, setFieldValue }) => {
          return (
            <Form>
              <VStack spacing='5' mb='2' alignItems={'flex-start'} width={'100%'}>
                <FormField
                  label='First name'
                  name='firstName'
                  type='text'
                  labelStyles={{
                    fontSize: '14px',
                    color: 'black',
                  }}
                  placeholder='First name'
                  error={errors.firstName}
                  touched={touched.firstName}
                />
                <FormField
                  label='Last Name'
                  name='lastName'
                  type='text'
                  labelStyles={{
                    fontSize: '14px',
                    color: 'black',
                  }}
                  placeholder='Last name'
                  error={errors.lastName}
                  touched={touched.lastName}
                />
                <FormField
                  label='Organization Name'
                  name='organizationName'
                  type='text'
                  labelStyles={{
                    fontSize: '14px',
                    color: 'black',
                  }}
                  placeholder='Organization name'
                  error={errors.organizationName}
                  touched={touched.organizationName}
                />
                <FormField
                  label='Work Email'
                  name='signupEmail'
                  type='email'
                  labelStyles={{
                    fontSize: '14px',
                    color: 'black',
                  }}
                  placeholder='name@email.com'
                  error={errors.signupEmail}
                  touched={touched.signupEmail}
                />
                <Checkbox
                  isChecked={values?.isChecked}
                  onChange={(event) => {
                    setFieldValue('isChecked', event.target.checked);
                  }}
                  label={
                    <Text
                      variant='secondary'
                      fontFamily='regular_roboto'
                      fontSize='3xs'
                      color={errors.isChecked && touched.isChecked ? 'red' : 'black'}
                    >
                      I have read and agree to the Blox
                      <a
                        href='https://www.bloxapp.io/terms-of-use'
                        target='_blank'
                        rel='noreferrer'
                        style={{ textDecoration: 'underline', margin: '0 .2rem' }}
                      >
                        Terms of Use
                      </a>
                      and
                      <a
                        href='https://www.bloxapp.io/privacy-policy'
                        target='_blank'
                        rel='noreferrer'
                        style={{ textDecoration: 'underline', marginLeft: '.2rem' }}
                      >
                        Privacy Policy
                      </a>
                    </Text>
                  }
                />

                <Button
                  type='submit'
                  w='full'
                  h='12'
                  borderRadius='xs'
                  fontSize='sm'
                  bgColor='green.900'
                >
                  Sign In
                </Button>
              </VStack>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default LatestNewAuthForm;
