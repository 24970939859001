import {
  Text,
  Menu,
  Button,
  Tooltip,
  MenuList,
  MenuButton,
  MenuOptionGroup,
  MenuItemOption,
} from '@chakra-ui/react';

import { capitalizeFirstLetter } from 'utils/GlobalHelpers';

interface Properties {
  payloadKey: string;
  buttonName: string;
  leftIcon?: string;
  menuItems: { name: string; value: string }[];
  rightIcon?: React.ReactNode;
  handleChanges: (dataType: string, data: any) => void;
  mr?: string;
}

const PlannerMenu = ({
  payloadKey,
  mr,
  buttonName,
  rightIcon,
  menuItems,
  handleChanges,
}: Properties) => {
  return (
    <Menu variant={'secondary'}>
      <Tooltip placement='top' label={'Roll up type'}>
        <MenuButton
          as={Button}
          rightIcon={rightIcon}
          width='fit-content'
          border='none'
          padding={'0'}
          mr={mr}
        >
          <Text fontWeight='400' variant={'h5'}>
            {capitalizeFirstLetter(buttonName)}
          </Text>
        </MenuButton>
      </Tooltip>
      <MenuList>
        <MenuOptionGroup
          value={buttonName?.toLowerCase()}
          onChange={(role: any) => handleChanges(payloadKey, role)}
        >
          {menuItems.map((data: any, index: number) => {
            return (
              <MenuItemOption key={index} value={data?.value}>
                {data?.name}
              </MenuItemOption>
            );
          })}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};

export default PlannerMenu;
