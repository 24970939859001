interface Properties {
  width?: number;
  height?: number;
  onClick?: () => void;
}

const HideIconComponent = ({ width = 34, height = 27, onClick }: Properties) => {
  return (
    <svg
      cursor={'pointer'}
      onClick={onClick}
      width={width}
      height={height}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.3708 12.0402L8.81638 15.5947L10.4347 17.2419L15.6363 12.0402L10.4347 6.83854L8.81638 8.48573L12.3708 12.0402ZM11.5906 23.5994C9.99157 23.5994 8.48887 23.296 7.08249 22.6891C5.67612 22.0823 4.45277 21.2587 3.41243 20.2184C2.3721 19.178 1.54851 17.9547 0.941647 16.5483C0.334787 15.1419 0.0313568 13.6392 0.0313568 12.0402C0.0313568 10.4412 0.334787 8.93847 0.941647 7.53209C1.54851 6.12572 2.3721 4.90237 3.41243 3.86203C4.45277 2.8217 5.67612 1.99811 7.08249 1.39125C8.48887 0.784387 9.99157 0.480957 11.5906 0.480957C13.1896 0.480957 14.6923 0.784387 16.0987 1.39125C17.5051 1.99811 18.7284 2.8217 19.7688 3.86203C20.8091 4.90237 21.6327 6.12572 22.2395 7.53209C22.8464 8.93847 23.1498 10.4412 23.1498 12.0402C23.1498 13.6392 22.8464 15.1419 22.2395 16.5483C21.6327 17.9547 20.8091 19.178 19.7688 20.2184C18.7284 21.2587 17.5051 22.0823 16.0987 22.6891C14.6923 23.296 13.1896 23.5994 11.5906 23.5994ZM11.5906 21.2876C14.1722 21.2876 16.3588 20.3918 18.1505 18.6001C19.9422 16.8084 20.838 14.6218 20.838 12.0402C20.838 9.45863 19.9422 7.27201 18.1505 5.48033C16.3588 3.68865 14.1722 2.79281 11.5906 2.79281C9.00903 2.79281 6.82241 3.68865 5.03073 5.48033C3.23905 7.27201 2.3432 9.45863 2.3432 12.0402C2.3432 14.6218 3.23905 16.8084 5.03073 18.6001C6.82241 20.3918 9.00903 21.2876 11.5906 21.2876Z'
        fill='#776ED0'
      />
    </svg>
  );
};

export default HideIconComponent;
