import { Box, Text } from '@chakra-ui/react';

import Avatar from 'components/Icons/Avatar';

const AnonymousModalTitle = () => {
  return (
    <Box position='relative'>
      <Text variant='secondary' fontFamily='bold' fontSize='calc(0.5rem + 0.4vw)'>
        Your model is not saved
      </Text>
      <Text variant='grey' fontFamily='bold' fontSize='calc(0.5rem + 0.4vw)'>
        Are you sure you want to log out?
      </Text>
      <Avatar
        styles={{
          position: 'absolute',
          right: '0',
          top: '-2',
          width: { base: 10, md: 12 },
          height: { base: 10, md: 12 },
        }}
      />
    </Box>
  );
};

export default AnonymousModalTitle;
