import { ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import HomeIcon from 'components/Icons/Home';
import { Block } from 'models/response/model.interface';
import { getModels, resetBlock } from 'redux/ModelsSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';
import apiService from 'services';

interface NavigationMenuData {
  blocks: Block[];
  id: number;
  name: string;
}

interface Properties {
  hasBlock: boolean;
}

const BreadCrumbComponent = ({ hasBlock }: Properties) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { modelId, blockId } = useParams();

  const models = useSelector(getModels);
  const baseScenario = useSelector(getBaseScenario);

  const [isTablet] = useMediaQuery('(max-width: 768px)');

  const navigateToHome = () => navigate(`/home`);

  const textStyle = {
    _hover: { textDecoration: 'underline' },
    cursor: 'pointer',
    size: 'Roboto-14',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  };

  const routeToOverviewPage = (model_id: string, block_id?: string) => {
    if (!block_id) {
      navigate(`/model/${model_id}/home`);
    } else {
      const lastUrlIndicator = pathname.split('/').pop();
      if (lastUrlIndicator?.toLocaleLowerCase() === 'drivers') {
        apiService
          .get_block_drivers(Number(blockId), {
            params: {
              scenario_id: Number(baseScenario?.id),
            },
          })
          .then((driversData: any) => {
            if (driversData.data.drivers.length > 0) {
              navigate(`/model/${model_id}/block/${block_id}/${lastUrlIndicator}`);
            } else navigate(`/model/${model_id}/block/${block_id}/plan`);
          });
      } else navigate(`/model/${model_id}/block/${block_id}/${lastUrlIndicator}`);
    }
  };

  const currentModel = useMemo(() => {
    return models?.find((element: any) => modelId === String(element.id));
  }, [models, modelId]);

  const otherModels = useMemo(() => {
    return models.filter((element: any) => element.id !== currentModel?.id);
  }, [currentModel?.id, models]);

  const currentBlock = useMemo(() => {
    return currentModel?.blocks?.find((element: Block) => blockId === String(element.id));
  }, [currentModel?.blocks, blockId]);

  const handleModelChange = (model_Id: number) => {
    dispatch(resetBlock());
    const modelCopy = models?.find((element: NavigationMenuData) => model_Id === element.id);
    if (modelCopy?.blocks && modelCopy?.blocks.length > 0) {
      routeToOverviewPage(String(modelCopy?.id));
    } else {
      routeToOverviewPage(String(modelCopy?.id));
    }
  };

  return (
    <Flex alignItems={'center'} height={'16px'} width={'100%'} zIndex={4} position={'relative'}>
      <Flex alignItems={'center'} justifyContent='space-between'>
        <Text cursor='pointer' onClick={() => navigateToHome()}>
          <HomeIcon height={'12px'} width={'22px'} />
        </Text>
        <Text whiteSpace={'nowrap'} {...textStyle} onClick={() => navigateToHome()}>
          My models
        </Text>
      </Flex>
      <Text mx={1}>/</Text>
      <Text
        {...textStyle}
        maxWidth={currentBlock ? '30%' : '60%'}
        whiteSpace={'nowrap'}
        onClick={() => {
          if (hasBlock) {
            handleModelChange(currentModel?.id);
          }
        }}
      >
        {currentModel?.name}
      </Text>
      {otherModels?.length > 0 && (
        <Menu variant='breadCrumb'>
          {({ isOpen }) => (
            <>
              <MenuButton>
                <ChevronDownIcon color={'white'} height={'16px'} width={'16px'} />
              </MenuButton>
              {isOpen && (
                <MenuList>
                  <Box
                    maxHeight={'50vh'}
                    overflowY='scroll'
                    className='scroll'
                    zIndex={'dropdown'}
                    scrollBehavior={'smooth'}
                  >
                    <MenuOptionGroup type='radio' value={modelId}>
                      {otherModels?.map((otherModel: any) => (
                        <MenuItemOption
                          key={otherModel.id}
                          value={otherModel?.id}
                          onClick={() => handleModelChange(otherModel?.id)}
                          fontSize={isTablet ? 'sm' : 'xs'}
                        >
                          <Text className='text-overflow' width={'200px'} size='Roboto-14'>
                            <abbr style={{ textDecoration: 'none' }} title={otherModel?.name}>
                              {otherModel?.name}
                            </abbr>
                          </Text>
                        </MenuItemOption>
                      ))}
                    </MenuOptionGroup>
                  </Box>
                </MenuList>
              )}
            </>
          )}
        </Menu>
      )}
      {currentModel?.blocks && hasBlock && (
        <>
          <Text mx={1}>/</Text>
          <Text
            {...textStyle}
            maxWidth={isTablet ? '30%' : '35vw'}
            whiteSpace={'nowrap'}
            onClick={() => {
              if (hasBlock) {
                handleModelChange(currentModel?.id);
              }
            }}
          >
            {currentBlock?.name}
          </Text>
          <Menu variant='breadCrumb'>
            {({ isOpen }) => (
              <>
                <MenuButton>
                  <ChevronDownIcon color={'white'} height={'16px'} width={'16px'} />
                </MenuButton>
                {isOpen && currentModel?.blocks?.length > 1 && (
                  <MenuList borderRadius={'10px'}>
                    <Box
                      maxHeight={'50vh'}
                      overflowY='scroll'
                      className='scroll'
                      zIndex={'dropdown'}
                      scrollBehavior={'smooth'}
                    >
                      <MenuOptionGroup type='radio' value={blockId}>
                        {currentModel?.blocks?.map(
                          (block: any) =>
                            block.id !== currentBlock?.id && (
                              <MenuItemOption
                                key={block.id}
                                value={block?.id}
                                onClick={() =>
                                  routeToOverviewPage(String(modelId), String(block?.id))
                                }
                                fontSize={isTablet ? 'sm' : 'xs'}
                              >
                                <Text className='text-overflow' width={'200px'} size='Roboto-14'>
                                  <abbr style={{ textDecoration: 'none' }} title={block?.name}>
                                    {block?.name}
                                  </abbr>
                                </Text>
                              </MenuItemOption>
                            ),
                        )}
                      </MenuOptionGroup>
                    </Box>
                  </MenuList>
                )}
              </>
            )}
          </Menu>
        </>
      )}
    </Flex>
  );
};

export default BreadCrumbComponent;
