import { Flex, Button, Spinner, useMediaQuery } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import {
  clearStepperFormData,
  handleActionStepPrevious,
  setClearSelectedAreasTable,
} from 'redux/DataImportSlice';

interface Properties {
  isSubmitting?: boolean;
  prevButtonName: string;
  nextButtonName: string;
  setactionCreate?: any;
  disableNext?: boolean;
}

const ActionStepFooter = ({
  prevButtonName,
  nextButtonName,
  isSubmitting,
  setactionCreate,
  disableNext = false,
}: Properties) => {
  const dispatch: any = useDispatch();
  const [isLaptop] = useMediaQuery('(max-width: 1200px)');
  const activeActionStep = useSelector((state: any) => state.dataImportSlice.actionSteps);

  return (
    <Flex my={2} color={'black.900'} fontSize='xs' display={'flex'} alignContent={'center'}>
      {activeActionStep !== 0 && (
        <Button
          width={'80px'}
          height={'30px'}
          borderRadius='xs'
          fontSize='xs'
          bgColor='#EFEEFE'
          color='#6562CF'
          fontWeight='400'
          me={'1rem'}
          fontFamily={'Roboto Regular'}
          px={isLaptop ? '8' : '10'}
          py={isLaptop ? '5' : '6'}
          onClick={() => {
            if (prevButtonName === 'CANCEL' || activeActionStep === 0) {
              dispatch(clearStepperFormData());
              setactionCreate(false);
            } else if (activeActionStep === 2) {
              dispatch(setClearSelectedAreasTable());
              dispatch(handleActionStepPrevious());
            } else {
              dispatch(handleActionStepPrevious());
            }
          }}
          textTransform={'capitalize'}
        >
          {prevButtonName}
        </Button>
      )}

      <Button
        _hover={{ _disabled: {} }}
        disabled={isSubmitting || disableNext}
        type='submit'
        borderRadius='xs'
        fontWeight='400'
        fontFamily={'Roboto Regular'}
        width={'80px'}
        px={isLaptop ? '6' : '10'}
        py={isLaptop ? '5' : '6'}
        height={'30px'}
        fontSize='xs'
        color='white'
        bgColor='#6562CF'
        textTransform={'capitalize'}
      >
        {nextButtonName}
        {isSubmitting && <Spinner ml={'4'} />}
      </Button>
    </Flex>
  );
};
export default ActionStepFooter;
