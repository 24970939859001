import { Box } from '@chakra-ui/react';
import './UserManagementShimmer.css';

const UserShimmer = (style: React.CSSProperties) => {
  return (
    <Box width={style.width} minHeight={style.minHeight} height={style.height}>
      <div className='user-shimmer'>
        <div className='user-shimmer-container'>
          <div className='user-shimmer-form-container'>
            <div className='user-shimmer-bg list-content-heading'></div>
            <div className='user-shimmer-form-horizontal-container'>
              <div className='user-shimmer-horizontal-field'>
                <div className='user-shimmer-bg user-shimmer-horizontal-field-label'></div>
                <div className='user-shimmer-bg user-shimmer-horizontal-field-input'></div>
              </div>
              <div className='user-shimmer-horizontal-field'>
                <div className='user-shimmer-bg user-shimmer-horizontal-field-label'></div>
                <div className='user-shimmer-bg user-shimmer-horizontal-field-input'></div>
              </div>
            </div>
            <div className='user-shimmer-form-vertical-container'>
              <div className='user-shimmer-vertical-field'>
                <div className='user-shimmer-bg user-shimmer-vertical-field-label'></div>
                <div className='user-shimmer-bg user-shimmer-vertical-field-input'></div>
              </div>
              <div className='user-shimmer-vertical-field'>
                <div className='user-shimmer-bg user-shimmer-vertical-field-label'></div>
                <div className='user-shimmer-bg user-shimmer-vertical-field-input'></div>
              </div>
            </div>
          </div>
          <div className='user-shimmer-image-container'>
            <div className='user-shimmer-bg user-shimmer-image'></div>
            <div className='user-shimmer-image-buttons-container'>
              <div className='user-shimmer-bg user-shimmer-button'></div>
              <div className='user-shimmer-bg user-shimmer-button'></div>
            </div>
          </div>
        </div>
        <div className='user-shimmer-footer-buttons-container'>
          <div className='user-shimmer-bg user-shimmer-button'></div>
          <div className='user-shimmer-bg user-shimmer-button'></div>
        </div>
      </div>
    </Box>
  );
};
export default UserShimmer;
