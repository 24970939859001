import { Button, Grid, Text } from '@chakra-ui/react';
import { useState } from 'react';

import FormField from 'components/FormField';

interface Properties {
  passwordErrors?: string;
  confirmPasswordError?: string;
  passwordTouched?: boolean;
  confirmPasswordTouched?: boolean;
}

const ChangePassword = ({
  passwordErrors,
  confirmPasswordError,
  passwordTouched,
  confirmPasswordTouched,
}: Properties) => {
  const [isChangePassClicked, setIsChangePassClicked] = useState(false);

  const onToggleChangePassword = () => {
    setIsChangePassClicked((previousState) => !previousState);
  };

  return (
    <>
      <Text variant='secondary' mb='2' fontFamily='regular_roboto' fontSize='2xs'>
        Password
      </Text>
      <Button
        h='8'
        w='auto'
        px='2'
        mb={isChangePassClicked ? '4' : '7'}
        borderWidth='1'
        borderRadius='xs'
        borderColor='black.900'
        fontWeight={400}
        fontSize='2xs'
        textTransform='capitalize'
        color='black.900'
        onClick={onToggleChangePassword}
      >
        Change Password
      </Button>

      {isChangePassClicked && (
        <Grid templateColumns={{ base: '100%', md: '1fr 1fr' }} columnGap='8' rowGap={4} mb='7'>
          <FormField
            label='New Password'
            name='password'
            type='password'
            placeholder='*********'
            error={passwordErrors}
            touched={passwordTouched}
          />
          <FormField
            label='Confirm new Password'
            name='confirmPassword'
            type='password'
            placeholder='*********'
            error={confirmPasswordError}
            touched={confirmPasswordTouched}
          />
        </Grid>
      )}
    </>
  );
};

export default ChangePassword;
