interface Properties {
  width?: number | string;
  height?: number | string;
  color?: string;
  isVerticalIcon?: boolean;
  style?: React.CSSProperties;
}

const InfoIconComponent = ({
  width = 40,
  height = 10,
  color = '#D9D9D9',
  style,
  isVerticalIcon,
}: Properties) => {
  return (
    <svg
      transform={isVerticalIcon ? 'rotate(90)' : ''}
      style={style}
      width={width}
      height={height}
      viewBox='0 0 19 5'
      fill={color}
      cursor={'pointer'}
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='5' cy='2.3938' r='1.3' fill={color} />
      <circle cx='9' cy='2.3938' r='1.3' fill={color} />
      <circle cx='13' cy='2.3938' r='1.3' fill={color} />
    </svg>
  );
};

export default InfoIconComponent;
