import { Box, Button, Text } from '@chakra-ui/react';

import DividerWithText from 'components/DividerWithText';

interface Properties {
  onLogout: () => void;
  onOpenAuthModal: () => void;
  isLogoTriggered?: boolean;
}

const AnonymousModalBody = ({ onLogout, onOpenAuthModal, isLogoTriggered }: Properties) => {
  return (
    <>
      <Box textAlign='center'>
        <Text variant='secondary' mb='2' fontFamily='bold' fontSize='calc(0.5rem + 0.4vw)'>
          Create a free account to save your plan
        </Text>
        <Button
          w='full'
          h='10'
          borderRadius='xs'
          bg='green.900'
          fontSize='calc(0.5rem + 0.4vw)'
          onClick={onOpenAuthModal}
        >
          SAVE YOUR FREE PLAN
        </Button>
      </Box>

      <DividerWithText text='OR' />

      <Box flexGrow={1} textAlign='center'>
        <Text variant='secondary' mb='2' fontFamily='bold' fontSize='calc(0.5rem + 0.4vw)'>
          Leave without saving
        </Text>
        {isLogoTriggered ? (
          <Button
            as='a'
            href='https://www.bloxapp.io/models?beta=true'
            target='_blank'
            w='full'
            h='10'
            borderRadius='xs'
            bg='green.900'
            fontSize='calc(0.5rem + 0.4vw)'
          >
            LOG OUT WITHOUT SAVING
          </Button>
        ) : (
          <Button
            w='full'
            h='10'
            borderRadius='xs'
            bg='green.900'
            fontSize='calc(0.5rem + 0.4vw)'
            onClick={onLogout}
          >
            LOG OUT WITHOUT SAVING
          </Button>
        )}
      </Box>
    </>
  );
};

export default AnonymousModalBody;
