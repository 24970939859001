import { Box, Button, HStack, useMediaQuery, useToast, VStack } from '@chakra-ui/react';
import { Formik, Form } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import { isExpired } from 'react-jwt';
import { useNavigate } from 'react-router-dom';

import { useAuthContext } from 'context/authContext';
import {
  getAuthenticationToken,
  getUsersData,
  getUsersPassword,
  SetAuthPageType,
  SetUserPassword,
} from 'redux/AuthSlice';
import FormField from 'components/FormField';
import CustomToast from 'components/CustomToast';
import { UserDetailsFormSchema } from 'validation/schemas/AuthenticationSchema';

const UserDetailsForm = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch: any = useDispatch();
  const { onAuthSuccess } = useAuthContext();

  const userDetails = useSelector(getUsersData);
  const usersPassword = useSelector(getUsersPassword);
  const authenticationToken = useSelector(getAuthenticationToken);

  const [isSmallLaptop] = useMediaQuery('(max-width: 768px)');
  const [isMobile] = useMediaQuery('(max-width: 576px)');

  const initialValues = {
    firstname: `${userDetails?.firstname}`,
    lastname: `${userDetails?.lastname}`,
    job_role: '',
    company: `${userDetails?.organization}`,
    industry: `${userDetails?.industry}`,
    password: '',
    token: '',
  };

  const onSubmitForm = (form: any) => {
    form.password = usersPassword;
    form.token = authenticationToken;
    dispatch(SetUserPassword(form)).then((response: any) => {
      if (response.type.endsWith('/rejected')) {
        toast({
          duration: 2500,
          render: () => (
            <CustomToast
              title={response?.payload?.message || 'Something went wrong..'}
              status='error'
            />
          ),
        });
      } else {
        onAuthSuccess(authenticationToken, response.payload.expiry);
        localStorage.removeItem('BLOX_PASSWORD_TOKEN');
        queryClient.invalidateQueries(['user']);
        const modelToken = window.localStorage.getItem('model-token');
        if (modelToken) {
          const isLinkExpired = isExpired(modelToken);
          if (isLinkExpired) {
            navigate('/home');
          } else {
            navigate(`/model/${modelToken}`);
            toast({
              duration: 2500,
              render: () => <CustomToast title={'Successfully login'} status='success' />,
            });
          }

          localStorage.removeItem('model-token');
        } else {
          navigate('/home');
        }
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmitForm}
      validationSchema={UserDetailsFormSchema}
    >
      {({ errors, touched }) => (
        <Form>
          <VStack spacing='5' mb='2' alignItems={'flex-start'} width={'100%'}>
            {isSmallLaptop ? (
              <>
                <FormField
                  label='First Name:'
                  name='firstname'
                  type='text'
                  labelStyles={{
                    fontSize: '14px',
                    color: '#121826',
                  }}
                  inputStyles={{
                    maxWidth: '300px',
                    boxShadow: '0px 0px 0px 1px #7A849E',
                  }}
                  placeholder='Enter first name'
                  error={errors.firstname}
                  touched={touched.firstname}
                />
                <FormField
                  label='Last Name:'
                  name='lastname'
                  type='text'
                  labelStyles={{
                    fontSize: '14px',
                    color: '#121826',
                  }}
                  inputStyles={{
                    maxWidth: '300px',
                    boxShadow: '0px 0px 0px 1px #7A849E',
                  }}
                  styles={{
                    marginTop: '.7rem',
                  }}
                  placeholder='Enter last name'
                  error={errors.lastname}
                  touched={touched.lastname}
                ></FormField>
              </>
            ) : (
              <HStack alignItems={'flex-start'} spacing='5' mb='2' width={'100%'}>
                <FormField
                  label='First Name:'
                  name='firstname'
                  type='text'
                  labelStyles={{
                    fontSize: '14px',
                    color: '#121826',
                  }}
                  inputStyles={{
                    maxWidth: '300px',
                    boxShadow: '0px 0px 0px 1px #7A849E',
                  }}
                  placeholder='First Name'
                  error={errors.firstname}
                  touched={touched.firstname}
                />
                <FormField
                  label='Last Name:'
                  name='lastname'
                  type='text'
                  labelStyles={{
                    fontSize: '14px',
                    color: '#121826',
                  }}
                  inputStyles={{
                    maxWidth: '300px',
                    boxShadow: '0px 0px 0px 1px #7A849E',
                  }}
                  placeholder='Last Name'
                  error={errors.lastname}
                  touched={touched.lastname}
                ></FormField>
                <Box minWidth={'170px'}></Box>
              </HStack>
            )}
            {isSmallLaptop ? (
              <>
                <FormField
                  label='Job Title:'
                  name='job_role'
                  type='text'
                  labelStyles={{
                    fontSize: '14px',
                    color: '#121826',
                  }}
                  inputStyles={{
                    maxWidth: '300px',
                    boxShadow: '0px 0px 0px 1px #7A849E',
                  }}
                  placeholder='Job title'
                  error={errors.job_role}
                  touched={touched.job_role}
                />
                <FormField
                  label='Organization Name:'
                  name='company'
                  type='text'
                  labelStyles={{
                    fontSize: '14px',
                    color: '#121826',
                  }}
                  inputStyles={{
                    maxWidth: '300px',
                    boxShadow: '0px 0px 0px 1px #7A849E',
                  }}
                  styles={{
                    marginTop: '.7rem',
                  }}
                  placeholder='Enter your organization name'
                  error={errors.company}
                  touched={touched.company}
                ></FormField>
              </>
            ) : (
              <HStack alignItems={'flex-start'} spacing='5' mb='2' width={'100%'}>
                <FormField
                  label='Job Title:'
                  name='job_role'
                  type='text'
                  labelStyles={{
                    fontSize: '14px',
                    color: '#121826',
                  }}
                  inputStyles={{
                    maxWidth: '300px',
                    boxShadow: '0px 0px 0px 1px #7A849E',
                  }}
                  placeholder='Job title'
                  error={errors.job_role}
                  touched={touched.job_role}
                />
                <FormField
                  label='Organization Name:'
                  name='company'
                  type='text'
                  labelStyles={{
                    fontSize: '14px',
                    color: '#121826',
                  }}
                  inputStyles={{
                    maxWidth: '300px',
                    boxShadow: '0px 0px 0px 1px #7A849E',
                  }}
                  placeholder='Enter your organization name'
                  error={errors.company}
                  touched={touched.company}
                ></FormField>
                <Box minWidth={'170px'}></Box>
              </HStack>
            )}
            {isSmallLaptop ? (
              <>
                <FormField
                  label='Industry:'
                  name='industry'
                  type='text'
                  labelStyles={{
                    fontSize: '14px',
                    color: '#121826',
                  }}
                  inputStyles={{
                    maxWidth: '300px',
                    boxShadow: '0px 0px 0px 1px #7A849E',
                  }}
                  styles={{
                    marginTop: '.7rem',
                  }}
                  placeholder='Enter your industry name'
                  error={errors.industry}
                  touched={touched.industry}
                ></FormField>
              </>
            ) : (
              <HStack alignItems={'flex-start'} spacing='5' mb='2' width={'100%'}>
                <FormField
                  label='Industry:'
                  name='industry'
                  type='text'
                  labelStyles={{
                    fontSize: '14px',
                    color: '#121826',
                  }}
                  inputStyles={{
                    maxWidth: '357px',
                    boxShadow: '0px 0px 0px 1px #7A849E',
                  }}
                  placeholder='Enter your industry name'
                  error={errors.industry}
                  touched={touched.industry}
                ></FormField>
                <Box minWidth={'170px'}></Box>
              </HStack>
            )}
            {!isMobile && (
              <Box display={'flex'} flexDirection={'column'}>
                <Button
                  type='submit'
                  w='150px'
                  h='9'
                  borderRadius='xs'
                  fontSize='xs'
                  bgColor='green.900'
                >
                  CONFIRM
                </Button>
                <small
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    marginTop: '.7rem',
                    cursor: 'pointer',
                    color: '#939393',
                    textDecoration: 'underline',
                  }}
                  onClick={() => {
                    navigate('/');
                    dispatch(SetAuthPageType(1));
                  }}
                >
                  Back to login
                </small>
              </Box>
            )}
          </VStack>
          {isMobile && (
            <Box display={'grid'}>
              <Button
                type='submit'
                w='100%'
                h='9'
                mt={4}
                borderRadius='xs'
                fontSize='xs'
                bgColor='green.900'
              >
                CONFIRM
              </Button>
              <small
                style={{
                  width: '100%',
                  cursor: 'pointer',
                  color: '#939393',
                  textAlign: 'center',
                  margin: '.7rem 0 0 0',
                  textDecoration: 'underline',
                }}
                onClick={() => {
                  navigate('/');
                  dispatch(SetAuthPageType(1));
                }}
              >
                Back to login
              </small>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default UserDetailsForm;
