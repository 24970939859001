import { AddIcon, SmallAddIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Text, useMediaQuery } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  FetchUserTeamList,
  getUserData,
  getUserSubscriptionLimits,
  SetTabs,
} from 'redux/UserSlice';
import { hasUserPermission } from 'utils/UserPermissions';
import { getTeamCount, hasPermission } from 'utils/TeamCount';

import TeamTableComponent from './TeamsTable';
import UserHeading from './UserHeading';
import UserShimmer from './UserManagementShimmer';
import UserUpgradeModal from './UserUpgradeModal';

interface Properties {
  type: number;
}

const UserTeamsComponent = ({ type }: Properties) => {
  const dispatch: any = useDispatch();

  const [loader, setLoader] = useState(false);
  const [inviteTeamModal, setInviteTeamModal] = useState(false);
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  const userData = useSelector(getUserData);
  const userSubscriptionLimits = useSelector(getUserSubscriptionLimits);

  const userPermission = hasUserPermission(userData?.role, ['edit']);

  useEffect(() => {
    if (
      !(userData?.role !== 'planner' && userSubscriptionLimits.current_subscription_name === 'Free')
    ) {
      setLoader(true);
      const getUsersTeamList = async () => {
        await dispatch(FetchUserTeamList());
        setLoader(false);
      };
      getUsersTeamList();
    }
  }, []);

  if (loader) return <UserShimmer />;

  return (
    <React.Fragment>
      {inviteTeamModal ? (
        <UserUpgradeModal
          heading={`You’ve hit your limit on team members`}
          subHeading1={'Upgrade your Blox subscription to invite'}
          subHeading2={'additional team members'}
          handleCancelButton={() => setInviteTeamModal(false)}
        />
      ) : userData?.role !== 'planner' &&
        userSubscriptionLimits.current_subscription_name === 'Free' ? (
        <UserUpgradeModal
          heading={`Upgrade you Blox subscription to unlock teams...`}
          subHeading1={'Invite your team members to share models,'}
          subHeading2={'engage with insights and collaborate'}
          handleCancelButton={() => setInviteTeamModal(false)}
        />
      ) : (
        <Flex width={'100%'} height={'100%'} flexDir='column'>
          <Flex
            width={'100%'}
            alignItems={isMobile ? 'left' : 'center'}
            justifyContent='space-between'
            flexDir={isMobile ? 'column' : 'row'}
          >
            <UserHeading heading={type === 1 ? 'Team' : 'Modify Owner'} />
            {type === 1 && userData?.role !== 'planner' && (
              <Button
                leftIcon={<AddIcon />}
                w='fit-content'
                h={isMobile ? '9' : '12'}
                borderRadius='10px'
                fontSize={isMobile ? '2xs' : 'xs'}
                color='white'
                bgColor='green.500'
                mr='4'
                mb={isMobile ? '4' : ''}
                onClick={() => {
                  if (
                    userSubscriptionLimits.current_subscription_name !== 'Free' &&
                    hasPermission(userSubscriptionLimits)
                  ) {
                    dispatch(SetTabs(8));
                  } else {
                    setInviteTeamModal(true);
                  }
                }}
                _hover={{}}
                disabled={!userPermission}
              >
                Invite Team Members
              </Button>
            )}
          </Flex>
          <Box backgroundColor='white.200' padding='.5rem 1rem' borderRadius='10px'>
            <Text fontSize='2xs' color='grey.100'>
              Team members
            </Text>
            <Text fontSize='xl' color='black' fontWeight='600'>
              {getTeamCount(userSubscriptionLimits)}
            </Text>
          </Box>
          <TeamTableComponent />
          {userData?.role !== 'planner' && (
            <Box>
              <Button
                leftIcon={<SmallAddIcon />}
                w='fit-content'
                padding={'0 1rem'}
                height={10}
                textTransform='none'
                borderRadius='10px'
                fontSize='2xs'
                color='grey.100'
                bgColor='white.200'
                onClick={() => {
                  if (
                    userSubscriptionLimits.current_subscription_name !== 'Free' &&
                    hasPermission(userSubscriptionLimits)
                  ) {
                    dispatch(SetTabs(8));
                  } else {
                    setInviteTeamModal(true);
                  }
                }}
                disabled={!userPermission}
                mt='2'
              >
                Invite Team Members
              </Button>
            </Box>
          )}
        </Flex>
      )}
    </React.Fragment>
  );
};

export default UserTeamsComponent;
