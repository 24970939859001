import { Box, Flex, Text, Tooltip, useMediaQuery } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Container from 'components/Container';
import AuthModal from 'components/AuthModal';
import { HEADER_HEIGHT } from 'projectConstants';
import { FetchModelBlocks, getBlocks, GetUserModels, resetBlock } from 'redux/ModelsSlice';
import BreadCrumbComponent from 'components/BreadCrumb';
import { fetchAllScenarios } from 'redux/ScenarioSlice';
import { handleBuilderModeTab } from 'redux/BuilderModeSlice';
import useDocumentTitle from 'hooks/useDocumentTitle';

import BlockInput, { TimeProperties } from './block/blockInput';
import ModelOverviewTabs from './Tabs';

const ModelOverviewPage = () => {
  const { modelId } = useParams();
  const dispatch: any = useDispatch();

  const model = useSelector(getBlocks);

  const [isTablet] = useMediaQuery('(max-width: 768px)');
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);

  useDocumentTitle(model?.name);

  useEffect(() => {
    dispatch(GetUserModels({}));
  }, []);

  useEffect(() => {
    dispatch(FetchModelBlocks({ modelId: Number(modelId), loader: true }));
    if (modelId) {
      dispatch(fetchAllScenarios(Number(modelId)));
    }
  }, [modelId]);

  useEffect(() => {
    return () => {
      dispatch(handleBuilderModeTab(0));
      dispatch(resetBlock());
    };
  }, []);

  const onToggleAuthModal = () => setIsAuthModalOpen((previousState) => !previousState);

  const formatTimeProperties = (properties: TimeProperties) => {
    return { ...properties, time_granularity: properties?.time_granularity ?? 'M' };
  };

  return (
    <Container padding='0'>
      <>
        <Box py={4} height={`calc(100vh - ${HEADER_HEIGHT})`} overflow='hidden'>
          <Box px={isTablet ? 4 : 7}>
            <BreadCrumbComponent hasBlock={false} />
          </Box>
          <Flex
            flexWrap='wrap'
            justifyContent='space-between'
            alignItems={'center'}
            alignContent='center'
            my={isTablet ? 4 : 7}
            mx={isTablet ? 4 : 7}
            gap={isTablet ? 0 : 2}
            height={isTablet ? 'fit-content' : '28px'}
          >
            <Tooltip label={`${model?.name} overview`}>
              <Text
                maxW={isTablet ? '100%' : 'calc(100% - 460px)'}
                className='text-overflow'
                size={'Comfortaa-28'}
              >
                {model?.name} overview
              </Text>
            </Tooltip>
            <Box width={isTablet ? '100%' : 'fit-content'}>
              {modelId && (
                <BlockInput
                  is_time_granularity_editable={model?.is_time_granularity_editable}
                  modelId={modelId}
                  properties={formatTimeProperties(model?.time_properties)}
                  reporting_currency={model?.reporting_currency}
                />
              )}
            </Box>
          </Flex>
          <ModelOverviewTabs />
        </Box>
        {isAuthModalOpen && <AuthModal isOpen={isAuthModalOpen} onClose={onToggleAuthModal} />}
      </>
    </Container>
  );
};

export default ModelOverviewPage;
