import 'rc-slider/assets/index.css';

import { HStack, Text, VStack } from '@chakra-ui/react';
import Slider from 'rc-slider';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { IndicatorDriver } from 'models/response/driver.interface';
import { InputAdjutment } from 'models/requestBody/getModelOutputs.interface';
import { GetInputAdjustments } from 'redux/driverAdjustmentSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';

interface Properties {
  driver: IndicatorDriver;
  onAdjustInput: (parameter: InputAdjutment) => void;
  isResetClicked: boolean;
  setIsResetClicked: (value: boolean) => void;
}
const SliderComponent = ({
  driver,
  onAdjustInput,
  isResetClicked,
  setIsResetClicked,
}: Properties) => {
  const Value = useSelector(GetInputAdjustments);
  const sliderValue = Value[driver.id] || 0;
  const [currentSliderValue, setCurrentSliderValue] = useState(sliderValue);
  const baseScenario = useSelector(getBaseScenario);

  useEffect(() => {
    setCurrentSliderValue(sliderValue);
  }, [sliderValue]);
  useEffect(() => {
    if (isResetClicked) {
      setCurrentSliderValue(0);
      setIsResetClicked(false);
    }
  }, [isResetClicked]);

  useEffect(() => {
    setCurrentSliderValue(0);
  }, [baseScenario?.id]);

  const setSliderValue = (value: any) => {
    onAdjustInput({ [driver.id]: value });
  };
  return (
    <VStack w='full' spacing='1' width={'90%'}>
      <HStack w='full' justify='space-between'>
        <Text size='modalContent'>{driver.name}</Text>
        <Text
          size='modalContent'
          py='2'
          px='1'
          w='11'
          borderRadius='xs'
          align='center'
          bgColor='violet.50'
        >
          {currentSliderValue}%
        </Text>
      </HStack>
      <Slider
        min={-100}
        max={100}
        startPoint={0}
        value={currentSliderValue}
        onChange={setCurrentSliderValue}
        onAfterChange={setSliderValue}
        trackStyle={{ backgroundColor: 'white', height: 8 }}
        railStyle={{ backgroundColor: '#CDCDCD66', height: 8 }}
        handleStyle={{
          borderColor: '#CDCDCD66',
          height: 16,
          width: 16,
        }}
      />
    </VStack>
  );
};

export default SliderComponent;
