import { Image } from '@chakra-ui/react';

import usericon from 'assets/user_icon.png';

interface Properties {
  width?: number;
  height?: number;
}

const UserBorderIcon = ({ width = 4.75, height = 4.75 }: Properties) => {
  return <Image src={usericon} w={width} h={height} />;
};

export default UserBorderIcon;
