import { Icon, StyleProps } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
  wrapperProperties?: StyleProps;
  onClick?: () => void;
}

const Trash = ({
  width = 4,
  height = 4.5,
  color = 'black.900',
  wrapperProperties,
  onClick,
}: Properties) => {
  return (
    <Icon
      onClick={onClick}
      {...wrapperProperties}
      viewBox='0 0 17 19'
      width={width}
      height={height}
      color={color}
      opacity={'.75'}
    >
      <path
        d='M3.41503 18.4008C2.87893 18.4008 2.42016 18.2101 2.03871 17.8287C1.65662 17.4466 1.46557 16.9875 1.46557 16.4514V3.7799H0.490845V1.83044H5.36449V0.855713H11.2129V1.83044H16.0865V3.7799H15.1118V16.4514C15.1118 16.9875 14.9211 17.4466 14.5396 17.8287C14.1575 18.2101 13.6984 18.4008 13.1623 18.4008H3.41503ZM13.1623 3.7799H3.41503V16.4514H13.1623V3.7799ZM5.36449 14.5019H7.31395V5.72936H5.36449V14.5019ZM9.26341 14.5019H11.2129V5.72936H9.26341V14.5019Z'
        fill='currentColor'
      />
    </Icon>
  );
};

export default Trash;
