import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { Scenario } from 'models/response/scenario.interface';
import apiService from 'services';
import { NewTotalValues } from 'models/response/blockOutput.interface';
import { STATUS } from 'projectConstants';

const initialState: any = {
  baseScenario: null,
  compareScenario: null,
  allScenarios: [],
  scenarioFetchStatus: STATUS.IDLE,

  chartOutput: null,
  indicatorTableOutput: null,
  chartOutputStatus: STATUS.IDLE,
  indicatorTableStatus: STATUS.IDLE,
  blockScenarioMap: {},
  modelId: null,
  blockId: null,
  tableData: null,
  tableHeaderData: null,
  tabsData: null,
  sliderValues: [],
  chartLabels: [],
  expandKey: '',
  tableFilter: null,
  chartFilter: null,
  chartType: '',
  minValue: 0,
  maxValue: 100,
  toggleCompare: false,
  varianceFilterChart: true,
  varianceFilterTable: true,
};

export const fetchCompareAnalysisSectionData = createAsyncThunk(
  'compare_chart_data/fetch',
  async ({ blockId, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.getCompareScenario(blockId, parameters);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchCompareTableData = createAsyncThunk(
  'compare_table_data/fetch',
  async ({ blockId, parameters }: any, thunkAPI) => {
    try {
      const response = await apiService.getCompareScenario(blockId, parameters);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchAllScenarios = createAsyncThunk(
  'scenarios/fetch',
  async (modelId: number | any, thunkAPI) => {
    try {
      const response = await apiService.get_scenarios(modelId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const renameScenario = createAsyncThunk(
  'scenarioRename/fetch',
  async (data: any, thunkAPI) => {
    try {
      const response = await apiService.renameScenario(data?.item_id, data?.requestBody);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);
export const deleteScenarios = createAsyncThunk(
  'scenarioDelete/fetch',
  async (scenarioId: number, thunkAPI) => {
    try {
      const response = await apiService.deleteScenario(scenarioId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);
export const createScenarios = createAsyncThunk(
  'scenarioCreate/fetch',
  async (data: any, thunkAPI) => {
    try {
      const response = await apiService.createScenario(data?.modelId, data?.data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);
export const duplicateScenarios = createAsyncThunk(
  'scenarioDuplicate/fetch',
  async (data: any, thunkAPI) => {
    try {
      const response = await apiService.createDuplicateScenario(data?.scenarioId, data.data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const formatChartData = (state: any, slice: boolean) => {
  if (state.chartOutput) {
    const chartOutput = JSON.parse(JSON.stringify(state.chartOutput));

    if (chartOutput && chartOutput.length > 0) {
      const tableDataArray = [];
      for (let index = 0; index < chartOutput?.length; index += 1) {
        const data = chartOutput[index];
        const maxValue =
          state.maxValue > Object.keys(data.total_values).length
            ? Object.keys(data.total_values).length - 1
            : state.maxValue;

        state.maxValue = maxValue;
        if (data?.dim_item_data && data?.dim_item_data.length > 0) {
          const items: NewTotalValues[] = (data.dim_item_data as NewTotalValues[]).map(
            (item: any) => {
              if (item.value) {
                const itemKeys: any = [];
                (item.value as NewTotalValues[])?.map((subItem: any) => {
                  let isShow = false;
                  if (state.chartFilter === 'Q' && subItem.name[0] === 'Q') {
                    isShow = true;
                  } else if (state.chartFilter === 'Y' && subItem.name.length === 4) {
                    isShow = true;
                  } else if (subItem.name.length === 6 && state.chartFilter === 'M') {
                    isShow = true;
                  }

                  if (isShow) itemKeys.push(subItem);
                  return subItem;
                });
                item.value = slice ? itemKeys.slice(state.minValue, state.maxValue + 1) : itemKeys;
              }

              return item;
            },
          );
          data.dim_item_data = items;
        }

        if (data?.total_values && data?.total_values.length) {
          const valueItems: Array<any> = [];
          (data.total_values as NewTotalValues[]).map((item: any) => {
            let isShow = false;

            if (state.chartFilter === 'Q' && item.name[0] === 'Q') {
              isShow = true;
            } else if (state.chartFilter === 'Y' && item.name.length === 4) {
              isShow = true;
            } else if (item.name.length === 6 && state.chartFilter === 'M') {
              isShow = true;
            }
            if (isShow) {
              valueItems.push(item);
            }
            return item;
          });
          state.sliderValues = valueItems.map((object: any) => object.name);
          data.total_values = slice
            ? valueItems.slice(state.minValue, state.maxValue + 1)
            : valueItems;
        }

        tableDataArray.push(data);
      }
      state.tabsData = tableDataArray;

      if (slice) {
        state.chartLabels = state.sliderValues.slice(state.minValue, state.maxValue + 1);
      } else {
        state.chartLabels = state.sliderValues;
        state.minValue = 0;
        state.maxValue = state.sliderValues.length - 1;
      }
    } else {
      state.tabsData = [];
    }
  }
};

export const formatTableData = (state: any) => {
  if (state.indicatorTableOutput) {
    let keysToAdd: string[] = [];
    if (state.indicatorTableOutput && state.indicatorTableOutput.length > 0) {
      if (state.expandKey) {
        if (state.tableFilter === 'Y') {
          const year = state.expandKey;
          for (
            let index = 0;
            index < Object.values(state.indicatorTableOutput[0].time_granularity[year]).length;
            index += 1
          ) {
            const quarter: any = Object.values(
              state.indicatorTableOutput[0].time_granularity[year],
            )[index];
            keysToAdd = keysToAdd.concat(quarter);
          }
        } else if (state.tableFilter === 'Q') {
          const year = state.expandKey.split('-')[1];
          const quarter: string = state.expandKey;

          keysToAdd = state.indicatorTableOutput[0].time_granularity[year][quarter].map(
            (month: string) => {
              return `${month}`;
            },
          );
        }
      }
      const tableDataArray = [];
      for (let index = 0; index < state.indicatorTableOutput?.length; index += 1) {
        const data = state.indicatorTableOutput[index];
        let filteredkeys: any = [];
        if (data?.dim_item_data && data?.dim_item_data.length > 0) {
          const items: NewTotalValues[] = (data.dim_item_data as NewTotalValues[]).map(
            (item: any) => {
              if (item.value) {
                const itemKeys = (item.value as NewTotalValues[])?.map((subItem: any) => {
                  let isShow = false;
                  let isExpandedColumn = false;
                  if (state.tableFilter === 'Q' && subItem.name[0] === 'Q') {
                    isShow = true;
                  } else if (state.tableFilter === 'Y' && subItem.name.length === 4) {
                    isShow = true;
                  } else if (subItem.name.length === 6 && state.tableFilter === 'M') {
                    isShow = true;
                  } else {
                    const check = keysToAdd.includes(subItem.name);
                    isShow = keysToAdd && keysToAdd.length > 0 && check;
                    if (isShow) isExpandedColumn = true;
                  }

                  Object.assign(subItem, { isShow, isExpandedColumn });

                  return subItem;
                });
                item.value = itemKeys;
              }
              return item;
            },
          );
          data.dim_item_data = items;
        }
        const timeAggregation = state.modelDetail?.time_properties?.time_granularity
          ? (state.modelDetail?.time_properties?.time_granularity as String).toUpperCase()
          : 'M';
        if (data?.total_values && data?.total_values.length) {
          filteredkeys = (data.total_values as NewTotalValues[]).map((item: any) => {
            let isShow = false;
            let isExpandedColumn = false;
            let isShowExpandable = false;
            if (state.tableFilter === 'Q' && item.name[0] === 'Q') {
              isShow = true;
              isShowExpandable = true;
            } else if (state.tableFilter === 'Y' && item.name.length === 4) {
              isShow = true;
              isShowExpandable = true;
            } else if (item.name.length === 6 && state.tableFilter === 'M') {
              isShow = true;
            } else {
              const check = keysToAdd.includes(item.name);
              isShow = keysToAdd && keysToAdd.length > 0 && check;
              if (isShow) isExpandedColumn = true;
            }

            if (timeAggregation === 'Y') isShowExpandable = false;

            Object.assign(item, { isShow, isShowExpandable, isExpandedColumn });
            return item;
          });
        }

        data.total_values = filteredkeys;
        if (index === 0) {
          state.tableHeaderData = filteredkeys;
        }
        tableDataArray.push(data);
      }
      state.tableData = tableDataArray;
    } else {
      state.tableHeaderData = null;
      state.tableData = null;
    }
  }
};

const ScenarioSlice = createSlice({
  initialState,
  name: 'scenario',
  reducers: {
    resetScenario: (state) => {
      // state.baseScenario = null;
      state.modelId = null;
      state.blockId = null;
      state.compareScenario = null;
      state.allScenarios = [];
      state.scenarioFetchStatus = STATUS.IDLE;

      state.chartOutput = null;
      state.indicatorTableOutput = null;
      state.indicatorTableStatus = STATUS.IDLE;
      state.chartOutputStatus = STATUS.IDLE;

      state.tableData = null;
      state.tableHeaderData = null;
      state.tabsData = null;
      state.expandKey = '';
      state.tableFilter = null;
      state.chartFilter = null;
      state.chartType = '';
      state.minValue = 0;
      state.maxValue = 100;
      state.sliderValues = [];
      state.chartLabels = [];
      state.varianceFilterChart = true;
      state.varianceFilterTable = true;

      state.toggleCompare = false;
    },
    setCompareChartType: (state, action) => {
      state.chartType = action.payload;
    },
    setCompareChartFilter: (state, action) => {
      state.chartFilter = action.payload;
      formatChartData(state, false);
    },
    setCompareTableFilter: (state, action) => {
      state.expandKey = '';
      state.tableFilter = action.payload;
      formatTableData(state);
    },
    setExpandKey: (state, action) => {
      state.expandKey = action.payload;
      formatTableData(state);
    },
    selectBaseScenario: (state, action) => {
      state.baseScenario = action.payload;

      // state.blockScenarioMap[`${state.modelId}`] = action.payload?.id;
    },
    selectCompareScenario: (state, action) => {
      state.compareScenario = action.payload;
    },
    toggleCompareScenario: (state, action) => {
      if (state.allScenarios?.length === 1) {
        state.toggleCompare = action.payload;
      }
      if (state.allScenarios?.length > 1) {
        state.toggleCompare = action.payload;
        state.compareScenario = !state.toggleCompare
          ? null
          : state.allScenarios.find((scenario: Scenario) => {
              return scenario.id !== state.baseScenario.id;
            });
      }
    },
    updateModelandBlock: (state, action) => {
      state.modelId = action.payload?.modelId;
      state.blockId = action.payload?.blockId;
    },
    updateBlockScenarioMap: (state, action) => {
      state.blockScenarioMap = action.payload;
    },
    toggleVarianceFilterChart: (state) => {
      state.varianceFilterChart = !state.varianceFilterChart;
    },
    toggleVarianceFilterTable: (state) => {
      state.varianceFilterTable = !state.varianceFilterTable;
    },

    handleCompareMinMaxSliderValue: (state, action) => {
      state.minValue = action.payload.min;
      state.maxValue = action.payload.max;
      formatChartData(state, true);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllScenarios.fulfilled, (state, action) => {
        state.allScenarios = action.payload.scenarios;
        state.scenarioFetchStatus = STATUS.SUCCESS;
        const userSpecificScenario = state.blockScenarioMap[`${state?.modelId}`];
        if (userSpecificScenario && state?.blockId) {
          state.baseScenario =
            state.allScenarios &&
            state.allScenarios.find((scenario: Scenario) => {
              return scenario?.id === userSpecificScenario;
            });
        } else if (!state.baseScenario) {
          state.baseScenario =
            state.allScenarios &&
            state.allScenarios.find((scenario: Scenario) => {
              return scenario.is_base;
            });
          if (state?.modelId) state.blockScenarioMap[`${state.modelId}`] = state.baseScenario?.id;
        }
      })
      .addCase(fetchCompareAnalysisSectionData.fulfilled, (state, action) => {
        if (action.payload && action.payload.length > 0) {
          state.chartOutput = action.payload;
          if (!state.chartType && state.chartOutput.length > 0) {
            state.chartType = state.chartOutput[0].chart_type || 'line';
          }
          formatChartData(state, false);
          state.chartOutputStatus = STATUS.SUCCESS;
        } else if (state.overallIndicatorFilter === 'outputs') {
          state.overallIndicatorFilter = 'inputs_and_outputs';
        } else {
          state.chartOutput = action.payload;
          formatChartData(state, false);
          state.chartOutputStatus = STATUS.SUCCESS;
        }
      })

      .addCase(fetchCompareTableData.fulfilled, (state, action) => {
        state.indicatorTableOutput = action.payload;
        state.indicatorTableStatus = STATUS.SUCCESS;
        state.expandKey = '';
        formatTableData(state);
      })

      .addCase(fetchCompareAnalysisSectionData.pending, (state) => {
        state.chartOutputStatus = STATUS.LOADING;
      })

      .addCase(fetchCompareTableData.pending, (state) => {
        state.indicatorTableStatus = STATUS.LOADING;
      });
  },
});
export const {
  selectBaseScenario,
  selectCompareScenario,
  resetScenario,
  setExpandKey,
  toggleCompareScenario,
  setCompareTableFilter,
  setCompareChartFilter,
  setCompareChartType,
  handleCompareMinMaxSliderValue,
  updateModelandBlock,
  updateBlockScenarioMap,
  toggleVarianceFilterChart,
  toggleVarianceFilterTable,
} = ScenarioSlice.actions;

export const getBaseScenario = (state: any) => state.scenario?.baseScenario;
export const getCompareScenario = (state: any) => state.scenario?.compareScenario;
export const getAllScenarios = (state: any) => state.scenario?.allScenarios;
export const fetchScenarioStatus = (state: any) => state.scenario.scenarioFetchStatus;
export const getCompareMinSliderValue = (state: any) => state.scenario.minValue;
export const getCompareMaxSliderValue = (state: any) => state.scenario.maxValue;
export const getValuesForSlider = (state: any) => state.scenario.sliderValues;
export const getChartLabels = (state: any) => state.scenario.chartLabels;
export const getCompareChartType = (state: any) => state.scenario.chartType;
export const getTableData = (state: any) => state.scenario.tableData;
export const getTableHeaderData = (state: any) => state.scenario.tableHeaderData;
export const getExpandKey = (state: any) => state.scenario.expandKey;

export const compareAnalysisOutput = (state: any) => state.scenario.tabsData;
export const getCompareIndicatorTableOutput = (state: any) => state.scenario.indicatorTableOutput;
export const getChartOutputData = (state: any) => state.scenario.chartOutput;
export const scenarioChartOutputStatus = (state: any) => state.scenario.chartOutputStatus;
export const getIndicatorTableStatus = (state: any) => state.scenario.indicatorTableStatus;
export const getVarianceFilterChart = (state: any) => state.scenario.varianceFilterChart;
export const getVarianceFilterTable = (state: any) => state.scenario.varianceFilterTable;

export const getToggleScenario = (state: any) => state.scenario.toggleCompare;

export default ScenarioSlice.reducer;
