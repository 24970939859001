import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { AddIcon } from '@chakra-ui/icons';
import { useMemo, useState } from 'react';

import addAction from 'assets/add_action_icon.png';
import { getImportDataFiles, selectedFileId } from 'redux/DataImportSlice';

import SourceItem from './SourceItem';
import VersionList from './VersionList';
import CurrentSource from './CurrentSource';

const commonStyles = {
  display: 'flex',
  alignItems: 'center',
};
const textStyles = {
  fontSize: 'xs',
  color: 'rgba(255, 255, 255, 1)',
  fontFamily: 'regular_roboto',
  fontWeight: '400',
};
const Source = ({ onOpen, selectedFileDetails }: any) => {
  const dispatch: any = useDispatch();
  const ImportDataFiles = useSelector(getImportDataFiles);
  const [curretSourceDetails, setCurretSourceDetails] = useState(null);

  useMemo(() => {
    const currentSource =
      ImportDataFiles &&
      ImportDataFiles?.find((item: any) => {
        return selectedFileDetails?.id === item?.id;
      });
    setCurretSourceDetails(currentSource);
  }, [selectedFileDetails]);

  const FileDetailsPage = (id: number, name: string) => {
    dispatch(
      selectedFileId({
        id,
        name,
        deatsilsPage: true,
      }),
    );
  };
  return (
    <>
      <Box
        w={'full'}
        maxH={`35vh`}
        overflowX={'hidden'}
        overflowY={'auto'}
        className='scroll'
        mt={'1rem'}
      >
        <Box pe={'0.4rem'} maxH='full'>
          {ImportDataFiles && ImportDataFiles?.length > 0 ? (
            <>
              {selectedFileDetails && selectedFileDetails?.deatsilsPage === true ? (
                <CurrentSource
                  curretSourceDetails={curretSourceDetails}
                  FileDetailsPage={FileDetailsPage}
                />
              ) : (
                ImportDataFiles.map((importDatafile: any) => (
                  <SourceItem
                    key={importDatafile.id}
                    ImportDataFiles={importDatafile}
                    FileDetailsPage={FileDetailsPage}
                  />
                ))
              )}
            </>
          ) : (
            <Box
              minH='40px'
              {...commonStyles}
              px={3}
              gap={3}
              // bg={'rgba(112, 98, 205, 1)'}
              borderRadius={'9px'}
              width='full'
              border={'1px dashed rgba(255, 255, 255, 0.40)'}
              onClick={onOpen}
              cursor={'pointer'}
              mt={3}
              boxShadow='2px 3px 5px rgba(0, 0, 0, 0.25)'
            >
              +<Text {...textStyles}>Add source</Text>
            </Box>
          )}
        </Box>
      </Box>
      {ImportDataFiles?.length === 0 && (
        <Box w={''} {...commonStyles} justifyContent={'center'} pt={5}>
          <Box>
            <Text>Add source e.g. a</Text>
            <Text textAlign={'left'}> csv file here</Text>
          </Box>

          <Image h={30} w={30} src={addAction} />
        </Box>
      )}
      {selectedFileDetails && selectedFileDetails?.deatsilsPage === true && (
        <VersionList selectedFileDetails={selectedFileDetails} />
      )}
      {ImportDataFiles?.length > 0 && selectedFileDetails?.id === null && (
        <Flex ps={'10px'} pt={'1rem'} cursor={'pointer'} onClick={onOpen}>
          <AddIcon />
        </Flex>
      )}
    </>
  );
};

export default Source;
