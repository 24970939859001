import { Input, InputGroup, InputLeftElement, useMediaQuery } from '@chakra-ui/react';
import { ChangeEvent } from 'react';

import Search from 'components/Icons/Search';

interface Properties {
  searchValue: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const SearchInModels = ({ searchValue, onChange }: Properties) => {
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <InputGroup
      w={isMobile ? '50%' : '20vw'}
      ml={isMobile ? '0' : '5'}
      h={isMobile ? '4vh' : '3vh'}
    >
      <InputLeftElement
        pointerEvents='none'
        h='100%'
        children={<Search width={3.5} color='grey.900' />}
      />
      <Input
        value={searchValue}
        onChange={onChange}
        placeholder='Search your blocks'
        h='100%'
        fontFamily='regular'
        fontSize='12px'
        lineHeight='9px'
        letterSpacing='-0.015em'
        color='grey.900'
        _placeholder={{ opacity: 0.7, color: 'inherit' }}
      />
    </InputGroup>
  );
};

export default SearchInModels;
