import {
  Box,
  Card,
  CardBody,
  Editable,
  EditableInput,
  EditablePreview,
  HStack,
  Image,
  Stack,
  Text,
  Tooltip,
  useMediaQuery,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { DragHandleIcon } from '@chakra-ui/icons';
import moment from 'moment';
import { MouseEvent, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLongPress } from 'use-long-press';
import { useDispatch } from 'react-redux';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import Pensil from 'components/Icons/Pencil';
import Setup from 'components/Icons/Setup';
import Visibility from 'components/Icons/Visibility';
import { Block } from 'models/response/model.interface';
import apiService from 'services';
import InfoIconComponent from 'components/Icons/InfoIcon';
import {
  DeleteModelsBlock,
  DuplicateModelsBlock,
  FetchModelBlocks,
  UpdateBlock,
} from 'redux/ModelsSlice';
import DeleteModal from 'components/Modals/DeleteModal';
import ModelModal from 'components/Modals/ModelModal';
import blockImage from 'assets/blox_logo.png';

export interface Properties {
  block: Block;
  icon?: string;
  is_edited?: boolean;
  is_drivers_present?: boolean;
}

const BlockCard = ({ block }: Properties) => {
  const dispatch: any = useDispatch();
  const { modelId } = useParams();
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(false);
  const [hover, setHover] = useState<boolean>(false);
  const [isDeleteBlock, setIsDeleteBlock] = useState({ status: false, blockId: '', blockName: '' });
  const { attributes, listeners, setNodeRef, transform, isDragging } = useSortable({
    id: block.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    zIndex: isDragging ? '100' : 'auto',
    opacity: isDragging ? 0.3 : 1,
  };

  const [isEditBlock, setIsEditBlock] = useState({ status: false, blockData: {} });
  const buttonStyle = {
    backgroundColor: '#4BB6B9',
    borderRadius: '6px',
    marginLeft: '0px',
    marginRight: '2px',
    fontSize: '10px',
    height: '28px',
    width: '90px',
    fontWeight: 'bold',
    _hover: { bg: '#ebedf0', borderColor: '#433889', color: 'white' },
  };

  const redirectToDriver = (blockId: number) => navigate(`/model/${modelId}/block/${blockId}/plan`);

  const redirectToPlan = (blockId: number) => navigate(`/model/${modelId}/block/${blockId}/plan`);

  const handleEditBlock = (blockId: number) => {
    apiService.get_block_drivers(Number(blockId)).then((data: any) => {
      if (data.data.drivers.length > 0) {
        redirectToDriver(blockId);
      } else redirectToPlan(blockId);
    });
  };

  const longClickCheck = useLongPress(() => {
    if (isMobile) {
      setHover((hoverStatus) => !hoverStatus);
    }
  });

  const handleSetupBlock = (blockId: number) => {
    apiService.updateBlock(Number(blockId), { id: block.id, is_edited: true }).then(() => {
      redirectToPlan(blockId);
    });
  };

  const onBlur = () => setIsEditMode(false);

  const onRenameBlock = (value: string) => {
    if (block?.name !== value.trim()) {
      dispatch(UpdateBlock({ blockId: block?.id, data: { name: value.trim() } })).then(() => {
        dispatch(FetchModelBlocks({ modelId }));
      });
    }
    setIsEditMode(false);
  };

  const onOpenEditMode = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setIsEditMode((previousState) => !previousState);
  };

  const handleDuplicateBlock = (blockId: number) => dispatch(DuplicateModelsBlock({ blockId }));

  const handleDeleteBlock = (modal: any) =>
    setIsDeleteBlock({ status: true, blockName: modal?.name, blockId: modal?.id });

  const handleDelete = () => {
    dispatch(DeleteModelsBlock({ blockId: isDeleteBlock.blockId }));
    setIsDeleteBlock({ blockId: '', blockName: '', status: false });
  };

  const handlePropertyModal = (blockData: any) => setIsEditBlock({ blockData, status: true });

  const handleSubmit = (form: any, blockData: any) => {
    setIsEditBlock({ status: false, blockData: {} });
    dispatch(UpdateBlock({ blockId: blockData?.id, data: form })).then((response: any) => {
      if (!response.error) {
        dispatch(FetchModelBlocks({ modelId }));
      }
    });
  };

  return (
    <Box ref={setNodeRef} style={style}>
      <Card variant={'block'} width={isMobile ? '250px' : '300px'} mr={isMobile ? 0 : 2}>
        <CardBody>
          <Box
            height='140px'
            width={isMobile ? '100%' : '300px'}
            position='relative'
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <Image
              filter={hover ? 'blur(2px)' : ''}
              cursor={'pointer'}
              onClick={() => redirectToPlan(block?.id)}
              // eslint-disable-next-line unicorn/prefer-logical-operator-over-ternary
              src={block.icon ? block.icon : blockImage}
              bg={
                (block?.is_edited === true && block?.is_drivers_present === false) ||
                (block?.is_edited === false && block?.is_drivers_present === false)
                  ? 'linear-gradient(0deg, rgba(109, 96, 202, 0.15), rgba(109, 96, 202, 0.15)), linear-gradient(180deg, #FFFFFF 50%, #F3F3F3 80.6%) !important'
                  : 'linear-gradient(#FFFFFF, #F3F3F3)'
              }
              borderTopRightRadius={'21px'}
              borderTopLeftRadius={'21px'}
              height={'100%'}
              w={'100%'}
              alt='Block Image'
              objectFit={'contain'}
            />
            {hover && (
              <Menu closeOnSelect={true} variant='primary'>
                <MenuButton position={'absolute'} top={'5px'} right={'5px'}>
                  <InfoIconComponent height={40} color='#BDBCD0' isVerticalIcon={true} />
                </MenuButton>
                <MenuList minWidth={'160px !important'}>
                  <MenuItem onClick={onOpenEditMode}>Rename</MenuItem>
                  <MenuItem onClick={() => handleDuplicateBlock(block?.id)}>Duplicate</MenuItem>
                  <MenuItem onClick={() => handleDeleteBlock(block)}>Delete</MenuItem>
                  <MenuItem onClick={() => handlePropertyModal(block)}>Properties</MenuItem>
                </MenuList>
              </Menu>
            )}

            {hover && (
              <DragHandleIcon
                {...listeners}
                {...attributes}
                cursor={'grab'}
                position={'absolute'}
                top={'10px'}
                left={'10px'}
                color={'#959595'}
                _focus={{ outline: 'none' }}
              />
            )}
          </Box>

          <Stack
            p={2}
            direction={'row'}
            style={{ justifyContent: 'space-between', display: 'flex' }}
            width={'100%'}
          >
            <HStack style={{ justifyContent: 'space-between' }} width={{ base: '60%', lg: '65%' }}>
              <VStack
                style={{ justifyContent: 'space-between', alignItems: 'start' }}
                width={'100%'}
              >
                <Box {...longClickCheck()} width={'100%'}>
                  {isEditMode ? (
                    <Editable
                      defaultValue={block?.name}
                      onSubmit={onRenameBlock}
                      startWithEditView={isEditMode}
                      onBlur={onBlur}
                      mb='2'
                      h='7'
                      fontFamily='bold_roboto'
                      fontSize='11px'
                      lineHeight='13px'
                      color='black.900'
                    >
                      <EditablePreview w='full' />
                      <EditableInput
                        _focusVisible={{
                          h: 'full',
                          px: '1',
                          borderWidth: '3',
                          borderRadius: 'xs',
                          borderColor: 'blue.500',
                        }}
                      />
                    </Editable>
                  ) : (
                    <Tooltip label={block?.name} borderRadius='4' fontSize='3xs'>
                      <Text variant='blockName'>{block?.name}</Text>
                    </Tooltip>
                  )}
                </Box>

                {block?.last_modified_at && (
                  <Box style={{ marginTop: 0 }}>
                    <Text fontSize='11px' color={'grey'} whiteSpace={'nowrap'}>
                      Edited {moment.utc(block?.last_modified_at).fromNow(false)}
                    </Text>
                  </Box>
                )}
              </VStack>
            </HStack>

            <HStack style={{ margin: 0 }} alignItems={'center'} justifyContent='center'>
              {block?.is_edited === false && block?.is_drivers_present === true && (
                <Box
                  display={'flex'}
                  onClick={() => handleSetupBlock(block?.id)}
                  style={buttonStyle}
                  bgColor={'#FFB000 !important'}
                  textTransform={'none'}
                  fontFamily='regular'
                  alignItems={'center'}
                  textAlign={'center'}
                  cursor={'pointer'}
                  justifyContent='space-between'
                  p={2}
                >
                  <Text color={'white'}>Set Up</Text>
                  <Setup width={6} height={5.5} />
                </Box>
              )}
              {block?.is_edited === true && block?.is_drivers_present === true && (
                <Box
                  display={'flex'}
                  onClick={() => handleEditBlock(block?.id)}
                  style={buttonStyle}
                  textTransform={'none'}
                  fontFamily='regular'
                  alignItems={'center'}
                  textAlign={'center'}
                  cursor={'pointer'}
                  justifyContent='space-between'
                  width={'65px !important'}
                  p={2}
                >
                  <Text color={'white'}>Edit</Text>
                  <Pensil color='white.900' width={4} />
                </Box>
              )}
              {((block?.is_edited === true && block?.is_drivers_present === false) ||
                (block?.is_edited === false && block?.is_drivers_present === false)) && (
                <Box
                  display={'flex'}
                  bgColor={'#6561A9 !important'}
                  onClick={() => redirectToPlan(block?.id)}
                  style={buttonStyle}
                  textTransform={'none'}
                  fontFamily='regular'
                  alignItems={'center'}
                  textAlign={'center'}
                  cursor={'pointer'}
                  justifyContent='space-between'
                  width={'65px !important'}
                  p={2}
                >
                  <Text color={'white'}>View</Text>
                  <Visibility color='White' width={6} height={4} />
                </Box>
              )}
            </HStack>
          </Stack>
        </CardBody>
      </Card>

      {isDeleteBlock.status && (
        <DeleteModal
          isOpen={isDeleteBlock.status}
          onClose={() => setIsDeleteBlock({ ...isDeleteBlock, status: false })}
          itemName={isDeleteBlock.blockName}
          onDelete={handleDelete}
        />
      )}

      {isEditBlock.status && (
        <ModelModal
          isOpen={isEditBlock.status}
          onClose={() => setIsEditBlock({ blockData: {}, status: false })}
          blockData={isEditBlock.blockData}
          onSave={handleSubmit}
        />
      )}
    </Box>
  );
};

export default BlockCard;
