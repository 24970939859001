import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

interface Properties {
  type: 'role' | 'delete';
  userName?: string | null;
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: () => void;
}

const SuccessModal = ({ type, userName, isOpen, onClose, handleSubmit }: Properties) => (
  <Modal isOpen={isOpen} onClose={onClose} size='xs'>
    <ModalOverlay />
    <ModalContent pb={3} width={62.5} color='black'>
      <ModalHeader position='relative'>
        <Text variant='modal' size='subTitle' align='center'>
          Confirm your action
        </Text>
      </ModalHeader>

      <ModalBody>
        {type === 'role' ? (
          <Text variant='modal' size='modalContent'>
            Are you sure you want to make{' '}
            <Text as='span' size='subTitle'>
              {userName}
            </Text>{' '}
            the owner of your organisation? You will lose owner access as a result.
          </Text>
        ) : (
          <Text variant='modal' size='modalContent'>
            Are you sure you want to delete{' '}
            <Text as='span' size='subTitle'>
              {userName}
            </Text>
          </Text>
        )}
      </ModalBody>

      <ModalFooter pb='0'>
        <HStack spacing='5'>
          <Button variant='clear' size='auto' fontSize='xs' color='red.500' onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant='clear'
            size='auto'
            fontSize='xs'
            color='violet.900'
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </HStack>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

export default SuccessModal;
