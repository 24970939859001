/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import apiService from 'services';
import apiServices from 'services/apiService';

export const SignUp = createAsyncThunk('signup/post', async ({ request_body }: any, thunkAPI) => {
  try {
    const response = await apiService.signup({ request_body });
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const SignIn = createAsyncThunk('signin/post', async ({ request_body }: any, thunkAPI) => {
  try {
    const response = await apiService.login({ request_body });
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error?.response?.data);
  }
});

export const InvitationUserData = createAsyncThunk(
  'invitationUserData/post',
  async ({ token }: any, thunkAPI) => {
    try {
      const response = await apiService.userInvitationData({ token });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const GetUserDetail = createAsyncThunk(
  'userDetail/get',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response = await apiServices.fetch_user();
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const ChangePassword = createAsyncThunk(
  'changepassword/post',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response = await apiServices.change_password({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const ResetPassword = createAsyncThunk(
  'resetpassword/post',
  async ({ request_body }: any, thunkAPI) => {
    try {
      const response = await apiService.reset_password({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

export const SetUserPassword = createAsyncThunk(
  'setpassword/post',
  async (request_body: any, thunkAPI) => {
    try {
      const response = await apiServices.set_new_password({ request_body });
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data);
    }
  },
);

const AuthSlice = createSlice({
  name: 'authSlice',
  initialState: {
    authPageType: 1, // 1: login, 2:signup, 3:reset
    setPasswordPageType: 1,
    setPasswordData: {
      token: '',
      password: '',
      userData: '',
      organization: '',
    },
    invitationPageType: 1, // 1. invitation Modal, 2. Invitation Signup form
    invitationData: '',
    invitationDataStatus: 'IDLE',
  },
  reducers: {
    SetAuthPageType: (state, action) => {
      state.authPageType = action.payload;
    },
    SetAuthPasswordPageType: (state, action) => {
      state.setPasswordPageType = action.payload;
    },
    SetPasswordToken: (state, action) => {
      state.setPasswordData.token = action.payload;
    },
    SetAuthPassword: (state, action) => {
      state.setPasswordData.password = action.payload;
    },
    SetInvitationPageType: (state, action) => {
      state.invitationPageType = action.payload;
    },
    resetAuthenticationSlice: (state) => {
      state.authPageType = 1;
      state.setPasswordPageType = 1;
      state.setPasswordPageType = 1;
      state.setPasswordData.password = '';
      state.setPasswordData.token = '';
      state.setPasswordData.userData = '';
      state.invitationPageType = 1;
      state.invitationData = '';
      state.invitationDataStatus = 'IDLE';
    },
  },
  extraReducers(builder) {
    builder.addCase(GetUserDetail.fulfilled, (state, action) => {
      state.setPasswordData.userData = action.payload.user;
    });
    builder.addCase(InvitationUserData.fulfilled, (state, action) => {
      state.invitationData = action.payload;
    });
    builder.addCase(InvitationUserData.rejected, (state, action) => {
      state.invitationDataStatus = 'FAILED';
    });
  },
});

export const {
  SetAuthPageType,
  SetPasswordToken,
  SetAuthPassword,
  SetAuthPasswordPageType,
  resetAuthenticationSlice,
  SetInvitationPageType,
} = AuthSlice.actions;

export const getInvitationUserData = (state: any) => state.auth.invitationData;
export const getAuthenticationPageType = (state: any) => state.auth.authPageType;
export const getSetPasswordsPageType = (state: any) => state.auth.setPasswordPageType;
export const getAuthenticationToken = (state: any) => state.auth.setPasswordData.token;
export const getUsersData = (state: any) => state.auth.setPasswordData.userData;
export const getUsersPassword = (state: any) => state.auth.setPasswordData.password;
export const getInvitationDataStatus = (state: any) => state.auth.invitationDataStatus;

export default AuthSlice.reducer;
