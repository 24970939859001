import { Flex, Tooltip } from '@chakra-ui/react';
import { useState } from 'react';
import { BiFilter } from 'react-icons/bi';

import EditPencilIcon from 'components/Icons/EditPencilIcon';

const HeaderComponent = ({ cell }: any) => {
  const [hoverHeaderCell, setHoverHeaderCell] = useState(false);

  const handleHover = (status: boolean) => {
    if (cell?.hasFilters) {
      setHoverHeaderCell(status);
    }
  };

  return (
    <>
      {cell?.text === 'Del' ? (
        <EditPencilIcon />
      ) : (
        <Flex
          width={'100%'}
          height={'inherit'}
          justifyContent={'space-between'}
          alignItems={'center'}
          onMouseOver={() => {
            if (cell?.hasFilters) handleHover(true);
          }}
          onMouseLeave={() => {
            if (cell?.hasFilters) handleHover(false);
          }}
        >
          <Tooltip label={cell.text}>
            <p
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                maxWidth: '100%',
                textOverflow: 'ellipsis',
              }}
            >
              {cell.text}
            </p>
          </Tooltip>
          {cell?.hasFilter && hoverHeaderCell && <BiFilter size={30} color={'rgb(190 218 218)'} />}
        </Flex>
      )}
    </>
  );
};

export default HeaderComponent;
