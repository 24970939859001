export default {
  none: '0',
  '2xs': '0.125rem', // 2px
  xs: '0.375rem', // 6px
  sm: '0.625rem', // 10px
  base: '1rem', // 16px
  md: '1.125rem', // 18px
  lg: '1.25rem', // 20px
  xl: '1.5rem', // 24px
  full: '9999px',
};
