import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const Lock = ({ width = 2.75, height = 3.5, color = 'white' }: Properties) => {
  return (
    <Icon viewBox='0 0 11 14' width={width} height={height} color={color}>
      <path
        d='M9.68894 4.88783H9.05402V3.63008C9.05402 1.89439 7.63179 0.485714 5.87941 0.485714C4.12703 0.485714 2.70481 1.89439 2.70481 3.63008V4.88783H2.06989C1.37148 4.88783 0.800049 5.45382 0.800049 6.14558V12.4343C0.800049 13.1261 1.37148 13.6921 2.06989 13.6921H9.68894C10.3874 13.6921 10.9588 13.1261 10.9588 12.4343V6.14558C10.9588 5.45382 10.3874 4.88783 9.68894 4.88783ZM5.87941 10.5477C5.181 10.5477 4.60957 9.98171 4.60957 9.28995C4.60957 8.59819 5.181 8.0322 5.87941 8.0322C6.57783 8.0322 7.14925 8.59819 7.14925 9.28995C7.14925 9.98171 6.57783 10.5477 5.87941 10.5477ZM7.84767 4.88783H3.91116V3.63008C3.91116 2.55471 4.7937 1.68057 5.87941 1.68057C6.96513 1.68057 7.84767 2.55471 7.84767 3.63008V4.88783Z'
        fill={color}
      />
    </Icon>
  );
};

export default Lock;
