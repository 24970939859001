import { Box, Flex, Text, Input, useOutsideClick } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';

import {
  AddBuilderDimension,
  DimensionReorderingData,
  FetchDimensionList,
  getAddDimension,
  getBuilderDimensions,
  handleAddDimensionStatus,
  handleBuilderSelectedDimension,
} from 'redux/BuilderModeSlice';
import FullScreenLoader from 'components/FullScreenLoader';

import DimensionItemTab from './DimensionItemTab';

const DimensionBuilderSidebar = () => {
  const reference: any = useRef(null);
  const dispatch: any = useDispatch();
  const inputReference: any = useRef(null);
  const parameters: any = useParams().modelId;

  const { modelId } = useParams();

  const builderDimension = useSelector(getBuilderDimensions);
  const addDimension = useSelector(getAddDimension);

  const [loader, setLoader] = useState(false);
  const [myDim, setDims] = useState(builderDimension);

  const carouselReference = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const getDimension = async () => {
      if (parameters) {
        setLoader(true);
        await dispatch(FetchDimensionList(parameters)).then(async (response: any) => {
          await dispatch(handleBuilderSelectedDimension(response?.payload?.dimensions[0]));
          setLoader(false);
        });
      }
    };
    getDimension();
  }, []);

  useEffect(() => {
    setDims(builderDimension);
  }, [builderDimension]);

  useOutsideClick({
    ref: reference,
    handler: () => {
      if (addDimension) dispatch(handleAddDimensionStatus(false));
    },
  });

  useOutsideClick({
    ref: inputReference,
    handler: () => {
      if (addDimension) dispatch(handleAddDimensionStatus(false));
    },
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleAddDimension = (value: string) => {
    const dinName = value.trim();
    if (parameters) {
      dispatch(
        AddBuilderDimension({
          modelId: parameters,
          data: { name: dinName },
          toaster: { successMessage: false, errorMessage: true },
        }),
      ).then(() => {
        dispatch(FetchDimensionList(parameters));
        dispatch(handleAddDimensionStatus(false));
      });
    }
  };

  useEffect(() => {
    const setReference = () => {
      setTimeout(() => {
        if (addDimension && inputReference.current) {
          inputReference.current.focus();
        }
      }, 0);
    };
    setReference();
  }, [addDimension]);

  const handleDragEnd = async (event: any) => {
    const { active, over } = event;
    const blockIds = builderDimension.map((block: any) => block.id);
    const oldIndex = blockIds.indexOf(active.id);
    const newIndex = blockIds.indexOf(over.id);
    setDims([...arrayMove(builderDimension, oldIndex, newIndex)]);
    const reOrderData = [...arrayMove(builderDimension, oldIndex, newIndex)];
    const ids = reOrderData?.map((item) => item?.id);
    await dispatch(
      DimensionReorderingData({
        modelId,
        request_body: { dimensions: ids },
      }),
    );
    await dispatch(FetchDimensionList(parameters));
  };

  return (
    <Flex height={'100%'} flexDirection={'column'}>
      <Text fontSize={'md'}>Dimensions</Text>
      {addDimension && (
        <Box mb={4} pr={4} pt={4} ref={reference}>
          <Input
            ref={inputReference}
            onKeyDown={(event: any) => {
              if (event?.keyCode === 13) handleAddDimension(event?.target?.value);
            }}
            placeholder='New Dimension'
          />
        </Box>
      )}
      {!loader ? (
        <Flex
          gap={2}
          flexDir={'column'}
          height={'100%'}
          overflowX={'hidden'}
          overflowY={'auto'}
          className='scroll'
          pr={4}
          mt={4}
          pb={'.2rem'}
        >
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            {myDim && myDim?.length > 0 ? (
              <>
                <SortableContext items={myDim} strategy={rectSortingStrategy}>
                  <Box ref={carouselReference}>
                    <Flex flexDirection={'column'} mt={'2'} gap={'1rem'} flexWrap={'wrap'}>
                      {myDim?.map((response: any, index) => {
                        return (
                          <React.Fragment key={index}>
                            <DimensionItemTab dimData={response} key={index} />
                          </React.Fragment>
                        );
                      })}
                    </Flex>
                  </Box>
                </SortableContext>
              </>
            ) : (
              'Not found'
            )}
          </DndContext>
        </Flex>
      ) : (
        <FullScreenLoader width='350px' height='100%' bgColor='transparent' />
      )}
    </Flex>
  );
};
export default DimensionBuilderSidebar;
