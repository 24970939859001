import { startLoading, stopLoading } from '../SharedSlice';

const LoaderMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action.type.endsWith('/pending') && action?.meta?.arg?.loader) {
    store.dispatch(startLoading());
  } else if (
    action.type.endsWith('/fulfilled') ||
    action.type.endsWith('/rejected' && action?.meta?.arg?.loader)
  ) {
    store.dispatch(stopLoading());
  }

  return next(action);
};
export default LoaderMiddleware;
