/* eslint-disable no-else-return */
/* eslint-disable no-unsafe-optional-chaining */

const calculateWidth = (totalElements: number, totalWidth: number) => {
  const nameField: number = 150;
  const columnsWidth = totalElements * 150 + nameField;
  return totalWidth > columnsWidth ? totalWidth / (totalElements + 1) : 150;
};

const headerRow = (tableItemsProperty_: any, tableProperty_: any) => {
  const nameOptions = tableItemsProperty_
    .filter((item: any) => item?.name !== null)
    .reduce((accumulator: any, item: any) => {
      if (!accumulator.includes(item?.name)) {
        accumulator.push(item?.name);
      }
      return accumulator;
    }, []);

  return {
    rowId: 'header',
    height: 50,
    cells: [
      {
        type: 'filter',
        options: nameOptions,
        id: 'name',
        text: 'Name',
        hasDelete: false,
        hasEdit: false,
        nonEditable: true,
        className: 'header-cell header-first',
      },
      ...(tableProperty_?.length
        ? tableProperty_?.map((data: any) => {
            const options = tableItemsProperty_
              .filter((item: any) => item[data?.id] !== null)
              .reduce((accumulator: any, item: any) => {
                if (!accumulator.includes(item[data?.id])) {
                  accumulator.push(item[data?.id]);
                }
                return accumulator;
              }, []);
            const row = {
              type: 'filter',
              options,
              id: data?.id,
              hasDelete: true,
              hasEdit: true,
              colType: 'header',
              text: data?.name,
              className: `header-cell `,
            };
            return row;
          })
        : []),
    ],
  };
};

const footerRow = (tableProperty_: any) => ({
  rowId: 'footer',
  height: 50,
  cells: [
    { type: 'addRow', text: '', className: 'footer-first', nonEditable: true },
    ...(tableProperty_?.length
      ? tableProperty_?.map(() => {
          const row = {
            type: 'text',
            text: '',
            nonEditable: true,
            className: `footer `,
            hasFormulaError: false,
          };
          return row;
        })
      : []),
  ],
});

const getRows = (tableItemsProperty_: any, tableProperty_: any) => {
  return [
    headerRow(tableItemsProperty_, tableProperty_),
    ...(tableItemsProperty_?.length
      ? tableItemsProperty_?.map((item: any, index: any) => {
          return {
            rowId: index,
            height: 50,
            itemId: item?.id,
            itemName: item?.name,
            reorderable: true,
            cells: [
              {
                type: 'text',
                placeholder: 'Enter value',
                text: `${item?.name}` ?? '',
                property: 'name',
                rows: tableItemsProperty_?.length,
                className: 'name',
                hasFormulaError: false,
                index,
              },
              ...(tableProperty_?.length
                ? tableProperty_?.map((data: any) => {
                    if (
                      data?.name.trim().toLowerCase() === 'start' ||
                      data?.name.trim().toLowerCase() === 'end' ||
                      data?.name.trim().toLowerCase() === 'start date' ||
                      data?.name.trim().toLowerCase() === 'end date'
                    ) {
                      const row = {
                        type: 'date',
                        text: item[`${data?.id}`] || '',
                        date: new Date(),
                        nonEditable: !item?.id,
                      };
                      return row;
                    } else if (data?.items) {
                      const row = {
                        type: 'dropdown',
                        nonEditable: !item?.id,
                        selectOption: true,
                        selectedValue: item[`${data?.id}`],
                        values: [
                          ...(data?.items?.length
                            ? data?.items?.map((value: any) => ({
                                label: value?.name,
                                value: value?.name,
                              }))
                            : []),
                        ],
                        index,
                        operation: '',
                        data,
                      };
                      return row;
                    } else {
                      const row = {
                        type: 'text',
                        rows: tableItemsProperty_?.length,
                        text: item[`${data?.id}`] ?? '',
                        index,
                        data,
                        hasFormulaError: false,
                        nonEditable: !item?.id,
                        // className: `${index_ === tableProperty_?.length - 1 ? ' last-index ' : ''}`,
                      };
                      return row;
                    }
                  })
                : []),
            ],
          };
        })
      : []),
    footerRow(tableProperty_),
  ];
};

const getColumns = (tableProperty_: any, tableReference: any) => [
  {
    columnId: 'name',
    width:
      tableReference || tableProperty_
        ? calculateWidth(tableProperty_?.length, tableReference)
        : 200,
    resizable: true,
  },
  ...(tableProperty_?.length
    ? tableProperty_?.map((data: any) => {
        const row = {
          columnId: `${data?.id}`,
          name: data?.name,
          width: tableReference ? calculateWidth(tableProperty_?.length, tableReference) : 200,
          resizable: true,
        };
        return row;
      })
    : []),
];

const reorderArray = <T extends {}>(array: T[], idxs: number[], to: number) => {
  const movedElements = array.filter((_, index) => idxs.includes(index));
  const targetIndex =
    Math.min(...idxs) < to ? (to += 1) : (to -= idxs.filter((index) => index < to).length);
  const leftSide = array.filter((_, index) => index < targetIndex && !idxs.includes(index));
  const rightSide = array.filter((_, index) => index >= targetIndex && !idxs.includes(index));
  const reorderedArray = [...leftSide, ...movedElements, ...rightSide];
  const idsOnlyArray = reorderedArray.map((item: any) => item.id);
  return idsOnlyArray;
};

export { headerRow, footerRow, getRows, getColumns, reorderArray };
