import { Image, StyleProps } from '@chakra-ui/react';

import assistant from 'assets/assistant_icon.png';

interface Properties {
  width?: number;
  height?: number;
  styles?: StyleProps;
}

const Assistant = ({ width = 34, height = 27, styles }: Properties) => {
  return <Image src={assistant} w={width} h={height} {...styles} />;
};

export default Assistant;
