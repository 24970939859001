const AlignVerticalBottomIcon = () => {
  return (
    <svg
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      width='25px'
      height='25px'
      viewBox='0 0 50 50'
    >
      <path
        d='M0 0 C5.94 0 11.88 0 18 0 C18 3.96 18 7.92 18 12 C12.06 12 6.12 12 0 12 C0 8.04 0 4.08 0 0 Z '
        fill='#000000'
        transform='translate(16,0)'
      />
      <path
        d='M0 0 C4.62 0 9.24 0 14 0 C14 3.96 14 7.92 14 12 C9.38 12 4.76 12 0 12 C0 8.04 0 4.08 0 0 Z '
        fill='#000000'
        transform='translate(36,35)'
      />
      <path
        d='M0 0 C4.62 0 9.24 0 14 0 C14 3.96 14 7.92 14 12 C9.38 12 4.76 12 0 12 C0 8.04 0 4.08 0 0 Z '
        fill='#000000'
        transform='translate(18,35)'
      />
      <path
        d='M0 0 C4.62 0 9.24 0 14 0 C14 3.96 14 7.92 14 12 C9.38 12 4.76 12 0 12 C0 8.04 0 4.08 0 0 Z '
        fill='#000000'
        transform='translate(0,35)'
      />
      <path
        d='M0 0 C0.66 0 1.32 0 2 0 C2 2.64 2 5.28 2 8 C7.94 8 13.88 8 20 8 C20 11.63 20 15.26 20 19 C19.34 19 18.68 19 18 19 C18 16.03 18 13.06 18 10 C12.72 10 7.44 10 2 10 C2 12.97 2 15.94 2 19 C1.34 19 0.68 19 0 19 C0 16.03 0 13.06 0 10 C-5.28 10 -10.56 10 -16 10 C-16 12.97 -16 15.94 -16 19 C-16.66 19 -17.32 19 -18 19 C-18 15.37 -18 11.74 -18 8 C-12.06 8 -6.12 8 0 8 C0 5.36 0 2.72 0 0 Z '
        fill='#000000'
        transform='translate(24,14)'
      />
    </svg>
  );
};

export default AlignVerticalBottomIcon;
