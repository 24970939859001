import { ButtonGroup, Button, useMediaQuery } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

export interface Properties {
  selectedYear?: string;
  color?: string;
  bg?: string;
  timeGranularity: string;
  fontColor?: string;
  onChangeSummeryMode: (value: string) => void;
}
const TabsFilter = ({
  timeGranularity,
  onChangeSummeryMode,
  selectedYear,
  bg,
  fontColor,
}: Properties) => {
  const [displayLevels, setDisplayLevels] = useState(timeGranularity);
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  useEffect(() => {
    setDisplayLevels(timeGranularity);
  }, [timeGranularity]);

  const handleChange = (value: string) => {
    setDisplayLevels(value);
    onChangeSummeryMode(value);
  };

  useEffect(() => {
    if (displayLevels === 'Y' && selectedYear !== 'all') {
      setDisplayLevels('M');
      onChangeSummeryMode('M');
    }
  }, [selectedYear]);

  return (
    <>
      <ButtonGroup isAttached variant='none' size={isMobile ? 'xs' : 'xs'} display='flex'>
        <Button
          fontSize={'2xs'}
          fontWeight={displayLevels === 'M' ? '600' : '500'}
          p='2'
          color={displayLevels === 'M' ? '#4BB6B9' : fontColor}
          sx={{
            background: displayLevels === 'M' ? bg : 'transparent',
            borderRadius: '5px',
            '&:hover': {
              bg,
            },
          }}
          onClick={() => handleChange('M')}
          borderRadius={0}
        >
          M
        </Button>
      </ButtonGroup>
      <ButtonGroup isAttached variant='none' size={isMobile ? 'xs' : 'xs'} display='flex'>
        <Button
          p='2'
          fontSize={'2xs'}
          fontWeight={displayLevels === 'Q' ? '600' : '500'}
          color={displayLevels === 'Q' ? '#4BB6B9' : fontColor}
          sx={{
            background: displayLevels === 'Q' ? bg : 'transparent',
            borderRadius: '5px',
            '&:hover': {
              bg,
            },
          }}
          onClick={() => handleChange('Q')}
          borderRadius={0}
        >
          Q
        </Button>
      </ButtonGroup>
      <ButtonGroup isAttached variant='none' size={isMobile ? 'xs' : 'xs'} display='flex'>
        <Button
          p='2'
          fontSize={'2xs'}
          fontWeight={displayLevels === 'Y' ? '600' : '500'}
          color={displayLevels === 'Y' ? '#4BB6B9' : fontColor}
          sx={{
            backgroundColor: displayLevels === 'Y' ? bg : 'transparent',
            borderRadius: '5px',
            '&:hover': {
              bg,
            },
          }}
          onClick={() => handleChange('Y')}
          borderRadius={0}
        >
          Y
        </Button>
      </ButtonGroup>
    </>
  );
};

export default TabsFilter;
