import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  VStack,
  Text,
  Stack,
  ModalHeader,
  ModalFooter,
  List,
  ListItem,
  ListIcon,
  Button,
  Image,
  Box,
} from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

import { SetTabs, SetUserModalVisible, getUserSubscriptionLimits } from 'redux/UserSlice';
import CoordinationBlox from 'assets/coordination_icon.png';
import RocketLaunch from 'components/Icons/RocketLaunch';
import { AppDispatch } from 'utils/GlobalHelpers';

const textStyles = {
  fontFamily: 'regular',
  fontWeight: '700',
  color: '#6562CF',
};

const paragraphStyles = {
  fontSize: 'sm',
  fontFamily: 'regular_roboto',
  fontWeight: '500',
  color: '#000',
};

const ModelPermissionPopup = ({ onClose, isOpen }: any) => {
  const dispatch: AppDispatch = useDispatch();

  const permissionData = useSelector(getUserSubscriptionLimits);

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered size={{ base: 'xs', md: '3xl' }}>
      <ModalOverlay />
      <ModalContent color={'#000'} maxW={'50rem'} width={'-webkit-fit-content'}>
        <ModalCloseButton />
        <ModalHeader> </ModalHeader>
        <ModalBody>
          <Stack direction={{ base: 'column', md: 'row' }} px={{ base: 0, md: 4 }}>
            <VStack width={'auto'} justify={'space-between'} alignItems={'start'}>
              <Text {...textStyles} mb={1} fontSize={{ base: 'md', md: 'xl' }}>
                So you’ve been busy? Nice work!
              </Text>
              <Text {...paragraphStyles} mb={2} fontSize={{ base: 'sm', md: 'sm' }}>
                It looks like you’ve reached the{' '}
                {permissionData?.subscription_features_limit?.No_of_models} model limit for your
                organisation - but you can easily upgrade to unlock more models and extra features,
                such as...
              </Text>

              <List spacing={1}>
                <ListItem display={'flex'}>
                  <ListIcon as={FaCheck} color='green.500' />
                  <Text>Premium support</Text>
                </ListItem>
                <ListItem display={'flex'}>
                  <ListIcon as={FaCheck} color='green.500' />
                  <Text>Shared team models</Text>
                </ListItem>
                <ListItem display={'flex'}>
                  <ListIcon as={FaCheck} color='green.500' />
                  <Text>Data integration</Text>
                </ListItem>
                <ListItem display={'flex'}>
                  <ListIcon as={FaCheck} color='green.500' />
                  <Text>Unlimited what-if scenarios per model and much more!</Text>
                </ListItem>
              </List>
            </VStack>
            <VStack width='auto'>
              <Box p={2}>
                <Image src={CoordinationBlox} />
              </Box>
              <Button
                width={{ base: '180px', md: '200px' }}
                leftIcon={<RocketLaunch />}
                style={{
                  background: '#3BB8BC',
                  borderRadius: '10px',
                  padding: '0',
                  color: 'white',
                }}
              >
                <Text
                  fontSize={{ base: 'xs', md: 'md' }}
                  fontWeight='400'
                  cursor={'pointer'}
                  onClick={async () => {
                    await dispatch(SetUserModalVisible(true));
                    await dispatch(SetTabs(5));
                  }}
                >
                  VIEW PAID PLANS
                </Text>
              </Button>
            </VStack>
          </Stack>
        </ModalBody>
        <ModalFooter> </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModelPermissionPopup;
