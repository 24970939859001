interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const FilterIconComponent = ({ width = 20, height = 20, color = 'white.900' }: Properties) => {
  return (
    <svg
      cursor={'pointer'}
      width={width}
      height={height}
      viewBox='0 0 29 29'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <mask id='mask0_436_830' maskUnits='userSpaceOnUse' x='0' y='0' width='29' height='29'>
        <rect x='0.0952148' y='0.109863' width='28.3696' height='28.3696' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_436_830)'>
        <path
          d='M6.21033 23.4557V15.113H3.84619V13.3399H10.3475V15.113H7.98337V23.4557H6.21033ZM6.21033 10.5211V5.13367H7.98337V10.5211H6.21033ZM11.0295 10.5211V8.74808H13.3937V5.13367H15.1667V8.74808H17.5308V10.5211H11.0295ZM13.3937 23.4557V13.3399H15.1667V23.4557H13.3937ZM20.577 23.4557V19.8413H18.2129V18.0682H24.7142V19.8413H22.35V23.4557H20.577ZM20.577 15.2494V5.13367H22.35V15.2494H20.577Z'
          fill={color}
        />
      </g>
    </svg>
  );
};

export default FilterIconComponent;
