import { CloseIcon } from '@chakra-ui/icons';
import { Button, Flex, Text, useMediaQuery, useBoolean, VStack, Image } from '@chakra-ui/react';
import { Suspense, lazy } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BiPlayCircle } from 'react-icons/bi';

import csv from 'assets/csv_icon.png';
import { textStyles } from 'pages/ModelOverviewPage/Import/Helper';
import {
  clearStepperFormData,
  getSelectedDataFileDetails,
  setActionCreateForm,
  setClearSelectedAreasTable,
} from 'redux/DataImportSlice';

const LazyMappingDataModal = lazy(() => import('../../../MappingDataModel'));

interface Properties {
  setIsFinalStep: any;
  selectedActionId: any;
  selectedMapType: any;
  scenarioId: any;
}

const FinalDataSteps = ({
  setIsFinalStep,
  selectedActionId,
  selectedMapType,
  scenarioId,
}: Properties) => {
  const dispatch = useDispatch();
  const [isLaptop] = useMediaQuery('(max-width: 1200px)');
  const [dataMappingModal, setDataMappingModal] = useBoolean(false);
  const sourceFileId = useSelector(getSelectedDataFileDetails);

  const getMappedData = () => {
    setDataMappingModal.on();
  };

  return (
    <VStack display='flex' justify={'center'} w={'100%'} pt={3}>
      <Flex justifyContent={'space-between'} w={'full'} align={'center'}>
        <Text
          color={'#6562CF'}
          fontSize='lg'
          fontFamily={'regular_roboto'}
          fontWeight={'400'}
          textAlign={'start'}
        >
          Select dimension columns{' '}
        </Text>
        <CloseIcon
          color={'black'}
          width={3}
          height={3}
          cursor={'pointer'}
          onClick={async () => {
            await dispatch(setActionCreateForm(false));
            await dispatch(clearStepperFormData());
            await dispatch(setClearSelectedAreasTable());
          }}
        />
      </Flex>
      <Text color={'black'} {...textStyles} textAlign={'start'} width={'100%'} pb={5}>
        This action is now saved to your source. You can now run your data into blox or view your
        sources.
      </Text>
      <Flex alignItems={'center'} justifyContent={'center'} pb={8} pt='2' w={'100%'}>
        <Flex color={'black.900'} fontSize='xs' display={'flex'} alignContent={'center'}>
          <Button
            maxWidth={'90px'}
            height={'30px'}
            borderRadius='xs'
            fontSize='xs'
            bgColor='#EFEEFE'
            color='#6562CF'
            fontWeight='400'
            me={'1rem'}
            fontFamily={'Roboto Regular'}
            px={isLaptop ? '8' : '10'}
            py={isLaptop ? '5' : '6'}
            onClick={() => setIsFinalStep(false)}
            textTransform={'capitalize'}
          >
            BACK
          </Button>
          <Button
            maxWidth={'140px'}
            height={'30px'}
            borderRadius='xs'
            fontSize='xs'
            bgColor='#4BB6B9'
            color='white'
            fontWeight='400'
            me={'1rem'}
            fontFamily={'Roboto Regular'}
            px={isLaptop ? '8' : '10'}
            py={isLaptop ? '5' : '6'}
            onClick={async () => {
              await dispatch(setActionCreateForm(false));
              await dispatch(clearStepperFormData());
              await dispatch(setClearSelectedAreasTable());
            }}
            textTransform={'capitalize'}
          >
            <Image src={csv} height={5} width={5} me={'0.3rem'} />
            VIEW SOURCES
          </Button>

          <Button
            _hover={{ _disabled: {} }}
            type='submit'
            borderRadius='xs'
            fontWeight='400'
            fontFamily={'Roboto Regular'}
            maxWidth={'90px'}
            px={isLaptop ? '6' : '10'}
            py={isLaptop ? '5' : '6'}
            height={'30px'}
            fontSize='xs'
            bgColor='#6562CF'
            textTransform={'capitalize'}
            onClick={getMappedData}
            color={'white'}
          >
            <Flex me={2} align={'center'}>
              <BiPlayCircle size={23} color={'#4BB6B9'} />
            </Flex>
            RUN
            {/* {isSubmitting && <Spinner ml={'4'} />} */}
          </Button>
        </Flex>
        <Suspense fallback={<div>Loading...</div>}>
          <LazyMappingDataModal
            mappingModal={dataMappingModal}
            setMappingModal={setDataMappingModal}
            selectedActionId={selectedActionId}
            dataSourceId={sourceFileId?.id}
            selectedMapType={selectedMapType}
            scenarioId={scenarioId}
          />
        </Suspense>
      </Flex>
    </VStack>
  );
};

export default FinalDataSteps;
