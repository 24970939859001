import {
  Flex,
  Text,
  VStack,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Button,
} from '@chakra-ui/react';
import { ChevronDownIcon, AddIcon, CloseIcon } from '@chakra-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { BsArrowRight } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Formik, Form, FieldArray, ErrorMessage } from 'formik';

import { textStyles } from 'pages/ModelOverviewPage/Import/Helper';
import {
  clearStepperFormData,
  getFormValues,
  getTableHeader,
  handleActionStepNext,
  resetRowsIndicatorMappedData,
  setActionCreateForm,
  setClearSelectedAreasTable,
  setColumnIndicatorMappedData,
  setSelectedAreasProperties,
} from 'redux/DataImportSlice';
import { fetchModelTime } from 'redux/IndicatorPageSlice';
import { getBlockInputIndicator } from 'redux/PlanPageSlice';

import ActionStepFooter from '../../ActionStepFooter';

const ColumnType = () => {
  const dispatch: any = useDispatch();
  const { modelId } = useParams();
  const headerNames = useSelector(getTableHeader);
  const getFormValue = useSelector(getFormValues);
  const blockInputIndicator = useSelector(getBlockInputIndicator);
  const [showErrorMessage, setShowErrorMessage] = useState(false);

  useEffect(() => {
    dispatch(fetchModelTime(Number(modelId)));
  }, []);
  const getIndicatorInput = (id: number) => {
    const data = blockInputIndicator.find((item: any) => item.id === Number(id));

    return data?.name ?? '';
  };

  const handleSubmit = async (form: any, setSubmitting: (data: boolean) => void) => {
    // const submittedData = form.selectedData.reduce((accumulator: any, item: any) => {
    //   accumulator[item.key] = item.value || null;
    //   return accumulator;
    // }, {});
    await dispatch(setColumnIndicatorMappedData(form?.selectedData));
    await dispatch(resetRowsIndicatorMappedData());
    await setSubmitting(false);
    await dispatch(handleActionStepNext());
  };

  return (
    <Flex w={'full'} flexDirection={'column'}>
      <Flex justifyContent={'space-between'} w={'full'} align={'center'}>
        <Text
          color={'#6562CF'}
          fontSize='lg'
          fontFamily={'regular_roboto'}
          fontWeight={'400'}
          textAlign={'start'}
        >
          Select indicators
        </Text>
        <CloseIcon
          color={'black'}
          width={3}
          height={3}
          cursor={'pointer'}
          onClick={async () => {
            await dispatch(setActionCreateForm(false));
            await dispatch(clearStepperFormData());
            await dispatch(setClearSelectedAreasTable());
          }}
        />
      </Flex>
      <Text color={'black'} {...textStyles} textAlign={'start'} width={'100%'}>
        Now select the columns in your table that relate to your time periods using the drop down
        menu belows
      </Text>
      <Text
        color={'#6562CF'}
        fontSize='lg'
        fontFamily={'regular_roboto'}
        fontWeight={'400'}
        textAlign={'start'}
        py={3}
      >
        Map the values into Blox
      </Text>
      <Formik
        initialValues={{
          selectedData:
            getFormValue?.columnIndicatorMappedData &&
            getFormValue?.columnIndicatorMappedData?.length
              ? getFormValue?.columnIndicatorMappedData
              : [{ key: headerNames[0], value: null }],
        }}
        onSubmit={(form, { setSubmitting }) => handleSubmit(form, setSubmitting)}
        enableReinitialize={true}
      >
        {({ values, errors, setFieldValue, isSubmitting }: any) => {
          return (
            <Form
              style={{
                width: '100%',
              }}
            >
              <VStack w={'full'}>
                <Flex justifyContent={'space-between'} align={'center'} w='full'>
                  <Text
                    fontSize='1rem'
                    color='black'
                    fontFamily={'regular_roboto'}
                    fontWeight={'600'}
                  >
                    Column in File
                  </Text>
                  <Flex w={'41%'}>
                    <Text
                      fontSize='1rem'
                      color='black'
                      fontFamily={'regular_roboto'}
                      fontWeight={'600'}
                    >
                      Indicator in Blox
                    </Text>
                  </Flex>
                </Flex>
                <FieldArray
                  name='selectedData'
                  render={() => (
                    <>
                      {values.selectedData.map((selectedItems: any, index: number) => (
                        <Flex
                          justifyContent='space-between'
                          align='center'
                          pt={3}
                          key={index}
                          w={'100%'}
                        >
                          <Flex align='center' w='45%' justify='start'>
                            <Menu closeOnSelect={true} isLazy>
                              <MenuButton
                                as={Button}
                                backgroundColor='white'
                                maxHeight={'40px'}
                                borderRadius='5px'
                                minWidth='140px'
                                textAlign='left'
                                padding='0 .8rem'
                                boxShadow='rgb(139, 137, 177) 0px 0px 0px 0.6px'
                                rightIcon={<ChevronDownIcon fontSize='md' />}
                                fontWeight='300'
                                fontSize='xs'
                                color='black.900'
                                w='100%'
                                border={
                                  errors.selecetedDimension
                                    ? '2px solid #fe6e6e'
                                    : '0.6px solid #8B89B1'
                                }
                              >
                                <Text me={3} noOfLines={1} height='fit-content'>
                                  <Text>{selectedItems?.key}</Text>
                                </Text>
                              </MenuButton>
                              <MenuList
                                height='fit-content'
                                maxHeight='20vh'
                                overflowY='auto'
                                className='scroll'
                              >
                                <MenuOptionGroup defaultValue={selectedItems.key}>
                                  {headerNames
                                    ?.filter(
                                      (header: any) =>
                                        !values?.selectedData?.some(
                                          (selected: any) => selected?.key === header,
                                        ),
                                    )
                                    ?.map((header: any) => (
                                      <MenuItemOption
                                        key={header}
                                        value={header}
                                        onClick={() => {
                                          setFieldValue(`selectedData.${index}.key`, header);
                                          dispatch(
                                            setSelectedAreasProperties({
                                              [`selectedDatas.${index}.key`]: header,
                                            }),
                                          );
                                        }}
                                      >
                                        {header}
                                      </MenuItemOption>
                                    ))}
                                </MenuOptionGroup>
                              </MenuList>
                            </Menu>
                          </Flex>
                          <Flex align='center' gap={2} justify='end' w='50%'>
                            <BsArrowRight color='black' style={{ width: '20px', height: '20px' }} />
                            <Menu closeOnSelect={true} isLazy>
                              <MenuButton
                                as={Button}
                                backgroundColor='white'
                                maxHeight={'40px'}
                                borderRadius='5px'
                                minWidth='140px'
                                textAlign='left'
                                padding='0 .8rem'
                                boxShadow='rgb(139, 137, 177) 0px 0px 0px 0.6px'
                                rightIcon={<ChevronDownIcon fontSize='md' />}
                                fontWeight='300'
                                fontSize='xs'
                                color='black.900'
                                w='100%'
                                border='0.6px solid #8B89B1'
                              >
                                <Text me={3} noOfLines={1} height='fit-content'>
                                  <Text>{getIndicatorInput(selectedItems?.value)}</Text>
                                </Text>
                              </MenuButton>
                              <MenuList
                                height='fit-content'
                                maxHeight='20vh'
                                overflowY='auto'
                                className='scroll'
                              >
                                <MenuOptionGroup defaultValue={selectedItems.value || ''}>
                                  {blockInputIndicator &&
                                    blockInputIndicator
                                      ?.filter(
                                        (item: any) =>
                                          !values.selectedData.some(
                                            (selected: any) => selected.value === item?.id,
                                          ),
                                      )
                                      ?.map((item: any) => (
                                        <MenuItemOption
                                          key={item?.id}
                                          value={item?.name}
                                          onClick={() =>
                                            setFieldValue(`selectedData.${index}.value`, item?.id)
                                          }
                                        >
                                          {item?.name}
                                        </MenuItemOption>
                                      ))}
                                </MenuOptionGroup>
                              </MenuList>
                            </Menu>
                          </Flex>
                        </Flex>
                      ))}
                    </>
                  )}
                />
                <ErrorMessage name='selectedData' component='div' className='error' />
                <Flex
                  pt={'.5rem'}
                  w={'full'}
                  align={'flex-start'}
                  cursor={'pointer'}
                  // onClick={() => {
                  //   const currentSelectedData = values.selectedData;
                  //   setFieldValue('selectedData', [
                  //     ...currentSelectedData,
                  //     { key: '', value: null },
                  //   ]);
                  // }}
                  onClick={() => {
                    const currentSelectedData = values.selectedData;
                    const lastSelectedItem = currentSelectedData[currentSelectedData.length - 1];
                    if (lastSelectedItem.value !== null) {
                      setFieldValue('selectedData', [
                        ...currentSelectedData,
                        { key: '', value: null },
                      ]);
                      setShowErrorMessage(false);
                    } else {
                      setShowErrorMessage(true);
                    }
                  }}
                >
                  <AddIcon color={'black'} />
                </Flex>
                {showErrorMessage && (
                  <Text color={'red'} fontSize={'2xs'} textAlign={'start'} w={'100%'}>
                    Value must be filled before adding a new column.
                  </Text>
                )}
              </VStack>

              <Flex alignItems={'center'} justifyContent={'flex-end'} pb={8} pt='5' w={'100%'}>
                <ActionStepFooter
                  nextButtonName='Continue'
                  prevButtonName='Previous'
                  isSubmitting={isSubmitting}
                />
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
};

export default ColumnType;
