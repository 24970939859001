import { Icon, StyleProps } from '@chakra-ui/react';

interface Properties {
  width?: number | string;
  height?: number | string;
  color?: string;
  onClick?: () => void;
  styles?: StyleProps;
  bgColor?: string;
}

const Cross = ({
  width = '35px',
  height = '35px',
  color = '#6561A9',
  onClick,
  styles,
  bgColor = 'white',
}: Properties) => {
  return (
    <Icon
      viewBox='0 0 32 31'
      width={width}
      height={height}
      color={color}
      onClick={onClick}
      cursor={'pointer'}
      {...styles}
    >
      <g mask='url(#mask0_125_55)'>
        <path
          d='M8.74718 24.54L6.95508 22.7479L14.1235 15.5795L6.95508 8.41103L8.74718 6.61893L15.9156 13.7873L23.084 6.61893L24.8761 8.41103L17.7077 15.5795L24.8761 22.7479L23.084 24.54L15.9156 17.3716L8.74718 24.54Z'
          fill={bgColor}
          fillOpacity='0.7'
        />
      </g>
    </Icon>
  );
};

export default Cross;
