import { Input } from '@chakra-ui/react';
import { useEffect, useRef } from 'react';

interface InputProperties {
  clickedOutside?: ((event: any) => void) | undefined;
  [key: string]: any;
}

const InputField = ({ clickedOutside, ...rest }: InputProperties) => {
  const inputReference = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputReference.current && !inputReference.current.contains(event.target as Node)) {
        if (clickedOutside) {
          clickedOutside(event);
        }
        inputReference.current.blur();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Input
      ref={inputReference}
      onClick={(event) => {
        inputReference.current?.select();
        event.stopPropagation();
      }}
      onFocus={(event) => {
        setTimeout(() => {
          inputReference.current?.select();
          event.stopPropagation();
        }, 0);
      }}
      {...rest}
    />
  );
};

export default InputField;
