import { Image, StyleProps } from '@chakra-ui/react';

import branchIcon from 'assets/alt_route_icon.png';

interface Properties {
  width?: number;
  height?: number;
  styles?: StyleProps;
}

const BranchIcon = ({ width = 34, height = 27, styles }: Properties) => {
  return <Image src={branchIcon} w={width} h={height} {...styles} />;
};

export default BranchIcon;
