/* eslint-disable @typescript-eslint/no-unused-vars */
import { QuestionIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Image,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useMediaQuery,
  useOutsideClick,
  VStack,
} from '@chakra-ui/react';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import assistant from 'assets/assistant_icon.png';
import Carousel from 'components/Carousel';
import NoOutputIndicatorIcon from 'components/Icons/NoOutputIndicator';
import { IndicatorDriver } from 'models/response/driver.interface';
import { STATUS } from 'projectConstants';
import {
  analysisOutput,
  chartOutputStatus,
  getActiveTabIndex,
  getBlockDriversData,
  getChartLabels,
  getIndicatorTableStatus,
  setActiveTabIndex,
  getTableData,
  ShowSidePanel,
} from 'redux/PlanPageSlice';
import formatValue from 'utils/FormatValues';
import { AppDispatch } from 'utils/GlobalHelpers';

import Chart from './Chart';

const ChartTabs = () => {
  const dispatch: AppDispatch = useDispatch();
  const tabsData: any = useSelector(analysisOutput);
  const activeTabIndex = useSelector(getActiveTabIndex);
  const indicatorInfo = tabsData?.length ? tabsData[activeTabIndex]?.help : '';
  const indicatorName = tabsData?.length ? tabsData[activeTabIndex]?.name : '';
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const chartLabels = useSelector(getChartLabels);
  const defaultTabIndex = tabsData?.findIndex((data: any) => data?.is_default_KPI === true) || 0;
  const tooltipReference = useRef<any>(null);
  const chartStatus = useSelector(chartOutputStatus);
  const tableStatus = useSelector(getIndicatorTableStatus);
  const blockDrivers = useSelector(getBlockDriversData);
  const showSidePanel = useSelector(ShowSidePanel);
  const filteredDrivers = blockDrivers?.filter(
    (driver: IndicatorDriver) => driver.type === 'indicator',
  );
  const [isMobile] = useMediaQuery('(max-width: 576px)');
  const [isLargeMobile] = useMediaQuery('(max-width: 660px)');
  const [isTablet] = useMediaQuery('(max-width: 768px)');
  const [isSmallLaptop] = useMediaQuery('(max-width: 1024px)');
  const [isLaptop] = useMediaQuery('(max-width: 1350px)');
  const [isLargeLaptop] = useMediaQuery('(max-width: 1500px)');
  const [isLargeScreen] = useMediaQuery('(min-width: 1500px)');
  const chartOutput = useSelector(getTableData);

  const handleTabIndexChange = (index: number) => dispatch(setActiveTabIndex(index));

  const [slidesToShow, setSlidesToShow] = useState(
    tabsData?.length < 4
      ? showSidePanel && filteredDrivers?.length > 0
        ? isMobile
          ? 2
          : isSmallLaptop
          ? 4
          : isLaptop
          ? 3
          : isLargeLaptop
          ? 4
          : 5
        : showSidePanel || filteredDrivers?.length > 0
        ? isMobile
          ? 2
          : isSmallLaptop
          ? 4
          : isLaptop
          ? 5
          : isLargeLaptop
          ? 7
          : isLargeScreen
          ? 8
          : 7
        : isMobile
        ? 2
        : isLargeMobile
        ? 3
        : isTablet
        ? 4
        : 6
      : showSidePanel && filteredDrivers?.length > 0
      ? isMobile
        ? 2
        : isLargeMobile
        ? 4
        : isSmallLaptop
        ? 5
        : isLaptop
        ? 3
        : isLargeLaptop
        ? 4
        : 5
      : showSidePanel || filteredDrivers?.length > 0
      ? isMobile
        ? 2
        : isSmallLaptop
        ? 4
        : isLaptop
        ? 5
        : isLargeLaptop
        ? 7
        : isLargeScreen
        ? 8
        : 7
      : isMobile
      ? 2
      : isLargeMobile
      ? 3
      : isTablet
      ? 4
      : 6,
  );

  useOutsideClick({
    ref: tooltipReference,

    handler: () => {
      setIsTooltipOpen(false);
    },
  });

  useEffect(() => {
    if (activeTabIndex === -1 && tabsData) {
      if (defaultTabIndex > -1) {
        handleTabIndexChange(defaultTabIndex);
      } else {
        handleTabIndexChange(0);
      }
    }
  }, [tabsData?.length > 0, defaultTabIndex, activeTabIndex]);

  const totalValue = useMemo(() => {
    const total = chartOutput?.map((data: any) => {
      return data?.dim_item_data
        ? data?.dim_item_data?.map((item: { value: any[] }) =>
            item?.value?.filter((items: { isShow: any }) => items.isShow),
          )
        : [];
    });
    const totalValues: (number | null)[][][] | undefined = total?.map((data: any[]) =>
      data?.map((item: any[]) => item?.map((items: any) => items?.value)),
    );
    const lastValues = totalValues?.map((data) => data?.map((item) => item?.[item.length - 1]));
    return lastValues;
  }, [chartOutput]);

  useEffect(() => {
    setSlidesToShow(
      tabsData?.length < 4
        ? showSidePanel && filteredDrivers?.length > 0
          ? isMobile
            ? 2
            : isSmallLaptop
            ? 4
            : isLaptop
            ? 3
            : isLargeLaptop
            ? 4
            : 5
          : showSidePanel || filteredDrivers?.length > 0
          ? isMobile
            ? 2
            : isSmallLaptop
            ? 4
            : isLaptop
            ? 5
            : isLargeLaptop
            ? 7
            : isLargeScreen
            ? 8
            : 7
          : isMobile
          ? 2
          : isLargeMobile
          ? 3
          : isTablet
          ? 4
          : 6
        : showSidePanel && filteredDrivers?.length > 0
        ? isMobile
          ? 2
          : isLargeMobile
          ? 4
          : isSmallLaptop
          ? 5
          : isLaptop
          ? 3
          : isLargeLaptop
          ? 4
          : 5
        : showSidePanel || filteredDrivers?.length > 0
        ? isMobile
          ? 2
          : isSmallLaptop
          ? 4
          : isLaptop
          ? 5
          : isLargeLaptop
          ? 7
          : isLargeScreen
          ? 8
          : 7
        : isMobile
        ? 2
        : isLargeMobile
        ? 3
        : isTablet
        ? 4
        : 6,
    );
  }, [showSidePanel]);
  const getTotalTabData = (data: any, data_type: any, index: number) => {
    if (data_type === 'balance') {
      if (totalValue) {
        return totalValue[index]?.reduce((total: number, value: any) => total + value, 0);
      }
    } else {
      return data?.reduce((total: number, item: any) => total + item.value, 0);
    }
    return data?.reduce((total: number, item: any) => total + item.value, 0);
  };
  if (
    (tabsData === null || tabsData?.length === 0) &&
    chartStatus !== STATUS.LOADING &&
    tableStatus !== STATUS.LOADING
  ) {
    return (
      <Box w='100%' mt='2' display='flex' justifyContent='center' alignItems='center'>
        <NoOutputIndicatorIcon />
      </Box>
    );
  }

  return (
    <Tabs
      size='file'
      variant='file'
      onChange={handleTabIndexChange}
      position='relative'
      px={isMobile ? 2 : 4}
      pt={isMobile ? 2 : 1}
      pb={isMobile ? 2 : 3}
      borderRadius='15px'
      backgroundColor={'#FFFFFF'}
      zIndex={0}
      index={activeTabIndex}
    >
      <TabList display='block' w='100%' h='6rem' pt={'0.3rem'} justifyContent={'start'}>
        <Carousel
          className='carousel_bg_white'
          slidesToScroll={1}
          slidesToShow={tabsData?.length > 2 ? 3 : 2}
        >
          {tabsData?.map((object: any, index: number) => {
            return (
              <Flex flexDirection={'column'} maxWidth={'100%'} key={object.id}>
                <Tab
                  key={object.id}
                  color='#57519C'
                  borderBottom={'1px solid #8D8BCE'}
                  _selected={{
                    borderBottom: '3px solid #4BB6B9',
                    background: '#EEF2F6',
                    borderTopRadius: '5px',
                    fontWeight: 800,
                  }}
                  maxWidth={'100%'}
                  w={'100%'}
                  h={'60px'}
                  paddingInlineStart={0}
                  paddingInlineEnd={0}
                  pl={0}
                  pr={0}
                  ml={0}
                  mr={0}
                  lineHeight='20px'
                >
                  <Flex
                    direction='row'
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    justify={isMobile ? 'space-around' : 'flex-start'}
                    p='3px'
                    width={'100%'}
                    height={'50px'}
                  >
                    <Tooltip label={object.name} borderRadius='4' fontSize='3xs'>
                      <Text
                        fontSize={isMobile ? 'xs' : 'md'}
                        lineHeight='18px'
                        marginRight={3}
                        fontFamily={'regular_roboto'}
                        fontWeight={activeTabIndex === index ? 600 : 500}
                        noOfLines={2}
                      >
                        {object.name}
                      </Text>
                    </Tooltip>
                    {index === activeTabIndex && (
                      <Tooltip
                        isOpen={isTooltipOpen}
                        hasArrow
                        arrowSize={10}
                        bg='white'
                        placement='right'
                        boxShadow={'rgba(0, 0, 0, 0.24) 0px 3px 8px'}
                        label={
                          <VStack
                            bg={'white'}
                            h='inherit'
                            p='3'
                            justify='space-between'
                            maxWidth={'300px'}
                            width={'180px'}
                            alignItems={'center'}
                            alignContent={'center'}
                            gap={1}
                            zIndex={1}
                          >
                            <Text
                              mb='2.5'
                              fontFamily='bold_roboto'
                              fontSize='sm'
                              lineHeight='4'
                              noOfLines={2}
                              color='black'
                            >
                              Your {indicatorName} tells you...
                            </Text>
                            <Image src={assistant} height={isMobile ? 15 : 20} />
                            <Text
                              size='modalContent'
                              fontFamily='regular_roboto'
                              noOfLines={3}
                              color='black'
                              fontSize='2xs'
                            >
                              {indicatorInfo}
                            </Text>
                          </VStack>
                        }
                        borderRadius='4'
                        width={'-moz-min-content'}
                      >
                        <QuestionIcon
                          ref={tooltipReference}
                          onBlur={() => setIsTooltipOpen(!isTooltipOpen)}
                          boxSize={'1.8em'}
                          onMouseEnter={() => setIsTooltipOpen(true)}
                          onMouseLeave={() => setIsTooltipOpen(false)}
                          me={1}
                        />
                      </Tooltip>
                    )}
                  </Flex>
                </Tab>
                <Box pt={'3'} pb={'1'} w={'100%'} h={'45px'}>
                  <Tooltip
                    label={formatValue(
                      getTotalTabData(object?.total_values, object?.data_type, index),
                      object?.format_properties,
                    )}
                    borderRadius='4'
                    fontSize='3xs'
                  >
                    <Text
                      textAlign={'center'}
                      fontSize={isMobile ? 'md' : 'lg'}
                      noOfLines={1}
                      color={activeTabIndex === index ? '#57519C' : '#8B89B4'}
                      onClick={() => handleTabIndexChange(index)}
                      cursor={'pointer'}
                    >
                      {formatValue(
                        getTotalTabData(object.total_values, object.data_type, index),
                        object.format_properties,
                      )}
                    </Text>
                  </Tooltip>
                </Box>
              </Flex>
            );
          })}
        </Carousel>
      </TabList>
      {tabsData && tabsData?.length && (
        <TabPanels>
          {tabsData?.map((object: any) => (
            <TabPanel key={object.id} pt={0} position={{ base: 'static', md: 'relative' }}>
              {chartLabels?.length > 0 && (
                <Chart
                  chartData={
                    object.dim_item_data ?? [{ value: object.total_values, name: 'Total' }]
                  }
                  chartLabels={chartLabels}
                  tabData={object}
                  activeTabIndex={activeTabIndex}
                />
              )}
            </TabPanel>
          ))}
        </TabPanels>
      )}
    </Tabs>
  );
};

export default ChartTabs;
