interface Properties {
  width?: number;

  height?: number;

  color?: string;

  style?: React.CSSProperties;
}

const ArrowDropdownIconComponent = ({
  width = 20,

  height = 20,

  color = 'white.900',

  style,
}: Properties) => {
  return (
    <svg
      style={style}
      width={width}
      height={height}
      viewBox='0 0 11 6'
      fill={color}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M5.854 5.86401L0.854004 0.864014H10.854L5.854 5.86401Z' fill={color} />
    </svg>
  );
};

export default ArrowDropdownIconComponent;
