import { Box, Flex, Input, Text, Tooltip } from '@chakra-ui/react';
import { KeyboardEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getShowNewGroupInput, setShowNewGroupInput } from 'redux/ItemPageSlice';
import { getSelectedDriver } from 'redux/PlanPageSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

import Plus from '../../../../../components/Icons/Plus';

interface GroupProperties {
  addGroupName: string | null;
  setAddGroupName: (argument: string | null) => void;
  addGroup: (event: any) => void;
  planPermission?: boolean;
}

const NewGroupInput = ({
  addGroupName,
  setAddGroupName,
  addGroup,
  planPermission,
}: GroupProperties) => {
  const dispatch: AppDispatch = useDispatch();

  const showNewGroupInput = useSelector(getShowNewGroupInput);
  const currentDriver = useSelector(getSelectedDriver);

  const changeInputHandler = (event: any) => {
    setAddGroupName(event.target.value);
  };

  const OnKeyEnter = async (event: KeyboardEvent) => {
    if (event && event.keyCode === 13) {
      addGroup(event);
    }
    return null;
  };

  return showNewGroupInput ? (
    <Flex
      cursor='pointer'
      justifyContent='flex-start'
      alignItems='left'
      p='10px 6px'
      alignSelf='flex-start'
    >
      <Input
        placeholder='New Group'
        onChange={planPermission ? undefined : (event: any) => changeInputHandler(event)}
        onKeyDown={planPermission ? undefined : (event: KeyboardEvent) => OnKeyEnter(event)}
        onBlur={
          planPermission
            ? undefined
            : (event: any) =>
                addGroupName ? addGroup(event) : dispatch(setShowNewGroupInput(false))
        }
        isDisabled={!!planPermission}
        autoFocus
      />
    </Flex>
  ) : (
    <Flex
      cursor={planPermission ? 'not-allowed' : 'pointer'}
      justifyContent='flex-start'
      alignItems='center'
      p='10px 20px'
      alignSelf='flex-start'
      onClick={planPermission ? undefined : () => dispatch(setShowNewGroupInput(true))}
    >
      <Box mr='15px'>
        <Plus height={5} width={5} />
      </Box>

      <Tooltip label={currentDriver?.group_property}>
        <Text fontSize='1xl' pt='2px'>
          {currentDriver?.group_property}
        </Text>
      </Tooltip>
    </Flex>
  );
};

export default NewGroupInput;
