/* eslint-disable class-methods-use-this */
import React from 'react';
import {
  Cell,
  Compatible,
  Uncertain,
  getCellProperty,
  keyCodes,
  isAlphaNumericKey,
  CellTemplate,
  isNavigationKey,
  UncertainCompatible,
  getCharFromKeyCode,
} from '@silevis/reactgrid';
import { Flex } from '@chakra-ui/react';

import { FormulaErrorBox, IndicatorIcon, TooltipContent } from 'utils/ReactGridUtils';

export interface TextCell extends Cell {
  text: string;
  placeholder: string;
  validator?: (text: string) => boolean;
  colType?: string;
  format?: string;
  indicatorType?: string;
  tableName?: string;
  hasFormulaError: boolean;
  currencyType?: string;
  nonEditable?: boolean;
}

export class TextCellTemplate implements CellTemplate<TextCell> {
  onDeleteHeader?: (rowId: any) => void;

  constructor(onDeleteHeader?: (rowId: any) => void) {
    this.onDeleteHeader = onDeleteHeader;
  }

  getCompatibleCell(uncertainCell: Uncertain<TextCell>): Compatible<TextCell> {
    const text = getCellProperty(uncertainCell, 'text', 'string');
    const hasFormulaError = getCellProperty(uncertainCell, 'hasFormulaError', 'boolean');
    let placeholder: string;
    try {
      placeholder = getCellProperty(uncertainCell, 'placeholder', 'string');
    } catch {
      placeholder = '';
    }
    const value = Number.parseFloat(text);
    return { ...uncertainCell, text, value, placeholder, hasFormulaError };
  }

  update(
    cell: Compatible<TextCell>,
    cellToMerge: UncertainCompatible<TextCell>,
  ): Compatible<TextCell> {
    return this.getCompatibleCell({
      ...cell,
      text: cellToMerge.text,
      placeholder: cellToMerge.placeholder,
    });
  }

  handleKeyDown(
    cell: Compatible<TextCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
  ): { cell: Compatible<TextCell>; enableEditMode: boolean } {
    const char = getCharFromKeyCode(keyCode, shift);
    if (!ctrl && !alt && isAlphaNumericKey(keyCode) && !(shift && keyCode === keyCodes.SPACE))
      return {
        cell: this.getCompatibleCell({ ...cell, text: shift ? char : char.toLowerCase() }),
        enableEditMode: true,
      };
    return { cell, enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER };
  }

  getClassName(cell: Compatible<TextCell>): string {
    const isValid = cell.validator ? cell.validator(cell.text) : true;
    const className = cell.className || '';
    return `${className} ${isValid ? 'valid' : 'invalid'} ${
      cell.placeholder && cell.text === '' ? 'placeholder' : ''
    }`;
  }

  numberWithCommas(x: number) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  render(
    cell: Compatible<TextCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<TextCell>, commit: boolean) => void,
  ): React.ReactNode {
    const { indicatorType, placeholder, text, hasFormulaError } = cell || {};
    const cellText: any = cell?.format ? Number(text) : cell.text;
    const format = cell.format?.toLocaleLowerCase();
    const cellFilteredText =
      format === 'number'
        ? `${this.numberWithCommas(cellText?.toFixed(2))}`
        : format === 'integer'
        ? `${this.numberWithCommas(cellText?.toFixed(0))}`
        : format === 'percentage'
        ? `${cellText?.toFixed(1)}%`
        : format === 'currency'
        ? cellText < 0
          ? `(${cell?.currencyType}${this.numberWithCommas(Math.abs(cellText?.toFixed(2)))})`
          : `${cell?.currencyType}${this.numberWithCommas(cellText?.toFixed(2))}`
        : cellText;

    const handleCellChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onCellChanged(this.getCompatibleCell({ ...cell, text: event.currentTarget.value }), false);
    };

    const handleReference = (input: any) => {
      if (input) {
        input.focus();
        input.setSelectionRange(input?.value?.length, input?.value?.length);
      }
    };

    const handleCellBlur = (event: React.FocusEvent<HTMLInputElement>) => {
      onCellChanged(
        this.getCompatibleCell({ ...cell, text: event.currentTarget.value }),
        (event as any).view?.event?.keyCode !== keyCodes.ESCAPE,
      );
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (isAlphaNumericKey(event.keyCode) || isNavigationKey(event.keyCode))
        event.stopPropagation();
    };

    if (!isInEditMode) {
      return (
        <div
          style={{
            width: '100%',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontSize: '15px',
            display: 'flex',
            justifyContent:
              cell?.colType === 'header'
                ? 'space-between'
                : cell?.tableName === 'block'
                ? 'end'
                : 'flex-start',
            alignItems: 'center',
          }}
        >
          <Flex width={'100%'} alignItems={'center'} justifyContent={'space-between'}>
            <Flex width={'100%'}>
              <IndicatorIcon indicatorType={indicatorType} />
              <TooltipContent
                tooltipText={text}
                text={cellFilteredText}
                placeholder={placeholder}
              />
            </Flex>
            <FormulaErrorBox hasFormulaError={hasFormulaError} />
          </Flex>
        </div>
      );
    }

    return (
      <input
        disabled={cell.nonEditable}
        style={{
          backgroundColor: '#423c82',
          color: 'white',
        }}
        ref={(input) => handleReference(input)}
        defaultValue={cell.text}
        onChange={handleCellChange}
        onBlur={handleCellBlur}
        onCopy={(event) => event.stopPropagation()}
        onCut={(event) => event.stopPropagation()}
        onPaste={(event) => event.stopPropagation()}
        onPointerDown={(event) => event.stopPropagation()}
        placeholder={cell.placeholder}
        onKeyDown={handleKeyDown}
      />
    );
  }
}
