import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
}

const ArrowLeftTable = ({ width = 3, height = 3, color = 'white.900' }: Properties) => {
  return (
    <Icon viewBox='0 0 14 11' width={width} height={height} color={color} mr='1'>
      <path
        d='M5.23908 0.0270683L6.44762 1.27403L3.3831 4.32692L13.8715 4.32692L13.8715 6.04686L3.3831 6.04686L6.44762 9.09975L5.23907 10.3467L0.059607 5.18689L5.23908 0.0270683Z'
        fill='white'
      />
    </Icon>
  );
};

export default ArrowLeftTable;
