import { Tooltip } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
  color?: string;
  type?: string;
  style?: React.CSSProperties;
  onClick?: (data: any) => void;
}

const MapViewIconComponent = ({
  width = 35,
  height = 35,
  color = '#4BB6B9',
  type,
  style,
  onClick,
}: Properties) => {
  return (
    <Tooltip placement='top' label={'Map view'}>
      <svg
        style={style}
        cursor={'pointer'}
        width={type === 'Map' ? width : 35}
        height={type === 'Map' ? height : 35}
        fill={type === 'Map' ? color : 'white'}
        viewBox='0 0 25 25'
        onClick={onClick}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M12 2C8.13 2 5 5.13 5 9C5 12.25 8.25 18.13 11.47 21.61C11.76 21.95 12.24 21.95 12.53 21.61C15.75 18.13 19 12.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5ZM12 0C17.52 0 22 4.48 22 9C22 14.25 14.25 24 12 24C9.75 24 2 14.25 2 9C2 4.48 6.48 0 12 0Z'
          fill={type === 'Map' ? color : 'white'}
        />
      </svg>
    </Tooltip>
  );
};

export default MapViewIconComponent;
