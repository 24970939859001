import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import LoginImage from 'components/Icons/LoginImage';
import Container from 'components/Container';
import Logo from 'components/Icons/Logo';
import { BLOX_SIGNUP_HEADING_TOP, BLOX_SIGNUP_HEADING_BOTTOM } from 'projectConstants';

import LatestNewAuthForm from './LatestNewAuthForm';

interface Properties {
  isOpen: boolean;
  onClose: () => void;
  setIsLogoTriggered?: (value: boolean) => void;
  successModal: any;
  flags?: any;
}

const NewAuthModal = ({ isOpen, onClose, setIsLogoTriggered, successModal }: Properties) => {
  const onCloseAuthModal = () => {
    onClose();
    setIsLogoTriggered?.(false);
  };

  return (
    <Modal isOpen={isOpen} onClose={onCloseAuthModal} size='xl' blockScrollOnMount={false}>
      <ModalOverlay bg='none' backdropFilter='auto' backdropInvert='10%' backdropBlur='5px' />
      <ModalContent my={{ base: '7.5', sm: '15' }} bg={'white !important'} margin={'0 1rem'}>
        <ModalHeader position='relative' padding={0}>
          <Container
            wrapperProperties={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 'fit-content',
              borderRadius: '16px',
              padding: '0',
            }}
            bgColor={
              'linear-gradient(180deg, rgba(101, 98, 207, 0.6) 0%, rgba(75, 182, 185, 0.6) 100%);'
            }
          >
            <Flex
              justifyContent={'space-between'}
              bgColor={'white'}
              width={'100%'}
              position={'relative'}
            >
              <Flex width={'100%'} padding={'2rem'} direction={'column'}>
                <Logo height={10} width={'fit-content'} />
                <Box>
                  <Text
                    variant='secondary'
                    fontFamily='bold'
                    fontSize={'3xl'}
                    width={'60%'}
                    lineHeight='2.5rem'
                    marginTop={'2rem'}
                  >
                    {BLOX_SIGNUP_HEADING_TOP}
                  </Text>
                  <Text
                    variant='secondary'
                    fontFamily='bold'
                    fontSize={'3xl'}
                    lineHeight='2.5rem'
                    width={'60%'}
                    color={'#6562cf'}
                    marginBottom={'2rem'}
                  >
                    {BLOX_SIGNUP_HEADING_BOTTOM}
                  </Text>
                </Box>
                <Box>
                  <LatestNewAuthForm onClose={onClose} successModal={successModal} />
                </Box>
              </Flex>
              <Box position={'absolute'} top={'0'} right={'0'}>
                <LoginImage
                  width={{ start: '180px', sm: '200px' }}
                  height={{ start: '180px', sm: '200px' }}
                  styles={{ borderRadius: '15px' }}
                />
              </Box>
            </Flex>
          </Container>
        </ModalHeader>
        <ModalBody></ModalBody>
      </ModalContent>
    </Modal>
  );
};
export default NewAuthModal;
