import React from 'react';
import { Box, Flex, Slide, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import { getDataKey, getStepNumber, setStepNumber } from 'redux/ItemPageSlice';
import { AppDispatch } from 'utils/GlobalHelpers';

import ArrowLeftBlocks from '../../../../../components/Icons/ArrowLeftBlocks';
import DatePickerDrivers from '../../DesktopPanel/DatePickerDrivers';
import DesktopProperties from '../../DesktopPanel/DesktopProperties';

interface StepThreeProperties {
  itemIndicator: any;
  onSubmitValue: (value: string, id: number, propertyValue: string) => void;
}

const StepThree = ({ itemIndicator, onSubmitValue }: StepThreeProperties) => {
  const dispatch: AppDispatch = useDispatch();
  const dataKey = useSelector(getDataKey);

  const stepNumber = useSelector(getStepNumber);

  const getDisplayProperties = (originalProperties: any) => {
    const properties = JSON.parse(JSON.stringify(originalProperties));
    const startDateKey = Object.keys(properties).find((key) => {
      if (
        `${key}`.toLowerCase().trim() === 'start date' ||
        `${key}`.toLowerCase().trim() === 'start'
      ) {
        return key;
      }
      return false;
    });

    const endDateKey = Object.keys(properties).find((key) => {
      if (`${key}`.toLowerCase().trim() === 'end date' || `${key}`.toLowerCase().trim() === 'end') {
        return key;
      }
      return false;
    });

    if (startDateKey) {
      delete properties[startDateKey];
    }
    if (endDateKey) {
      delete properties[endDateKey];
    }
    return properties;
  };
  return (
    <Slide
      direction='right'
      in={stepNumber === 2}
      unmountOnExit
      style={{
        position: stepNumber === 2 ? 'fixed' : 'relative',
        height: '100%',
        transform: 'translateX(-100%)',
        zIndex: '200',
      }}
    >
      {itemIndicator?.id && (
        <Flex
          textAlign='left'
          alignSelf='left'
          w='100%'
          height={'inherit'}
          bg='linear-gradient(180deg, #3D3380 0%, #7163D0 60%)'
          mb='6px'
          flexDirection='column'
          borderRadius='14px'
          filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))'
          transition='all 0.3s ease-out'
          justifyContent='center'
          cursor='pointer'
        >
          <Box w='100%' h='100vh' p='20px'>
            <Flex
              onClick={() => dispatch(setStepNumber(1))}
              justifyContent='space-between'
              alignItems='center'
              mb='40px'
            >
              <Flex alignItems='center' cursor='pointer'>
                <Box mr='12px' paddingBottom='6px'>
                  <ArrowLeftBlocks />
                </Box>
                <Text fontSize='18px' noOfLines={1} maxW='300px'>
                  {dataKey?.name}
                </Text>
              </Flex>
            </Flex>
            <Box mb='12px'>
              <Text>{itemIndicator?.name}</Text>
            </Box>
            <Box
              p='16px'
              bgColor='#7163D0'
              borderRadius='14px'
              filter='drop-shadow(0px 0px 15.998px rgba(0, 0, 0, 0.25))'
            >
              <DatePickerDrivers
                values={itemIndicator?.properties}
                onSubmitValue={onSubmitValue}
                id={itemIndicator?.id}
              />
              {Object.entries(getDisplayProperties(itemIndicator?.properties)).map(
                ([keys, value], index_: number) => {
                  return (
                    <React.Fragment key={index_}>
                      <DesktopProperties
                        keys={keys}
                        value={value}
                        onSubmitValue={onSubmitValue}
                        dataElementsId={itemIndicator?.id}
                      />
                    </React.Fragment>
                  );
                },
              )}
            </Box>
          </Box>
        </Flex>
      )}
    </Slide>
  );
};

export default StepThree;
