import { Icon } from '@chakra-ui/react';

interface Properties {
  width?: number;
  height?: number;
}

const ChevronDown = ({ width = 1.5, height = 1.5 }: Properties) => {
  return (
    <Icon viewBox='0 0 9 5' width={width} height={height}>
      <path d='M4.5 4.5L0.602886 0L8.39711 0L4.5 4.5Z' fill='#212121' />
    </Icon>
  );
};

export default ChevronDown;
