import Adjustment from 'components/Icons/Adjustment';
import Chart from 'components/Icons/Chart';
import Pencil from 'components/Icons/Pencil';
import Save from 'components/Icons/Save';
import Search from 'components/Icons/Search';

export const HEADER_HEIGHT = '28px';
export const HEADER_HEIGHT_WITH_NAVIGATION = '160px';
export const WELCOME_HEIGHT = '145px';

export const OUTPUT_PAGE_DIFF = '85px';
export const DRIVERS_HEADER = '52px';
export const DRIVERS_BOTTOM = '134px';
export const FORMULA_ERROR = 'There is an error in this formula';
export const ADD_INFORMATION = 'Add information to your input blocks';
export const BLOX_SIGNUP_HEADING = 'Supercharge your financial';
export const BLOX_SIGNUP_HEADING_TOP = 'Supercharge your financial';
export const BLOX_SIGNUP_HEADING_BOTTOM = 'planning and modelling';
export const BLOCK_NOT_FOUND = 'No blocks found with that name';

export const CURRENCY_OPTIONS = [
  {
    value: '£',
    label: 'Pounds',
  },
  {
    value: '€',
    label: 'Euro',
  },
  {
    value: '$',
    label: 'Dollar',
  },
];

export const CHART_COLORS = [
  '#c06de8',
  '#3bb8bc',
  '#3cc5e3',
  '#9fefa9',
  '#e2a6fe',
  '#6fbb78',
  '#3d80cc',
  '#4a5fa3',
  '#ff6361',
  '#bc5090',
  '#ffa600',
  '#58508d',
  '#003f5c',
  '#8a928d',
  '#ffd67f',
  '#f2705e',
  '#b0a06f',
  '#add9a8',
  '#ff8500',
  '#8c00ff',
  '#f6a9bb',
  '#abd9ff',
  '#d86d2c',
  '#ff8014',
  '#78fc78',
  '#fff230',
  '#ff879c',
  '#ff9494',
  '#bb805a',
  '#cf8a47',
  '#b40000',
  '#d05098',
  '#1e5aa8',
  '#fac80a',
  '#543324',
  '#1b2a34',
  '#545955',
  '#00852b',
  '#7fc475',
  '#fdc383',
  '#58ab41',
  '#91501c',
  '#afbed6',
  '#97cbd9',
  '#f9b7a5',
  '#f06d61',
  '#7396c8',
  '#bcb4a5',
  '#671fa1',
  '#f58624',
  '#d67923',
  '#069d9f',
  '#56472f',
  '#26469a',
  '#4861ac',
  '#b7d425',
  '#00aaa4',
  '#9cd6cc',
  '#a5ca18',
  '#deea92',
  '#f89a39',
  '#ee5434',
  '#901f76',
  '#f9a777',
  '#ad6140',
  '#ef5828',
  '#cddd34',
  '#70819a',
  '#75657d',
  '#f48147',
  '#897d62',
  '#19325a',
  '#00451a',
  '#708e7c',
  '#88605e',
  '#720012',
  '#dd982e',
  '#009ece',
  '#ffcf0b',
  '#fcac00',
  '#5f3109',
  '#ec441d',
  '#969696',
  '#1c58a7',
  '#0e3e9a',
  '#312b87',
  '#8a12a8',
  '#646464',
  '#c8c8c8',
  '#a47624',
  '#468a5f',
  '#3fb6a9',
  '#9dc3f7',
  '#476fb6',
  '#872b17',
  '#7b5d41',
  '#8e5597',
  '#564e9d',
  '#9195ca',
  '#d3359d',
  '#ff9ecd',
  '#f17880',
  '#f3c988',
  '#faa964',
  '#ffec6c',
  '#77c9d8',
  '#60ba76',
  '#441a91',
  '#3e95b6',
  '#ffc995',
  '#372100',
  '#aa7d55',
  '#469bc3',
  '#68c3e2',
  '#d3f2ea',
  '#a06eb9',
  '#cda4de',
  '#e2f99a',
  '#77774e',
  '#ff6d77',
];

export const STATUS = Object.freeze({
  IDLE: 'IDLE',
  FAILED: 'FAILED',
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
});
export const DASHBOARD_CHART_TYPE = {
  KPI: 1,
  CHART: 2,
  TABLE: 3,
  TEXT_BOX: 4,
};
export const OUTPUT_SIDEBAR_INFO = [
  {
    id: 1,
    title: 'Analyse your KPIs',
    description: 'Click through the tabs to view future projections for each KPI',
    icon: Chart,
  },
  {
    id: 2,
    title: 'Drill in to the detail',
    description: 'Expand the table to drill into the values for each of your items',
    icon: Search,
  },
  {
    id: 3,
    title: 'Adjust your inputs',
    description: 'Use the sliders to quickly adjust your inputs and model the outcomes',
    icon: Adjustment,
  },
  {
    id: 4,
    title: 'Modify your inputs',
    description: 'Go back and adjust your inputs',
    icon: Pencil,
  },
  {
    id: 5,
    title: 'Save your model',
    description: 'Save your data and come back at any time to view and update it',
    icon: Save,
  },
];

export const MONTH_NAMES = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
