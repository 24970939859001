import { Box, Button, Flex, Text, useMediaQuery, VStack } from '@chakra-ui/react';
import { Form, Formik } from 'formik';
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import FormField from 'components/FormField';
import { FetchUserDetails, SetUserModalVisible, UpdateUserDetails } from 'redux/UserSlice';
import { SignUpUser } from 'models/UsersSlice.interface';
import { UserDetailsSchema } from 'validation/schemas/UserManagementSchema';

import UserImage from './UserImage';
import UserHeading from './UserHeading';
import UserFooter from './UserFooter';
import UserShimmer from './UserManagementShimmer';

interface Properties {
  userData: any;
  updateUserDetail: (data: SignUpUser) => Promise<void>;
  getUserDetails: (data: any) => Promise<void>;
}

const ProfileForm = ({ getUserDetails, updateUserDetail, userData }: Properties) => {
  const [isSmallLaptop] = useMediaQuery('(max-width: 768px)');
  const [isLaptop] = useMediaQuery('(max-width: 1024px)');
  const [isPasswordChangeVisible, setPasswordChangeVisibility] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    const getUserDetail = async () => {
      await getUserDetails({});
      setLoader(false);
    };
    getUserDetail();
  }, []);

  const handleSubmit = async (form: any, setSubmitting: (data: boolean) => void) => {
    await updateUserDetail({ request_body: form, toaster: true });
    setSubmitting(false);
  };

  const handleImageDelete = () => {
    updateUserDetail({
      request_body: { profile_image_url: '' },
      toaster: {
        successMessage: 'Image Deleted Successfully',
      },
    });
  };

  const handleImageUpload = (data: string) => {
    updateUserDetail({
      request_body: { profile_image_url: data },
      toaster: {
        successMessage: 'Image Uploaded Successfully',
      },
    });
  };

  if (loader) return <UserShimmer />;
  return (
    <Flex width={'100%'} height={'100%'} flexDir='column'>
      <Formik
        initialValues={{
          firstname: userData?.firstname || '',
          lastname: userData?.lastname || '',
          job_role: userData?.job_role || '',
          username: userData?.username || '',
          profile_image_url: userData?.profile_image_url || '',
          password: '',
          confirmPassword: '',
        }}
        onSubmit={(form, { setSubmitting }) => {
          handleSubmit(form, setSubmitting);
        }}
        validationSchema={UserDetailsSchema}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }: any) => {
          return (
            <Form>
              <Box height={'100%'} width={'100%'}>
                <UserHeading heading='Profile' />
                <Flex height={`calc(100% - 63px)`} width={'100%'} flexDir={'column'}>
                  <Box
                    width={'100%'}
                    position='relative'
                    overflow={'hidden'}
                    overflowY={'auto'}
                    height={'100%'}
                  >
                    <Flex width={'100%'} flexDir={isLaptop ? 'column-reverse' : 'row'} p='2'>
                      <Box width={isLaptop ? '100%' : '70%'}>
                        <VStack
                          spacing='5'
                          alignItems={'flex-start'}
                          width={'100%'}
                          fontFamily='regular_roboto'
                        >
                          <Flex
                            alignItems={'flex-start'}
                            gap='5'
                            width={'100%'}
                            flexDir={isSmallLaptop ? 'column' : 'row'}
                          >
                            <FormField
                              label='First name'
                              name='firstname'
                              type='text'
                              labelStyles={{
                                fontSize: '15px',
                                color: '#212121',
                              }}
                              inputStyles={{
                                maxWidth: '100%',
                                boxShadow: '0px 0px 0px 1.5px #8B8AAE',
                                height: '35px',
                                paddingInlineStart: '8px',
                              }}
                              placeholder='Enter first name'
                              error={errors.firstname}
                              touched={touched.firstname}
                              disabled={isSubmitting}
                            />
                            <FormField
                              label='Last Name'
                              name='lastname'
                              type='text'
                              labelStyles={{
                                fontSize: '15px',
                                color: '#212121',
                              }}
                              inputStyles={{
                                maxWidth: '100%',
                                boxShadow: '0px 0px 0px 1.5px #8B8AAE',
                                height: '35px',
                                paddingInlineStart: '8px',
                              }}
                              placeholder='Enter last name'
                              error={errors.lastname}
                              touched={touched.lastname}
                              disabled={isSubmitting}
                            />
                          </Flex>
                          <FormField
                            label='Email address'
                            name='username'
                            type='email'
                            labelStyles={{
                              fontSize: '15px',
                              color: '#212121',
                            }}
                            inputStyles={{
                              maxWidth: '100%',
                              boxShadow: '0px 0px 0px 1.5px #8B8AAE',
                              height: '35px',
                              paddingInlineStart: '8px',
                            }}
                            styles={{
                              marginTop: '.7rem',
                            }}
                            placeholder='name@email.com '
                            error={errors.username}
                            touched={touched.username}
                            disabled={isSubmitting}
                          />
                          <FormField
                            label='Job title'
                            name='job_role'
                            type='text'
                            labelStyles={{
                              fontSize: '15px',
                              color: '#212121',
                            }}
                            inputStyles={{
                              maxWidth: '100%',
                              boxShadow: '0px 0px 0px 1.5px #8B8AAE',
                              height: '35px',
                              paddingInlineStart: '8px',
                            }}
                            styles={{
                              marginTop: '.7rem',
                            }}
                            placeholder='e.g CEO'
                            error={errors.job_role}
                            touched={touched.job_role}
                            disabled={isSubmitting}
                          />
                          <Box mt='.7rem !important'>
                            <Text color='#212121' mb='2' fontSize='15px'>
                              Password
                            </Text>
                            <Button
                              h='9'
                              w='auto'
                              borderWidth='1'
                              borderRadius='10px'
                              fontWeight={400}
                              fontSize='15px'
                              textTransform='capitalize'
                              color='grey.100'
                              boxShadow='0px 0px 0px 1.5px #8B8AAE'
                              padding='3'
                              onClick={() => setPasswordChangeVisibility(!isPasswordChangeVisible)}
                            >
                              Change Password
                            </Button>
                          </Box>
                          {isPasswordChangeVisible && (
                            <Flex
                              alignItems={'flex-start'}
                              gap='5'
                              width={'100%'}
                              flexDir={isSmallLaptop ? 'column' : 'row'}
                            >
                              <FormField
                                label='New Password'
                                name='password'
                                type='password'
                                labelStyles={{
                                  fontSize: '15px',
                                  color: '#212121',
                                }}
                                inputStyles={{
                                  maxWidth: '100%',
                                  boxShadow: '0px 0px 0px 1.5px #8B8AAE',
                                  height: '35px',
                                  paddingInlineStart: '8px',
                                }}
                                placeholder='********'
                                error={errors.password}
                                touched={touched.password}
                                disabled={isSubmitting}
                              />
                              <FormField
                                label='Confirm new Password'
                                name='confirmPassword'
                                type='password'
                                labelStyles={{
                                  fontSize: '15px',
                                  color: '#212121',
                                }}
                                inputStyles={{
                                  maxWidth: '100%',
                                  boxShadow: '0px 0px 0px 1.5px #8B8AAE',
                                  height: '35px',
                                  paddingInlineStart: '8px',
                                }}
                                placeholder='********'
                                error={errors.confirmPassword}
                                touched={touched.confirmPassword}
                                disabled={isSubmitting}
                              />
                            </Flex>
                          )}
                        </VStack>
                      </Box>
                      <UserImage
                        disabled={isSubmitting}
                        type='profile'
                        bucketName={'blox-user-profile'}
                        imageUrl={values.profile_image_url}
                        setFormValue={(value: string) => {
                          setFieldValue('profile_image_url', value);
                        }}
                        imageLoading={imageLoading}
                        handleImageLoading={(value: boolean, data?: any) => {
                          setImageLoading(value);
                          if (data) {
                            handleImageUpload(data);
                          }
                        }}
                        handleImageDelete={() => {
                          setFieldValue('profile_image_url', '');
                          handleImageDelete();
                        }}
                      />
                    </Flex>
                  </Box>
                  <UserFooter
                    isSubmitting={isSubmitting}
                    disabled={imageLoading || Object.keys(errors).length > 0 || isSubmitting}
                  />
                </Flex>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
};

const stateToProperty = (state: any) => {
  return {
    userData: state.userSlice.userData,
  };
};

const mapDispatchToProperties = (dispatch: any) => {
  return {
    getUserDetails: (data: any) => dispatch(FetchUserDetails(data)),
    setUserModalVisible: (data: any) => dispatch(SetUserModalVisible(data)),
    updateUserDetail: (data: SignUpUser) => dispatch(UpdateUserDetails(data)),
  };
};

export default connect(stateToProperty, mapDispatchToProperties)(ProfileForm);
