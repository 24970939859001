import { Button, Grid, Text, useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { ChangeEvent, useState } from 'react';

import Checkbox from 'components/Checkbox';
import FormField from 'components/FormField';
import User from 'models/response/user.interface';
import apiService from 'services';
import registerSchema from 'validation/schemas/registerSchema';
import CustomToast from 'components/CustomToast';
import editUserSchema from 'validation/schemas/UsersEditSchema';
import { SignUpUser } from 'models/UsersSlice.interface';

import ChangePassword from './ChangePassword';

interface FormValues {
  firstName: string;
  lastName: string;
  role: string;
  company: string;
  email: string;
  password: string;
  confirmPassword: string;
}

interface Properties {
  onClose: () => void;
  user: User | null;
}

const AuthForm = ({ onClose, user }: Properties) => {
  const toast = useToast();
  const initialValues: FormValues = {
    firstName: '',
    lastName: '',
    role: '',
    company: '',
    email: '',
    password: '',
    confirmPassword: '',
  };

  const initialEditValues: FormValues = {
    firstName: user?.firstname || '',
    lastName: user?.lastname || '',
    role: user?.job_role || '',
    company: user?.company || '',
    email: user?.username || '',
    password: '',
    confirmPassword: '',
  };

  const queryClient = useQueryClient();
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [emailError, setEmailError] = useState('');

  const { mutate } = useMutation(
    ({ request_body }: SignUpUser) => apiService.update_user_details({ request_body }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['user']);
        onClose();
        toast({
          duration: 2500,
          render: () => <CustomToast title='Updated successfully' status='success' />,
        });
      },
      onError: (response: any) => {
        toast({
          duration: 2500,
          render: () => <CustomToast title={response?.response?.data?.message} status='error' />,
        });
        if (response.response.data.message.includes('already exists')) {
          setEmailError(response?.response?.data?.message || 'Something went wrong..');
        }
      },
    },
  );

  const onFocus = () => {
    setEmailError('');
  };

  const onCheckTerms = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedTerms(event.target.checked);
  };

  const onSubmitForm = (values: FormValues) => {
    mutate({
      request_body: {
        username: values.email,
        password: values.password,
        firstname: values.firstName,
        lastname: values.lastName,
        job_role: values.role,
        company: values.company,
      },
    });
  };

  return (
    <Formik
      initialValues={user?.is_anonymous ? initialValues : initialEditValues}
      onSubmit={onSubmitForm}
      validationSchema={user?.is_anonymous ? registerSchema : editUserSchema}
    >
      {({ errors, touched }) => (
        <Form>
          <Grid
            templateColumns={{ base: '100%', md: '1fr 1fr' }}
            columnGap='8'
            rowGap={4}
            mb={user?.is_anonymous ? '7' : '4'}
          >
            <FormField
              label='First name'
              name='firstName'
              placeholder='Enter first name'
              error={errors.firstName}
              touched={touched.firstName}
            />
            <FormField
              label='Last name'
              name='lastName'
              placeholder='Enter last name'
              error={errors.lastName}
              touched={touched.lastName}
            />
            <FormField
              label='Job Role'
              name='role'
              placeholder='e.g CEO'
              error={errors.role}
              touched={touched.role}
            />
            <FormField
              label='Company'
              name='company'
              placeholder='Enter Company'
              error={errors.company}
              touched={touched.company}
            />
            <FormField
              label='Email address'
              name='email'
              type='email'
              placeholder='name@email.com'
              error={errors.email || emailError}
              touched={touched.email}
              onFocus={onFocus}
            />
            {user?.is_anonymous && (
              <FormField
                label='Enter your password'
                name='password'
                type='password'
                placeholder='*********'
                error={errors.password}
                touched={touched.password}
              />
            )}
          </Grid>

          {!user?.is_anonymous && (
            <ChangePassword
              passwordErrors={errors.password}
              confirmPasswordError={errors.confirmPassword}
              passwordTouched={touched.password}
              confirmPasswordTouched={touched.confirmPassword}
            />
          )}

          {user?.is_anonymous && (
            <Checkbox
              isChecked={checkedTerms}
              onChange={onCheckTerms}
              label={
                <Text variant='secondary' fontFamily='regular_roboto' fontSize='3xs'>
                  I have read and agree to the Blox{' '}
                  <a
                    href='https://www.bloxapp.io/terms-of-use'
                    target='_blank'
                    rel='noreferrer'
                    style={{ textDecoration: 'underline', margin: '0 .2rem' }}
                  >
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a
                    href='https://www.bloxapp.io/privacy-policy'
                    target='_blank'
                    rel='noreferrer'
                    style={{ textDecoration: 'underline', marginLeft: '.2rem' }}
                  >
                    Privacy Policy
                  </a>
                </Text>
              }
            />
          )}

          <Button
            type='submit'
            isDisabled={user?.is_anonymous ? !checkedTerms : false}
            w='full'
            h='12'
            mt='2'
            borderRadius='xs'
            bg='green.900'
          >
            {user?.is_anonymous ? 'save your free plan' : 'save your details'}
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default AuthForm;
