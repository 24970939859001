export default {
  '4xs': '0.5', // 8px
  '3xs': '0.625rem', // 10px
  '2xs': '0.75rem', // 12px
  xs: '0.875rem', // 14px
  sm: '1rem', // 16px
  md: '1.125rem', // 18px
  lg: '1.25rem', // 20px
  xl: '1.5rem', // 24px
  '2xl': '1.625rem', // 26px
  '3xl': '1.75rem', // 28px
  '4xl': '1.875rem', // 30px
};
