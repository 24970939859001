import React, { useRef } from 'react';
import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  Tooltip,
  useBoolean,
  useOutsideClick,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';

import BranchIcon from 'components/Icons/BranchIcon';
import { getAllScenarios } from 'redux/ScenarioSlice';
import { Scenario } from 'models/response/scenario.interface';
import { AppDispatch } from 'utils/GlobalHelpers';
import { getDashboardSelectedScenario, selectDashboardScenario } from 'redux/DashboardSlice';

const SelectScenario = () => {
  const dispatch: AppDispatch = useDispatch();
  const reference = useRef() as React.MutableRefObject<HTMLInputElement>;

  const scenarios = useSelector(getAllScenarios);
  const selectedScenario = useSelector(getDashboardSelectedScenario);

  const [scenariosPopover, setScenariosPopover] = useBoolean();

  const selectScenario = (scenario: any) => {
    dispatch(selectDashboardScenario(scenario));
  };

  useOutsideClick({
    ref: reference,
    handler: () => {
      setScenariosPopover.off();
    },
  });

  return (
    <Stack maxWidth={'200px'}>
      {scenarios?.length > 0 && (
        <Popover
          placement='bottom-start'
          closeOnBlur={false}
          isOpen={scenariosPopover}
          onOpen={setScenariosPopover.on}
          onClose={setScenariosPopover.off}
          isLazy
          lazyBehavior='keepMounted'
        >
          <Box ref={reference}>
            <PopoverTrigger>
              <Flex
                textAlign='left'
                alignSelf='left'
                w='100%'
                minH={'35px'}
                bgColor='#7163D0'
                px={2}
                borderRadius={'4px'}
                filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))'
                transition='all 0.3s ease-out'
                justifyContent='space-between'
                alignItems='center'
                cursor='pointer'
              >
                <Flex width='80%' alignItems='center'>
                  <BranchIcon height={7} width={7} />
                  <Tooltip label={selectedScenario?.name}>
                    <Text
                      alignContent='center'
                      color='white'
                      fontSize='xs'
                      whiteSpace='nowrap'
                      overflow='hidden'
                      textOverflow={'ellipsis'}
                      ml={3}
                    >
                      {/* ddsadsa */}
                      {selectedScenario?.name}
                    </Text>
                  </Tooltip>
                </Flex>
                <ChevronDownIcon boxSize={{ base: 6 }} color='white' />
              </Flex>
            </PopoverTrigger>

            <PopoverContent
              ml={{ base: '5', md: '3' }}
              filter='drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))'
              bg='white'
              border='none'
              maxWidth='80vw'
              borderRadius='14px'
            >
              <Box pt={4} pb={1} px={5}>
                <Text color='#6562CF' fontSize={'xs'} fontWeight={'600'}>
                  Select Scenario
                </Text>
              </Box>
              <PopoverBody>
                <Flex direction={'column'}>
                  {scenarios?.length > 0 &&
                    scenarios.map((scenario: Scenario) => {
                      return (
                        <Flex
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          key={scenario.id}
                          cursor='pointer'
                          borderRadius='14px'
                          px='1'
                          py='2'
                          onClick={(event) => {
                            event.stopPropagation();
                            selectScenario(scenario);
                            setScenariosPopover.off();
                          }}
                          bg={selectedScenario?.id === scenario?.id ? '#EFEEFE' : 'transparent'}
                        >
                          <Box display={'flex'} color={'#8B89B1'} alignItems={'center'} w={'70%'}>
                            {selectedScenario?.id === scenario?.id && <CheckIcon />}
                            <Text
                              px='2'
                              py='0'
                              color='black'
                              fontSize={'xs'}
                              whiteSpace='nowrap'
                              overflow='hidden'
                              textOverflow={'ellipsis'}
                            >
                              {scenario?.name}
                            </Text>
                          </Box>
                        </Flex>
                      );
                    })}
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Box>
        </Popover>
      )}
    </Stack>
  );
};

export default SelectScenario;
