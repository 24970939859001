import {
  Box,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useOutsideClick,
} from '@chakra-ui/react';
import { Collapse } from 'react-collapse';
import { useDispatch, useSelector } from 'react-redux';
import React, { useRef, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useParams } from 'react-router-dom';

import InputField from 'components/InputField';
import {
  PostDeleteDimensionItem,
  UpdateItemName,
  fetchDimensionItemsGroup,
  getDataIndicators,
  getDisplayInfo,
  getOpenItem,
  getRenameItemName,
  setDisplayInfo,
  setIsItemRenamed,
  setRenameItemName,
  setopenItem,
  setSelectedElement,
} from 'redux/ItemPageSlice';
import { FetchBlockItemDimensions, getSelectedDriver, handleFilters } from 'redux/PlanPageSlice';
import { getBaseScenario } from 'redux/ScenarioSlice';
import DeleteModal from 'components/Modals/DeleteModal';
import { AppDispatch } from 'utils/GlobalHelpers';

import useClickAndDoubleClickHandler from '../../../../hooks/useClickAndDoubleClickHandler';
import arrowDown from '../Icons/arrowDown.svg';
import arrowUp from '../Icons/arrowUp.svg';
import infoIcon from '../Icons/infoIcon.svg';

import DatePickerDrivers from './DatePickerDrivers';
import DesktopProperties from './DesktopProperties';

interface DesktopElementsProperties {
  _index: number;
  dataKey: any;
  styles: any;
  onSubmitValue: (value: string, id: number, propertyValue: string) => void;
  longClickCheck: any;
  isLongClicked: boolean;
  planPermission?: boolean;
}

const DesktopElements = ({
  _index,
  dataKey,
  styles,
  onSubmitValue,
  longClickCheck,
  isLongClicked,
  planPermission,
}: DesktopElementsProperties) => {
  const { blockId } = useParams();
  const [itemNameField, setItemNameField] = useState('');
  const [isDeleteModal, setIsDeleteModal] = useState(false);
  const [DeleteIdModal, setDeleteIdModal]: any = useState(null);
  const [DeleteNameModal, setDeleteNameModal] = useState('');
  const dispatch: AppDispatch = useDispatch();
  const dataIndicators = useSelector(getDataIndicators);
  const displayInfo = useSelector(getDisplayInfo);
  const currentDriver = useSelector(getSelectedDriver);
  const renameItemName = useSelector(getRenameItemName);
  const show = useSelector(getOpenItem);
  const reference = useRef() as React.MutableRefObject<HTMLInputElement>;
  const baseScenario = useSelector(getBaseScenario);

  const handleShow = (id: number) => {
    if (show === id) {
      return dispatch(setopenItem(null));
    }
    return dispatch(setopenItem(id));
  };

  const renameItem = (event: any, id: number) => {
    event.stopPropagation();
    dispatch(setDisplayInfo(null));
    if (renameItemName === id) {
      return dispatch(setRenameItemName(0));
    }
    return dispatch(setRenameItemName(id));
  };

  useOutsideClick({
    ref: reference,
    handler: () => {
      dispatch(setDisplayInfo(null));
    },
  });

  const toggleInfoPopup = (id: number | null) => {
    if (displayInfo === id) {
      return dispatch(setDisplayInfo(null));
    }
    return dispatch(setDisplayInfo(id));
  };

  const showOrRename = useClickAndDoubleClickHandler(handleShow, renameItem);

  const UpdateItemApi = async (item_id: Number) => {
    const requestBody = { name: itemNameField, scenario_id: baseScenario?.id };
    await dispatch(
      UpdateItemName({
        item_id,
        request_body: requestBody,
        toaster: {
          successMessage: 'Successfully updated',
          errorMessage: 'An item with this name already exists',
        },
      }),
    );
    await dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));
    await dispatch(
      FetchBlockItemDimensions({
        blockId,
      }),
    );
    await dispatch(handleFilters());
  };

  const onKeyPressAction = async (event: any, item_id: number) => {
    if (event.code === 'Enter') {
      await UpdateItemApi(item_id);
      setIsItemRenamed(true);
      setItemNameField('');
    }
    if (event.code === 'Escape') setItemNameField('');
  };

  const getDisplayProperties = (originalProperties: any) => {
    const properties = JSON.parse(JSON.stringify(originalProperties));
    const startDateKey = Object.keys(properties).find((key) => {
      if (
        `${key}`.toLowerCase().trim() === 'start date' ||
        `${key}`.toLowerCase().trim() === 'start'
      ) {
        return key;
      }
      return false;
    });

    const endDateKey = Object.keys(properties).find((key) => {
      if (`${key}`.toLowerCase().trim() === 'end date' || `${key}`.toLowerCase().trim() === 'end') {
        return key;
      }
      return false;
    });
    if (startDateKey) {
      delete properties[startDateKey];
    }
    if (endDateKey) {
      delete properties[endDateKey];
    }
    return properties;
  };

  const DeleteDimItemModal = (event: any, name: any, id: any) => {
    setDeleteIdModal(id);
    setDeleteNameModal(name);
    setIsDeleteModal(true);
    event.stopPropagation();
  };

  const onDeleteModel = async () => {
    await dispatch(
      PostDeleteDimensionItem({
        ItemId: DeleteIdModal,
        parameters: { scenario_id: baseScenario?.id },
      }),
    );
    await dispatch(fetchDimensionItemsGroup({ currentDriver, scenario_id: baseScenario?.id }));
    setIsDeleteModal(false);
    setDeleteIdModal(null);
    setDeleteNameModal('');
    await dispatch(
      FetchBlockItemDimensions({
        blockId,
      }),
    );
    await dispatch(handleFilters());
  };

  return (
    <>
      {dataIndicators[dataKey?.name].items?.map((dataElements: any, index: number) => {
        const draggableId: string = JSON.stringify({
          item_id: dataElements.id,
          property_id: currentDriver?.group_property_id,
          value: dataElements?.name,
        });
        return (
          <React.Fragment key={index}>
            <Draggable key={`${dataElements.id}`} draggableId={draggableId} index={index}>
              {(provided: any) => (
                <Flex
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  key={index}
                  textAlign='left'
                  alignSelf='left'
                  w='100%'
                  minH='50px'
                  bgColor='#7163D0'
                  mb='10px'
                  p='0'
                  flexDirection='column'
                  borderRadius='14px'
                  filter='drop-shadow(0px 0px 15.998px rgba(0, 0, 0, 0.25))'
                  transition='all 0.3s ease-out'
                  zIndex={show === dataElements?.id || displayInfo === dataElements?.id ? '2' : '0'}
                  css={styles.container(
                    show === dataElements?.id || displayInfo === dataElements?.id,
                  )}
                >
                  <Box
                    onClick={(event) => {
                      showOrRename(event, dataElements?.id);
                      dispatch(setSelectedElement(_index));
                      dispatch(setDisplayInfo(null));
                    }}
                    position='relative'
                    p='16px'
                    onBlur={() => itemNameField && UpdateItemApi(dataElements?.id)}
                  >
                    <Flex
                      justifyContent='space-between'
                      className='infoButtonParent'
                      {...longClickCheck()}
                    >
                      {renameItemName === dataElements?.id && !planPermission ? (
                        <InputField
                          width={'fit-content'}
                          outline={'none !important'}
                          boxShadow={'none !important'}
                          border={'1px white solid !important'}
                          padding={'15px 5px 15px 10px'}
                          height={'28px'}
                          fontSize={'16px !important'}
                          fontFamily={'Roboto Bold'}
                          defaultValue={dataElements?.name}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setItemNameField(event.target.value);
                          }}
                          onKeyDown={(event: React.ChangeEvent<HTMLInputElement>) => {
                            if (event.target.value.trim().length > 0)
                              onKeyPressAction(event, dataElements?.id);
                          }}
                          _placeholder={{ color: '#ffffff66' }}
                          autoFocus
                        />
                      ) : (
                        <Tooltip label={dataElements?.name}>
                          <Text
                            fontFamily='Roboto Bold'
                            fontSize='sx'
                            lineHeight='9'
                            fontWeight='bold'
                            padding={'2px'}
                            noOfLines={isLongClicked ? 0 : 1}
                          >
                            {dataElements?.name}
                          </Text>
                        </Tooltip>
                      )}
                      <Flex alignItems='center' position='relative' ref={reference}>
                        <Menu isOpen={displayInfo === dataElements?.id}>
                          <MenuButton
                            width={'auto'}
                            marginRight='1rem'
                            cursor={planPermission ? 'not-allowed' : 'pointer'}
                            onClick={
                              planPermission
                                ? undefined
                                : (event) => {
                                    event.stopPropagation();
                                    toggleInfoPopup(dataElements?.id);
                                  }
                            }
                          >
                            <Image src={infoIcon} w='28px' height='18px' className='infoButton' />
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              onClick={(event) => renameItem(event, dataElements?.id)}
                              padding='.5rem 1.5rem'
                            >
                              Rename
                            </MenuItem>
                            <MenuItem
                              onClick={(event) => {
                                DeleteDimItemModal(event, dataElements?.name, dataElements?.id);
                              }}
                              padding='.5rem 1.5rem'
                            >
                              Delete
                            </MenuItem>
                          </MenuList>
                        </Menu>
                        <Image src={show === dataElements?.id ? arrowUp : arrowDown} w='12px' />
                      </Flex>
                    </Flex>
                  </Box>
                  <Collapse isOpened={show === dataElements?.id} width='100%'>
                    <Box mb='15px' p='0 16px 16px 16px'>
                      <DatePickerDrivers
                        values={dataElements?.properties}
                        onSubmitValue={onSubmitValue}
                        id={dataElements?.id}
                        timescale={dataElements?.timescale}
                      />
                      {Object.entries(getDisplayProperties(dataElements?.properties)).map(
                        ([keys, value], index_: number) => {
                          return (
                            <React.Fragment key={index_}>
                              <DesktopProperties
                                keys={keys}
                                value={value}
                                onSubmitValue={onSubmitValue}
                                dataElementsId={dataElements?.id}
                                planPermission={planPermission}
                              />
                            </React.Fragment>
                          );
                        },
                      )}
                    </Box>
                  </Collapse>
                </Flex>
              )}
            </Draggable>
          </React.Fragment>
        );
      })}
      <DeleteModal
        isOpen={isDeleteModal}
        onClose={() => setIsDeleteModal(false)}
        itemName={DeleteNameModal}
        onDelete={onDeleteModel}
      />
    </>
  );
};

export default DesktopElements;
