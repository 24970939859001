import { Flex } from '@chakra-ui/react';

import Spinner from 'components/Spinner';
import { HEADER_HEIGHT } from 'projectConstants';

interface Properties {
  isGridElement?: boolean;
  bgColor?: string;
  height?: string;
  width?: string;
  style?: React.CSSProperties;
  flags?: any;
}

const FullScreenLoader = ({ isGridElement, bgColor, height, width, style }: Properties) => {
  return (
    <>
      {isGridElement ? (
        <Flex
          style={style}
          data-testid='loader-component'
          h={height || `calc(100vh - ${HEADER_HEIGHT})`}
          w={width}
          position='absolute'
          align='center'
          left='50%'
          transform='translateX(-50%)'
        >
          <Spinner />
        </Flex>
      ) : (
        <Flex
          style={style}
          data-testid='loader-component'
          justify='center'
          align='center'
          h={height || `calc(100vh - ${HEADER_HEIGHT})`}
          w={width}
          bg={bgColor || 'gradient.body'}
        >
          <Spinner />
        </Flex>
      )}
    </>
  );
};

export default FullScreenLoader;
