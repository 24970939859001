import { Menu, MenuButton, MenuList, MenuItem, Button, Flex } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';

import AddIconComponent from 'components/Icons/AddIcon';
import ArrowDropdownIconComponent from 'components/Icons/ArrowDropdownIconComponent';
import ChartIconComponent from 'components/Icons/ChartIcon';
import KPIIconComponent from 'components/Icons/KpiIcon';
import TableIcomComponent from 'components/Icons/TableIcon';
import TextBoxIconComponent from 'components/Icons/TextBoxIcon';
import { AppDispatch } from 'utils/GlobalHelpers';
import { handleWidgetType } from 'redux/DashboardSlice';

import SelectScenario from './SelectScenario';

const DashboardingHeader = () => {
  const dispatch: AppDispatch = useDispatch();

  const handleAddWidget = (widgetType: number) => dispatch(handleWidgetType(widgetType));

  return (
    <Flex justifyContent={'space-between'}>
      <SelectScenario />
      <Menu variant={'dashboarding'}>
        {({ isOpen }) => {
          return (
            <>
              <MenuButton
                backgroundColor={'#5A58AB'}
                padding={'.5rem 1rem'}
                as={Button}
                border={'none'}
                rightIcon={
                  <ArrowDropdownIconComponent
                    style={{ margin: '0 .5rem' }}
                    height={12}
                    width={12}
                    color='white'
                  />
                }
                leftIcon={
                  <AddIconComponent
                    style={{ margin: '0 .5rem' }}
                    color='#62B5BA'
                    height={18}
                    width={18}
                  />
                }
                fontSize={'md'}
                fontWeight={'400'}
                width={'fit-content'}
                height={'45px'}
              >
                Create
              </MenuButton>
              {isOpen && (
                <MenuList zIndex={18}>
                  <MenuItem onClick={() => handleAddWidget(1)}>
                    <KPIIconComponent
                      width={'21.05px'}
                      height={'21.05px'}
                      color={'#787878'}
                      marginRight='.5rem'
                    />
                    KPI
                  </MenuItem>
                  <MenuItem onClick={() => handleAddWidget(2)}>
                    <ChartIconComponent
                      marginRight='.5rem'
                      width={'21.05px'}
                      height={'21.05px'}
                      color={'#787878'}
                    />
                    Chart
                  </MenuItem>
                  <MenuItem onClick={() => handleAddWidget(3)}>
                    <TableIcomComponent
                      marginRight='.5rem'
                      width={'21.05px'}
                      height={'21.05px'}
                      color={'#787878'}
                    />{' '}
                    Table
                  </MenuItem>
                  <MenuItem onClick={() => handleAddWidget(4)}>
                    <TextBoxIconComponent
                      marginRight='.5rem'
                      width={'21.05px'}
                      height={'21.05px'}
                      color={'#787878'}
                    />{' '}
                    Text box
                  </MenuItem>
                </MenuList>
              )}
            </>
          );
        }}
      </Menu>
    </Flex>
  );
};

export default DashboardingHeader;
