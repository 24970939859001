import { ChevronDownIcon, InfoIcon } from '@chakra-ui/icons';
import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  VStack,
} from '@chakra-ui/react';
import { ErrorMessage, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';

import {
  addStepTwoFormData,
  handleActionStepNext,
  getFormValues,
  clearStepperFormPropertyData,
  getNoheadersStatus,
  setNoheaders,
} from 'redux/DataImportSlice';
import { ActionSteptwoSchemaDataType } from 'validation/schemas/ActionStepSchema';
import { getBlocks } from 'redux/ModelsSlice';
import { getAllScenarios } from 'redux/ScenarioSlice';
import { Scenario } from 'models/response/scenario.interface';
import CustomCheckboxIcon from 'components/Icons/CustomCheckboxIcon';

import { textStyles } from '../../Helper';
import ActionStepFooter from '../ActionStepFooter';

interface Properties {
  setHelpModalSeleceted: any;
  dataType: string;
}

const DataType = ({ setHelpModalSeleceted, dataType }: Properties) => {
  const dispatch: any = useDispatch();
  const [selectBlockId, setSelectBlockId] = useState<number | null>();
  const getFormValue = useSelector(getFormValues);
  const allScenarios = useSelector(getAllScenarios);
  const [selectBlockDetails, setSelectBlockDetails] = useState<any>(null);
  const isHeader = useSelector(getNoheadersStatus);
  const [selectedScenarioId, setSelectedScenarioId] = useState<number | null>();
  const [selectedScenarioDetails, setSelectedScenarioDetails] = useState<any>('');
  const dimesnsionList = useSelector(getBlocks);

  useMemo(() => {
    const calculateSelectedBlockDetails = dimesnsionList?.blocks?.find((item: any) => {
      if (selectBlockId && !getFormValue.blockId && item?.id === selectBlockId) {
        return item;
      }
      if (getFormValue?.blockId && !selectBlockId && item?.id === Number(getFormValue?.blockId)) {
        return item;
      }
      if (getFormValue?.blockId && selectBlockId && item?.id === selectBlockId) {
        return item;
      }
      return null;
    });
    setSelectBlockDetails(calculateSelectedBlockDetails);
  }, [selectBlockId, getFormValue.blockId]);
  useMemo(() => {
    const calculateSelectedScenarioDetails = allScenarios?.find((scenario: Scenario) => {
      if (!selectedScenarioId && !getFormValue.selectedScenario) {
        return scenario.is_base;
      }
      if (
        selectedScenarioId &&
        !getFormValue.selectedScenario &&
        scenario?.id === selectedScenarioId
      ) {
        return scenario;
      }
      if (
        getFormValue?.selectedScenario &&
        !selectedScenarioId &&
        scenario?.id === Number(getFormValue?.selectedScenario)
      ) {
        return scenario;
      }
      if (
        getFormValue?.selectedScenario &&
        selectedScenarioId &&
        scenario?.id === selectedScenarioId
      ) {
        return scenario;
      }
      return null;
    });
    setSelectedScenarioDetails(calculateSelectedScenarioDetails);
  }, [selectedScenarioId, getFormValue.selectedScenario]);

  const handleSubmit = (form: any, setSubmitting: (data: boolean) => void) => {
    dispatch(addStepTwoFormData(form));
    setSubmitting(false);
    dispatch(handleActionStepNext());
  };

  return (
    <VStack w={'100%'} pt={3}>
      <Formik
        initialValues={{
          selectedDataType: '' || dataType || getFormValue.dataType,
          selectedBlock: '' || selectBlockDetails?.id || getFormValue.blockId,
          selectedScenario:
            ('' || selectedScenarioDetails?.id || getFormValue.selectedScenario) ?? '',
        }}
        validationSchema={ActionSteptwoSchemaDataType}
        onSubmit={(form, { setSubmitting }) => handleSubmit(form, setSubmitting)}
        enableReinitialize={true}
      >
        {({ values, errors, isSubmitting, setFieldValue }: any) => {
          return (
            <Form style={{ width: '100%' }}>
              <VStack display='flex' justify={'center'} w={'100%'}>
                <VStack display='flex' justify={'center'} w={'100%'}>
                  <VStack w={'100%'} justify={'center'}>
                    <ErrorMessage name='selectedDataType' component='div' className='error' />
                    {values.selectedDataType === '2' && (
                      <>
                        <FormControl mt={5}>
                          <FormLabel
                            fontSize='0.9rem'
                            color='black'
                            htmlFor='selecetDimension'
                            fontFamily={'Roboto Regular'}
                          >
                            Select block
                          </FormLabel>
                          <Menu closeOnSelect={true} isLazy>
                            <MenuButton
                              as={Button}
                              backgroundColor='white'
                              maxHeight={'40px'}
                              borderRadius='5px'
                              minWidth={'150px'}
                              textAlign='left'
                              padding='0 .8rem'
                              boxShadow={'rgb(139, 137, 177) 0px 0px 0px 0.6px'}
                              rightIcon={<ChevronDownIcon fontSize='md' />}
                              fontWeight='300'
                              fontSize='xs'
                              color='black.900'
                              w={'100%'}
                              border={
                                errors.selectedBlock ? '2px solid #fe6e6e' : '0.6px solid #8B89B1'
                              }
                              disabled={isSubmitting}
                            >
                              {selectBlockDetails?.name || <Text opacity={0.5}>Select block</Text>}
                            </MenuButton>
                            <MenuList
                              height={'fit-content'}
                              maxHeight={'20vh'}
                              overflowY={'auto'}
                              className='scroll'
                            >
                              <MenuOptionGroup
                                value={values.selectedBlock}
                                // onChange={(role: any) => {
                                //   debugger;
                                //   setFieldValue('selecetedDimension', role);
                                //   setSelectedDimId(role);
                                // }}
                              >
                                <MenuItemOption value={''}>Select block</MenuItemOption>
                                {dimesnsionList &&
                                  dimesnsionList?.blocks?.map((item: any, index: number) => (
                                    <MenuItemOption
                                      key={index}
                                      value={item?.id}
                                      onClick={async () => {
                                        await setFieldValue('selectedBlock', item?.id);
                                        await setSelectBlockId(item?.id);
                                        await dispatch(clearStepperFormPropertyData());
                                      }}
                                    >
                                      {item?.name}
                                    </MenuItemOption>
                                  ))}
                              </MenuOptionGroup>
                            </MenuList>
                          </Menu>
                          <ErrorMessage name='selectedBlock' component='div' className='error' />
                        </FormControl>
                        <FormControl mt={5} pt={2}>
                          <FormLabel
                            fontSize='0.9rem'
                            color='black'
                            htmlFor='selecetDimension'
                            fontFamily={'Roboto Regular'}
                          >
                            Select scenario
                          </FormLabel>
                          <Menu closeOnSelect={true} isLazy>
                            <MenuButton
                              as={Button}
                              backgroundColor='white'
                              maxHeight={'40px'}
                              borderRadius='5px'
                              minWidth={'150px'}
                              textAlign='left'
                              padding='0 .8rem'
                              boxShadow={'rgb(139, 137, 177) 0px 0px 0px 0.6px'}
                              rightIcon={<ChevronDownIcon fontSize='md' />}
                              fontWeight='300'
                              fontSize='xs'
                              color='black.900'
                              w={'100%'}
                              border={
                                errors.selectedScenario
                                  ? '2px solid #fe6e6e'
                                  : '0.6px solid #8B89B1'
                              }
                              disabled={isSubmitting}
                            >
                              {selectedScenarioDetails ? (
                                <Text me={3} noOfLines={1} height={'fit-content'}>
                                  {selectedScenarioDetails?.name}
                                </Text>
                              ) : (
                                <Text opacity={0.5}>Select scenario</Text>
                              )}
                            </MenuButton>
                            <MenuList zIndex={'dropdown'}>
                              <MenuOptionGroup
                                value={selectedScenarioDetails?.id}
                                // onChange={(role: any) => {
                                //   debugger;
                                //   setFieldValue('selecetedDimension', role);
                                //   setSelectedDimId(role);
                                // }}
                              >
                                <MenuItemOption value={''}>Select scenario</MenuItemOption>
                                {allScenarios &&
                                  allScenarios?.map((item: any, index: number) => (
                                    <MenuItemOption
                                      key={index}
                                      value={item?.id}
                                      onClick={() => {
                                        setFieldValue('selectedScenario', item?.id);
                                        setSelectedScenarioId(item?.id);
                                      }}
                                    >
                                      {item?.name}
                                    </MenuItemOption>
                                  ))}
                              </MenuOptionGroup>
                            </MenuList>
                          </Menu>
                        </FormControl>
                        <Flex gap={2} w={'100%'} pt={2}>
                          <Checkbox
                            className='checkboxStyle'
                            background={'transparent'}
                            borderColor={'black'}
                            border={'2px solid black'}
                            icon={<CustomCheckboxIcon isChecked={isHeader} />}
                            isChecked={isHeader}
                            onChange={() => {
                              dispatch(setNoheaders(!isHeader));
                            }}
                          ></Checkbox>
                          <Text fontSize='0.9rem' color='black' fontFamily={'Roboto Regular'}>
                            File has no column headers
                          </Text>
                        </Flex>
                      </>
                    )}
                  </VStack>
                </VStack>
                <Flex
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  pb={8}
                  pt='5'
                  w={'100%'}
                >
                  <Flex>
                    <Text
                      {...textStyles}
                      color={'black'}
                      textDecoration='underline'
                      onClick={() => {
                        setHelpModalSeleceted.on();
                      }}
                      display={'none'}
                    >
                      Help <InfoIcon color={'#8B89B1'} ms={3} />
                    </Text>
                  </Flex>
                  <ActionStepFooter
                    nextButtonName='Continue'
                    prevButtonName='Previous'
                    isSubmitting={isSubmitting}
                  />
                </Flex>
              </VStack>
            </Form>
          );
        }}
      </Formik>
    </VStack>
  );
};

export default DataType;
