import 'react-datepicker/dist/react-datepicker.css';

import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react';
import { forwardRef, MouseEventHandler, ReactNode } from 'react';
import DatePicker from 'react-datepicker';
import { GoChevronDown } from 'react-icons/go';

import Calendar from 'components/Icons/Calendar';

import datePickerStyle from './style';

interface Properties {
  value?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  planPermission?: boolean;
}

const DatePickerInput = forwardRef<HTMLButtonElement, Properties>(
  ({ value, onClick, planPermission }, reference) => (
    <Button
      ref={reference}
      cursor={planPermission ? 'not-allowed' : 'pointer'}
      onClick={planPermission ? undefined : onClick}
      leftIcon={<Calendar color='white' />}
      rightIcon={<GoChevronDown size={18} />}
      width={120}
      height={'30px'}
      variant='picker'
    >
      {value}
    </Button>
  ),
);

const DatePickerCustom = ({
  selected,
  onChange,
  placeholder,
  label,
  errorMessage,
  timescale,
  onSelect,
  clearDateInput,
  dateId,
  startIn,
  endIn,
  deleteStart,
  clearSection = true,
  isDisabled,
}: any) => {
  return (
    <FormControl sx={datePickerStyle} data-testid='datePicker-test'>
      {label && <FormLabel>{label}</FormLabel>}

      <DatePicker
        data-testid='datePicker-test'
        selected={selected}
        onChange={onChange}
        disabled={isDisabled}
        placeholderText={placeholder}
        dateFormat={timescale === 'Y' ? 'yyyy' : 'MMM yyyy'}
        showMonthYearPicker={timescale === 'M'}
        showYearPicker={timescale === 'Y'}
        customInput={<DatePickerInput planPermission={isDisabled} />}
        onSelect={onSelect}
      >
        {clearSection && (
          <Flex
            marginBottom='15px'
            borderTop='1px solid rgba(112, 138, 138, 0.3)'
            width='90%'
            display='flex'
            justifyContent='center'
            alignItems='center'
            paddingTop='10px'
            color='purple'
            onClick={() => {
              clearDateInput(dateId, deleteStart, startIn, endIn);
            }}
          >
            <Box>Clear Selection</Box>
          </Flex>
        )}
      </DatePicker>

      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};

export default DatePickerCustom;
